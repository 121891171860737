import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import moment from "moment";

const GrowthInvestorsGraph = () => {
  const [growthInvestorChartData, setGrowthInvestorChartData] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState({
    value: null,
    label: `Since ${moment().format("MMM YY")}`,
  });
  const [monthOptions, setMonthOptions] = useState([]);

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard/growthInvestorsChart`,
        {
          params: {
            saas_parent_id: authUser?.userInfo?.saas_parent_id,
            show_all_saas_data,
            is_super_admin: authUser?.userInfo?.is_super_admin,
            queryMonth: selectedMonth?.value,
          },
          headers: {
            Authorization: authUser?.token,
          },
        }
      )
      .then(response => {
        const responseData = response?.data?.data;
        setGrowthInvestorChartData(responseData);
        const monthData = responseData?.resultMonths?.map(data => ({
          value: data?.period,
          label: "Since " + data?.period,
        }));

        let currentMonthOptionLabel = `Since ${moment().format("MMM YY")}`;
        if (
          !monthData?.find(
            month =>
              month?.label?.toLowerCase() ===
              currentMonthOptionLabel?.toLowerCase()
          )
        ) {
          monthData.unshift({
            label: currentMonthOptionLabel,
            value: null,
          });
        }
        setMonthOptions(monthData);
      })
      .catch(err => {});

    return () => {};
  }, [selectedMonth]);

  const handleChangeMonth = selectedOption => {
    setSelectedMonth(selectedOption);
  };

  const CustomizedTick = graphVal => {
    const { x, y, stroke, payload } = graphVal;
    const [month, year] = payload.value.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={7} fill="#666" fontSize={11}>
          <tspan textAnchor="middle" x="0">
            {month}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="12">
            {year}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <div className="column-sec">
      <Row className="align-items-center">
        <Col md={8}>
          <h2 className="mb-4 mt-3 title-sm">Investor Scale-Up</h2>
        </Col>
        <Col md={4}>
          <Select
            className="custom-select form-control-sm"
            id="period"
            name="period"
            options={monthOptions}
            value={selectedMonth}
            onChange={handleChangeMonth}
          />
        </Col>
      </Row>
      <Row>
        <div className="mt-4 mb-4">
          <ResponsiveContainer width="100%" height={280}>
            <LineChart
              data={growthInvestorChartData?.mergedResponse || []}
              margin={{
                top: 5,
                right: 20,
                left: 5,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" tick={<CustomizedTick />} interval={0} />
              <YAxis
                tickFormatter={value =>
                  new Intl.NumberFormat("en").format(value)
                }
              />
              <Tooltip
                formatter={value => new Intl.NumberFormat("en").format(value)}
              />
              <Legend wrapperStyle={{ bottom: -20, left: 25 }} align="center" />
              <Line
                type="monotone"
                dataKey="newInvestorCount"
                stroke="#de8787"
                activeDot={{ r: 8 }}
                name="Registered Investors"
                legendType="rect"
              />
              <Line
                type="monotone"
                legendType="rect"
                dataKey="growthInvestorCount"
                name="Angel Investors"
                stroke="#8b123d"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Row>
    </div>
  );
};

export default GrowthInvestorsGraph;
