/**
 * Merge pitchday and deepdive assessment report
 */
export const MERGE_ASSESSMENT_REPORT = "MERGE_ASSESSMENT_REPORT"
export const MERGE_ASSESSMENT_REPORT_SUCCESS = "MERGE_ASSESSMENT_REPORT_SUCCESS"
export const MERGE_ASSESSMENT_REPORT_FAIL = "MERGE_ASSESSMENT_REPORT_FAIL"

/**
 * Update Poll Range Value
 */
export const UPDATE_POLL_RANGE_VALUE = "UPDATE_POLL_RANGE_VALUE"
export const UPDATE_POLL_RANGE_VALUE_SUCCESS = "UPDATE_POLL_RANGE_VALUE_SUCCESS"
export const UPDATE_POLL_RANGE_VALUE_FAIL = "UPDATE_POLL_RANGE_VALUE_FAIL"

/**
 * Get Zoom Meeting Details MEDIA
 */
export const GET_ZOOM_MEETING_DETAILS_MEDIA = "GET_ZOOM_MEETING_DETAILS_MEDIA"
export const GET_ZOOM_MEETING_DETAILS_MEDIA_SUCCESS = "GET_ZOOM_MEETING_DETAILS_MEDIA_SUCCESS"
export const GET_ZOOM_MEETING_DETAILS_MEDIA_FAIL = "GET_ZOOM_MEETING_DETAILS_MEDIA_FAIL"

/**
 * Get Deep Dive Assessment Report
 */
export const GET_DEEP_DIVE_ASSESSMENT_REPORT = "GET_DEEP_DIVE_ASSESSMENT_REPORT"
export const GET_DEEP_DIVE_ASSESSMENT_REPORT_SUCCESS = "GET_DEEP_DIVE_ASSESSMENT_REPORT_SUCCESS"
export const GET_DEEP_DIVE_ASSESSMENT_REPORT_FAIL = "GET_DEEP_DIVE_ASSESSMENT_REPORT_FAIL"

/**
 * Get Zoom Meeting Details PARTICIPANTS
 */
export const GET_ZOOM_MEETING_DETAILS_PARTICIPANTS = "GET_ZOOM_MEETING_DETAILS_PARTICIPANTS"
export const GET_ZOOM_MEETING_DETAILS_PARTICIPANTS_SUCCESS = "GET_ZOOM_MEETING_DETAILS_PARTICIPANTS_SUCCESS"
export const GET_ZOOM_MEETING_DETAILS_PARTICIPANTS_FAIL = "GET_ZOOM_MEETING_DETAILS_PARTICIPANTS_FAIL"

/**
 * Get Zoom Meeting Details QA
 */
export const GET_ZOOM_MEETING_DETAILS_QA = "GET_ZOOM_MEETING_DETAILS_QA"
export const GET_ZOOM_MEETING_DETAILS_QA_SUCCESS = "GET_ZOOM_MEETING_DETAILS_QA_SUCCESS"
export const GET_ZOOM_MEETING_DETAILS_QA_FAIL = "GET_ZOOM_MEETING_DETAILS_QA_FAIL"

/**
 * Get Zoom Meeting Details POLL
 */
export const GET_ZOOM_MEETING_DETAILS_POLL = "GET_ZOOM_MEETING_DETAILS_POLL"
export const GET_ZOOM_MEETING_DETAILS_POLL_SUCCESS = "GET_ZOOM_MEETING_DETAILS_POLL_SUCCESS"
export const GET_ZOOM_MEETING_DETAILS_POLL_FAIL = "GET_ZOOM_MEETING_DETAILS_POLL_FAIL"

/**
 * Get Completed Deep Dive Event List
 */
export const GET_COMPLETED_DEEP_DIVE_EVENT_LIST = "GET_COMPLETED_DEEP_DIVE_EVENT_LIST"
export const GET_COMPLETED_DEEP_DIVE_EVENT_LIST_SUCCESS = "GET_COMPLETED_DEEP_DIVE_EVENT_LIST_SUCCESS"
export const GET_COMPLETED_DEEP_DIVE_EVENT_LIST_FAIL = "GET_COMPLETED_DEEP_DIVE_EVENT_LIST_FAIL"

/* DEEP DIVE EVENT LIST */
export const GET_DEEP_DIVE_EVENT_LIST = "GET_DEEP_DIVE_EVENT_LIST"
export const GET_DEEP_DIVE_EVENT_LIST_SUCCESS = "GET_DEEP_DIVE_EVENT_LIST_SUCCESS"
export const GET_DEEP_DIVE_EVENT_LIST_FAIL = "GET_DEEP_DIVE_EVENT_LIST_FAIL"

/**
 * GET START UP | FOUNDER LIST
 */
export const GET_START_UP_LIST = "GET_START_UP_LIST"
export const GET_START_UP_LIST_SUCCESS = "GET_START_UP_LIST_SUCCESS"
export const GET_START_UP_LIST_FAIL = "GET_START_UP_LIST_FAIL"

/**
 * GET PITCH DAY LIST
 */
export const GET_PITCH_DAY_LIST = "GET_PITCH_DAY_LIST"
export const GET_PITCH_DAY_LIST_SUCCESS = "GET_PITCH_DAY_LIST_SUCCESS"
export const GET_PITCH_DAY_LIST_FAIL = "GET_PITCH_DAY_LIST_FAIL"

/**
 * GET OTHER DETAILS LIST
 */
export const GET_OTHER_DETAILS = "GET_OTHER_DETAILS"
export const GET_OTHER_DETAILS_SUCCESS = "GET_OTHER_DETAILS_SUCCESS"
export const GET_OTHER_DETAILS_FAIL = "GET_OTHER_DETAILS_FAIL"

/**
 * CREATE DEEP DIVE EVENT
 */
export const ADD_DEEP_DIVE_EVENT = "ADD_DEEP_DIVE_EVENT"
export const ADD_DEEP_DIVE_EVENT_SUCCESS = "ADD_DEEP_DIVE_EVENT_SUCCESS"
export const ADD_DEEP_DIVE_EVENT_FAIL = "ADD_DEEP_DIVE_EVENT_FAIL"

/**
 * GET DEEP DIVE EVENT DETAILS
 */
export const GET_DEEP_DIVE_EVENT_DETAILS = "GET_DEEP_DIVE_EVENT_DETAILS"
export const GET_DEEP_DIVE_EVENT_DETAILS_SUCCESS = "GET_DEEP_DIVE_EVENT_DETAILS_SUCCESS"
export const GET_DEEP_DIVE_EVENT_DETAILS_FAIL = "GET_DEEP_DIVE_EVENT_DETAILS_FAIL"

/**
 * UPDATE DEEP DIVE EVENT
 */
export const UPDATE_DEEP_DIVE_EVENT = "UPDATE_DEEP_DIVE_EVENT"
export const UPDATE_DEEP_DIVE_EVENT_SUCCESS = "UPDATE_DEEP_DIVE_EVENT_SUCCESS"
export const UPDATE_DEEP_DIVE_EVENT_FAIL = "UPDATE_DEEP_DIVE_EVENT_FAIL"

/**
 * DELETE DEEP DIVE EVENT
 */
export const DELETE_DEEP_DIVE_EVENT = "DELETE_DEEP_DIVE_EVENT"
export const DELETE_DEEP_DIVE_EVENT_SUCCESS = "DELETE_DEEP_DIVE_EVENT_SUCCESS"
export const DELETE_DEEP_DIVE_EVENT_FAIL = "DELETE_DEEP_DIVE_EVENT_FAIL"
