import React, { Component } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Button, Table, Alert, Input, FormGroup, Label } from "reactstrap";
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { getDocumentRules, setDocumentRules, closeAlertBox } from "../../store/document/actions"
import { isEqual } from "lodash-es";
import { isEmpty } from "lodash";

const COLUMN_TO_KEY_MAP = {
  "Pitch Deck": "pitch_deck",
  "Key Clients": "key_clients",
  "Product Screenshots": "product_screenshots",
  "Latest Capitalization": "latest_capitalization",
  "Last One Year Financial Docs": "last_one_year_docs",
  "Next Two Years Projections Docs": "next_two_years_projection_docs",
};
const COLUMNS = ["Pitch Deck",
  "Key Clients",
  "Product Screenshots",
  "Latest Capitalization",
  "Last One Year Financial Docs",
  "Next Two Years Projections Docs"];

class DocumentRules extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rules: {},
      error: "",
      success: "",
    }
  }

  componentDidMount() {
    const { rules, disabled, onGetDocumentRules } = this.props
    onGetDocumentRules();
    this.setState({ rules, disabled });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newRules = this.props.rules;
    if (!isEqual(newRules, nextProps.rules)) {
      newRules = nextProps.rules;
    }
    this.setState({ rules: newRules, error: nextProps.error, success: nextProps.success });
  }

  handleCheck = (key) => {
    let rules = this.state.rules;
    this.setState({ disabled: false, rules: { ...rules, [key]: !this.isChecked(rules[key]) } });
  }

  updateRules = () => {
    const { onSetDocumentRules } = this.props
    onSetDocumentRules(this.state.rules);
  }

  isChecked = (v) => {
    return v === "true" || v === true;
  }

  handleAlertClose = () => {
    const { onAlertClose } = this.props;
    onAlertClose();
  }

  render() {
    const rules = this.state.rules;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Document Rules | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Document Rules" breadcrumbItem="Rules" />
            {/* Render Breadcrumbs */}
            <Row>
              <Col xl="12">
                {
                  !isEmpty(this.state.error) ? (
                    <Alert color="danger" toggle={this.handleAlertClose}>Some error occured!</Alert>
                  ) : null
                }
                {!!this.state.success ? (
                  <Alert color="success" toggle={this.handleAlertClose}>{this.state.success}</Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {
                      rules && Object.keys(rules).length > 0 ?
                        <>
                          <Table bordered>
                            <thead>
                              <tr>
                                <th><strong>Document Type</strong></th>
                                {
                                  COLUMNS.map((c, i) => (
                                    <th key={i}>{c}</th>
                                  ))
                                }
                              </tr>
                            </thead>
                            <tbody>
                              <tr><td><strong>Publicly Available ?</strong></td>
                                {
                                  COLUMNS.map((c, i) => (
                                    <td key={i}>
                                      <FormGroup check>
                                        <Label check>
                                          <Input type="checkbox" onChange={() => { this.handleCheck(COLUMN_TO_KEY_MAP[c]) }} checked={this.isChecked(rules[COLUMN_TO_KEY_MAP[c]])} />
                                        </Label>
                                      </FormGroup>
                                    </td>
                                  ))
                                }
                              </tr>
                            </tbody>
                          </Table>
                          <br />
                          <Button title="Click to update the Rules" className="btn btn-info" onClick={this.updateRules} disabled={this.state.disabled}>Update</Button>
                        </>
                        : <h5 className="text-center">No Document rules found !</h5>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}


DocumentRules.propTypes = {
  rules: PropTypes.object,
  onGetDocumentRules: PropTypes.func,
  onSetDocumentRules: PropTypes.func,
  onAlertClose: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  disabled: PropTypes.bool,
}

const mapStateToProps = ({ docs }) => ({
  rules: docs.rules,
  error: docs.error,
  success: docs.success,
  disabled: docs.disabled,
})

const mapDispatchToProps = dispatch => ({
  onGetDocumentRules: () => dispatch(getDocumentRules()),
  onAlertClose: () => dispatch(closeAlertBox()),
  onSetDocumentRules: rules => dispatch(setDocumentRules(rules)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocumentRules))
