import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'


const GeneralMasterAddModal = ({ show, addModal, getAll }) => {
  const [data, setData] = useState({
    type: '',
    masterdata: []
  })
  const [valueObj, setValueObj] = useState({})
  const [count, setCount] = useState(1)
  let arr = []

  const toggle = () => {
    addModal()
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setValueObj({
      ...valueObj,
      [name]: value
    })
  }

  const addData = async (e) => {
    e.preventDefault()

    let tempObj = {
      ...data,
      masterdata: [...data.masterdata, valueObj],
    }

    await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/generalmaster/create`, tempObj, {
      headers: {
        'Authorization': JSON.parse(localStorage.getItem('authUser')).token
      }
    }).then(res => {
      addModal()
      getAll()
      toast.success('Add Successfully')
    }).catch(err => {
      toast.error('Something went wrong')
    })
  }

  for (let index = 0;index < count;index++) {
    arr.push(
      <div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
        <Label>Label:</Label>
        <Input
          type='text'
          name='label'
          onChange={handleChange}
        />

        <Label>Value:</Label>
        <Input
          type='text'
          name='value'
          onChange={handleChange}
        />
      </div>
    )
  }

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={show}
        toggle={toggle}
      >
        <ModalHeader>
          Add Data
        </ModalHeader>

        <ModalBody>
          <Label>Type: </Label>
          <Input
            name='type'
            type='text'
            onChange={(e) => setData({
              ...data,
              ['type']: e.target.value
            })}
          />
          {arr}
          <Button onClick={() => {
            setCount(count + 1)
            setData((preValue) => ({
              ...preValue,
              masterdata: [...preValue.masterdata, valueObj]
            }))
          }}>+ More</Button>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => addData(e)}>
            Add
          </Button>
          <Button onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default GeneralMasterAddModal
