import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { CSVDownload } from "react-csv";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { checkIntentCurrency, exchangeCurrencyRates } from "helpers/common";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getAllUsersForReference,
  toCommaFormat,
  toCommaFormatWithK,
  getAppliedFunding,
} from "../../../helpers/backend_helper";
import { Table } from "reactstrap";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import * as moment from "moment";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, size, stubTrue, toInteger } from "lodash";
import {
  getAppliedFundings,
  updateStatus,
  getDealManagers,
  updateFundingDetails,
} from "store/appliedFunding/actions";
import { Accordion } from "react-bootstrap";
import { profilePicture } from "helpers/storage_helper";
import InvestorGroupsModal from "./InvestorGroupsModal";
import axios from "axios";
import { amountNumberToTextFrmt } from "helpers/utils";
import DataFetchLoader from "components/Common/DataFetchLoader";

const selectOptions = [
  "Filter by status",
  "Applying/Draft",
  "Submitted",
  "In-Process/Reviewing",
  "Waitlisted",
  "Approved",
  "Declined",
];

const statusList = [
  "Applied For",
  "Applying/Draft",
  "Deal Rejected",
  "Closed - Internally Funded",
  "Closed - Externally Funded",
];

const styles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#E27235" : "",
  }),
};

function getYearsListing() {
  let yearData = [];
  let currentYear = parseInt(moment().format("YYYY"));
  for (let i = 2022; i <= currentYear; i++) {
    yearData.push({ label: `${i}`, value: `${i}` });
  }

  return yearData;
}

const months = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];

function getLabel(monthId) {
  let monthData = months?.find(item => item.value === monthId);

  return monthData?.label || "January";
}

let years = getYearsListing();

function sortHistoryDesc(history) {
  history.sort(function (a, b) {
    let date_1 = moment(b.date);
    let date_2 = moment(a.date);
    return date_2 - date_1;
  });

  return history;
}

function sortHistory(history) {
  history.sort(function (a, b) {
    let date_1 = moment(b.date);
    let date_2 = moment(a.date);
    return date_1 - date_2;
  });

  return history;
}

function getFolioAndSchema(scheme_name) {
  if (typeof scheme_name === "undefined" || scheme_name === "") {
    return "";
  }

  return `<Folio not available yet> + ${scheme_name}`;
}

function getKYCStatus(status) {
  if (typeof status === "undefined") return "";

  return status;
}

function getInvestmentVehicle(inv_vehicle) {
  let invVehicleString = "";
  if (typeof inv_vehicle === "string") {
    if (inv_vehicle === "") return "";
  }

  if (typeof inv_vehicle === "object") {
    if (typeof inv_vehicle[0] === "string") return inv_vehicle[0];
    if (inv_vehicle.length === 0) return "";
    invVehicleString = inv_vehicle.map(item => item?.label);
  }

  if (!invVehicleString) return "";

  return invVehicleString?.join(", ") || "";
}

function getManagerName(obj) {
  if (!obj || obj === "") return "";

  if (Object.keys(obj).length === 0) return "";

  if (typeof obj?.firstname === "undefined") return "";

  if (obj?.firstname === "") return "";

  return `${obj?.firstname} ${obj?.lastname}`;
}

function getDrawdownAmount(record) {
  let intent_cur = checkIntentCurrency(
    record?.user_currency || {},
    record?.startup_currency || {},
    record?.tax_return_filling_countries || []
  );

  let service_fees =
    typeof record?.pastinvestment?.past_investment?.service_fees === "undefined"
      ? 0
      : record?.pastinvestment?.past_investment?.service_fees;

  if (typeof record?.pastinvestment?.past_investment === "undefined") return "";

  if (
    typeof record?.pastinvestment?.past_investment?.amount_invested ===
    "undefined"
  )
    return "";

  return `${intent_cur?.symbol}${
    record?.pastinvestment?.past_investment?.amount_invested + service_fees
  }`;
}

function getTransactionDate(pastInv) {
  if (typeof pastInv === "undefined") return "";

  if (typeof pastInv?.createdAt === "undefined") return "";

  if (pastInv?.createdAt.length === 0) return "";

  return moment(pastInv?.createdAt).format("DD-MM-YYYY");
}

function getSaasName(saas) {
  if (saas === "undefined") return "";
  if (typeof saas === "undefined") return "";
  if (saas === "") return "";

  return saas;
}

function getTransactionApproveStatus(pastInv) {
  if (typeof pastInv === "undefined") return "No";

  if (typeof pastInv?.verify_by_founder === "undefined") return "No";

  return pastInv?.verify_by_founder ? "Yes" : "No";
}

function getIntentAmount(record) {
  let intent_cur = JSON.parse(localStorage.getItem("allFundDetails"))?.find(
    funddetail =>
      funddetail?._id?.toString() == record?.fund_type_id?.toString()
  );

  let committed_amount_currency = 0;

  if (intent_cur?.currency === "INR") {
    committed_amount_currency = record?.committed_amount || 0;
  } else if (intent_cur?.currency === "USD") {
    committed_amount_currency = record?.dealCommittedAmount || 0;
  }

  if (record?.areYouIntrested === "Yes") {
    if (
      record?.committed_amount === null ||
      typeof record?.committed_amount === "undefined"
    ) {
      return `${intent_cur?.symbol}${0}`;
    } else {
      let cur = `${intent_cur?.symbol}${
        committed_amount_currency ? toCommaFormat(committed_amount_currency) : 0
      }`;

      return cur;
    }
  }

  if (record?.areYouIntrested === "No" || record?.areYouIntrested === "Maybe") {
    return record?.areYouIntrested;
  }

  if (record?.areYouIntrested === "" && record?.committed_amount === 0) {
    return "No";
  }

  return "No";
}

function getConsentAmount(record) {
  let intent_cur = JSON.parse(localStorage.getItem("allFundDetails"))?.find(
    funddetail =>
      funddetail?._id?.toString() == record?.fund_type_id?.toString()
  );

  let consent_amount_sent = 0;
  let consent_amount_rec = 0;
  let consent_amount_currency = 0;
  if (intent_cur?.currency === "INR") {
    consent_amount_currency = record?.consent_amount || 0;
  } else if (intent_cur?.currency === "USD") {
    consent_amount_currency = record?.consent_amount_global || 0;
  }

  if (consent_amount_currency > 0) {
    consent_amount_sent = toCommaFormat(consent_amount_currency);
  }
  if (record?.terms_accept && consent_amount_currency > 0) {
    consent_amount_rec = toCommaFormat(consent_amount_currency);
  } else {
    consent_amount_rec = consent_amount_sent;
  }

  return consent_amount_rec === 0
    ? ""
    : `${intent_cur?.symbol}${consent_amount_rec}`;
}

function getDrawdownStatus(row) {
  // let status = "";
  // if (row?.drawdown_status == "Drawdown Sent") {
  //   if (
  //     row?.terms_accept &&
  //     row?.drawdown_drop_status == "no" &&
  //     row?.pastinvestment?.past_investment?.amount_invested > 0
  //   ) {
  //     status = "Received";
  //   } else if (
  //     row?.drawdown_status == "Drawdown Sent" &&
  //     row?.drawdown_drop_status == "yes"
  //   ) {
  //     status = "Dropped";
  //   } else {
  //     if (row?.terms_accept && row?.drawdown_drop_status == "no") {
  //       status = "Pending";
  //     }
  //   }
  // }
  // return status;

  let status = "-";
  if (
    row?.kyc_status ==
      "Contribution Agreement Signed by All - Process Completed" &&
    parseInt(row?.consent_amount) > 0 &&
    !row?.contribution_limit_exceeded
  ) {
    if (
      row?.drawdown_status == "Drawdown Sent" &&
      row?.consent_send == "yes" &&
      row?.terms_accept &&
      (!row?.consent_drop_status || row?.consent_drop_status == "no") &&
      parseInt(row?.consent_amount) > 0 &&
      (!row?.drawdown_drop_status || row?.drawdown_drop_status == "no")
    ) {
      if (row?.pastinvestment?.past_investment?.amount_invested > 0) {
        status = "Received";
      } else {
        status = "Awaited";
      }
    } else if (
      row?.drawdown_status == "Drawdown Sent" &&
      row?.drawdown_drop_status == "yes"
    ) {
      status = "Dropped";
    }
  }

  return status;
}

function getConsentStatus(record) {
  let status = "-";
  if (
    record?.kyc_status ==
      "Contribution Agreement Signed by All - Process Completed" &&
    parseInt(record?.consent_amount) > 0
  ) {
    if (
      record?.consent_send == "yes" &&
      record?.terms_accept &&
      (!record?.consent_drop_status || record?.consent_drop_status == "no") &&
      record?.consent_amount > 0
    ) {
      status = "Received";
    } else if (record?.consent_drop_status == "yes") {
      status = "Dropped";
    } else if (record?.consent_send == "yes" && !record?.terms_accept) {
      status = "Pending";
    }
  }

  return status === "-" ? "" : status;
}

class AppliedFundingList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      roleWiseAccess: {},
      saas_name_list: [],
      current_status_list: [
        { value: "Applied For", label: "Applied For" },
        { value: "Deal Live", label: "Deal Live" },
        {
          value: "Closed - Externally Funded",
          label: "Closed - Externally Funded",
        },
        {
          value: "Closed - Internally Funded",
          label: "Closed - Internally Funded",
        },
        { value: "Deal Rejected", label: "Deal Rejected" },
        { value: "Applying/Draft", label: "Applying/Draft" },
      ],
      name_populated: false,
      users_last_six_month: [],
      revenue_last_six_month: [],
      editFundingDealErrors: {},
      formedCSVData: [],
      formedBulkCSVData: [],
      exportLoading: false,
      cumulative_export_criteria: [
        "ca_completed_date",
        "kyc_approved_date",
        "consent_received_date",
        "consent_sent_date",
        "drawdown_sent_date",
      ],
      exportBulkLoading: false,
      minimum_amount: "",
      how_much_fund_raising: "",
      exportCSVPopup: false,
      actual_valuation: "",
      valuation_type: "",
      evaluation_report_primary_information: {},
      appliedFunding: [],
      exportNotification: false,
      dealManagers: [],
      dealChangeLogs: [],
      evaluationReport: {},
      month: 1,
      year: "2023",
      manager: "",
      modal: false,
      editFundingDealDetailModal: false,
      curr_status: "Filter by status",
      investorGroupModalOpen: false,
      historyLogsModalOpen: false,
      selectedDeal: {},
      dealHistoryLog: [],
      historyUsername: [],
      loading: false,
      fundTypeList: [],
      selectedFundType: {},
      querystring: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.editFundingDealDetailsToggle =
      this.editFundingDealDetailsToggle.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleClicks = this.handleClicks.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleInvestorGroupsModal = this.handleInvestorGroupsModal.bind(this);
    this.handleHistoryLogsModal = this.handleHistoryLogsModal.bind(this);
    this.handleExportCSVPopup = this.handleExportCSVPopup.bind(this);
    this.setInvestorGroupsModal = this.setInvestorGroupsModal.bind(this);
  }

  handleInvestorGroupsModal = () => {
    this.setState(prevState => ({
      investorGroupModalOpen: !prevState.investorGroupModalOpen,
    }));
  };

  setInvestorGroupsModal = investorGroupModalOpen =>
    this.setState({ investorGroupModalOpen });

  handleHistoryLogsModal = () => {
    this.setState(prevState => ({
      historyLogsModalOpen: !prevState.historyLogsModalOpen,
    }));
  };

  handleExportCSVPopup = () => {
    this.setState(prevState => ({
      exportCSVPopup: !prevState.exportCSVPopup,
    }));
  };

  async getAllInvestmentVehicles() {
    try {
      if (!JSON.parse(localStorage.getItem("allFundDetails"))) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/applyforfunding/getFundDetails`,
            {
              headers: {
                Authorization: JSON.parse(localStorage.getItem("authUser"))
                  ?.token,
              },
            }
          )
          .then(res => {
            let data = res?.data?.data;
            let newdata = data.filter(row => {
              if (
                row?._id?.toString() == "64870dba37cf22f296df37fb" ||
                row?._id?.toString() == "64870da237cf22f296df37f9"
              ) {
                return true;
              }
            });

            newdata.push({ value: "", label: "All" });

            this.setState({
              fundTypeList: newdata,
            });

            localStorage.setItem("allFundDetails", JSON.stringify(data));

            //For defualt selected fund type
            let selectedFundType = data?.find(
              row => row?._id?.toString() == "64870dba37cf22f296df37fb"
            );
            this.setState({ selectedFundType: selectedFundType });
          });
      } else {
        let data = JSON.parse(localStorage.getItem("allFundDetails"));
        let newdata = data.filter(row => {
          if (
            row?._id?.toString() == "64870dba37cf22f296df37fb" ||
            row?._id?.toString() == "64870da237cf22f296df37f9"
          ) {
            return true;
          }
        });
        newdata.push({ value: "", label: "All" });
        this.setState({
          fundTypeList: newdata,
        });
      }
    } catch (error) {}
  }

  async handlefundType(e) {
    const { appliedFunding, onGetAppliedFundings, dataSize } = this.props;
    this.setState({ selectedFundType: e });
    let query = ``;
    if (e?._id) {
      query += "&fund_type_id=" + e?._id;
    }
    let param = this.state.query + query;
    await onGetAppliedFundings(param);
  }

  setHistoryLogsModal = historyLogsModalOpen =>
    this.setState({ historyLogsModalOpen });

  async componentDidMount() {
    const {
      appliedFunding,
      dealManagers,
      onGetAppliedFundings,
      onGetDealManagers,
      manager,
      dataSize,
      page,
    } = this.props;

    this.getAllInvestmentVehicles();
    getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let { _id, saas_name } = user;

            if (!_id) return null;
            if (!saas_name) return null;
            let temp = saas_name;
            if (user?.is_saas_parent) {
              let temp = saas_name;

              saasPartners.push({
                value: _id,
                label: temp,
              });
            }

            return { value: _id, label: temp };
          })
          .filter(data => !!data);
        this.setState({
          saas_name_list: users,
          name_populated: true,
        });
      })
      .catch(error => {
        console.trace(error);
        this.setState({
          saas_name_list: [],
          name_populated: false,
        });
      });

    await onGetDealManagers();
    await onGetAppliedFundings();

    this.setState({ appliedFunding, dealManagers, manager, dataSize });

    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(async res => {
        this.setState({ roleWiseAccess: res.data.roleWiseAccess });
        await onGetDealManagers();
        //await onGetAppliedFundings();
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
  }

  getDealHistory = async historyLog => {
    try {
      let historyIds = [];
      historyLog?.map(history => {
        historyIds.push(history?.activated_user_id);
        return historyIds;
      });

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/applyforfunding/get-deal-history-log`,
          historyIds,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(async res => {
          this.setState({
            historyUsername: res?.data?.data,
          });
        });
    } catch (error) {}
  };

  getDealChangeLogs = async fundingDealId => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/applyforfunding/get-deal-change-logs/${fundingDealId}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(async res => {
          this.setState({
            dealChangeLogs: res?.data?.logs,
          });
        });
    } catch (error) {}
  };

  handleFilter = e => {
    this.setState({ curr_status: e.target.value });
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleClicks = arg => {
    this.setState({ appliedFunding: "", isEdit: false });
    this.toggle();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { appliedFunding } = this.props;
    if (
      !isEmpty(appliedFunding) &&
      size(prevProps.appliedFunding) !== size(appliedFunding)
    ) {
      this.setState({ appliedFunding: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteUser = user => {
    // const { onDeleteUser } = this.props;
    if (user._id !== undefined) {
      // onDeleteUser(user);
      this.onPaginationPageChange(1);
    }
  };

  revenueInLastSixMonth = last_six_month_revenue => {
    let fields = [
      "First Month",
      "Second Month",
      "Third Month",
      "Fourth Month",
      "Fifth Month",
      "Sixth Month",
    ];
    let revenueDetails = [];
    last_six_month_revenue.map((item, index) => {
      try {
        let now = new Date(),
          month_ago = index + 1,
          revenue = "-";
        now.setMonth(now.getMonth() - month_ago);
        now.setDate(1);

        let revenue_month_data = last_six_month_revenue.filter(
          d => d.revenue_month_date === moment(now).format("YYYY-MM-DD")
        );

        // revenue
        if (revenue_month_data && revenue_month_data.length > 0)
          revenue = revenue_month_data[0].revenue;
        revenueDetails.push({ field: fields[index], value: revenue });
      } catch (err) {}
      return true;
    });
    return revenueDetails;
  };

  usersInLastSixMonth = last_six_month_users => {
    let fields = [
      "First Month",
      "Second Month",
      "Third Month",
      "Fourth Month",
      "Fifth Month",
      "Sixth Month",
    ];
    let usersDetails = [];
    last_six_month_users.map((item, index) => {
      try {
        let now = new Date(),
          month_ago = index + 1,
          user_count = "-";
        now.setMonth(now.getMonth() - month_ago);
        now.setDate(1);

        let users_month_data = last_six_month_users.filter(
          d => d.users_month_date === moment(now).format("YYYY-MM-DD")
        );

        // user
        if (users_month_data && users_month_data.length > 0)
          user_count = users_month_data[0].total_users;
        usersDetails.push({ field: fields[index], value: user_count });
      } catch (err) {}
      return true;
    });

    return usersDetails;
  };

  handleClick = arg => {
    const appliedFunding = arg;
    const { onUpdateStatus } = this.props;

    if (
      appliedFunding.applyforfundings.current_status !== "Approved" &&
      appliedFunding.applyforfundings.current_status !== "Declined" &&
      appliedFunding.applyforfundings.current_status !== "Waitlisted"
    ) {
      onUpdateStatus({
        id: appliedFunding.applyforfundings._id,
        status: "In-Process/Reviewing",
      });
    }
    const business_information = appliedFunding.applyforfundings
      .business_information?.length
      ? [
          {
            industry_sector:
              appliedFunding.applyforfundings.business_information[0]
                ?.industry_sector,
            business_model_category:
              appliedFunding.applyforfundings.business_information[0]
                ?.business_model_category,
            type_of_sales:
              appliedFunding.applyforfundings.business_information[0]
                ?.type_of_sales,
            location_of_target_customer:
              appliedFunding.applyforfundings.business_information[0]
                ?.location_of_target_customer,
            _id: appliedFunding.applyforfundings.business_information[0]?._id,
            business_model:
              appliedFunding.applyforfundings.business_information[0]
                ?.business_model,
            last_six_month_revenue:
              appliedFunding.applyforfundings.business_information[0]
                ?.last_six_month_revenue,
            trends_in_revenue_number:
              appliedFunding.applyforfundings.business_information[0]
                ?.trends_in_revenue_number,
            total_user_in_last_six_month:
              appliedFunding.applyforfundings.business_information[0]
                ?.total_user_in_last_six_month,
            customers_list:
              appliedFunding.applyforfundings.business_information[0]
                ?.customers_list,
            current_go_to_market_channel:
              appliedFunding.applyforfundings.business_information[0]
                ?.current_go_to_market_channel,
            use_case_and_potential_target_customers:
              appliedFunding.applyforfundings.business_information[0]
                ?.use_case_and_potential_target_customers,
            competitors:
              appliedFunding.applyforfundings.business_information[0]
                ?.competitors,
            your_stand_out_with_competitors:
              appliedFunding.applyforfundings.business_information[0]
                ?.your_stand_out_with_competitors,
            current_monthly_burn_rate:
              appliedFunding.applyforfundings.business_information[0]
                ?.current_monthly_burn_rate,
            current_cash_balance:
              appliedFunding.applyforfundings.business_information[0]
                ?.current_cash_balance,
            headquarter_location:
              appliedFunding.applyforfundings.business_information[0]
                ?.headquarter_location,
            company_incorporation_location:
              appliedFunding.applyforfundings.business_information[0]
                ?.company_incorporation_location,
            raised_any_outside_funding:
              appliedFunding.applyforfundings.business_information[0]
                ?.raised_any_outside_funding,
            total_fund_raised:
              appliedFunding.applyforfundings.business_information[0]
                ?.total_fund_raised,
            how_much_fund_raising:
              appliedFunding.applyforfundings.business_information[0]
                ?.how_much_fund_raising,
            current_valuation:
              appliedFunding.applyforfundings.business_information[0]
                ?.current_valuation,
            actual_valuation:
              appliedFunding.applyforfundings.business_information[0]
                ?.actual_valuation,
            pre_money_valuation:
              appliedFunding.applyforfundings.business_information[0]
                ?.pre_money_valuation,
            post_money_valuation:
              appliedFunding.applyforfundings.business_information[0]
                ?.post_money_valuation,
          },
        ]
      : [];

    let usersDetails = this.usersInLastSixMonth(
      appliedFunding.applyforfundings.business_information[0]
        ?.total_user_in_last_six_month || []
    );
    let revenueDetails = this.revenueInLastSixMonth(
      appliedFunding.applyforfundings.business_information[0]
        ?.last_six_month_revenue || []
    );

    const team_information = appliedFunding.applyforfundings.team_information
      ?.length
      ? [
          {
            _id: appliedFunding.applyforfundings.team_information[0]?._id,
            cofounder:
              appliedFunding.applyforfundings.team_information[0]?.cofounder,
            founding_team_based_in:
              appliedFunding.applyforfundings.team_information[0]
                ?.founding_team_based_in,
          },
        ]
      : [];

    const financial_information = appliedFunding.applyforfundings
      .financial_information?.length
      ? [
          {
            _id: appliedFunding.applyforfundings.financial_information[0]?._id,
            upload_financial_documents_last_one_year:
              appliedFunding.applyforfundings.financial_information[0]
                .upload_financial_documents_last_one_year,
            more_documents:
              appliedFunding.applyforfundings.financial_information[0]
                .more_documents,
          },
        ]
      : [];

    const evaluation_report_primary_information =
      appliedFunding?.evaluationReport?.primary_information;
    const actual_valuation =
      evaluation_report_primary_information?.valuation_type === "preMoney"
        ? toInteger(evaluation_report_primary_information?.current_valuation) +
          toInteger(
            evaluation_report_primary_information?.how_much_fund_raising
          )
        : toInteger(evaluation_report_primary_information?.current_valuation);
    localStorage.setItem(
      "evaluationReportId",
      appliedFunding?.evaluationReport?._id
    );

    this.setState({
      appliedFunding: {
        id: appliedFunding.applyforfundings._id,
        user_id: appliedFunding.applyforfundings.user_id,
        current_status: appliedFunding.applyforfundings.current_status,
        basic_information: [
          {
            _id: appliedFunding.applyforfundings.basic_information[0]?._id,
            company_legal_name:
              appliedFunding.applyforfundings.basic_information[0]
                ?.company_legal_name,
            company_tag_line:
              appliedFunding.applyforfundings.basic_information[0]
                ?.company_tag_line,
            product_description:
              appliedFunding.applyforfundings.basic_information[0]
                ?.product_description,
            elevator_pitch:
              appliedFunding.applyforfundings.basic_information[0]
                ?.elevator_pitch,
            website:
              appliedFunding.applyforfundings.basic_information[0]?.website,
            product_launched:
              appliedFunding.applyforfundings.basic_information[0]
                ?.product_launched,
          },
        ],
        business_information: business_information,
        team_information: team_information,
        product_video: appliedFunding.applyforfundings?.product_video,
        financial_information: financial_information,
        evaluationReport: appliedFunding?.evaluationReport,
        evaluation_report_primary_information:
          evaluation_report_primary_information,
      },
      users_last_six_month: usersDetails,
      revenue_last_six_month: revenueDetails,
      how_much_fund_raising:
        evaluation_report_primary_information?.how_much_fund_raising || "",
      current_valuation:
        evaluation_report_primary_information?.current_valuation || "",
      actual_valuation: actual_valuation,
      minimum_amount:
        evaluation_report_primary_information?.minimum_amount || "",
    });
    //
    this.toggle();
  };

  editFundingDealDetailsToggle() {
    this.setState(prevState => ({
      editFundingDealDetailModal: !prevState.editFundingDealDetailModal,
    }));
  }
  /**
   * Handling submit user on user form
   */
  handleValidSubmit = (e, values) => {
    const { isEdit, appliedFunding, selectedUser } = this.state;
    const manager = this.state.manager || this.props.manager;
    const { onUpdateStatus } = this.props;

    onUpdateStatus({
      id: appliedFunding.id,
      status: values["current_status"],
      reason: values["comment"],
      manager: manager,
    });

    this.setState({ selectedUser: null });
    this.toggle();
  };

  handleOnChange = e => {
    this.setState({ manager: e.target.value });
  };
  handleOnChangeEditFundingDeal = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onTableChange = async (action, { filters, sortOrder, sortField, page }) => {
    const { appliedFunding, onGetAppliedFundings, dataSize } = this.props;

    //if (action === "filter") {
    //console.log(filters);
    const startupAndRound = filters?.startupAndRound?.filterVal || "";
    const firstname = filters?.["user.firstname"]?.filterVal || "";
    const saas_name = filters?.saas_name?.filterVal || "";
    const current_status = filters?.current_status?.filterVal || "";
    //const user_type = filters["user_types[0].user_type[0]"]?.filterVal || "";

    let query = ``;
    if (startupAndRound.length > 0) {
      query += "&startupAndRound=" + startupAndRound;
    }
    if (firstname.length > 0) {
      query += "&firstname=" + firstname;
    }
    if (saas_name.length > 0) {
      query += "&saas_name=" + saas_name;
    }
    if (current_status.length > 0) {
      query += "&current_status=" + current_status;
    }

    let order = sortOrder === "asc" ? 1 : -1;
    query += `&page=${page}&sort=${
      sortField === "_id" ? "createdAt" : sortField
    }&order=${order}`;
    this.setState({ query: query });

    if (this.state.selectedFundType?._id) {
      query += "&fund_type_id=" + this.state.selectedFundType?._id;
    }

    await onGetAppliedFundings(query);

    //}
  };

  // PHP
  updateEditFunctionDeal = async () => {
    const {
      minimum_amount,
      how_much_fund_raising,
      current_valuation,
      actual_valuation,
      evaluationReport,
    } = this.state;
    let evaluationReportId = localStorage.getItem("evaluationReportId");
    const {
      app_available,
      app_type,
      company_name,
      current_team_size,
      customers,
      direct_competition,
      entry_barriers_substitutes,
      existing_global_players,
      founded_in,
      headquarters,
      indirect_competition,
      risks,
      website,
      valuation_type,
    } = this.state?.appliedFunding?.evaluation_report_primary_information;

    const reqData = {
      app_available: app_available,
      app_type: app_type,
      company_name: company_name,
      current_team_size: current_team_size,
      customers: customers,
      direct_competition: direct_competition,
      entry_barriers_substitutes: entry_barriers_substitutes,
      existing_global_players: existing_global_players,
      founded_in: founded_in,
      headquarters: headquarters,
      indirect_competition: indirect_competition,
      risks: risks,
      website: website,
      evaluationReportId: evaluationReportId,
      valuation_type: valuation_type,
      how_much_fund_raising: how_much_fund_raising,
      current_valuation: current_valuation,
      actual_valuation: actual_valuation,
      minimum_amount: minimum_amount,
    };
    await this.props.onUpdateEditFundingDetails(reqData);
    this.setState({
      how_much_fund_raising: how_much_fund_raising,
      current_valuation: current_valuation,
      actual_valuation: actual_valuation,
      minimum_amount: minimum_amount,
    });
    this.editFundingDealDetailsToggle();
  };

  sortedUsers = users => {
    return users.sort((a, b) =>
      `${a.startup_name}`
        .toLowerCase()
        .localeCompare(`${b.startup_name}`.toLowerCase())
    );
  };

  generateCsvDataForAllSaaS = async () => {
    try {
      this.setState({
        ...this.state,
        exportNotification: false,
        exportLoading: true,
      });

      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api-v2/get-startup-details/${this.state.month}-${this.state.year}`,
        {
          criteria: JSON.stringify(this.state.cumulative_export_criteria),
        }
      );

      if (response.data?.status === true) {
        if (response.data?.records?.length === 0) {
          this.setState({ exportNotification: true });
        }

        let kycExportData = response.data?.records;
        kycExportData = kycExportData.map((item, index) => ({
          id: index,
          ...item,
        }));

        if (kycExportData?.length > 0) {
          let csvData = [],
            headers = [
              "Startup Name",
              "Investment Vehicle",
              "Scheme Name",
              "Folio No.",
              "Folio + Scheme Name",
              "Startup (Round Name - Date)",
              "Deal Status",
              "Deal Live Date",
              "Behalf of",
              "User Name",
              "Applicant Name",
              // "Type",
              "User E-Mail",
              "KYC Status",
              "Intent",
              "Intent Date",
              "Consent Date",
              "Drawdown Date",
              "CA Date",
              "KYC Date",
              // "Drawdown Date",
              "Consent Status",
              // "Consent Received Date",
              "Consent Amount",
              "Drawdown Status",
              "Drawdown Amount",
              "Transaction Date",
              "Transaction Approved",
              "Investment Manager",
              "Deal Manager",
              "Syndicate Manager",
              "Syndicate Name",
            ];

          csvData.push(headers);
          // let sorted = this.sortedUsers(kycExportData || []);
          kycExportData.forEach((invt, i) => {
            csvData.push([
              `${invt.startup_name || ""}`,
              `${getInvestmentVehicle(invt?.investment_vehicle) || ""}`,
              `${invt?.scheme_name || ""}`,
              `${""}`,
              `${getFolioAndSchema(invt?.scheme_name)}`,
              `${invt.startup_name} (${invt.round_name} - ${moment(
                invt?.deal_date
              ).format("DD-MMM-YYYY")})`,
              `${invt?.deal_status}`,
              `${invt?.deal_live_date}`,
              `${invt?.relation} - ${invt?.applicant_name}`,
              `${invt?.user_name}`,
              `${invt?.applicant_name}`,
              // `${invt?.type}`,
              `${invt?.email}`,
              `${getKYCStatus(invt?.kyc_status)}`,
              `${getIntentAmount(invt)}`,
              `${moment(invt?.createdAt).format("DD-MMM-YYYY")}`,
              // `${
              //   ["", null, undefined].includes(invt?.consent_sent_date)
              //     ? ""
              //     : moment(invt?.consent_sent_date).format("DD-MMM-YYYY")
              // }`,
              `${
                ["", null, undefined].includes(invt?.consent_received_date)
                  ? ""
                  : moment(invt?.consent_received_date).format("DD-MMM-YYYY")
              }`,
              `${
                ["", null, undefined].includes(invt?.drawdown_sent_date)
                  ? ""
                  : moment(invt?.drawdown_sent_date).format("DD-MMM-YYYY")
              }`,
              `${
                ["", null, undefined].includes(invt?.ca_completed_on)
                  ? ""
                  : moment(invt?.ca_completed_on).format("DD-MMM-YYYY")
              }`,
              `${
                ["", null, undefined].includes(invt?.kyc_approved_on)
                  ? ""
                  : moment(invt?.kyc_approved_on).format("DD-MMM-YYYY")
              }`,
              `${getConsentStatus(invt)}`,
              `${getConsentAmount(invt)}`,
              `${getDrawdownStatus(invt)}`,
              `${getDrawdownAmount(invt)}`,
              `${getTransactionDate(invt?.pastinvestment)}`,
              `${getTransactionApproveStatus(invt?.pastinvestment)}`,
              `${getManagerName(invt?.investment_manager_detail)}`,
              `${getManagerName(invt?.deal_manager)}`,
              `${getManagerName(invt?.syndicate_manager_detail)}`,
              `${getSaasName(invt?.saasName)}`,
            ]);
          });

          this.setState({
            ...this.state,
            formedCSVData: csvData,
            exportLoading: false,
          });

          this.setState({
            ...this.state,
            formedCSVData: [],
          });
        } else {
          this.setState({
            ...this.state,
            formedCSVData: [],
            exportLoading: false,
          });
        }
      }
    } catch (error) {
      console.log("Error:", error);
      this.setState({
        ...this.state,
        formedCSVData: [],
        exportLoading: false,
      });
    }
  };

  generateCsvDataForBulkExport = async () => {
    try {
      this.setState({
        ...this.state,
        exportBulkLoading: true,
      });

      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api-v2/cumulative-data`
      );

      if (response.data?.status === true) {
        let kycExportData = response.data?.records;
        kycExportData = kycExportData.map((item, index) => ({
          id: index,
          ...item,
        }));

        if (kycExportData?.length > 0) {
          let csvData = [],
            headers = [
              "Startup Name",
              "Investment Vehicle",
              "Scheme Name",
              "Folio No.",
              "Folio + Scheme Name",
              "Startup (Round Name - Date)",
              "Deal Status",
              "Deal Live Date",
              "Behalf of",
              "User Name",
              "Applicant Name",
              // "Type",
              "User E-Mail",
              "User Id",
              "KYC Status",
              "Intent",
              "Intent Type",
              "Intent Date",
              "Consent Status",
              "Consent Date",
              // "Consent Received Date",
              // "Consent Amount",
              // "Drawdown Status",
              // "Drawdown Amount",
              // "Transaction Date",
              // "Transaction Approved",
              // "Investment Manager",
              // "Deal Manager",
              "Syndicate Manager",
              "Syndicate Name",
            ];

          csvData.push(headers);
          // let sorted = this.sortedUsers(kycExportData || []);
          kycExportData.forEach((invt, i) => {
            csvData.push([
              `${invt.startup_name || ""}`,
              `${getInvestmentVehicle(invt?.investment_vehicle) || ""}`,
              `${invt?.scheme_name || ""}`,
              `${""}`,
              `${getFolioAndSchema(invt?.scheme_name)}`,
              `${invt.startup_name} (${invt.round_name} - ${moment(
                invt?.deal_date
              ).format("DD-MMM-YYYY")})`,
              `${invt?.deal_status}`,
              `${invt?.deal_live_date}`,
              `${invt?.relation} - ${invt?.applicant_name}`,
              `${invt?.user_name}`,
              `${invt?.applicant_name}`,
              // `${invt?.type}`,
              `${invt?.email}`,
              `${invt?.user_id}`,
              `${getKYCStatus(invt?.kyc_status)}`,
              `${getIntentAmount(invt)}`,
              `${invt?.intentType}`,
              `${moment(invt?.createdAt).format("DD-MMM-YYYY")}`,
              `${getConsentStatus(invt)}`,
              // `${invt?.consent_sent_date}`,
              `${invt?.consent_sent_date}`,
              // `${getConsentAmount(invt)}`,
              // `${getDrawdownStatus(invt)}`,
              // `${getDrawdownAmount(invt)}`,
              // `${getTransactionDate(invt?.pastinvestment)}`,
              // `${getTransactionApproveStatus(invt?.pastinvestment)}`,
              // `${getManagerName(invt?.investment_manager_detail)}`,
              // `${getManagerName(invt?.deal_manager)}`,
              `${getManagerName(invt?.syndicate_manager_detail)}`,
              `${getSaasName(invt?.saasName)}`,
            ]);
          });

          this.setState({
            ...this.state,
            formedBulkCSVData: csvData,
            exportBulkLoading: false,
          });

          this.setState({
            ...this.state,
            formedBulkCSVData: [],
          });
        } else {
          this.setState({
            ...this.state,
            formedBulkCSVData: [],
            exportBulkLoading: false,
          });
        }
      }
    } catch (error) {
      console.log("Error:", error);
      this.setState({
        ...this.state,
        formedBulkCSVData: [],
        exportBulkLoading: false,
      });
    }
  };

  /* Insert,Update Delete data */
  render() {
    const { appliedFunding, error, dataSize } = this.props;
    const { isEdit } = this.state;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: dataSize, // replace later with size(appliedFunding),
      custom: true,
    };

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        {size > 0 ? (
          <>
            Showing {from} to {to} of {size}
          </>
        ) : (
          <>No Data</>
        )}
      </span>
    );

    const options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      sizePerPage: 10,
      paginationTotalRenderer: customTotal,
      totalSize: this.props.dataSize,
      disablePageTitle: false,
    };

    const defaultSorted = [
      {
        dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const defaultValues = {
      current_status: !isEmpty(this.state.appliedFunding.current_status)
        ? this.state.appliedFunding.current_status
        : "false",
    };

    let listColumns = [
      {
        text: "id",
        dataField: "id",
        sort: true,
        hidden: true,
        formatter: (cellContent, appliedFunding) =>
          appliedFunding.applyforfundings.id,
      },
      {
        text: "Startup Logo",
        dataField: "startup_logo",
        sort: false,
        formatter: (cellContent, appliedFunding) => (
          <>
            {appliedFunding?.applyforfundings?.startup_id?.basic_information
              ?.startup_logo && (
              <img
                src={profilePicture(
                  appliedFunding?.applyforfundings?.startup_id
                    ?.basic_information?.startup_logo
                )}
                alt="profile"
                className="rounded"
                width="50"
                height="50"
              />
            )}
          </>
        ),
        // filterValue: (cell, row) => (row.user.firstname + row.user.lastname),
      },

      {
        text: "Deal",
        dataField: "startupAndRound",
        sort: false,
        filter: textFilter(),
        formatter: (cellContent, appliedFunding) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link
                className="text-black text-capitalize"
                to={`/applied-funding-details/${appliedFunding.applyforfundings?._id}`}
              >
                {appliedFunding?.applyforfundings?.startup_id?.basic_information
                  ?.startup_name +
                  " (" +
                  appliedFunding?.applyforfundings?.funding_information
                    ?.funding_round +
                  ")"}{" "}
                [
                {!(
                  appliedFunding?.applyforfundings?.funding_information
                    ?.deal_date === undefined ||
                  appliedFunding?.applyforfundings?.funding_information
                    ?.deal_date === "undefined"
                )
                  ? moment(
                      appliedFunding?.applyforfundings?.funding_information
                        ?.deal_date
                    ).format("MMM YYYY")
                  : "-"}
                ]
              </Link>
            </h5>
          </>
        ),
      },

      {
        text: "Domain Expert",
        // dataField: "saas_name",
        dataField: "saas_name",
        sort: false,
        hidden: JSON.parse(localStorage.getItem("user_info"))?.is_saas_parent
          ? true
          : false,
        formatter: (cellContent, appliedFunding) => {
          let { userDetailsObj } = appliedFunding;
          let saas_name = "N/A";
          if (userDetailsObj) {
            saas_name = userDetailsObj?.saas_name;
          }
          //console.log(appliedFunding);
          return saas_name;
        },

        filter:
          this.state.roleWiseAccess?.is_super_admin ||
          this.state.roleWiseAccess?.show_all_saas_data
            ? selectFilter({
                options: this.state.saas_name_list.sort((a, b) => {
                  if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                    return -1;
                  }
                  if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                }),
                delay: 1500,
              })
            : false,
      },
      // {
      //   dataField: "email",
      //   text: "Email",
      //   sort: true,
      //   formatter: (cell, row, rowIndex, appliedFunding) => row.user.email,
      //   filterValue: (cell, row) => row.user?.email,
      //   filter: textFilter(),
      // },

      // {
      //   dataField: "applyforfundings.funding_information.funding_round",
      //   text: "Startup Rounds",
      //   sort: true,
      //   filterValue: (cell, row) =>
      //     row.applyforfundings?.funding_information?.funding_round,
      //   formatter: (cell, row, rowIndex, appliedFunding) => (
      //     <>{row.applyforfundings.funding_information.funding_round}</>
      //   ),
      //   filter: textFilter(),
      // },
      {
        dataField: "current_status",
        text: "Status",
        sort: false,
        filterValue: (cell, row) => row.applyforfundings?.current_status,
        formatter: (cell, row, rowIndex, appliedFunding) => (
          <>
            <p>{`${row.applyforfundings.current_status}`}</p>
            <p style={{ marginTop: "-10px" }}>
              {`(${
                moment(row?.applyforfundings?.deal_live_date).format(
                  "DD-MMM-YYYY"
                ) || ""
              })`}
            </p>
          </>
        ),
        filter:
          this.state.roleWiseAccess?.is_super_admin ||
          this.state.roleWiseAccess?.show_all_saas_data ||
          JSON.parse(localStorage.getItem("user_info"))?.is_saas_parent
            ? selectFilter({
                options: this.state.current_status_list.sort((a, b) => {
                  if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                    return -1;
                  }
                  if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                }),
                delay: 1500,
              })
            : false,
      },

      {
        dataField: "committed_amount",
        text: "KYC Status",
        formatter: (cell, row, rowIndex, appliedFunding) => {
          let investment_report = row?.allAssessmentReport?.investment_report;
          let count = investment_report?.length;

          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              row?.applyforfundings?.fund_type_id?.toString()
          );

          let maybe_totalkyc_count = row?.maybe_totalkyc_count;
          let maybe_ishared_oi_count = row?.maybe_ishared_oi_count;
          let maybe_onboarding_approved_count =
            row?.maybe_onboarding_approved_count;
          let maybe_onboarding_unapp_pend_rej_count =
            row?.maybe_onboarding_unapp_pend_rej_count;
          maybe_onboarding_unapp_pend_rej_count =
            maybe_onboarding_unapp_pend_rej_count + maybe_ishared_oi_count;
          let maybe_onboarding_drop_count = row?.maybe_onboarding_drop_count;

          let investor_count = 0;
          let ishared_oi_count = row?.ishared_oi_count;
          let ishared_oi_sum = row?.ishared_oi_sum;

          let onboarding_approved_count = row?.onboarding_approved_count;
          let onboarding_approved_sum = row?.onboarding_approved_sum;
          onboarding_approved_sum = onboarding_approved_sum
            ? toCommaFormat(onboarding_approved_sum)
            : 0;

          let onboarding_unapp_pend_rej_count =
            row?.onboarding_unapp_pend_rej_count;
          onboarding_unapp_pend_rej_count =
            onboarding_unapp_pend_rej_count + ishared_oi_count;
          let onboarding_unapp_pend_rej_sum =
            row?.onboarding_unapp_pend_rej_sum;
          onboarding_unapp_pend_rej_sum =
            onboarding_unapp_pend_rej_sum + ishared_oi_sum;

          onboarding_unapp_pend_rej_sum = onboarding_unapp_pend_rej_sum
            ? toCommaFormat(onboarding_unapp_pend_rej_sum)
            : 0;
          let onboarding_drop_count = row?.onboarding_drop_count;
          let onboarding_drop_sum = row?.onboarding_drop_sum;
          onboarding_drop_sum = onboarding_drop_sum
            ? toCommaFormat(onboarding_drop_sum)
            : 0;

          let committed_amount_sum = 0;
          let committed_amount_sum_not_app = row?.committed_amount_sum_not_app;

          if (count > 0) {
            if (intent_cur?.currency == "USD") {
              for (let i = 0; i < count; i++) {
                if (investment_report[i]?.areYouIntrested == "Yes") {
                  if (
                    parseInt(investment_report[i]?.consent_amount_global) > 0
                  ) {
                    investor_count++;
                    committed_amount_sum =
                      parseInt(committed_amount_sum) +
                      parseInt(investment_report[i]?.consent_amount_global);
                  } else {
                    if (
                      parseInt(investment_report[i]?.dealCommittedAmount) > 0
                    ) {
                      investor_count++;
                      committed_amount_sum =
                        parseInt(committed_amount_sum) +
                        parseInt(investment_report[i]?.dealCommittedAmount);
                    }
                  }
                }
              }
            } else {
              for (let i = 0; i < count; i++) {
                if (investment_report[i]?.areYouIntrested == "Yes") {
                  if (parseInt(investment_report[i]?.consent_amount) > 0) {
                    investor_count++;
                    committed_amount_sum =
                      parseInt(committed_amount_sum) +
                      parseInt(investment_report[i]?.consent_amount);
                  } else {
                    if (parseInt(investment_report[i]?.committed_amount) > 0) {
                      investor_count++;
                      committed_amount_sum =
                        parseInt(committed_amount_sum) +
                        parseInt(investment_report[i]?.committed_amount);
                    }
                  }
                }
              }
            }
          }
          committed_amount_sum = committed_amount_sum
            ? toCommaFormat(committed_amount_sum)
            : 0;
          count = count ? count : 0;
          return (
            <>
              <p style={{ color: "#000000" }}>
                {investor_count}{" "}
                {maybe_totalkyc_count > 0 ? `(${maybe_totalkyc_count})` : ""} /{" "}
                {intent_cur?.symbol}
                {committed_amount_sum}
              </p>
              <p style={{ color: "#008000" }}>
                {onboarding_approved_count}{" "}
                {maybe_onboarding_approved_count > 0
                  ? `(${maybe_onboarding_approved_count})`
                  : ""}{" "}
                / {intent_cur?.symbol}
                {onboarding_approved_sum}
              </p>
              <p style={{ color: "#808080" }}>
                {onboarding_unapp_pend_rej_count}{" "}
                {maybe_onboarding_unapp_pend_rej_count > 0
                  ? `(${maybe_onboarding_unapp_pend_rej_count})`
                  : ""}{" "}
                / {intent_cur?.symbol}
                {onboarding_unapp_pend_rej_sum}
              </p>

              <p style={{ color: "#ff0000" }}>
                {onboarding_drop_count}{" "}
                {maybe_onboarding_drop_count > 0
                  ? `(${maybe_onboarding_drop_count})`
                  : ""}{" "}
                / {intent_cur?.symbol}
                {onboarding_drop_sum}
              </p>
            </>
          );
        },
      },

      {
        dataField: "ca_shared_count",
        text: "C.A. Status",
        formatter: (cell, row, rowIndex, appliedFunding) => {
          let ca_shared_count = row?.ca_shared_count;
          let ca_shared_intentsum = row?.ca_shared_intentsum;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              row?.applyforfundings?.fund_type_id?.toString()
          );
          ca_shared_intentsum = ca_shared_intentsum
            ? toCommaFormat(ca_shared_intentsum)
            : 0;

          let ca_signedbyall_count = row?.ca_signedbyall_count;
          let ca_signedbyall_consentsum = row?.ca_signedbyall_consentsum;
          ca_signedbyall_consentsum = ca_signedbyall_consentsum
            ? toCommaFormat(ca_signedbyall_consentsum)
            : 0;
          let ca_shared_pend_trustee_count = row?.ca_shared_pend_trustee_count;
          let ca_shared_pend_trustee_sum = row?.ca_shared_pend_trustee_sum;
          ca_shared_pend_trustee_sum = ca_shared_pend_trustee_sum
            ? toCommaFormat(ca_shared_pend_trustee_sum)
            : 0;
          let ca_drop_count = row?.ca_drop_count;
          let ca_drop_intentsum = row?.ca_drop_intentsum;
          ca_drop_intentsum = ca_drop_intentsum
            ? toCommaFormat(ca_drop_intentsum)
            : 0;

          return (
            <>
              <p style={{ color: "#000000" }}>
                {ca_shared_count} / {intent_cur?.symbol}
                {ca_shared_intentsum}
              </p>
              <p style={{ color: "#008000" }}>
                {ca_signedbyall_count} / {intent_cur?.symbol}
                {ca_signedbyall_consentsum}
              </p>
              <p style={{ color: "#808080" }}>
                {ca_shared_pend_trustee_count} / {intent_cur?.symbol}
                {ca_shared_pend_trustee_sum}
              </p>

              <p style={{ color: "#ff0000" }}>
                {ca_drop_count} / {intent_cur?.symbol}
                {ca_drop_intentsum}
              </p>
            </>
          );
        },
      },

      {
        dataField: "terms_accept",
        text: "Consent",
        formatter: (cell, row, rowIndex, appliedFunding) => {
          let investment_report = row?.allAssessmentReport?.investment_report;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              row?.applyforfundings?.fund_type_id?.toString()
          );
          let count = investment_report?.length;
          let consent_amount_sum = 0;
          let usercount = 0;

          let consent_drop_status_count = 0;
          let consent_drop_sum = 0;

          let ca_signedbyall_count = row?.ca_signedbyall_count;

          let ca_signedbyall_consentsum = row?.ca_signedbyall_consentsum;
          ca_signedbyall_consentsum = ca_signedbyall_consentsum
            ? toCommaFormat(ca_signedbyall_consentsum)
            : 0;

          let ca_signedbyall_consent_recvd_count =
            row?.ca_signedbyall_consent_recvd_count;
          ca_signedbyall_consent_recvd_count =
            ca_signedbyall_consent_recvd_count
              ? ca_signedbyall_consent_recvd_count
              : 0;
          let ca_signedbyall_consent_recvd_sum =
            row?.ca_signedbyall_consent_recvd_sum;
          ca_signedbyall_consent_recvd_sum = ca_signedbyall_consent_recvd_sum
            ? toCommaFormat(ca_signedbyall_consent_recvd_sum)
            : 0;

          let ca_signedbyall_consent_notrecvd_count =
            row?.ca_signedbyall_consent_notrecvd_count;
          ca_signedbyall_consent_notrecvd_count =
            ca_signedbyall_consent_notrecvd_count
              ? ca_signedbyall_consent_notrecvd_count
              : 0;
          let ca_signedbyall_consent_notrecvd_sum =
            row?.ca_signedbyall_consent_notrecvd_sum;
          ca_signedbyall_consent_notrecvd_sum =
            ca_signedbyall_consent_notrecvd_sum
              ? toCommaFormat(ca_signedbyall_consent_notrecvd_sum)
              : 0;

          let ca_signedbyall_consent_dropped_count =
            row?.ca_signedbyall_consent_dropped_count;
          ca_signedbyall_consent_dropped_count =
            ca_signedbyall_consent_dropped_count
              ? ca_signedbyall_consent_dropped_count
              : 0;
          let ca_signedbyall_consent_dropped_sum =
            row?.ca_signedbyall_consent_dropped_sum;
          ca_signedbyall_consent_dropped_sum =
            ca_signedbyall_consent_dropped_sum
              ? toCommaFormat(ca_signedbyall_consent_dropped_sum)
              : 0;

          return (
            <>
              {row?.applyforfundings?.term_sheet_status ==
              "received_from_sebi" ? (
                <>
                  <p style={{ color: "#000000" }}>
                    {ca_signedbyall_count} / {intent_cur?.symbol}
                    {ca_signedbyall_consentsum}
                  </p>
                  <p style={{ color: "#008000" }}>
                    {ca_signedbyall_consent_recvd_count} / {intent_cur?.symbol}
                    {ca_signedbyall_consent_recvd_sum}
                  </p>
                  <p style={{ color: "#808080" }}>
                    {ca_signedbyall_consent_notrecvd_count} /{" "}
                    {intent_cur?.symbol}
                    {ca_signedbyall_consent_notrecvd_sum}
                  </p>

                  <p style={{ color: "#ff0000" }}>
                    {ca_signedbyall_consent_dropped_count} /{" "}
                    {intent_cur?.symbol}
                    {ca_signedbyall_consent_dropped_sum}
                  </p>
                </>
              ) : (
                <p style={{ color: "black" }}>Term Sheet WIP</p>
              )}
            </>
          );
        },
      },

      {
        dataField: "drawdown_status",
        text: "Drawdown",
        formatter: (cell, row, rowIndex, appliedFunding) => {
          let investment_report = row?.allAssessmentReport?.investment_report;
          let internal_funded_investors = row?.internal_funded_investors;
          let count = investment_report?.length;
          let drawdown_status = row?.applyforfundings?.drawdown_status;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              row?.applyforfundings?.fund_type_id?.toString()
          );

          let casignedbyall_consentrecvd_ddsent_count =
            row?.casignedbyall_consentrecvd_ddsent_count;
          casignedbyall_consentrecvd_ddsent_count =
            casignedbyall_consentrecvd_ddsent_count
              ? casignedbyall_consentrecvd_ddsent_count
              : 0;
          let casignedbyall_consentrecvd_ddsent_sum =
            row?.casignedbyall_consentrecvd_ddsent_sum;
          let casignedbyall_consentrecvd_ddsent_sum2 =
            row?.casignedbyall_consentrecvd_ddsent_sum;
          casignedbyall_consentrecvd_ddsent_sum2 =
            casignedbyall_consentrecvd_ddsent_sum2
              ? casignedbyall_consentrecvd_ddsent_sum2
              : 0;

          casignedbyall_consentrecvd_ddsent_sum =
            casignedbyall_consentrecvd_ddsent_sum
              ? toCommaFormat(casignedbyall_consentrecvd_ddsent_sum)
              : 0;

          let casignedbyall_ddsent_pfolio_amt_count =
            row?.casignedbyall_ddsent_pfolio_amt_count;
          casignedbyall_ddsent_pfolio_amt_count =
            casignedbyall_ddsent_pfolio_amt_count
              ? casignedbyall_ddsent_pfolio_amt_count
              : 0;
          let casignedbyall_ddsent_pfolio_amt_sum =
            row?.casignedbyall_ddsent_pfolio_amt_sum;
          casignedbyall_ddsent_pfolio_amt_sum =
            casignedbyall_ddsent_pfolio_amt_sum
              ? toCommaFormat(casignedbyall_ddsent_pfolio_amt_sum)
              : 0;

          let casignedbyall_ddsent_pfolio_notadded_count =
            row?.casignedbyall_ddsent_pfolio_notadded_count;
          casignedbyall_ddsent_pfolio_notadded_count =
            casignedbyall_ddsent_pfolio_notadded_count
              ? casignedbyall_ddsent_pfolio_notadded_count
              : 0;
          let casignedbyall_ddsent_pfolio_notadded_consent_sum =
            row?.casignedbyall_ddsent_pfolio_notadded_consent_sum;
          casignedbyall_ddsent_pfolio_notadded_consent_sum =
            casignedbyall_ddsent_pfolio_notadded_consent_sum
              ? toCommaFormat(casignedbyall_ddsent_pfolio_notadded_consent_sum)
              : 0;

          let casignedbyall_ddsent_drop_count =
            row?.casignedbyall_ddsent_drop_count;
          casignedbyall_ddsent_drop_count = casignedbyall_ddsent_drop_count
            ? casignedbyall_ddsent_drop_count
            : 0;
          let casignedbyall_ddsent_drop_sum =
            row?.casignedbyall_ddsent_drop_sum;
          casignedbyall_ddsent_drop_sum = casignedbyall_ddsent_drop_sum
            ? toCommaFormat(casignedbyall_ddsent_drop_sum)
            : 0;

          let min_drawdown_consent_cond = 0;
          if (intent_cur?.currency == "USD") {
            min_drawdown_consent_cond =
              row?.applyforfundings?.funding_information
                ?.min_consent_for_drawdown_usd;
          } else {
            min_drawdown_consent_cond =
              row?.applyforfundings?.funding_information?.min_drawdown_consent;
          }

          return (
            <>
              {/* {min_drawdown_consent_cond} */}
              {drawdown_status == "Drawdown Sent" ? (
                <>
                  <p style={{ color: "#000000" }}>
                    {casignedbyall_consentrecvd_ddsent_count} /{" "}
                    {intent_cur?.symbol}
                    {casignedbyall_consentrecvd_ddsent_sum}
                  </p>
                  <p style={{ color: "#008000" }}>
                    {casignedbyall_ddsent_pfolio_amt_count} /{" "}
                    {intent_cur?.symbol}
                    {casignedbyall_ddsent_pfolio_amt_sum}
                  </p>
                  <p style={{ color: "#808080" }}>
                    {casignedbyall_ddsent_pfolio_notadded_count} /{" "}
                    {intent_cur?.symbol}
                    {casignedbyall_ddsent_pfolio_notadded_consent_sum}
                  </p>

                  <p style={{ color: "#ff0000" }}>
                    {casignedbyall_ddsent_drop_count} / {intent_cur?.symbol}
                    {casignedbyall_ddsent_drop_sum}
                  </p>
                </>
              ) : (
                <p style={{ color: "#000000" }}>
                  Minimum consent needed {intent_cur?.symbol}{" "}
                  {toCommaFormat(min_drawdown_consent_cond)}
                </p>
              )}
            </>
          );
        },
      },

      {
        dataField: "menu",
        isDummyField: true,
        editable: false,
        hidden: this.state?.roleWiseAccess?.is_super_admin ? false : true,
        text: "",
        formatter: (cellContent, user) => {
          return (
            <div className="d-flex gap-3">
              {/* {this.state.roleWiseAccess?.fundingDealListViewEye ? (
                <Link
                  className="text-success"
                  to={`/applied-funding-details/${user?.applyforfundings?._id}`}
                >
                  <i className="mdi mdi-eye font-size-18" id="edittooltip"></i>
                </Link>
              ) : (
                ""
              )} */}

              {/* {this.state.roleWiseAccess?.fundingDealListInvestorGroups ? (
                <Label
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    let dealLive =
                      user?.applyforfundings?.status === "Open" &&
                      user?.applyforfundings?.current_status === "Deal Live";

                    if (!dealLive) {
                      return alert("Deal is not live");
                    }

                    return this.setState({
                      investorGroupModalOpen: true,
                      selectedDeal: user?.applyforfundings || {},
                    });
                  }}
                >
                  Investor Groups
                </Label>
              ) : (
                ""
              )} */}
              {this.state?.roleWiseAccess?.is_super_admin && (
                <Label
                  className="btn btn-sm btn-secondary"
                  onClick={async () => {
                    this.getDealHistory(user?.applyforfundings.deal_historys);
                    this.getDealChangeLogs(user?.applyforfundings?._id);
                    let { deal_historys } = user?.applyforfundings;
                    let new_deal_history = sortHistory(deal_historys);
                    return this.setState({
                      historyLogsModalOpen: true,
                      dealHistoryLog: new_deal_history || [],
                    });
                  }}
                >
                  Status Logs
                </Label>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Applied Funding List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <DataFetchLoader loading={this.state.loading} />
            <Row className="d-md-flex align-items-center">
              <Col md="4" lg="3">
                <h5 className="text-uppercase mb-0">Startup Rounds</h5>
              </Col>
              <Col md="8" lg="9">
                <div className="d-flex justify-content-end align-items-center gap-3">
                  <div className="color-diff">
                    <div className="item">
                      <span className="purplecol"></span> Opportunity
                    </div>
                    <div className="item">
                      <span className="greencol"></span> Approved
                    </div>
                    <div className="item">
                      <span className="browncol"></span> WIP
                    </div>
                    <div className="item">
                      <span className="redcol"></span> Dropped
                    </div>
                  </div>
                  <div style={{ minWidth: "200px" }}>
                    <div className="text-start">
                      <Label>Select Fund Type</Label>
                    </div>
                    <div className="mb-4 align-items-center">
                      <Select
                        placeholder={"Select Fund Type"}
                        options={this.state.fundTypeList?.sort((a, b) => {
                          if (
                            a?.label?.toLowerCase() < b?.label?.toLowerCase()
                          ) {
                            return -1;
                          }
                          if (
                            a?.label?.toLowerCase() > b?.label?.toLowerCase()
                          ) {
                            return 1;
                          }
                          return 0;
                        })}
                        onChange={e => this.handlefundType(e)}
                        value={
                          this.state.selectedFundType?._id
                            ? this.state.selectedFundType
                            : { value: "", label: "All" }
                        }
                      />
                    </div>
                  </div>

                  {this?.state?.roleWiseAccess?.[
                    "cummulativeStartupDataExportCSVButton"
                  ] && (
                    <>
                      <button
                        className="btn btn-success"
                        style={{ marginRight: "3px", maxWidth: "225px" }}
                        onClick={() => {
                          this.setState({
                            exportCSVPopup: true,
                          });
                        }}
                      >
                        Export Cummulative Startup Data CSV
                      </button>
                    </>
                  )}

                  {/* <>
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        if (!this.state?.exportBulkLoading) {
                          this.generateCsvDataForBulkExport();
                        }
                      }}
                    >
                      {this.state?.exportBulkLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i className="bx bx-export fs-4 mr-3"></i>
                      )}{" "}
                      Export Bulk Startup Data CSV
                    </button>
                    {this.state.formedBulkCSVData.length > 0 ? (
                      <CSVDownload
                        data={this.state.formedBulkCSVData}
                        target="_blank"
                      />
                    ) : null}
                  </> */}
                  
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <DataFetchLoader
                    loading={
                      this.state.roleWiseAccess?.users_list ? false : true
                    }
                  />
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={listColumns}
                      data={
                        this.state.curr_status === "Filter by status"
                          ? appliedFunding
                          : appliedFunding.filter(
                              e =>
                                e.applyforfundings.current_status ===
                                this.state.curr_status
                            )
                      }
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={listColumns}
                          data={
                            this.state.curr_status === "Filter by status"
                              ? appliedFunding
                              : appliedFunding.filter(
                                  e =>
                                    e.applyforfundings.current_status ===
                                    this.state.curr_status
                                )
                          }
                          // search={{
                          //     searchFormatted: true
                          // }}
                          serach
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "funding-deal table align-middle table-hover"
                                      }
                                      remote={{
                                        filter: true,
                                        pagination: true,
                                        sort: true,
                                      }}
                                      bordered={false}
                                      striped={true}
                                      responsive
                                      ref={this.node}
                                      filter={filterFactory()}
                                      onTableChange={this.onTableChange}
                                    />

                                    <Modal
                                      size="lg"
                                      isOpen={
                                        this.state.editFundingDealDetailModal
                                      }
                                      className={this.props.className}
                                      toggle={this.editFundingDealDetailsToggle}
                                    >
                                      <ModalHeader
                                        toggle={
                                          this.editFundingDealDetailsToggle
                                        }
                                        tag="h4"
                                      >
                                        {"Edit Funding deal Details"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <AvForm
                                          onSubmit={() =>
                                            this.updateEditFunctionDeal()
                                          }
                                        >
                                          <Row
                                            form
                                            className="edit-fundings-form"
                                          >
                                            <Col className="col-12 mb-2">
                                              <label>Minimum Amount</label>
                                              <AvField
                                                type="number"
                                                className="form-control"
                                                name="minimum_amount"
                                                placeholder="Minimum Amount"
                                                value={
                                                  this.state.minimum_amount ||
                                                  ""
                                                }
                                                onChange={e =>
                                                  this.handleOnChangeEditFundingDeal(
                                                    e
                                                  )
                                                }
                                                errorMessage="Required Field"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </Col>
                                            <Col className="col-12 mb-2">
                                              <label>
                                                How much fund raising?
                                              </label>
                                              <AvField
                                                type="number"
                                                className="form-control"
                                                name="how_much_fund_raising"
                                                placeholder="How much fund raising?"
                                                value={
                                                  this.state
                                                    .how_much_fund_raising || ""
                                                }
                                                onChange={e =>
                                                  this.handleOnChangeEditFundingDeal(
                                                    e
                                                  )
                                                }
                                                errorMessage="Required Field"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </Col>
                                            <Col className="col-12 mb-2">
                                              <label>Current valuation</label>
                                              <AvField
                                                type="number"
                                                className="form-control"
                                                name="current_valuation"
                                                placeholder="Current valuation"
                                                value={
                                                  this.state
                                                    .current_valuation || ""
                                                }
                                                onChange={e =>
                                                  this.handleOnChangeEditFundingDeal(
                                                    e
                                                  )
                                                }
                                                errorMessage="Required Field"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </Col>
                                            <Col className="col-12 mb-2">
                                              <label>Valuation Type</label>
                                              <p className="form-control">
                                                {this.state?.appliedFunding
                                                  ?.evaluation_report_primary_information
                                                  ?.valuation_type ===
                                                "preMoney"
                                                  ? "Pre-Money"
                                                  : "Post-Money"}
                                              </p>
                                            </Col>
                                            <Col className="col-12 mb-2">
                                              <label>Actual valuation</label>
                                              <AvField
                                                type="number"
                                                className="form-control"
                                                name="actual_valuation"
                                                placeholder="Actual valuation"
                                                value={
                                                  this.state.actual_valuation ||
                                                  ""
                                                }
                                                onChange={e =>
                                                  this.handleOnChangeEditFundingDeal(
                                                    e
                                                  )
                                                }
                                                errorMessage="Required Field"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </Col>
                                            <Col className="col-12 mb-2">
                                              <button
                                                type="submit"
                                                className="btn btn-info"
                                              >
                                                Update
                                              </button>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </ModalBody>
                                    </Modal>

                                    <Modal
                                      size="lg"
                                      isOpen={this.state.modal}
                                      className={this.props.className}
                                      toggle={this.toggle}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {"View Applied Fundings"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <AvForm
                                          onValidSubmit={this.handleValidSubmit}
                                          model={defaultValues}
                                        >
                                          <Row form className="fundings-form">
                                            <Col className="col-12">
                                              <h4>Basic Information</h4>
                                              <div className="information-wrap">
                                                <span className="d-flex justify-content-between">
                                                  <h6>Company Leagal Name </h6>
                                                  {this.state?.appliedFunding
                                                    ?.basic_information
                                                    ? this.state?.appliedFunding
                                                        ?.basic_information[0]
                                                        ?.company_legal_name
                                                    : ""}
                                                </span>
                                                <span className="d-flex justify-content-between">
                                                  <h6>Company Tag Line </h6>
                                                  {this.state?.appliedFunding
                                                    ?.basic_information
                                                    ? this.state?.appliedFunding
                                                        ?.basic_information[0]
                                                        ?.company_tag_line
                                                    : ""}
                                                </span>
                                                <span className="d-flex justify-content-between">
                                                  <h6>Product description </h6>
                                                  {this.state?.appliedFunding
                                                    ?.basic_information
                                                    ? this.state?.appliedFunding
                                                        ?.basic_information[0]
                                                        ?.product_description
                                                    : ""}
                                                </span>
                                                <span className="d-flex justify-content-between">
                                                  <h6>Elevator pitch </h6>
                                                  {this.state?.appliedFunding
                                                    ?.basic_information
                                                    ? this.state?.appliedFunding
                                                        ?.basic_information[0]
                                                        ?.elevator_pitch
                                                    : ""}
                                                </span>
                                                <span className="d-flex justify-content-between">
                                                  <h6>Website </h6>
                                                  {this.state?.appliedFunding
                                                    ?.basic_information
                                                    ? this.state?.appliedFunding
                                                        ?.basic_information[0]
                                                        ?.website
                                                    : ""}
                                                </span>
                                                <span className="d-flex justify-content-between">
                                                  <h6>Product launched </h6>
                                                  {this.state?.appliedFunding
                                                    ?.basic_information
                                                    ? this.state?.appliedFunding
                                                        ?.basic_information[0]
                                                        ?.product_launched
                                                    : ""}
                                                </span>
                                              </div>
                                              {this.state?.appliedFunding
                                                ?.business_information
                                                ?.length ? (
                                                <>
                                                  <h4 className="mt-2">
                                                    Business Information
                                                  </h4>
                                                  <div className="information-wrap">
                                                    <span className="d-flex justify-content-between">
                                                      <h6>Industry Sector </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? (
                                                            this.state
                                                              ?.appliedFunding
                                                              ?.business_information[0]
                                                              ?.industry_sector ||
                                                            []
                                                          ).map(e => e)
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Bussiness Modal Category{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state?.appliedFunding?.business_information[0]?.business_model_category.map(
                                                            e => e
                                                          )
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>Type Of Sales </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state?.appliedFunding?.business_information[0]?.type_of_sales.map(
                                                            e => e
                                                          )
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Location Of Target
                                                        Customer{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state?.appliedFunding?.business_information[0]?.location_of_target_customer.map(
                                                            e => e
                                                          )
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>Bussiness Model </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.business_model
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Trends in revnue number{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.trends_in_revenue_number
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Current go to market
                                                        channel{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.current_go_to_market_channel
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Usecase and potential
                                                        target customers{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.use_case_and_potential_target_customers
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>Competitiors </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.competitors
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Your stand out
                                                        competitiors{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.your_stand_out_with_competitors
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Current monthly burn
                                                        rate{" "}
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.current_monthly_burn_rate
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Current cash balance
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.current_cash_balance
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Headquarter location
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.headquarter_location
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Company Incorporation
                                                        location
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.company_incorporation_location
                                                        : ""}
                                                    </span>
                                                    <span className="d-flex justify-content-between">
                                                      <h6>
                                                        Raised any outside
                                                        funding
                                                      </h6>
                                                      {this.state
                                                        ?.appliedFunding
                                                        ?.business_information
                                                        ? this.state
                                                            ?.appliedFunding
                                                            ?.business_information[0]
                                                            ?.raised_any_outside_funding
                                                        : ""}
                                                    </span>
                                                    <div className="information-wrap mt-4 mb-4">
                                                      <div className="d-flex justify-content-between mb-4">
                                                        <h4 className="">
                                                          Evaluation Report
                                                          Details
                                                        </h4>
                                                        <button
                                                          className="btn btn-info"
                                                          onClick={
                                                            this
                                                              .editFundingDealDetailsToggle
                                                          }
                                                        >
                                                          Edit
                                                        </button>
                                                      </div>
                                                      <span className="d-flex justify-content-between">
                                                        <h6>Minimum Amount</h6>
                                                        {/* PHP */}
                                                        <p>
                                                          {
                                                            this.state
                                                              ?.minimum_amount
                                                          }
                                                        </p>
                                                      </span>
                                                      <span className="d-flex justify-content-between">
                                                        <h6>
                                                          How much fund raising?
                                                        </h6>
                                                        <p>
                                                          {
                                                            this.state
                                                              ?.how_much_fund_raising
                                                          }
                                                        </p>
                                                      </span>
                                                      <span className="d-flex justify-content-between">
                                                        <h6>
                                                          Current valuation
                                                        </h6>
                                                        <p>
                                                          {
                                                            this.state
                                                              ?.current_valuation
                                                          }
                                                        </p>
                                                      </span>
                                                      <span className="d-flex justify-content-between">
                                                        <h6>Valuation Type</h6>
                                                        <p>
                                                          {this.state
                                                            ?.appliedFunding
                                                            ?.evaluation_report_primary_information &&
                                                          this.state
                                                            ?.appliedFunding
                                                            ?.evaluation_report_primary_information
                                                            ?.valuation_type ===
                                                            "preMoney"
                                                            ? "Pre Money"
                                                            : "Post Money"}
                                                        </p>
                                                      </span>
                                                      <span className="d-flex justify-content-between">
                                                        <h6>
                                                          Actual valuation
                                                        </h6>
                                                        {/* <p>
                                                                                                                              {
                                                                                                                                  (this.state?.appliedFunding?.evaluation_report_primary_information?.valuation_type==='preMoney') ?
                                                                                                                                      toInteger(this.state?.appliedFunding?.evaluation_report_primary_information?.current_valuation) + toInteger(this.state?.appliedFunding?.evaluation_report_primary_information?.how_much_fund_raising)
                                                                                                                                      :
                                                                                                                                      toInteger(this.state?.appliedFunding?.evaluation_report_primary_information?.current_valuation)
                                                                                                                              }
                                                                                                                          </p> */}
                                                        <p>
                                                          {
                                                            this.state
                                                              ?.actual_valuation
                                                          }
                                                        </p>
                                                      </span>
                                                      {/* <span className="d-flex justify-content-between"><h6>Post money valuation</h6>
                                                                                                                      {this.state?.appliedFunding?.business_information ? this.state?.appliedFunding?.business_information[0]?.post_money_valuation : ""}
                                                                                                                  </span> */}
                                                    </div>

                                                    <h4 className="mt-4">
                                                      Last six month revenue
                                                    </h4>
                                                    <div className="information-wrap">
                                                      {this.state.revenue_last_six_month.map(
                                                        (revenueDetails, i) => {
                                                          return (
                                                            <span
                                                              key={i}
                                                              className="d-flex justify-content-between"
                                                            >
                                                              <h6>
                                                                {
                                                                  revenueDetails.field
                                                                }
                                                              </h6>
                                                              {
                                                                revenueDetails.value
                                                              }
                                                            </span>
                                                          );
                                                        }
                                                      )}
                                                    </div>

                                                    <h4 className="mt-4">
                                                      Total user in last six
                                                      month
                                                    </h4>
                                                    <div className="information-wrap">
                                                      {this.state.users_last_six_month.map(
                                                        (userDetails, i) => {
                                                          return (
                                                            <span
                                                              key={i}
                                                              className="d-flex justify-content-between"
                                                            >
                                                              <h6>
                                                                {
                                                                  userDetails.field
                                                                }
                                                              </h6>
                                                              {
                                                                userDetails.value
                                                              }
                                                            </span>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                    <h4 className="mt-4">
                                                      Customers List
                                                    </h4>
                                                    <Table bordered responsive>
                                                      <thead>
                                                        <tr>
                                                          <th>
                                                            Customers Name
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state
                                                          ?.appliedFunding
                                                          ?.business_information &&
                                                        this.state
                                                          ?.appliedFunding
                                                          ?.business_information[0]
                                                          ?.customers_list
                                                          ?.length
                                                          ? this.state?.appliedFunding?.business_information[0]?.customers_list.map(
                                                              (e, ind) => (
                                                                <tr key={ind}>
                                                                  <td>
                                                                    {e?.customer_name ||
                                                                      ""}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )
                                                          : null}
                                                      </tbody>
                                                    </Table>
                                                    <h4 className="mt-4">
                                                      Total fund Raised
                                                    </h4>
                                                    <Table bordered responsive>
                                                      <thead>
                                                        <tr>
                                                          <th>Investor name</th>
                                                          <th>Funding round</th>
                                                          <th>Amount</th>
                                                          <th>
                                                            As on month year
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state
                                                          ?.appliedFunding
                                                          ?.business_information &&
                                                        this.state
                                                          ?.appliedFunding
                                                          ?.business_information[0]
                                                          ?.customers_list
                                                          ?.length
                                                          ? this.state?.appliedFunding?.business_information[0]?.total_fund_raised.map(
                                                              (e, ind) => (
                                                                <tr key={ind}>
                                                                  <td>
                                                                    {e?.investor_name ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.funding_round ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.amount ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.as_on_month_year ||
                                                                      ""}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )
                                                          : null}
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                </>
                                              ) : null}

                                              {this.state?.appliedFunding
                                                ?.team_information?.length ? (
                                                <>
                                                  <h4 className="mt-2">
                                                    Team Information
                                                  </h4>
                                                  <div className="information-wrap">
                                                    <h4 className="mt-4">
                                                      Cofounders
                                                    </h4>
                                                    <Table bordered responsive>
                                                      <thead>
                                                        <tr>
                                                          <th>Name</th>
                                                          <th>Email</th>
                                                          <th>Linkedin url</th>
                                                          <th>
                                                            Mobile country code
                                                          </th>
                                                          <th>Mobile number</th>
                                                          <th>Country</th>
                                                          <th>City</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state
                                                          ?.appliedFunding
                                                          ?.team_information &&
                                                        this.state
                                                          ?.appliedFunding
                                                          ?.team_information[0]
                                                          ?.cofounder?.length
                                                          ? this.state?.appliedFunding?.team_information[0]?.cofounder.map(
                                                              (e, ind) => (
                                                                <tr key={ind}>
                                                                  <td>
                                                                    {e?.cofounder_name ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {" "}
                                                                    {e?.cofounder_email ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    <a
                                                                      href={
                                                                        e.cofounder_linkedin_url
                                                                      }
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                    >
                                                                      {e?.cofounder_linkedin_url ||
                                                                        ""}
                                                                    </a>
                                                                  </td>
                                                                  <td>
                                                                    {e?.cofounder_mobile_country_code ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.cofounder_mobile_number ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.cofounder_country ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.cofounder_city ||
                                                                      ""}
                                                                  </td>{" "}
                                                                </tr>
                                                              )
                                                            )
                                                          : null}
                                                      </tbody>
                                                    </Table>
                                                    <h4 className="mt-4">
                                                      Funding team based in
                                                    </h4>
                                                    <Table bordered responsive>
                                                      <thead>
                                                        <tr>
                                                          <th>
                                                            Cofounder name
                                                          </th>
                                                          <th>Country</th>
                                                          <th>City</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state
                                                          ?.appliedFunding
                                                          ?.team_information &&
                                                        this.state
                                                          ?.appliedFunding
                                                          ?.team_information[0]
                                                          ?.founding_team_based_in
                                                          ?.length
                                                          ? this.state?.appliedFunding?.team_information[0]?.founding_team_based_in.map(
                                                              (e, ind) => (
                                                                <tr key={ind}>
                                                                  <td>
                                                                    {e?.cofounder_name ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.cofounder_country ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {e?.cofounder_city ||
                                                                      ""}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )
                                                          : null}
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                </>
                                              ) : null}

                                              {this.state?.appliedFunding
                                                ?.product_video?.length ? (
                                                <>
                                                  <h4 className="mt-2">
                                                    Product Video Information
                                                  </h4>
                                                  <div className="information-wrap">
                                                    <Table bordered responsive>
                                                      <thead>
                                                        <tr>
                                                          <th>Title</th>
                                                          <th>Video Link</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state
                                                          ?.appliedFunding
                                                          ?.product_video &&
                                                        this.state
                                                          ?.appliedFunding
                                                          ?.product_video.length
                                                          ? this.state?.appliedFunding?.product_video.map(
                                                              (e, ind) => (
                                                                <tr key={ind}>
                                                                  <td>
                                                                    {e?.title ||
                                                                      ""}
                                                                  </td>
                                                                  <td>
                                                                    {" "}
                                                                    <a
                                                                      href={
                                                                        e.video_link
                                                                      }
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                    >
                                                                      {e?.video_link ||
                                                                        ""}
                                                                    </a>
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )
                                                          : null}
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                </>
                                              ) : null}

                                              {this.state?.appliedFunding
                                                ?.financial_information
                                                ?.length ? (
                                                <>
                                                  <h4 className="mt-2">
                                                    Financial Information
                                                  </h4>
                                                  <div className="information-wrap">
                                                    <h4 className="mt-2">
                                                      Documents
                                                    </h4>
                                                    <Table bordered responsive>
                                                      <thead>
                                                        <tr>
                                                          <th>
                                                            Document Title
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state
                                                          ?.appliedFunding
                                                          ?.financial_information &&
                                                        this.state
                                                          ?.appliedFunding
                                                          ?.financial_information[0]
                                                          ?.more_documents
                                                          .length
                                                          ? this.state?.appliedFunding?.financial_information[0]?.more_documents.map(
                                                              (e, ind) => (
                                                                <tr key={ind}>
                                                                  <td>
                                                                    {e?.doc_title ||
                                                                      ""}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )
                                                          : null}
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                </>
                                              ) : null}

                                              <div className="status-box">
                                                <label>Status</label>
                                                <AvRadioGroup
                                                  name="current_status"
                                                  required
                                                >
                                                  <AvRadio
                                                    label="Approved"
                                                    value="Approved"
                                                  />
                                                  <AvRadio
                                                    label="Reject"
                                                    value="Declined"
                                                  />
                                                  <AvRadio
                                                    label="Waitlisted"
                                                    value="Waitlisted"
                                                  />
                                                </AvRadioGroup>
                                              </div>
                                              <div className="status-box">
                                                <label>Deal Manager</label>

                                                <Input
                                                  type="select"
                                                  name="dealManager"
                                                  onChange={this.handleOnChange}
                                                  id="dealManager"
                                                  value={
                                                    this.state.manager ||
                                                    this.props.manager
                                                  }
                                                >
                                                  {this.props.dealManagers.map(
                                                    (m, i) => (
                                                      <option
                                                        value={m._id}
                                                        key={i}
                                                      >
                                                        {m.name}
                                                      </option>
                                                    )
                                                  )}
                                                </Input>
                                              </div>
                                              <div className="coment-box">
                                                <AvField
                                                  name="comment"
                                                  label="Comment"
                                                  type="textarea"
                                                  errorMessage="Comment required"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row> */}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Investor Groups Modal */}
            <Modal
              size="xl"
              isOpen={this.state.investorGroupModalOpen}
              toggle={this.handleInvestorGroupsModal}
            >
              <ModalHeader toggle={this.handleInvestorGroupsModal} tag="h4">
                Add Investor Groups
              </ModalHeader>
              <ModalBody>
                <InvestorGroupsModal
                  investorGroupsModalOpen={this.state.investorGroupModalOpen}
                  handleInvestorGroupsModal={this.handleInvestorGroupsModal}
                  selectedDeal={this.state.selectedDeal}
                />
              </ModalBody>
            </Modal>
            <Modal
              size="xl"
              isOpen={this.state.historyLogsModalOpen}
              toggle={this.handleHistoryLogsModal}
            >
              <ModalHeader toggle={this.handleHistoryLogsModal} tag="h4">
                Deal History logs
              </ModalHeader>
              <ModalBody>
                <>
                  <Accordion defaultActiveKey={["0", "1"]}>
                    {this.state?.dealHistoryLog?.length > 0 && (
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Deal Status Changes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-responsive int_table">
                            <div className="react-bootstrap-table">
                              <Table>
                                <thead>
                                  <tr>
                                    <th className="small-text">Username</th>
                                    <th className="small-text">Email</th>
                                    <th className="small-text">Changed on</th>
                                    <th className="small-text">Field Name</th>
                                    <th className="small-text">Old Value</th>
                                    <th className="small-text">New Value</th>
                                    <th className="small-text">IP</th>
                                    <th className="small-text">OS</th>
                                    <th className="small-text">Location</th>
                                    <th className="small-text">Device</th>
                                    <th className="small-text">Browser</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state?.dealHistoryLog?.map(history => {
                                    return (
                                      <tr>
                                        <td>
                                          {this.state.historyUsername?.map(
                                            user => {
                                              if (
                                                history?.activated_user_id ===
                                                user?._id
                                              ) {
                                                return (
                                                  user?.firstname +
                                                  " " +
                                                  user?.lastname
                                                );
                                              }
                                            }
                                          )}
                                        </td>
                                        <td>
                                          {this.state.historyUsername?.map(
                                            user => {
                                              if (
                                                history?.activated_user_id ===
                                                user?._id
                                              ) {
                                                return user?.email;
                                              }
                                            }
                                          )}
                                        </td>
                                        <td>
                                          {moment(history?.date).format(
                                            "DD-MMM-YYYY h:mm A"
                                          )}
                                        </td>
                                        <td>Deal Status</td>
                                        <td>{history?.old_status}</td>
                                        <td>{history?.new_deal_status}</td>
                                        <td>{history?.userIP}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          {history?.userOS}
                                        </td>
                                        <td>{history?.userLocation}</td>
                                        <td>{history?.userDevice}</td>
                                        <td>{history?.userBrowser}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {this.state?.dealChangeLogs?.length > 0 && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Deal Value Changes</Accordion.Header>
                        <Accordion.Body>
                          <div className="table-responsive int_table">
                            <div className="react-bootstrap-table">
                              <Table>
                                <thead>
                                  <tr>
                                    <th className="small-text">Username</th>
                                    <th className="small-text">Email</th>
                                    {/* <th>Old Status</th>
                                    <th>New Status</th> */}
                                    <th className="small-text">Changed on</th>
                                    <th className="small-text">Field Name</th>
                                    <th className="small-text">Old Value</th>
                                    <th className="small-text">New Value</th>
                                    <th className="small-text">IP</th>
                                    <th className="small-text">OS</th>
                                    <th className="small-text">Location</th>
                                    <th className="small-text">Device</th>
                                    <th className="small-text">Browser</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state?.dealChangeLogs?.map(history => {
                                    return (
                                      <tr>
                                        <td>{history?.username}</td>
                                        <td>{history?.email}</td>
                                        <td>{history?.created_at}</td>
                                        <td>{history?.field_name}</td>
                                        <td>{history?.old_value}</td>
                                        <td>{history?.new_value}</td>
                                        <td>{history?.ip}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          {history?.os}
                                        </td>
                                        <td>{history?.location}</td>
                                        <td>{history?.device}</td>
                                        <td>{history?.browser}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </>
              </ModalBody>
            </Modal>
            <Modal
              size="md"
              isOpen={this.state.exportCSVPopup}
              toggle={this.handleExportCSVPopup}
            >
              <ModalHeader toggle={this.handleExportCSVPopup} tag="h4">
                Export Cumulative Startup Data
              </ModalHeader>
              <ModalBody>
                <>
                  <Container>
                    <Row>
                      <Form.Group className="col-md-6 col-lg-6 mb-4 floating-label float-select">
                        <Form.Label className="float-label">Month</Form.Label>
                        <Select
                          id="select"
                          placeholder="Select"
                          options={months}
                          styles={styles}
                          value={{
                            label: getLabel(this.state.month),
                            value: this.state.month,
                          }}
                          onChange={e =>
                            this.setState({
                              month: e.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 col-lg-6 mb-4 floating-label float-select">
                        <Form.Label className="float-label">Year</Form.Label>
                        <Select
                          id="select"
                          placeholder="Select"
                          options={years}
                          styles={styles}
                          value={{
                            label: this.state.year,
                            value: this.state.year,
                          }}
                          onChange={e =>
                            this.setState({
                              year: e.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                    {/* <hr /> */}
                    {/* <Row className="mt-3"> */}
                    <Form.Group tag="fieldset" className="borfieldset mt-3">
                      <h5 className="borfieldset-head">
                        <span>Choose Criteria</span>
                      </h5>
                      <Form.Group>
                        <Form.Label>
                          <Input
                            type="checkbox"
                            name="drawdown_sent_date"
                            checked={this.state.cumulative_export_criteria?.includes(
                              "drawdown_sent_date"
                            )}
                            onChange={e => {
                              if (
                                this.state.cumulative_export_criteria?.includes(
                                  "drawdown_sent_date"
                                )
                              ) {
                                let filteredChoices =
                                  this.state.cumulative_export_criteria?.filter(
                                    item => item !== "drawdown_sent_date"
                                  );
                                this.setState({
                                  cumulative_export_criteria: filteredChoices,
                                });
                              } else {
                                this.setState({
                                  cumulative_export_criteria: [
                                    ...this.state.cumulative_export_criteria,
                                    "drawdown_sent_date",
                                  ],
                                });
                              }
                            }}
                          />{" "}
                          Drawdown Sent Date
                        </Form.Label>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          <Input
                            type="checkbox"
                            name="consent_sent_date"
                            checked={this.state.cumulative_export_criteria?.includes(
                              "consent_sent_date"
                            )}
                            onChange={e => {
                              if (
                                this.state.cumulative_export_criteria?.includes(
                                  "consent_sent_date"
                                )
                              ) {
                                let filteredChoices =
                                  this.state.cumulative_export_criteria?.filter(
                                    item => item !== "consent_sent_date"
                                  );
                                this.setState({
                                  cumulative_export_criteria: filteredChoices,
                                });
                              } else {
                                this.setState({
                                  cumulative_export_criteria: [
                                    ...this.state.cumulative_export_criteria,
                                    "consent_sent_date",
                                  ],
                                });
                              }
                            }}
                          />{" "}
                          Consent Sent Date
                        </Form.Label>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          <Input
                            type="checkbox"
                            name="consent_received_date"
                            checked={this.state.cumulative_export_criteria?.includes(
                              "consent_received_date"
                            )}
                            onChange={e => {
                              if (
                                this.state.cumulative_export_criteria?.includes(
                                  "consent_received_date"
                                )
                              ) {
                                let filteredChoices =
                                  this.state.cumulative_export_criteria?.filter(
                                    item => item !== "consent_received_date"
                                  );
                                this.setState({
                                  cumulative_export_criteria: filteredChoices,
                                });
                              } else {
                                this.setState({
                                  cumulative_export_criteria: [
                                    ...this.state.cumulative_export_criteria,
                                    "consent_received_date",
                                  ],
                                });
                              }
                            }}
                          />{" "}
                          Consent Received Date
                        </Form.Label>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          <Input
                            type="checkbox"
                            name="kyc_approved_date"
                            checked={this.state.cumulative_export_criteria?.includes(
                              "kyc_approved_date"
                            )}
                            onChange={e => {
                              if (
                                this.state.cumulative_export_criteria?.includes(
                                  "kyc_approved_date"
                                )
                              ) {
                                let filteredChoices =
                                  this.state.cumulative_export_criteria?.filter(
                                    item => item !== "kyc_approved_date"
                                  );
                                this.setState({
                                  cumulative_export_criteria: filteredChoices,
                                });
                              } else {
                                this.setState({
                                  cumulative_export_criteria: [
                                    ...this.state.cumulative_export_criteria,
                                    "kyc_approved_date",
                                  ],
                                });
                              }
                            }}
                          />{" "}
                          KYC Approved Date
                        </Form.Label>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          <Input
                            type="checkbox"
                            name="ca_completed_date"
                            checked={this.state.cumulative_export_criteria?.includes(
                              "ca_completed_date"
                            )}
                            onChange={e => {
                              if (
                                this.state.cumulative_export_criteria?.includes(
                                  "ca_completed_date"
                                )
                              ) {
                                let filteredChoices =
                                  this.state.cumulative_export_criteria?.filter(
                                    item => item !== "ca_completed_date"
                                  );
                                this.setState({
                                  cumulative_export_criteria: filteredChoices,
                                });
                              } else {
                                this.setState({
                                  cumulative_export_criteria: [
                                    ...this.state.cumulative_export_criteria,
                                    "ca_completed_date",
                                  ],
                                });
                              }
                            }}
                          />{" "}
                          CA Completion Date
                        </Form.Label>
                      </Form.Group>
                    </Form.Group>
                    {this.state.exportNotification && (
                      <p
                        style={{
                          color: "tomato",
                          marginBottom: 0,
                          marginTop: "5px",
                        }}
                      >
                        No records found.
                      </p>
                    )}
                    {/* </Row> */}
                  </Container>
                </>
              </ModalBody>
              <ModalFooter>
                <div>
                  <button
                    className="btn btn-success"
                    style={{ marginRight: "3px" }}
                    onClick={() => {
                      if (!this.state?.exportLoading) {
                        this.generateCsvDataForAllSaaS();
                      }
                    }}
                  >
                    {this.state?.exportLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <i className="bx bx-export fs-4 mr-3"></i>
                    )}{" "}
                    Export
                  </button>
                  {this.state.formedCSVData.length > 0 ? (
                    <CSVDownload
                      data={this.state.formedCSVData}
                      target="_blank"
                    />
                  ) : null}
                </div>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AppliedFundingList.propTypes = {
  appliedFunding: PropTypes.array,
  dealManagers: PropTypes.array,
  className: PropTypes.any,
  onGetAppliedFundings: PropTypes.func,
  onGetDealManagers: PropTypes.func,
  error: PropTypes.string,
  manager: PropTypes.string,
  onUpdateStatus: PropTypes.func,
  onUpdateEditFundingDetails: PropTypes.func,
};

const mapStateToProps = ({ appliedFunding }) => {
  return {
    appliedFunding: appliedFunding.appliedFundings.data || [],
    dataSize: appliedFunding?.appliedFundings?.dataSize || [],
    dealManagers: appliedFunding.dealManagers || [],
    manager: appliedFunding.manager || "",
    error: appliedFunding.error,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAppliedFundings: param => dispatch(getAppliedFundings(param)),
  onGetDealManagers: () => dispatch(getDealManagers()),
  onUpdateStatus: statusPayload => dispatch(updateStatus(statusPayload)),
  onUpdateEditFundingDetails: payload =>
    dispatch(updateFundingDetails(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppliedFundingList));
