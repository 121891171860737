import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import {
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  ModalHeader,
  ModalBody,
  NavItem,
  Button,
  Modal,
  NavLink,
} from "reactstrap";
import Select from "react-select";
import { getAllUsersForReference } from "../../helpers/backend_helper";
import { AvForm } from "availity-reactstrap-validation";
import { getInvestorDetail } from "store/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import { indexOf } from "lodash-es";

class InvestorDetail extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      investor: {},
      user_data: {},
      modal: false,
      activeTab: "1",
      show: false,
      reference_id: "",
      options: [],
      referenceUserName: "",
      inputValue: "",
      defaultSelected: { value: null, label: "Select User" },
    };
    this.Toggleshow = this.Toggleshow.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { onGetInvestorDetail, investor } = this.props;
    onGetInvestorDetail(id);
    this.setState({ investor });
    const token = authHeader();

    axios
      .get(process.env.REACT_APP_API_URL + `/api/user/getdetail/${id}`, {
        headers: { Authorization: `${token}` },
      })
      .then(async response => {
        await this.setState({ user_data: response.data.user });
      })
      .catch(error => {
        // console.log(error);
      });

    getAllUsersForReference()
      .then(async response => {
        const data = response.data
          .map(user => {
            let { _id, firstname, lastname, email } = user;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            return { value: _id, label: temp };
          })
          .filter(data => !!data);
        let prevData = data.find(
          ({ value }) => value === investor?.reference_user_id?.[0]?._id
        );
        this.setState({ defaultSelected: prevData });
        this.setState({ options: data });
      })
      .catch(err => {
        this.setState({
          options: [],
          defaultSelected: { value: null, label: "Select User" },
        });
        // console.log(err);
      });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleChange = value => {
    this.setState({ reference_id: value.value });
  };

  Toggleshow() {
    this.setState({ show: !this.state.show });
  }

  async handleReferenceSubmit(id) {
    if (!id) return alert("Invalid Investor ID");

    try {
      const token = authHeader();

      let response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/admin/user/update/reference/${id}?reference_user_id=${this.state.reference_id}`,
        {
          headers: { Authorization: `${token}` },
        }
      );

      let data = response?.data;
      if (!data?.status) {
        console.log(data?.error);
        return alert(data?.message || "Error while updating reference name");
      }

      let user = data?.investor?.reference_user_id?.[0];
      if (user) {
        let { _id, firstname, lastname, email } = user;

        let temp = `${firstname} ${lastname}`.trim();

        if (temp) {
          temp = `${temp} ${email ? "- " + email : ""}`.trim();
        }
        this.setState({ referenceUserName: temp });
        this.setState({ defaultSelected: { value: _id, label: temp } });
      }

      this.setState({
        show: false,
        reference_id: "",
      });
      return alert(data?.message);
    } catch (error) {
      // console.log(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating reference name";
      return alert(message);
    }
  }

  render() {
    const { investor } = this.props;
    const defaultValues = {};
    let { options, referenceUserName } = this.state;

    if (!referenceUserName) {
      let user = investor?.reference_user_id?.[0];
      if (user) {
        let { firstname, lastname, email } = user;

        let temp = `${firstname} ${lastname}`.trim();

        if (temp) {
          referenceUserName = `${temp} ${email ? "- " + email : ""}`.trim();
        }
      }
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Investor Detail | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Investors" breadcrumbItem="Investor Detail" />

            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "1" && "active"}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Basic Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "2" && "active"}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    KYC Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "3" && "active"}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Education Details
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Modal
                    size="lg"
                    isOpen={this.state.show}
                    toggle={this.Toggleshow}
                  >
                    <ModalHeader toggle={this.Toggleshow} tag="h4">
                      {"Update Reference"}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm
                        onValidSubmit={() =>
                          this.handleReferenceSubmit(
                            this?.props?.match?.params?.id
                          )
                        }
                        model={defaultValues}
                      >
                        <Row form>
                          <Row>
                            <Col sm="12">
                              <div className="mb-3">
                                <Select
                                  name="referencename"
                                  label="Reference Name"
                                  errorMessage="Invalid reference name"
                                  validate={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  options={options}
                                  onChange={this.handleChange}
                                  value={this.state.defaultSelected}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                  <Row>
                    <Col sm="12">
                      <div className="information-wrap">
                        <span className="d-flex justify-content-between">
                          <h6>Name:</h6>
                          {`${this.state.user_data.firstname || ""} ${this.state.user_data.lastname || ""}` || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>LinkedIn Profile Link </h6>
                          {this.state.user_data.linkedin_profile_link && (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={
                                this.state.user_data.linkedin_profile_link || ""
                              }
                            >
                              LinkedIn Profile
                            </a>
                          )}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Mobile</h6>
                          {this.state.user_data.mobile_country_code || ""}{" "}
                          {this.state.user_data.mobile_number || ""}
                        </span>
                        <span className="d-flex justify-content-between align-items-center">
                          <h6>Reference Text</h6>
                          {investor.reference_name}
                        </span>
                        <span className="d-flex justify-content-between align-items-center">
                          <h6>Reference Name</h6>
                          <div className="d-flex gap-4">
                            <span>{referenceUserName}</span>

                            {/* <Button size="sm" onClick={this.Toggleshow}>
                              Update
                            </Button> */}
                          </div>
                        </span>
                        <span className="d-flex justify-content-between align-items-center">
                          <h6>Angel list Profile</h6>
                          {/* {investor.reference_name || ""} */}
                          <div className="d-flex gap-4">
                            <span>
                              {investor.angel_list_profile_url && (
                                <a
                                  href={investor.angel_list_profile_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Angel List Profile
                                </a>
                              )}
                            </span>
                          </div>
                        </span>
                        <span className="d-flex justify-content-between align-items-center">
                          <h6>Investment allocated for a year</h6>
                          {/* {investor.reference_name || ""} */}
                          <div className="d-flex gap-4">
                            <span>
                              {investor.investment_allocated_for_year}
                            </span>
                          </div>
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Gender </h6>
                          {investor?.gender || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Status </h6>
                          {this.state.user_data?.status || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Investor Type</h6>
                          {investor.investor_type || ""}
                        </span>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="information-wrap">
                        <span className="d-flex justify-content-between">
                          <h6>Email</h6>
                          {this.state.user_data.email || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Website</h6>
                          {this.state.user_data.website || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>City , State , Country </h6>
                          {investor.city || ""} {investor.state && " , "}{" "}
                          {investor.state || ""} {investor.country && " , "}{" "}
                          {investor.country || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Designation</h6>
                          {investor.designation || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Headline</h6>
                          {investor.headline || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Bio</h6>
                          {investor.bio || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Source</h6>
                          {this.state.user_data.source || ""}
                        </span>
                        <div className="information-wrap">
                          <span className="d-flex justify-content-between">
                            <h6>Past Investment</h6>
                            {investor.past_investment || ""}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="information-wrap">
                        <span className="d-flex justify-content-between">
                          <h6>Applicant Name</h6>
                          {investor?.kyc_details?.applicant_name || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Applicant PAN Number </h6>
                          {investor?.kyc_details?.applicant_pan_number || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Father / Husband Name </h6>
                          {investor?.kyc_details?.father_husband_name || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>GST Number</h6>
                          {investor?.kyc_details?.gst_number || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Investor Type</h6>
                          {investor?.kyc_details?.kyc_type || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Occupation</h6>
                          {investor?.kyc_details?.occupation || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Address</h6>
                          {investor?.kyc_details?.address || ""}
                        </span>
                        <span className="d-flex justify-content-between">
                          <h6>Open to mentoring opportunities?</h6>
                          {investor?.mentoring_opportunities || ""}
                        </span>

                        {investor?.mentoring_opportunities &&
                          investor?.mentoring_opportunities !== "undefined" ? (
                          <span className="d-flex justify-content-between">
                            <h6>Area of Mentorship </h6>
                            {investor?.area_of_mentorship?.map(mentor => (
                              <div key={mentor}>{mentor}</div>
                            )) || ""}
                          </span>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    {investor?.education?.map((edu, index) => (
                      <Col sm="12" key={index}>
                        <div className="information-wrap">
                          <span className="d-flex justify-content-between">
                            <h6>Degree</h6>
                            {`${edu.degree}` || ""}
                          </span>
                          <span className="d-flex justify-content-between">
                            <h6>Institute</h6>
                            {edu.institute || ""}
                          </span>
                          <span className="d-flex justify-content-between">
                            <h6>Year From</h6>
                            {`${edu.yearfrom}` || ""}
                          </span>
                          <span className="d-flex justify-content-between">
                            <h6>Year To</h6>
                            {`${edu.yearto}` || ""}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

InvestorDetail.propTypes = {
  match: PropTypes.object,
  onGetInvestorDetail: PropTypes.func,
  investor: PropTypes.object,
};

const mapStateToProps = ({ investors }) => ({
  investor: investors.investor,
  error: investors.error,
});

const mapDispatchToProps = dispatch => ({
  onGetInvestorDetail: id => dispatch(getInvestorDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvestorDetail));
