import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import {
  investmentUserWiseList as getUsersAll,
  getAllUsersForReference,
} from "helpers/backend_helper";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
// Date picker Imports
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import 'bootstrap-daterangepicker/daterangepicker.css';

const show_all_saas_data = JSON.parse(
  localStorage.getItem("roleWiseAccess")
)?.show_all_saas_data;

const UsersReport = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const loginUser = JSON.parse(localStorage.getItem("authUser")).userInfo;
  const [selectedSaas, setSelectedSaas] = useState({
    value: "",
    label: "Select SAAS Parent",
  });
  const [saasPartnersList, setSaasPartnersList] = useState([]);

  //console.log(users)

  useEffect(() => {
    // if (!modal) {
    getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_fullname,
              saas_name,
              saas_alias,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              let temp2 = saas_fullname;
              if (temp2) {
                temp2 += ` (${saas_name})`;
              } else {
                temp2 = saas_name || saas_alias;
              }
              temp = temp2 || temp;

              saasPartners.push({
                value: _id,
                label: temp,
              });
            }

            return { value: _id, label: temp };
          })
          .filter(data => !!data);

        // if (show_all_saas_data == true) {
        //   saasPartners = [{ value: "", label: "Select SAAS Parent" }, ...saasPartners];
        //   setSelectedSaas({
        //     value: "",
        //     label: "Select SAAS Parent",
        //   })
        // } else {
        //   if (is_super_admin == false) {
        //     saasPartners = [...saasPartners];
        //     setSelectedSaas({value: saasPartners[0].saas_parent_id, label: saasPartners[0].label})
        //     setSelectedSaasCondition(true);
        //   }
        //   else{
        //     saasPartners = [{ value: "", label: "Select SAAS Parent" }, ...saasPartners];
        //     setSelectedSaas({
        //       value: "",
        //       label: "Select SAAS Parent",
        //     })
        //   }
        // }

        // setReferenceUserList(users);
        setSaasPartnersList(saasPartners);
        // setSaasFilterOptions(saasPartners);
      })
      .catch(error => {
        console.trace(error);
        // setReferenceUserList([]);
        setSaasPartnersList([]);
        // setSaasFilterOptions([]);
      });
    //}
    return () => {};
  }, []);

  useEffect(() => {
    setLoading(true);
    const query = history.location.search
      ? history.location.search
      : `?page=${page}${searchQuery}&user_type=investor&show_all_saas_data=${show_all_saas_data}`;
    getUsersAll(query)
      .then(response => {
        const usersFilterData = [];
        response.users.map(itemData => {
          itemData.user_types.map(item => {
            if (item.user_type[0] == "investor") {
              usersFilterData.push(itemData);
            }
          });
        });
        setUsers(usersFilterData);
        setTotal(response.dataSize);
        setLoading(false);
      })
      .catch(err => {});
    return () => {};
  }, [page, searchQuery, show_all_saas_data]);

  const defaultSorted = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const userListColumns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      text: "Name",
      dataField: "firstname",
      filterValue: (cell, user) => `${user.firstname} ${user.lastname}`,
      filter: textFilter(),
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.firstname} {user.lastname}
            </Link>
          </h5>
        </>
      ),
    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    //   filter: textFilter(),
    // },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter({
        defaultValue: history.location.search.split("&email=")[1],
      }),
    },
    {
      // dataField: "saas_parent_id._id",
      // text: "SaaS name",
      // filter: textFilter(),
      // formatter: (cellContent, user) => (
      //   <>{user?.saas_parent_id?.saas_name}</>
      // ),

      dataField: "saas_parent._id",
      text: "Domain Expert Name",
      formatter: (cellContent, user) => {
        let value = user?.saas_parent?.saas_name;
        if (user?.is_saas_parent) {
          value = user.saas_name;
        }
        return <>{value}</>;
      },
      filter:
        loginUser?.is_super_admin || show_all_saas_data
          ? selectFilter({
              options: saasPartnersList.sort((a, b) => {
                if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                  return -1;
                }
                if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                  return 1;
                }
                return 0;
              }),
              delay: 1500,
            })
          : false,
    },

    {
      dataField: "user_types[0].user_type[0]",
      text: "User Type",
      formatter: (cellContent, user) => (
        <>
          {user.user_types.map(userType => {
            if (userType.user_type[0] == "investor")
              return `${
                userType.user_type[0]?.charAt(0).toUpperCase() +
                userType.user_type[0].slice(1)
              }  ${user.user_types.length > 1 ? " " : " "}`;
          })}
        </>
      ),
      //filter: textFilter(),
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   formatter: (cellContent, user) => (
    //     <>{user.status.charAt(0).toUpperCase() + user.status.slice(1)}</>
    //   ),
    //   filter: textFilter(),
    // },
    // {
    //   dataField: "email_verified",
    //   text: "Email Verified",
    //   sort: true,
    //   formatter: (cellContent, user) => (
    //     <>
    //       {user.email_verified.charAt(0).toUpperCase() +
    //         user.email_verified.slice(1)}
    //     </>
    //   ),
    //   filter: textFilter(),
    // },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, user) => (
        <>{moment(new Date(user.createdAt)).format("DD MMM Y")}</>
      ),
      // csvFormatter: (cellContent,user) => `${moment(new Date(user.createdAt)).format("DD MMM Y")}`,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      csvExport: false,
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link
            className="text-success"
            to={
              user.user_types[0]?.user_type[0] === "founder"
                ? `/Founder-report/${user._id}`
                : user.user_types[0]?.user_type[0] === "investor"
                ? `/Investor-report/${user._id}`
                : `/professional-detail/${user._id}`
            }
          >
            <i color="primary" className="font-16 btn-block btn btn-primary">
              View Report
            </i>
          </Link>

          {/* <Link to='/Investor-report'>
            <i color="primary" className="font-16 btn-block btn btn-primary">
              View Report
            </i>
          </Link> */}
        </div>
      ),
    },
  ];

  const onTableChange = async (action, { filters, sortOrder, sortField }) => {
    if (action === "filter") {
      const email = filters?.email?.filterVal || "";
      const firstname = filters?.firstname?.filterVal || "";
      const user_type = filters["user_types[0].user_type[0]"]?.filterVal || "";
      const status = filters?.status?.filterVal || "";

      const saas_parent_id = filters["saas_parent._id"]?.filterVal || "";

      let query = ``;
      if (email.length > 0) {
        query += "&email=" + email;
      }
      if (firstname.length > 0) {
        query += "&firstname=" + firstname;
      }
      if (user_type.length > 0) {
        query += "&user_type=" + user_type;
      }
      if (status.length > 0) {
        query += "&status=" + status;
      }

      if (saas_parent_id != "") {
        query += "&saas_parent_id=" + saas_parent_id;
      }
      let order = sortOrder === "asc" ? 1 : -1;
      query += `&sort=${
        sortField === "_id" ? "createdAt" : sortField
      }&order=${order}`;

      setSearchQuery(query);
    } else if (action === "sort") {
      const email = filters?.email?.filterVal || "";
      const firstname = filters?.firstname?.filterVal || "";
      const user_type = filters["user_types[0].user_type[0]"]?.filterVal || "";
      const status = filters?.status?.filterVal || "";

      const saas_parent_id = filters["saas_parent._id"]?.filterVal || "";

      let query = ``;
      if (email.length > 0) {
        query += "&email=" + email;
      }
      if (firstname.length > 0) {
        query += "&firstname=" + firstname;
      }
      if (user_type.length > 0) {
        query += "&user_type=" + user_type;
      }
      if (status.length > 0) {
        query += "&status=" + status;
      }

      if (saas_parent_id != "") {
        query += "&saas_parent_id=" + saas_parent_id;
      }
      let order = sortOrder === "asc" ? 1 : -1;
      query += `&sort=${
        sortField === "_id" ? "createdAt" : sortField
      }&order=${order}`;

      setSearchQuery(query);
    }
  };

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          <Row className="">
            <Col sm="6">
              <h5 className="text-uppercase">Investment User Wise </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={userListColumns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={users}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row></Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{ filter: true, pagination: true }}
                                    options={options}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UsersReport.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default UsersReport;

// export default UsersReport
