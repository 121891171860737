import Swal from "sweetalert2";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";

import { Form } from "react-bootstrap";

import {
  getUsers2 as getUsersAll,
  getUserAdditionalInfo,
  getAllUsersForReference,
  getTimezones,
} from "../../helpers/backend_helper";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Table,
  Alert,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Input,
} from "reactstrap";

import {
  getPitchDays,
  deletePitchDay,
  sendInvitationToAll,
  sendInvitationToAllSuccess,
  sendInvitationToAllFail,
  sendInvitationToFounder,
  sendInvitationToFounderSuccess,
  sendInvitationToFounderFail,
  sendInvitationToInvestor,
  sendInvitationToInvestorSuccess,
  sendInvitationToInvestorFail,
  sendZoomMeetingInvitation,
  updatePitchDayData,
} from "../../store/pitchdays/actions";
import { isEmpty, size } from "lodash";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import Select from "react-select";
import pitchdays from "store/pitchdays/reducer";
import DataFetchLoader from "components/Common/DataFetchLoader";

// you will also need the css that comes with bootstrap-daterangepicker
// import 'bootstrap-daterangepicker/daterangepicker.css';
// import DateRangePicker from "react-bootstrap-daterangepicker";

class PitchDaysList extends Component {
  constructor(props) {
    // const customTotal = (from, to, size) => (
    //   <span className="react-bootstrap-table-pagination-total">
    //     Showing {from} to {to} of {size}
    //   </span>
    // );
    // this.options = {
    //   paginationSize: 1,
    //   pageStartIndex: 1,
    //   alwaysShowAllBtns: true, // Always show next and previous button
    //   withFirstAndLast: true, // Hide the going to First and Last page button
    //   hideSizePerPage: true, // Hide the sizePerPage dropdown always
    //   hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    //   firstPageText: false,
    //   prePageText: false,
    //   nextPageText: false,
    //   lastPageText: false,
    //   nextPageTitle: "First page",
    //   prePageTitle: "Pre page",
    //   firstPageTitle: "Next page",
    //   lastPageTitle: "Last page",
    //   showTotal: true,
    //   paginationTotalRenderer: customTotal,
    //   disablePageTitle: false,
    // };
    super(props);
    this.node = React.createRef();
    this.state = {
      roleWiseAccess: JSON.parse(localStorage.getItem("roleWiseAccess")),
      pitchdays: [],
      pitchdayselected: {},
      alert_visible: false,
      modal: false,
      selectedValue: {},
      selectOptions: [],
      selectRsvpOptions: [
        { label: "Attending", value: "Attending" },
        { label: "No RSVP Yet", value: "No RSVP Yet" },
        { label: "Decline", value: "Decline" },
      ],
      activeTab: "1",
      invest_count: 0,
      loading: false,
      timezonelist: [],
      pitchDayListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, pitchday) => <>{pitchday.id}</>,
        },
        {
          text: "Title",
          dataField: "title",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {pitchday.title}
                </Link>
              </h5>
            </>
          ),
          filter: textFilter(),
        },
        {
          dataField: "event_type",
          text: "Event Type",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "saas_parent_id",
          text: "Syndicate Name",
          filter: selectFilter({ options: this.selectDataSaas }),
          formatter: (cellContent, pitchday) => (
            <>{pitchday.saas.length ? pitchday.saas[0].saas_name : ""}</>
          ),
        },
        {
          dataField: "updatedAt",
          text: "Updated On",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              {moment(new Date(pitchday.updatedAt)).format(
                "DD MMM YYYY hh:mm A"
              )}
            </>
          ),
        },
        {
          dataField: "date.event_date",
          text: "Event On",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              {/* {moment
                .utc(pitchday.date.event_date)
                // .local()
                .format("DD MMM YYYY hh:mm A")} */}

              {localStorage.getItem("zonelist") &&
              JSON.parse(localStorage.getItem("zonelist")) &&
              JSON.parse(localStorage.getItem("zonelist"))?.length &&
              pitchday?.selectedTimezon
                ? moment(pitchday?.date?.event_date)
                    .add(
                      JSON.parse(localStorage.getItem("zonelist"))?.filter(
                        item =>
                          item?.alias === (pitchday?.selectedTimezon ?? "IST")
                      )?.[0]?.offset
                    )
                    .format("DD-MMM-YYYY hh:mm A") +
                  " " +
                  pitchday?.selectedTimezon
                : moment
                    .utc(pitchday.date.event_date)
                    // .local()
                    .add("+05:30")
                    .format("DD MMM YYYY hh:mm A") + " IST"}
            </>
          ),
        },
        {
          dataField: "invited_investor",
          text: "Invited Investors/ Founders /Panellist",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              {pitchday.invited_investor.length}/
              {pitchday.invited_founder.length}/
              {pitchday?.invited_panellist?.length ?? 0}
            </>
          ),
        },
        {
          // dataField: "invest_count",
          dataField: "total_attendees",
          text: "Total Attendees/ Duration",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              {/* {this.getcountAttend(pitchday)} */}
              {pitchday?.total_attendees ?? "-"}/
              {pitchday?.total_duration ?? "-"}
            </>
          ),
        },
        // {
        //   dataField: "invited_founder",
        //   text: "Invited Founders",
        //   sort: true,
        //   formatter: (cellContent, pitchday) => (
        //     <>{pitchday.invited_founder.length}</>
        //   ),
        // },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, pitchday) => {
            // let date = moment.utc().local().format("YYYY-MM-DD HH:mm") + ':00';
            // let abc = date.split('-');
            // let s = abc[2].replace(/^0+/, '');
            // let newDate = abc[0] + "-" + abc[1] + "-" + s;
            // console.log("---newDate---", newDate);
            return (
              <div className="d-flex gap-3">
                {
                  (pitchday?.event_type === "General Event") ?
                    this.state.roleWiseAccess?.eventAttendanceReport ?
                      (moment.utc().local().format("YYYY-MM-DD HH:mm") >= moment.utc(pitchday?.date?.event_date).local().add(2, "hours").format("YYYY-MM-DD HH:mm")) ?
                        <Link className={`btn btn-${pitchday?.isGenerated ? "success" : "primary"}`} to={`/create-event-report/${pitchday?._id}`}>
                          {pitchday?.isGenerated ? "View Report" : "Generate Report"}
                        </Link> :
                        // <span className="btn btn-success">Attendance Report</span>
                        <Link className="btn btn-primary" to={`/create-event-report/${pitchday?._id}`} >Invitees Report</Link>
                      : ''
                    : (pitchday?.is_associated) ?
                      this.state.roleWiseAccess?.eventAttendanceReport ?
                        <Link className="btn btn-success" to={`/create-event-report/${pitchday?._id}`}>Attendance Report</Link>
                        : ''
                      :
                      this.state.roleWiseAccess?.eventGenerateReport ?
                        (moment.utc().local().format("YYYY-MM-DD HH:mm") >= moment.utc(pitchday?.date.event_date).local().add(2, "hours").format("YYYY-MM-DD HH:mm")) ?
                          <Link className="btn btn-primary" to={`/create-event-report/${pitchday?._id}`} >
                            {pitchday?.isGenerated ? "View Report" : "Generate Report"}
                          </Link> :
                          // <span className="btn btn-primary">Generate Report</span> 
                          <Link className="btn btn-primary" to={`/create-event-report/${pitchday?._id}`} >Invitees Report</Link>
                        : ''
                }
              </div>
            )
          },
        },
      ],
      investorListColumns: [
        {
          text: "user_id",
          dataField: "user_id",
          hidden: true,
          formatter: (cellContent, investor) => <>{investor?.user_id}</>,
        },
        {
          text: "Investor",
          dataField: "firstname",
          formatter: (cellContent, investor) => (
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {(
                  (investor?.investor_first_name || "") +
                  " " +
                  (investor?.investor_last_name || "")
                ).trim()}
              </Link>
            </h5>
          ),
        },
        // {
        //   text: "RSVP Status",
        //   dataField: "rsvp.status",
        //   formatter: (cellContent, investor) => <>{investor.rsvp.status}</>,
        // },
        {
          dataField: "rsvp.status",
          text: "RSVP Status",
          // filter: textFilter(),
          filter: selectFilter({
            options: this.selectRsvpStatus,
            defaultValue: "Attending",
          }),
          formatter: (cellContent, investor) => <>{investor?.rsvp?.status}</>,
        },
      ],
    };

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );
    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };
    this.handlePitchDayClick = this.handlePitchDayClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handlePitchDayClicks = this.handlePitchDayClicks.bind(this);
    this.handleSendAllInvite = this.handleSendAllInvite.bind(this);
    this.handleSendInviteToFounder = this.handleSendInviteToFounder.bind(this);
    this.handleSendInviteToInvestor =
      this.handleSendInviteToInvestor.bind(this);
  }

  getcountAttend = pitchday => {
    let icount = 0;
    pitchday?.invited_investor?.map(k => {
      // let icount=0
      if (k.rsvp.status == "Attending") {
        icount++;
      }
    });
    return icount;
  };

  componentDidMount() {
    const { pitchdays, onGetPitchDays } = this.props;
    this.getTimezonDetails();
    // this.setState({ pitchdays });
    this.setState({
      roleWiseAccess: JSON.parse(localStorage.getItem("roleWiseAccess")),
    });
    onGetPitchDays();
    this.getAllUsersForReferenceNEW();
  }

  getTimezonDetails = () => {
    getTimezones()
      .then(response => {
        localStorage.setItem(
          "zonelist",
          JSON.stringify(response?.zonelist ?? [])
        );
      })
      .catch(error => {
        console.trace(error);
      });
  };
  selectDataSaas = () => {
    return this.state.selectOptions;
  };

  selectRsvpStatus = () => {
    return this.state.selectRsvpOptions;
  };

  async getAllUsersForReferenceNEW() {
    this.setState({ loading: true });
    await getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_fullname,
              saas_name,
              saas_alias,
              saas_parent_id,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              let temp2 = saas_fullname;
              if (temp2) {
                temp2 += ` (${saas_name})`;
              } else {
                temp2 = saas_alias;
              }
              temp = temp2 || temp;
              saas_parent_id = _id;

              saasPartners.push({
                value: saas_parent_id,
                label: temp,
              });
            }
            // console.log(users);
            this.setState({ selectOptions: saasPartners });

            return saasPartners;
          })
          .filter(data => !!data);
        this.setState({ loading: false });

        // setSaasPartnersList(saasPartners);
      })
      .catch(error => {
        console.trace(error);
        this.setState({ loading: false });
      });
  }
  toggleTab = tab => {
    this.setState({ activeTab: tab });
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
    this.setState({ activeTab: "1" });
  }

  handlePitchDayClicks = arg => {
    this.setState({ pitchdays: "", isEdit: false });
    this.toggle();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { pitchdays } = this.props;

    if (!isEmpty(pitchdays) && size(prevProps.pitchdays) !== size(pitchdays)) {
      this.setState({ pitchdays: pitchdays, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeletePitchDay = async pitchday => {
    await Swal.fire({
      title: "Do you want to delete this event?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        const {
          onGetPitchDays,
          onDeletePitchDay,
          deletePitchDaySuccess,
          deletePitchDayError,
        } = this.props;
        if (pitchday._id !== undefined) {
          await onDeletePitchDay(pitchday);
          await setTimeout(async () => {
            await onGetPitchDays();
            if (deletePitchDaySuccess && !deletePitchDayError) {
              // this.setState({ pitchdays });
              this.onPaginationPageChange(1);
              Swal.fire("Pitch Day deleted successfully.", "", "success");
            }
            if (deletePitchDayError && !deletePitchDaySuccess) {
              Swal.fire("Pitch Day not deleted.", "Please try again.", "error");
            }
          }, 500);
        }
      }
    });
  };

  handleAllInvitation = async pitchday => {
    await Swal.fire({
      title: "Do you want to send invitation mail?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Send",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (pitchday._id !== undefined) {
          this.props.onSendInvitationToAll(pitchday._id);
          setTimeout(() => {
            this.props.sendInvitationToAllSuccess("");
            this.props.sendInvitationToAllFail("");
          }, 3000);
        }
      }
    });
  };

  handleRangeFilter = (start, end) => {
    const { pitchdays } = this.props;
    const filteredResult = pitchdays.filter(day => {
      const createdAt = moment(new Date(day.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return day;
      } else {
        return;
      }
    });
    this.setState({ pitchdays: filteredResult });
  };

  handleSendZoomMeetingMail = async payload => {
    await Swal.fire({
      title: "Do you want to send meeting invitation mail?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Send",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (payload !== undefined) {
          this.props.onSendZoomMeetingInvitation(payload);
          this.onAlertDismiss();
          setTimeout(() => {
            this.onAlertDismiss();
            // this.props.sendInvitationToAllSuccess("");
            // this.props.sendInvitationToAllFail("");
          }, 3000);
        }
      }
    });
    // this.props.onSendZoomMeetingInvitation(payload);
    // this.onAlertDismiss();
  };

  handleSendAllInvite = pitchday => {
    this.props.onSendInvitationToAll(pitchday._id);
    setTimeout(() => {
      this.props.sendInvitationToAllSuccess("");
      this.props.sendInvitationToAllFail("");
    }, 3000);
  };

  handleSendInviteToFounder = pitchday => {
    this.props.onSendInvitationToFounder(pitchday);
    setTimeout(() => {
      this.props.sendInvitationToFounderSuccess("");
      this.props.sendInvitationToFounderFail("");
    }, 3000);
  };

  handleSendInviteToInvestor = pitchday => {
    this.props.onSendInvitationToInvestor(pitchday);
    setTimeout(() => {
      this.props.sendInvitationToInvestorSuccess("");
      this.props.sendInvitationToInvestorFail("");
    }, 3000);
  };

  handlePitchDayClick = arg => {
    const pitchday = arg;

    this.setState({
      pitchdayselected: {
        id: pitchday._id,
        firstname: pitchday.firstname,
        lastname: pitchday.lastname,
        mobile_country_code: pitchday.mobile_country_code,
        mobile_number: pitchday.mobile_number,
        alternateMobileNumber: pitchday.alternateMobileNumber,
        email: pitchday.email,
        linkedin_profile_link: pitchday.linkedin_profile_link,
        is_admin: pitchday.is_admin,
        mobile_number_verified: pitchday.mobile_number_verified,
        email_verified: pitchday.email_verified,
        status: pitchday.status,
        user_types: pitchday.user_types,
        founders: pitchday.invited_founder,
        investors: pitchday.invited_investor,
        outside_investor: pitchday.outside_investor,
        startup_name: pitchday.startup_name,
        startup_details: pitchday.startup_details,
      },
      isEdit: true,
    });
    let icount = 0;
    pitchday?.invited_investor?.map(k => {
      if (k.rsvp.status == "Attending") {
        icount++;
      }
    });
    this.setState({ invest_count: icount });
    this.toggle();
  };

  //handle copy enablex moderator link
  handleCopyEnablexLink = async link => {
    const unsecuredCopyToClipboard = text => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        // console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };

    const moderatorLink =
      link?.direct_url_moderator + "?user_ref=pitchday@wefoundercircle.com";
    if (window?.isSecureContext && navigator?.clipboard) {
      await navigator?.clipboard?.writeText(moderatorLink);
    } else {
      unsecuredCopyToClipboard(moderatorLink);
    }
  };
  handleChangeDisableEvent = async e => {
   const { onGetPitchDays,updatedPitchDays } = this.props;
   try{
    await updatedPitchDays(e)

    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/pitchday/changeEventDisableStatus`,
      {
        isDisable: e?.val,
        post_id: e?.id,
      },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      }
    );
    // if (response?.data?.status === true) 
      await onGetPitchDays()
    
  }catch(err){
    this.setState({ loading: false });
  }

  };
  //handle copy enablex moderator link
  handleCopyEventPublicLink = async link => {
    const unsecuredCopyToClipboard = text => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        // console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };

    let front_url =
      process.env.REACT_APP_FRONTEND_URL + "/event/" + link;

    if (window?.isSecureContext && navigator?.clipboard) {
      await navigator?.clipboard?.writeText(front_url);
    } else {
      unsecuredCopyToClipboard(front_url);
    }
  };

  //New Button Implement
  handlePostPitchDayClick = async arg => {
    await Swal.fire({
      title: "Do you want to send Post Pitch/General Event mail?",
      showCancelButton: true,
      confirmButtonText: "Send",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        const req = { id: arg._id };
        const token = authHeader();
        let configHeaders;
        if (token) {
          configHeaders = {
            headers: {
              Authorization: token,
            },
          };
        }
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/pitchDay/sendPostPitch`,
            req,
            configHeaders
          )
          .then(response => {
            if (response.status === 200) {
              alert("Post mail send successfully.");
              window.location.href = "/event";
            }
          })
          .catch(error => {
            // console.log(error);
          });
      }
    });
  };
  // Scope End

  handleChange(e) {
    this.setState({ selectedValue: e });
  }

  onAlertDismiss = () => {
    this.setState({ alert_visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alert_visible: false });
      }, 2000);
    });
  };

  exportCSV = async pitchdayId => {
    const req = { investor_user_id: pitchdayId };
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/post/pitchday/export-investor-report`,
        req,
        configHeaders,
        { responseType: "blob" }
      )
      .then(response => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data?.signedUrl;
          link.setAttribute("download", "EventDetails.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          alert("CSV exported successfully.");
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleRSVPstatus = e => {
    this.setState({
      rsvp_status: e.currentTarget.value,
    });
  };

  render() {
    const { pitchdays, error } = this.props;
    const { isEdit } = this.state;
    const { ExportCSVButton } = CSVExport;
    const { loading } = this.state;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: pitchdays.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "date.event_date", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Event | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <DataFetchLoader loading={loading} />
            {/* <Breadcrumbs breadcrumbItem="Events List" /> */}
            <Row className="d-flex align-items-center">
              <Col sm="6">
                <h5 className="text-uppercase mb-0">Events List</h5>
              </Col>
              <Col sm="6">
                {this.state.roleWiseAccess?.addEvent ? (
                  <div className="search-box ms-2 mb-2 d-flex justify-content-between">
                    <div className="position-relative">
                    </div>
                    <a
                      href="/create-event"
                      className="btn btn-primary"
                    >
                      Add Event
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            {/* Render Breadcrumbs */}
            <Row>
              <Col lg="12">
                {!!this.props.sendZoomMeetingInvitationError ? (
                  <Alert
                    color="danger"
                    className="mt-4"
                    isOpen={this.state.alert_visible}
                  >
                    {this.props.sendZoomMeetingInvitationError}
                  </Alert>
                ) : null}
                {!!this.props.sendZoomMeetingInvitationSuccess ? (
                  <Alert
                    color="success"
                    className="mt-4"
                    isOpen={this.state.alert_visible}
                  >
                    {this.props.sendZoomMeetingInvitationSuccess}
                  </Alert>
                ) : null}
              </Col>
              <Col lg="12">
                <Card>
                  <DataFetchLoader
                    loading={
                      this.state.roleWiseAccess?.users_list ? false : true
                    }
                  />
                  <CardBody>
                    <React.Fragment>
                      <Row className="">
                        <Col sm="8">
                          <div className="text-sm-end">
                            {/* <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={this.handleUserClicks}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Create New User
                                        </Button> */}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          {/* {(!isEmpty(error) && (error!='Not able to fetch pitch days!')) ? (
                                    <p className="text-danger">{error}</p>
                                  ) : null} */}
                          {!!this.props.success ? (
                            <Alert color="success">{this.props.success}</Alert>
                          ) : null}
                        </Col>
                      </Row>
                    </React.Fragment>
                    {pitchdays ? (
                      <PaginationProvider
                        pagination={paginationFactory(this.options)}
                        keyField="_id"
                        columns={this.state.pitchDayListColumns}
                        data={pitchdays}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="_id"
                            columns={this.state.pitchDayListColumns}
                            data={pitchdays}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="event-table" id="1235">
                                      <BootstrapTable
                                        {...toolkitprops?.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-hover"
                                        }
                                        bordered={false}
                                        striped={true}
                                        responsive
                                        ref={this.node}
                                        filter={filterFactory()}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                      "No data found"
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              size="lg"
              isOpen={this.state.modal}
              className={this.props?.className}
              toggle={this.toggle}
            >
              <ModalHeader toggle={this.toggle} tag="h4">
                {"View Pitch"}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl="12">
                    {!isEmpty(error) ? (
                      <p className="text-danger">Some error occured!</p>
                    ) : null}
                    {!!this.props.success ? (
                      <Alert color="success">{this.props.success}</Alert>
                    ) : null}
                  </Col>
                </Row>

                <Container fluid>
                  <div>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={this.state.activeTab === "1" && "active"}
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                        >
                          Investors{" "}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.activeTab === "2" && "active"}
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                        >
                          Founders{" "}
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={this.state.activeTab === "3" && "active"}
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                        >
                          Outside Investor
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <>
                          <div className="pt-3 d-flex justify-content-between mb-2">
                            {/* <h5>Investors</h5> */}
                            <Col
                              style={{
                                marginRight: 5,
                              }}
                            >
                              <div className="text-sm-end">
                                <div>
                                  <Button
                                    className="btn btn-success"
                                    onClick={() =>
                                      this.exportCSV(
                                        this.state.pitchdayselected.id
                                      )
                                    }
                                  >
                                    <i className="bx bx-export fs-4"></i> Export
                                    CSV
                                  </Button>
                                </div>
                              </div>
                            </Col>
                            {/* {this.state.pitchdayselected &&
                            this.state.pitchdayselected?.investors &&
                            this.state.pitchdayselected?.investors?.length ? (
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() =>
                                  this.handleSendInviteToInvestor(
                                    this.state.pitchdayselected.id
                                  )
                                }
                              >
                                Send invitation
                              </Button>
                            ) : null} */}
                          </div>
                          <div>
                            <p>
                              <Row>
                                <Col>
                                  {this.state.invest_count} Attending out of{" "}
                                  {
                                    this.state.pitchdayselected?.investors
                                      ?.length
                                  }
                                  <br />
                                </Col>
                              </Row>
                            </p>
                          </div>
                          {/* <Link className="text-success" to="#"><i className="mdi mdi-email-send font-size-18" id="editooltip" onClick={() => this.handleSendAllInvite(e)}></i></Link> */}
                          {this.state.pitchdayselected &&
                          this.state.pitchdayselected?.investors &&
                          this.state.pitchdayselected?.investors?.length ? (
                            <Row>
                              <Col xl="12">
                                <div id="77878">
                                  <PaginationProvider
                                    pagination={paginationFactory(this.options)}
                                    keyField="_id"
                                    columns={this.state.investorListColumns}
                                    data={
                                      this.state.pitchdayselected?.investors
                                    }
                                  >
                                    {({
                                      paginationProps,
                                      paginationTableProps,
                                    }) => (
                                      <ToolkitProvider
                                        keyField="_id"
                                        columns={this.state.investorListColumns}
                                        data={
                                          this.state.pitchdayselected?.investors
                                        }
                                        search
                                      >
                                        {toolkitprops => (
                                          <React.Fragment>
                                            <BootstrapTable
                                              {...toolkitprops?.baseProps}
                                              // {...(this.state.pitchdayselected?.investors?.length >= 10 ?paginationTableProps : {})}
                                              {...paginationTableProps}
                                              classes={
                                                "table event-list align-middle table-hover"
                                              }
                                              bordered={true}
                                              striped={true}
                                              responsive
                                              ref={this.node}
                                              filter={filterFactory()}
                                              // columns={
                                              //   this.state?.investorListColumns
                                              // }
                                            />
                                          </React.Fragment>
                                        )}
                                      </ToolkitProvider>
                                    )}
                                  </PaginationProvider>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            // <>
                            //   {this.state.pitchdayselected.investors
                            //     .length <= 10 ? (
                            //     <Table bordered>
                            //       <thead>
                            //         <tr>
                            //           <th>Investor</th>
                            //           <th>RSVP status</th>
                            //         </tr>
                            //       </thead>

                            //       <tbody>
                            //         {(
                            //           this.state.pitchdays
                            //             ?.investors || []
                            //         ).map((e, ind) => (
                            //           <tr key={ind}>
                            //             <td>
                            //               {
                            //                 e.investor_first_name
                            //               }{" "}
                            //               {e.investor_last_name}
                            //             </td>
                            //             <td>{e.rsvp.status}</td>
                            //           </tr>
                            //         ))}
                            //       </tbody>
                            //     </Table>
                            //   ) : (
                            //     <Row>
                            //       <Col xl="12">
                            //         <div >
                            //           <BootstrapTable
                            //             {...toolkitprops.baseProps}
                            //             {...paginationTableProps}
                            //             classes={"table event-list align-middle table-hover"}
                            //             bordered={false}
                            //             striped={false}
                            //             responsive
                            //             ref={this.node}
                            //             filter={filterFactory()}
                            //             columns={
                            //               this.state
                            //                 .investorListColumns
                            //             }
                            //             data={
                            //               this.state.pitchdays
                            //                 ?.investors || []
                            //             }
                            //           />
                            //         </div>
                            //       </Col>
                            //     </Row>
                            //   )}
                            // </>
                            <h5 className="pt-4 text-center">
                              No investors found
                            </h5>
                          )}
                          <br />
                        </>
                      </TabPane>
                      <TabPane tabId="2">
                        <>
                          <div className="pt-3 d-flex justify-content-between mb-2">
                            <h5></h5>
                            {/* {this.state.pitchdayselected &&
                            this.state.pitchdayselected?.founders &&
                            this.state.pitchdayselected?.founders.length ? (
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() =>
                                  this.handleSendInviteToFounder(
                                    this.state.pitchdayselected.id
                                  )
                                }
                              >
                                Send invitation
                              </Button>
                            ) : null} */}
                          </div>
                          {this.state.pitchdayselected &&
                          this.state.pitchdayselected?.founders &&
                          this.state.pitchdayselected?.founders.length ? (
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th>Founders</th>
                                  <th>Startups</th>
                                  <th>RSVP status</th>
                                  {/* <th>Send Invitation</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {(
                                  this.state.pitchdayselected?.founders || []
                                ).map((e, ind) => {
                                  ind = ind++;
                                  let startup_name;
                                  let startupDetails =
                                    this.state.pitchdayselected
                                      ?.startup_details;
                                  if (startupDetails) {
                                    for (let k in startupDetails) {
                                      if (
                                        e.startup_id == startupDetails[k]._id
                                      ) {
                                        startup_name =
                                          startupDetails[k]?.basic_information
                                            ?.startup_name;
                                        break;
                                      }
                                    }
                                  }
                                  return (
                                    <tr key={ind}>
                                      <td>{e?.founder_name}</td>
                                      <td>{startup_name}</td>
                                      <td>{e?.rsvp?.status}</td>
                                      {/* <td><Link className="text-success" to="#"><i className="mdi mdi-email-send font-size-18" id="editooltip" onClick={() => this.handleSendAllInvite(e)}></i></Link></td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <h5 className="pt-4 text-center">
                              No Founders found
                            </h5>
                          )}
                        </>
                      </TabPane>
                      <TabPane tabId="3">
                        <>
                          <div className="d-flex justify-content-between mb-2">
                            {/* <h5>Outside Investor</h5> */}
                          </div>
                          {this.state.pitchdayselected &&
                          this.state.pitchdayselected?.outside_investor &&
                          this.state.pitchdayselected?.outside_investor
                            .length ? (
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th>Investors</th>

                                  {/* <th>Send Invitation</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {(
                                  this.state.pitchdayselected
                                    ?.outside_investor || []
                                ).map((e, ind) => (
                                  <tr key={ind}>
                                    <td>{e.email}</td>
                                    {/* <td><Link className="text-success" to="#"><i className="mdi mdi-email-send font-size-18" id="editooltip" onClick={() => this.handleSendAllInvite(e)}></i></Link></td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <h5 className="pt-4 text-center">
                              No Outside Investors found
                            </h5>
                          )}
                        </>
                      </TabPane>
                    </TabContent>
                  </div>
                </Container>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

PitchDaysList.propTypes = {
  pitchdays: PropTypes.array,
  onGetPitchDays: PropTypes.func,
  updatedPitchDays: PropTypes.func,
  onDeletePitchDay: PropTypes.func,
  onSendInvitationToAll: PropTypes.func,
  sendInvitationToAllSuccess: PropTypes.func,
  sendInvitationToAllFail: PropTypes.func,
  onSendInvitationToInvestor: PropTypes.func,
  sendInvitationToInvestorSuccess: PropTypes.func,
  sendInvitationToInvestorFail: PropTypes.func,
  onSendInvitationToFounder: PropTypes.func,
  sendInvitationToFounderSuccess: PropTypes.func,
  sendInvitationToFounderFail: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  onSendZoomMeetingInvitation: PropTypes.func,
  sendZoomMeetingInvitationSuccess: PropTypes.string,
  sendZoomMeetingInvitationError: PropTypes.string,
  deletePitchDaySuccess: PropTypes.bool,
  deletePitchDayError: PropTypes.bool,
};

const mapStateToProps = ({ pitchdays }) => ({
  pitchdays: pitchdays.pitchdays,
  deletePitchDaySuccess: pitchdays.deletePitchDaySuccess,
  deletePitchDayError: pitchdays.deletePitchDayError,
  error: pitchdays.error,
  success: pitchdays.success,
  sendZoomMeetingInvitationSuccess: pitchdays.zoomMeetingInvitationSuccess,
  sendZoomMeetingInvitationError: pitchdays.zoomMeetingInvitationError,
});

const mapDispatchToProps = dispatch => ({
  onGetPitchDays: () => dispatch(getPitchDays()),
  updatedPitchDays: (val) => dispatch(updatePitchDayData(val)),
  onDeletePitchDay: pitchday => dispatch(deletePitchDay(pitchday)),
  onSendInvitationToAll: pitchday => dispatch(sendInvitationToAll(pitchday)),
  sendInvitationToAllFail: msg => dispatch(sendInvitationToAllFail(msg)),
  sendInvitationToAllSuccess: msg => dispatch(sendInvitationToAllSuccess(msg)),
  onSendInvitationToInvestor: pitchday =>
    dispatch(sendInvitationToInvestor(pitchday)),
  sendInvitationToInvestorSuccess: msg =>
    dispatch(sendInvitationToInvestorSuccess(msg)),
  sendInvitationToInvestorFail: msg =>
    dispatch(sendInvitationToInvestorFail(msg)),
  onSendInvitationToFounder: pitchday =>
    dispatch(sendInvitationToFounder(pitchday)),
  sendInvitationToFounderSuccess: msg =>
    dispatch(sendInvitationToFounderSuccess(msg)),
  sendInvitationToFounderFail: msg =>
    dispatch(sendInvitationToFounderFail(msg)),
  onSendZoomMeetingInvitation: msg => dispatch(sendZoomMeetingInvitation(msg)),
});
const isEventValid = pitchday => {
  let currentDate = moment(new Date()).format("MM/DD/YYYY:HH:mm:ss");
  let existingEventDate = moment(pitchday?.date?.event_date).format(
    "MM/DD/YYYY:HH:mm:ss"
  );
  console.log(currentDate);
  console.log(existingEventDate);

  if (currentDate < existingEventDate) return true;
  return true;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PitchDaysList));
