import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import MetaTags from "react-meta-tags";
import Stepper from "react-stepper-horizontal";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardTitle,
  Label,
  Button,
  Modal,
  FormGroup,
  ModalBody,
  ModalHeader,
  Row,
  Alert,
} from "reactstrap";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumbs from "../../components/Common/Breadcrumb";

function CreateCallForMoney() {
  const [startups, setStartups] = useState([]);
  const [modal, setModal] = useState(false);
  const [showAdditionalInvestorsModal, setShowAdditionalInvestorsModal] =
    useState(false);
  const [is_for_future_deals, setIs_for_future_deals] = useState(true);
  const [investors, setInvestors] = useState([]);
  const [call_for_money_id, setCallForMoney] = useState(null);
  const [error, setError] = useState("");
  const [messages, setMessages] = useState([]);
  const [additionalInvestors, setAdditionalInvestors] = useState([]);

  const [post, setPost] = useState({
    sharable_links: [{ link: "" }],
    deposit_type: "FounderAcct",
  });
  const [assessment_reports, setAssessment_reports] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const [investor, setInvestor] = useState(null);

  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [selectedAdditionalInvestors, setSelectedAdditionalInvestors] =
    useState([]);

  const token = authHeader();
  const steps = [
    {
      title: "Money Call",
      href: "#",
      onClick: e => {
        e.preventDefault();
      },
    },
    {
      title: "Invites",
      href: "#",
      onClick: e => {
        e.preventDefault();
      },
    },
  ];

  const additionalInvestorsColumns = [
    { dataField: "user_id", text: "Id" },
    { dataField: "investor_name", text: "Name" },
    { dataField: "email", text: "Email" },
    { dataField: "wfc_commission", text: "Investt Commission" },
  ];

  const columns = [
    { dataField: "user_id", text: "Id" },
    { dataField: "investor_name", text: "Name" },
    { dataField: "email", text: "Email" },
    { dataField: "committed_amount", text: "Committed Amount" },
    { dataField: "wfc_commission", text: "Investt Commission" },
    {
      dataField: "",
      text: "Invited",
      formatter: (cellContent, investor) =>
        investor.invited && investor.invited === true ? <p>Yes</p> : <p>No</p>,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, investor) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                setInvestor(investor);
                setModal(true);
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    //selected: selected,
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedInvestors(rows);
      } else {
        setSelectedInvestors([]);
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      let selectedForInvite = selectedInvestors;

      if (isSelect) {
        let existing = selectedForInvite.filter(i => i.user_id === row.user_id);
        if (existing.length <= 0) {
          setSelectedInvestors([...selectedForInvite, row]);
        }
      } else {
        let selectedOnly = selectedForInvite.filter(
          i => i.user_id !== row.user_id
        );
        setSelectedInvestors(selectedOnly);
      }
    },
  };

  const selectAdditionalInvestor = {
    mode: "checkbox",
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedAdditionalInvestors(rows);
      } else {
        setSelectedAdditionalInvestors([]);
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      let selectedInvts = selectedAdditionalInvestors;

      if (isSelect) {
        let existing = selectedInvts.filter(i => i.user_id === row.user_id);
        if (existing.length <= 0) {
          setSelectedAdditionalInvestors([...selectedInvts, row]);
        }
      } else {
        let selectedOnly = selectedInvts.filter(i => i.user_id !== row.user_id);
        setSelectedAdditionalInvestors(selectedOnly);
      }
    },
  };

  const setNewMessage = msg => {
    setMessages([msg]);
  };

  const handleChangeIndustrySector = (e, index) => {
    const sectors = post.sharable_links;
    sectors[index]["link"] = e.currentTarget.value;
    setPost({ ...post, sharable_links: sectors });
  };

  const handleRemovesharable_links = index => {
    var values = post.sharable_links;
    values.splice(index, 1);
    setPost({ ...post, sharable_links: values });
  };
  const handleAddsharable_links = e => {
    var values = post.sharable_links;
    values.push({ link: "" });
    setPost({ ...post, sharable_links: values });
  };

  const sendInvitations = async investorList => {
    let invitesData = new FormData();
    investorList.forEach((invite, i) => {
      invitesData.append(`invites[${i}][call_for_money_id]`, call_for_money_id);
      invitesData.append(
        `invites[${i}][committed_amount]`,
        invite.committed_amount
      );
      invitesData.append(
        `invites[${i}][commission]`,
        `${invite.wfc_commission}`
      );
      invitesData.append(`invites[${i}][investor_user_id]`, invite.user_id);
      invitesData.append(
        `invites[${i}][due_date]`,
        moment(new Date()).format("YYYY/MM/DD")
      ); // default for now
    });

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/call/for/money/send/invites`,
      data: invitesData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        setNewMessage({ type: "success", message: res.data.message });
      })
      .catch(err => {});
  };

  const fetchNotIncludedInvestors = async cfmId => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/call/for/money/${cfmId}/not/included/investors`,
      headers: { Authorization: `${token}` },
    })
      .then(res => {
        if (res.data.investors) {
          setAdditionalInvestors(res.data.investors);
        }
      })
      .catch(err => {
        setNewMessage({
          type: "danger",
          message:
            "Unable to fetch investors list which are not included in call for money",
        });
      });
  };

  const sendInvitesToInvestors = async () => {
    if (selectedInvestors.length > 0) {
      await sendInvitations(selectedInvestors);
    } else {
      alert("please select some investors for invites");
    }
  };

  const updateCommissionForFuture = async invt => {
    let commData = new FormData();
    commData.append("commission", invt.wfc_commission);
    commData.append("investor_user_id", invt.user_id);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/call/for/money/set/wfc/commission`,
      data: commData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        setNewMessage({ type: "success", message: res.data.message });
        setModal(false);
      })
      .catch(err => {});
  };

  const handleCommissionUpdateForInvestor = async () => {
    let updatedInvts = [];
    investors.forEach((invt, i) => {
      if (invt.user_id === investor.user_id) {
        updatedInvts.push(investor);
      } else {
        updatedInvts.push(invt);
      }
    });

    setInvestors(updatedInvts);

    if (is_for_future_deals) {
      await updateCommissionForFuture(investor);
      setIs_for_future_deals(false);
    } else {
      setModal(false);
    }
  };

  const handleChangeSaveForFuture = (e, user_id) => {
    if (e.target.value === "true" || e.target.value === true) {
      setIs_for_future_deals(false);
    } else {
      setIs_for_future_deals(true);
    }
  };

  const handleCommissionChange = e => {
    setInvestor({ ...investor, wfc_commission: e.target.value });
  };

  const onClickBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const createPost = post => {
    let bodyFormData = new FormData();
    post.sharable_links = post.sharable_links.map(link => link.link);
    bodyFormData.append("assessment_report_id", post.assessment_report_id);
    bodyFormData.append("deal_id", post.deal_id);
    bodyFormData.append("deposit_type", post.deposit_type);
    bodyFormData.append("sharable_links", post.sharable_links);
    bodyFormData.append("description", post.description);
    bodyFormData.append("term_condition", post.term_condition);
    try {
      axios({
        method: "POST",
        url: `${
          process.env.REACT_APP_API_URL + "/api/call/for/money/first/step"
        }`,
        data: bodyFormData,
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async res => {
          if (res.data.id) {
            setCallForMoney(res.data.id);
            setMessages([]);

            await axios
              .get(
                process.env.REACT_APP_API_URL +
                  `/api/call/for/money/${res.data.id}/investors`,
                {
                  headers: { Authorization: `${token}` },
                }
              )
              .then(async res => {
                await setInvestors(res.data.investors);
                await setCurrentStep(currentStep + 1);
              })
              .catch(err => {
                if (err.message.errors && Object.keys(err.message.errors) > 0) {
                  err.message.errors.forEach((k, i) => {
                    setNewMessage({
                      type: "danger",
                      message: err.message.errors[k],
                    });
                  });
                }
              });

            await fetchNotIncludedInvestors(res.data.id);
          }
        })
        .catch(error => {
          let errs = error.response.data.message.errors || {};
          if (errs && Object.keys(errs).length > 0) {
            Object.keys(errs).forEach((k, i) => {
              setNewMessage({ type: "danger", message: errs[k] });
            });
          }
        });
    } catch (error) {}
  };

  // add additional investors in assessment report
  const addAdditionalInvestors = async addnlInvstrs => {
    if (addnlInvstrs && addnlInvstrs.length > 0) {
      let addInvtData = new FormData();

      addnlInvstrs.forEach((invt, i) => {
        addInvtData.append(
          `additional_investors[${i}][committed_amount]`,
          "ss"
        );
        addInvtData.append(
          `additional_investors[${i}][commission]`,
          `${invt.wfc_commission}`
        );
        addInvtData.append(`additional_investors[${i}][user_id]`, invt.user_id);
        addInvtData.append(`additional_investors[${i}][email]`, invt.email);
        addInvtData.append(
          `additional_investors[${i}][user_name]`,
          invt.investor_name
        );
      });

      try {
        await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/call/for/money/${call_for_money_id}/add/additional/investors`,
          data: addInvtData,
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
          .then(async res => {
            await fetchNotIncludedInvestors(call_for_money_id);
          })
          .catch(error => {
            let errs = error.response.data.message.errors || {};
            if (errs && Object.keys(errs).length > 0) {
              Object.keys(errs).forEach((k, i) => {
                setNewMessage({ type: "danger", message: errs[k] });
              });
            }
          });
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  async function fetchData() {
    await axios
      .get(process.env.REACT_APP_API_URL + "/api/call/for/money/startups", {
        headers: { Authorization: `${token}` },
      })
      .then(async res => {
        await setStartups(res.data.startups);
      })
      .catch(err => {});
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/call/for/money/assessmentIds/with/startup",
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then(async res => {
        await setAssessment_reports(res.data.startups);
      })
      .catch(err => {});
  }

  const addMoreInvestors = () => {
    let existing = investors,
      newSeld = selectedAdditionalInvestors,
      additional = [];
    selectedAdditionalInvestors.forEach((invt, i) => {
      let exist = existing.filter(iv => iv.user_id === invt.user_id);
      if (exist.length <= 0) {
        existing.push(invt);
        additional.push(invt);
      }
    });
    setInvestors(existing);
    // set in assessment report
    addAdditionalInvestors(additional);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>First Step Call For Money | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Call For Money"
            breadcrumbItem="Create Call For Money"
          />
          <Row>
            <Col lg="12">
              <Card>
                {messages.map((msg, i) => {
                  return (
                    <Alert color={msg.type} key={i} className="mt-4">
                      {msg.message}
                    </Alert>
                  );
                })}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <Stepper steps={steps} activeStep={currentStep} />
                <CardBody>
                  {currentStep === 0 && (
                    <div id="first_step">
                      <CardTitle className="mb-4">
                        Create Call For Money
                      </CardTitle>
                      <AvForm
                        onValidSubmit={() => createPost(post)}
                        model={post}
                      >
                        <Row form>
                          <Col className="col-12">
                            <div className="mb-3">
                              <AvField
                                name="startup"
                                label="Select Startup"
                                type="select"
                                onChange={event => {
                                  setPost({
                                    ...post,
                                    deal_id: event.target.value,
                                  });
                                }}
                                errorMessage="Please select a Startup"
                                validate={{
                                  required: { value: true },
                                }}
                                value={post.deal_id || ""}
                              >
                                <option value="" disabled>
                                  Select a Startup
                                </option>
                                {assessment_reports &&
                                  startups.map((founder, index) => (
                                    <option
                                      value={founder.fundingDealId}
                                      key={index}
                                    >
                                      {founder.startup}
                                    </option>
                                  ))}
                              </AvField>
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="report"
                                label="Select Assessment Report"
                                type="select"
                                onChange={event => {
                                  setPost({
                                    ...post,
                                    assessment_report_id: event.target.value,
                                  });
                                }}
                                errorMessage="Please select a Assessment Report"
                                validate={{
                                  required: { value: true },
                                }}
                                value={post.assessment_report_id || ""}
                              >
                                <option value="" disabled>
                                  Select a Report
                                </option>
                                {assessment_reports &&
                                  assessment_reports.map((report, index) => (
                                    <option
                                      value={report.assessmentReportId}
                                      key={index}
                                    >
                                      {report.startup}
                                    </option>
                                  ))}
                              </AvField>
                            </div>
                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Label className="col-form-label col-lg-2">
                                  Shareable Links
                                </Label>
                                <div
                                  className="inner col-lg-10 ms-md-auto"
                                  id="repeater_industry_sector"
                                >
                                  {post.sharable_links.map((field, key) => (
                                    <React.Fragment key={"_field_" + key}>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="10">
                                          <input
                                            type="text"
                                            name="link"
                                            className="inner form-control"
                                            placeholder="Enter shareable link..."
                                            onChange={e =>
                                              handleChangeIndustrySector(e, key)
                                            }
                                          />
                                        </Col>

                                        <Col md="2">
                                          <div className="mt-2 mt-md-0 d-grid">
                                            <Button
                                              color="primary"
                                              className="inner"
                                              onClick={() => {
                                                handleRemovesharable_links(key);
                                              }}
                                              block
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                              <Row className="justify-content-end">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={handleAddsharable_links}
                                  >
                                    Add More
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <div className="mb-3 font-size-12">
                              <p>Deposit Type</p>
                              <AvRadioGroup inline name="deposit_type" label="">
                                <AvRadio
                                  label="Founder Account"
                                  value="FounderAcct"
                                />
                                <AvRadio
                                  label="Escrow Account"
                                  value="EscrowAcc"
                                  disabled
                                />
                              </AvRadioGroup>
                            </div>
                            <br />
                            <div className="mb-3">
                              <AvField
                                name="Description"
                                label="Description"
                                type="textarea"
                                onChange={event => {
                                  setPost({
                                    ...post,
                                    description: event.target.value,
                                  });
                                }}
                                errorMessage="Please add some description for call for money "
                                validate={{
                                  required: { value: true },
                                }}
                                value={
                                  post.description ||
                                  "Enter a few lines as description for call for money"
                                }
                              ></AvField>
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="Terms and Conditions"
                                label="Terms and Conditions"
                                type="textarea"
                                onChange={event => {
                                  setPost({
                                    ...post,
                                    term_condition: event.target.value,
                                  });
                                }}
                                errorMessage="Please add Terms and Conditions associated with this deal"
                                validate={{
                                  required: { value: true },
                                }}
                                value={
                                  post.terms_and_conditions ||
                                  "Default Terms and Conditions"
                                }
                              ></AvField>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-start">
                              <button
                                type="submit"
                                onClick={() => {
                                  onClickBack();
                                }}
                                className="btn btn-success save-user"
                              >
                                Back
                              </button>
                            </div>
                          </Col>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                style={{ marginRight: 10 }}
                                className="btn btn-success save-user"
                              >
                                Next
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  )}
                  {currentStep === 1 && (
                    <div id="second_step">
                      <CardTitle className="mb-4">
                        Select Investors and send Invitation for Money Call
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <div className="text-end">
                            <button
                              type="submit"
                              onClick={() => {
                                setSelectedAdditionalInvestors([]);
                                setShowAdditionalInvestorsModal(true);
                              }}
                              className="btn btn-success save-user"
                            >
                              Add Investors
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="user_id"
                              data={investors}
                              selectRow={selectRow}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                            />
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col>
                          <div className="text-center">
                            <button
                              type="submit"
                              onClick={() => {
                                onClickBack();
                              }}
                              className="btn btn-success save-user"
                            >
                              Back
                            </button>
                          </div>
                        </Col>
                        <Col>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                              onClick={() => sendInvitesToInvestors()}
                            >
                              Send Invites
                            </button>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Modal
                          isOpen={modal}
                          toggle={() => setModal(false)}
                          size="lg"
                        >
                          <ModalHeader toggle={() => setModal(false)} tag="h4">
                            Edit Invstt commission for Investor
                          </ModalHeader>
                          <ModalBody>
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative"></div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  {investor && (
                                    <AvForm
                                      className="outer-repeater"
                                      onValidSubmit={() =>
                                        handleCommissionUpdateForInvestor()
                                      }
                                    >
                                      <div
                                        data-repeater-list="outer-group"
                                        className="outer"
                                      >
                                        <div
                                          data-repeater-item
                                          className="outer"
                                        >
                                          <FormGroup className="mb-4" row>
                                            <Label
                                              htmlFor="name"
                                              className="col-form-label col-lg-3"
                                            >
                                              Investor Name
                                            </Label>
                                            <Col lg="9">
                                              <AvField
                                                id="name"
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Investor name"
                                                errorMessage="Required Field"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  investor.investor_name || ""
                                                }
                                              />
                                            </Col>
                                          </FormGroup>
                                          <FormGroup className="mb-4" row>
                                            <Label
                                              htmlFor="commission"
                                              className="col-form-label col-lg-3"
                                            >
                                              Invstt Commission
                                            </Label>
                                            <Col lg="9">
                                              <AvField
                                                id="commission"
                                                name="commission"
                                                type="text"
                                                className="form-control"
                                                placeholder="Invstt Commission"
                                                errorMessage="Required Field"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={e =>
                                                  handleCommissionChange(e)
                                                }
                                                value={
                                                  investor.wfc_commission || ""
                                                }
                                              />
                                            </Col>
                                          </FormGroup>
                                          <FormGroup className="mb-4" row>
                                            <Col lg="3"></Col>
                                            <Col lg="9">
                                              <AvField
                                                name="for_all_deals"
                                                label={`  Save for Future Deals for ${investor.investor_name}`}
                                                type="checkbox"
                                                onChange={e =>
                                                  handleChangeSaveForFuture(
                                                    e,
                                                    investor.user_id
                                                  )
                                                }
                                              />
                                            </Col>
                                          </FormGroup>
                                        </div>
                                      </div>
                                      <Row className="text-center">
                                        <Col lg="12">
                                          <Button type="submit" color="primary">
                                            Update
                                          </Button>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  )}
                                </Col>
                              </Row>
                            </React.Fragment>
                          </ModalBody>
                        </Modal>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Modal
              isOpen={showAdditionalInvestorsModal}
              toggle={() => setShowAdditionalInvestorsModal(false)}
              size="lg"
            >
              <ModalHeader
                toggle={() => setShowAdditionalInvestorsModal(false)}
                tag="h4"
              >
                Select more investors for Call For Money
              </ModalHeader>
              <ModalBody>
                <React.Fragment>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField="user_id"
                          data={additionalInvestors}
                          selectRow={selectAdditionalInvestor}
                          columns={additionalInvestorsColumns}
                          bordered={false}
                          striped={true}
                          responsive
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12" className="text-end">
                      <Button
                        type="submit"
                        color="primary"
                        onClick={() => addMoreInvestors()}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CreateCallForMoney;
