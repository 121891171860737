import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Button,
  ModalHeader,
  ModalFooter,
  Label,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { checkIntentCurrency, formatNumberToCommaSeparatedCurrency } from "../../../helpers/common";
import { Modal } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";

import {
  getKyc,
  getAllUsersForReference,
  getAllPartnersForEmployees,
  getDealManagers,
  toCommaFormat,
  getInvestorKycUpdateLogs,
  getKycV2,
  verifyAdminEmailOTP,
} from "../../../helpers/backend_helper";
import axios from "axios";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { loginAsUser } from "../../../helpers/backend_helper";
import Select from "react-select";

// Loader for data fetch
import DataFetchLoader from "../../../components/Common/DataFetchLoader";

// For User CSV Export
import { CSVDownload } from "react-csv";
import LedgerDetails from "./CALedger/LedgerDetails-v2";
import SignupLedger from "./CALedger/SignupLedger-v2";

function sortDataInAsc(history) {
  history.sort(function (a, b) {
    let date_1 = moment(a.approvedAt);
    let date_2 = moment(b.approvedAt);
    return date_1 - date_2;
  });

  return history;
}

function capitalizeLabel(label) {
  let temp = label?.split("_");
  temp = temp?.map(item => item?.charAt(0)?.toUpperCase() + item?.substring(1));
  temp = temp?.join(" ");

  return temp;
}

function filterKYCAddedData(logObj) {
  let modifiedObj = [];
  logObj?.forEach(item => {
    let createdAt = item?.createdAt;
    let { kyc_status, source, user_details } = item?.updated;

    let Obj = {
      user_name: source?.adminUsername,
      user_email: source?.adminEmail,
      source: source?.source,
      status: "Kyc Status",
      new_status: "Onboarding Complete, Approval Pending",
      old_status: "--",
      changed_on: createdAt,
      ip: source?.adminIpAddress,
      os: source?.adminOsName,
      location: source?.adminLocation,
      device: source?.adminDeviceName,
      browser: source?.adminBrowserName,
    };

    modifiedObj.push(Obj);
  });

  return modifiedObj;
}

function calculateBaseConsentAmountFeeINR(values) {
  let {
    consent_amount,
    transaction_fees,
    iv_mapped_transaction_fees,
    iv_mapped_transaction_fees_cap_table,
    transaction_fees_cap_table,
    min_amount_cap_amount_tab,
  } = values;
  let managementFees = 0;
  // let gstPercent = parseInt(gst) / 100;
  // let transaction_fee_percent = parseInt(transaction_fee) / 100;

  // let amountCalculated =
  //   parseInt(consent_amount) *
  //   (transaction_fee_percent + transaction_fee_percent * gstPercent);

  // return amountCalculated;
  if (
    !parseInt(min_amount_cap_amount_tab) ||
    consent_amount < parseInt(min_amount_cap_amount_tab)
  ) {
    let in_syndicate_transaction_fees =
      iv_mapped_transaction_fees &&
      Object.values(iv_mapped_transaction_fees)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees)?.[0])
        : "";
    if (
      in_syndicate_transaction_fees ||
      (transaction_fees && parseInt(transaction_fees))
    ) {
      //transaction fee for syndicate i.e. 2% +GST(18%) =2.36% total trans fee
      let managementFeesData =
        ((in_syndicate_transaction_fees
          ? in_syndicate_transaction_fees
          : parseInt(transaction_fees)) +
          ((in_syndicate_transaction_fees
            ? in_syndicate_transaction_fees
            : parseInt(transaction_fees)) *
            18) /
            100) /
        100;
      managementFees = consent_amount * managementFeesData;
    }
  } else if (consent_amount >= parseInt(min_amount_cap_amount_tab)) {
    //transaction fee for direct cap tab i.e. 2% +GST(18%) =2.36% total trans fee
    let in_captab_transaction_fees =
      iv_mapped_transaction_fees_cap_table &&
      Object.values(iv_mapped_transaction_fees_cap_table)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees_cap_table)?.[0])
        : "";

    if (
      in_captab_transaction_fees ||
      (transaction_fees_cap_table && parseInt(transaction_fees_cap_table))
    ) {
      let managementFeesData =
        ((in_captab_transaction_fees
          ? in_captab_transaction_fees
          : parseInt(transaction_fees_cap_table)) +
          ((in_captab_transaction_fees
            ? in_captab_transaction_fees
            : parseInt(transaction_fees_cap_table)) *
            18) /
            100) /
        100;
      managementFees = consent_amount * managementFeesData;
    }
  }

  return managementFees;
}

function fetchConsentAmount({ baseAmount, fee }) {
  return parseInt(baseAmount) + parseInt(fee);
}

function calculateBaseConsentAmountFeeForeign({
  consent_amount_global,
  transaction_fees,
  iv_mapped_transaction_fees,
  iv_mapped_transaction_fees_cap_table,
  transaction_fees_cap_table,
  min_amount_cap_amount_tab_usd,
}) {
  // let transaction_fee_percent = parseInt(transaction_fee) / 100;
  // let amountCalculated = transaction_fee_percent * parseInt(consent_amount);
  // return amountCalculated;
  let managementFees = 0;
  if (
    !parseInt(min_amount_cap_amount_tab_usd) ||
    consent_amount_global < parseInt(min_amount_cap_amount_tab_usd)
  ) {
    let in_syndicate_transaction_fees =
      iv_mapped_transaction_fees &&
      Object.values(iv_mapped_transaction_fees)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees)?.[0])
        : "";

    if (
      in_syndicate_transaction_fees ||
      (transaction_fees && parseInt(transaction_fees))
    ) {
      //transaction fee for syndicate i.e. 2%  =2.00% total trans fee
      let managementFeesData =
        (in_syndicate_transaction_fees
          ? in_syndicate_transaction_fees
          : parseInt(transaction_fees)) / 100;
      managementFees = consent_amount_global * managementFeesData;
    }
  }
  if (
    parseInt(min_amount_cap_amount_tab_usd) &&
    consent_amount_global >= parseInt(min_amount_cap_amount_tab_usd)
  ) {
    //transaction fee for direct cap tab i.e. 2%  =2.00% total trans fee
    let in_captab_transaction_fees =
      iv_mapped_transaction_fees_cap_table &&
      Object.values(iv_mapped_transaction_fees_cap_table)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees_cap_table)?.[0])
        : "";

    if (
      in_captab_transaction_fees ||
      (transaction_fees_cap_table && parseInt(transaction_fees_cap_table))
    ) {
      let managementFeesData =
        (in_captab_transaction_fees
          ? in_captab_transaction_fees
          : parseInt(transaction_fees_cap_table)) / 100;
      managementFees = consent_amount_global * managementFeesData;
    }
  }

  return managementFees;
}

function numberToEnglish(n) {
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words,
    and = "and";

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
}

function getPANNumber(applicant_name, relationObj) {
  let user = relationObj.filter(data => {
    if (data.applicant_name === applicant_name) return data;
  });
  user = user[0];

  return user?.applicant_pan_number;
}

function getMomentDateString(dateStr) {
  let date = moment(dateStr).format("MMM DD, YYYY");
  let time = moment(dateStr).format("hh:mm A");

  return `${date} ${time}`;
}

function getInvestorFolio(name, applicant_email, user_email) {
  return name && !applicant_email
    ? `${name} ${user_email}`
    : !name && applicant_email
    ? `${user_email} (Name not available)`
    : `${name}, ${applicant_email}`;
}

const KycList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [kycdata, setKycdata] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [formedCSVData, setFormedCSVData] = useState([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [execution, setExecution] = useState(0);
  const [modelCommentData, setModelCommentData] = useState([]);
  const [showCommentModel, setShowCommentModel] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [approvalModalData, setApprovalModalData] = useState("");

  const [investmentManagers, setinvestmentManagers] = useState([]);
  const [currentlySelectedRelative, setCurrentySelectedRelative] = useState({
    value: "self",
    label: "Self",
  });

  const [reportPopup, setReportPopup] = useState(false);
  const [caDdDetails, setCaDdDetails] = useState(null);
  const [relatives, setRelatives] = useState(null);
  const [logsModalData, setLogsModalData] = useState({});
  const [fundCurrency, setFundCurrency] = useState("INR");
  const [logsModalOpen, setLogsModalOpen] = useState(false);
  const [logsDateFormat] = useState("DD-MMM-YYYY hh:mm:ss A");
  const [fundTypeList, setfundTypeList] = useState([]);
  const [selectedFundType, setSelectedFundType] = useState({});

  const selectedSaasPartner = {
    value: JSON.parse(localStorage.getItem("authUser"))?.userInfo
      ?.saas_parent_id,
  };

  const [access, setAccess] = useState({});

  const [saasFilterOptions, setSaasFilterOptions] = useState([]);

  const selectType = [
    { value: "Individual", label: "Individual" },
    { value: "Relative", label: "Relative" },
    { value: "Entity", label: "Entity" },
  ];

  const CURRENCY_MAP = {
    USD: {
      value: "USD",
      label: "United States Dollar",
      symbol: "US$",
      locale: "en-US",
    },
    INR: {
      value: "INR",
      label: "Indian Rupee",
      symbol: "₹",
      locale: "en-IN",
    },
  };

  const selectKycStatus = [
    {
      value: "Intent Shared, Onboarding Initiated",
      label: "Intent Shared, KYC Initiated",
      fmode: "isoi",
    },
    {
      value: "Onboarding Complete, Approval Pending",
      label: "KYC Complete, Approval Pending",
      fmode: "ocap",
    },
    { value: "Onboarding Approved", label: "KYC Approved", fmode: "oa" },

    {
      value: "Onboarding Rejected",
      label: "KYC Disapproved",
      fmode: "oreject",
    },
    {
      value: "Onboarding Process Dropped",
      label: "KYC Process Dropped",
      fmode: "ocancelled",
    },
    {
      value: "Contribution Agreement Shared",
      label: "Contribution Agreement Shared",
      fmode: "ca_shared",
    },
    {
      value: "Contribution Agreement Not Shared",
      label: "Contribution Agreement Not Shared",
      fmode: "ca_not_shared",
    },
    {
      value: "Contribution Agreement Signed by Investor, Pending by Trustee",
      label: "Contribution Agreement Signed by Investor, Pending by Trustee",
      fmode: "ca_sbi_pbt",
    },
    {
      value: "Contribution Agreement Signed by All - Process Completed",
      label: "Contribution Agreement Signed by All - Process Completed",
      fmode: "ca_sba_pc",
    },
    {
      value: "Contribution Agreement Dropped",
      label: "Contribution Agreement Dropped",
      fmode: "ca_cancelled",
    },
  ];

  const LabelSwitch = label => {
    switch (label) {
      case "individual_kyc_status":
        return "Individual KYC Status";
      case "entity_kyc_status":
        return "Entity KYC Status";
      case "relative_kyc_status":
        return "Relative KYC Status";
      case "individual_ca_status":
        return "Individual CA Status";
      case "entity_ca_status":
        return "Entity CA Status";
      case "relative_ca_status":
        return "Relative CA Status";
      case "createdAt":
      case "updatedAt":
        return "Updated At";
      default:
        return label;
    }
  };

  const StatusSwitch = label => {
    switch (label) {
      case "pending":
        return "KYC Complete, Approval Pending";
      case "incomplete":
        return "KYC Process Incomplete";
      case "approved":
        return "KYC Approved";
      case "rejected":
        return "KYC Disapproved";
      case "cancelled":
        return "KYC Process Dropped";
      case "ca_shared":
        return "Contribution Agreement Shared";
      case "pending_by_trustee":
        return "Contribution Agreement Signed by Investor, Pending by Trustee";
      case "process_completed":
        return "Contribution Agreement Signed by All - Process Completed";
      case "process_cancelled":
        return "Contribution Agreement Dropped";
      default:
        return label;
    }
  };

  const setLogsModal = logsModalOpen => {
    if (!logsModalOpen) {
      setLogsModalData({});
    }
    setLogsModalOpen(logsModalOpen);
  };

  const toggleLogsModal = () => {
    setLogsModal(!logsModalOpen);
  };

  const [show_all_saas_data, setShowAllSaasData] = useState(
    JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data
  );

  let params = new URLSearchParams(window.location.search);
  let fmode = params.get("fmode");
  let url_fund_type_id = params.get("fund_type_id");

  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let is_super_admin = authUser?.userInfo?.is_super_admin;

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
        setShowAllSaasData(res.data.roleWiseAccess?.show_all_saas_data);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/applyforfunding/getFundDetails`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        let data = res?.data?.data;
        let newdata = data.filter(row => {
          if (
            row?._id?.toString() == "64870dba37cf22f296df37fb" ||
            row?._id?.toString() == "64870da237cf22f296df37f9"
          ) {
            return true;
          }
        });

        setfundTypeList(newdata);
        //For defualt selected fund type
        //For defualt fund type data
        if (!url_fund_type_id) {
          url_fund_type_id = "64870dba37cf22f296df37fb";
        }

        let selectedFundType = data?.find(
          row => row?._id?.toString() == url_fund_type_id
        );
        setSelectedFundType(selectedFundType);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    return () => {};
  }, []);

  useEffect(() => {
    getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_fullname,
              saas_name,
              saas_alias,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              let temp2 = saas_name;
              if (temp2) {
                temp2 += ` (${saas_fullname})`;
              } else {
                temp2 = saas_fullname || saas_alias;
              }
              temp = temp2 || temp;

              saasPartners.push({
                value: _id,
                label: temp,
              });
            }

            return { value: _id, label: temp };
          })
          .filter(data => !!data);
        setSaasFilterOptions(saasPartners);
      })
      .catch(error => {
        console.trace(error);
        setSaasFilterOptions([]);
      });

    return () => {};
  }, []);

  useEffect(() => {
    getDealManagers()
      .then(response => {
        let managers = response.managers;
        managers = managers.sort((a, b) => {
          if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
            return -1;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        managers = managers.map(mngr => {
          return { value: mngr._id, label: mngr.name };
        });
        setinvestmentManagers(managers);
      })
      .catch(error => {
        console.trace(error);
        setinvestmentManagers([]);
      });

    return () => {};
  }, []);

  useEffect(() => {
    fetchAndSetKYCData(page, searchQuery, show_all_saas_data);

    return () => {};
  }, [page, searchQuery, show_all_saas_data, selectedFundType]);

  const fetchAndSetKYCData = async (page, searchQuery, show_all_saas_data) => {
    setLoading(true);

    const saas_parent_id = authUser?.userInfo?.saas_parent_id;
    setExecution(1);
    let exeflag = 0;
    if (fmode && execution) {
      exeflag = 1;
    } else if (!fmode) {
      exeflag = 1;
    }

    //For defualt fund type data
    if (!url_fund_type_id) {
      url_fund_type_id = "64870dba37cf22f296df37fb";
    }

    if (selectedFundType?._id) {
      url_fund_type_id = selectedFundType?._id;
    }
    if (exeflag) {
      await getKycV2(
        `?page=${page}${searchQuery}&saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}&fund_type_id=${url_fund_type_id}`
      )
        .then(response => {
          let kycdata = response?.data?.kycrelationdata;
          let dataSize = response?.data?.dataSize;

          setKycdata(kycdata);
          setTotal(dataSize);
          setLoading(false);
        })
        .catch(error => {
          // console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/role/getroles`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      })
      .then(res => {
        let arr = [];
        res.data.roleWiseAccess?.map(data => {
          let obj = {};
          obj.label = data.name;
          obj.value = data._id;
          arr.push(obj);
        });
      })
      .catch(err => {
        // console.log(err)
      });

    return () => {};
  }, [selectedSaasPartner]);

  const handleReport = async userDetails => {
    // AWAITING RELATION LISTING BASED ON INVESTOR

    if (relatives === null) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api-v2/get-relatives/${userDetails?.investor_id}/${userDetails?.fund_type_id}`
        )
        .then(response => {
          let { data } = response.data;
          if (
            userDetails?.relation !== "Self" &&
            userDetails?.relation !== "self"
          ) {
            let selectedRelation = [
              {
                value: userDetails?.pan_number || userDetails?.relation,
                label: userDetails?.relation_key,
                fund_type_id: userDetails?.fund_type_id,
                fund_type: userDetails?.fund_type,
              },
            ];

            setRelatives(selectedRelation);
          } else {
            setRelatives(data);
            setCurrentySelectedRelative({
              label: "Self",
              value: "self",
            });
          }
        })
        .catch(err => {
          console.log("Error:", err);
        });
    }

    // fETCHING RECORD BASED ON INVESTOR ID
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api-v2/investor/ledger/${userDetails?.investor_id}?relation=${userDetails?.relation}&fund_type_id=${userDetails?.fund_type_id}&fund_currency=${userDetails?.fund_currency}`
        // configHeaders
      )
      .then(response => {
        let {
          report: {
            statistics: {
              total_ca_till_date_count,
              draw_down_till_date_count,
              investment_limit,
              investment_limit_currency,
              amount_sanctioned_date,
              total_investment_so_far,
            },
            consent_report: consentReport,
            additional_ca_data,
            fund_currency,
            fund_type_id,
          },
        } = response.data;

        let overall_investment_limit = 0,
          errors = {},
          overall_drawdown = 0;
        if (additional_ca_data?.length > 0) {
          additional_ca_data.forEach(item => {
            overall_investment_limit += parseInt(
              item?.total_commitment_over_five_Years
            );
          });
          overall_drawdown += additional_ca_data?.length;
        }
        overall_investment_limit += parseInt(investment_limit);

        // Fetching currency foreach transaction
        let curr_symbol = fund_currency === "INR" ? "₹" : "US$";
        consentReport = consentReport.map(item => {
          // let currency = checkIntentCurrency(
          //   item?.userCurrency,
          //   item.startupCurrency,
          //   item?.tax_return_filling_countries
          // );

          if (fund_currency === "INR") {
            return {
              currency: curr_symbol,
              consentAmount: fetchConsentAmount({
                baseAmount: item?.consent_amount,
                fee:
                  typeof item?.management_fees === "undefined"
                    ? calculateBaseConsentAmountFeeINR({
                        consent_amount: item?.consent_amount,
                        transaction_fees: item?.transaction_fees,
                        iv_mapped_transaction_fees:
                          item?.iv_mapped_transaction_fees,
                        iv_mapped_transaction_fees_cap_table:
                          item?.iv_mapped_transaction_fees_cap_table,
                        transaction_fees_cap_table:
                          item?.transaction_fees_cap_table,
                        min_amount_cap_amount_tab:
                          item?.min_amount_cap_amount_tab,
                      })
                    : parseInt(item?.management_fees),
              }),
              baseConsentAmount: `${curr_symbol}${toCommaFormat(
                item?.consent_amount
              )}`,
              baseConsentAmountFee: `${curr_symbol}${toCommaFormat(
                typeof item?.management_fees === "undefined"
                  ? calculateBaseConsentAmountFeeINR({
                      consent_amount: item?.consent_amount,
                      transaction_fees: item?.transaction_fees,
                      iv_mapped_transaction_fees:
                        item?.iv_mapped_transaction_fees,
                      iv_mapped_transaction_fees_cap_table:
                        item?.iv_mapped_transaction_fees_cap_table,
                      transaction_fees_cap_table:
                        item?.transaction_fees_cap_table,
                      min_amount_cap_amount_tab:
                        item?.min_amount_cap_amount_tab,
                    })
                  : item?.management_fees
              )}`,
              approvedAt: item?.approvedAt,
              startup: item?.startup_name,
              syndicate: item?.syndicate,
              deal_month: item?.deal_month,
              contribution_limit_exceeded: item?.contribution_limit_exceeded,
              sanctioned_limit_exceeded: item?.sanctioned_limit_exceeded,
              // deal_type: item?.deal_type,
              // valuation_type: item?.valuation_type,
              funding_round: item?.funding_round,
              className: "text-black",
              sign: "-",
            };
          } else {
            return {
              currency: curr_symbol,
              consentAmount: fetchConsentAmount({
                baseAmount: item?.consent_amount_global || 0,
                fee:
                  typeof item?.management_fees_global === "undefined"
                    ? calculateBaseConsentAmountFeeForeign({
                        consent_amount_global: item?.consent_amount_global || 0,
                        transaction_fees: item?.transaction_fees,
                        iv_mapped_transaction_fees:
                          item?.iv_mapped_transaction_fees,
                        iv_mapped_transaction_fees_cap_table:
                          item?.iv_mapped_transaction_fees_cap_table,
                        transaction_fees_cap_table:
                          item?.transaction_fees_cap_table,
                        min_amount_cap_amount_tab:
                          item?.min_amount_cap_amount_tab,
                        min_amount_cap_amount_tab_usd:
                          item?.min_amount_cap_amount_tab_usd,
                      })
                    : parseInt(item?.management_fees_global),
              }),
              baseConsentAmount: `${curr_symbol}${toCommaFormat(
                item?.consent_amount_global || 0
              )}`,
              baseConsentAmountFee: `${curr_symbol}${toCommaFormat(
                typeof item?.management_fees_global === "undefined"
                  ? calculateBaseConsentAmountFeeForeign({
                      consent_amount_global: item?.consent_amount_global || 0,
                      transaction_fees: item?.transaction_fees,
                      iv_mapped_transaction_fees:
                        item?.iv_mapped_transaction_fees,
                      iv_mapped_transaction_fees_cap_table:
                        item?.iv_mapped_transaction_fees_cap_table,
                      transaction_fees_cap_table:
                        item?.transaction_fees_cap_table,
                      min_amount_cap_amount_tab:
                        item?.min_amount_cap_amount_tab,
                      min_amount_cap_amount_tab_usd:
                        item?.min_amount_cap_amount_tab_usd,
                    })
                  : item?.management_fees_global
              )}`,
              approvedAt: item?.approvedAt,
              startup: item?.startup_name,
              sanctioned_limit_exceeded: item?.sanctioned_limit_exceeded,
              contribution_limit_exceeded: item?.contribution_limit_exceeded,
              syndicate: item?.syndicate,
              deal_month: item?.deal_month,
              // deal_type: item?.deal_type,
              // valuation_type: item?.valuation_type,
              funding_round: item?.funding_round,
              className: "text-black",
              sign: "",
            };
          }
        });

        let defaultDataObj = {
          currency: curr_symbol,
          approvedAt: "--",
          startup: "--",
          deal_month: "--",
          syndicate: "--",
          baseConsentAmount: "--",
          baseConsentAmountFee: "--",
          sanctioned_limit_exceeded: false,
          // deal_type: item?.deal_type,
          // valuation_type: item?.valuation_type,
          funding_round: "--",
        };

        const getPercent = (amount_used, amount_allotted) => {
          if (amount_used && amount_used === 0) return 0;

          if (!amount_used) return 0;

          let num = (amount_used / amount_allotted) * 100;
          return num.toFixed(2);
        };

        let total_investment_amount = 0,
          drawdown_till_date = 0,
          ca_limit_exceeded_flag = false;
        consentReport.forEach(item => {
          if (!item?.contribution_limit_exceeded) {
            if (item?.currency === "₹") {
              total_investment_amount =
                total_investment_amount + item?.consentAmount;
              drawdown_till_date += 1;
            } else {
              total_investment_amount =
                total_investment_amount + item?.consentAmount;
              drawdown_till_date += 1;
            }
          }

          if (
            typeof item?.contribution_limit_exceeded !== "undefined" &&
            item?.contribution_limit_exceeded === true
          ) {
            ca_limit_exceeded_flag = true;
          }
        });

        let index = 0;
        if (total_investment_so_far !== parseInt(total_investment_amount)) {
          errors[
            `${index}`
          ] = `Deal level investment amount (${curr_symbol}${toCommaFormat(
            total_investment_amount
          )}) doesn't match the user level investment amount (${curr_symbol}${toCommaFormat(
            total_investment_so_far
          )}).`;
        }

        index++;
        if (ca_limit_exceeded_flag) {
          errors[
            `${index}`
          ] = `The total draw-down amount across deals has crossed the total contribution amount. The user needs to sign a new CA addendum.`;
        }

        // consentReport.forEach(item => {
        //   if (item?.currency === "$") {
        //     index = index + 1;
        //     errors[
        //       `${index}`
        //     ] = `Startup deal (${item?.startup}) from syndicate (${item?.syndicate}) has a drawdown in dollars ($).`;
        //   }
        // });

        let lastInvestmentDate = null;
        let tempConsentReport = [];
        let ca_data = [];
        if (additional_ca_data?.length > 0) {
          ca_data = additional_ca_data?.map(item => ({
            ...defaultDataObj,
            className: "text-success",
            sign: "+",
            approvedAt: item?.created_on,
            currency: item?.commitment_currency,
            consentAmount: parseInt(item?.total_commitment_over_five_Years),
          }));
        }

        if (consentReport.length > 0) {
          lastInvestmentDate =
            consentReport[consentReport.length - 1]?.approvedAt;

          // let ca_data = [];
          // if (additional_ca_data?.length > 0) {
          //   ca_data = additional_ca_data?.map(item => ({
          //     ...defaultDataObj,
          //     className: "text-success",
          //     sign: "+",
          //     approvedAt: item?.created_on,
          //     currency: item?.commitment_currency == "INR" ? "₹" : "$",
          //     consentAmount: parseInt(item?.total_commitment_over_five_Years),
          //   }));
          // }

          let sortableArray = [...consentReport, ...ca_data];
          sortableArray = sortDataInAsc(sortableArray);
          sortableArray = sortableArray?.map(item => ({
            ...item,
            approvedAt:
              typeof item?.approvedAt === "undefined"
                ? "--"
                : moment(item?.approvedAt).format("DD-MMM-YYYY"),
          }));

          tempConsentReport = [
            {
              ...defaultDataObj,
              consentAmount: investment_limit,
              className: "text-success",
              sign: "+",
              approvedAt: amount_sanctioned_date,
            },
            ...sortableArray,
            {
              ...defaultDataObj,
              consentAmount: overall_investment_limit - total_investment_amount,
              className: "text-black",
              sign: "",
              approvedAt: "--",
            },
          ];
        } else {
          tempConsentReport = [
            {
              ...defaultDataObj,
              consentAmount: investment_limit,

              className: "text-success",
              sign: "+",
              approvedAt: amount_sanctioned_date,
            },
            ...ca_data,
            {
              ...defaultDataObj,
              consentAmount: overall_investment_limit - total_investment_amount,
              className: "text-black",
              sign: "",
              approvedAt: "--",
            },
          ];
        }

        consentReport = tempConsentReport;
        setCaDdDetails({
          errors: errors,
          generalDetails: {
            fullname: `${userDetails?.firstname} ${userDetails?.lastname}`,
            email: userDetails?.email,
            investor_id: userDetails?.investor_id,
            relation: "self",
          },
          deductionDetails: {
            "Total investment left": `${
              investment_limit_currency === "INR" ? "₹" : "$"
            }${toCommaFormat(
              overall_investment_limit - total_investment_amount
            )}`,
            "Total investment limit sanctioned": `${
              investment_limit_currency === "INR" ? "₹" : "$"
            }${toCommaFormat(overall_investment_limit)}`,
          },
          reportDetails: {
            "Total Contribution Agreements till date":
              total_ca_till_date_count + overall_drawdown,
            "Total Draw Down till date": drawdown_till_date,
            "Total investment so far/Total investment limit sanctioned": `${
              investment_limit_currency === "INR" ? "₹" : "$"
            }${toCommaFormat(total_investment_amount)}/${
              investment_limit_currency === "INR" ? "₹" : "$"
            }${toCommaFormat(overall_investment_limit)}`,
            "Percentage used":
              getPercent(total_investment_amount, overall_investment_limit) +
              "%",
          },
          investor_id: userDetails?.investor_id,
          consentReport,
          fund_type_id: fund_type_id,
          fund_currency: fund_currency,
        });
        setReportPopup(true);
      })
      .catch(error => {
        //  console.log(error);
      });
  };

  const sortedUsers = users => {
    return users.sort((a, b) =>
      `${a?.kyc_details?.applicant_name}`
        .toLowerCase()
        .localeCompare(`${a?.kyc_details?.applicant_name}`.toLowerCase())
    );
  };

  const generateCsvDataForAllSaaS = async () => {
    setExportLoading(true);
    const saas_parent_id = authUser?.userInfo?.saas_parent_id;
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }

    //For defualt fund type data
    if (!url_fund_type_id) {
      url_fund_type_id = "64870dba37cf22f296df37fb";
    }

    if (selectedFundType?._id) {
      url_fund_type_id = selectedFundType?._id;
    }

    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/userReport/getkycAllData?saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}&fund_type_id=${url_fund_type_id}`,
      configHeaders
    );

    if (response.data.status === true) {
      let kycExportData = response.data.data.kycrelationdata;
      kycExportData = kycExportData.map((item, index) => ({
        id: index,
        ...item,
      }));

      if (kycExportData.length > 0) {
        let csvData = [],
          headers = [
            "Name",
            "Applicant Name",
            "Investor Folio",
            "Primary Email",
            "Primary Mobile",
            "Syndicate Manager Name",
            "Investment Manager Name",
            "Individual/Entity",
            "Domain Expert Name",
            "Onboarding Status",
            "C.A. Status",
            "Relation",
            "Nationailty",
            "PEP Status",
            "Occupation",
            "Father/Spouse Name",
            "DOB",
            "Tax ID proof number",
            "Permanent Address",
            "Communication Address same as Permanent Address",
            "Communication Address",
            "Overseas Address",
            "Gross Income",
            "Bank Name",
            "Bank Address",
            "Bank Account Number",
            "Bank Account Type",
            "Bank SWIFT Code",
            "Name In Bank Account",
            "Total commitment over 5 years",
            "IFSC Code",
            "City and Country Of Birth",
            "Is US Person?",
            "Is Citizen of Country Other than India",
            "Is Resident for TAX purpose in Country other than India",
            "Country 1",
            "Country 2",

            "TIN 1",
            "TIN 2",

            "Nominee 1 Name",
            "Nominee 1 Date of Birth",
            "Is Nominee 1 Minor",
            "Nominee 1 Share (%)",
            "Nominee 1 Relationship",
            "Nominee 1 Address",
            "Nominee 1 Guardian Name",
            "Nominee 1 Guardian DOB",
            "Nominee 1 Guardian Relationship with Nominee",
            "Nominee 1 Guardian Address",
            "Nominee 2 Name",
            "Nominee 2 Date of Birth",
            "Is Nominee 2 Minor",
            "Nominee 2 Share (%)",
            "Nominee 2 Relationship",
            "Nominee 2 Address",
            "Nominee 2 Guardian Name",
            "Nominee 2 Guardian DOB",
            "Nominee 2 Guardian Relationship with Nominee",
            "Nominee 2 Guardian Address",
            "Nominee 3 Name",
            "Nominee 3 Date of Birth",
            "Is Nominee 3 Minor",
            "Nominee 3 Share (%)",
            "Nominee 3 Relationship",
            "Nominee 3 Address",
            "Nominee 3 Guardian Name",
            "Nominee 3 Guardian DOB",
            "Nominee 3 Guardian Relationship with Nominee",
            "Nominee 3 Guardian Address",
            "Overseas Address",
            "Wish to Nominate",
            "Consent So Far",
            "Date/Time",
            "CA Amount",
          ];

        csvData.push(headers);
        let sorted = sortedUsers(kycExportData || []);

        sorted.forEach((invt, i) => {
          csvData.push([
            `${invt?.user?.fullUserName || ""}`,
            `${
              invt?.applicant_name &&
              !invt?.applicant_name?.toLowerCase().includes("undefined")
                ? invt?.applicant_name
                : invt?.user?.fullUserName || ""
            }`,
            `${getInvestorFolio(
              invt?.user?.fullUserName,
              invt?.mail_id,
              invt?.user?.email
            )}`,
            `${invt?.mail_id || invt?.user?.email || ""}`,
            `${invt?.contact_number || ""}`,
            `${
              invt?.user?.saas_parent?.syndicate_manager_detail?.fullUserName ||
              ""
            }`,
            `${invt?.user?.investment_manager_detail?.fullUserName || ""}`,
            `${invt?.kyc_type || ""}`,
            `${invt?.saas_name || ""}`,
            `${invt?.onboarding_status || ""}`,
            `${invt?.ca_status || ""}`,
            `${invt?.relation || ""}`,
            `${invt?.nationality || ""}`,
            `${invt?.politically_exposed_person_status || ""}`,
            `${invt?.occupation || ""}`,
            `${invt?.father_husband_name || ""}`,
            `${invt?.dob || ""}`,
            `${invt?.pan_number || ""}`,
            `${
              invt?.permanent_address_extra?.street_address ||
              invt?.permanent_address_extra?.address_line_2 ||
              ""
            }`,
            `${invt?.communication_address_same || ""}`,
            `${invt?.communication_address || invt?.address || ""}`,
            `${invt?.overseas_address || ""}`,
            `${invt?.gross_annual_income || ""}`,
            `${invt?.bank_name || ""}`,
            `${invt?.bank_address || ""}`,
            `${invt?.account_number || ""}`,
            `${invt?.bank_type || ""}`,
            `${invt?.swift_code || ""}`,
            `${invt?.beneficiary_name || ""}`,
            `${invt?.total_commitment_over_five_Years || ""}`,
            `${invt?.ifsc_code || ""}`,
            `${invt?.city || ""} ${invt?.country || ""}`,
            `${invt?.is_us_person || ""}`,
            `${invt?.is_citizenship_outside_india || ""}`,
            `${invt?.is_outside_tex_residency || ""}`,
            `${invt?.country_1 || ""}`,
            `${invt?.country_2 || ""}`,

            `${invt?.tin_1 || ""}`,
            `${invt?.tin_2 || ""}`,

            `${
              (invt?.nominee_details?.length > 0 &&
                invt?.nominee_details[0]?.name) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 0 &&
                invt?.nominee_details[0]?.dob) ||
              ""
            }`,
            `${
              invt?.nominee_details?.length > 0 &&
              invt?.nominee_details[0]?.dob &&
              moment(invt?.nominee_details[0]?.dob).isValid()
                ? moment().diff(invt?.nominee_details[0]?.dob, "years") < 18
                  ? "Yes"
                  : "No"
                : ""
            }`,
            `${
              (invt?.nominee_details?.length > 0 &&
                invt?.nominee_details[0]?.share) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 0 &&
                invt?.nominee_details[0]?.relation_with_contributor) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 0 &&
                invt?.nominee_details[0]?.address) ||
              ""
            }`,

            `${
              (invt?.guardian_details?.length > 0 &&
                invt?.guardian_details[0]?.name) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 0 &&
                invt?.guardian_details[0]?.dob) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 0 &&
                invt?.guardian_details[0]?.relation_with_nominee) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 0 &&
                invt?.guardian_details[0]?.address) ||
              ""
            }`,

            `${
              (invt?.nominee_details?.length > 1 &&
                invt?.nominee_details[1]?.name) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 1 &&
                invt?.nominee_details[1]?.dob) ||
              ""
            }`,
            `${
              invt?.nominee_details?.length > 1 &&
              invt?.nominee_details[1]?.dob &&
              moment(invt?.nominee_details[1]?.dob).isValid()
                ? moment().diff(invt?.nominee_details[1]?.dob, "years") < 18
                  ? "Yes"
                  : "No"
                : ""
            }`,
            `${
              (invt?.nominee_details?.length > 1 &&
                invt?.nominee_details[1]?.share) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 1 &&
                invt?.nominee_details[1]?.relation_with_contributor) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 1 &&
                invt?.nominee_details[1]?.address) ||
              ""
            }`,

            `${
              (invt?.guardian_details?.length > 1 &&
                invt?.guardian_details[1]?.name) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 1 &&
                invt?.guardian_details[1]?.dob) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 1 &&
                invt?.guardian_details[1]?.relation_with_nominee) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 1 &&
                invt?.guardian_details[1]?.address) ||
              ""
            }`,

            `${
              (invt?.nominee_details?.length > 2 &&
                invt?.nominee_details[2]?.name) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 2 &&
                invt?.nominee_details[2]?.dob) ||
              ""
            }`,
            `${
              invt?.nominee_details?.length > 2 &&
              invt?.nominee_details[2]?.dob &&
              moment(invt?.nominee_details[2]?.dob).isValid()
                ? moment().diff(invt?.nominee_details[2]?.dob, "years") < 18
                  ? "Yes"
                  : "No"
                : ""
            }`,
            `${
              (invt?.nominee_details?.length > 2 &&
                invt?.nominee_details[2]?.share) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 2 &&
                invt?.nominee_details[2]?.relation_with_contributor) ||
              ""
            }`,
            `${
              (invt?.nominee_details?.length > 2 &&
                invt?.nominee_details[2]?.address) ||
              ""
            }`,

            `${
              (invt?.guardian_details?.length > 2 &&
                invt?.guardian_details[2]?.name) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 2 &&
                invt?.guardian_details[2]?.dob) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 2 &&
                invt?.guardian_details[2]?.relation_with_nominee) ||
              ""
            }`,
            `${
              (invt?.guardian_details?.length > 2 &&
                invt?.guardian_details[2]?.address) ||
              ""
            }`,

            `${
              (invt?.nominee_details?.length > 2 &&
                invt?.overseas_address_extra?.street_address) ||
              invt?.overseas_address_extra?.address_line_2 ||
              ""
            }`,
            `${invt?.is_wish_nominee || ""}`,
            `${invt?.total_investment_so_far || ""}`,
            `${getMomentDateString(invt?.date)}`,
            `${invt?.ca_amount}`,
          ]);
        });

        setFormedCSVData(csvData);
        setExportLoading(false);
        setFormedCSVData([]);
      } else {
        setFormedCSVData([]);
        setExportLoading(false);
      }
    }
  };

  const generateCsvDataForAllSaaSIntentLog = async () => {
    setExportLoading(true);
    const saas_parent_id = authUser?.userInfo?.saas_parent_id;
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/userReport/getIntentLog?saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`,
      configHeaders
    );

    if (response.data.status === true) {
      let kycExportData = response.data.data.kycrelationdata;
      kycExportData = kycExportData.map((item, index) => ({
        id: index,
        ...item,
      }));
      // console.log(kycExportData);
      // return false;
      // removing similar records and keeping only the final status records
      let tempData = {};
      let name_entries = {};

      if (kycExportData.length > 0) {
        let csvData = [],
          headers = [
            "Name",
            "Applicant Name",
            "Investor Folio",
            "Primary Email",
            "Primary Mobile",
            "Individual/Entity",
            "Onboarding Status",
            "old_onboarding_status",
            "user_id",
            "intent_count",
            "intent_count_global",
            "intent_deal_ids",
            "intent_deal_ids_global",
            "intent_amt_inr",
            "intent_amt_usd",
            "C.A. Status",
            "Relation",
            "Nationailty",

            "Pan Number",
            "Wish to Nominate",
            "Source of Funds",
            "Date/Time",
          ];

        csvData.push(headers);
        let sorted = sortedUsers(kycExportData || []);
        sorted.forEach((invt, i) => {
          csvData.push([
            `${invt?.user?.fullname || ""}`,
            `${
              invt?.applicant_name &&
              !invt?.applicant_name.toLowerCase().includes("undefined")
                ? invt?.applicant_name
                : invt?.user?.fullname || ""
            }`,
            `${getInvestorFolio(
              invt?.user?.fullname,
              invt?.mail_id,
              invt?.user?.email
            )}`,
            `${invt?.mail_id || invt?.user?.email || ""}`,
            `${invt?.contact_number || ""}`,

            `${invt?.kyc_type || ""}`,

            `${invt?.onboarding_status || ""}`,
            `${invt?.old_onboarding_status || ""}`,
            `${invt?.user_id || ""}`,
            `${invt?.intent_count || ""}`,
            `${invt?.intent_count_global || ""}`,
            `${invt?.intent_deal_ids?.toString() || ""}`,
            `${invt?.intent_deal_ids_global?.toString() || ""}`,
            `${invt?.committed_amount || ""}`,
            `${invt?.dealCommittedAmount || ""}`,

            `${invt?.ca_status || ""}`,
            `${invt?.relation || ""}`,
            `${invt?.nationality || ""}`,
            `${invt?.pan_number || ""}`,

            `${invt?.individual_steps?.nominee?.is_wish_nominee || ""}`,
            `${invt?.individual_steps?.investment_details?.source_of_funds}`,
            `${getMomentDateString(invt?.date)}`,
          ]);
        });

        setFormedCSVData(csvData);
        setExportLoading(false);
        setFormedCSVData([]);
      } else {
        setFormedCSVData([]);
        setExportLoading(false);
      }
    }
  };

  const generateCsvDataForAllSaaSIntentDate = async () => {
    setExportLoading(true);
    const saas_parent_id = authUser?.userInfo?.saas_parent_id;
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/userReport/getIntentDate?saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`,
      configHeaders
    );

    if (response.data.status === true) {
      let kycExportData = response.data.data.kycrelationdata;
      kycExportData = kycExportData.map((item, index) => ({
        id: index,
        ...item,
      }));
      // console.log(kycExportData);
      // return false;
      // removing similar records and keeping only the final status records
      let tempData = {};
      let name_entries = {};

      if (kycExportData.length > 0) {
        let csvData = [],
          headers = [
            "Firstname",
            "Lastname",
            "Primary Email",
            "user_id",
            "KYC Status",
            "intent_deal_id",
            "deal_name",
            "deal_round",
            "deal_saas",
            "intent_amt_inr",
            "intent_amt_usd",
            "areYouIntrested",
            "Relation",
            "Intent Date Time",
          ];

        csvData.push(headers);
        let sorted = sortedUsers(kycExportData || []);
        sorted.forEach((invt, i) => {
          if (invt?.kyc_type == "Individual" && !invt?.relation) {
            invt.relation = "Self";
          }
          csvData.push([
            `${invt?.user?.firstname || ""}`,
            `${invt?.user?.lasttname || ""}`,
            `${invt?.user?.email || ""}`,
            `${invt?.user_id || ""}`,
            `${invt?.kyc_status || ""}`,
            `${invt?.deal_id_string || ""}`,
            `${invt?.startup_name || ""}`,
            `${invt?.funding_round || ""}`,
            `${invt?.saas_name || ""}`,
            `${invt?.committed_amount || ""}`,
            `${invt?.dealCommittedAmount || ""}`,
            `${invt?.areYouIntrested || ""}`,
            `${invt?.relation || ""}`,
            `${getMomentDateString(invt?.createdAt)}`,
          ]);
        });

        setFormedCSVData(csvData);
        setExportLoading(false);
        setFormedCSVData([]);
      } else {
        setFormedCSVData([]);
        setExportLoading(false);
      }
    }
  };

  const getItemStatusValue = (item) => {
    if (item === "Onboarding Complete, Approval Pending") {
      return "KYC Complete, Approval Pending"
    }
    return item
  }

  const handleUserLogin = async user => {
    try {
      let flag = await verifyAdminEmailOTP();

      if (!flag) {
        alert("Error while logging in to the user.");
        return;
      }

      //login admin as user
      let loggedUser;
      let url = process.env.REACT_APP_FRONTEND_URL;
      if (!url) {
        url = window.location.protocol + "//" + window.location.hostname;
      }
      loggedUser = await loginAsUser({ userId: user._id });

      if (loggedUser) {
        window.open(url + "/loginAsUser/" + loggedUser.token);
      }
    } catch (err) {
      //  console.log(err.response);
      return false;
    }
  };

  const defaultSorted = [
    {
      dataField: "date", //  if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  let userListColumns = [
    {
      text: "Name",
      dataField: "firstname",
      sort: false,
      filterValue: (cell, user) =>
        `${user?.user?.firstname} ${user?.user?.lastname}`,
      filter: textFilter({
        defaultValue: history.location.search.split("&firstname=")[1],
        delay: 1500,
      }),
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <div className="fw-bold">
              {user?.user?.firstname} {user?.user?.lastname}
            </div>
          </h5>
        </>
      ),
    },
    {
      dataField: "applicant_name",
      text: "Applicant name",
      filter: textFilter(),
      formatter: (cellContent, user) => (
        <>
          {user?.applicant_name} ({user?.relation || "Self"})
        </>
      ),
    },
    {
      dataField: "date",
      text: "Last Updated Date",
      formatter: (cellContent, user) => (
        <>{moment(new Date(user.date)).format("DD MMM Y")}</>
      ),
    },
    // {
    //   dataField: "relation",
    //   text: "Relation",
    //   formatter: (cellContent, user) => <>{user?.relation}</>,
    // },
    {
      dataField: "saas_parent_id",
      text: "Domain Expert Name",
      sort: false,
      formatter: (cellContent, user) => {
        let value = user?.saas_name;
        return <>{value}</>;
      },
      filter:
        is_super_admin || show_all_saas_data
          ? selectFilter({
              options: saasFilterOptions.sort((a, b) => {
                if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                  return -1;
                }
                if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                  return 1;
                }
                return 0;
              }),
              delay: 1500,
            })
          : false,
    },
    {
      dataField: "kyc_type",
      text: "Investor Type",
      filter: selectFilter({
        options: selectType.sort((a, b) => {
          if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
            return -1;
          }
          if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
        delay: 1500,
      }),

      formatter: (cellContent, user) => (
        <div className="text-capitalize">{user?.kyc_type}</div>
      ),
    },

    {
      dataField: "kyc_status",
      text: "KYC Status",
      style: {
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      filter: selectFilter({
        options: selectKycStatus.sort((a, b) => {
          if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
            return -1;
          }
          if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
        delay: 1500,
        defaultValue: selectKycStatus.find(person => person.fmode === fmode)
          ?.value,
      }),
      formatter: (cellContent, user) => (
        <div className="text-capitalize">{user?.kyc_status}</div>
      ),
    },
    {
      dataField: "comment",
      text: "Remarks",
      sort: false,
      editable: false,
      formatter: (cellContent, user) => {
        if (!!user?.comment) {
          return (
            <>
              <div className="d-flex gap-3">
                <Button
                  color="secondary"
                  className="inner"
                  onClick={() => {
                    setModelCommentData(user?.comment?.split("\n"));
                    setShowCommentModel(true);
                  }}
                >
                  View
                </Button>
              </div>
            </>
          );
        }
      },
    },
    {
      dataField: "investor_tags",
      text: "Approval Tags",
      sort: false,
      formatter: (cellContent, user) => {
        if (user?.investor_tags?.length > 0) {
          return (
            <div className="d-flex gap-3">
              <Button
                color="secondary"
                className="inner"
                onClick={() => {
                  let value = [];

                  user?.investor_tags?.map(e => {
                    if (e) {
                      value.push(e);
                    }
                  });

                  setApprovalModalData(value);
                  setApprovalModal(true);
                }}
              >
                View
              </Button>
            </div>
          );
        }
      },
    },
    {
      dataField: "investment_manager_id",
      text: "IM/DEM",
      filter: selectFilter({
        options: investmentManagers,
        delay: 1500,
      }),
      formatter: (cellContent, user) => {
        let value = [];
        let investment_manager_name =
          user?.user?.investment_manager_detail?.firstname;
        if (
          investment_manager_name &&
          user?.user?.investment_manager_detail?.lastname
        ) {
          investment_manager_name =
            investment_manager_name +
            " " +
            user?.user?.investment_manager_detail?.lastname;
        }

        if (investment_manager_name) {
          value.push(investment_manager_name);
        }

        let syndicate_manager_name =
          user?.user?.saas_parent?.syndicate_manager_detail?.firstname;

        if (
          syndicate_manager_name &&
          user?.user?.saas_parent?.syndicate_manager_detail?.lastname
        ) {
          syndicate_manager_name =
            syndicate_manager_name +
            " " +
            user?.user?.saas_parent?.syndicate_manager_detail?.lastname;
        }
        if (syndicate_manager_name) {
          value.push(syndicate_manager_name);
        }
        value = value?.filter(data => !!data)?.join(", ");
        return <>{value}</>;
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      csvExport: false,
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          {access?.userListLoginAction ? (
            <Link className="text-success" to="#" title="Login with this user">
              <i
                className="mdi mdi-share font-size-18"
                id="logintooltip"
                onClick={() => handleUserLogin(user.user)}
              ></i>
            </Link>
          ) : (
            ""
          )}

          {access?.view_kyc_logs && (
            <Button
              color="secondary"
              className="inner"
              onClick={async () => {
                try {
                  setLogsModalData({});

                  let investor_id = user.user?._id;
                  let investor_type = "";
                  let email_id = "",
                    contact_number = "",
                    country_code = "",
                    pan_number = "",
                    kyc_type = "",
                    kyc_id = "",
                    fund_type_id = "",
                    user_id = "";

                  if (
                    ["", null, undefined, "null", "undefined", "Self"].includes(
                      user?.relation
                    )
                  ) {
                    email_id = user?.user?.email;
                    investor_type = "Individual";
                    pan_number = user?.pan_number;
                    fund_type_id = user?.fund_type_id?._id;
                    kyc_type = user?.kyc_type;
                    kyc_id = user?.kyc_id;
                    user_id = user?.user_id;
                  } else if (user?.relation === "Entity") {
                    investor_type = "Entity";
                    email_id = user?.mail_id;
                    pan_number = user?.pan_number;
                    fund_type_id = user?.fund_type_id?._id;
                    kyc_type = user?.kyc_type;
                    kyc_id = user?.kyc_id;
                    user_id = user?.user_id;
                  } else {
                    investor_type = "Relative";
                    email_id = user?.mail_id;
                    contact_number = user?.contact_number;
                    country_code = user?.country_code;
                    pan_number = user?.pan_number;
                    fund_type_id = user?.fund_type_id?._id;
                    kyc_type = user?.kyc_type;
                    kyc_id = user?.kyc_id;
                    user_id = user?.user_id;
                  }
                  let params = {
                    investor_id: investor_id,
                    investor_type: investor_type,
                    email: email_id,
                    pan_card: pan_number,
                    country_code: country_code,
                    contact_number: contact_number,
                    kyc_type,
                    kyc_id,
                    fund_type_id,
                    user_id,
                  };

                  let data = await getInvestorKycUpdateLogs(params);
                  if (!data.status) return alert(data.message);
                  setLogsModalData(data.data);
                  setFundCurrency(data?.fundCurrency?.currency || "INR")
                  setLogsModal(true);
                } catch (err) {
                  setLogsModal(false);
                  return alert(
                    err?.response?.data?.message ||
                      err?.message ||
                      "Cannot get past logs"
                  );
                }
              }}
            >
              Logs
            </Button>
          )}
        </div>
      ),
    },
  ];

  if (access?.view_ca_reports) {
    userListColumns?.splice(9, 0, {
      dataField: "",
      text: "CA Report",
      formatter: (cellContent, user) => {
        let userDetails = {
          firstname: user?.user?.firstname,
          lastname: user?.user?.lastname,
          relation_key: `${user?.relation} (${user?.applicant_name})`,
          pan_number: user?.pan_number || "",
          email: user?.user?.email,
          investor_id: user?.user_id,
          relation:
            user?.kyc_type === "Individual"
              ? "self"
              : user?.fund_type_id?.currency === "INR"
              ? user?.pan_number
              : user?.passport_number,
          fund_type_id: user?.fund_type_id?._id?.toString(),
          fund_type: user?.fund_type_id?.label,
          fund_currency: user?.fund_type_id?.currency,
        };

        if (
          user.kyc_status ===
          "Contribution Agreement Signed by All - Process Completed"
        ) {
          // return caDdCount && caDdCount.user_id === user?.user_id ? (
          //   <p>{caDdCount?.count_cc_dd}</p>
          // ) : (
          //   <>
          //     <p onClick={() => fetchInvestDetail(user?.user_id)}>View Data</p>
          //   </>
          // );
          return (
            <>
              <p
                style={{ color: "#34C38F", cursor: "pointer" }}
                onClick={() => handleReport(userDetails)}
              >
                {user?.ledgerStats || "--"}
              </p>
            </>
          );
        }
      },
    });
  }

  const onTableChange = async (action, { filters, sortOrder, sortField }) => {
    let str = "";
    Object.keys(filters).forEach(filter => {
      let filterVal = filters?.[filter]?.filterVal;
      if (filterVal) {
        // console.log(filterVal);
        switch (filter) {
          case "user_types[0].user_type[0]":
            str += `&user_type=${filterVal}`;
            break;
          case "status":
            str += `&status=${
              "disabled".startsWith(filterVal.toLowerCase())
                ? "Declined"
                : filterVal
            }`;
            break;
          default:
            str += `&${filter}=${filterVal}`;
            break;
        }
      }
    });
    let order = sortOrder === "asc" ? 1 : -1;
    str += `&sort=${
      sortField === "_id" ? "createdAt" : sortField
    }&order=${order}`;
    setSearchQuery(str);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {from} to {to} of {size}
    </span>
  );

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    paginationTotalRenderer: customTotal,
    onPageChange: async (page, size) => {
      setPage(page);
    },
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Onboarding List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          <Row className="d-flex align-items-center">
            <Col sm="5">
              <h5 className="text-uppercase">Onboarding List</h5>
            </Col>
            <Col sm="4">
              <>
                <div className="text-start">
                  <Label>Select Fund Type</Label>
                </div>
                <div className="mb-4 align-items-center">
                  <Select
                    placeholder={"Select Fund Type"}
                    options={fundTypeList}
                    onChange={e => setSelectedFundType(e)}
                    value={selectedFundType?._id ? selectedFundType : null}
                  />
                </div>
              </>
            </Col>
            {access?.export_kyc_data && (
              <Col sm="3" className="d-flex justify-content-end">
                {/* {exportLoading ? (
                  <button
                    className="btn btn-success"
                    onClick={() => generateCsvDataForAllSaaSIntentDate()}
                  >
                    <Spinner animation="border" size="sm" />
                    Export IntentLog Data CSV
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={() => generateCsvDataForAllSaaSIntentDate()}
                  >
                    <i className="bx bx-export fs-4 mr-3"></i>Export IntentLog Data
                    CSV
                  </button>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp; */}
                {exportLoading ? (
                  <button
                    className="btn btn-success"
                    onClick={() => generateCsvDataForAllSaaS()}
                  >
                    <Spinner animation="border" size="sm" />
                    Export KYC Data CSV
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={() => generateCsvDataForAllSaaS()}
                  >
                    <i className="bx bx-export fs-4 mr-3"></i>Export KYC Data
                    CSV
                  </button>
                )}
                {formedCSVData.length > 0 ? (
                  <CSVDownload data={formedCSVData} target="_blank" />
                ) : null}
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-0">
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={userListColumns}
                    data={kycdata}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={kycdata}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                    // rowStyle={row => ({
                                    //   backgroundColor: row?.is_saas_parent
                                    //     ? "lightgray"
                                    //     : "",
                                    //   fontWeight: row?.is_saas_parent
                                    //     ? "600"
                                    //     : "0",
                                    // })}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" show={logsModalOpen} toggle={() => toggleLogsModal()}>
        <ModalHeader toggle={() => toggleLogsModal()} tag="h4">
          KYC status/CA status Report Past Logs
        </ModalHeader>
        <Modal.Body>
          {!isEmpty(logsModalData) && (
            <>
              <Accordion defaultActiveKey={["0", "1", "2", "3"]}>
                {logsModalData?.added?.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Values Added</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive int_table">
                        <div
                          className="react-bootstrap-table"
                          style={{ height: "300px" }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Old Status</th>
                                <th>New Status</th>
                                <th>Source</th>
                                <th>Username</th>
                                <th>Email</th>
                                {/* <th>Update Source</th> */}
                                <th>Browser</th>
                                <th>OS</th>
                                <th>Device</th>
                                <th>Location</th>
                                <th>IP</th>
                                <th>Changed On</th>
                                {/* <th>Admin Email</th>
                                <th>Admin Name</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {filterKYCAddedData(logsModalData?.added)?.map(
                                item => {
                                  if (
                                    !item?.device ||
                                    !item?.browser ||
                                    !item?.os
                                  )
                                    return null;
                                  return (
                                    <tr>
                                      <td>{item?.status}</td>
                                      <td>{getItemStatusValue(item?.old_status)}</td>
                                      <td>{getItemStatusValue(item?.new_status)}</td>
                                      <td>{item?.source}</td>
                                      <td>{item?.user_name}</td>
                                      <td>{item?.user_email}</td>
                                      {/* <td>
                                          {added?.user_details?.user_name}
                                        </td>
                                        <td>
                                          {added?.user_details?.user_email}
                                        </td> */}
                                      <td>{item?.browser || ""}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {item?.os || ""}
                                      </td>
                                      <td>{item?.device || ""}</td>
                                      <td>{item?.location || ""}</td>
                                      <td>{item?.ip || ""}</td>
                                      <td>
                                        {moment(item?.changed_on).format(
                                          logsDateFormat
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {logsModalData?.updated?.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Values Changed</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive int_table">
                        <div
                          className="react-bootstrap-table"
                          style={{ height: "300px" }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Old Status</th>
                                <th>New Status</th>
                                <th>Source</th>
                                <th>Username</th>
                                <th>Email</th>
                                {/* <th>Update Source</th> */}
                                <th>Browser</th>
                                <th>OS</th>
                                <th>Device</th>
                                <th>Location</th>
                                <th>IP</th>
                                <th>Changed On</th>
                                {/* <th>Admin Email</th>
                                <th>Admin Name</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {logsModalData?.updated?.map((updated, index) => {
                                return Object.keys(updated).map(key => {
                                  if (
                                    key === "createdAt" ||
                                    key === "source" ||
                                    key === "updatedBy" ||
                                    key === "user_details" ||
                                    key === "changedBy"
                                  )
                                    return null;

                                  let label = LabelSwitch(key);
                                  if (!label) return null;

                                  let values = updated[key];

                                  let oldValue = StatusSwitch(values.oldValue);
                                  let newValue = StatusSwitch(values.newValue);

                                  if (typeof oldValue === "object") {
                                    oldValue = JSON.stringify(oldValue);
                                  }
                                  if (typeof newValue === "object") {
                                    newValue = JSON.stringify(newValue);
                                  }

                                  return (
                                    <tr>
                                      <td>{capitalizeLabel(label)}</td>
                                      <td>{oldValue}</td>
                                      <td>{newValue}</td>
                                      <td>{`${updated?.source?.newValue}-${
                                        updated?.source?.adminDeviceName?.includes(
                                          "Desktop"
                                        )
                                          ? "website"
                                          : "mobile"
                                      }`}</td>
                                      <td>{updated?.source?.adminUsername}</td>
                                      <td>{updated?.source?.adminEmail}</td>
                                      {/* <td>
                                        {updated?.user_details?.user_name}
                                      </td>
                                      <td>
                                        {updated?.user_details?.user_email}
                                      </td> */}
                                      <td>
                                        {updated?.source?.adminBrowserName ||
                                          ""}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {updated?.source?.adminOsName || ""}
                                      </td>
                                      <td>
                                        {updated?.source?.adminDeviceName || ""}
                                      </td>
                                      <td>
                                        {updated?.source?.adminLocation || ""}
                                      </td>
                                      <td>
                                        {updated?.source?.adminIpAddress || ""}
                                      </td>
                                      {/* <td>{updated?.source?.newValue}</td> */}
                                      {/* <td>{updated?.source?.adminUsername}</td>
                                      <td>{updated?.source?.adminEmail}</td> */}
                                      <td>
                                        {moment(updated["createdAt"]).format(
                                          logsDateFormat
                                        )}
                                      </td>
                                    </tr>
                                  );
                                });
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {logsModalData?.kycChangeData?.length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Kyc Data Update</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive int_table">
                        <div
                          className="react-bootstrap-table"
                          style={{ height: "300px" }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Field</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                                <th>Source</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Browser</th>
                                <th>OS</th>
                                <th>Device</th>
                                <th>Location</th>
                                <th>IP</th>
                                <th>Changed On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {logsModalData?.kycChangeData?.map(
                                (item, index) => {
                                  if (item["Field"] === "Total Commitment Over Five Years" || item["Field"] === "Gross Annual Income") {
                                    let amountStringOld = item["Old Value"] === "--" ? JSON.stringify(item["Old Value"]) : formatNumberToCommaSeparatedCurrency(parseInt(item["Old Value"]), CURRENCY_MAP[fundCurrency])
                                    let amountStringNew = formatNumberToCommaSeparatedCurrency(parseInt(item["New Value"]), CURRENCY_MAP[fundCurrency])
                                    return (
                                      <tr>
                                        <td>{item["Field"]}</td>
                                        <td>
                                          {amountStringOld}
                                        </td>
                                        <td>
                                          {amountStringNew}
                                        </td>
                                        <td>{item["Source"]}</td>
                                        <td>{item["Username"]}</td>
                                        <td>{item["Email"]}</td>
                                        <td>{item["Browser"]}</td>
                                        <td>{item["OS"]}</td>
                                        <td>{item["Device"]}</td>
                                        <td>{item["Location"]}</td>
                                        <td>{item["IP"]}</td>
                                        <td>
                                          {moment(item["Changed On"]).format(
                                            logsDateFormat
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return (
                                    <tr>
                                      <td>{item["Field"]}</td>
                                      {item["File Type"] === "link" &&
                                      item["Old Value"]?.includes(
                                        "https://"
                                      ) ? (
                                        <td>
                                          <a
                                            href={item["Old Value"]}
                                            target="_blank"
                                          >
                                            Old Upload
                                          </a>
                                        </td>
                                      ) : (
                                        <td>
                                          {JSON.stringify(item["Old Value"])}
                                        </td>
                                      )}
                                      {item["File Type"] === "link" &&
                                      item["New Value"]?.includes(
                                        "https://"
                                      ) ? (
                                        <td>
                                          <a
                                            href={item["New Value"]}
                                            target="_blank"
                                          >
                                            New Upload
                                          </a>
                                        </td>
                                      ) : (
                                        <td>
                                          {JSON.stringify(item["New Value"])}
                                        </td>
                                      )}
                                      <td>{item["Source"]}</td>
                                      <td>{item["Username"]}</td>
                                      <td>{item["Email"]}</td>
                                      <td>{item["Browser"]}</td>
                                      <td>{item["OS"]}</td>
                                      <td>{item["Device"]}</td>
                                      <td>{item["Location"]}</td>
                                      <td>{item["IP"]}</td>
                                      <td>
                                        {moment(item["Changed On"]).format(
                                          logsDateFormat
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {logsModalData?.kycAdminChangeData?.length > 0 && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      For admin purpose/use only
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive int_table">
                        <div
                          className="react-bootstrap-table"
                          style={{ height: "300px" }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Field</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                                <th>Source</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Browser</th>
                                <th>OS</th>
                                <th>Device</th>
                                <th>Location</th>
                                <th>IP</th>
                                <th>Changed On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {logsModalData?.kycAdminChangeData?.map(
                                (item, index) => {
                                  return (
                                    <tr>
                                      <td>{item["Field"]}</td>
                                      <td>
                                        {item["Field"] === "Date Of Birth" &&
                                        item["Old Value"] !== "--"
                                          ? moment(item["Old Value"]).format(
                                              "DD-MM-YYYY"
                                            )
                                          : item["Old Value"]}
                                      </td>
                                      <td>
                                        {item["Field"] === "Date Of Birth" &&
                                        item["New Value"] !== "--"
                                          ? moment(item["New Value"]).format(
                                              "DD-MM-YYYY"
                                            )
                                          : item["New Value"]}
                                      </td>
                                      <td>{item["Source"]}</td>
                                      <td>{item["Username"]}</td>
                                      <td>{item["Email"]}</td>
                                      <td>{item["Browser"]}</td>
                                      <td>{item["OS"]}</td>
                                      <td>{item["Device"]}</td>
                                      <td>{item["Location"]}</td>
                                      <td>{item["IP"]}</td>
                                      <td>
                                        {moment(item["Changed On"]).format(
                                          logsDateFormat
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </>
          )}
          {(isEmpty(logsModalData) ||
            Object.values(logsModalData).every(
              value => value?.length === 0
            )) && (
            <Row className="text-center">
              <Col>
                <Label tag="h4">Past Logs Not Available</Label>
              </Col>
            </Row>
          )}
          <ModalFooter>
            <Row>
              <Col lg={12}>
                <Button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setLogsModal(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal.Body>
      </Modal>
      {
        <Modal
          size="lg"
          className="report-modal"
          // style={{ width: "1000px" }}
          show={reportPopup}
          onHide={() => {
            setReportPopup(false);
            setCaDdDetails(null);
            setRelatives(null);
            setCurrentySelectedRelative(relatives[0]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="tabsection">
                <Tabs
                  defaultActiveKey={"CA Ledger"}
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey={"CA Ledger"} title={"CA Ledger"}>
                    <LedgerDetails
                      relatives={relatives}
                      currentlySelectedRelative={
                        relatives === null || relatives[0]?.value === "self"
                          ? currentlySelectedRelative
                          : relatives[0]
                      }
                      setCurrentySelectedRelative={setCurrentySelectedRelative}
                      handleReport={handleReport}
                      caDdDetails={caDdDetails}
                    />
                  </Tab>
                  {/* {caDdDetails?.fund_currency === "INR" && ( */}
                  <Tab eventKey={"Sign CA"} title={"Sign CA"}>
                    <SignupLedger
                      relatives={relatives}
                      currentlySelectedRelative={currentlySelectedRelative}
                      investor={caDdDetails?.investor_id}
                      fund_type_id={caDdDetails?.fund_type_id}
                      fund_currency={caDdDetails?.fund_currency}
                    />
                  </Tab>
                  {/* )} */}
                </Tabs>
              </div>
            </>
          </Modal.Body>
        </Modal>
      }

      <Modal
        // className="auctionmodal multiplecomp contactModal"
        size="md"
        show={showCommentModel}
        onHide={() => {
          setShowCommentModel(false);
          setModelCommentData("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ textAlign: "center" }}>Remarks</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {modelCommentData?.length > 0 &&
              modelCommentData.map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        // className="auctionmodal multiplecomp contactModal"
        size="md"
        show={approvalModal}
        onHide={() => {
          setApprovalModal(false);
          setApprovalModalData("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ textAlign: "center" }}>Approval Tags</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {approvalModalData?.length > 0 &&
              approvalModalData.map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

KycList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
};
export default KycList;
