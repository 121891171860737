import { AvField, AvForm } from "availity-reactstrap-validation";
import { getAllUsersForReference } from "helpers/backend_helper";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { MetaTags } from "react-meta-tags";
import ReactQuill from "react-quill";
import logoLightSvg from "../../assets/images/removeIcon.svg";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import DataFetchLoader from "components/Common/DataFetchLoader";
import moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

const InvestorCommunication = () => {
  const defaultUserPic = "https://www.invstt.com/img/profile.png";

  const [message_title, setMessageTitle] = useState("");
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("user_info")) || {}
  );
  const [message_description, setMessageDescription] = useState("");
  const [saas_parent_list, setSaasParentList] = useState([]);
  const [select_saas_parent, setSelectedSaasParent] = useState("");
  const [saas_logo, setSaasLogo] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [previewRecipientModal, setPreviewRecipientModal] = useState(false);
  const [template, setTemplate] = useState("");
  const [markCC, setMarkCC] = useState("");
  const [attachFile, setAttachFile] = useState(null);
  const [userName, setUserName] = useState("");
  const [errors, setErrors] = useState({});
  const [userSaasName, setUserSaasName] = useState("");
  const [regardStatement, setRegardStatement] = useState("");
  const [selectedInvestorData, setSelectedInvestorData] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("all");
  const [checkAllInvestors, setCheckAllInvestors] = useState(false);
  const [checkAllInvestorsFund, setCheckAllInvestorsFund] = useState(false);
  const [checkAllInvestorsDeal, setCheckAllInvestorsDeal] = useState(false);
  const [inclusionCheck, setInclusionCheck] = useState(true);
  const [queryText, setQueryText] = useState("");
  const [userProfilePic, setUserProfilePic] = useState(defaultUserPic);
  const [fundTypeOptions, setFundTypeOptions] = useState([]);
  const [fundType, setFundType] = useState("");
  const [dealList, setDealList] = useState([]);
  const [fundUsers, setFundUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState("");
  const [dealUsers, setDealUsers] = useState([]);
  const [filteredDealUsers, setFilteredDealUsers] = useState([]);
  const [roleWiseAccess, setRoleWiseAccess] = useState({});
  const [roleLoaded, setRoleLoaded] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [tabType, setTabtype] = useState("selectInvestors");
  const ref = useRef();

  // Select Deal
  const [selectedDeals, setSelectedDeals] = useState([]);

  const getAttachmentText = () => {
    let attachText = "";
    if (attachFile) {
      attachText = `<div style="margin-top:20px">           
            <h5 className="text-gray mx-3">Attachments:</h5>
            <a className="mx-3" target="_blank" href=${
              attachFile && typeof attachFile === "object"
                ? URL.createObjectURL(attachFile)
                : ""
            }>
                ${attachFile?.name}
            </a>
            </div>`;
    }
    return attachText;
  };

  const baseTemplate = `<body>
    <table width="650" border="0" align="center" cellpadding="0" cellspacing="0" style="border-collapse: collapse;">
        <tbody>
            <tr>
                <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="20">
                        <tbody>
                            <tr>
                                <td align="right" width="650">
                                    <font style="font-family: Arial, 'sans-serif'; font-size: 12px; color:#271233;">
                                        <a href="https://www.invstt.com"
                                            style="color:#E27235; text-decoration: none;">Visit Invstt.com</a>
                                        |
                                        <a href="https://www.invstt.com/login"
                                            style="color:#E27235; text-decoration: none;">Login</a>
                                    </font>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td align="center">
                    <table style="min-width: 621px;" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td bgcolor="#efefef" style="border: 1px solid #e1e0df;">
                                    <table width="100%" border="0" align="center" cellpadding="15" cellspacing="0">
                                        <tbody>
                                            <tr>
                                                <td width="63%" align="left">
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="37%"><img
                                                                        src="https://www.invstt.com/img/invstt-logo.png"
                                                                        width="170" height="60" alt="" /></td>
                                                                <td width="6%" align="center">|</td>
                                                                <td width="37%"><img src="${saas_logo}" height="60"
                                                                        alt="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td width="37%" align="right">
                                                    <font style="
                                                      font-family: Arial, 'sans-serif';
                                                      font-size: 21px;
                                                      color: #271233;
                                                    ">Startup. Investment.<br />
                                                        <strong style="font-size: 21px; color: #e27235">Made
                                                            Easy.</strong>
                                                    </font>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>
                    <table width="621" border="0" align="center" cellpadding="15" cellspacing="0"
                        style="border: 1px solid #e1e0df">
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td width="50%" colspan="2" valign="top" style="padding: 0px;">
                                                <h3 style="text-align:center;color:black">${message_title}</h3>
                                                <span style="font-size: 16px; font-family: Calibri, 'Helvetica Neue', Helvetica, Arial, 'sans-serif'; color: #161616"><strong>Dear [Investor Name]</strong>,</span><br><br>
                                                    <div style="font-size: 16px; font-family: Calibri, 'Helvetica Neue', Helvetica, Arial, 'sans-serif'; color: #161616;word-break:break-word" class="react-quill-preview">
                                                    ${message_description}
                                                    <hr style="margin:0px"/>
                                                    ${regardStatement}
                                                    </div>
                                                ${getAttachmentText()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="20">
                        <tbody>
                            <tr>
                                <td width="50%" align="left">
                                    <font style="font-family: Arial, 'sans-serif'; font-size: 18px; color:#e27235;">
                                        <strong><a href="mailto:support@invstt.com">Team Invstt</a></strong>
                                    </font>
                                </td>
                                <td width="50%" align="right">
                                    <font style="font-family: Arial, 'sans-serif';font-size: 12px;color: #271233;">
                                      To Unsubscribe or change your setting
                                      <a href="https://www.invstt.com/NotificationSetting" style="color: #e27235">
                                      click here</a>
                                    </font>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
            </tr>
        </tbody>
    </table>
</body>`;

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {
          size: [],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "size",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "clean",
  ];

  const handlePreview = () => {
    setRegardStatement(`<br><p>Regards</p><span>${userName}<span>`);
    let newTemplate = baseTemplate;
    setTemplate(newTemplate);
    setPreviewModal(true);
  };

  const handleDealList = () => {
    let dealListing = [];
    let deals = dealList || [];
    deals.sort((a, b) =>
      a?.startup_id?.basic_information?.startup_name?.localeCompare(
        b?.startup_id?.basic_information?.startup_name
      )
    );
    let value;
    deals.map(deal => {
      value = deal?._id;
      deal.value = value;
      deal.label = (
        <div className="d-flex align-items-center">
          <div>
            <img
              height={"30px"}
              width={"30px"}
              src={
                deal?.startup_id?.basic_information?.startup_logo ||
                "https://www.invstt.com/img/eaiser-diversification.png"
              }
            ></img>
          </div>
          <div className="mx-3">
            <span>
              <strong>
                {deal?.startup_id?.basic_information?.startup_name}
              </strong>{" "}
              ({deal?.funding_information?.funding_round}) [
              {moment(deal?.funding_information?.deal_date).format("MMM YYYY")}]
              - {deal?.investmentCount || 0} Investors
            </span>
          </div>
        </div>
      );
      deal.searchText = `${deal?.startup_id?.basic_information?.startup_name} ${
        deal?.funding_information?.funding_round
      } ${moment(deal?.funding_information?.deal_date).format("MMM YYYY")}`;
      dealListing.push(deal);
      return null;
    });
    return dealListing;
  };

  const handleDealApply = async e => {
    setDealUsers([]);
    setFilteredDealUsers([])
    setLoading(true);
    setCheckAllInvestorsDeal(false);
    if (selectedDeals?.length === 0 || !selectedDeals) {
      toast.error("Please Select At least one Deal.")
      setLoading(false);
      return 
    }

    let dealIds = "";
    selectedDeals?.map(el => {
      dealIds += el?.value + ",";
    });
    setSelectedDeal("Deals Selected");
    const token = authHeader();
    await axios
      .get(
        process.env.REACT_APP_API_URL +
        `/api/admin/user/getusersbydeal?dealIds=${dealIds}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(response => {
        if (response?.status === 200) {
          let userSelection = response?.data?.data || []
          let deal_users = userSelection?.map(el => {
              let saasItem = saas_parent_list?.find(
                item =>
                  item?.value?.toString() === el?.saas_parent_id?.toString()
              );
              if (saasItem) {
                el.saas_name = saasItem?.saas_fullname;
              }
              el.checked = false;
              return el;
            })
            ?.sort((a, b) =>
            {
              if (a?.firstname + a?.lastname < b?.firstname + b?.lastname) { return -1; }
              if (a?.firstname + a?.lastname > b?.firstname + b?.lastname) { return 1; }
              return 0;
            }
            );
          setDealUsers(deal_users || []);
          checkFilterData(deal_users)
          setLoading(false);
        }
      })
      .catch(error => {
        setDealUsers([]);
        setFilteredDealUsers([])
        // console.log(error)
        setLoading(false);
      });
  };

  useEffect(() => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    const userInfo = authUser?.userInfo;

    let roleId = userInfo?.roleId;
    const user_id = userInfo?.user_id;

    if (roleId == null || roleId == "") {
      roleId = localStorageRoleid(authUser, user_id);
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        setRoleWiseAccess(res?.data?.roleWiseAccess);
        setRoleLoaded(true);
        // this.setState({ roleWiseAccess: res.data.roleWiseAccess });
      })
      .catch(error => {
        console.log("route-error", error?.message);
        setRoleLoaded(true);
      });

    if (true) {
      getAllUsersForReference()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let {
                _id,
                firstname,
                lastname,
                email,
                saas_name,
                saas_fullname,
                saas_logo,
                other_primary_saas,
              } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              let otherSaas = [];
              if (user?.other_primary_saas) {
                for (let other_saas of user.other_primary_saas) {
                  other_saas?.status === "accepted"
                    ? otherSaas.push(other_saas)
                    : [];
                }
              }

              if (user?.is_saas_parent) {
                temp = user?.saas_fullname + " - " + temp;
                saasPartners.push({
                  value: _id,
                  label: temp,
                  saas_name: saas_name,
                  saas_fullname: saas_fullname,
                  other_primary_saas: otherSaas,
                  saas_logo: saas_logo || "",
                });
              }

              return {
                value: _id,
                label: temp,
                saas_name: saas_name,
                saas_fullname: saas_fullname,
                other_primary_saas: other_primary_saas,
              };
            })
            .filter(data => !!data);

          saasPartners = saasPartners.sort((a, b) => {
            let name1 = a?.saas_fullname?.split(" ").join("");
            let name2 = b?.saas_fullname?.split(" ").join("");
            return name1?.localeCompare(name2);
          });

          setSaasParentList(saasPartners);
        })
        .catch(error => {
          console.trace(error);
        });
    }

    let saas_parent_id = userInfo?.saas_parent_id;
    if (userInfo?.is_saas_parent) {
      saas_parent_id = userInfo?.user_id;
    }

    let user_name = (userInfo?.firstname + " " + userInfo?.lastname)?.trim();
    let saas_fullname = userInfo?.saas_fullname;
    let profile_pic =
      userInfo?.profile_pic || "https://www.invstt.com/img/profile.png";
    let saas_logo = userInfo?.saas_logo;
    setUserProfilePic(profile_pic);
    setUserName(user_name);
    setUserSaasName(saas_fullname);
    setSaasLogo(saas_logo);

    if (!isEmpty(saas_parent_id) && userInfo?.is_super_admin === false) {
      getAllInvestorData(saas_parent_id);
    }
    return () => {};
  }, []);

  const localStorageRoleid = async (authUser, user_id) => {
    let roleId = "";
    let response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/user/getdetail/${user_id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        authUser.userInfo.roleId = res?.data?.user?.roleId;
        localStorage.setItem("authUser", JSON.stringify(authUser));
        roleId = res?.data?.user?.roleId;
        return roleId;
      }).catch((err) => {
        // console.log(err)
        return null
      })

    return response;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    let error = {};
    setDisableSend(true);
    if (
      userInfo?.is_super_admin === true &&
      (!select_saas_parent || select_saas_parent === "")
    ) {
      error = {
        ...error,
        select_saas_parent: "This field is required.",
      };
    }
    if (!message_title || message_title === "") {
      error = {
        ...error,
        message_title: "This field is required.",
      };
    }
    if (!message_description || message_description === "") {
      error = {
        ...error,
        message_description: "This field is required.",
      };
    }

    // console.log(attachFile)
    // if (attachFile && !MIME_TYPE_MAP?.find((el) => attachFile?.type)) {
    //   error = {
    //     ...error,
    //     attachFile: "Invalid File Type.",
    //   };
    // }

    let userIds = "";
    if (markCC) {
      userIds += userInfo?.user_id?.toString() + ","
    }
    let userCount = 0;
    if (selectedRadio === "all") {
      selectedInvestorData.map(el => {
        if (el?.checked) {
          userIds += el?._id.toString() + ",";
          userCount++;
        }
      });
    }
    if (selectedRadio === "fund") {
      fundUsers.map(el => {
        if (el?.checked && !userIds?.includes(el?._id)) {
          userIds += el?._id.toString() + ",";
          userCount++;
        }
      });
    }
    if (selectedRadio === "investment") {
      dealUsers.map(el => {
        if (el?.checked && !userIds?.includes(el?._id)) {
          userIds += el?._id.toString() + ",";
          userCount++;
        }
      });
    }

    if (userCount === 0) {
      error = {
        ...error,
        generalError: "Please Select atleast One User.",
      };
    }

    if (!isEmpty(error)) {
      toast.error("Please Fill the Required Values.");
      setErrors(error);
      setDisableSend(false);
      return 
    }

    let saas_parent_id = userInfo?.is_super_admin
      ? select_saas_parent
      : userInfo?.is_saas_parent
      ? userInfo?.user_id
      : userInfo?.saas_parent_id;

    const formData = new FormData();
    let data = {
      userIds: userIds,
      senderEmail: userInfo?.email,
      markCCSelf: false,
      title: message_title,
      content: message_description,
      saas_parent_id: saas_parent_id,
    }

    formData.append("image", attachFile);

    let encryptedQuery = encryptDataToArray(data)
    formData.append("q", JSON.stringify(encryptedQuery));
    const token = authHeader();

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/user/investor-communication-mail`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
      data: formData,
    };

    if (isEmpty(error)) {
      await axios(config)
        .then(response => {
          if (response?.status === 200) {
            toast.success("Your Message Has Been Sent.");
            setErrors({});
            setTimeout(() => {
              window.location.href = "/investor-communication";
            }, 2000);
          }
        })
        .catch(error => {
          let errorMessage =
            error?.response?.message || "Something went wrong.";
          toast.error(errorMessage);
          setDisableSend(false);
        });
      // alert("Your Message Has Been Sent.")
    }
  };

  const handleDealSelect = async e => {
    setSelectedDeals(e);
  };

  const handleRecipientModal = () => {
    if (userInfo?.is_super_admin === true && isEmpty(select_saas_parent)) {
      setErrors({ ...errors, select_saas_parent: "This field is required." });
    } else {
      if (disableSend) {
        setTabtype("previewTab")
      }
      setPreviewRecipientModal(true);
      // setSelectedRadio("all");
      setErrors({ ...errors, generalError: "", select_saas_parent: "" });
    }
    // setSelectedInvestorData(actualSelectedInvestorData)
  };

  const getAllInvestorData = async saas_parent_id => {
    const token = authHeader();
    setSelectedInvestorData([]);
    setFundUsers([]);
    setDealList([]);
    setSelectedDeals([]);
    setDealUsers([]);
    setFilteredDealUsers([]);
    setCheckAllInvestors(false);
    setCheckAllInvestorsDeal(false);
    setCheckAllInvestorsFund(false);
    setLoading(true);
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/admin/user/getinvestorsForInvCom?selectedSaasParent=${saas_parent_id}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(response => {
        if (response?.status === 200) {
          let investorData = response?.data?.allUserArray || [];
          investorData = investorData
            .map(el => {
              el.checked = false;
              el.username = (el?.firstname + " " + el?.lastname).trim();
              return el;
            })
            ?.sort((a, b) =>
              (a?.firstname + a?.lastname)?.localeCompare(
                b?.firstname + b?.lastname
              )
            );
          setSelectedInvestorData(investorData);
          if (saas_parent_list?.length > 0) {
            let saasItem = saas_parent_list?.find(
              el => el?.value === saas_parent_id
            );
            if (saasItem) {
              setUserSaasName(saasItem?.saas_fullname);
              setSaasLogo(saasItem?.saas_logo);
            }
          }
        }
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setSelectedInvestorData([]);
        setLoading(false);
      });
  };

  const getFundDetails = async () => {
    const token = authHeader();
    await axios
      .get(
        process.env.REACT_APP_API_URL + `/api/applyforfunding/getFundDetails`,
        { headers: { Authorization: `${token}` } }
      )
      .then(response => {
        if (response?.status === 200) {
          setFundTypeOptions(response?.data?.data || []);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const getDealList = async () => {
    setLoading(true);
    const token = authHeader();
    let saas_parent_id = userInfo?.saas_parent_id;
    if (userInfo?.is_saas_parent) {
      saas_parent_id = userInfo?.user_id;
    }
    if (isEmpty(saas_parent_id) || userInfo?.is_super_admin) {
      saas_parent_id = select_saas_parent;
    }
    if (!isEmpty(saas_parent_id)) {
      await axios
        .get(
          process.env.REACT_APP_API_URL +
            `/api/admin/user/getdealListForInvCom?selectedSaasParent=${saas_parent_id}`,
          { headers: { Authorization: `${token}` } }
        )
        .then(response => {
          if (response?.status === 200) {
            setDealList(response?.data?.data || []);
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
          setDealList([]);
        });
    }
  };

  const getInvestorByFund = async fund_type_id => {
    setLoading(true);
    const token = authHeader();
    let saas_parent_id = userInfo?.saas_parent_id;
    if (userInfo?.is_saas_parent) {
      saas_parent_id = userInfo?.user_id;
    }
    if (select_saas_parent !== "") {
      saas_parent_id = select_saas_parent;
    }
    if (isEmpty(saas_parent_id)) {
      return;
    }

    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/admin/user/getusersForInvCom?selectedSaasParent=${saas_parent_id}&fund_type_id=${fund_type_id}&pageNo=1&limit=10&sortOrder=-1`,
        { headers: { Authorization: `${token}` } }
      )
      .then(response => {
        if (response?.status === 200) {
          let fundData = response?.data
            ?.map(el => {
              let saasItem = saas_parent_list?.find(
                item =>
                  item?.value?.toString() === el?.saas_parent_id?.toString()
              );
              if (saasItem) {
                el.saas_name = saasItem?.saas_fullname;
              }
              el.checked = false;
              return el;
            })
            ?.sort((a, b) =>
              (a?.firstname + a?.lastname)?.localeCompare(
                b?.firstname + b?.lastname
              )
            );
          setFundUsers(fundData || []);
          setLoading(false);
        }
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
  };

  const handleRadioChange = async val => {
    if (selectedRadio !== val) {
      handleClearCheck()
    }
    setSelectedRadio(val);
    setQueryText("");
  };

  const checkQueryText = el => {
    if (queryText === "") {
      return true;
    }
    let query = queryText?.toLowerCase();
    let username = (el?.firstname + " " + el?.lastname)?.toLowerCase();
    let saasname = el?.saas_name?.toLowerCase();
    let saas_detail = el?.saas_detail?.saas_name?.toLowerCase();
    let email = el?.email?.toLowerCase();
    // let username = el?.username?.toLowerCase()
    if (
      username?.includes(query) ||
      saasname?.includes(query) ||
      email?.includes(query) ||
      saas_detail?.includes(query)
    ) {
      return true;
    }

    return false;
  };

  const handleSelectAll = (val, type) => {
    if (type == "all") {
      setCheckAllInvestors(val);
      let investorData = selectedInvestorData
        .map(el => {
          el.checked = val;
          return el;
        })
        .sort((a, b) =>
          (a?.firstname + a?.lastname)?.localeCompare(
            b?.firstname + b?.lastname
          )
        );
      setSelectedInvestorData(investorData);
    } else if (type === "fund") {
      setCheckAllInvestorsFund(val);
      let investorData = fundUsers
        .map(el => {
          el.checked = val;
          return el;
        })
        .sort((a, b) =>
          (a?.firstname + a?.lastname)?.localeCompare(
            b?.firstname + b?.lastname
          )
        );
      setFundUsers(investorData);
    } else {
      setCheckAllInvestorsDeal(val);
      let investorData = filteredDealUsers
        .map(el => {
          el.checked = val;
          return el;
        })
        .sort((a, b) =>
          (a?.firstname + a?.lastname)?.localeCompare(
            b?.firstname + b?.lastname
          )
        );
      setDealUsers(investorData);
    }
  };

  const handleClearCheck = () => {
    let val = false
    setCheckAllInvestors(val);
    setCheckAllInvestorsDeal(val);
    setCheckAllInvestorsFund(val);

    let investorData = selectedInvestorData
      .map(el => {
        el.checked = val;
        return el;
      })
      .sort((a, b) =>
        (a?.firstname + a?.lastname)?.localeCompare(
          b?.firstname + b?.lastname
        )
      );

    let investorDataFund = fundUsers
      .map(el => {
        el.checked = val;
        return el;
      })
      .sort((a, b) =>
        (a?.firstname + a?.lastname)?.localeCompare(
          b?.firstname + b?.lastname
        )
      );

    let investorDataDeal = dealUsers
      .map(el => {
        el.checked = val;
        return el;
      })
      .sort((a, b) =>
        (a?.firstname + a?.lastname)?.localeCompare(
          b?.firstname + b?.lastname
        )
      );

    setSelectedInvestorData(investorData);
    setFundUsers(investorDataFund);
    setDealUsers(investorDataDeal);
  }

  const handleUserCheck = (val, email) => {
    let allCheck = true;
    let investorData = selectedInvestorData.map(el => {
      if (el?.email === email) {
        el.checked = val;
      }
      if (el.checked === false) {
        allCheck = false;
      }
      return el;
    });
    investorData = investorData?.sort((a, b) => {
      if (a?.checked && b?.checked) {
        return (a?.firstname + a?.lastname)?.localeCompare(
          b?.firstname + b?.lastname
        );
      } else {
        if (a?.checked && !b?.checked) {
          return -1;
        } else if (!a?.checked && b?.checked) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    setCheckAllInvestors(allCheck);
    setSelectedInvestorData(investorData);
  };

  const handleUserCheckFund = (val, email) => {
    let allCheck = true;
    let investorData = fundUsers.map(el => {
      if (el?.email === email) {
        el.checked = val;
      }
      if (el.checked === false) {
        allCheck = false;
      }
      return el;
    });
    investorData = investorData?.sort((a, b) => {
      if (a?.checked && b?.checked) {
        return (a?.firstname + a?.lastname)?.localeCompare(
          b?.firstname + b?.lastname
        );
      } else {
        if (a?.checked && !b?.checked) {
          return -1;
        } else if (!a?.checked && b?.checked) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    setCheckAllInvestorsFund(allCheck);
    setFundUsers(investorData);
  };

  const handleUserCheckDeal = (val, email) => {
    let allCheck = true;
    let investorData = dealUsers.map(el => {
      if (el?.email === email) {
        el.checked = val;
      }
      if (el.checked === false) {
        allCheck = false;
      }
      return el;
    });
    investorData = investorData?.sort((a, b) => {
      if (a?.checked && b?.checked) {
        return (a?.firstname + a?.lastname)?.localeCompare(
          b?.firstname + b?.lastname
        );
      } else {
        if (a?.checked && !b?.checked) {
          return -1;
        } else if (!a?.checked && b?.checked) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    setCheckAllInvestorsDeal(allCheck);
    setDealUsers(investorData);
  };

  const checkCount = data => {
    let count = 0;
    count = data?.filter(el => el?.checked)?.length || 0;
    return count;
  };

  const handleAttachFileChange = e => {
    let maxSize = 31457280;
    if (
      e.target?.files &&
      e.target?.files.length > 0 &&
      e.target?.files[0]?.size <= maxSize
    ) {
      const MIME_TYPE_MAP = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ];
      if (MIME_TYPE_MAP?.includes(e.target.files[0]?.type)) {
        setAttachFile(e.target.files[0]);
        setErrors({ ...errors, attachFile: "" });
      }
      else {
        setErrors({ ...errors, attachFile: "Invalid File Type" });
      }
    } else {
      setErrors({ ...errors, attachFile: "Max Size Limit is 30MB." });
    }
  };

  const getTotalSelected = () => {
    let count = 0;
    if (selectedRadio === "all") {
      count = checkCount(selectedInvestorData);
    } else if (selectedRadio === "investment") {
      count = checkCount(dealUsers);
    } else {
      count = checkCount(fundUsers);
    }
    if (count === 0) {
      return "";
    }
    return `(${count?.toLocaleString("en-IN")} Users)`;
  };

  const handleDeleteAttachment = () => {
    ref.current.value = "";
    setAttachFile(null);
  };

  useEffect(() => {
    if (dealUsers?.length) {
      checkFilterData()
    }
  }, [inclusionCheck, dealUsers])

  const checkFilterData = (userData) => {
    let allUsers
    allUsers = userData ? userData : JSON.parse(JSON.stringify(dealUsers))
    if (inclusionCheck) {
      allUsers = allUsers?.filter((el) => el?.drawdownRecieved === true)
      setFilteredDealUsers(allUsers)
    }
    else {
      allUsers = allUsers?.filter((el) => el?.drawdownRecieved === false)
      setFilteredDealUsers(allUsers)
    }
  }

  const NoOptionsMessage = type => {
    return (
      <p className="mt-3 d-flex align-items-center justify-content-center">
        {type === "deals" ? "No Deals Found." : "No Syndicate Found."}
      </p>
    );
  };

  return (
    <div className="d-flex flex-column page-content">
      <MetaTags>
        <title>Investor Communication | Invstt - Admin</title>
      </MetaTags>
      <ToastContainer />
      <Row style={{ marginLeft: "25px" }}>
        {roleWiseAccess?.investor_communication_access === true ? (
          <div className="">
            <div className="">
              <h4 className="mb-3">Message</h4>
              <AvForm>
                {userInfo?.is_super_admin && (
                  <FormGroup className="mb-2">
                    <Col lg="3">
                      <Label htmlFor="title" className="col-form-label">
                        Domain Expert Parent: <span className="text-danger">*</span>
                      </Label>
                    </Col>
                    <Col
                      lg="9"
                      style={{
                        maxWidth: "750px",
                        minWidth: "750px",
                      }}
                    >
                      <Select
                        options={saas_parent_list}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          NoOptionsMessage: () => NoOptionsMessage("syndicate"),
                        }}
                        onChange={async e => {
                          setSelectedSaasParent(e?.value);
                          await getAllInvestorData(e?.value);
                          if (e?.value !== "") {
                            setErrors({ ...errors, select_saas_parent: "" });
                          }
                        }}
                        // onChange={e => handleDealSelect(e)}
                        getOptionValue={opt => `${opt?.label}`}
                        placeholder="Select Domain Expert"
                        value={saas_parent_list?.filter(
                          el => el?.value === select_saas_parent
                        )}
                        // closeMenuOnSelect={false}
                      />
                      {errors && errors?.select_saas_parent && (
                        <div className="text-danger mt-2">
                          {errors?.select_saas_parent}
                        </div>
                      )}
                    </Col>
                  </FormGroup>
                )}
                <FormGroup className="">
                  {/* <Col lg="9"> */}
                  <Label className="col-form-label">
                    Recipients: <span className="text-danger">*</span>
                  </Label>
                  <div
                    className="d-flex align-items-center px-2 justify-content-between"
                    style={{
                      width: "200px",
                      cursor: "pointer",
                      padding: "4px",
                      border: "1px solid silver",
                      borderRadius: "10px",
                      opacity: disableSend ? "0.5" : 1,
                    }}
                    onClick={e => {
                      // if (!disableSend) {
                        handleRecipientModal(e);
                      // }
                    }}
                  >
                    Select Recipients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i
                      class="mdi mdi-chevron-down font-size-18"
                      title="Add Recipients"
                      id="edittooltip"
                    ></i>
                  </div>
                </FormGroup>
                {errors && errors?.generalError && (
                  <div className="text-danger mt-2">{errors?.generalError}</div>
                )}
                <FormGroup className="">
                  <Label className="col-form-label">
                    Title: <span className="text-danger">*</span>
                  </Label>
                  <AvField
                    type="text"
                    name="message_title"
                    className="form-control inputFocus"
                    placeholder="Enter Message Title"
                    // errorMessage="Required Field"
                    style={{
                      width: "50%",
                      minWidth: "750px",
                      borderRadius: "10px",
                    }}
                    onChange={e => {
                      setMessageTitle(e.currentTarget.value);
                      setErrors({ ...errors, message_title: "" });
                    }}
                    disabled={disableSend}
                    // required
                  ></AvField>
                  {errors && errors?.message_title && (
                    <span className="text-danger">{errors?.message_title}</span>
                  )}
                </FormGroup>
                <FormGroup className="" col style={{ height: "300px" }}>
                  <Col>
                    <Label className="col-form-label">
                      Description: <span className="text-danger">*</span>
                    </Label>
                  </Col>
                  <Col
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <ReactQuill
                      id="message_description"
                      style={{
                        height: "258px",
                        // width: "50%",
                        maxWidth: "750px",
                      }}
                      className="inputFocus"
                      placeholder="Enter Message"
                      value={message_description}
                      modules={eventDescriptionEditorModules}
                      formats={eventDescriptionEditorFormats}
                      onChange={(content, delta, source, editor) => {
                        setMessageDescription(content);
                        setErrors({ ...errors, message_description: "" });
                      }}
                      readOnly={disableSend}
                      // required
                    />
                    {errors && errors?.message_description && (
                      <div
                        style={{ marginTop: "50px", marginBottom: "50px" }}
                        className="text-danger"
                      >
                        {errors?.message_description}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup style={{ marginTop: "60px", maxWidth: "750px" }}>
                  <Label className="col-form-label">Attach Files: </Label>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <input
                        type="file"
                        name="attach_file"
                        className="form-control"
                        placeholder="Attach File"
                        style={{ borderRadius: "10px" }}
                        ref={ref}
                        disabled={disableSend}
                        onChange={e => handleAttachFileChange(e)}
                      ></input>
                      {attachFile && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteAttachment()}
                          class="text-danger mx-2"
                        >
                          <img width={"30px"} src={logoLightSvg} alt="Img" />
                        </span>
                      )}
                      <div
                        className="text-gray mx-2"
                        style={{ minWidth: "80px" }}
                      >
                        [Max 30 MB]
                      </div>
                    </div>
                    <div>
                      <FormGroup className="">
                        <Input
                          className=""
                          type="checkbox"
                          onChange={e => {
                            setMarkCC(e.target.checked);
                          }}
                        />{" "}
                        <span className="col-form-label">
                          &nbsp;Mark CC to Self
                        </span>
                      </FormGroup>
                    </div>
                  </div>
                  {errors && errors?.attachFile && (
                    <span className="text-danger">{errors?.attachFile}</span>
                  )}
                </FormGroup>

                <div>
                  <div
                    className="d-flex justify-content-center mt-3"
                    style={{ maxWidth: "650px" }}
                  >
                    <div className="">
                      <Button
                        type="submit"
                        color="secondary"
                        disabled={disableSend}
                        onClick={() => handlePreview()}
                      >
                        Preview
                      </Button>
                      <Button
                        type="reset"
                        color="secondary"
                        className="mx-5 text-center"
                        disabled={disableSend}
                        onClick={e => handleSubmit(e)}
                      >
                        &nbsp;&nbsp;{disableSend ? "Sending...." : "Send"}
                        &nbsp;&nbsp;
                      </Button>
                    </div>
                  </div>
                </div>
              </AvForm>
            </div>
          </div>
        ) : (
          roleLoaded === true && (
            <div>
              <h3>You do not have Access to this page.</h3>
            </div>
          )
        )}
      </Row>
      <Modal
        isOpen={previewModal}
        toggle={() => {
          setPreviewModal(!previewModal);
        }}
        size="lg"
      >
        <ModalHeader toggle={() => setPreviewModal(!previewModal)} tag="h4">
          <div className="d-flex">Mail Content Preview</div>
        </ModalHeader>
        <ModalBody>
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: baseTemplate }}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={previewRecipientModal}
        toggle={() => {
          setPreviewRecipientModal(!previewRecipientModal);
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => setPreviewRecipientModal(!previewRecipientModal)}
          tag="h4"
        >
          Recipients
        </ModalHeader>
        <ModalBody>
          <Tabs
            activeKey={tabType}
            onSelect={(e) => setTabtype(e)}
          >
            {!disableSend && (
              <Tab
              title="Select Investors"
              eventKey="selectInvestors"
              key="selectInvestors"
            >
              <div className="mt-3">
                <div className="d-flex align-items-center">
                  <div className="radio m-2">
                    <label
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value="accepted"
                        // style={{ marginRight: "5px" }}
                        checked={selectedRadio === "all"}
                        onClick={() => {
                          handleRadioChange("all");
                        }}
                      />
                      &nbsp;All Investors
                    </label>
                  </div>
                  <div className="radio m-2">
                    <label
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value="accepted"
                        // style={{ marginRight: "5px" }}
                        checked={selectedRadio === "investment"}
                        onClick={async () => {
                          handleRadioChange("investment");
                          // setSelectedDeal("");
                          await getDealList();
                        }}
                      />
                      &nbsp;By Investment
                    </label>
                  </div>
                  <div className="radio m-2">
                    <label
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value="accepted"
                        // style={{ marginRight: "5px" }}
                        checked={selectedRadio === "fund"}
                        onClick={async () => {
                          handleRadioChange("fund");
                          await getFundDetails();
                        }}
                      />
                      &nbsp;By Fund
                    </label>
                  </div>
                </div>
                <DataFetchLoader loading={loading} />
                {selectedRadio === "all" && (
                  <>
                    <div className="my-2 d-flex align-items-center">
                      <div>
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handleSelectAll(e.target.checked, "all");
                          }}
                          checked={checkAllInvestors}
                          className="mx-2"
                          defaultChecked={false}
                        />
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={e =>
                            handleSelectAll(!checkAllInvestors, "all")
                          }
                        >
                          Select All Investors
                        </span>
                      </div>
                      <div className="mx-3">
                        <Input
                          type="text"
                          className="form-control search-box inputFocus"
                          style={{ width: "250px", borderRadius: "15px" }}
                          placeholder="Search..."
                          value={queryText}
                          onChange={e => setQueryText(e.target.value)}
                        />
                      </div>
                    </div>
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <Table style={{ width: "100%" }} striped={true}>
                        <tbody>
                          {selectedInvestorData?.length > 0
                            ? selectedInvestorData?.map(el => {
                                if (checkQueryText(el)) {
                                  return (
                                    <tr>
                                      <td
                                        style={{ width: "50px" }}
                                        className="text-center"
                                      >
                                        <Input
                                          type="checkbox"
                                          onChange={e => {
                                            handleUserCheck(
                                              e.target.checked,
                                              el?.email
                                            );
                                            // handelChange(e, "exportUsers");
                                          }}
                                          checked={el?.checked}
                                          defaultChecked={false}
                                        />
                                      </td>
                                      <td>
                                        {el?.firstname || ""}{" "}
                                        {el?.lastname || ""} ({el?.email || ""})
                                        -{" "}
                                        {el?.saas_fullname ||
                                          el?.saas_detail?.saas_fullname ||
                                          ""}
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            : "No Data Available"}
                        </tbody>
                      </Table>
                    </div>
                    {selectedInvestorData?.length > 0 && (
                      <div className="mt-2">
                        Total -{" "}
                        {selectedInvestorData?.length?.toLocaleString(
                          "en-IN"
                        ) || 0}
                      </div>
                    )}
                  </>
                )}

                {selectedRadio === "fund" && (
                  <>
                    <div>
                      <select
                        type="select"
                        name="saas_parent"
                        className="form-control mx-2 inputFocus"
                        style={{ maxWidth: "200px", borderRadius: "10px" }}
                        onChange={async e => {
                          if (e.target.value !== "") {
                            setCheckAllInvestorsFund(false);
                            setFundType(e.target.value);
                            await getInvestorByFund(e.target.value);
                          }
                        }}
                        value={
                          fundTypeOptions?.find(el => el?._id === fundType)?._id
                        }
                        // required
                      >
                        <option value="">Select Fund Type</option>
                        {fundTypeOptions &&
                          fundTypeOptions.map(el => {
                            return (
                              <option key={el?.value} value={el?._id}>
                                {el?.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {fundType !== "" && (
                      <>
                        <div className="my-2 d-flex align-items-center">
                          <div>
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handleSelectAll(e.target.checked, "fund");
                              }}
                              checked={checkAllInvestorsFund}
                              className="mx-2"
                              defaultChecked={false}
                            />
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={e =>
                                handleSelectAll(!checkAllInvestorsFund, "fund")
                              }
                            >
                              Select All Investors
                            </span>
                          </div>
                          <div className="mx-3">
                            <Input
                              type="text"
                              className="form-control search-box inputFocus"
                              style={{ width: "250px", borderRadius: "15px" }}
                              placeholder="Search..."
                              value={queryText}
                              onChange={e => setQueryText(e.target.value)}
                            />
                          </div>
                        </div>
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          <Table style={{ width: "100%" }} striped={true}>
                            <tbody>
                              {fundUsers?.length > 0
                                ? fundUsers?.map(el => {
                                    if (checkQueryText(el)) {
                                      return (
                                        <tr>
                                          <td
                                            style={{ width: "50px" }}
                                            className="text-center"
                                          >
                                            <Input
                                              type="checkbox"
                                              onChange={e => {
                                                handleUserCheckFund(
                                                  e.target.checked,
                                                  el?.email
                                                );
                                                // handelChange(e, "exportUsers");
                                              }}
                                              checked={el?.checked}
                                              defaultChecked={false}
                                            />
                                          </td>
                                          <td>
                                            {el?.firstname || ""}{" "}
                                            {el?.lastname || ""} (
                                            {el?.email || ""}) -{" "}
                                            {el?.saas_name ||
                                              el?.saas_detail?.saas_name ||
                                              ""}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })
                                : "No Data Available"}
                            </tbody>
                          </Table>
                        </div>
                        {fundUsers?.length > 0 && (
                          <div className="mt-2">
                            Total -{" "}
                            {fundUsers?.length?.toLocaleString("en-IN") || 0}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {selectedRadio === "investment" && (
                  <>
                    <div className="d-flex justify-content-start align-items-center mb-2">
                      <h5 className="mx-2 mb-0">
                        Select Deals:
                      </h5>
                      <div>
                        <Input
                            type="radio"
                          onChange={e => {
                            setInclusionCheck(true);
                          }}
                            name="inclusion"
                          checked={inclusionCheck}
                            // defaultChecked={false}
                          className="mx-2"
                        />
                        <span
                          style={{ cursor: "pointer" }}
                            onClick={e => setInclusionCheck(true)}
                        >
                          Inclusion
                        </span>
                      </div>
                      <div>
                        <Input
                            type="radio"
                          onChange={e => {
                            setInclusionCheck(false);
                          }}
                            name="inclusion"
                            checked={!inclusionCheck}
                            // defaultChecked={false}
                          className="mx-2"
                        />
                        <span
                          style={{ cursor: "pointer" }}
                            onClick={e => setInclusionCheck(false)}
                        >
                          Exclusion
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      <div style={{ width: "100%" }}>
                        <Select
                          options={handleDealList()}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            NoOptionsMessage: () => NoOptionsMessage("deals"),
                          }}
                          onChange={e => handleDealSelect(e)}
                          getOptionValue={opt => `${opt?.searchText}`}
                          placeholder="Select Deals"
                          isMulti
                          value={selectedDeals}
                          closeMenuOnSelect={false}
                        />
                      </div>
                      <div className="">
                        <Button onClick={e => handleDealApply()}>Apply</Button>
                      </div>
                    </div>
                    {selectedDeal !== "" && (
                      <>
                        <div className="my-2 d-flex align-items-center">
                          <div>
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handleSelectAll(e.target.checked, "investment");
                              }}
                              checked={checkAllInvestorsDeal}
                              defaultChecked={false}
                              className="mx-2"
                            />
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={e =>
                                handleSelectAll(
                                  !checkAllInvestorsDeal,
                                  "investment"
                                )
                              }
                            >
                              Select All Investors
                            </span>
                          </div>
                          <div className="mx-3">
                            <Input
                              type="text"
                              className="form-control search-box inputFocus"
                              style={{ width: "250px", borderRadius: "15px" }}
                              placeholder="Search..."
                              value={queryText}
                              onChange={e => setQueryText(e.target.value)}
                            />
                          </div>
                        </div>
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          <Table style={{ width: "100%" }} striped={true}>
                            <tbody>
                                {filteredDealUsers?.length > 0
                                  ? filteredDealUsers?.map(el => {
                                    if (checkQueryText(el)) {
                                      return (
                                        <tr>
                                          <td
                                            style={{ width: "50px" }}
                                            className="text-center"
                                          >
                                            <Input
                                              type="checkbox"
                                              onChange={e => {
                                                handleUserCheckDeal(
                                                  e.target.checked,
                                                  el?.email
                                                );
                                                // handelChange(e, "exportUsers");
                                              }}
                                              checked={el?.checked}
                                              defaultChecked={false}
                                            />
                                          </td>
                                          <td>
                                            {el?.firstname || ""}{" "}
                                            {el?.lastname || ""} (
                                            {el?.email || ""}) -{" "}
                                            {el?.saas_name ||
                                              el?.saas_detail?.saas_name ||
                                              ""}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })
                                : "No Data Available"}
                            </tbody>
                          </Table>
                        </div>
                          {filteredDealUsers?.length > 0 && (
                          <div className="mt-2">
                            Total -{" "}
                              {filteredDealUsers?.length?.toLocaleString("en-IN") || 0}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Tab>
            )}
            <Tab
              title={`Preview ${getTotalSelected()}`}
              eventKey={"previewTab"}
              key={"previewTab"}
            >
              <div className="p-3">
                {checkCount(selectedInvestorData) > 0 &&
                  selectedRadio === "all" && (
                    <div className="byAllInvestors mt-2">
                      <div className="d-flex align-items-center">
                        <h5 className="">
                          By All Investors (
                          {checkCount(selectedInvestorData)?.toLocaleString(
                            "en-IN"
                          )}
                          )
                      </h5>
                      </div>
                      <hr />
                      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <Table style={{ width: "100%" }} striped={true}>
                          <tbody>
                            {selectedInvestorData?.length > 0
                              ? selectedInvestorData?.map(el => {
                                  if (el?.checked) {
                                    return (
                                      <tr>
                                        <td className="">
                                          {el?.firstname || ""}{" "}
                                          {el?.lastname || ""} (
                                          {el?.email || ""}) -{" "}
                                          {el?.saas_fullname ||
                                            el?.saas_detail?.saas_fullname ||
                                            ""}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              : "No Data Available"}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                {checkCount(dealUsers) > 0 && selectedRadio === "investment" && (
                  <div className="byFund mt-2">
                    <div className="d-flex align-items-center">
                      <h5 className="">
                        By Investment (
                        {checkCount(dealUsers)?.toLocaleString("en-IN")})
                      </h5>
                    </div>
                    <hr />
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <Table style={{ width: "100%" }} striped={true}>
                        <tbody>
                          {dealUsers?.length > 0
                            ? dealUsers?.map(el => {
                                if (el?.checked) {
                                  return (
                                    <tr>
                                      <td className="">
                                        {el?.firstname || ""}{" "}
                                        {el?.lastname || ""} ({el?.email || ""})
                                        -{" "}
                                        {el?.saas_name ||
                                          el?.saas_detail?.saas_name ||
                                          ""}
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            : "No Data Available"}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
                {checkCount(fundUsers) > 0 && selectedRadio === "fund" && (
                  <div className="byFund mt-2">
                    <div className="d-flex align-items-center">
                      <h5 className="">
                        By Fund Type (
                        {checkCount(fundUsers)?.toLocaleString("en-IN")})
                      </h5>
                    </div>
                    <hr />
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <Table style={{ width: "100%" }} striped={true}>
                        <tbody>
                          {fundUsers?.length > 0
                            ? fundUsers?.map(el => {
                                if (el?.checked) {
                                  return (
                                    <tr>
                                      <td className="">
                                        {el?.firstname || ""}{" "}
                                        {el?.lastname || ""} ({el?.email || ""})
                                        -{" "}
                                        {el?.saas_name ||
                                          el?.saas_detail?.saas_name ||
                                          ""}
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            : "No Data Available"}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InvestorCommunication;
