/* GENERAL POSTS */
// get
export const GET_GENERAL_POSTS = "GET_GENERAL_POSTS"
export const GET_GENERAL_POSTS_FAIL = "GET_GENERAL_POSTS_FAIL"
export const GET_GENERAL_POSTS_SUCCESS = "GET_GENERAL_POSTS_SUCCESS"
// add
export const ADD_GENERAL_POSTS = "ADD_GENERAL_POSTS"
export const ADD_GENERAL_POSTS_SUCCESS = "ADD_GENERAL_POSTS_SUCCESS"
export const ADD_GENERAL_POSTS_FAIL = "ADD_GENERAL_POSTS_FAIL"
// update
export const UPDATE_GENERAL_POSTS = "UPDATE_GENERAL_POSTS"
export const UPDATE_GENERAL_POSTS_SUCCESS = "UPDATE_GENERAL_POSTS_SUCCESS"
export const UPDATE_GENERAL_POSTS_FAIL = "UPDATE_GENERAL_POSTS_FAIL"
// delete
export const DELETE_GENERAL_POSTS = "DELETE_GENERAL_POSTS"
export const DELETE_GENERAL_POSTS_SUCCESS = "DELETE_GENERAL_POSTS_SUCCESS"
export const DELETE_GENERAL_POSTS_FAIL = "DELETE_GENERAL_POSTS_FAIL"
/* USER POSTS */
// get
export const GET_USER_POSTS = "GET_USER_POSTS"
export const GET_USER_POSTS_FAIL = "GET_USER_POSTS_FAIL"
export const GET_USER_POSTS_SUCCESS = "GET_USER_POSTS_SUCCESS"
// add
export const ADD_USER_POSTS = "ADD_USER_POSTS"
export const ADD_USER_POSTS_SUCCESS = "ADD_USER_POSTS_SUCCESS"
export const ADD_USER_POSTS_FAIL = "ADD_USER_POSTS_FAIL"
// update
export const UPDATE_USER_POSTS = "UPDATE_USER_POSTS"
export const UPDATE_USER_POSTS_SUCCESS = "UPDATE_USER_POSTS_SUCCESS"
export const UPDATE_USER_POSTS_FAIL = "UPDATE_USER_POSTS_FAIL"
// delete
export const DELETE_USER_POSTS = "DELETE_USER_POSTS"
export const DELETE_USER_POSTS_SUCCESS = "DELETE_USER_POSTS_SUCCESS"
export const DELETE_USER_POSTS_FAIL = "DELETE_USER_POSTS_FAIL"
