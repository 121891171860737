import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { isEmpty } from "lodash";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import {
  getFundingQuestions,
  deleteGroup,
  addNewQuestion,
  updateQuestion,
  deleteFundingQuestionFromAdmin,
  getInvestorsnew,
} from "../../helpers/backend_helper";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import * as moment from "moment";
import Select from "react-select";
import ReactQuill from "react-quill";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

function QuestionAnswers(props) {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({}); // single selected question for edit
  const [selectedQuestions, setSelectedQuestions] = useState([]); // selected questions for uplift

  const [allInvestors, setAllInvestors] = useState([]);
  const [selectedInvestors, setSelectedInvestors] = useState([]);

  const [errors, setErrors] = useState("");
  const [modal, setModal] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isEditAdminAction, setisEditAdminAction] = useState("Answered");
  const node = React.createRef();
  const [getUserRolles, setUserRolles] = useState({});
  const [copiedFrom, setCopiedFrom] = useState("");

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  useEffect(() => {
    setUserRolles(JSON.parse(localStorage.getItem("roleWiseAccess")));
    //fetch data from API
    fetchData();
    return () => {};
  }, []);

  const getAllInvestors = async () => {
    try {
      let investors = await getInvestorsnew();

      setAllInvestors(
        investors.map(({ _id, firstname, lastname, email }) => ({
          value: _id,
          label: (
            (firstname || "") +
            " " +
            (lastname || "") +
            " - " +
            (email || "")
          ).trim(),
        })) || []
      );
    } catch (error) {
      setAllInvestors([]);
    }
  };

  useEffect(() => {
    getAllInvestors();

    return () => {};
  }, []);

  const fetchData = () => {
    getFundingQuestions(props.appliedFundingDealId).then(res => {
      if (res && res?.questions?.length > 0) {
        setQuestions(res.questions);
      } else {
        setQuestions([]);
      }
    });
    if (props?.appliedFundingDeal?.copied_from)
      setCopiedFrom(props?.appliedFundingDeal?.copied_from);
  };

  const handleEditQuestion = async question => {
    if (question._id) {
      setisEdit(true);
      setSelectedQuestion(question);
      setSelectedInvestors(
        question.mapped_investors.map(investor => ({
          value: investor._id.toString(),
          label: investor.firstname + " " + investor.lastname,
        }))
      );
      toggle();
    } else {
    }
  };

  const handleDeleteQuestion = async question => {
    if (question._id && window.confirm("Are you sure, want to delete?")) {
      // await deleteGroup(question._id);
      await deleteFundingQuestionFromAdmin(question._id);
      onPaginationPageChange(1);
      await fetchData();
    }
  };

  const handleValidQuestionSubmit = async (e, formData) => {
    let status = { status: false };
    try {
      if (isEdit) {
        //update
        let data = {
          ...formData,
          mapped_investors: selectedInvestors.map(investor => investor.value),
          answer: selectedQuestion.answer,
        };
        let encryptedQuery = encryptDataToArray(data);
        let payload = {
          q: JSON.stringify(encryptedQuery),
        };
        status = await updateQuestion(selectedQuestion._id, payload);
      } else {
        //create new question
        let data = {
          ...formData,
          mapped_investors: selectedInvestors.map(investor => investor.value),
          answer: selectedQuestion.answer,
          appliedFundingDealId: props?.appliedFundingDeal?.copied_from
            ? props?.appliedFundingDeal?.copied_from
            : props?.appliedFundingDealId,
        };
        let encryptedQuery = encryptDataToArray(data);
        let payload = {
          q: JSON.stringify(encryptedQuery),
        };
        status = await addNewQuestion(payload);
      }

      if (status.status === true) {
        setModal(false);
        setSelectedQuestion({});
        setSelectedInvestors([]);
        await fetchData();
        return true;
      }
    } catch (err) {
      let error = "";
      if (err.response?.data?.message?.errors) {
        error = Object.values(err.response?.data?.message?.errors).join(", ");
      } else {
        error = err.response?.data?.message;
      }
      setErrors("Error: " + error);
    }
  };

  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const toggle = () => {
    let sts = true;
    if (modal === true) {
      sts = false;
      setisEdit(false);
    }
    setModal(sts);

    // if (!isEdit) {
    //   setSelectedInvestors([]);
    // }
  };

  let defaultValues = {};

  if (isEdit) {
    defaultValues = {
      question: !isEmpty(selectedQuestion.question)
        ? selectedQuestion.question
        : "",
      admin_action: !isEmpty(selectedQuestion.admin_action)
        ? selectedQuestion.admin_action
        : "Pending",
      answer: !isEmpty(selectedQuestion.answer) ? selectedQuestion.answer : "",
      reject_reason: !isEmpty(selectedQuestion.reject_reason)
        ? selectedQuestion.reject_reason
        : "",
      publish: !isEmpty(selectedQuestion.publish)
        ? selectedQuestion.publish
        : "All",
    };
  }

  //questions embbed for another modules
  const curSelected = () => {
    let questionArr = [];
    selectedQuestions.forEach((ele, i) => {
      questionArr.push(ele._id);
    });
    return questionArr;
  };

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, question) => <>{question._id}</>,
    },
    {
      text: "Question",
      dataField: "question",
      sort: true,
      filter: textFilter(),
    },
    {
      text: "Answered",
      dataField: "answered_by_admin",
      sort: true,
      formatter: (cellContent, question) => {
        return (
          <>{question.admin_action === "Answered" ? "Admin" : "Founder"}</>
        );
      },
    },
    {
      text: "Private",
      dataField: "is_private",
      sort: true,
    },

    {
      dataField: "admin_action",
      text: "Status",
      formatter: (cellContent, question) => <>{question.admin_action}</>,
    },

    {
      text: "Created",
      dataField: "createdAt",
      sort: true,
      formatter: (cellContent, questions) => (
        <>{moment(new Date(questions.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, question) => (
        <div className="d-flex gap-3">
          {getUserRolles?.q_and_a_edit == true && (
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => handleEditQuestion(question)}
              ></i>
            </Link>
          )}
          {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteQuestion(question)}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: questions.length, // replace later with size(questions),
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: curSelected(),
    onSelectAll: (isSelect, rows, e) => {
      let finalArry;
      if (isSelect) {
        finalArry = [...new Set([...selectedQuestions, ...rows])];
      } else {
        finalArry = selectedQuestions;
        rows.forEach((e, i) => {
          selectedQuestions.forEach((ele, ind) => {
            if (e._id === ele._id) {
              finalArry.splice(ind, 1);
            }
          });
        });
      }

      setSelectedQuestions(finalArry);
    },

    onSelect: (row, isSelect) => {
      let finalArry;

      if (isSelect) {
        finalArry = [...new Set([...selectedQuestions, ...[row]])];
      } else {
        finalArry = selectedQuestions;
        finalArry.forEach((e, i) => {
          if (e._id === row._id) {
            finalArry.splice(i, 1);
          }
        });
      }

      setSelectedQuestions(finalArry);
    },
  };

  const fundingRound =
    props?.appliedFundingDeal?.funding_information?.funding_round || "";

  return (
    <>
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={columns}
                    data={questions}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={questions}
                        search={{
                          searchFormatted: false,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            {
                              <Row className="mb-2">
                                <Col sm="8">
                                  {/* <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative"></div>
                                  </div> */}
                                  {copiedFrom != "" ? (
                                    <>
                                      This is a copied deal, if you want to see
                                      or add Q&As please{" "}
                                      <a
                                        href={`/applied-funding-details/${copiedFrom}`}
                                      >
                                        {" "}
                                        click here
                                      </a>{" "}
                                      to go to parent deal
                                    </>
                                  ) : null}
                                </Col>

                                <Col sm="4">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={e => toggle()}
                                      disabled={copiedFrom == "" ? false : true}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Add New Question-Answer
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            }
                            <Row>
                              <Col xl="12">
                                {!isEmpty(errors) ? (
                                  <p className="text-danger">
                                    Some error occured!
                                  </p>
                                ) : null}
                              </Col>
                            </Row>
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              selectRow={selectRow}
                              data={questions}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />

                            <Modal
                              size="lg"
                              isOpen={modal}
                              toggle={e => toggle()}
                            >
                              <ModalHeader toggle={e => toggle()} tag="h4">
                                {(!!isEdit ? "Edit Question" : "Add Question") +
                                  (fundingRound ? ` For ${fundingRound}` : "")}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={handleValidQuestionSubmit}
                                  model={defaultValues}
                                >
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="question"
                                          label="Question"
                                          type="text"
                                          errorMessage="Invalid Question"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={""}
                                        />
                                      </div>

                                      <div className="mb-3">
                                        <label>Admin Status</label>
                                        <AvRadioGroup
                                          name="admin_action"
                                          required
                                          inline
                                          onChange={e =>
                                            setisEditAdminAction(e.target.value)
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          defaultValue={
                                            selectedQuestion.admin_action ||
                                            "Answered"
                                          }
                                        >
                                          <AvRadio
                                            label="Pending"
                                            value="Pending"
                                          />
                                          <AvRadio
                                            label="Approved"
                                            value="Approved"
                                          />
                                          <AvRadio
                                            label="Answered"
                                            value="Answered"
                                          />
                                          <AvRadio
                                            label="Rejected"
                                            value="Rejected"
                                          />
                                        </AvRadioGroup>
                                      </div>
                                      {isEditAdminAction === "Rejected" && (
                                        <div className="mb-3">
                                          <AvField
                                            name="reject_reason"
                                            label={`Reject Reason`}
                                            type="textarea"
                                            placeholder=""
                                            errorMessage="Invalid Reason"
                                            value={""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </div>
                                      )}
                                      {isEditAdminAction === "Answered" && (
                                        <div
                                          className="mb-3"
                                          style={{ height: "200px" }}
                                        >
                                          <label>Answer</label>
                                          <ReactQuill
                                            id="description"
                                            style={{ height: "128px" }}
                                            placeholder=""
                                            value={
                                              selectedQuestion.answer || ""
                                            }
                                            onChange={answer => {
                                              setSelectedQuestion({
                                                ...selectedQuestion,
                                                answer,
                                              });
                                            }}
                                            modules={
                                              eventDescriptionEditorModules
                                            }
                                            formats={
                                              eventDescriptionEditorFormats
                                            }
                                          />
                                          {/* <AvField
                                            name="answer"
                                            label={`Answer`}
                                            type="textarea"
                                            placeholder=""
                                            errorMessage="Invalid Answer"
                                            value={""}
                                            validate={{
                                              required: { value: false },
                                            }}
                                          /> */}
                                        </div>
                                      )}

                                      <div className="mb-3">
                                        <label>Select Investors</label>
                                        <Select
                                          isMulti
                                          isClearable
                                          value={selectedInvestors}
                                          options={allInvestors}
                                          onChange={investors =>
                                            setSelectedInvestors(
                                              investors || []
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="mb-3">
                                        <label>Publish</label>
                                        <AvRadioGroup
                                          name="publish"
                                          required
                                          inline
                                          defaultValue="All"
                                        >
                                          <AvRadio label="All" value="All" />
                                          <AvRadio
                                            label="Investor"
                                            value="Investor"
                                          />
                                        </AvRadioGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      {!isEmpty(errors) ? (
                                        <p className="text-danger">{errors}</p>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-question"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>

                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </>
  );
}

QuestionAnswers.propTypes = {
  selectedUsers: PropTypes.array,
  selectedQuestions: PropTypes.array,
  selectedQuestion: PropTypes.object,
  usedForEvent: PropTypes.any,
  userRoleType: PropTypes.string,
  onSelectGroup: PropTypes.func,
  appliedFundingDealId: PropTypes.string,
  appliedFundingDeal: PropTypes.object,
};

export default QuestionAnswers;
