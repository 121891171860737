import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import {
  createSetting,
  deleteSetting,
  getAllAccessSettings,
  getOneAccessSetting,
  updateSetting,
} from "store/actions";
import { MetaTags } from "react-meta-tags";
import { encryptDataToArray } from "helpers/EncryptDecrypt";
import Dropdown from "react-multilevel-dropdown";
import { CSVLink } from "react-csv";
import axios from "axios";

const FrontendSettings = ({
  getAllAccessSettingsFunction,
  getOneAccessSettingFunction,
  createSettingFunction,
  updateSettingFunction,
  deleteSettingFunction,
  allAccessSettings,
  accessSetting,
  successMessage,
  errorMessage,
  ...props
}) => {
  const [addSettingModal, setAddSettingModal] = useState(false);
  const [addSettingName, setAddSettingName] = useState(null);
  const [addSettingError, setAddSettingError] = useState(null);
  const [roleUsers, setRoleUsers] = useState([]);
  const is_super_admin = JSON.parse(localStorage.getItem("authUser"))?.userInfo
    ?.is_super_admin;

  const toggleAddSettingModal = () => {
    setAddSettingModal(!addSettingModal);
    setAddSettingName(null);
    // setAddSettingError(null);
  };

  const handleAddSettingFunction = async () => {
    try {
      if (!addSettingName) {
        setAddSettingError("Setting name is required");

        setTimeout(() => {
          setAddSettingError(null);
        }, 2000);

        return;
      }

      let reqBody = {
        setting_name: addSettingName,
      };

      let encryptedQuery = encryptDataToArray(reqBody)
      let reqData = {
        q: JSON.stringify(encryptedQuery)
      }

      await createSettingFunction(reqData);
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while creating access setting";
      setAddSettingError(message);

      setTimeout(() => {
        setAddSettingError(null);
      }, 2000);

      return;
    }
  };

  useEffect(() => {
    if (!!successMessage) {
      if (!!addSettingModal) {
        toggleAddSettingModal();
      }

      return;
    }

    if (!!errorMessage) {
      setAddSettingError(errorMessage);

      setTimeout(() => {
        setAddSettingError(null);
      }, 2000);

      return;
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (!addSettingModal) {
      getAllAccessSettingsFunction();
    }
  }, [addSettingModal]);

  useEffect(() => {
    if (accessSetting?._id) {
      fetchRoleUsers(accessSetting?._id)
    }
  }, [accessSetting])

  const fetchRoleUsers = async (roleid) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/admin/frontend-access/getroleusers/${roleid}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };

    axios(config)
      .then(response => {
        setRoleUsers(response?.data?.roleUsers || [])
      })
      .catch(error => {
        setRoleUsers([])
      });
  }

  useEffect(() => {
    if (!!allAccessSettings?.length) {
      getOneAccessSettingFunction({
        settingId: allAccessSettings?.[0]?._id,
      });
    }
  }, [allAccessSettings]);

  const superAdminSelected = !!accessSetting?.is_super_admin;

  const handleChangeSetting = e => {
    let name = e.target.name;
    let checked = !!e.target?.checked;

    let reqBody = {
      settingId: accessSetting?._id,
      setting_name: accessSetting?.setting_name,
      [name]: checked,
    };

    updateSettingFunction(reqBody);
    toast.success("Updated Successfully");
  };

  const handleDeleteSetting = e => {
    Swal.fire({
      title: `Are you sure you want to delete setting - ${accessSetting?.setting_name} ?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        let settingId = accessSetting?._id;

        deleteSettingFunction({ settingId });
      }
    });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Frontend Settings | Invstt - Admin</title>
      </MetaTags>

      <ToastContainer />

      <Row className="text-end">
        <Col md={12}>
          <Button
            color="dark"
            outline
            onClick={() => {
              toggleAddSettingModal();
            }}
            disabled={addSettingModal}
            className="me-2"
          >
            <i className="mdi mdi-plus-circle-outline me-1" />
            Add New Setting
          </Button>

          <Button
            color="danger"
            outline
            onClick={() => {
              handleDeleteSetting();
            }}
            disabled={superAdminSelected}
          >
            <i className="mdi mdi-delete me-1" />
            Delete Selected Setting
          </Button>
        </Col>

        {addSettingModal ? (
          <>
            <Col lg={3} md={4} sm={6} xs={12} className="ms-auto mt-2 text-end">
              <div className="text-start">
                <Label>Enter Setting Name:</Label>
              </div>
              <Input
                type="text"
                value={addSettingName}
                onChange={e => {
                  setAddSettingName(e.target.value);
                }}
                placeholder="Setting Name"
              />

              {!!addSettingError && (
                <>
                  <div className="mt-1 text-start">
                    <Label className="text-danger">{addSettingError}</Label>
                  </div>
                </>
              )}

              <div className="mt-2 d-flex gap-2 align-items-center">
                <Button
                  color="success"
                  onClick={() => {
                    handleAddSettingFunction();
                  }}
                >
                  Save
                </Button>

                <Button
                  color="danger"
                  onClick={() => {
                    toggleAddSettingModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>

      <Row className="mt-4">
        <Col>
          {allAccessSettings?.map((setting, index) => (
            <>
              <Button
                color="primary"
                outline
                onClick={e => {
                  getOneAccessSettingFunction({
                    settingId: setting?._id,
                  });
                }}
                className="m-1 p-2"
                active={accessSetting?._id === setting?._id}
              >
                {setting?.setting_name}
              </Button>
            </>
          ))}
        </Col>
      </Row>

      {/* <Row className="mt-3 px-5 ">
        <Col md={12} className="d-flex justify-content-end">
          {roleUsers?.length > 0 && is_super_admin && (
            <div className="mx-4 d-flex flex-row gap-3"
              style={{
                width: '35%',
              }}>
              <select
                className="form-control"
              >
                {roleUsers?.map((el) => (
                  <option value={`${el?.userName}`}>{el?.userName || ""} ({el?.email})</option>
                ))}
              </select>
              <CSVLink
                data={roleUsers}
                headers={[
                  {
                    key: "userName",
                    label: "User Name",
                  },
                  {
                    key: "email",
                    label: "Email",
                  },
                  {
                    key: "roleName",
                    label: "Role Name",
                  },
                ]}
                filename={`Frontend Access List.csv`}
              >
                <Button color="info" outline>
                  Export&nbsp;CSV
                </Button>
              </CSVLink>
            </div>
          )}</Col>
      </Row> */}

      {/* <Row className="mt-3 px-5">
        <Col md={12}>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Timeline <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="timeline.add_edit_delete_post"
                    label="Posts : Add / Delete"
                    checked={
                      superAdminSelected ||
                      accessSetting?.timeline?.add_edit_delete_post
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            User Network <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="user_network.send_request"
                    label="Connection Request : Send"
                    checked={
                      superAdminSelected || accessSetting?.user_network?.send_request
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="user_network.accept_reject_connection_request"
                    label="Connection Request : Accept / Reject"
                    checked={
                      superAdminSelected ||
                      accessSetting?.user_network?.accept_reject_connection_request
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="user_network.send_message"
                    label="Send Message"
                    checked={
                      superAdminSelected || accessSetting?.user_network?.send_message
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Founder Startup Details <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="startup_details.add_edit"
                    label="Details : Add / Edit"
                    checked={
                      superAdminSelected || accessSetting?.startup_details?.add_edit
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Investment Deals <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="investment_deals.add_edit_intent"
                    label="Intent : Add / Edit"
                    checked={
                      superAdminSelected ||
                      accessSetting?.investment_deals?.add_edit_intent
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="investment_deals.terms_accept_supersede"
                    label="Consent : Terms Accept Supersede"
                    checked={
                      superAdminSelected ||
                      accessSetting?.investment_deals?.terms_accept_supersede
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Startup Details <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="startup_page.founder_qa"
                    label="Founder QA : Add / Edit / Answer"
                    checked={
                      superAdminSelected || accessSetting?.startup_page?.founder_qa
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="startup_page.investor_qa"
                    label="Investor QA : Add / Edit"
                    checked={
                      superAdminSelected || accessSetting?.startup_page?.investor_qa
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Startup Investments <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item_long" position="right">
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="investment.add_edit_delete"
                    label="Investor Investments : Add / Edit / Delete"
                    checked={
                      superAdminSelected || accessSetting?.investment?.add_edit_delete
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="investment.approve_unapprove"
                    label="Startup Round Investments : Approve / Unapprove"
                    checked={
                      superAdminSelected ||
                      accessSetting?.investment?.approve_unapprove
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            User Profile <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="user_profile.edit"
                    label="My Profile : Edit"
                    checked={superAdminSelected || accessSetting?.user_profile?.edit}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            User KYC <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item_long" position="right">
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="kyc.add_edit"
                    label="KYC : Add / Edit"
                    checked={superAdminSelected || accessSetting?.kyc?.add_edit}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="kyc.change_status"
                    label={
                      <>
                        KYC Status : Approve / Reject&emsp;|&emsp;CA All Status :
                        Change
                      </>
                    }
                    checked={superAdminSelected || accessSetting?.kyc?.change_status}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Demat <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="demat.add_edit"
                    label="Demat : Add / Edit"
                    checked={superAdminSelected || accessSetting?.demat?.add_edit}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="demat.change_status"
                    label={
                      <>
                        Demat Status : Approve / Disapprove
                      </>
                    }
                    checked={superAdminSelected || accessSetting?.demat?.change_status}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Talent <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="talent_module.add_edit_jobs"
                    label="Users : Add / Edit / Delete"
                    checked={
                      superAdminSelected ||
                      accessSetting?.talent_module?.add_edit_jobs
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="talent_module.apply_for_jobs"
                    label="Professionals : Apply"
                    checked={
                      superAdminSelected ||
                      accessSetting?.talent_module?.apply_for_jobs
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Events <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item_long" position="right">
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="event_module.edit"
                    label="Event Manage: (Primary Syndicate)"
                    checked={superAdminSelected || accessSetting?.event_module?.edit}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="event_module.edit_saas"
                    label="Event Manage: (Primary Syndicate + Secondary Syndicate)"
                    checked={
                      superAdminSelected || accessSetting?.event_module?.edit_saas
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item_long">
                <div className="d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="event_module.edit_notification_setting"
                    label="Edit Notification Settings"
                    checked={
                      superAdminSelected || accessSetting?.event_module?.edit_notification_setting
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Discover Syndicate <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center" >
                  <Form.Check
                    type="checkbox"
                    name="discover_syndicate.join_syndicate"
                    label="Syndicate : Join"
                    checked={
                      superAdminSelected ||
                      accessSetting?.discover_syndicate?.join_syndicate
                    }
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
                <div>&gt;</div>
                {(superAdminSelected || accessSetting?.discover_syndicate?.join_syndicate) && (
                  <Dropdown.Submenu className="dropdown_item_long" position="right">
                    <Dropdown.Item className="dropdown_item_long">
                      <div className="d-flex flex-row align-items-center">
                        <Form.Check
                          type="checkbox"
                          name="discover_syndicate.communication_access"
                          label="Discover Syndicate Enable/Disable Communication"
                          checked={
                            superAdminSelected ||
                            accessSetting?.discover_syndicate?.communication_access
                          }
                          onChange={e => {
                            handleChangeSetting(e);
                          }}
                          disabled={superAdminSelected}
                        />
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Submenu>
                )}
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            Contact Us <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center" >
                  <Form.Check
                    type="checkbox"
                    name="contact_us"
                    label="Contact Us Form"
                    checked={superAdminSelected || accessSetting?.contact_us}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
            User Settings <div>&gt;</div>
            <Dropdown.Submenu className="dropdown_item" position="right">
              <Dropdown.Item className="dropdown_item d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center" >
                  <Form.Check
                    type="checkbox"
                    name="user_settings"
                    label="User Settings Page"
                    checked={superAdminSelected || accessSetting?.user_settings}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="dropdown_item d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center" >
                  <Form.Check
                    type="checkbox"
                    name="switch_profile"
                    label="Switch Profile"
                    checked={superAdminSelected || accessSetting?.switch_profile}
                    onChange={e => {
                      handleChangeSetting(e);
                    }}
                    disabled={superAdminSelected}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
        </Col>
      </Row> */}

      <Row className="mt-3 px-5">
        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Timeline
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="timeline.add_edit_delete_post"
              label="Posts : Add / Delete"
              checked={
                superAdminSelected ||
                accessSetting?.timeline?.add_edit_delete_post
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            User Network
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="user_network.send_request"
              label="Connection Request : Send"
              checked={
                superAdminSelected || accessSetting?.user_network?.send_request
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />

            <Form.Check
              type="checkbox"
              name="user_network.accept_reject_connection_request"
              label="Connection Request : Accept / Reject"
              checked={
                superAdminSelected ||
                accessSetting?.user_network?.accept_reject_connection_request
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
            <Form.Check
              type="checkbox"
              name="user_network.send_message"
              label="Send Message"
              checked={
                superAdminSelected || accessSetting?.user_network?.send_message
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Founder Startup Details
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="startup_details.add_edit"
              label="Details : Add / Edit"
              checked={
                superAdminSelected || accessSetting?.startup_details?.add_edit
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Investment Deals
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="investment_deals.add_edit_intent"
              label="Intent : Add / Edit"
              checked={
                superAdminSelected ||
                accessSetting?.investment_deals?.add_edit_intent
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
            {/* <Form.Check
              type="checkbox"
              name="investment_deals.add_edit_consent"
              label="Consent : Add / Edit"
              checked={
                superAdminSelected ||
                accessSetting?.investment_deals?.add_edit_consent
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              // disabled={superAdminSelected}
              disabled={true}
            /> */}
            <Form.Check
              type="checkbox"
              name="investment_deals.terms_accept_supersede"
              label="Consent : Terms Accept Supersede"
              checked={
                superAdminSelected ||
                accessSetting?.investment_deals?.terms_accept_supersede
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Startup Details
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="startup_page.founder_qa"
              label="Founder QA : Add / Edit / Answer"
              checked={
                superAdminSelected || accessSetting?.startup_page?.founder_qa
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />

            <Form.Check
              type="checkbox"
              name="startup_page.investor_qa"
              label="Investor QA : Add / Edit"
              checked={
                superAdminSelected || accessSetting?.startup_page?.investor_qa
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Startup Investments
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="investment.add_edit_delete"
              label="Investor Investments : Add / Edit / Delete"
              checked={
                superAdminSelected || accessSetting?.investment?.add_edit_delete
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />

            <Form.Check
              type="checkbox"
              name="investment.approve_unapprove"
              label="Startup Round Investments : Approve / Unapprove"
              checked={
                superAdminSelected ||
                accessSetting?.investment?.approve_unapprove
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            User Profile
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="user_profile.edit"
              label="My Profile : Edit"
              checked={superAdminSelected || accessSetting?.user_profile?.edit}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            User KYC
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="kyc.add_edit"
              label="KYC : Add / Edit"
              checked={superAdminSelected || accessSetting?.kyc?.add_edit}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />

            <Form.Check
              type="checkbox"
              name="kyc.change_status"
              label={
                <>
                  KYC Status : Approve / Reject&emsp;|&emsp;CA All Status :
                  Change
                </>
              }
              checked={superAdminSelected || accessSetting?.kyc?.change_status}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Demat
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="demat.add_edit"
              label="Demat : Add / Edit"
              checked={superAdminSelected || accessSetting?.demat?.add_edit}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />

            <Form.Check
              type="checkbox"
              name="demat.change_status"
              label={
                <>
                  Demat Status : Approve / Disapprove
                </>
              }
              checked={superAdminSelected || accessSetting?.demat?.change_status}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Talent
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="talent_module.add_edit_jobs"
              label="Users : Add / Edit / Delete"
              checked={
                superAdminSelected ||
                accessSetting?.talent_module?.add_edit_jobs
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />

            <Form.Check
              type="checkbox"
              name="talent_module.apply_for_jobs"
              label="Professionals : Apply"
              checked={
                superAdminSelected ||
                accessSetting?.talent_module?.apply_for_jobs
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Events
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="event_module.edit"
              label="Event Manage: (Primary Syndicate)"
              checked={superAdminSelected || accessSetting?.event_module?.edit}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
            <Form.Check
              type="checkbox"
              name="event_module.edit_saas"
              label="Event Manage: (Primary Syndicate + Secondary Syndicate)"
              checked={
                superAdminSelected || accessSetting?.event_module?.edit_saas
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
            <Form.Check
              type="checkbox"
              name="event_module.edit_notification_setting"
              label="Edit Notification Settings"
              checked={
                superAdminSelected || accessSetting?.event_module?.edit_notification_setting
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Discover Syndicate
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="discover_syndicate.join_syndicate"
              label="Syndicate : Join"
              checked={
                superAdminSelected ||
                accessSetting?.discover_syndicate?.join_syndicate
              }
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
            {(superAdminSelected ||
              accessSetting?.discover_syndicate?.join_syndicate) && (
              <div style={{ fontSize: "medium", paddingLeft: "20px" }}>
                <Form.Check
                  type="checkbox"
                  name="discover_syndicate.communication_access"
                  label="Discover Syndicate Enable/Disable Communication"
                  checked={
                    superAdminSelected ||
                    accessSetting?.discover_syndicate?.communication_access
                  }
                  onChange={e => {
                    handleChangeSetting(e);
                  }}
                  disabled={superAdminSelected}
                />
              </div>
            )}
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            Contact Us
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="contact_us"
              label="Contact Us Form"
              checked={superAdminSelected || accessSetting?.contact_us}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="text-bg-secondary rounded p-2 font-size-16">
            User Settings
          </div>
          <div className="p-4 font-size-16">
            <Form.Check
              type="checkbox"
              name="user_settings"
              label="User Settings Page"
              checked={superAdminSelected || accessSetting?.user_settings}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
            <Form.Check
              type="checkbox"
              name="switch_profile"
              label="Switch Profile"
              checked={superAdminSelected || accessSetting?.switch_profile}
              onChange={e => {
                handleChangeSetting(e);
              }}
              disabled={superAdminSelected}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

FrontendSettings.propTypes = {
  allAccessSettings: PropTypes.array,
  accessSetting: PropTypes.object,
  successMessage: PropTypes.any,
  errorMessage: PropTypes.any,
};

const mapStateToProps = state => ({
  ...(state?.frontendAccessSetting || {}),
});

const mapDispatchToProps = dispatch => ({
  getAllAccessSettingsFunction: payload =>
    dispatch(getAllAccessSettings(payload)),
  getOneAccessSettingFunction: payload =>
    dispatch(getOneAccessSetting(payload)),
  createSettingFunction: payload => dispatch(createSetting(payload)),
  updateSettingFunction: payload => dispatch(updateSetting(payload)),
  deleteSettingFunction: payload => dispatch(deleteSetting(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FrontendSettings));
