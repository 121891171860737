import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb";
import EvaluationReport from "./EvaluationReport";
import {
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

function FounderDetails({ match }) {
  const [startup, setStartup] = useState({});
  const [moreInfo, setMoreInfo] = useState({});
  const [startupInfo, setStartupInfo] = useState({});
  const [report, setReport] = useState([]);
  const [information, setInformation] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [kpi, setKPI] = useState({});

  const handleRemoveKPI = index => {
    consle.log(index);
  };
  const handleAddKPI = e => {
    e.preventDefault();
    // setStartup({ ...startup, additional_kpis: [...startup.additional_kpis, ...kpi] });
    setShow(false);
  };

  useEffect(async () => {
    let id = match.params.id;
    await Tab1(id);
    await Tab2(id);
    await Tab3(id);
    await Tab4(id);
    // await Tab4(id);
    await setLoading(false);
    return () => {};
  }, []);

  async function Tab1(id) {
    const token = authHeader();
    await axios
      .get(process.env.REACT_APP_API_URL + `/api/user/getdetail/${id}`, {
        headers: { Authorization: `${token}` },
      })
      .then(async response => {
        await setStartup(response.data.user);
        await console.log(startup, "startup info");
      })
      .catch(error => {
        // console.log(error);
      });
    await axios
      .get(process.env.REACT_APP_API_URL + `/api/user/userProfile/${id}`, {
        headers: { Authorization: `${token}` },
      })
      .then(async response => {
        await setMoreInfo(response.data);
      })
      .catch(error => {
        //console.log(error);
      });
  }

  async function Tab2(id) {
    const token = authHeader();
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/user/evaluationreports/user/${id}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(async response => {
        if (response.data.report.length > 0) {
          await setReport(response.data.report);
        }
      })
      .catch(async error => {
        //console.log(error);
      });
  }

  async function Tab3(id) {
    const token = authHeader();
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/applyforfunding/getallbyuser/${id}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(async response => {
        await setInformation(response.data.fundingData[0]);
      })
      .catch(error => {
        // console.log(error);
      });
  }

  async function Tab4(id) {
    const token = authHeader();
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/user/founder/getdetailbyuserid/${id}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(async response => {
        setStartupInfo(response.data);
        console.log(startupInfo, "startup infooo");
      })
      .catch(error => {
        //console.log(error);
      });
  }

  const toggle = tab => {
    setActiveTab(tab);
  };

  const startupTeamMembers = () => {
    let members = startupInfo?.startup?.team_information?.cofounder || [""];
    return members;
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    // let founders = [];
    // if (report && report.primary_information) founders = report.primary_information.founder || [];

    // let team_members = [];
    // if (report && report.primary_information?.current_status && report.primary_information?.current_status.length > 0) team_members = report.primary_information?.current_status[0]?.key_team_members || [];

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Founder Detail | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Founders" breadcrumbItem="Founder Detail" />
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" && "active"}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Basic Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" && "active"}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Evaluation Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" && "active"}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Education Details
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row className="mt-5">
                    <Col sm="6">
                      <h4>Founder Information</h4>
                      <table className="table table-hover table-responsive">
                        <tbody>
                          <tr>
                            <th>Founder name:</th>
                            <td>
                              {startup?.firstname} {startup?.lastname}
                            </td>
                          </tr>
                          <tr>
                            <th>Profile Image:</th>
                            <td>
                              {startup?.profile_pic && (
                                <img
                                  src={startup?.profile_pic}
                                  alt="profile"
                                  width="100"
                                  height="100"
                                />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Email:</th>
                            <td>{startup?.email}</td>
                          </tr>
                          <tr>
                            <th>Linkedin Url:</th>
                            <td>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={startup.linkedin_profile_link || ""}
                              >
                                LinkedIn Link
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>Mobile Number:</th>
                            <td>
                              {startup.mobile_country_code || ""}{" "}
                              {startup.mobile_number || ""}
                            </td>
                          </tr>
                          <tr>
                            <th>Source :</th>
                            <td>{moreInfo?.founderInfo?.source || ""} </td>
                          </tr>
                          <tr>
                            <th>Gender :</th>
                            <td>{moreInfo?.founderInfo?.gender || ""}</td>
                          </tr>
                          <tr>
                            <th>Country , State , City :</th>
                            <td>
                              {moreInfo?.founderInfo?.country || ""}{" "}
                              {moreInfo?.founderInfo?.state && " , "}{" "}
                              {moreInfo?.founderInfo?.state || ""}{" "}
                              {moreInfo?.founderInfo?.city && " , "}{" "}
                              {moreInfo?.founderInfo?.city || ""}
                            </td>
                          </tr>
                          <tr>
                            <th>Headline :</th>
                            <td>{moreInfo?.founderInfo?.headline || ""}</td>
                          </tr>
                          <tr>
                            <th>Bio :</th>
                            <td>{moreInfo?.founderInfo?.bio || ""}</td>
                          </tr>
                          <tr>
                            <th>Here For :</th>
                            <td>
                              {(moreInfo.here_for.length &&
                                moreInfo.here_for.map(item => (
                                  <span key={item}>{item + " "}, </span>
                                ))) ||
                                ""}
                            </td>
                          </tr>
                          <tr>
                            <th>Current Desgination:</th>
                            <td>{moreInfo.founderInfo?.designation || ""}</td>
                          </tr>
                          <tr>
                            <th>Past Invesments :</th>
                            <td>
                              {moreInfo.founderInfo?.past_investment || ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="6">
                      <h4>Company/Startup Information</h4>
                      <table className="table table-hover table-responsive">
                        <tbody>
                          <tr>
                            <th>Is Approved?:</th>
                            <td>
                              {startupInfo &&
                              startupInfo?.startup &&
                              startupInfo?.startup?.status === "approve"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <th>Brand Name:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.startup_name}
                            </td>
                          </tr>
                          <tr>
                            <th>Registered Company Name:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.registered_company_name}
                            </td>
                          </tr>
                          <tr>
                            <th>Logo:</th>
                            <td>
                              {startupInfo?.startup?.basic_information
                                ?.startup_logo && (
                                <img
                                  src={
                                    startupInfo?.startup?.basic_information
                                      ?.startup_logo
                                  }
                                  alt="profile"
                                  width="100"
                                  height="100"
                                />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Website:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.website}
                            </td>
                          </tr>
                          <tr>
                            <th>Startup Type:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.startup_type}
                            </td>
                          </tr>
                          <tr>
                            <th>Startup Description:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.startup_description}
                            </td>
                          </tr>
                          <tr>
                            <th>Country:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.country_name}
                            </td>
                          </tr>
                          <tr>
                            <th>State:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.state_name}
                            </td>
                          </tr>
                          <tr>
                            <th>City:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.city_name}
                            </td>
                          </tr>
                          <tr>
                            <th>Product Description:</th>
                            <td>
                              {startupInfo &&
                                startupInfo?.startup &&
                                startupInfo?.startup?.basic_information
                                  ?.product_description}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="6">
                      <h4>Previous Startups</h4>
                      {moreInfo.founderInfo?.previous_startups?.length > 0 &&
                        moreInfo.founderInfo?.previous_startups.map(
                          (item, index) => (
                            <div key={index}>
                              <table className="table table-hover table-responsive">
                                <tbody>
                                  <tr>
                                    <th>Startup name:</th>
                                    <td>{item.startup || ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Status:</th>
                                    <td>{item.startup_status || ""}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )
                        )}
                    </Col>
                    <Col sm="6">
                      <h4>Team Information</h4>
                      <table className="table table-hover table-responsive">
                        <tbody>
                          <tr>
                            <th>Member Name</th>
                            <th>Linkedin Url</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Country</th>
                          </tr>
                          {startupTeamMembers().map((m, i) => (
                            <tr key={i}>
                              <th>{m.cofounder_name || "-"}</th>
                              <th>
                                {m.cofounder_linkedin_url && (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={startup.linkedin_profile_link || ""}
                                  >
                                    {m.cofounder_linkedin_url}
                                  </a>
                                )}
                                {!m.cofounder_linkedin_url && "-"}
                              </th>
                              <th>{m.cofounder_city || "-"}</th>
                              <th>{m.cofounder_state || "-"}</th>
                              <th>{m.cofounder_country || "-"}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <EvaluationReport evaluationReport={report} />
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    {moreInfo.founderInfo?.education?.map((edu, index) => (
                      <Col sm="12" key={index}>
                        <div className="information-wrap">
                          <span className="d-flex justify-content-between">
                            <h6>Degree</h6>
                            {`${edu.degree}` || ""}
                          </span>
                          <span className="d-flex justify-content-between">
                            <h6>Institute</h6>
                            {edu.institute || ""}
                          </span>
                          <span className="d-flex justify-content-between">
                            <h6>Year From</h6>
                            {`${edu.yearfrom}` || ""}
                          </span>
                          <span className="d-flex justify-content-between">
                            <h6>Year To</h6>
                            {`${edu.yearto}` || ""}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

FounderDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default FounderDetails;
