import Swal from "sweetalert2";
import * as moment from "moment";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import GroupsList from "../Users/UserList/users-groups";
import { Form } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";

import Breadcrumbs from "../../components/Common/Breadcrumb";
// Quill Editor for Event Description
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import _ from "lodash";
import Select from "react-select";
import axios from "axios";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

class CreateWeeklyBrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      errors: {},
      postsData: [],
      selectedPostsData: [],
      description: "",
      dynamicContentImage: { fileName: null, file: null },
      marketing_title: "",
      marketing_content: "",
      marketingImg: { fileName: null, file: null },
      successMessage: "",
      datetime: "",
    };
    const newWeeklyBrew = {
      title: this.state.title.trim(),
      description: this.state.description.trim(),
      marketing_content: this.state.marketing_content.trim(),
    };
  }

  componentDidMount() {
    const token = authHeader();

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/weeklyBrew/get_timeline_posts`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    axios(config)
      .then(res => {
        let postsData = [];
        if (res.data) {
          let posts = res?.data?.posts;
          if (posts.length > 0) {
            for (let post of posts) {
              let dropdownlebel =
                post?.description
                  ?.replace(/<\/?[^>]+(>|$)/g, " ")
                  .substring(0, 100) +
                " - " +
                post?.creadted_by_user_name;

              postsData.push({
                label: dropdownlebel,
                value: post._id,
              });
            }
          }

          this.setState({ postsData: postsData });
        }
      })
      .catch(error => {
        //  console.log(error);
      });
  }

  handleTitleChange = e => {
    this.setState({
      title: e.currentTarget.value,
    });
  };
  handleMarketingTitleChange = e => {
    this.setState({
      marketing_title: e.currentTarget.value,
    });
  };

  handleDescriptionChange = e => {
    this.setState({
      description: e.currentTarget.value,
    });
  };
  handleMarketingContentChange = e => {
    this.setState({
      marketing_content: e.currentTarget.value,
    });
  };

  handlePostChange = e => {
    this.setState({
      selectedPostsData: e,
    });
  };

  fetchValues = posts => {
    let allPostID = [];
    for (let post of posts) {
      allPostID.push(post.value);
    }
    return allPostID;
  };

  eventDateTimeChange = e => {
    this.setState({
      datetime: e.currentTarget.value,
    });
  };

  eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];
  render() {
    const {
      title,
      description,
      marketing_content,
      marketingImg,
      dynamicContentImage,
      datetime,
      errors,
      selectedPostsData,
    } = this.state;
    const findFormErrors = () => {
      const newErrors = {};
      if (!description || description === "")
        newErrors.description = "The description is required.";
      if (!marketing_content || marketing_content === "")
        newErrors.marketing_content = "The Marketing Content is required.";
      if (!datetime || datetime === "")
        newErrors.datetime = "The Scheduled Date is required.";
      if (selectedPostsData?.length===0)
        newErrors.selectedPostsData = "The Timeline Posts is required.";
      return newErrors;
    };

    const updateKPI = async e => {
      const newErrors = findFormErrors();
      if (Object.keys(newErrors).length > 0) {
        this.setState({ errors: newErrors });
      } else {
        const token = authHeader();

        const formData = new FormData();
        let data = {
          title: this.state.title,
          content: this.state.description,
          marketing_title: this.state.marketing_title,
          marketing_content: this.state.marketing_content,
          timeline_posts: this.fetchValues(this.state.selectedPostsData),
          datetime: this.state.datetime,
        };

        if (this.state.marketingImg?.file) {
          formData.append("marketingImgFile", this.state.marketingImg?.file);
        }
        if (this.state.dynamicContentImage?.file) {
          formData.append(
            "dynamicContentImage",
            this.state.dynamicContentImage?.file
          );
        }

        let encryptedQuery = encryptDataToArray(data);
        formData.append("q", JSON.stringify(encryptedQuery));

        let config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/weeklyBrew/createBrew`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: token,
          },
          data: formData,
        };
        await axios(config)
          .then(res => {
            if (res.data) {
              let messages = this.setState({
                successMessage: "Weekly Brew updated successfully!",
              });
              window.location.href = "/weekly-brew";
            }
          })
          .catch(error => {
            //  console.log(error);
          });
      }
    };

    const { is_saving_weeklyBrew } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Weekly Brew | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="WeeklyBrew"
              breadcrumbItem="Create Weekly Brew"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm className="outer-repeater">
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          {this?.state?.successMessage && (
                            <div class="alert alert-success" role="alert">
                              {this.state.successMessage}
                            </div>
                          )}
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Business Title:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="title"
                                name="title"
                                type="text"
                                className="form-control"
                                // readOnly="true"
                                placeholder="Business Title"
                                errorMessage="The Business title is required."
                                validate={{ required: { value: true } }}
                                value={this.state.title || ""}
                                onChange={this.handleTitleChange}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup
                            className="mb-4"
                            row
                            style={{ height: "300px" }}
                          >
                            <Col lg="3">
                              <Label className="col-form-label">
                                Business Content:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <ReactQuill
                                id="description"
                                style={{ height: "258px" }}
                                placeholder="Business Content"
                                errorMessage="Required Field"
                                validate={{ required: { value: true } }}
                                value={this.state.description || ""}
                                onChange={content => {
                                  this.setState({ description: content });
                                  this.setState({ successMessage: "" });
                                }}
                                modules={this.eventDescriptionEditorModules}
                                formats={this.eventDescriptionEditorFormats}
                                required
                              />
                              <br></br>
                              <br></br>
                              <div className="text-danger">
                                {errors?.description}
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                Business Image:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Input
                                type="file"
                                accept=".jpeg, .png, .jpg, .gif"
                                className="form-control"
                                placeholder="Select Favicon Logo"
                                onChange={event => {
                                  this.setState(prevState => {
                                    let dynamicContentImage = Object.assign(
                                      {},
                                      prevState.dynamicContentImage
                                    ); // creating copy of state variable jasper
                                    dynamicContentImage.file =
                                      event.target.files[0];
                                    dynamicContentImage.fileName =
                                      event.target.value;

                                    // update the name property, assign a new value

                                    return { dynamicContentImage };

                                    // return new object jasper object
                                  });
                                }}
                                multiple={false}
                              />
                              <div className="text-danger">
                                {errors?.dynamicContentImage}
                              </div>
                              <strong>Note:</strong> (Image Size: 200px*200px)
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Marketing Title:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="marketing_title"
                                name="marketing_title"
                                type="text"
                                className="form-control"
                                // readOnly="true"
                                placeholder="Marketing Title"
                                errorMessage="The Marketing title is required."
                                validate={{ required: { value: true } }}
                                value={this.state.marketing_title || ""}
                                onChange={this.handleMarketingTitleChange}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup
                            className="mb-4"
                            row
                            style={{ height: "300px" }}
                          >
                            <Col lg="3">
                              <Label className="col-form-label">
                                Marketing Content:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <ReactQuill
                                id="marketing_content"
                                style={{ height: "258px" }}
                                placeholder="Marketing Content"
                                errorMessage="Required Field"
                                validate={{ required: { value: true } }}
                                value={this.state.marketing_content || ""}
                                onChange={content => {
                                  this.setState({ marketing_content: content });
                                  this.setState({ successMessage: "" });
                                }}
                                modules={this.eventDescriptionEditorModules}
                                formats={this.eventDescriptionEditorFormats}
                                required
                              />
                              <br></br>
                              <br></br>
                              <div className="text-danger">
                                {errors?.marketing_content}
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                Marketing Image:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Input
                                type="file"
                                accept=".jpeg, .png, .jpg, .gif"
                                className="form-control"
                                placeholder="Select Favicon Logo"
                                onChange={event => {
                                  this.setState(prevState => {
                                    let marketingImg = Object.assign(
                                      {},
                                      prevState.marketingImg
                                    ); // creating copy of state variable jasper
                                    marketingImg.file = event.target.files[0];
                                    marketingImg.fileName = event.target.value;

                                    // update the name property, assign a new value

                                    return { marketingImg }; // return new object jasper object
                                  });
                                }}
                                multiple={false}
                              />
                              {/* <div className="text-danger">{errors?.datetime}</div> */}
                              <strong>Note:</strong> (Image Size: 200px*200px)
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                Timeline Posts:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Select
                                id="selectedGroups"
                                placeholder="Timeline Posts"
                                name="timeline_posts"
                                className="mb-3"
                                options={this.state.postsData}
                                // styles={styles}
                                value={this.state.selectedPostsData}
                                isMulti={true}
                                isClearable={true}
                                onChange={posts =>
                                  this.handlePostChange(posts || [])
                                }
                                validate={{ required: { value: true } }}
                              />
                              <div className="text-danger">
                                {errors?.selectedPostsData}
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                Scheduled Date:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <DatePicker
                                className="form-control"
                                selected={this.state.datetime}
                                filterDate={date => date.getDay() === 2}
                                onChange={e => {
                                  this.setState({ datetime: e });
                                }}
                                minDate={new Date()}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select Upcoming Monday Date"
                              />
                              <br></br>
                              <div className="text-danger">
                                {errors?.datetime}
                              </div>

                              {/* <AvField
                                                                id="event_datetime"
                                                                name="event_datetime"
                                                                type="date"
                                                                className="form-control"
                                                                errorMessage="Required Field"
                                                                validate={{ required: { value: true } }}
                                                                value={this.state.datetime || ""}
                                                                onChange={this.eventDateTimeChange}
                                                            /> */}
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                      <div data-repeater-list="outer-group" className="outer">
                        <br></br>
                        <Row className="justify-content-end ">
                          <Col lg={9}>
                            {this.state?.error?.description && (
                              <h6 className="text-danger mt-2 mb-2">
                                {this.state?.error?.description}
                              </h6>
                            )}
                            <Button
                              type="submit"
                              color="success"
                              disabled={is_saving_weeklyBrew ? true : false}
                              onClick={e => {
                                updateKPI(e);
                              }}
                            >
                              Add Content
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              onClick={e => {
                                e.preventDefault();
                                window.location.href = "/weekly-brew";
                              }}
                            >
                              {" "}
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </AvForm>
                    {!!this.props.error ? (
                      <Alert
                        color="danger"
                        className="mt-4"
                        isOpen={this.state.alert_visible}
                      >
                        {this.props.error}
                      </Alert>
                    ) : null}
                    {!!this.props.success ? (
                      <>
                        <Alert
                          color="success"
                          className="mt-4"
                          isOpen={this.state.alert_visible}
                        >
                          {this.props.success}
                        </Alert>
                      </>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
CreateWeeklyBrew.propTypes = {
  className: PropTypes.any,
  onAddWeeklyBrew: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  onSubmit: PropTypes.func,
};
export default connect()(withRouter(CreateWeeklyBrew));
