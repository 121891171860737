import {
  createNewManualPayout,
  getAllInvestmentVehicles,
  getAllPartnersForPayout,
  getAllPayoutTypes,
  getAllUsersForPayout,
  getAllVPPayoutEnabledSyndicates,
  updateManualPayout,
} from "helpers/backend_helper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select, { createFilter } from "react-select";
import { VariableSizeList as List } from "react-window";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useWindowResize } from "./useWindowResize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const AddManualPayoutTransaction = ({
  manualPayoutModal,
  setManualPayoutModal,
  toggleManualPayoutModal,
  saas_parent_id,
  isAdd,
  editPayoutDetails,
  userData,
  partnerData,
  allCurrencies = [],
  manualPayoutFor,
}) => {
  const [payoutTypes, setPayoutTypes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allPaymentStatus, setAllPaymentStatus] = useState([
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Successful",
      value: "successful",
    },
    // {
    //   label: "Cancelled",
    //   value: "cancelled",
    // },
    // {
    //   label: "Failed",
    //   value: "failed",
    // },
  ]);
  const [allPartners, setAllPartners] = useState([]);
  const [investmentVehicles, setInvestmentVehicles] = useState([]);
  const [vpPayoutEnabledSyndicates, setVPPayoutEnabledSyndicates] = useState(
    []
  );

  const fetchAllInvestmentVehicles = async () => {
    try {
      let data = await getAllInvestmentVehicles();

      if (!data?.status) {
        setInvestmentVehicles([]);
        return;
      }

      setInvestmentVehicles(data?.investmentVehicles || []);
    } catch (error) {
      setInvestmentVehicles([]);
    }
  };

  const fetchVPPayoutEnabledSyndicates = async (req, res) => {
    try {
      let data = await getAllVPPayoutEnabledSyndicates({
        saas_parent_id: manualPayoutFor === "syndicate" ? saas_parent_id : null,
      });

      if (!data?.status) {
        setVPPayoutEnabledSyndicates([]);
        return;
      }

      setVPPayoutEnabledSyndicates(data?.vpPayoutEnabledSyndicates || []);
    } catch (error) {
      setVPPayoutEnabledSyndicates([]);
    }
  };

  useEffect(() => {
    if (manualPayoutModal) {
      fetchPayoutTypes();
      fetchAllUsers();
      fetchAllPartners();
      fetchAllInvestmentVehicles();
      fetchVPPayoutEnabledSyndicates();
    }

    resetAllFields();

    return () => {};
  }, [manualPayoutModal]);

  // used when editing payout details
  useEffect(() => {
    if (!isAdd) {
      let {
        transaction_type,
        payout_partner_id,
        user_id,
        transaction_status,
        payment_reference_id,
        payment_comments,
        additional_details,
        pay_billing_name,
        invoice_link,
        transaction_currency,
        amount_due_usd,
        usd_inr_rate,
        manual_payout_syndicate_id,
        investment_vehicle,
      } = editPayoutDetails || {};
      let { _id } = user_id || {};
      let { payout_date, paid_amount, paid_amount_fee } =
        additional_details || {};

      setSelectedPayoutType(
        payoutTypes?.find(type => type?.value === transaction_type) || {
          value: transaction_type,
          label: transaction_type,
        }
      );
      setSelectedUser(allUsers?.find(user => user?.value === _id));
      setSelectedStatus(
        allPaymentStatus?.find(status => status?.value === transaction_status)
      );
      setSelectedPaymentDate(payout_date ? new Date(payout_date) : new Date());
      setPaidAmount(paid_amount);
      setPaidAmountFee(paid_amount_fee);
      setPaymentRefId(payment_reference_id);
      setPaymentComments(payment_comments);
      setSelectedPartner(
        allPartners?.find(partner => partner?.value === payout_partner_id)
      );
      setPayBillingName(pay_billing_name);
      setInvoiceFile(null);
      setSavedInvoiceLink(invoice_link || "");
      setTransactionCurrency(
        allCurrencies?.find(
          curr => curr?.value === (transaction_currency?.value || "INR")
        )
      );
      setDueUSD(amount_due_usd);
      setUsdInrRate(usd_inr_rate);
      setManualPayoutSyndicateId(
        vpPayoutEnabledSyndicates?.find(
          syndicate => syndicate?.value === manual_payout_syndicate_id?._id
        )
      );
      setInvestmentVehicle(
        investmentVehicles?.find(
          vehicle => vehicle?.value === investment_vehicle?.value
        )
      );

      setInputErrors({
        transaction_type: transaction_type
          ? true
          : errorMessageMap["transaction_type"],
        payout_partner_id: payout_partner_id
          ? true
          : errorMessageMap["payout_partner_id"],
        user_id: _id ? true : errorMessageMap["user_id"],
        payout_date: payout_date ? true : errorMessageMap["payout_date"],
        paid_amount: ![null, undefined, ""].includes(paid_amount)
          ? true
          : errorMessageMap["paid_amount"],
        paid_amount_fee: ![null, undefined, ""].includes(paid_amount_fee)
          ? true
          : errorMessageMap["paid_amount_fee"],
        transaction_status: transaction_status
          ? true
          : errorMessageMap["transaction_status"],
        payment_reference_id: referenceIdRequired
          ? ![null, undefined, ""].includes(payment_reference_id)
            ? true
            : errorMessageMap["payment_reference_id"]
          : true,
        // transaction_currency: !!transaction_currency?.value
        //   ? true
        //   : errorMessageMap["transaction_currency"],
        manual_payout_syndicate_id: manual_payout_syndicate_id
          ? true
          : errorMessageMap["manual_payout_syndicate_id"],
        investment_vehicle: investment_vehicle?.value
          ? true
          : errorMessageMap["investment_vehicle"],
      });
    } else {
      setManualPayoutSyndicateId(
        vpPayoutEnabledSyndicates?.find(
          syndicate => syndicate?.value === saas_parent_id
        )
      );
      setInputErrors(prevErrors => ({
        ...prevErrors,
        manual_payout_syndicate_id: true,
      }));
    }

    return () => {};
  }, [
    isAdd,
    payoutTypes,
    allUsers,
    allPartners,
    vpPayoutEnabledSyndicates,
    investmentVehicles,
    editPayoutDetails,
  ]);

  // function to clear all input fields when modal is toggled
  const resetAllFields = () => {
    setSelectedPayoutType(null);
    setSelectedUser(null);
    setSelectedStatus({
      label: "Successful",
      value: "successful",
    });
    setSelectedPaymentDate(new Date());
    setPaidAmount(null);
    setPaidAmountFee(null);
    setPaymentRefId(null);
    setPaymentComments(null);
    setSelectedPartner(null);
    setPayBillingName(null);
    setInvoiceFile(null);
    setSavedInvoiceLink("");
    setTransactionCurrency(null);
    setDueUSD(null);
    setUsdInrRate(null);
    setManualPayoutSyndicateId(null);
    setInvestmentVehicle(null);

    setInputErrors({
      transaction_type: null,
      payout_partner_id: null,
      user_id: null,
      payout_date: true,
      paid_amount: null,
      paid_amount_fee: null,
      transaction_status: true,
      payment_reference_id: true,
      // transaction_currency: null,
      manual_payout_syndicate_id: null,
      investment_vehicle: null,
    });
  };

  const fetchPayoutTypes = async () => {
    try {
      let response = await getAllPayoutTypes();
      if (response?.status) {
        let { data } = response;
        if (Array.isArray(data)) {
          data = data?.map(type => ({ value: type, label: type }));
          setPayoutTypes(data);
        } else {
          setPayoutTypes([]);
        }
      } else {
        let message = response?.message || "Error while fetching payout types";
        alert(message);
        setPayoutTypes([]);
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching payout types";
      alert(message);
      setPayoutTypes([]);
    }
  };

  const fetchAllUsers = async () => {
    try {
      let response = await getAllUsersForPayout({
        saas_parent_id,
        user_id: userData?._id,
      });
      if (response?.status) {
        let { data } = response;
        if (Array.isArray(data)) {
          if (!userData?.is_saas_parent) {
            data = data.filter(
              option => option?.value !== userData?.saas_parent_id
            );
          }
          setAllUsers(data);
        } else {
          setAllUsers([]);
        }
      } else {
        let message = response?.message || "Error while fetching users";
        alert(message);
        setAllUsers([]);
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching users";
      alert(message);
      setAllUsers([]);
    }
  };

  const fetchAllPartners = async () => {
    try {
      let response = await getAllPartnersForPayout({
        saas_parent_id,
        user_id: userData?._id,
      });
      if (response?.status) {
        let { data } = response;
        if (Array.isArray(data)) {
          if (!userData?.is_saas_parent) {
            data = data.filter(
              option => option?.value !== userData?.saas_parent_id
            );
          }
          setAllPartners(data);
        } else {
          setAllPartners([]);
        }
      } else {
        let message = response?.message || "Error while fetching partners";
        alert(message);
        setAllPartners([]);
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching partners";
      alert(message);
      setAllPartners([]);
    }
  };

  const handlePayoutTypeSelectChange = (newValue, { action, removedValue }) => {
    if (action === "create-option") {
      let temp = payoutTypes;
      temp.push(newValue);
      setPayoutTypes(temp);
    }
    setSelectedPayoutType(newValue);
  };

  // for list of users
  // start
  const listRef = useRef();
  const sizeMap = useRef({});

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index, true);
  }, []);

  const getSize = index => sizeMap.current[index] || 35;

  const [windowWidth] = useWindowResize();

  const OptionRow = ({ children, index, setSize, windowWidth }) => {
    const rowRef = useRef();

    useEffect(() => {
      setSize(index, rowRef.current?.getBoundingClientRect()?.height);

      return () => {};
    }, [setSize, index, windowWidth]);

    return <div ref={rowRef}>{children[index]}</div>;
  };
  // end

  // form-inputs
  const [selectedPayoutType, setSelectedPayoutType] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "Successful",
    value: "successful",
  });
  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());
  const [paidAmount, setPaidAmount] = useState(null);
  const [paidAmountFee, setPaidAmountFee] = useState(null);
  const [paymentRefId, setPaymentRefId] = useState(null);
  const [referenceIdRequired, setReferenceIdRequired] = useState(false);
  const [paymentComments, setPaymentComments] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [payBillingName, setPayBillingName] = useState(null);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [savedInvoiceLink, setSavedInvoiceLink] = useState("");
  const [transactionCurrency, setTransactionCurrency] = useState(null);
  const [dueUSD, setDueUSD] = useState(null);
  const [UsdInrRate, setUsdInrRate] = useState(null);
  const [manualPayoutSyndicateId, setManualPayoutSyndicateId] = useState(null);
  const [investmentVehicle, setInvestmentVehicle] = useState(null);

  const [inputErrors, setInputErrors] = useState({
    transaction_type: null,
    payout_partner_id: null,
    user_id: null,
    payout_date: true,
    paid_amount: null,
    paid_amount_fee: null,
    transaction_status: true,
    payment_reference_id: null,
    // transaction_currency: null,
    manual_payout_syndicate_id: null,
    investment_vehicle: null,
  });

  const errorMessageMap = {
    transaction_type: "Payout type is required",
    payout_partner_id: "Partner for payout is required",
    user_id: "User is required",
    payout_date: "Payout date is required",
    paid_amount: "Amount is required",
    paid_amount_fee: "Fee % is required",
    transaction_status: "Payment status is required",
    payment_reference_id: "Reference ID is required",
    // transaction_currency: "Currency is required",
    manual_payout_syndicate_id: "Syndicate is required",
    investment_vehicle: "Investment Vehicle is required",
  };

  useEffect(() => {
    let flag = selectedStatus?.value === "successful";
    setReferenceIdRequired(flag);
    let tempErrors = inputErrors;
    tempErrors["payment_reference_id"] = flag
      ? ![null, undefined, ""].includes(paymentRefId)
        ? true
        : errorMessageMap["payment_reference_id"]
      : true;
    setInputErrors(tempErrors);

    return () => {};
  }, [selectedStatus]);

  const handleInputErrors = (key, value) => {
    setInputErrors({ ...inputErrors, [key]: value });
  };

  const handleCreateManualPayout = async e => {
    e.preventDefault();

    try {
      if (!Object.keys(inputErrors).every(key => inputErrors[key] === true)) {
        let tempErrors = inputErrors;
        Object.keys(inputErrors)
          .filter(key => inputErrors[key] === null)
          .forEach(key => {
            tempErrors[key] = errorMessageMap[key];
          });
        setInputErrors({ ...tempErrors });
        console.log(tempErrors);

        alert("Please enter all required details");
        return;
      }

      let reqBody = {};
      reqBody["transaction_type"] = selectedPayoutType?.value;
      reqBody["payout_partner_id"] = selectedPartner?.value;
      reqBody["user_id"] = selectedUser?.value;
      reqBody["payout_date"] = selectedPaymentDate;
      reqBody["paid_amount"] = paidAmount;
      reqBody["paid_amount_fee"] = paidAmountFee;
      reqBody["transaction_status"] = selectedStatus?.value;
      reqBody["payment_reference_id"] = paymentRefId;
      reqBody["payment_comments"] = paymentComments;
      reqBody["pay_billing_name"] = referenceIdRequired ? payBillingName : "";
      reqBody["is_manual_payout"] = true;
      reqBody["saas_parent_id"] = saas_parent_id;
      reqBody["invoiceFile"] = invoiceFile?.file;
      reqBody["transaction_currency"] = JSON.stringify(
        // transactionCurrency || {}
        allCurrencies?.find(
          currency => currency?.value === investmentVehicle?.currency
        ) || {}
      );
      reqBody["amount_due_usd"] = parseFloat(dueUSD || 0);
      reqBody["usd_inr_rate"] = parseFloat(UsdInrRate || 0);
      let dueINR = (
        parseFloat(dueUSD || 0) * parseFloat(UsdInrRate || 0)
      ).toFixed(2);
      reqBody["amount_due_inr"] = parseFloat(dueINR || 0);
      reqBody["manual_payout_for"] = manualPayoutFor || "syndicate";
      reqBody["manual_payout_syndicate_id"] =
        manualPayoutFor === "syndicate"
          ? saas_parent_id
          : manualPayoutSyndicateId?.value;
      reqBody["investment_vehicle"] = JSON.stringify(investmentVehicle || {});

      let response = await createNewManualPayout(reqBody);
      if (response?.status) {
        alert(response?.message || "Payout Added");
        toggleManualPayoutModal();
        return;
      } else {
        alert(response?.message || "Error while adding payout");
        return;
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while creating manual payout";
      alert(message);
      return;
    }
  };

  const handleUpdateManualPayout = async e => {
    e.preventDefault();

    try {
      if (!Object.keys(inputErrors).every(key => inputErrors[key] === true)) {
        let tempErrors = inputErrors;
        Object.keys(inputErrors)
          .filter(key => inputErrors[key] === null)
          .forEach(key => {
            tempErrors[key] = errorMessageMap[key];
          });
        setInputErrors({ ...tempErrors });

        alert("Please enter all required details");
        return;
      }

      let reqBody = {};
      reqBody["_id"] = editPayoutDetails?._id;
      reqBody["transaction_type"] = selectedPayoutType?.value;
      reqBody["payout_partner_id"] = selectedPartner?.value;
      reqBody["user_id"] = selectedUser?.value;
      reqBody["payout_date"] = selectedPaymentDate;
      reqBody["paid_amount"] = paidAmount;
      reqBody["paid_amount_fee"] = paidAmountFee;
      reqBody["transaction_status"] = selectedStatus?.value;
      reqBody["payment_reference_id"] = paymentRefId;
      reqBody["payment_comments"] = paymentComments;
      reqBody["pay_billing_name"] = referenceIdRequired ? payBillingName : "";
      reqBody["is_manual_payout"] = true;
      reqBody["saas_parent_id"] = saas_parent_id;
      reqBody["invoiceFile"] = invoiceFile?.file;
      reqBody["oldInvoiceFile"] = savedInvoiceLink || "";
      reqBody["transaction_currency"] = JSON.stringify(
        // transactionCurrency || {}
        allCurrencies?.find(
          currency => currency?.value === investmentVehicle?.currency
        ) || {}
      );
      reqBody["amount_due_usd"] = parseFloat(dueUSD || 0);
      reqBody["usd_inr_rate"] = parseFloat(UsdInrRate || 0);
      let dueINR = (
        parseFloat(dueUSD || 0) * parseFloat(UsdInrRate || 0)
      ).toFixed(2);
      reqBody["amount_due_inr"] = parseFloat(dueINR || 0);
      reqBody["manual_payout_for"] = manualPayoutFor || "syndicate";
      reqBody["manual_payout_syndicate_id"] =
        manualPayoutFor === "syndicate"
          ? saas_parent_id
          : manualPayoutSyndicateId?.value;
      reqBody["investment_vehicle"] = JSON.stringify(investmentVehicle || {});

      let response = await updateManualPayout(reqBody);
      if (response?.status) {
        alert(response?.message || "Payout Updated");
        toggleManualPayoutModal();
        return;
      } else {
        alert(response?.message || "Error while updating payout");
        return;
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating manual payout";
      alert(message);
      return;
    }
  };

  return (
    <>
      <Modal
        isOpen={manualPayoutModal}
        toggle={toggleManualPayoutModal}
        size="lg"
      >
        <ModalHeader toggle={toggleManualPayoutModal}>
          Manual Payout
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={e => {
              if (isAdd) {
                handleCreateManualPayout(e);
              } else {
                handleUpdateManualPayout(e);
              }
            }}
          >
            {/* Payout Type */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Payout Type<span className="text-danger">*</span>
              </Col>
              <Col>
                <CreatableSelect
                  placeholder={`Select or type new payout type`}
                  isClearable
                  options={payoutTypes}
                  value={selectedPayoutType}
                  onChange={(value, action) => {
                    handlePayoutTypeSelectChange(value, action);
                    let key = "transaction_type";
                    handleInputErrors(
                      key,
                      value?.value ? true : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "transaction_type";
                    handleInputErrors(
                      key,
                      selectedPayoutType?.value ? true : errorMessageMap[key]
                    );
                  }}
                />
                {inputErrors["transaction_type"] && (
                  <span className="text-danger">
                    <small>{inputErrors["transaction_type"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Partner for Payout */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Partner For Payout<span className="text-danger">*</span>
              </Col>
              <Col>
                <Select
                  placeholder={`Select Partner For Payout`}
                  isClearable
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={allPartners.sort((a, b) => {
                    if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                      return -1;
                    }
                    if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })}
                  value={selectedPartner}
                  onChange={value => {
                    setSelectedPartner(value);
                    let key = "payout_partner_id";
                    handleInputErrors(
                      key,
                      value?.value ? true : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "payout_partner_id";
                    handleInputErrors(
                      key,
                      selectedPartner?.value ? true : errorMessageMap[key]
                    );
                  }}
                />
                {inputErrors["payout_partner_id"] && (
                  <span className="text-danger">
                    <small>{inputErrors["payout_partner_id"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* User */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select User<span className="text-danger">*</span>
              </Col>
              <Col>
                <Select
                  placeholder={`Select User`}
                  isClearable
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={allUsers}
                  value={selectedUser}
                  onChange={value => {
                    setSelectedUser(value);
                    let key = "user_id";
                    handleInputErrors(
                      key,
                      value?.value ? true : errorMessageMap[key]
                    );
                  }}
                  components={{
                    MenuList: props => {
                      const { options, children, maxHeight } = props;

                      return (
                        <List
                          ref={listRef}
                          height={maxHeight}
                          width="100%"
                          itemCount={children.length}
                          itemSize={getSize}
                          itemData={options}
                        >
                          {({ index, style }) => {
                            return (
                              <div style={style}>
                                <OptionRow
                                  children={children}
                                  index={index}
                                  setSize={setSize}
                                  windowWidth={windowWidth}
                                />
                              </div>
                            );
                          }}
                        </List>
                      );
                    },
                  }}
                  onBlur={() => {
                    let key = "user_id";
                    handleInputErrors(
                      key,
                      selectedUser?.value ? true : errorMessageMap[key]
                    );
                  }}
                />
                {inputErrors["user_id"] && (
                  <span className="text-danger">
                    <small>{inputErrors["user_id"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Payout Date */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Payout Date<span className="text-danger">*</span>
              </Col>
              <Col>
                <DatePicker
                  locale="en-IN"
                  selected={selectedPaymentDate}
                  onChange={value => {
                    setSelectedPaymentDate(value);
                    let key = "payout_date";
                    handleInputErrors(
                      key,
                      !!value ? true : errorMessageMap[key]
                    );
                  }}
                  onSelect={value => {
                    setSelectedPaymentDate(value);
                    let key = "payout_date";
                    handleInputErrors(
                      key,
                      !!value ? true : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "payout_date";
                    handleInputErrors(
                      key,
                      !!selectedPaymentDate ? true : errorMessageMap[key]
                    );
                  }}
                  className="form-control"
                  dateFormat="d MMM, yyyy"
                />
                {inputErrors["payout_date"] && (
                  <span className="text-danger">
                    <small>{inputErrors["payout_date"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Payout Syndicate */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Syndicate<span className="text-danger">*</span>
              </Col>
              <Col>
                <Select
                  placeholder={`Select Syndicate`}
                  options={vpPayoutEnabledSyndicates}
                  value={manualPayoutSyndicateId}
                  isDisabled={manualPayoutFor === "syndicate"}
                  onChange={value => {
                    setManualPayoutSyndicateId(value);
                    let key = "manual_payout_syndicate_id";
                    handleInputErrors(
                      key,
                      value?.value ? true : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "manual_payout_syndicate_id";
                    handleInputErrors(
                      key,
                      manualPayoutSyndicateId?.value
                        ? true
                        : errorMessageMap[key]
                    );
                  }}
                />
                {inputErrors["manual_payout_syndicate_id"] && (
                  <span className="text-danger">
                    <small>{inputErrors["manual_payout_syndicate_id"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Investment Vehicle */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Investment Vehicle<span className="text-danger">*</span>
              </Col>
              <Col>
                <Select
                  placeholder={`Select Investment Vehicle`}
                  options={investmentVehicles}
                  value={investmentVehicle}
                  onChange={value => {
                    setInvestmentVehicle(value);
                    let key = "investment_vehicle";
                    handleInputErrors(
                      key,
                      value?.value ? true : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "investment_vehicle";
                    handleInputErrors(
                      key,
                      investmentVehicle?.value ? true : errorMessageMap[key]
                    );
                  }}
                />
                {inputErrors["investment_vehicle"] && (
                  <span className="text-danger">
                    <small>{inputErrors["investment_vehicle"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Transaction Currency */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Currency<span className="text-danger">*</span>
              </Col>
              <Col>
                <Select
                  placeholder={`Select Currency`}
                  options={
                    allCurrencies?.filter(curr =>
                      ["INR", "USD"].includes(curr?.value)
                    ) || []
                  }
                  // value={transactionCurrency}
                  value={allCurrencies?.find(
                    currency => currency?.value === investmentVehicle?.currency
                  )}
                  isDisabled={true}
                  // onChange={value => {
                  //   setTransactionCurrency(value);
                  //   let key = "transaction_currency";
                  //   handleInputErrors(
                  //     key,
                  //     value?.value ? true : errorMessageMap[key]
                  //   );
                  // }}
                  // onBlur={() => {
                  //   let key = "transaction_currency";
                  //   handleInputErrors(
                  //     key,
                  //     transactionCurrency?.value ? true : errorMessageMap[key]
                  //   );
                  // }}
                />
                {inputErrors["transaction_currency"] && (
                  <span className="text-danger">
                    <small>{inputErrors["transaction_currency"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Paid Amount */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Enter Amount<span className="text-danger">*</span>
              </Col>
              <Col>
                <Input
                  type="number"
                  value={paidAmount}
                  onChange={e => {
                    setPaidAmount(e.target.value);
                    let key = "paid_amount";
                    handleInputErrors(
                      key,
                      ![null, undefined, ""].includes(e.target.value)
                        ? true
                        : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "paid_amount";
                    handleInputErrors(
                      key,
                      ![null, undefined, ""].includes(paidAmount)
                        ? true
                        : errorMessageMap[key]
                    );
                  }}
                  placeholder="Enter Amount"
                />
                {inputErrors["paid_amount"] && (
                  <span className="text-danger">
                    <small>{inputErrors["paid_amount"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Paid Fee % */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Enter Fee %<span className="text-danger">*</span>
              </Col>
              <Col>
                <Input
                  type="number"
                  min="0"
                  max="100"
                  step="0.01"
                  value={paidAmountFee}
                  onChange={e => {
                    setPaidAmountFee(e.target.value);
                    let key = "paid_amount_fee";
                    handleInputErrors(
                      key,
                      ![null, undefined, ""].includes(e.target.value)
                        ? true
                        : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "paid_amount_fee";
                    handleInputErrors(
                      key,
                      ![null, undefined, ""].includes(paidAmountFee)
                        ? true
                        : errorMessageMap[key]
                    );
                  }}
                  placeholder="Enter Fee %"
                />
                {inputErrors["paid_amount_fee"] && (
                  <span className="text-danger">
                    <small>{inputErrors["paid_amount_fee"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Payout Status */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Select Payment Status<span className="text-danger">*</span>
              </Col>
              <Col>
                <Select
                  placeholder={`Select Payment Status`}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={allPaymentStatus.sort((a, b) => {
                    if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                      return -1;
                    }
                    if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })}
                  value={selectedStatus}
                  onChange={value => {
                    setSelectedStatus(value);
                    let key = "transaction_status";
                    handleInputErrors(
                      key,
                      value?.value ? true : errorMessageMap[key]
                    );
                  }}
                  onBlur={() => {
                    let key = "transaction_status";
                    handleInputErrors(
                      key,
                      selectedStatus?.value ? true : errorMessageMap[key]
                    );
                  }}
                />
                {inputErrors["transaction_status"] && (
                  <span className="text-danger">
                    <small>{inputErrors["transaction_status"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Reference ID */}
            <Row className="mb-2 align-items-center">
              <Col xs={12} sm={4} md={3}>
                Enter Reference ID
                {referenceIdRequired && <span className="text-danger">*</span>}
              </Col>
              <Col>
                <Input
                  type="text"
                  value={paymentRefId}
                  onChange={e => {
                    setPaymentRefId(e.target.value);
                    let key = "payment_reference_id";
                    handleInputErrors(
                      key,
                      referenceIdRequired
                        ? ![null, undefined, ""].includes(e.target.value)
                          ? true
                          : errorMessageMap[key]
                        : true
                    );
                  }}
                  onBlur={() => {
                    let key = "payment_reference_id";
                    handleInputErrors(
                      key,
                      referenceIdRequired
                        ? ![null, undefined, ""].includes(paymentRefId)
                          ? true
                          : errorMessageMap[key]
                        : true
                    );
                  }}
                  placeholder="Enter Reference ID"
                />
                {inputErrors["payment_reference_id"] && (
                  <span className="text-danger">
                    <small>{inputErrors["payment_reference_id"]}</small>
                  </span>
                )}
              </Col>
            </Row>

            {/* Billing Name */}
            {referenceIdRequired && (
              <>
                <Row className="mb-2 align-items-center">
                  <Col xs={12} sm={4} md={3}>
                    Enter Billing Name
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      value={payBillingName}
                      onChange={e => {
                        setPayBillingName(e.target.value);
                      }}
                      placeholder="Enter Billing Name"
                    />
                  </Col>
                </Row>
              </>
            )}

            {/* Upload Invoice */}
            {referenceIdRequired && (
              <>
                <Row className="mb-2 align-items-center">
                  <Col xs={12} sm={4} md={3}>
                    Upload Invoice
                  </Col>
                  <Col>
                    <Input
                      type="file"
                      className="inner form-control"
                      accept=".png, .jpeg, .jpg, .gif, .pdf, .csv, .xls, .xlsx"
                      value={invoiceFile?.fileName}
                      multiple={false}
                      onChange={event => {
                        setInvoiceFile({
                          fileName: event.target.value,
                          file: event.target.files?.[0],
                        });
                      }}
                      placeholder="Upload Invoice"
                    />
                    {savedInvoiceLink && (
                      <>
                        <a href={savedInvoiceLink} target="_blank">
                          Saved Invoice
                        </a>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}

            {/* Due Amounts Section */}
            <Row className="mb-2">
              <Col xs={12} sm={4} md={3}>
                Amount due in USD($)
              </Col>
              <Col xs={12} sm={8} md={6} lg={4}>
                <Input
                  type="number"
                  step={0.01}
                  min={0}
                  value={dueUSD}
                  onChange={event => {
                    setDueUSD(event.target.value);
                  }}
                  placeholder="Enter amount due in USD($)"
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12} sm={4} md={3}>
                Conversion Rate (USD-INR)
              </Col>
              <Col xs={12} sm={8} md={6} lg={4}>
                <Input
                  type="number"
                  step={0.01}
                  min={0}
                  value={UsdInrRate}
                  onChange={event => {
                    setUsdInrRate(event.target.value);
                  }}
                  placeholder="Enter conversion rate (USD-INR)"
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12} sm={4} md={3}>
                Amount due in INR(₹)
              </Col>
              <Col xs={12} sm={8} md={6} lg={4}>
                <Input
                  type="number"
                  value={(
                    parseFloat(dueUSD || 0) * parseFloat(UsdInrRate || 0)
                  ).toFixed(2)}
                  disabled
                  placeholder="Enter amount due in INR(₹)"
                />
              </Col>
            </Row>

            {/* Comments */}
            <Row className="mb-2">
              <Col xs={12} sm={4} md={3}>
                Enter Comments
              </Col>
              <Col>
                <Input
                  type="textarea"
                  value={paymentComments}
                  onChange={e => {
                    setPaymentComments(e.target.value);
                  }}
                  placeholder="Enter Additional Info/Comment"
                  rows={4}
                />
              </Col>
            </Row>

            {/* Buttons */}
            <Row className="mb-2 text-center">
              <Col>
                <Button
                  type="reset"
                  color="danger"
                  className="me-1"
                  onClick={toggleManualPayoutModal}
                >
                  Cancel
                </Button>
                <Button type="submit" color="success" className="ms-1">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddManualPayoutTransaction;
