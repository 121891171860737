import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { isEmpty, isEqual } from "lodash";
import { getPostEventDetail } from "store/actions";
import ReactQuill from "react-quill";
import { Figure, Tab, Tabs } from "react-bootstrap";
import {
  addEditEventManualQA,
  fetchAllEventManualQAs,
  fetchAllInvestorsRelatedToSaas,
  updateEventReportQAVisibility,
} from "helpers/backend_helper";
import InvestorListQA from "./InvestorListQA";
import { displayImage } from "helpers/storage_helper";

class QuestionAnswers extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.listRef = React.createRef();
    this.sizeMapRef = React.createRef({});
    this.state = {
      post: {},

      manualQAs: [],
      allSaasInvestors: [],

      qaModal: false,
      questionId: null,
      question: null,
      answer: null,
      mapped_investors: [],
      qaError: null,
      saveLoading: false,

      investorsModal: false,
      selectedQAInvestors: [],
    };
  }

  toggleQAModal = () => {
    this.setState({
      qaModal: !this.state.qaModal,
    });
  };

  toggleInvestorsModal = () => {
    if (this.state.investorsModal) {
      this.setState({
        selectedQAInvestors: [],
      });
    }

    this.setState({
      investorsModal: !this.state.investorsModal,
    });
  };

  handleAddEditQAModal = (questionValue = {}) => {
    this.setState({
      questionId: questionValue?._id || "",
      question: questionValue?.question || "",
      answer: questionValue?.answer || "",
      mapped_investors: questionValue?.mapped_investors || [],
      qaError: "",
      saveLoading: false,
    });
    this.toggleQAModal();
  };

  handleValidQASubmit = async e => {
    this.setState({
      saveLoading: true,
    });

    try {
      let { questionId, question, answer, mapped_investors } = this.state;

      if (!(question && answer)) {
        this.setState({
          qaError: "Please enter question-answer",
          saveLoading: false,
        });

        return;
      }

      let reqBody = {
        questionId,
        question,
        answer,
        mapped_investors,
        postId: this.state.post?._id,
      };

      let response = await addEditEventManualQA(reqBody);

      if (!response?.status) {
        this.setState({
          qaError:
            response?.message ||
            "There was an error while saving question-answer",
          saveLoading: false,
        });

        return;
      }

      await this.fetchAllEventManualQAsAPI({
        postId: this.state?.post?._id,
      });

      // await this.props?.onGetPostEventDetail({
      //   postId: this.state?.post?._id,
      //   showAll: true,
      // });

      this.handleAddEditQAModal();
    } catch (error) {
      let qaError =
        error?.response?.data?.message ||
        error?.message ||
        "There was an error while saving question-answer";

      this.setState({
        qaError: qaError,
        saveLoading: false,
      });

      return;
    }
  };

  async handleShowOnFrontendChange(reqBody) {
    try {
      reqBody = {
        ...reqBody,
        postId: this.state?.post?._id,
      };

      let response = await updateEventReportQAVisibility(reqBody);

      if (!response?.status) {
        let errorMessage = response?.message || "Error while deleting q&a";
        alert(errorMessage);
        return;
      }

      await this.fetchAllEventManualQAsAPI({
        postId: this.state?.post?._id,
      });

      // await this.props?.onGetPostEventDetail({
      //   postId: this.state?.post?._id,
      //   showAll: true,
      // });
    } catch (error) {
      let errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error while deleting q&a";
      alert(errorMessage);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.postDetails, nextProps.postDetails)) {
      this.setState({ post: nextProps.postDetails });
      this.fetchAllEventManualQAsAPI({
        postId: nextProps?.postDetails?._id,
      });

      this.fetchAllInvestorsRelatedToSaasAPI({
        saasParentId: nextProps?.postDetails?.saas_parent_id?._id,
      });
    }
  }

  fetchAllEventManualQAsAPI = async reqBody => {
    try {
      let response = await fetchAllEventManualQAs({
        ...reqBody,
      });

      if (!response?.status) {
        this.setState({
          manualQAs: [],
        });
        return;
      }

      this.setState({
        manualQAs: response?.data?.eventQA || [],
      });
    } catch (error) {
      this.setState({
        manualQAs: [],
      });
    }
  };

  fetchAllInvestorsRelatedToSaasAPI = async reqBody => {
    try {
      let response = await fetchAllInvestorsRelatedToSaas({
        ...reqBody,
      });

      if (!response?.status) {
        this.setState({
          allSaasInvestors: [],
        });
        return;
      }

      this.setState({
        allSaasInvestors: response?.data?.allInvestors || [],
      });
    } catch (error) {
      this.setState({
        allSaasInvestors: [],
      });
    }
  };

  eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  render() {
    const { post } = this.state;

    let qa = [];

    if (!isEmpty(post) && post !== undefined) {
      qa = post?.media_content.filter(val => val?.media_type[0] === "qa") || [];
      qa = (qa?.length > 0 && qa[0]?.content?.body?.questions) || [];
    }

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row className="mt-4">
              <Col lg={12} className="mb-2 mb-sm-0">
                <div className="text-end">
                  <Button
                    color="primary"
                    className="font-16 btn-block"
                    onClick={() => {
                      this.handleAddEditQAModal();
                    }}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add New Question-Answer
                  </Button>
                </div>
              </Col>

              <Col lg="12">
                <Tabs>
                  <Tab title="Event Q&A" eventKey="Event Q&A" key="Event Q&A">
                    <Card>
                      <Row>
                        <Col lg={12} className="border rounded p-0">
                          {qa?.length ? (
                            <>
                              <Table className="rounded mb-0">
                                <tbody className="rounded">
                                  <tr
                                    className="rounded"
                                    style={{
                                      backgroundColor: "rgb(221 221 221)",
                                    }}
                                  >
                                    <td width="50%" align="center">
                                      <b>Question</b>
                                    </td>
                                    <td width="50%" align="center">
                                      <b>Answer</b>
                                    </td>
                                  </tr>
                                  <br />

                                  {qa?.map((question, index) => {
                                    return (
                                      <>
                                        <tr
                                          className="rounded"
                                          style={{
                                            backgroundColor: "rgb(221 221 221)",
                                          }}
                                        >
                                          <th colSpan={2}>
                                            {question?.name} ({question?.email})
                                          </th>
                                        </tr>

                                        {question?.question_details?.length ? (
                                          <>
                                            {question?.question_details?.map(
                                              (userQuestion, index) => {
                                                return (
                                                  <>
                                                    <tr
                                                      key={
                                                        userQuestion?.question +
                                                        question?.answer
                                                      }
                                                    >
                                                      <td width="50%">
                                                        {userQuestion?.question}
                                                      </td>
                                                      <td width="50%">
                                                        {userQuestion?.answer}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Tab>

                  <Tab
                    title="Manual Q&A"
                    eventKey="Manual Q&A"
                    key="Manual Q&A"
                  >
                    <Card>
                      <CardBody>
                        <Table className="rounded mb-0">
                          <tbody className="rounded">
                            <tr
                              className="rounded"
                              style={{
                                backgroundColor: "rgb(221 221 221)",
                              }}
                            >
                              <td width="30%" align="center">
                                <b>Question</b>
                              </td>
                              <td width="50%" align="center">
                                <b>Answer</b>
                              </td>
                              <td width="10%" align="center">
                                <b>Tagged Investors</b>
                              </td>
                              <td width="10%" align="center">
                                <b>Action</b>
                              </td>
                            </tr>

                            {this.state?.manualQAs?.length ? (
                              <>
                                {this.state?.manualQAs?.map(qa => {
                                  return (
                                    <>
                                      <tr key={qa?._id}>
                                        <td width="30%">{qa?.question}</td>
                                        <td
                                          width="50%"
                                          className="react-quill-preview"
                                          dangerouslySetInnerHTML={{
                                            __html: qa?.answer,
                                          }}
                                        ></td>
                                        <td width="10%" align="center">
                                          {!!qa?.mapped_investors?.length ? (
                                            <>
                                              <Link
                                                to="#"
                                                onClick={e => {
                                                  this.setState({
                                                    selectedQAInvestors:
                                                      qa?.mapped_investors ||
                                                      [],
                                                  });

                                                  this.toggleInvestorsModal();
                                                }}
                                                title="Click to see investor list"
                                              >
                                                {qa?.mapped_investors?.length}
                                              </Link>
                                            </>
                                          ) : (
                                            <>{qa?.mapped_investors?.length}</>
                                          )}
                                        </td>
                                        <td width="10%" align="center">
                                          <Link
                                            className="text-info me-1"
                                            to="#"
                                            title="Edit Q-A"
                                          >
                                            <i
                                              className="mdi mdi-pencil font-size-18"
                                              id="edittooltip"
                                              onClick={e => {
                                                this.handleAddEditQAModal(qa);
                                              }}
                                            ></i>
                                          </Link>
                                          <Link
                                            className="text-danger ms-1"
                                            to="#"
                                            title="Delete Q-A"
                                          >
                                            <i
                                              className="bx bx-trash font-size-18"
                                              id="edittooltip"
                                              onClick={e => {
                                                this.handleShowOnFrontendChange(
                                                  {
                                                    questionId: qa?._id,
                                                  }
                                                );
                                              }}
                                            ></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={4} align="center">
                                    No Question-Answer
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Tab>
                </Tabs>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.qaModal}
              toggle={() => {
                this.toggleQAModal();
              }}
              size="lg"
            >
              <ModalHeader
                toggle={() => {
                  this.toggleQAModal();
                }}
                tag="h4"
              >
                Question - Answer
              </ModalHeader>

              <ModalBody>
                <Row className="mb-2">
                  <Col>
                    <Label>Question:</Label>

                    <Input
                      type="textarea"
                      rows={3}
                      value={this.state.question}
                      onChange={e => {
                        this.setState({
                          question: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <Label>Answer:</Label>

                    <ReactQuill
                      id="answer"
                      style={{ height: "150px" }}
                      placeholder=""
                      value={this.state.answer}
                      onChange={e => {
                        this.setState({
                          answer: e,
                        });
                      }}
                      modules={this.eventDescriptionEditorModules}
                      formats={this.eventDescriptionEditorFormats}
                    />
                  </Col>
                </Row>

                <Row
                  className="mb-4"
                  style={{
                    marginTop: "3.75rem",
                  }}
                >
                  <Label>Select Investors:</Label>

                  <InvestorListQA
                    allInvestors={this.state.allSaasInvestors}
                    mappedInvestors={this.state.mapped_investors}
                    setMappedInvestors={values => {
                      this.setState({
                        mapped_investors: values
                          ?.map(investor => investor?.value)
                          ?.filter(data => !!data),
                      });
                    }}
                  />
                </Row>

                <Row
                  className="mb-4"
                  // style={{
                  //   marginTop: "3.75rem",
                  // }}
                >
                  {!!this.state.qaError && (
                    <>
                      <Col md={12} className="mb-2">
                        <div className="text-danger">{this.state.qaError}</div>
                      </Col>
                    </>
                  )}

                  <Col className="d-flex gap-2">
                    <Button
                      color="success"
                      className="font-16 btn-block"
                      onClick={e => {
                        this.handleValidQASubmit(e);
                      }}
                    >
                      Save
                    </Button>

                    <Button
                      color="danger"
                      className="font-16 btn-block"
                      onClick={() => {
                        this.handleAddEditQAModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.investorsModal}
              toggle={() => {
                this.toggleInvestorsModal();
              }}
              size="lg"
            >
              <ModalHeader
                toggle={() => {
                  this.toggleInvestorsModal();
                }}
                tag="h4"
              >
                Question - Answer Tagged Investors
              </ModalHeader>

              <ModalBody
                style={{
                  maxHeight: "500px",
                  minHeight: "500px",
                  overflow: "auto",
                }}
              >
                <Row>
                  {this.state.selectedQAInvestors?.map((investor, index) => {
                    let investorValue = this.state.allSaasInvestors?.find(
                      inv => inv?.value === investor
                    );

                    if (!investorValue) {
                      return null;
                    }

                    return (
                      <p>
                        <strong>{index + 1}</strong>. {investorValue?.userName}
                      </p>
                    );

                    // return (
                    //   <Col md={12}>
                    //     <Figure>
                    //       <p className="mt-3 mr-3">
                    //         <strong>{index + 1}</strong>
                    //       </p>

                    //       <Figure.Image
                    //         alt=""
                    //         src={displayImage(investorValue?.profilePic)}
                    //         onError={e => {
                    //           if (e.target) {
                    //             e.target.src = "/img/profile.png";
                    //           }
                    //         }}
                    //       />
                    //     </Figure>
                    //     <p>{investorValue?.userName}</p>
                    //   </Col>
                    // );
                  })}
                </Row>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

QuestionAnswers.propTypes = {
  match: PropTypes.object,
  postDetails: PropTypes.object,
  onGetPostEventDetail: PropTypes.func,
  error: PropTypes.string,
};

const mapStateToProps = ({ pitchdays }) => ({
  error: pitchdays.updateRoleError,
});

const mapDispatchToProps = dispatch => ({
  onGetPostEventDetail: payload => dispatch(getPostEventDetail(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionAnswers));
