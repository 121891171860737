import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { amountNumberToTextFrmt } from "../../helpers/utils";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb";
import EvaluationReport from "./EvaluationReport";
import AssessmentReport from "./AssessmentReport";
import EventEngadgementActivity from "./EventEngadgementActivity";
import QuestionAnswers from "./QuestionAnswers";
import moment from "moment";

import {
  getAppliedFundings,
  updateStatus,
  copyDealStatus,
  getDealManagers,
  updateFundingDetails,
  getFundingDealStatusList,
  getFundingDealTypeList,
  updateZoomInvestorIntentStatus,
  onSendConsentToInvestor,
  updateStatusSuccess
} from "store/appliedFunding/actions";
import {
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { profilePicture } from "../../helpers/storage_helper";
import InvestorGroupsModal from "./AppliedFundingList/InvestorGroupsModal";
import { Table, Form } from "react-bootstrap";
import {
  getAllUsersForReference,
  sendZoomInvestorIntentUpdate,
  sendConsentToInvestor,
  updateTermSheetStatus,
  getAllUsersForReferenceEvenDisabled,
} from "helpers/backend_helper";
import {
  checkDeviceType,
  getLocationString,
  // getOperatingSystem,
  
  getDeviceDetails,
} from "../../helpers/logs_helper";
import { isEmpty } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { encryptDataToArray } from "helpers/EncryptDecrypt";
import { ToastContainer, toast } from "react-toastify";
import { ToWords } from "to-words";
import {
  exchangeCurrencyRates,
  formatNumberToStringWithCurrencyV2,
} from "helpers/common";

// TO DETECT USER BROWSER; p.s. i don't know how it works, it just does ;)
navigator.browserDetection = (function () {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
})();

const removeNullUndefined = value => {
  return [null, "null", undefined, "undefined"].includes(value) ? "" : value;
};
const handleButtonClick = term_sheet_file => {
  // window.open = `${process.env.REACT_APP_API_URL}/${term_sheet_file}`;
  var anchor = document.createElement("a");
  anchor.href = `${process.env.REACT_APP_API_URL}/${term_sheet_file}`;
  anchor.target = "_blank";
  anchor.click();
};
function FounderDetails({
  match,
  dealManagers,
  onGetDealManagers,
  onUpdateStatus,
  onUpdateStatusSuccess,
  onCopyDealStatus,
  UpdateFundingDealStatusSuccess,
  UpdateFundingDealStatusError,
  CopyDealStatusSuccess,
  CopyDealStatusError,
  onGetFundingDealStatusList,
  // onGetValuationTypesList,
  onGetFundingDealTypeList,
  onUpdateZoomInvestorIntentStatus,
  fundingDealStatusList,
  fundingDealTypeList,
  appliedFunding,
  ...props
}) {
  const [appliedFundingDealId, setAppliedFundingDealId] = useState({});
  const [appliedFundingDeal, setAppliedFundingDeal] = useState({});
  const [investmentVehicleOptions, setInvestmentVehicleOptions] = useState([]);
  const [evaluationReport, setEvaluationReport] = useState({});
  const [startupInfo, setStartupInfo] = useState({});
  const [pan_number, setPanNumber] = useState("");
  const [fundDetails, setFundDetails] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [assessmentReport, setAssessmentReport] = useState({});
  const [approvalStatus, setApprovalStatus] = useState("");
  const [dealManagerId, setDealmanagerId] = useState("");
  const [selectedInvestmentVehicle, setSelectedInvestmentVehicle] = useState([
    { label: "Invstt Trust", value: "Invstt Trust", currency: "INR" },
  ]);
  const [comment, setComment] = useState("");
  const [activeTab, setActiveTab] = useState("3");
  const [loading, setLoading] = useState(true);
  const [round, setRound] = useState("");
  const [valuationType, setValuationType] = useState("");
  const [valuationTypeToShow, setValuationTypeToShow] = useState("");
  const [dealType, setDealType] = useState("");
  const [whatIsYourValuation, setWhatIsYourValuation] = useState("");
  const [valuationFloor, setValuationFloor] = useState("");
  const [valuationCap, setValuationCap] = useState("");
  const [selectSaas, setSelectSaas] = useState(null);
  const [optionsSaas, setOptionsSaas] = useState([]);
  const [directCapCurrency, setDirectCapCurrency] = useState("INR");

  const [howMuchRaising, setHowMuchRaising] = useState("");
  const [getPlatform, setPlatform] = useState("");
  const [totalInvestor, setTotalInvestor] = useState("");
  const [getPreviousFundingHistory, setPreviousFundingHistory] = useState("");
  const [getRemarks, setRemarks] = useState("");
  const [getMonth, setMonth] = useState("");
  const [getYears, setYears] = useState("");
  const [getDiscount, setDiscount] = useState("");
  const [fundAskTarget, setFundAskTarget] = useState("");
  const [target, setTarget] = useState("");
  const [toggleUpdateDealStatus, setToggleUpdateDealStatus] = useState(false);
  const [toggleTermSheetStatus, setToggleTermSheetStatus] = useState(false);
  const [selectedTermSheetStatus, setSelectedTermSheetStatus] = useState("");
  const [termSheetFile, setTermSheetFile] = useState("");
  const [toggleCopyDealStatus, setToggleCopyDealStatus] = useState(false);
  const [dealStatusList, setDealStatusList] = useState([]);
  const [dealStatus, setDealStatus] = useState("");
  const [copydealStatus, setcopyDealStatus] = useState("");
  const [showButton, setshowButton] = useState(true);
  const [getFundSource, setFundSource] = useState("");
  const [minAmount, setMinAmount] = useState([]);
  const [minAmountUsd, setMinAmountUsd] = useState("");
  const [minAmountCapTab, setMinAmountCapTab] = useState([]);
  const [minAmountCapTabUsd, setMinAmountCapTabUsd] = useState("");
  const [existingCommmitment, setExistingCommmitment] = useState([]);
  const [minAmountSyndicate, setMinAmountSyndicate] = useState([]);
  const [minAmountSyndicateUsd, setMinAmountSyndicateUsd] = useState("");
  const [saasName, setSaasName] = useState("");
  const [toggleCountryMismatch, setCountryMismatch] = useState(false);
  const [updateType, setUpdateType] = useState("current");

  const [transactionFeesForSyndicate, setTransactionFeesForSyndicate] =
    useState([]);
  const [transactionFeesForSyndicateNew, setTransactionFeesForSyndicateNew] =
    useState({ ["InvsttTrust"]: "" });
  const [transaction_fees_annual, setTransactionFeesAnnual] = useState("");
  const [transactionFeesForCapTab, setTransactionFeesForCapTab] = useState([]);
  const [exit_documentation_fees_captab, setExit_documentation_fees_captab] =
    useState("");
  const [carryOnCapitalGains, setCarryOnCapitalGains] = useState([]);
  const [carryOnCapitalGainsNew, setCarryOnCapitalGainsNew] = useState({});

  const [investorGroupModalOpen, setInvestorGroupsModal] = useState(true);
  const [lastSixMonthsTab, setLastSixMonthsTab] = useState("revenue");
  const [additionalInfoTab, setAdditionalInfoTab] = useState("6.1");
  const [updateClicked, setUpdateClicked] = useState(false);
  const [updateTermSheet, setupdateTermSheet] = useState(false);
  const [copyClicked, setCopyClicked] = useState(false);
  const [getUserRolles, setUserRolles] = useState({});
  const [zoomInvestorUpdateLoad, setZoomInvestorUpdateLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const [existingCommitmentErrors, setExistingCommitmentErrors] = useState({});
  const [consetSendLoad, setConsetSendLoad] = useState(false);
  const [fundingRoundList, setFundingRoundList] = useState([]);
  const [geolocationData, setGeolocationData] = useState({});
  const columns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
  ];
  const CURRENCY_MAP = {
    USD: {
      value: "USD",
      label: "United States Dollar",
      symbol: "US$",
      locale: "en-US",
    },
    INR: {
      value: "INR",
      label: "Indian Rupee",
      symbol: "₹",
      locale: "en-IN",
    },
  };
  const CURRENCY_VALUES = [
    {
      value: "INR",
      label: "INR",
    },
    {
      value: "USD",
      label: "USD",
    },
  ];
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const PLATFORMS = [
    { value: "lets venture", label: "Lets Venture" },
    { value: "venture catalysts", label: "Venture Catalysts" },
    { value: "mumbai venture", label: "Mumbai Venture" },
    { value: "jito", label: "Jito" },
    { value: "direct", label: "Direct" },
    { value: "wfc", label: "WFC" },
  ];
  const DEFAULT_FUNDING_SOURCE = [
    { label: "On-Platform", value: "On-Platform" },
  ];
  const IS_HE_SHE_LEAD_INVESTOR = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];
  const VALUATION_TYPES = [
    { value: "pre_money", label: "Pre Money" },
    { value: "post_money", label: "Post Money" },
  ];
  const MONTHS = [
    {
      value: "01",
      label: "January",
    },
    {
      value: "02",
      label: "February",
    },
    {
      value: "03",
      label: "March",
    },
    {
      value: "04",
      label: "April",
    },
    {
      value: "05",
      label: "May",
    },
    {
      value: "06",
      label: "June",
    },
    {
      value: "07",
      label: "July",
    },
    {
      value: "08",
      label: "August",
    },
    {
      value: "09",
      label: "September",
    },
    {
      value: "10",
      label: "October",
    },
    {
      value: "11",
      label: "November",
    },
    {
      value: "12",
      label: "December",
    },
  ];

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const eventDescriptionEditorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "clean",
  ];

  const eventDescriptionEditorModulesDetailed = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const eventDescriptionEditorFormatsDetailed = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
  ];

  const [investmentThesisModal, setInvestmentThesisModal] = useState(false);
  const [investmentThesis, setInvestmentThesis] = useState({
    thesis: null,
    successMsg: null,
    errorMsg: null,
  });
  const [investmentThesisSaving, setInvestmentThesisSaving] = useState(false);
  const [thesisType, SetThesisType] = useState("");
  const [capTableRadio, setCapTableRadio] = useState(false);
  const [valAmountRadio, setValAmountRadio] = useState(true);

  const toggleInvestmentThesisModal = (investmentThesis, type) => {
    SetThesisType(type);
    setInvestmentThesisModal(!investmentThesisModal);
    setInvestmentThesis({
      thesis: investmentThesis,
      successMsg: null,
      errorMsg: null,
    });
  };

  const term_sheet_status_options = [
    { value: "sent_to_sebi", label: "Term Sheet under preparation" },
    { value: "received_from_sebi", label: "Term sheet ready" },
  ];
  const generateYearOptions = optLen => {
    return Array(optLen || 50)
      .fill(0)
      .map((_y, i) => ({
        label: `${new Date().getFullYear() - i}`,
        value: `${new Date().getFullYear() - i}`,
      }));
  };
  const YEARS = generateYearOptions(50);
  const handleInvestorGroupsModal = () => {
    // setInvestorGroupsModal(!investorGroupModalOpen);
    setInvestorGroupsModal(true);
  };

  useEffect(() => {
    setUserRolles(JSON.parse(localStorage.getItem("roleWiseAccess")));
    onGetFundingDealStatusList();
    // await onGetValuationTypesList();
    onGetFundingDealTypeList();
    let id = match.params.id;
    setAppliedFundingDealId(id);
    onGetDealManagers();
    getAllFundingRounds();
    getFundingDealDetails(id);
    getGeolocationData();

    setLoading(false);
    return () => {};
  }, [investorGroupModalOpen]);

  const getGeolocationData = async () => {
    // FETCHING USER DEVICE DATA;
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    await axios
      .get("https://geolocation-db.com/json/")
      .then(res => setGeolocationData(res.data))
      .catch(err => {
        // console.log("ERROR WHILE FETCHING GEOLOCATION DATA:", err)
      });
  };

  const getAllFundingRounds = async () => {
    const token = authHeader();
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/generalmaster/getallbytype/funding_round`,
        { headers: { Authorization: `${token}` } }
      )
      .then(async response => {
        if (response.status === 200) {
          setFundingRoundList(response?.data?.mastervalue?.[0]?.values);
        }
      })
      .catch(err => {});
  };

  const getFundingDealDetails = async id => {
    const token = authHeader();
    await axios
      .get(
        process.env.REACT_APP_API_URL + `/api/applyforfunding/getdetail/${id}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(async response => {
        if (response.status === 200) {
          let allInvestmentVehicles =
            response?.data?.data?.investmentVehicle?.masterValue?.values;

          // client need to only have two funds for now, to be removed eventually
          // -> further commented on client demand
          let onlyTwoFunds = allInvestmentVehicles.filter(d => {
            return true;
            // if (
            //   d.value === "WFC Global Angels Fund" ||
            //   d.value === "Invstt Trust"
            // )
            //   return d;
          });
          let filteredInvestmentVehicles = onlyTwoFunds;
          // if (response?.data?.data?.founder?.[0]?.currency?.value === "USD") {
          //   filteredInvestmentVehicles = onlyTwoFunds.filter(iv => {
          //     if (iv.currency === "USD") return iv;
          //   });
          //   setSelectedInvestmentVehicle([filteredInvestmentVehicles[0]]);
          // }
          setSaasOptions(response?.data?.data?.user);
          setAppliedFundingDeal(response?.data?.data?.applyforfundings);
          setFundDetails(response?.data?.data?.fund_details);
          setParticularSyndicateDetails(
            response?.data?.data?.applyforfundings?.saas_parent_id ||
              response?.data?.data?.user?.saas_parent_id
          );
          setEvaluationReport(response?.data?.data?.evaluationReport);
          setStartupInfo(response?.data?.data?.founder);
          setPanNumber(
            response?.data?.data?.founder?.[0]?.basic_information?.pan_number ||
              ""
          );
          setUserInfo(response?.data?.data?.user);
          setInvestmentVehicleOptions(
            filteredInvestmentVehicles?.map(value => value) || []
          );
          const current_status =
            response?.data?.data?.applyforfundings?.current_status;
          const deal_manager_id =
            response?.data?.data?.applyforfundings?.deal_manager;
          const approve_reject_reason =
            response?.data?.data?.applyforfundings?.approve_reject_reason;
          const deal_historys =
            response?.data?.data?.applyforfundings?.deal_historys[
              response?.data?.data?.applyforfundings?.deal_historys.length - 1
            ];

          if (current_status !== "") {
            fundingDealStatusList.map((value, key) => {
              if (
                value.value == current_status ||
                value.label == current_status
              ) {
                setDealStatus(value.label);
                setcopyDealStatus(value.label);
              }
            });
          }
          setDealmanagerId(
            response?.data?.data?.applyforfundings?.deal_manager?.user_id?._id
          );
          // setDealmanagerId(deal_historys?.activated_user_id);
          setComment(approve_reject_reason);

          if (response?.data?.data?.applyforfundings?.funding_information) {
            if (
              response?.data?.data?.applyforfundings?.funding_information
                ?.valuation_type === "pre_money"
            ) {
              setValuationTypeToShow("Pre Money");
              setValuationType("pre_money");
            } else {
              setValuationTypeToShow("Post Money");
              setValuationType("post_money");
            }
            setHowMuchRaising(
              response?.data?.data?.applyforfundings?.funding_information
                ?.how_much_fund_raising
            );
            setFundAskTarget(
              response?.data?.data?.applyforfundings?.funding_information
                ?.fund_ask_target || ""
            );
            setValuationFloor(
              response?.data?.data?.applyforfundings?.funding_information
                ?.valuation_floor || ""
            );
            if (
              response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle == "WFC Global Angels Fund" ||
              response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle == "Angelist US"
            ) {
              setTransactionFeesForSyndicateNew({
                ...transactionFeesForSyndicateNew,
                [response?.data?.data?.applyforfundings?.funding_information?.investment_vehicle[0]?.replace(
                  /\s+/g,
                  ""
                )]:
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.transaction_fees,
              });
              setCarryOnCapitalGainsNew({
                ...carryOnCapitalGainsNew,
                [response?.data?.data?.applyforfundings?.funding_information?.investment_vehicle[0]?.replace(
                  /\s+/g,
                  ""
                )]:
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.carry_on_capital_gains,
              });
              setSelectedInvestmentVehicle([
                {
                  value:
                    response?.data?.data?.applyforfundings?.funding_information
                      ?.investment_vehicle[0],
                  label:
                    response?.data?.data?.applyforfundings?.funding_information
                      ?.investment_vehicle[0],
                  currency: "USD",
                },
              ]);
            } else if (
              response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle == "Invstt Trust" ||
              response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle == "Angelist India"
            ) {
              setTransactionFeesForSyndicateNew({
                ...transactionFeesForSyndicateNew,
                [response?.data?.data?.applyforfundings?.funding_information?.investment_vehicle[0]?.replace(
                  /\s+/g,
                  ""
                )]:
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.transaction_fees,
              });
              setCarryOnCapitalGainsNew({
                ...carryOnCapitalGainsNew,
                [response?.data?.data?.applyforfundings?.funding_information?.investment_vehicle[0]?.replace(
                  /\s+/g,
                  ""
                )]:
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.carry_on_capital_gains,
              });

              setSelectedInvestmentVehicle([
                {
                  value:
                    response?.data?.data?.applyforfundings?.funding_information
                      ?.investment_vehicle[0],
                  label:
                    response?.data?.data?.applyforfundings?.funding_information
                      ?.investment_vehicle[0],
                  currency: "INR",
                },
              ]);
            } else if (
              response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle.length > 1 ||
              (response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle.length == 1 &&
                Object.keys(
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.investment_vehicle[0]
                ).includes("currency"))
            ) {
              if (Object.keys(transactionFeesForSyndicateNew).length === 0)
                setTransactionFeesForSyndicateNew({
                  ...transactionFeesForSyndicateNew,
                  ["InvsttTrust"]:
                    response?.data?.data?.applyforfundings?.funding_information
                      ?.transaction_fees,
                });
              if (Object.keys(carryOnCapitalGainsNew).length === 0)
                setCarryOnCapitalGainsNew({
                  ...carryOnCapitalGainsNew,
                  ["InvsttTrust"]:
                    response?.data?.data?.applyforfundings?.funding_information
                      ?.carry_on_capital_gains,
                });
              setSelectedInvestmentVehicle(
                response?.data?.data?.applyforfundings?.funding_information
                  ?.investment_vehicle
              );
            } else {
              setTransactionFeesForSyndicateNew({
                // ...transactionFeesForSyndicateNew,
                // ["InvsttTrust"]:
                [`${filteredInvestmentVehicles?.value?.[0]?.replace(
                  /\s/g,
                  ""
                )}`]:
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.transaction_fees,
              });
              setCarryOnCapitalGainsNew({
                // ...carryOnCapitalGainsNew,
                // ["InvsttTrust"]:
                [`${filteredInvestmentVehicles?.value?.[0]?.replace(
                  /\s/g,
                  ""
                )}`]:
                  response?.data?.data?.applyforfundings?.funding_information
                    ?.carry_on_capital_gains,
              });
              // setSelectedInvestmentVehicle([
              //   {
              //     label: "Invstt Trust",
              //     value: "Invstt Trust",
              //     currency: "INR",
              //   },
              // ]);
              setSelectedInvestmentVehicle([filteredInvestmentVehicles[0]]);
            }
            setValuationCap(
              response?.data?.data?.applyforfundings?.funding_information
                ?.valuation_cap || ""
            );
            setPlatform(
              response?.data?.data?.applyforfundings?.funding_information
                ?.platform || ""
            );

            if (
              response?.data?.data?.applyforfundings?.funding_information
                ?.iv_mapped_transaction_fees &&
              Object.keys(
                response?.data?.data?.applyforfundings?.funding_information
                  ?.iv_mapped_transaction_fees
              ).length > 0
            )
              setTransactionFeesForSyndicateNew(
                response?.data?.data?.applyforfundings?.funding_information
                  ?.iv_mapped_transaction_fees
              );

            if (
              response?.data?.data?.applyforfundings?.funding_information
                ?.iv_mapped_carry_on_capital_gains &&
              Object.keys(
                response?.data?.data?.applyforfundings?.funding_information
                  ?.iv_mapped_carry_on_capital_gains
              ).length > 0
            )
              setCarryOnCapitalGainsNew(
                response?.data?.data?.applyforfundings?.funding_information
                  ?.iv_mapped_carry_on_capital_gains
              );

            setPreviousFundingHistory(
              response?.data?.data?.applyforfundings?.funding_information
                ?.previous_fund_history || ""
            );
            setMonth(
              new Date(
                response?.data?.data?.applyforfundings?.funding_information?.deal_date
              ).getMonth() + 1 || ""
            );
            setYears(
              new Date(
                response?.data?.data?.applyforfundings?.funding_information?.deal_date
              ).getFullYear() || ""
            );
            setRemarks(
              response?.data?.data?.applyforfundings?.funding_information
                ?.remarks || ""
            );
            setDiscount(
              response?.data?.data?.applyforfundings?.funding_information
                ?.discount &&
                response?.data?.data?.applyforfundings?.funding_information
                  ?.discount !== "undefined"
                ? response?.data?.data?.applyforfundings?.funding_information
                    ?.discount
                : ""
            );
            setSchemeName(
              response?.data?.data?.applyforfundings?.scheme_name &&
                response?.data?.data?.applyforfundings?.scheme_name !==
                  "undefined"
                ? response?.data?.data?.applyforfundings?.scheme_name
                : ""
            );
            setSharePrice(
              response?.data?.data?.applyforfundings?.share_price &&
                response?.data?.data?.applyforfundings?.share_price !==
                  "undefined"
                ? response?.data?.data?.applyforfundings?.share_price
                : ""
            );
            setSharePriceUSD(
              response?.data?.data?.applyforfundings?.share_price_usd &&
                response?.data?.data?.applyforfundings?.share_price_usd !==
                  "undefined"
                ? response?.data?.data?.applyforfundings?.share_price_usd
                : ""
            );
            setTotalInvestor(
              response?.data?.data?.applyforfundings?.funding_information
                ?.number_of_investors || ""
            );
            setWhatIsYourValuation(
              response?.data?.data?.applyforfundings?.funding_information
                ?.current_valuation
            );
            let roundType =
              round != ""
                ? round
                : response?.data?.data?.applyforfundings?.funding_information
                    ?.funding_round;
            setRound(roundType);
            setFundSource(
              response?.data?.data?.applyforfundings?.funding_information
                ?.funding_source || ""
            );
            let deal_type_value =
              dealType != ""
                ? dealType
                : response?.data?.data?.applyforfundings?.funding_information
                    ?.deal_type;
            setDealType(deal_type_value);
            setMinAmount(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_amount
            );
            setMinAmountUsd(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_amount_usd
            );
            setMinAmountSyndicate(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_amount_syndicate
            );
            setMinAmountSyndicateUsd(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_amount_syndicate_usd
            );
            setTransactionFeesAnnual(
              response?.data?.data?.applyforfundings?.funding_information
                ?.transaction_fees_annual
            );
            setCapTableRadio(
              response?.data?.data?.applyforfundings?.funding_information
                ?.capTableRadio
            );
            setValAmountRadio(
              response?.data?.data?.applyforfundings?.funding_information
                ?.valAmountRadio
            );
            setDirectCapCurrency(
              response?.data?.data?.applyforfundings?.funding_information
                ?.investment_vehicle?.[0]?.currency || "INR"
            );
            setMinAmountCapTab(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_amount_cap_amount_tab &&
                response?.data?.data?.applyforfundings?.funding_information
                  ?.min_amount_cap_amount_tab !== "undefined"
                ? response?.data?.data?.applyforfundings?.funding_information
                    ?.min_amount_cap_amount_tab
                : ""
            );
            setMinAmountCapTabUsd(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_amount_cap_amount_tab_usd &&
                response?.data?.data?.applyforfundings?.funding_information
                  ?.min_amount_cap_amount_tab_usd !== "undefined"
                ? response?.data?.data?.applyforfundings?.funding_information
                    ?.min_amount_cap_amount_tab_usd
                : ""
            );
            // setMinAmountMultiCurrency(
            //   response?.data?.data?.applyforfundings?.funding_information
            //     ?.min_amount_multiCurrency &&
            //     response?.data?.data?.applyforfundings?.funding_information
            //       ?.min_amount_multiCurrency !== "undefined"
            //     ? response?.data?.data?.applyforfundings?.funding_information
            //         ?.min_amount_multiCurrency
            //     : {}
            // );
            setMinConsentDrawdown(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_drawdown_consent &&
                response?.data?.data?.applyforfundings?.funding_information
                  ?.min_drawdown_consent !== "undefined"
                ? response?.data?.data?.applyforfundings?.funding_information
                    ?.min_drawdown_consent
                : ""
            );
            setMinConsentDrawdownUsd(
              response?.data?.data?.applyforfundings?.funding_information
                ?.min_consent_for_drawdown_usd &&
                response?.data?.data?.applyforfundings?.funding_information
                  ?.min_consent_for_drawdown_usd !== "undefined"
                ? response?.data?.data?.applyforfundings?.funding_information
                    ?.min_consent_for_drawdown_usd
                : ""
            );
            // setMinAmountSynidcateMultiCurrency(
            //   response?.data?.data?.applyforfundings?.funding_information
            //     ?.min_amount_syndicate_multiCurrency &&
            //     response?.data?.data?.applyforfundings?.funding_information
            //       ?.min_amount_syndicate_multiCurrency !== "undefined"
            //     ? response?.data?.data?.applyforfundings?.funding_information
            //         ?.min_amount_syndicate_multiCurrency
            //     : {}
            // );
            // setMinAmountCapTabMultiCurrency(
            //   response?.data?.data?.applyforfundings?.funding_information
            //     ?.min_amount_cap_amount_tab_multiCurrency &&
            //     response?.data?.data?.applyforfundings?.funding_information
            //       ?.min_amount_cap_amount_tab_multiCurrency !== "undefined"
            //     ? response?.data?.data?.applyforfundings?.funding_information
            //         ?.min_amount_cap_amount_tab_multiCurrency
            //     : {}
            // );
            setExistingCommmitment(
              response?.data?.data?.applyforfundings?.funding_information
                ?.existing_commitments
            );
            setTransactionFeesForSyndicate(
              response?.data?.data?.applyforfundings?.funding_information
                ?.transaction_fees
            );

            setExit_documentation_fees_captab(
              response?.data?.data?.applyforfundings?.funding_information
                ?.exit_documentation_fees_captab
            );
            setTransactionFeesForCapTab(
              response?.data?.data?.applyforfundings?.funding_information
                ?.transaction_fees_cap_table &&
                response?.data?.data?.applyforfundings?.funding_information
                  ?.transaction_fees_cap_table !== "undefined"
                ? response?.data?.data?.applyforfundings?.funding_information
                    ?.transaction_fees_cap_table
                : ""
            );
            setCarryOnCapitalGains(
              response?.data?.data?.applyforfundings?.funding_information
                ?.carry_on_capital_gains
            );
          }
          setshowButton(false);

          // if (current_status !== "" && current_status !== "Approved" && current_status !== "Declined" && current_status !== "Waitlisted") {
          //     await onUpdateStatus({ id: id, status: "In-Process/Reviewing" })
          // }
        }
      })
      .catch(error => {});
  };
  useEffect(() => {
    fundingDealStatusList.map((value, key) => {
      if (
        value.value == appliedFundingDeal?.current_status ||
        value.label == appliedFundingDeal?.current_status
      ) {
        setDealStatus(value.label);
        setcopyDealStatus(value.label);
      }
    });
    return () => {};
  }, [fundingDealStatusList, appliedFunding]);

  useEffect(() => {
    if (!capTableRadio && appliedFundingDeal) {
      handleMinAmountCapTab({
        target: { value: 0 },
      });
      handleMinAmountCapTabUsd({
        target: { value: 0 },
      });
      handleTransactionFeesForCapTab({
        target: { value: 0 },
      });
      handleExitDocumentationFeesForCapTab({
        target: { value: 0 },
      });
    }
  }, [capTableRadio]);

  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (currencies?.length == 0) {
      getCurrenciesList();
    }
    return () => {};
  }, []);

  const getCurrenciesList = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/get-all-currencies`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        let currency = res.data.currencies;
        setCurrencies(currency);
      })
      .catch(err => {});
  };
  const handleStatusChange = e => {
    setDealStatus(e.target.value);
  };
  const handleDealManagerChange = e => {
    setDealmanagerId(e.target.value);
  };
  const handleInvestmentVehicleChange = e => {
    let selectedInvestmentVehicles = [];
    e.map(val => selectedInvestmentVehicles.push(val.value));
    setSelectedInvestmentVehicle(selectedInvestmentVehicles);
  };
  const handleSelectedStatus = e => {
    setDealStatus(e.target.value);
  };
  const handlecopySelectedStatus = e => {
    setcopyDealStatus(e.target.value);
  };
  const handleSelectedPlatform = e => {
    setPlatform(e.target.value);
  };
  const handleSelectedDealType = e => {
    setErrors({});
    if (e.value === "Equity Shares") {
      setValuationCap("");
      setValuationFloor("");
    }
    setDealType(e.value);
  };

  const handleSelectedTermSheetStatus = e => {
    setErrors({});
    setSelectedTermSheetStatus(e.value);
  };

  const handleTermsheetUpload = e => {
    setErrors({});
    const file = e.target.files[0];
    if (file) {
      setTermSheetFile(file);
    } else {
      setErrors(prevState => ({
        ...prevState,
        term_sheet_file: "Please upload termsheet file.",
      }));
    }
  };

  const handleSyndicate = e => {
    // setSelectedSydicate(obj)
    setSelectSaas(e.target.value);
  };
  const handleSelectedRoundType = e => {
    setRound(e.value);
  };
  const handleSelectedValuationType = e => {
    setValuationType(e.value);
  };

  const handleFundSource = e => {
    setFundSource(e.target.value);
  };
  const handleCommentChange = e => {
    setComment(e.target.value);
  };
  const handleTotalInvestor = e => {
    setTotalInvestor(Math.abs(e.target.value));
  };
  const handleFundHistory = e => {
    setPreviousFundingHistory(Math.abs(e.target.value));
  };
  const handleRemarks = e => {
    setRemarks(e.target.value);
  };
  const handleMonth = e => {
    setMonth(e.target.value);
  };
  const handleYears = e => {
    setYears(e.target.value);
  };

  const handleDiscount = e => {
    setDiscount(e.target.value);
  };
  const addedExistingCommitmentData = () => {
    // let error={};
    // if(existingCommmitment?.length>0){
    //   if(!existingCommmitment[existingCommmitment?.length-1]?.investorName){
    //     error={...error,investorName:"Please enter investor name"}
    //   }
    //   if(!existingCommmitment[existingCommmitment?.length-1]?.committedAmount){
    //     error={...error,committedAmount:"Please enter committed amount"}
    //   }
    //   if(Object.keys(error)?.length)
    //     {
    //       setExistingCommitmentErrors(error)
    //       return
    //     }else{
    //       setExistingCommitmentErrors(error)
    //     }

    // }else{
    //   setExistingCommitmentErrors({})
    // }
    let existingdata = existingCommmitment;
    existingdata.push({
      investorName: "",
      committedAmount: 0,
      isLeadInvestor: "",
      comment: "",
    });
    setErrors({
      ...errors,
      existingCommmitment: "",
    });
    setExistingCommmitment([...existingdata]);
  };
  const handleExistingCommmitment = (val, name, index) => {
    let existingdata = existingCommmitment[index];
    existingCommmitment[index][name] = val;
    setExistingCommmitment([...existingCommmitment]);
  };
  const handleDeleteCommitment = index => {
    let existingdata = existingCommmitment;
    if (existingCommmitment?.length === 1) {
      setErrors({
        ...errors,
        existingCommmitment: "Cannot Remove Last Entry.",
      });
      return;
    }
    setErrors({
      ...errors,
      existingCommmitment: "",
    });
    if (index !== -1) {
      existingdata.splice(index, 1);
      setExistingCommmitment([...existingdata]);
    }
    // let error={}
    // if(existingCommmitment?.length>0){
    //   if(!existingCommmitment[existingCommmitment?.length-1]?.investorName){
    //     error={...error,investorName:"Please enter investor name"}
    //   }
    //   if(!existingCommmitment[existingCommmitment?.length-1]?.committedAmount){
    //     error={...error,committedAmount:"Please enter committed amount"}
    //   }
    //   if(Object.keys(error)?.length)
    //     {
    //       setExistingCommitmentErrors(error)
    //       return
    //     }else{
    //       setExistingCommitmentErrors(error)
    //     }

    // }else{
    //   setExistingCommitmentErrors({})
    // }
  };

  const handleRound = e => {
    setRound(e.target.value);
  };

  const handleTarget = e => {
    setFundAskTarget(Math.abs(e.target.value));
  };

  const handleMinAmount = e => {
    setMinAmount(Math.abs(e.target.value));
    setMinAmountUsd(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["INR"],
          CURRENCY_MAP?.["USD"]
        )
      )
    );
  };

  const handleMinAmountUsd = e => {
    setMinAmountUsd(Math.abs(e.target.value));
    setMinAmount(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["USD"],
          CURRENCY_MAP?.["INR"]
        )
      )
    );
  };

  const [minAmountMultiCurrency, setMinAmountMultiCurrency] = useState({});
  const [minAmountSyndicateMultiCurrency, setMinAmountSynidcateMultiCurrency] =
    useState({});
  const [minAmountCapTabMultiCurrency, setMinAmountCapTabMultiCurrency] =
    useState({});
  const [minConsentDrawdown, setMinConsentDrawdown] = useState({});
  const [minConsentDrawdownUsd, setMinConsentDrawdownUsd] = useState("");

  const handleMinAmountMultiCurrency = e => {
    setMinAmountMultiCurrency({
      ...minAmountMultiCurrency,
      [e.target.name]: Math.abs(e.target.value),
    });
  };

  const handleMinAmountSyndicate = e => {
    setMinAmountSyndicate(Math.abs(e.target.value));
    setMinAmountSyndicateUsd(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["INR"],
          CURRENCY_MAP?.["USD"]
        )
      )
    );
  };

  const handleMinAmountSyndicateUsd = e => {
    setMinAmountSyndicateUsd(Math.abs(e.target.value));
    setMinAmountSyndicate(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["USD"],
          CURRENCY_MAP?.["INR"]
        )
      )
    );
  };

  const handleMinAmountSyndicateMultiCurrency = e => {
    setMinAmountSynidcateMultiCurrency({
      ...minAmountSyndicateMultiCurrency,
      [e.target.name]: Math.abs(e.target.value),
    });
  };

  const handleMinConsentForDrawdown = e => {
    setMinConsentDrawdown(Math.abs(e.target.value));
    setMinConsentDrawdownUsd(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["INR"],
          CURRENCY_MAP?.["USD"]
        )
      )
    );
  };

  const handleMinConsentForDrawdownUsd = e => {
    setMinConsentDrawdownUsd(Math.abs(e.target.value));
    setMinConsentDrawdown(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["USD"],
          CURRENCY_MAP?.["INR"]
        )
      )
    );
  };

  const handleMinAmountCapTab = e => {
    setMinAmountCapTab(e.target.value);
    setMinAmountCapTabUsd(
      parseInt(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["INR"],
          CURRENCY_MAP?.["USD"]
        )
      )
    );
  };

  const handleMinAmountCapTabUsd = e => {
    setMinAmountCapTabUsd(e.target.value);
    setMinAmountCapTab(
      parseInt(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["USD"],
          CURRENCY_MAP?.["INR"]
        )
      )
    );
  };

  const handleMinAmountCapTabMultiCurrency = e => {
    setMinAmountCapTabMultiCurrency({
      ...minAmountCapTabMultiCurrency,
      [e.target.name]: e.target.value,
    });
  };

  const handleTransactionFeesForSyndicate = e => {
    const { id, value } = e.target;
    setTransactionFeesForSyndicateNew({
      // ...transactionFeesForSyndicateNew,
      [id]: Math.abs(value),
    });
  };

  const handleAnnualTransactionFeesForSyndicate = e => {
    const { id, value } = e.target;
    setTransactionFeesAnnual(Math.abs(value));
  };

  const handleTransactionFeesForCapTab = e => {
    setTransactionFeesForCapTab(e.target.value);
  };
  const handleExitDocumentationFeesForCapTab = e => {
    setExit_documentation_fees_captab(e.target.value);
  };

  const handleCarryOnCapitalGains = e => {
    const { id, value } = e.target;
    setCarryOnCapitalGainsNew({
      // ...carryOnCapitalGainsNew,
      [id]: Math.abs(value),
    });
  };

  const [schemeName, setSchemeName] = useState("");

  const handleSchemeName = e => {
    setSchemeName(e.target.value);
  };

  const [sharePrice, setSharePrice] = useState("");
  const [sharePriceUSD, setSharePriceUSD] = useState("");

  const handleSharePrice = e => {
    // let data = {};
    // const token = authHeader();

    // let startupCurrency = "INR";
    // if (startupInfo[0]?.currency?.value) {
    //   startupCurrency = startupInfo[0]?.currency?.value;
    // }
    setSharePrice(Math.abs(e.target.value));
    setSharePriceUSD(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["INR"],
          CURRENCY_MAP?.["USD"]
        )
      )
    );

    // // IF YOU SEND EMPTY ARRAY THEN WHOLE OBJECT IS RETURNED
    // // const currenciesData = currencies.filter(c => {
    // //   return c.value != startupCurrency;
    // // });
    // // const currenciesArray = currenciesData.map(d => {
    // //   return d.value;
    // // });
    // data.baseCurrency = startupCurrency;
    // data.targetCurrencies = [];
    // // data.targetCurrencies = currenciesArray;
    // data.value = parseFloat(Math.abs(e.target.value));
    // data.date = new Date();

    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/convert-currencies`,
    //     data,
    //     { headers: { Authorization: `${token}` } }
    //   )
    //   .then(res => {
    //     let convertedValues = res.data.convertedValues;
    //     console.log("convertedValues:", convertedValues);

    //     setSharePrice({
    //       ...convertedValues,
    //     });
    //   });
  };

  const handleSharePriceUSD = e => {
    setSharePriceUSD(Math.abs(e.target.value));
    setSharePrice(
      Math.abs(
        exchangeCurrencyRates(
          Math.abs(e.target.value),
          CURRENCY_MAP?.["USD"],
          CURRENCY_MAP?.["INR"]
        )
      )
    );
  };

  const handleWhatIsYourValuation = e => {
    setWhatIsYourValuation(e.target.value);
  };
  const handleValuationFloor = e => {
    setValuationFloor(e.target.value);
  };
  const handleValuationCap = e => {
    setValuationCap(e.target.value);
  };

  const zoomInvestorsIntentUpdate = async () => {
    setZoomInvestorUpdateLoad(true);
    await sendZoomInvestorIntentUpdate(match.params.id);
    setTimeout(async () => {
      setZoomInvestorUpdateLoad(false);
    }, 1500);
  };

  const sendConsetFormToRelatedInvestor = async () => {
    setConsetSendLoad(true);
    await sendConsentToInvestor(match.params.id);
    setTimeout(async () => {
      setConsetSendLoad(false);
    }, 1500);
    setAppliedFundingDeal(prevState => ({
      ...prevState,
      consent_button_send: "yes",
    }));
  };

  const handleHowMuchRaising = e => {
    setHowMuchRaising(Math.abs(e.target.value));
  };

  const updateTermSheetApi = async e => {
    // e.preventDefault();
    setupdateTermSheet(true);
    let old_termsheet_file =
      appliedFundingDeal?.term_sheet_file !== null &&
      appliedFundingDeal?.term_sheet_file !== undefined
        ? appliedFundingDeal?.term_sheet_file
        : "";
    let old_termsheet_status = appliedFundingDeal?.term_sheet_status;

    if (
      selectedTermSheetStatus === old_termsheet_status &&
      old_termsheet_file === termSheetFile
    ) {
      setupdateTermSheet(false);
      return false;
    }
    let loggedInUserData = JSON.parse(localStorage.getItem("user_info"));
    let userEmail = loggedInUserData?.email;
    let userId = loggedInUserData?.user_id;
    let userName =
      loggedInUserData?.firstname + " " + loggedInUserData?.lastname;
    let deviceInfo = getDeviceDetails(navigator.userAgent);
    let id = match.params.id;
    try {
      let group = {
        _id: id,
        term_sheet_status: selectedTermSheetStatus,
        term_sheet_file: termSheetFile,
        userLocation: getLocationString(
          geolocationData?.city,
          geolocationData?.country_name
        ),
        userIP: geolocationData["IPv4"],
        userBrowser: deviceInfo.browser,
        userOS: deviceInfo.os,
        userDevice: checkDeviceType(navigator),
        userEmail,
        userId,
        userName,
        funding_deal_id: appliedFundingDealId,
      };
      let data = await updateTermSheetStatus(group);
      if (data.status == false || !data) {
        setErrors({ message: data.message, errortype: "TermSheet" });
      } else {
        // toast.success("Term Sheet Status updated successfully.");
        setErrors({});
      }
    } catch (error) {
      setErrors(error.message);
    }
    setupdateTermSheet(false);
  };

  const updateFundingDealStatus = async () => {
    setUpdateClicked(true);
    let data = { id: match.params.id };
    if (dealManagerId) {
      data.manager = dealManagerId;
    }
    if (sharePrice) {
      data.sharePrice = sharePrice;
    }
    if (sharePriceUSD) {
      data.sharePriceUSD = sharePriceUSD;
    }
    if (comment) {
      data.reason = comment;
    }
    // setDealStatus
    if (dealStatus) {
      data.status = dealStatus;
    }
    // let error={};
    // if(existingCommmitment?.length>0){
    //   if(!existingCommmitment[existingCommmitment?.length-1]?.investorName){
    //     error={...error,investorName:"Please enter investor name"}
    //   }
    //   if(!existingCommmitment[existingCommmitment?.length-1]?.committedAmount){
    //     error={...error,committedAmount:"Please enter committed amount"}
    //   }
    //   if(Object.keys(error)?.length)
    //     {
    //       setExistingCommitmentErrors(error)
    //       setUpdateClicked(false)
    //       return
    //     }else{
    //       setExistingCommitmentErrors(error)
    //     }

    // }else{
    //   setExistingCommitmentErrors({})
    // }
    let err = {};

    if (!removeNullUndefined(dealType)) {
      err = {
        ...err,
        dealType: "Instrument Type is required",
      };
    }

    if (!removeNullUndefined(round)) {
      err = {
        ...err,
        round: "Round Name is required",
      };
    }

    if (
      (!whatIsYourValuation || parseInt(whatIsYourValuation) === 0) &&
      valAmountRadio
    ) {
      err = {
        ...err,
        whatIsYourValuation: "Please enter valuation amount",
      };
    }
    if (
      (!valuationCap ||
        parseInt(valuationCap) === 0 ||
        !valuationFloor ||
        parseInt(valuationFloor) === 0) &&
      !valAmountRadio
    ) {
      err = {
        ...err,
        whatIsYourValuation: "Please enter valuation floor-cap amount",
      };
    }

    if (
      isEmpty(pan_number) &&
      appliedFundingDeal?.current_status !== "Deal Live" &&
      dealStatus === "DealLive" && selectedInvestmentVehicle?.[0]?.value === "Invstt Trust"
    ) {
      err = {
        ...err,
        pan_number: "PAN Number is required for making a Deal Live.",
      };
    }

    let regPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!isEmpty(pan_number) && !regPan.test(pan_number)) {
      err = {
        ...err,
        pan_number: "Please enter a valid Pan Number",
      };
    }

    // if (dealType === "Equity Shares") {
    //   if (!whatIsYourValuation || parseInt(whatIsYourValuation) === 0) {
    //     err = { ...err, whatIsYourValuation: "Please enter your valuation" };
    //   }
    // } else {
    //   if (!whatIsYourValuation || parseInt(whatIsYourValuation) === 0) {
    //     if (
    //       (!valuationCap || parseInt(valuationCap) === 0) &&
    //       (!valuationFloor || parseInt(valuationFloor) === 0)
    //     ) {
    //       err = {
    //         ...err,
    //         valuationCap:
    //           "Please enter either valuation or valuation floor-cap",
    //         valuationFloor:
    //           "Please enter either valuation or valuation floor-cap",
    //         whatIsYourValuation:
    //           "Please enter either valuation or valuation floor-cap",
    //       };
    //     }
    //     //  else if (parseInt(valuationCap) <= parseInt(valuationFloor)) {
    //     //   err = {
    //     //     ...err,
    //     //     valuationCap: "Please enter valuation floor < cap",
    //     //     valuationFloor: "Please enter valuation floor < cap",
    //     //   };
    //     // }
    //   }
    //   if (
    //     whatIsYourValuation &&
    //     parseInt(whatIsYourValuation) !== 0 &&
    //     ((valuationCap && parseInt(valuationCap) !== 0) ||
    //       (valuationFloor && parseInt(valuationFloor) !== 0))
    //   ) {
    //     err = {
    //       ...err,
    //       valuationCap: "Please remove either valuation or valuation floor-cap",
    //       valuationFloor:
    //         "Please remove either valuation or valuation floor-cap",
    //       whatIsYourValuation:
    //         "Please remove either valuation or valuation floor-cap",
    //     };
    //   }
    // }
    if (
      howMuchRaising &&
      fundAskTarget &&
      parseInt(howMuchRaising) > parseInt(fundAskTarget)
    ) {
      err = {
        ...err,
        howMuchRaising:
          "Allocation for Domain expert can't be more than Round Size",
      };
    }

    if (
      !valuationCap &&
      !valuationFloor &&
      whatIsYourValuation &&
      parseInt(whatIsYourValuation) < parseInt(fundAskTarget)
    ) {
      err = {
        ...err,
        fundAskTarget: "Round Size can't be more than the Valuation",
      };
    }
    if (
      !valuationCap &&
      !valuationFloor &&
      whatIsYourValuation &&
      parseInt(whatIsYourValuation) < parseInt(howMuchRaising)
    ) {
      err = {
        ...err,
        howMuchRaising:
          "Allocation for Domain expert can't be more than the Valuation",
      };
    }
    if (
      selectedInvestmentVehicle?.[0]?.currency === "INR" &&
      (!minAmount || minAmount === undefined || minAmount === "undefined")
    ) {
      err = { ...err, minAmount: "Minimum intent amount is required" };
    } else if (
      selectedInvestmentVehicle?.[0]?.currency === "INR" &&
      parseInt(minAmount) < 100000
    ) {
      err = {
        ...err,
        minAmount:
          "Minimum intent amount must be more than or equals to 1,00,000",
      };
    }
    if (
      selectedInvestmentVehicle?.[0]?.currency === "USD" &&
      (!minAmountUsd ||
        minAmountUsd === undefined ||
        minAmountUsd === "undefined")
    ) {
      err = { ...err, minAmount: "Minimum intent amount is required" };
    } else if (
      selectedInvestmentVehicle?.[0]?.currency === "USD" &&
      parseInt(minAmountUsd) < 1250
    ) {
      err = {
        ...err,
        minAmount:
          "Minimum intent amount must be more than or equals to 1,250.",
      };
    }
    if (
      !minAmountUsd ||
      minAmountUsd === "" ||
      minAmountUsd === undefined ||
      minAmountUsd === "undefined"
    ) {
      err = { ...err, minAmountUsd: "Minimum intent amount is required" };
    }

    // if (
    //   minAmountSyndicate &&
    //   parseInt(minAmountSyndicate) > 0 &&
    //   (!minAmountSyndicateUsd ||
    //     minAmountSyndicateUsd === "" ||
    //     minAmountSyndicateUsd === undefined ||
    //     minAmountSyndicateUsd === "undefined")
    // ) {
    //   err = {
    //     ...err,
    //     minAmountSyndicateUsd: "Minimum amount for syndicate is required",
    //   };
    // }
    // if (
    //   minAmountSyndicateUsd &&
    //   parseInt(minAmountSyndicateUsd) > 0 &&
    //   (!minAmountSyndicate ||
    //     minAmountSyndicate === "" ||
    //     minAmountSyndicate === undefined ||
    //     minAmountSyndicate === "undefined") &&
    //   (selectedInvestmentVehicle !== "WFC Global Angels Fund" ||
    //     selectedInvestmentVehicle === "" ||
    //     !selectedInvestmentVehicle)
    // ) {
    //   err = {
    //     ...err,
    //     minAmountSyndicate: "Minimum  amount for syndicate is required",
    //   };
    // } else if (
    //   (selectedInvestmentVehicle !== "WFC Global Angels Fund" ||
    //     selectedInvestmentVehicle === "" ||
    //     !selectedInvestmentVehicle) &&
    //   parseInt(minAmountSyndicate) < 100000
    // ) {
    //   err = {
    //     ...err,
    //     minAmountSyndicate:
    //       "Minimum Syndicate amount must be more than or equals to 1,00,000",
    //   };
    // }
    if (
      (parseInt(minConsentDrawdown) === 0 || minConsentDrawdown === "") &&
      selectedInvestmentVehicle?.[0]?.currency === "INR"
    ) {
      err = {
        ...err,
        minConsentDrawdown: "Minimum consent for drawdown is required",
      };
    }

    if (
      (parseInt(minConsentDrawdownUsd) === 0 || minConsentDrawdownUsd === "") &&
      selectedInvestmentVehicle?.[0]?.currency === "USD"
    ) {
      err = {
        ...err,
        minConsentDrawdown: "Minimum consent for drawdown is required",
      };
    }

    if (
      !minAmountUsd ||
      minAmountUsd === undefined ||
      minAmountUsd === "undefined"
    ) {
      err = { ...err, minAmountUsd: "Minimum intent amount is required" };
    }
    // else if (parseInt(minAmountUsd) < 50000) {
    //   err = {
    //     ...err,
    //     minAmountUsd:
    //       "Minimum intent amount must be more then or equals to 50,000",
    //   };
    // }

    if (
      !schemeName ||
      schemeName === undefined ||
      schemeName === "undefined" ||
      schemeName === ""
    ) {
      err = { ...err, schemeName: "Scheme Name is required" };
    }
    if (
      !dealManagerId ||
      dealManagerId === undefined ||
      dealManagerId === "undefined" ||
      dealManagerId === ""
    ) {
      err = { ...err, dealManagerId: "Please select Deal Manager" };
    }

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value !== "INR") {
    //     if (
    //       !minAmountMultiCurrency[currencyName] ||
    //       minAmountMultiCurrency[currencyName] === undefined ||
    //       minAmountMultiCurrency[currencyName] === "undefined"
    //     ) {
    //       err = {
    //         ...err,
    //         minAmountMultiCurrency: {
    //           ...(err?.["minAmountMultiCurrency"] || {}),
    //           [currencyName]: "Minimum intent amount is required",
    //         },
    //       };
    //     }
    //     // else if (parseInt(minAmountMultiCurrency[currencyName]) < 50000) {
    //     //   err = {
    //     //     ...err,
    //     //     minAmountMultiCurrency: {
    //     //       ...(err?.["minAmountMultiCurrency"] || {}),
    //     //       [currencyName]:
    //     //         "Minimum intent amount must be more then or equals to 50,000",
    //     //     },
    //     //   };
    //     // }
    //   }
    // });

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (
    //     !minConsentDrawdown[currencyName] ||
    //     minConsentDrawdown[currencyName] === undefined ||
    //     minConsentDrawdown[currencyName] === "undefined"
    //   ) {
    //     err = {
    //       ...err,
    //       minConsentDrawdown: {
    //         ...(err?.["minConsentDrawdown"] || {}),
    //         [currencyName]: "Minimum consent for drawdown amount is required",
    //       },
    //     };
    //   }
    //   // else if (parseInt(minConsentDrawdown[currencyName]) < 50000) {
    //   //   err = {
    //   //     ...err,
    //   //     minConsentDrawdown: {
    //   //       ...(err?.["minConsentDrawdown"] || {}),
    //   //       [currencyName]:
    //   //         "Minimum consent for drawdown amount must be more then or equals to 50,000",
    //   //     },
    //   //   };
    //   // }
    // });
    if (capTableRadio) {
      if (
        minAmountCapTab &&
        parseInt(minAmountCapTab) > 0 &&
        (!minAmountCapTabUsd ||
          minAmountCapTabUsd === "" ||
          minAmountCapTabUsd === undefined ||
          minAmountCapTabUsd === "undefined")
      ) {
        err = {
          ...err,
          minAmountCapTabUsd: "Please enter min cap tab",
        };
      }

      if (
        minAmountCapTabUsd &&
        parseInt(minAmountCapTabUsd) > 0 &&
        (!minAmountCapTab ||
          minAmountCapTab === "" ||
          minAmountCapTab === undefined ||
          minAmountCapTab === "undefined") &&
        (selectedInvestmentVehicle !== "WFC Global Angels Fund" ||
          selectedInvestmentVehicle === "" ||
          !selectedInvestmentVehicle)
      ) {
        err = {
          ...err,
          minAmountCapTab: "Please enter min cap tab",
        };
      } else if (parseInt(minAmountCapTab) < 100000) {
        err = {
          ...err,
          minAmountCapTab:
            "Minimum Cap Tab amount must be more than or equals to 1,00,000",
        };
      }
    }

    // if (
    //   !minAmountCapTab ||
    //   minAmountCapTab === undefined ||
    //   minAmountCapTab === "undefined"
    // ) {
    //   err = { ...err, minAmountCapTab: "Please enter min cap tab" };
    // }
    if (!RegExp(/^[0-9]*$/g).test(minAmountCapTab)) {
      err = { ...err, minAmountCapTab: "Please enter valid min cap tab" };
    }
    // if (minAmountCapTab == "") {
    //   err = { ...err, minAmountCapTab: "Please enter min cap tab" };
    // }

    // if (
    //   !minAmountCapTabUsd ||
    //   minAmountCapTabUsd === undefined ||
    //   minAmountCapTabUsd === "undefined"
    // ) {
    //   err = { ...err, minAmountCapTabUsd: "Please enter min cap tab" };
    // }
    if (!RegExp(/^[0-9]*$/g).test(minAmountCapTabUsd)) {
      err = { ...err, minAmountCapTabUsd: "Please enter valid min cap tab" };
    }
    // if (minAmountCapTabUsd == "") {
    //   err = { ...err, minAmountCapTabUsd: "Please enter min cap tab" };
    // }

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value != "INR") {
    //     if (
    //       !RegExp(/^[0-9]*$/g).test(minAmountCapTabMultiCurrency[currencyName])
    //     ) {
    //       err = {
    //         ...err,
    //         minAmountCapTabMultiCurrency: {
    //           ...(err?.["minAmountCapTabMultiCurrency"] || {}),
    //           [currencyName]: "Please enter valid min cap tab",
    //         },
    //       };
    //     }
    //   }
    // });

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value != "INR") {
    //     if (
    //       // !minAmountCapTabMultiCurrency[currencyName] ||
    //       // minAmountCapTabMultiCurrency[currencyName] === undefined ||
    //       minAmountCapTabMultiCurrency[currencyName] == ""
    //     ) {
    //       err = {
    //         ...err,
    //         minAmountCapTabMultiCurrency: {
    //           ...(err?.["minAmountCapTabMultiCurrency"] || {}),
    //           [currencyName]: "Please enter min cap tab",
    //         },
    //       };
    //     }
    //   }
    // });

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value != "INR") {
    //     if (
    //       (minAmountSyndicate && parseInt(minAmountSyndicate) > 0) ||
    //       minAmountSyndicateMultiCurrency[currencyName]
    //       // &&(parseInt(minAmountSyndicateMultiCurrency) > 0)
    //     ) {
    //       if (
    //         (minAmountSyndicate && parseInt(minAmountSyndicate) > 0) ||
    //         (minAmountSyndicateMultiCurrency[currencyName] &&
    //           parseInt(minAmountSyndicateMultiCurrency[currencyName]) > 0)
    //       ) {
    //         if (
    //           !transactionFeesForSyndicate ||
    //           parseInt(transactionFeesForSyndicate) == 0 ||
    //           transactionFeesForSyndicate === undefined ||
    //           transactionFeesForSyndicate === "undefined"
    //         ) {
    //           err = {
    //             ...err,
    //             transactionFeesForSyndicate:
    //               "Please enter transaction fees for syndicate",
    //           };
    //         }

    //         currencies.map(e => {
    //           const check = e.value;
    //           if (e.value != "INR") {
    //             if (
    //               !minAmountSyndicateMultiCurrency[check] ||
    //               minAmountSyndicateMultiCurrency[check] === undefined ||
    //               minAmountSyndicateMultiCurrency[check] === "undefined"
    //               // minAmountSyndicateMultiCurrency[currencyName]
    //             ) {
    //               err = {
    //                 ...err,
    //                 minAmountSyndicateMultiCurrency: {
    //                   ...(err?.["minAmountSyndicateMultiCurrency"] || {}),
    //                   [check]: "Please enter min amount for syndicate",
    //                 },
    //               };
    //             }
    //           }
    //         });
    //       }
    //       currencies.map(e => {
    //         const check = e.value;
    //         if (e.value != "INR") {
    //           if (
    //             !minAmountSyndicateMultiCurrency[check] ||
    //             minAmountSyndicateMultiCurrency[check] === undefined ||
    //             minAmountSyndicateMultiCurrency[check] === "undefined"
    //             // minAmountSyndicateMultiCurrency[currencyName]
    //           ) {
    //             err = {
    //               ...err,
    //               minAmountSyndicateMultiCurrency: {
    //                 ...(err?.["minAmountSyndicateMultiCurrency"] || {}),
    //                 [check]: "Please enter min amount for syndicate",
    //               },
    //             };
    //           }
    //         }
    //       });
    //       if (!minAmountSyndicate || minAmountSyndicate === "undefined") {
    //         err = {
    //           ...err,
    //           minAmountSyndicate: "Please enter min amount for syndicate",
    //         };
    //       }
    //     }
    //   }
    // });

    if (!RegExp(/^[0-9]*$/g).test(transactionFeesForCapTab)) {
      err = {
        ...err,
        transactionFeesForCapTab:
          "Please enter valid transaction fees for cap tab",
      };
    }
    // if (transactionFeesForCapTab == "") {
    //   err = {
    //     ...err,
    //     transactionFeesForCapTab: "Please enter transaction fees for cap tab",
    //   };
    // }
    if (parseInt(transactionFeesForCapTab) > 100) {
      err = {
        ...err,
        transactionFeesForCapTab:
          "Transaction fee for cap tab must be less than 100",
      };
    }

    // if(!transactionFeesForCapTab||transactionFeesForCapTab===undefined||transactionFeesForCapTab==='undefined'){
    //   err={...err,transactionFeesForCapTab:"Please enter transaction fees for cap tab"}
    // }
    // if (minAmountSyndicate && parseInt(minAmountSyndicate) > 0) {
    //   if (
    //     !transactionFeesForSyndicate ||
    //     parseInt(transactionFeesForSyndicate) == 0 ||
    //     transactionFeesForSyndicate === undefined ||
    //     transactionFeesForSyndicate === "undefined"
    //   ) {
    //     err = {
    //       ...err,
    //       transactionFeesForSyndicate:
    //         "Please enter transaction fees for syndicate",
    //     };
    //   }
    // }
    // if (minAmountSyndicateUsd && parseInt(minAmountSyndicateUsd) > 0) {
    //   if (
    //     !transactionFeesForSyndicate ||
    //     parseInt(transactionFeesForSyndicate) == 0 ||
    //     transactionFeesForSyndicate === undefined ||
    //     transactionFeesForSyndicate === "undefined"
    //   ) {
    //     err = {
    //       ...err,
    //       transactionFeesForSyndicate:
    //         "Please enter transaction fees for syndicate",
    //     };
    //   }
    // }
    if (
      minAmountSyndicate &&
      minAmountSyndicateUsd &&
      selectedInvestmentVehicle
    ) {
      selectedInvestmentVehicle.map(iv => {
        if (
          parseInt(
            transactionFeesForSyndicateNew[iv.value.replace(/\s+/g, "")]
          ) > 100
        ) {
          err = {
            ...err,
            transactionFeesForSyndicateNew: {
              ...err.transactionFeesForSyndicateNew,
              [iv?.value.replace(/\s+/g, "")]:
                "Management fee for syndicate must be less than 100",
            },
          };
        }
      });
    }
    if (selectedInvestmentVehicle) {
      selectedInvestmentVehicle.map(iv => {
        if (
          transactionFeesForSyndicateNew[iv?.value.replace(/\s+/g, "")] === ""
        ) {
          err = {
            ...err,
            transactionFeesForSyndicateNew: {
              ...err.transactionFeesForSyndicateNew,
              [iv?.value.replace(/\s+/g, "")]:
                "Please enter Management fee for syndicate",
            },
          };
        }
      });
    }

    // else if((!parseInt(minAmountSyndicate)||parseInt(minAmountSyndicate)==0)&&(!parseInt(minAmountCapTab)||parseInt(minAmountCapTab)==0)){
    //   setErrors({minAmountSyndicate:"Please enter either minimum syndicate or min cap tab",minAmountCapTab:"Please enter either minimum syndicate or min cap tab"})
    //   setUpdateClicked(false)
    //   return
    // }
    // else if((parseInt(minAmountSyndicate))&&(parseInt(minAmountCapTab))){
    //   if(parseInt(minAmountSyndicate)){
    //     if(parseInt(minAmountSyndicate)<50000){
    //       setErrors({minAmountSyndicate:"Please enter min amount syndicate greater then or equals to 50,000"})
    //       setUpdateClicked(false)
    //       return
    //    }
    //   }
    //    if(parseInt(minAmountCapTab)){
    //     if(parseInt(minAmountCapTab)<50000){
    //       setErrors({minAmountCapTab:"Please enter min amount cap tab greater then or equals to 50,000"})
    //       setUpdateClicked(false)
    //       return
    //     }
    //   }
    //   if(parseInt(minAmountCapTab)>0&&parseInt(minAmountSyndicate)>0&&parseInt(minAmountCapTab)<=parseInt(minAmountSyndicate)){
    //       setErrors({minAmountCapTab:"Min Cap Tab should be greater than Min Syndicate amount"})
    //       setUpdateClicked(false)
    //       return

    //   }
    //   else{
    //     setErrors({})
    //   }
    // }
    // else if(parseInt(minAmountSyndicate)||parseInt(minAmountCapTab)){
    //   if(parseInt(minAmountSyndicate)){
    //     if(parseInt(minAmountSyndicate)<50000){
    //       setErrors({minAmountSyndicate:"Please enter min amount syndicate greater then or equals to 50,000"})
    //       setUpdateClicked(false)
    //       return
    //    }
    //   }
    //    else if(parseInt(minAmountCapTab)){
    //     if(parseInt(minAmountCapTab)<50000){
    //       setErrors({minAmountCapTab:"Please enter min amount cap tab greater then or equals to 50,000"})
    //       setUpdateClicked(false)
    //       return
    //     }
    //   }else{
    //     setErrors({})
    //   }
    // }
    if (Object.keys(err)?.length) {
      setUpdateClicked(false);
      setErrors(err);
      return;
    } else {
      setErrors({});
    }
    // if (dealStatus === "DealLive") {
    data.howMuchRaising = howMuchRaising;
    data.schemeName = schemeName;
    data.whatIsYourValuation = valAmountRadio ? whatIsYourValuation : "";
    data.round = round;
    data.deal_type = dealType;
    data.valuation_type = valuationType;
    data.fund_ask_target = fundAskTarget;
    data.min_amount = minAmount;
    data.min_amount_usd = minAmountUsd;
    // data.min_amount_multi_currency = JSON.stringify(minAmountMultiCurrency);
    data.min_consent_for_drawdown = minConsentDrawdown;
    if (minConsentDrawdownUsd)
      data.min_consent_for_drawdown_usd = minConsentDrawdownUsd;
    data.min_amount_syndicate = minAmountSyndicate;
    data.transaction_fees_annual = transaction_fees_annual;
    data.capTableRadio = capTableRadio;
    data.valAmountRadio = valAmountRadio;
    data.pan_number = pan_number;
    if (minAmountSyndicateUsd)
      data.min_amount_syndicate_usd = minAmountSyndicateUsd;
    // data.min_amount_syndicate_multi_currency =
    // JSON.stringify(minAmountSyndicateMultiCurrency) || {};
    data.min_amount_cap_amount_tab = minAmountCapTab;
    data.min_amount_cap_amount_tab_usd = minAmountCapTabUsd;
    // data.min_amount_cap_amount_tab_multi_currency = JSON.stringify(
    //   minAmountCapTabMultiCurrency
    // );
    const defaultInvestmentVehicleArray = [
      {
        value: "Invstt Trust",
        label: "Invstt Trust",
        currency: "INR",
      },
    ];
    data.iv_mapped_transaction_fees = JSON.stringify(
      transactionFeesForSyndicateNew
    );
    let deviceInfo = getDeviceDetails(navigator.userAgent);
    // data.transaction_fees = transactionFeesForSyndicate;
    data.transaction_fees_cap_table = transactionFeesForCapTab;
    data.userDevice = checkDeviceType(navigator);
    data.userOS = deviceInfo.os;
    data.userIP = geolocationData["IPv4"];
    data.userBrowser = deviceInfo.browser;
    data.userLocation = getLocationString(
      geolocationData?.city,
      geolocationData?.country_name
    );
    data.iv_mapped_carry_on_capital_gains = JSON.stringify(
      carryOnCapitalGainsNew
    );
    // data.carry_on_capital_gains = carryOnCapitalGains;
    data.deal_open_close = "Open";
    data.exit_documentation_fees_captab = exit_documentation_fees_captab;
    (data.valuation_floor = !valAmountRadio ? valuationFloor : ""),
      // valuationFloor && parseInt(valuationFloor) !== 0 ? valuationFloor : ""),
      (data.valuation_cap = !valAmountRadio ? valuationCap : ""),
      // valuationCap && parseInt(valuationCap) !== 0 ? valuationCap : ""),
      (data.platform = getPlatform),
      (data.funding_source = getFundSource),
      (data.number_of_investors = totalInvestor),
      (data.previous_fund_history = getPreviousFundingHistory),
      (data.discount = getDiscount),
      (data.remarks = getRemarks),
      (data.deal_date = `${getYears}-${getMonth}-01`),
      (data.existingCommmitment = JSON.stringify(existingCommmitment)),
      (data.investment_vehicle = !selectedInvestmentVehicle
        ? JSON.stringify(defaultInvestmentVehicleArray)
        : JSON.stringify(selectedInvestmentVehicle));
    // }
    if (
      [
        "DealRejected",
        "Closed-InternallyFunded",
        "Closed-ExternallyFunded",
      ].includes(dealStatus)
    ) {
      data.deal_open_close = "Close";
    }

    if (getUserRolles?.update_term_sheet_status === true) {
      await updateTermSheetApi();
    }

    let encryptedQuery = encryptDataToArray(data);
    let payload = {
      q: JSON.stringify(encryptedQuery),
      id: match.params.id,
    };

    let res = await onUpdateStatus(payload);

    if (res?.payload) {
      let id = match.params.id;
      setTimeout(async () => {
        await getFundingDealDetails(id);
        toggleUpdateDealStatusBtn();
        setUpdateClicked(false);
      }, 1500);
    }
  };

  const numberWithCommas = (x, locale) => {
    if (x === "") {
      return "";
    }

    let result = parseInt(x || 0).toLocaleString(
      locale
        ? locale
        : selectedInvestmentVehicle?.[0]?.currency === "INR"
        ? "en-IN"
        : "en-US"
    );
    return result;
  };

  const copyFundingDealStatus = async () => {
    setCopyClicked(true);
    let data = { id: match.params.id };
    if (dealManagerId) {
      data.manager = dealManagerId;
    }
    if (sharePrice) {
      data.sharePrice = sharePrice;
    }
    if (sharePriceUSD) {
      data.sharePriceUSD = sharePriceUSD;
    }
    if (comment) {
      data.reason = comment;
    }
    // setDealStatus
    if (copydealStatus) {
      data.status = copydealStatus;
    }

    let err = {};

    if (!removeNullUndefined(dealType)) {
      err = {
        ...err,
        dealType: "Instrument Type is required",
      };
    }

    if (!removeNullUndefined(round)) {
      err = {
        ...err,
        round: "Round Name is required",
      };
    }

    if (
      (!whatIsYourValuation || parseInt(whatIsYourValuation) === 0) &&
      valAmountRadio
    ) {
      err = {
        ...err,
        whatIsYourValuation: "Please enter valuation amount",
      };
    }
    if (
      (!valuationCap ||
        parseInt(valuationCap) === 0 ||
        !valuationFloor ||
        parseInt(valuationFloor) === 0) &&
      !valAmountRadio
    ) {
      err = {
        ...err,
        whatIsYourValuation: "Please enter valuation floor-cap amount",
      };
    }

    if (isEmpty(pan_number) && dealStatus === "DealLive" && selectedInvestmentVehicle?.[0]?.value === "Invstt Trust") {
      err = {
        ...err,
        pan_number: "PAN Number is required for making a Deal Live.",
      };
    }

    let regPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!isEmpty(pan_number) && !regPan.test(pan_number)) {
      err = {
        ...err,
        pan_number: "Please enter a valid Pan Number",
      };
    }

    // if (dealType === "Equity Shares") {
    //   if (!whatIsYourValuation || parseInt(whatIsYourValuation) === 0) {
    //     err = { ...err, whatIsYourValuation: "Please enter your valuation" };
    //   }
    // } else {
    //   if (!whatIsYourValuation || parseInt(whatIsYourValuation) === 0) {
    //     if (
    //       (!valuationCap || parseInt(valuationCap) === 0) &&
    //       (!valuationFloor || parseInt(valuationFloor) === 0)
    //     ) {
    //       err = {
    //         ...err,
    //         // valuationCap:
    //         //   "Please enter either valuation or valuation floor-cap",
    //         // valuationFloor:
    //         //   "Please enter either valuation or valuation floor-cap",
    //         whatIsYourValuation:
    //           "Please enter either valuation or valuation floor-cap",
    //       };
    //     }
    //     // else if (parseInt(valuationCap) <= parseInt(valuationFloor)) {
    //     //   err = {
    //     //     ...err,
    //     //     valuationCap: "Please enter valuation floor < cap",
    //     //     valuationFloor: "Please enter valuation floor < cap",
    //     //   };
    //     // }
    //   }
    //   if (
    //     whatIsYourValuation &&
    //     parseInt(whatIsYourValuation) !== 0 &&
    //     ((valuationCap && parseInt(valuationCap) !== 0) ||
    //       (valuationFloor && parseInt(valuationFloor) !== 0))
    //   ) {
    //     err = {
    //       ...err,
    //       // valuationCap: "Please remove either valuation or valuation floor-cap",
    //       // valuationFloor:
    //       //   "Please remove either valuation or valuation floor-cap",
    //       whatIsYourValuation:
    //         "Please remove either valuation or valuation floor-cap",
    //     };
    //   }
    // }
    // if (minAmountSyndicateUsd && parseInt(minAmountSyndicateUsd) > 0) {
    //   if (
    //     !transactionFeesForSyndicate ||
    //     parseInt(transactionFeesForSyndicate) == 0 ||
    //     transactionFeesForSyndicate === undefined ||
    //     transactionFeesForSyndicate === "undefined"
    //   ) {
    //     err = {
    //       ...err,
    //       transactionFeesForSyndicate:
    //         "Please enter transaction fees for syndicate",
    //     };
    //   }
    // }
    if (capTableRadio) {
      if (
        minAmountCapTab &&
        parseInt(minAmountCapTab) > 0 &&
        (!minAmountCapTabUsd ||
          minAmountCapTabUsd === "" ||
          minAmountCapTabUsd === undefined ||
          minAmountCapTabUsd === "undefined")
      ) {
        err = {
          ...err,
          minAmountCapTabUsd: "Please enter min cap tab",
        };
      }
    }
    if (
      howMuchRaising &&
      fundAskTarget &&
      parseInt(howMuchRaising) > parseInt(fundAskTarget)
    ) {
      err = {
        ...err,
        howMuchRaising:
          "Allocation for Domain expert can't be more than Round Size",
      };
    }
    if (
      !valuationCap &&
      !valuationFloor &&
      whatIsYourValuation &&
      parseInt(whatIsYourValuation) < parseInt(fundAskTarget)
    ) {
      err = {
        ...err,
        fundAskTarget: "Round Size can't be more than the Valuation",
      };
    }
    if (
      !valuationCap &&
      !valuationFloor &&
      whatIsYourValuation &&
      parseInt(whatIsYourValuation) < parseInt(howMuchRaising)
    ) {
      err = {
        ...err,
        howMuchRaising:
          "Allocation for Domain expert can't be more than the Valuation",
      };
    }
    if (capTableRadio) {
      if (
        minAmountCapTabUsd &&
        parseInt(minAmountCapTabUsd) > 0 &&
        (selectedInvestmentVehicle !== "WFC Global Angels Fund" ||
          selectedInvestmentVehicle === "" ||
          !selectedInvestmentVehicle) &&
        (!minAmountCapTab ||
          minAmountCapTab === "" ||
          minAmountCapTab === undefined ||
          minAmountCapTab === "undefined")
      ) {
        err = {
          ...err,
          minAmountCapTab: "Please enter min cap tab",
        };
      }
    }

    // if (
    //   !minAmountCapTabUsd ||
    //   minAmountCapTabUsd === undefined ||
    //   minAmountCapTabUsd === "undefined"
    // ) {
    //   err = { ...err, minAmountCapTabUsd: "Please enter min cap tab" };
    // }
    if (!RegExp(/^[0-9]*$/g).test(minAmountCapTabUsd)) {
      err = { ...err, minAmountCapTabUsd: "Please enter valid min cap tab" };
    }
    // if (minAmountCapTabUsd == "") {
    //   err = { ...err, minAmountCapTabUsd: "Please enter min cap tab" };
    // }

    if (
      !minAmountUsd ||
      minAmountUsd === "" ||
      minAmountUsd === undefined ||
      minAmountUsd === "undefined"
    ) {
      err = { ...err, minAmountUsd: "Minimum intent amount is required" };
    }

    // if (
    //   minAmountSyndicate &&
    //   parseInt(minAmountSyndicate) > 0 &&
    //   (!minAmountSyndicateUsd ||
    //     minAmountSyndicateUsd === "" ||
    //     minAmountSyndicateUsd === undefined ||
    //     minAmountSyndicateUsd === "undefined")
    // ) {
    //   err = {
    //     ...err,
    //     minAmountSyndicateUsd: "Minimum amount for syndicate is required",
    //   };
    // }
    // if (
    //   minAmountSyndicateUsd &&
    //   parseInt(minAmountSyndicateUsd) > 0 &&
    //   (selectedInvestmentVehicle !== "WFC Global Angels Fund" ||
    //     selectedInvestmentVehicle === "" ||
    //     !selectedInvestmentVehicle) &&
    //   (!minAmountSyndicate ||
    //     minAmountSyndicate === "" ||
    //     minAmountSyndicate === undefined ||
    //     minAmountSyndicate === "undefined")
    // ) {
    //   err = {
    //     ...err,
    //     minAmountSyndicate: "Minimum  amount for syndicate is required",
    //   };
    // }

    if (
      (parseInt(minConsentDrawdown) === 0 || minConsentDrawdown === "") &&
      selectedInvestmentVehicle?.[0]?.currency === "INR"
    ) {
      err = {
        ...err,
        minConsentDrawdown: "Minimum consent for drawdown is required",
      };
    }

    if (
      (parseInt(minConsentDrawdownUsd) === 0 || minConsentDrawdownUsd === "") &&
      selectedInvestmentVehicle?.[0]?.currency === "USD"
    ) {
      err = {
        ...err,
        minConsentDrawdown: "Minimum consent for drawdown is required",
      };
    }

    if (
      !minAmountUsd ||
      minAmountUsd === undefined ||
      minAmountUsd === "undefined"
    ) {
      err = { ...err, minAmountUsd: "Minimum intent amount is required" };
    }
    // else if (parseInt(minAmountUsd) < 50000) {
    //   err = {
    //     ...err,
    //     minAmountUsd:
    //       "Minimum intent amount must be more then or equals to 50,000",
    //   };
    // }

    if (
      !selectSaas ||
      selectSaas === undefined ||
      selectSaas === "undefined" ||
      selectSaas === null
    ) {
      err = { ...err, selectSaas: "selecting Syndicate is required" };
    }
    if (
      selectedInvestmentVehicle?.[0]?.currency === "INR" &&
      (!minAmount || minAmount === undefined || minAmount === "undefined")
    ) {
      err = { ...err, minAmount: "Minimum intent amount is required" };
    } else if (
      selectedInvestmentVehicle?.[0]?.currency === "INR" &&
      parseInt(minAmount) < 100000
    ) {
      err = {
        ...err,
        minAmount:
          "Minimum intent amount must be more than or equals to 1,00,000",
      };
    }
    if (
      selectedInvestmentVehicle?.[0]?.currency === "USD" &&
      (!minAmountUsd ||
        minAmountUsd === undefined ||
        minAmountUsd === "undefined")
    ) {
      err = { ...err, minAmount: "Minimum intent amount is required" };
    } else if (
      selectedInvestmentVehicle?.[0]?.currency === "USD" &&
      parseInt(minAmountUsd) < 1250
    ) {
      err = {
        ...err,
        minAmount:
          "Minimum intent amount must be more than or equals to 1,250.",
      };
    }

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value !== "INR") {
    //     if (
    //       !minAmountMultiCurrency[currencyName] ||
    //       minAmountMultiCurrency[currencyName] === undefined ||
    //       minAmountMultiCurrency[currencyName] === "undefined"
    //     ) {
    //       err = {
    //         ...err,
    //         minAmountMultiCurrency: {
    //           ...(err?.["minAmountMultiCurrency"] || {}),
    //           [currencyName]: "Minimum intent amount is required",
    //         },
    //       };
    //     }
    //     // else if (parseInt(minAmountMultiCurrency[currencyName]) < 50000) {
    //     //   err = {
    //     //     ...err,
    //     //     minAmountMultiCurrency: {
    //     //       ...(err?.["minAmountMultiCurrency"] || {}),
    //     //       [currencyName]:
    //     //         "Minimum intent amount must be more then or equals to 50,000",
    //     //     },
    //     //   };
    //     // }
    //   }
    // });

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (
    //     !minConsentDrawdown[currencyName] ||
    //     minConsentDrawdown[currencyName] === undefined ||
    //     minConsentDrawdown[currencyName] === "undefined"
    //   ) {
    //     err = {
    //       ...err,
    //       minConsentDrawdown: {
    //         ...(err?.["minConsentDrawdown"] || {}),
    //         [currencyName]: "Minimum consent for drawdown amount is required",
    //       },
    //     };
    //   }
    //   // else if (parseInt(minConsentDrawdown[currencyName]) < 50000) {
    //   //   err = {
    //   //     ...err,
    //   //     minConsentDrawdown: {
    //   //       ...(err?.["minConsentDrawdown"] || {}),
    //   //       [currencyName]:
    //   //         "Minimum consent for drawdown amount must be more then or equals to 50,000",
    //   //     },
    //   //   };
    //   // }
    // });

    if (
      !schemeName ||
      schemeName === undefined ||
      schemeName === "undefined" ||
      schemeName === ""
    ) {
      err = { ...err, schemeName: "Scheme Name is required" };
    }

    if (!RegExp(/^[0-9]*$/g).test(minAmountCapTab)) {
      err = { ...err, minAmountCapTab: "Please enter valid min cap tab" };
    }
    // if (minAmountCapTab == "") {
    //   err = { ...err, minAmountCapTab: "Please enter min cap tab" };
    // }
    if (
      !dealManagerId ||
      dealManagerId === undefined ||
      dealManagerId === "undefined" ||
      dealManagerId === ""
    ) {
      err = { ...err, dealManagerId: "Please select Deal Manager" };
    }

    if (selectedInvestmentVehicle) {
      selectedInvestmentVehicle.map(iv => {
        if (
          parseInt(
            transactionFeesForSyndicateNew[iv.value.replace(/\s+/g, "")]
          ) > 100
        ) {
          err = {
            ...err,
            transactionFeesForSyndicateNew: {
              ...err.transactionFeesForSyndicateNew,
              [iv?.value.replace(/\s+/g, "")]:
                "Management fees for syndicate must be less than 100",
            },
          };
        }
      });
    }
    if (selectedInvestmentVehicle) {
      selectedInvestmentVehicle.map(iv => {
        if (
          transactionFeesForSyndicateNew[iv?.value.replace(/\s+/g, "")] === ""
        ) {
          err = {
            ...err,
            transactionFeesForSyndicateNew: {
              ...err.transactionFeesForSyndicateNew,
              [iv?.value.replace(/\s+/g, "")]:
                "Please enter Management fee for syndicate",
            },
          };
        }
      });
    }
    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value != "INR") {
    //     if (
    //       !RegExp(/^[0-9]*$/g).test(minAmountCapTabMultiCurrency[currencyName])
    //     ) {
    //       err = {
    //         ...err,
    //         minAmountCapTabMultiCurrency: {
    //           ...(err?.["minAmountCapTabMultiCurrency"] || {}),
    //           [currencyName]: "Please enter valid min cap tab",
    //         },
    //       };
    //     }
    //   }
    // });

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value != "INR") {
    //     if (
    //       // !minAmountCapTabMultiCurrency[currencyName] ||
    //       // minAmountCapTabMultiCurrency[currencyName] === undefined ||
    //       minAmountCapTabMultiCurrency[currencyName] == ""
    //     ) {
    //       err = {
    //         ...err,
    //         minAmountCapTabMultiCurrency: {
    //           ...(err?.["minAmountCapTabMultiCurrency"] || {}),
    //           [currencyName]: "Please enter min cap tab",
    //         },
    //       };
    //     }
    //   }
    // });

    // currencies.map(c => {
    //   const currencyName = c.value;
    //   if (c.value != "INR") {
    //     if (
    //       (minAmountSyndicate && parseInt(minAmountSyndicate) > 0) ||
    //       minAmountSyndicateMultiCurrency[currencyName]
    //       // &&(parseInt(minAmountSyndicateMultiCurrency) > 0)
    //     ) {
    //       if (
    //         (minAmountSyndicate && parseInt(minAmountSyndicate) > 0) ||
    //         (minAmountSyndicateMultiCurrency[currencyName] &&
    //           parseInt(minAmountSyndicateMultiCurrency[currencyName]) > 0)
    //       ) {
    //         if (
    //           !transactionFeesForSyndicate ||
    //           parseInt(transactionFeesForSyndicate) == 0 ||
    //           transactionFeesForSyndicate === undefined ||
    //           transactionFeesForSyndicate === "undefined"
    //         ) {
    //           err = {
    //             ...err,
    //             transactionFeesForSyndicate:
    //               "Please enter transaction fees for syndicate",
    //           };
    //         }

    //         currencies.map(e => {
    //           const check = e.value;
    //           if (e.value != "INR") {
    //             if (
    //               !minAmountSyndicateMultiCurrency[check] ||
    //               minAmountSyndicateMultiCurrency[check] === undefined ||
    //               minAmountSyndicateMultiCurrency[check] === "undefined"
    //               // minAmountSyndicateMultiCurrency[currencyName]
    //             ) {
    //               err = {
    //                 ...err,
    //                 minAmountSyndicateMultiCurrency: {
    //                   ...(err?.["minAmountSyndicateMultiCurrency"] || {}),
    //                   [check]: "Please enter min amount for syndicate",
    //                 },
    //               };
    //             }
    //           }
    //         });
    //       }
    //       currencies.map(e => {
    //         const check = e.value;
    //         if (e.value != "INR") {
    //           if (
    //             !minAmountSyndicateMultiCurrency[check] ||
    //             minAmountSyndicateMultiCurrency[check] === undefined ||
    //             minAmountSyndicateMultiCurrency[check] === "undefined"
    //             // minAmountSyndicateMultiCurrency[currencyName]
    //           ) {
    //             err = {
    //               ...err,
    //               minAmountSyndicateMultiCurrency: {
    //                 ...(err?.["minAmountSyndicateMultiCurrency"] || {}),
    //                 [check]: "Please enter min amount for syndicate",
    //               },
    //             };
    //           }
    //         }
    //       });
    //       if (!minAmountSyndicate || minAmountSyndicate === "undefined") {
    //         err = {
    //           ...err,
    //           minAmountSyndicate: "Please enter min amount for syndicate",
    //         };
    //       }
    //     }
    //   }
    // });

    if (!RegExp(/^[0-9]*$/g).test(transactionFeesForCapTab)) {
      err = {
        ...err,
        transactionFeesForCapTab:
          "Please enter valid transaction fees for cap tab",
      };
    }
    // if (transactionFeesForCapTab == "") {
    //   err = {
    //     ...err,
    //     transactionFeesForCapTab: "Please enter transaction fees for cap tab",
    //   };
    // }
    if (parseInt(transactionFeesForCapTab) > 100) {
      err = {
        ...err,
        transactionFeesForCapTab:
          "Transaction fee for cap tab must be less than 100",
      };
    }

    // if (minAmountSyndicate && parseInt(minAmountSyndicate) > 0) {
    //   if (
    //     !transactionFeesForSyndicate ||
    //     parseInt(transactionFeesForSyndicate) == 0 ||
    //     transactionFeesForSyndicate === undefined ||
    //     transactionFeesForSyndicate === "undefined"
    //   ) {
    //     err = {
    //       ...err,
    //       transactionFeesForSyndicate:
    //         "Please enter transaction fees for syndicate",
    //     };
    //   }
    // }

    if (Object.keys(err)?.length) {
      setCopyClicked(false);
      setErrors(err);
      return;
    } else {
      setErrors({});
    }
    // if (dealStatus === "DealLive") {
    data.saas_parent_id = selectSaas;
    data.howMuchRaising = howMuchRaising;
    data.schemeName = schemeName;
    data.whatIsYourValuation = whatIsYourValuation;
    data.round = round;
    data.deal_type = dealType;
    data.valuation_type = valuationType;
    data.fund_ask_target = fundAskTarget;
    data.min_amount = minAmount;
    data.min_amount_usd = minAmountUsd;
    // data.min_amount_multi_currency = JSON.stringify(minAmountMultiCurrency);
    data.min_consent_for_drawdown = minConsentDrawdown;
    if (minConsentDrawdownUsd)
      data.min_consent_for_drawdown_usd = minConsentDrawdownUsd;
    data.min_amount_syndicate = minAmountSyndicate;
    data.transaction_fees_annual = transaction_fees_annual;
    data.capTableRadio = capTableRadio;
    data.valAmountRadio = valAmountRadio;
    data.pan_number = pan_number;
    if (minAmountSyndicateUsd)
      data.min_amount_syndicate_usd = minAmountSyndicateUsd;
    // data.min_amount_syndicate_multi_currency =
    //   JSON.stringify(minAmountSyndicateMultiCurrency) || {};
    data.min_amount_cap_amount_tab = minAmountCapTab;
    data.transaction_fees_annual = transaction_fees_annual;
    data.capTableRadio = capTableRadio;
    data.valAmountRadio = valAmountRadio;
    data.min_amount_cap_amount_tab_usd = minAmountCapTabUsd;
    // data.min_amount_cap_amount_tab_multi_currency = JSON.stringify(
    //   minAmountCapTabMultiCurrency
    // );
    data.iv_mapped_transaction_fees = JSON.stringify(
      transactionFeesForSyndicateNew
    );
    // data.transaction_fees = transactionFeesForSyndicate;
    data.transaction_fees_cap_table = transactionFeesForCapTab;
    data.iv_mapped_transaction_fees = JSON.stringify(
      transactionFeesForSyndicateNew
    );
    // data.carry_on_capital_gains = carryOnCapitalGains;
    data.deal_open_close = "Open";
    (data.valuation_floor = !valAmountRadio ? valuationFloor : ""),
      // valuationFloor && parseInt(valuationFloor) !== 0 ? valuationFloor : ""),
      (data.valuation_cap = !valAmountRadio ? valuationCap : ""),
      // valuationCap && parseInt(valuationCap) !== 0 ? valuationCap : ""),
      (data.platform = getPlatform),
      (data.funding_source = getFundSource),
      (data.number_of_investors = 0),
      (data.previous_fund_history = getPreviousFundingHistory),
      (data.discount = getDiscount),
      (data.remarks = getRemarks),
      (data.deal_date = `${getYears}-${getMonth}-01`),
      (data.existingCommmitment = JSON.stringify(existingCommmitment)),
      (data.investment_vehicle = !selectedInvestmentVehicle
        ? JSON.stringify([
            { value: "Invstt Trust", label: "Invstt Trust", currency: "INR" },
          ])
        : selectedInvestmentVehicle.map(obj => JSON.stringify(obj)));
    // }
    if (
      [
        "DealRejected",
        "Closed-InternallyFunded",
        "Closed-ExternallyFunded",
      ].includes(dealStatus)
    ) {
      data.deal_open_close = "Close";
    }

    let deviceInfo = getDeviceDetails(navigator.userAgent);
    data.userDevice = checkDeviceType(navigator);
    data.userOS = deviceInfo.os;
    data.userIP = geolocationData["IPv4"];
    data.userBrowser = deviceInfo.browser;
    data.userLocation = getLocationString(
      geolocationData?.city,
      geolocationData?.country_name
    );

    try {
      let encryptedQuery = encryptDataToArray(data);
      let payload = {
        q: JSON.stringify(encryptedQuery),
        id: match.params.id,
      };
      let res = await onCopyDealStatus(payload);

      if (res?.payload) {
        setTimeout(async () => {
          // let id = match.params.id;
          // await getFundingDealDetails(id);
          toggleCopyDealStatusBtn();
          setCopyClicked(false);
        }, 1500);
      }
    } catch (err) {
      // console.log("error", err);
    }
  };

  const handleSaveInvestmentThesis = async () => {
    try {
      if (
        investmentThesis?.thesis?.replace(/(<([^>]+)>)/gi, "")?.length > 1000 &&
        thesisType !== "short"
      ) {
        toast.error(
          "Thesis Character Limit Exceeded. Please reduce it and try again."
        );
        return;
      }
      setInvestmentThesisSaving(true);

      let formData = new FormData();
      let data = {
        investmentThesis: investmentThesis?.thesis,
        thesisType: thesisType,
      };
      let encryptedQuery = encryptDataToArray(data);
      formData.append("q", JSON.stringify(encryptedQuery));

      const token = authHeader();
      let response = await axios({
        url:
          process.env.REACT_APP_API_URL +
          `/api/applyforfunding/investment-thesis/${match?.params?.id}`,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      });

      if (!response?.data?.status) {
        let errorMsg =
          response?.data?.message || "Error while saving investment thesis";
        setInvestmentThesis({
          ...investmentThesis,
          successMsg: null,
          errorMsg: errorMsg,
        });
        setInvestmentThesisSaving(false);

        return;
      }

      let successMsg = response?.data?.message || "Updated Investment Thesis";

      setInvestmentThesis({
        ...investmentThesis,
        successMsg: successMsg,
        errorMsg: null,
      });

      if (thesisType === "short") {
        setAppliedFundingDeal({
          ...appliedFundingDeal,
          investmentThesis: investmentThesis?.thesis,
          investmentThesisModifyDate: new Date(),
        });
      } else {
        setAppliedFundingDeal({
          ...appliedFundingDeal,
          investmentThesisDetailed: {
            description: investmentThesis?.thesis,
            modify_date: new Date(),
          },
        });
      }

      setTimeout(() => {
        setInvestmentThesisSaving(false);
        toggleInvestmentThesisModal({});
      }, 1500);

      return;
    } catch (error) {
      let errorMsg =
        error?.response?.data?.message ||
        error?.message ||
        "Error while saving investment thesis";
      setInvestmentThesis({
        ...investmentThesis,
        successMsg: null,
        errorMsg: errorMsg,
      });
      setInvestmentThesisSaving(false);
    }
  };

  const getCurrencySymbol = val => {
    try {
      let currencySymbol =
        currencies?.find(el => el?.value === val)?.symbol || "";
      if (currencySymbol === "US$") {
        currencySymbol = "$";
      }
      return currencySymbol;
    } catch (err) {
      return "₹";
    }
  };

  const getValueinWords = (value, currency) => {
    if (!value || value === "undefined") {
      value = 0;
    }
    try {
      const toWords = new ToWords({
        localeCode: currency === "USD" ? "en-US" : "en-IN",
      });
      // console.log(toWords)
      let result = toWords.convert(value || 0);
      return (
        `${currency === "USD" ? "Dollars " : "Rupees "}` + result + `  Only`
      );
    } catch (err) {
      return "";
    }
  };

  const getCharacterRemaining = text => {
    if (typeof text !== "string") {
      return 0;
    }
    let charactersLeft = 1000 - text?.replace(/(<([^>]+)>)/gi, "")?.length;
    if (charactersLeft < 1) {
      return 0;
    }
    return charactersLeft;
  };

  const toggleUpdateDealStatusBtn = async () => {
    if (toggleUpdateDealStatus) {
      setErrors({});
    }
    await onUpdateStatusSuccess(false);
    let termsheet_file =
      appliedFundingDeal?.term_sheet_file !== null &&
      appliedFundingDeal?.term_sheet_file !== undefined
        ? appliedFundingDeal?.term_sheet_file
        : "";
    setSelectedTermSheetStatus(appliedFundingDeal?.term_sheet_status || "");
    setTermSheetFile(termsheet_file);
    setToggleUpdateDealStatus(!toggleUpdateDealStatus ? true : false);
  };

  const toggleTermSheetStatusBtn = async () => {
    let termsheet_file =
      appliedFundingDeal?.term_sheet_file !== null &&
      appliedFundingDeal?.term_sheet_file !== undefined
        ? appliedFundingDeal?.term_sheet_file
        : "";
    setSelectedTermSheetStatus(appliedFundingDeal?.term_sheet_status || "");
    setTermSheetFile(termsheet_file);
    setToggleTermSheetStatus(!toggleTermSheetStatus ? true : false);
  };

  const toggleCopyDealStatusBtn = async () => {
    if (!isEmpty(appliedFundingDeal?.copied_from)) {
      alert("Can't Copy! This is a copied Deal!");
      return;
    }
    if (toggleCopyDealStatus) {
      setErrors({});
    }
    setToggleCopyDealStatus(!toggleCopyDealStatus ? true : false);
  };

  const toggle = tab => {
    setActiveTab(tab);
  };

  const get_funding_info = f_name => {
    let value = "";
    if (
      appliedFundingDeal?.funding_information &&
      Object.keys(appliedFundingDeal?.funding_information || {}).includes(
        f_name
      )
    ) {
      value = appliedFundingDeal?.funding_information[f_name];
    }
    return value;
  };

  const convertToVehicleCurrency = number => {
    let vehicleCurrency =
      CURRENCY_MAP?.[
        appliedFundingDeal?.funding_information?.investment_vehicle?.[0]
          ?.currency || "INR"
      ];
    let converted = formatNumberToStringWithCurrencyV2(number, vehicleCurrency);
    return converted;
  };

  const get_existing_commitment_info = items => {
    let fundingInfo = items?.funding_information || {};
    let dataYes = [],
      dataNo = [];
    fundingInfo?.existing_commitments?.map(val => {
      if (val?.isLeadInvestor === "YES") {
        dataYes.push(val);
      } else {
        dataNo.push(val);
      }
    });

    let mainData = [...dataYes, ...dataNo];

    let value = mainData
      ?.map(val => {
        let startupCurrencyConvertedAmount = convertToVehicleCurrency(
          val?.committedAmount
        );

        let retValue = "";

        if (val?.isLeadInvestor === "YES") {
          if (val?.investorName) {
            retValue =
              startupCurrencyConvertedAmount +
              ` [${val?.investorName}, Lead Investor]`;
          } else {
            retValue = startupCurrencyConvertedAmount + " [Lead Investor]";
          }
        } else {
          if (val?.investorName) {
            retValue =
              startupCurrencyConvertedAmount + ` [${val?.investorName}]`;
          } else {
            retValue = startupCurrencyConvertedAmount;
          }
        }

        return retValue ? `${retValue}<br />` : retValue;
      })
      ?.filter(data => data);

    return !!value?.length ? value?.join("") : 0;
  };

  const getMultiCurrencyFieldName = (fieldName, targetCurrency) => {
    if (!fieldName) {
      return fieldName;
    }

    targetCurrency = targetCurrency?.value
      ? targetCurrency
      : startupInfo[0]?.currency?.value;

    if (targetCurrency?.value?.toLowerCase() === "usd") {
      fieldName += "_usd";
    }

    return fieldName;
  };

  const setSaasOptions = userInfo => {
    getAllUsersForReference().then(response => {
      let res = response.data;
      let allSaasDetails = res.filter(d => {
        return d.is_saas_parent == true;
      });
      let WFCsaasParentDetails = allSaasDetails.filter(d => {
        if (d.saas_name == "WFC") return d._id;
      });
      let joinedSyndicates = [];
      if (userInfo?.is_saas_parent == true)
        joinedSyndicates.push(userInfo?._id);
      if (userInfo?.saas_parent_id && userInfo?.saas_parent_id !== null)
        joinedSyndicates.push(userInfo?.saas_parent_id);

      if (userInfo?.other_primary_saas?.length > 0) {
        userInfo?.other_primary_saas?.map(syndicates => {
          if (syndicates.status == "accepted")
            joinedSyndicates.push(syndicates.saas_parent_id);
        });
      }

      if (joinedSyndicates.length == 0)
        joinedSyndicates.push(WFCsaasParentDetails[0]._id);
      let allSaas = allSaasDetails.filter(d => {
        let obj;
        if (joinedSyndicates && joinedSyndicates.includes(d._id)) {
          obj = {
            label: d.saas_name,
            value: d._id,
          };
          return obj;
        }
      });
      let saasOptions = allSaas.map(d => {
        let obj = {
          label: d.saas_name,
          value: d._id,
        };
        return obj;
      });
      setOptionsSaas([
        {
          label: "select",
          value: "undefined",
        },
        ...saasOptions,
      ]);
    });
  };
  const setParticularSyndicateDetails = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/user/getdetail/${id}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      })
      .then(res => {
        setSaasName(res?.data?.user?.saas_name || "");
      });
//     getAllUsersForReferenceEvenDisabled().then(response => {
//       let res = response.data;
//       let allSaas = res.filter(d => {
//         return d.is_saas_parent == true;
//       });

//       const filteredSyndicateDetails = Object.values(allSaas).filter(d => {
//         return d._id == id;
//       });
//       setSaasName(filteredSyndicateDetails[0]?.saas_name);
//     });
  };

  const formatted_kpi_data = kpi_name => {
    let kpi_data = [];
    let kpi_dict = {};

    // make a complete kpi dict
    let fin_kpi = appliedFundingDeal?.business_information?.financial_kpi || {};
    let eng_kpi =
      appliedFundingDeal?.business_information?.engagement_kpi || {};
    let busi_kpi = appliedFundingDeal?.business_information?.business_kpi || [];

    let all_kpis = Object.assign({}, fin_kpi, eng_kpi);
    busi_kpi.map((k, i) => {
      all_kpis[k.name] = k;
    });

    if (Object.keys(all_kpis).includes(kpi_name)) {
      let values = all_kpis[kpi_name].values || [];

      if (all_kpis[kpi_name].values && all_kpis[kpi_name].values.length > 0) {
        let fields = [
          "value_one_month_ago",
          "value_two_month_ago",
          "value_three_month_ago",
          "value_four_month_ago",
          "value_five_month_ago",
          "value_six_month_ago",
        ];
        let standard_data = [],
          canShow = false;

        fields.forEach((f, index) => {
          let now = new Date(),
            month_ago = index + 1,
            value = "-";
          now.setMonth(now.getMonth() - month_ago);
          now.setDate(1);
          let month_ago_date = moment(now).format("YYYY-MM-DD");

          let kpi_month_data = values.filter(
            d => d.kpi_month_date === month_ago_date
          );
          if (
            kpi_month_data &&
            kpi_month_data.length > 0 &&
            kpi_month_data[0].value !== ""
          ) {
            //value = toCommaFormat(kpi_month_data[0].value);
            value = kpi_month_data[0].value;
          }

          standard_data.push({
            month: moment(now).format("MMM YYYY"),
            value: value,
          });

          // can list in performance
          if (value) canShow = value !== "-";
        });
        if (canShow) kpi_data = standard_data;
      }
    }

    return kpi_data;
  };

  const userKPIList = () => {
    let kpi_names = [];
    let fin_kpi_names = Object.keys(
      appliedFundingDeal?.business_information?.financial_kpi || {}
    );
    let eng_kpi_names = Object.keys(
      appliedFundingDeal?.business_information?.engagement_kpi || {}
    );
    let business_kpi_names = (
      appliedFundingDeal?.business_information?.business_kpi || []
    ).map((k, i) => k.name);
    kpi_names = kpi_names.concat(fin_kpi_names);
    kpi_names = kpi_names.concat(eng_kpi_names);
    kpi_names = kpi_names.concat(business_kpi_names);
    return kpi_names;
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    let fundingDealOptions = fundingDealTypeList?.map(item => {
      return {
        value: item?.value,
        label: item?.value,
      };
    });
    let fundingRoundOptions = fundingRoundList?.map(item => {
      return {
        value: item?.value,
        label: item?.value,
      };
    });
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Funding Deal | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <ToastContainer />
            <React.Fragment>
              <Row>
                <Col xs="12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">
                      Applied Funding Deal :{" "}
                      {!!startupInfo?.[0]?.basic_information?.startup_name ? (
                        <>
                          {`${startupInfo?.[0]?.basic_information?.startup_name} - ${appliedFundingDeal?.funding_information?.funding_round}`}
                          {!(
                            appliedFundingDeal?.funding_information
                              ?.deal_date === undefined ||
                            appliedFundingDeal?.funding_information
                              ?.deal_date === "undefined"
                          ) ? (
                            <>
                              {" "}
                              (
                              {moment(
                                appliedFundingDeal?.funding_information
                                  ?.deal_date
                              ).format("MMM YYYY")}
                              )
                            </>
                          ) : (
                            ""
                          )}{" "}
                          [{saasName || ""}]
                        </>
                      ) : (
                        <></>
                      )}
                    </h4>
                  </div>
                </Col>
              </Row>
            </React.Fragment>

            <div>
              <Nav tabs>
                {getUserRolles?.view_deal_basic_details == true && (
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" && "active"}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Basic Details
                    </NavLink>
                  </NavItem>
                )}

                {getUserRolles?.intent_report == true && (
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" && "active"}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Investor Report
                    </NavLink>
                  </NavItem>
                )}

                {getUserRolles?.q_and_a == true && (
                  <NavItem>
                    <NavLink
                      className={activeTab === "5" && "active"}
                      onClick={() => toggle("5")}
                    >
                      Q&A
                    </NavLink>
                  </NavItem>
                )}

                {(!!getUserRolles?.is_super_admin ||
                  !!getUserRolles?.investment_thesis) && (
                  <NavItem>
                    <NavLink
                      className={activeTab === "8" && "active"}
                      onClick={() => toggle("8")}
                    >
                      Investment Thesis
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row className="mt-3">
                    {/* Investor grouping card */}
                    {appliedFundingDeal?.status === "Open" &&
                      appliedFundingDeal?.current_status === "Deal Live" && (
                        <Col
                          sm={12}
                          className="border border-secondary rounded mb-3"
                        >
                          <Row className="m-3">
                            <Col sm={12}>
                              <h4>Investor Groups</h4>
                              <Row>
                                <InvestorGroupsModal
                                  investorGroupsModalOpen={
                                    investorGroupModalOpen
                                  }
                                  handleInvestorGroupsModal={
                                    handleInvestorGroupsModal
                                  }
                                  selectedDeal={appliedFundingDeal || {}}
                                />
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    <Col
                      sm={12}
                      className="border border-secondary rounded p-0 mb-3"
                    >
                      <div>
                        <div className="peach-table">
                          <div className="custom-table-header">
                            <div
                              className="d-flex align-items-center justify-content-center relative"
                              style={{
                                minHeight: "50px",
                              }}
                            >
                              <h3
                                title={`Investment Terms For ${
                                  startupInfo?.[0]?.basic_information
                                    ?.startup_name || ""
                                }`}
                                className="heading"
                              >
                                Investment Terms For{" "}
                                {startupInfo?.[0]?.basic_information
                                  ?.startup_name || ""}
                              </h3>
                              <div className="table-btn">
                                {(getUserRolles?.is_super_admin ||
                                  (!(
                                    appliedFundingDeal?.current_status ===
                                      "Closed - Internally Funded" ||
                                    appliedFundingDeal?.current_status ===
                                      "Closed - Externally Funded"
                                  ) &&
                                    getUserRolles?.update_funding_deal_status ===
                                      true)) && (
                                  <>
                                    <Button
                                      type="button"
                                      className="btn-success me-1"
                                      disabled={
                                        !getUserRolles?.is_super_admin &&
                                        !getUserRolles?.update_funding_deal_status
                                      }
                                      onClick={() =>
                                        toggleUpdateDealStatusBtn()
                                      }
                                    >
                                      <i className="icocol">
                                        <img src="../img/edit-ico.png" alt="" />
                                      </i>
                                      Update Details
                                    </Button>
                                  </>
                                )}
                                {appliedFundingDeal?.term_sheet_file !==
                                  undefined &&
                                appliedFundingDeal?.term_sheet_file !== null &&
                                appliedFundingDeal?.term_sheet_file !== "" ? (
                                  <Button
                                    type="button"
                                    className="btn-success me-1"
                                    onClick={() =>
                                      handleButtonClick(
                                        appliedFundingDeal?.term_sheet_file
                                      )
                                    }
                                  >
                                    <i className="icocol">
                                      <img src="../img/docs-ico.png" alt="" />
                                    </i>
                                    View Term Sheet
                                  </Button>
                                ) : (
                                  ""
                                )}

                                {getUserRolles?.copy_to_another_saas ===
                                  true && (
                                  <Button
                                    type="button"
                                    className="btn-success me-1"
                                    onClick={() => toggleCopyDealStatusBtn()}
                                    disabled={showButton}
                                  >
                                    <i className="icocol">
                                      <img src="../img/copy-ico.png" alt="" />
                                    </i>
                                    Copy Deal
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="custom-table-content">
                            <div className="custom-table-row ssection">
                              <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                <p>Round </p>
                              </div>
                              <div className="custom-table-content-right scolumn d-flex align-items-center justify-content-center">
                                <p>
                                  {" "}
                                  {get_funding_info(
                                    "fund_ask_target",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "-" ? (
                                    <>
                                      {convertToVehicleCurrency(
                                        get_funding_info(
                                          "fund_ask_target",
                                          "",
                                          appliedFundingDeal
                                        )
                                      )}
                                      [
                                      {get_funding_info(
                                        "funding_round",
                                        "",
                                        appliedFundingDeal
                                      )}
                                      ]
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="custom-table-row ssection">
                              <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                <p>Instrument </p>
                              </div>
                              <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                <p>
                                  {appliedFundingDeal?.funding_information
                                    ?.deal_type
                                    ? appliedFundingDeal?.funding_information
                                        ?.deal_type
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            {parseInt(
                              appliedFundingDeal?.funding_information
                                ?.valuation_cap || 0
                            ) ||
                            parseInt(
                              appliedFundingDeal?.funding_information
                                ?.valuation_floor || 0
                            ) ? (
                              <>
                                {parseInt(
                                  appliedFundingDeal?.funding_information
                                    ?.valuation_floor || 0
                                ) ? (
                                  <div className="custom-table-row ssection">
                                    <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                      <p>Valuation Floor </p>
                                    </div>
                                    <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                      <p>
                                        {parseInt(
                                          get_funding_info(
                                            "valuation_floor",
                                            "-",
                                            appliedFundingDeal
                                          ) || 0
                                        ) !== "" ? (
                                          <>
                                            {convertToVehicleCurrency(
                                              get_funding_info(
                                                "valuation_floor",
                                                "",
                                                appliedFundingDeal
                                              )
                                            )}
                                          </>
                                        ) : (
                                          "N.A."
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {parseInt(
                                  appliedFundingDeal?.funding_information
                                    ?.valuation_cap || 0
                                ) ? (
                                  <div className="custom-table-row ssection">
                                    <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                      <p>Valuation Cap </p>
                                    </div>
                                    <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                      <p>
                                        {parseInt(
                                          get_funding_info(
                                            "valuation_cap",
                                            "-",
                                            appliedFundingDeal
                                          ) || 0
                                        ) !== "" ? (
                                          <>
                                            {convertToVehicleCurrency(
                                              get_funding_info(
                                                "valuation_cap",
                                                "",
                                                appliedFundingDeal
                                              )
                                            )}
                                          </>
                                        ) : (
                                          "N.A."
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <div className="custom-table-row ssection">
                                <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                  <p>Valuation </p>
                                </div>
                                <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                  <p>
                                    {convertToVehicleCurrency(
                                      get_funding_info(
                                        "current_valuation",
                                        "",
                                        appliedFundingDeal
                                      )
                                    )}
                                    [
                                    {get_funding_info(
                                      "valuation_type",
                                      "-",
                                      appliedFundingDeal
                                    )?.replace("_", "-")}
                                    ]
                                  </p>
                                </div>
                              </div>
                            )}
                            {!!parseInt(
                              appliedFundingDeal?.funding_information?.discount?.split(
                                "%"
                              )?.[0]
                            ) && (
                              <div className="custom-table-row ssection">
                                <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                  <p>Discount</p>
                                </div>
                                <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                  <p>
                                    {parseInt(
                                      appliedFundingDeal?.funding_information?.discount?.split(
                                        "%"
                                      )?.[0]
                                    )}
                                    %
                                  </p>
                                </div>
                              </div>
                            )}

                            <div className="custom-table-row ssection">
                              <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                <p>
                                  Allocation for{" "}
                                  {(appliedFundingDeal?.saas_parent_id
                                    ?.saas_fullname ||
                                    appliedFundingDeal?.saas_parent_id
                                      ?.saas_name) ??
                                    "Domain Expert"}
                                </p>
                              </div>
                              <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                <p>
                                  {get_funding_info(
                                    "how_much_fund_raising",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "-" ? (
                                    <>
                                      {convertToVehicleCurrency(
                                        get_funding_info(
                                          "how_much_fund_raising",
                                          "",
                                          appliedFundingDeal
                                        )
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </p>
                              </div>
                            </div>
                            {typeof appliedFundingDeal?.funding_information
                              ?.existing_commitments !== "undefined" &&
                            appliedFundingDeal?.funding_information
                              ?.existing_commitments ? (
                              <>
                                {appliedFundingDeal?.funding_information
                                  ?.existing_commitments[0]?.committedAmount ? (
                                  <div className="custom-table-row ssection">
                                    <div className="custom-table-content-left d-flex justify-content-center align-items-center">
                                      <p>Existing Commitment </p>
                                    </div>
                                    <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                      <p>
                                        {" "}
                                        {appliedFundingDeal?.funding_information
                                          ?.existing_commitments &&
                                        appliedFundingDeal?.funding_information
                                          ?.existing_commitments?.length > 0 ? (
                                          <>
                                            {" "}
                                            {get_existing_commitment_info(
                                              appliedFundingDeal
                                            ) ? (
                                              <>
                                                {/* ₹{" "} */}
                                                {
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        get_existing_commitment_info(
                                                          appliedFundingDeal
                                                        ),
                                                    }}
                                                  ></span>
                                                }
                                              </>
                                            ) : (
                                              "N.A."
                                            )}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}

                            {get_funding_info(
                              "investment_vehicle",
                              "-",
                              appliedFundingDeal
                            ) &&
                            [
                              "Angelist US",
                              "Angelist India",
                              "WFC Global Angels Fund",
                              "Invstt Trust",
                            ].includes(
                              get_funding_info(
                                "investment_vehicle",
                                "-",
                                appliedFundingDeal
                              )
                            ) ? (
                              <div className="custom-table-row">
                                <div className="custom-table-content-left d-flex align-items-center justify-content-center flex-column">
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <p>
                                      {get_funding_info(
                                        "investment_vehicle",
                                        "-",
                                        appliedFundingDeal
                                      ) &&
                                      get_funding_info(
                                        "investment_vehicle",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "-"
                                        ? "Via " +
                                          get_funding_info(
                                            "investment_vehicle",
                                            "-",
                                            appliedFundingDeal
                                          )
                                        : "Via Invstt Trust"}{" "}
                                      :
                                    </p>
                                  </div>
                                  {get_funding_info(
                                    getMultiCurrencyFieldName("min_amount"),
                                    "-",
                                    appliedFundingDeal
                                  ) &&
                                  get_funding_info(
                                    getMultiCurrencyFieldName("min_amount"),
                                    "-",
                                    appliedFundingDeal
                                  ) !== "undefined" &&
                                  parseInt(
                                    get_funding_info(
                                      getMultiCurrencyFieldName("min_amount"),
                                      "-",
                                      appliedFundingDeal
                                    )
                                  ) > 0 ? (
                                    <p>Minimum Investment</p>
                                  ) : (
                                    ""
                                  )}
                                  {get_funding_info(
                                    "transaction_fees",
                                    "-",
                                    appliedFundingDeal
                                  ) &&
                                  get_funding_info(
                                    "transaction_fees",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "-" &&
                                  get_funding_info(
                                    "transaction_fees",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "undefined" &&
                                  parseInt(
                                    get_funding_info(
                                      "transaction_fees",
                                      "-",
                                      appliedFundingDeal
                                    )
                                  ) !== 0 ? (
                                    <p>Management fees</p>
                                  ) : (
                                    ""
                                  )}
                                  {get_funding_info(
                                    "carry_on_capital_gains",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "-" &&
                                  get_funding_info(
                                    "carry_on_capital_gains",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "undefined" ? (
                                    <p>Carry on capital gains</p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="custom-table-content-right d-flex align-items-center justify-content-center flex-column">
                                  <p>
                                    <br />
                                  </p>
                                  <p>
                                    <>
                                      {get_funding_info(
                                        getMultiCurrencyFieldName("min_amount"),
                                        "-",
                                        appliedFundingDeal
                                      ) &&
                                      get_funding_info(
                                        getMultiCurrencyFieldName("min_amount"),
                                        "-",
                                        appliedFundingDeal
                                      ) !== "undefined" &&
                                      parseInt(
                                        get_funding_info(
                                          getMultiCurrencyFieldName(
                                            "min_amount"
                                          ),
                                          "-",
                                          appliedFundingDeal
                                        )
                                      ) > 0 ? (
                                        <>
                                          {convertToVehicleCurrency(
                                            get_funding_info(
                                              getMultiCurrencyFieldName(
                                                "min_amount"
                                              ),
                                              "",
                                              appliedFundingDeal
                                            )
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  </p>
                                  <p>
                                    {get_funding_info(
                                      "transaction_fees",
                                      "-",
                                      appliedFundingDeal
                                    ) &&
                                    get_funding_info(
                                      "transaction_fees",
                                      "-",
                                      appliedFundingDeal
                                    ) !== "-" &&
                                    get_funding_info(
                                      "transaction_fees",
                                      "-",
                                      appliedFundingDeal
                                    ) !== "undefined" &&
                                    parseInt(
                                      get_funding_info(
                                        "transaction_fees",
                                        "-",
                                        appliedFundingDeal
                                      )
                                    ) !== 0 ? (
                                      <>
                                        {get_funding_info(
                                          "transaction_fees",
                                          "-",
                                          appliedFundingDeal
                                        )}
                                        {![
                                          "Angelist US",
                                          "Angelist India",
                                          "WFC Global Angels Fund",
                                        ].includes(
                                          get_funding_info(
                                            "investment_vehicle",
                                            "-",
                                            appliedFundingDeal
                                          )
                                        ) ? (
                                          <>% + GST </>
                                        ) : (
                                          <>% </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  <p>
                                    {get_funding_info(
                                      "carry_on_capital_gains",
                                      "-",
                                      appliedFundingDeal
                                    ) !== "-" &&
                                    get_funding_info(
                                      "carry_on_capital_gains",
                                      "-",
                                      appliedFundingDeal
                                    ) !== "undefined" ? (
                                      <>
                                        {get_funding_info(
                                          "carry_on_capital_gains",
                                          "",
                                          appliedFundingDeal
                                        )}
                                        %
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </div>
                              </div>
                            ) : get_funding_info(
                                "investment_vehicle",
                                "-",
                                appliedFundingDeal
                              ) &&
                              get_funding_info(
                                "investment_vehicle",
                                "-",
                                appliedFundingDeal
                              ) !== "-" &&
                              (get_funding_info(
                                "investment_vehicle",
                                "-",
                                appliedFundingDeal
                              ).length > 1 ||
                                (get_funding_info(
                                  "investment_vehicle",
                                  "-",
                                  appliedFundingDeal
                                ).length == 1 &&
                                  Object.keys(
                                    get_funding_info(
                                      "investment_vehicle",
                                      "-",
                                      appliedFundingDeal
                                    )[0]
                                  ).includes("currency"))) ? (
                              get_funding_info(
                                "investment_vehicle",
                                "-",
                                appliedFundingDeal
                              ).map(iv => {
                                let fundCurrency =
                                  CURRENCY_MAP?.[iv?.currency || "INR"];

                                return (
                                  <>
                                    <div className="custom-table-row">
                                      <div className="custom-table-content-left d-flex align-items-center justify-content-center flex-column">
                                        <div
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        >
                                          <p>{iv?.label || ""}:</p>
                                        </div>
                                        {get_funding_info(
                                          getMultiCurrencyFieldName(
                                            "min_amount",
                                            fundCurrency
                                          ),
                                          "-",
                                          appliedFundingDeal
                                        ) &&
                                        get_funding_info(
                                          getMultiCurrencyFieldName(
                                            "min_amount",
                                            fundCurrency
                                          ),
                                          "-",
                                          appliedFundingDeal
                                        ) !== "undefined" &&
                                        parseInt(
                                          get_funding_info(
                                            getMultiCurrencyFieldName(
                                              "min_amount",
                                              fundCurrency
                                            ),
                                            "-",
                                            appliedFundingDeal
                                          )
                                        ) > 0 ? (
                                          <p>Minimum Investment</p>
                                        ) : (
                                          ""
                                        )}
                                        {get_funding_info(
                                          "iv_mapped_transaction_fees",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] &&
                                        get_funding_info(
                                          "iv_mapped_transaction_fees",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] !==
                                          "-" &&
                                        get_funding_info(
                                          "iv_mapped_transaction_fees",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] !==
                                          "undefined" &&
                                        get_funding_info(
                                          "iv_mapped_transaction_fees",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] !==
                                          "undefined" &&
                                        parseInt(
                                          get_funding_info(
                                            "iv_mapped_transaction_fees",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")]
                                        ) !== 0 ? (
                                          <p>Management fees</p>
                                        ) : (
                                          ""
                                        )}
                                        {get_funding_info(
                                          "iv_mapped_carry_on_capital_gains",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] &&
                                        get_funding_info(
                                          "iv_mapped_carry_on_capital_gains",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] !==
                                          "-" &&
                                        get_funding_info(
                                          "iv_mapped_carry_on_capital_gains",
                                          "-",
                                          appliedFundingDeal
                                        )[iv.value.replace(/\s+/g, "")] !==
                                          "undefined" ? (
                                          <p>Carry on capital gains</p>
                                        ) : (
                                          ""
                                        )}
                                        {fundCurrency?.value === "USD" ? (
                                          <>
                                            <p>Remitter Bank Charges</p>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="custom-table-content-right d-flex align-items-center justify-content-center flex-column">
                                        <p>
                                          <br />
                                        </p>
                                        <p>
                                          <>
                                            {get_funding_info(
                                              getMultiCurrencyFieldName(
                                                "min_amount",
                                                fundCurrency
                                              ),
                                              "-",
                                              appliedFundingDeal
                                            ) &&
                                            get_funding_info(
                                              getMultiCurrencyFieldName(
                                                "min_amount",
                                                fundCurrency
                                              ),
                                              "-",
                                              appliedFundingDeal
                                            ) !== "undefined" &&
                                            parseInt(
                                              get_funding_info(
                                                getMultiCurrencyFieldName(
                                                  "min_amount",
                                                  fundCurrency
                                                ),
                                                "-",
                                                appliedFundingDeal
                                              )
                                            ) > 0 ? (
                                              <>
                                                {convertToVehicleCurrency(
                                                  get_funding_info(
                                                    getMultiCurrencyFieldName(
                                                      "min_amount",
                                                      fundCurrency
                                                    ),
                                                    "",
                                                    appliedFundingDeal
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        </p>
                                        <p>
                                          {get_funding_info(
                                            "iv_mapped_transaction_fees",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")] &&
                                          get_funding_info(
                                            "iv_mapped_transaction_fees",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")] !==
                                            "-" &&
                                          get_funding_info(
                                            "iv_mapped_transaction_fees",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")] !==
                                            "undefined" &&
                                          parseInt(
                                            get_funding_info(
                                              "iv_mapped_transaction_fees",
                                              "-",
                                              appliedFundingDeal
                                            )[iv.value.replace(/\s+/g, "")]
                                          ) !== 0 ? (
                                            <>
                                              {
                                                get_funding_info(
                                                  "iv_mapped_transaction_fees",
                                                  "-",
                                                  appliedFundingDeal
                                                )[iv.value.replace(/\s+/g, "")]
                                              }
                                              {![
                                                "Angelist US",
                                                "Angelist India",
                                                "WFC Global Angels Fund",
                                              ].includes(iv.value) ? (
                                                <>% + GST </>
                                              ) : (
                                                <>% </>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                        <p>
                                          {get_funding_info(
                                            "iv_mapped_carry_on_capital_gains",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")] &&
                                          get_funding_info(
                                            "iv_mapped_carry_on_capital_gains",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")] !==
                                            "-" &&
                                          get_funding_info(
                                            "iv_mapped_carry_on_capital_gains",
                                            "-",
                                            appliedFundingDeal
                                          )[iv.value.replace(/\s+/g, "")] !==
                                            "undefined" ? (
                                            <>
                                              {
                                                get_funding_info(
                                                  "iv_mapped_carry_on_capital_gains",
                                                  "",
                                                  appliedFundingDeal
                                                )[iv.value.replace(/\s+/g, "")]
                                              }
                                              %
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                        {fundCurrency?.value === "USD" ? (
                                          <>
                                            <p>
                                              As may be applicable (Approx $20)
                                            </p>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              ""
                            )}
                            {!["Direct-Cap Table"].includes(
                              appliedFundingDeal?.funding_information
                                ?.investment_vehicle?.[0]?.value
                            ) ? (
                              <>
                                {(get_funding_info(
                                  getMultiCurrencyFieldName(
                                    "min_amount_cap_amount_tab"
                                  ),
                                  "-",
                                  appliedFundingDeal
                                ) &&
                                  get_funding_info(
                                    getMultiCurrencyFieldName(
                                      "min_amount_cap_amount_tab"
                                    ),
                                    "-",
                                    appliedFundingDeal
                                  ) !== "undefined" &&
                                  parseInt(
                                    get_funding_info(
                                      getMultiCurrencyFieldName(
                                        "min_amount_cap_amount_tab"
                                      ),
                                      "-",
                                      appliedFundingDeal
                                    )
                                  ) > 0) ||
                                (get_funding_info(
                                  "transaction_fees_cap_table",
                                  "-",
                                  appliedFundingDeal
                                ) &&
                                  get_funding_info(
                                    "transaction_fees_cap_table",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "-" &&
                                  get_funding_info(
                                    "transaction_fees_cap_table",
                                    "-",
                                    appliedFundingDeal
                                  ) !== "undefined" &&
                                  parseInt(
                                    get_funding_info(
                                      "transaction_fees_cap_table",
                                      "-",
                                      appliedFundingDeal
                                    )
                                  ) !== 0) ? (
                                  <div className="custom-table-row ssection">
                                    <div className="custom-table-content-left d-flex align-items-center justify-content-center flex-column">
                                      <p
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Via Direct Cap Table :
                                      </p>

                                      {get_funding_info(
                                        getMultiCurrencyFieldName(
                                          "min_amount_cap_amount_tab"
                                        ),
                                        "-",
                                        appliedFundingDeal
                                      ) &&
                                      get_funding_info(
                                        getMultiCurrencyFieldName(
                                          "min_amount_cap_amount_tab"
                                        ),
                                        "-",
                                        appliedFundingDeal
                                      ) !== "undefined" &&
                                      parseInt(
                                        get_funding_info(
                                          getMultiCurrencyFieldName(
                                            "min_amount_cap_amount_tab"
                                          ),
                                          "-",
                                          appliedFundingDeal
                                        )
                                      ) ? (
                                        <p>Minimum Investment</p>
                                      ) : (
                                        ""
                                      )}
                                      {get_funding_info(
                                        "transaction_fees_cap_table",
                                        "-",
                                        appliedFundingDeal
                                      ) &&
                                      get_funding_info(
                                        "transaction_fees_cap_table",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "-" &&
                                      get_funding_info(
                                        "transaction_fees_cap_table",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "undefined" &&
                                      parseInt(
                                        get_funding_info(
                                          "transaction_fees_cap_table",
                                          "-",
                                          appliedFundingDeal
                                        )
                                      ) !== 0 ? (
                                        <p>Transaction Fees</p>
                                      ) : (
                                        ""
                                      )}
                                      {get_funding_info(
                                        "exit_documentation_fees_captab",
                                        "-",
                                        appliedFundingDeal
                                      ) &&
                                      get_funding_info(
                                        "exit_documentation_fees_captab",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "-" &&
                                      get_funding_info(
                                        "exit_documentation_fees_captab",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "undefined" &&
                                      parseInt(
                                        get_funding_info(
                                          "exit_documentation_fees_captab",
                                          "-",
                                          appliedFundingDeal
                                        )
                                      ) !== 0 ? (
                                        <p>Exit Documentation Fees</p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="custom-table-content-right d-flex align-items-center justify-content-center flex-column">
                                      <p>
                                        <br />
                                      </p>
                                      <p>
                                        {get_funding_info(
                                          getMultiCurrencyFieldName(
                                            "min_amount_cap_amount_tab"
                                          ),
                                          "-",
                                          appliedFundingDeal
                                        ) &&
                                        get_funding_info(
                                          getMultiCurrencyFieldName(
                                            "min_amount_cap_amount_tab"
                                          ),
                                          "-",
                                          appliedFundingDeal
                                        ) !== "undefined" &&
                                        parseInt(
                                          get_funding_info(
                                            getMultiCurrencyFieldName(
                                              "min_amount_cap_amount_tab"
                                            ),
                                            "-",
                                            appliedFundingDeal
                                          )
                                        ) > 0 ? (
                                          <>
                                            {get_funding_info(
                                              getMultiCurrencyFieldName(
                                                "min_amount_cap_amount_tab"
                                              ),
                                              "-",
                                              appliedFundingDeal
                                            ) &&
                                              get_funding_info(
                                                getMultiCurrencyFieldName(
                                                  "min_amount_cap_amount_tab"
                                                ),
                                                "-",
                                                appliedFundingDeal
                                              ) !== "undefined" &&
                                              parseInt(
                                                get_funding_info(
                                                  getMultiCurrencyFieldName(
                                                    "min_amount_cap_amount_tab"
                                                  ),
                                                  "-",
                                                  appliedFundingDeal
                                                )
                                              ) > 0 && (
                                                <>
                                                  {convertToVehicleCurrency(
                                                    get_funding_info(
                                                      (appliedFundingDeal
                                                        ?.funding_information
                                                        ?.investment_vehicle?.[0]
                                                        ?.currency === "INR")
                                                        ? getMultiCurrencyFieldName(
                                                            "min_amount_cap_amount_tab"
                                                          )
                                                        : getMultiCurrencyFieldName(
                                                            "min_amount_cap_amount_tab_usd"
                                                          ),
                                                      "",
                                                      appliedFundingDeal
                                                    )
                                                  )}
                                                </>
                                              )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      <p>
                                        {get_funding_info(
                                          "transaction_fees_cap_table",
                                          "-",
                                          appliedFundingDeal
                                        ) &&
                                        get_funding_info(
                                          "transaction_fees_cap_table",
                                          "-",
                                          appliedFundingDeal
                                        ) !== "-" &&
                                        get_funding_info(
                                          "transaction_fees_cap_table",
                                          "-",
                                          appliedFundingDeal
                                        ) !== "undefined" &&
                                        parseInt(
                                          get_funding_info(
                                            "transaction_fees_cap_table",
                                            "-",
                                            appliedFundingDeal
                                          )
                                        ) !== 0 ? (
                                          <>
                                            {get_funding_info(
                                              "transaction_fees_cap_table",
                                              "-",
                                              appliedFundingDeal
                                            )}
                                            {get_funding_info(
                                              "investment_vehicle",
                                              "-",
                                              appliedFundingDeal
                                            ) !== "WFC Global Angels Fund" ? (
                                              <>% + GST </>
                                            ) : (
                                              <>% </>
                                            )}
                                            {/* % + GST */}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      {get_funding_info(
                                        "exit_documentation_fees_captab",
                                        "-",
                                        appliedFundingDeal
                                      ) &&
                                      get_funding_info(
                                        "exit_documentation_fees_captab",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "-" &&
                                      get_funding_info(
                                        "exit_documentation_fees_captab",
                                        "-",
                                        appliedFundingDeal
                                      ) !== "undefined" &&
                                      parseInt(
                                        get_funding_info(
                                          "exit_documentation_fees_captab",
                                          "-",
                                          appliedFundingDeal
                                        )
                                      ) !== 0 ? (
                                        <p>
                                          {get_funding_info(
                                            "exit_documentation_fees_captab",
                                            "-",
                                            appliedFundingDeal
                                          )}
                                          % + GST
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {get_funding_info(
                              "remarks",
                              "-",
                              appliedFundingDeal
                            ) && (
                              <div className="custom-table-row">
                                <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                  {get_funding_info(
                                    "remarks",
                                    "-",
                                    appliedFundingDeal
                                  ) ? (
                                    <p>Additional Notes</p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                  {get_funding_info(
                                    "remarks",
                                    "-",
                                    appliedFundingDeal
                                  ) ? (
                                    <p
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        marginBottom: 0,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: get_funding_info(
                                          "remarks",
                                          "-",
                                          appliedFundingDeal
                                        ),
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="custom-table-row ssection">
                              <div className="custom-table-content-left d-flex align-items-center justify-content-center flex-column">
                                <p
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Additional Details
                                </p>
                                <p>Deal Status</p>
                                {!isEmpty(
                                  appliedFundingDeal?.deal_manager?.user_id
                                ) && <p>Deal Manager</p>}
                                {!(
                                  appliedFundingDeal?.drawdown_status ===
                                    undefined ||
                                  appliedFundingDeal?.drawdown_status ===
                                    "undefined"
                                ) && (
                                  <>
                                    <p>Drawdown Status</p>
                                  </>
                                )}
                                {startupInfo?.[0]?.basic_information
                                  ?.pan_number && <p>PAN of the Startup</p>}
                                {!isEmpty(appliedFundingDeal?.scheme_name) && (
                                  <p>Scheme Name</p>
                                )}
                                {!isEmpty(
                                  appliedFundingDeal?.funding_information
                                    ?.min_drawdown_consent ||
                                    appliedFundingDeal?.funding_information
                                      ?.min_consent_for_drawdown_usd
                                ) && <p>Min Consent for Drawdown</p>}
                                {!isEmpty(
                                  appliedFundingDeal?.share_price ||
                                    appliedFundingDeal?.funding_information
                                      ?.share_price_usd
                                ) && <p>Share Price</p>}
                              </div>
                              <div className="custom-table-content-right d-flex align-items-center justify-content-center flex-column">
                                <p>&nbsp;</p>
                                <p>
                                  {appliedFundingDeal?.current_status
                                    ? appliedFundingDeal?.current_status
                                    : ""}
                                </p>
                                {!isEmpty(
                                  appliedFundingDeal?.deal_manager?.user_id
                                ) && (
                                  <p>
                                    {" "}
                                    {(appliedFundingDeal?.deal_manager?.user_id
                                      ?.firstname || "") +
                                      " " +
                                      (appliedFundingDeal?.deal_manager?.user_id
                                        ?.lastname || "")}
                                  </p>
                                )}
                                {!(
                                  appliedFundingDeal?.drawdown_status ===
                                    undefined ||
                                  appliedFundingDeal?.drawdown_status ===
                                    "undefined"
                                ) && (
                                  <>
                                    <p>
                                      {" "}
                                      {!(
                                        appliedFundingDeal?.drawdown_status ===
                                          undefined ||
                                        appliedFundingDeal?.drawdown_status ===
                                          "undefined"
                                      )
                                        ? appliedFundingDeal?.drawdown_status
                                        : "-"}
                                    </p>
                                  </>
                                )}
                                {startupInfo?.[0]?.basic_information
                                  ?.pan_number && (
                                  <p>
                                    {
                                      startupInfo?.[0]?.basic_information
                                        ?.pan_number
                                    }
                                  </p>
                                )}
                                {!isEmpty(appliedFundingDeal?.scheme_name) && (
                                  <p>{appliedFundingDeal?.scheme_name}</p>
                                )}
                                {!isEmpty(
                                  appliedFundingDeal?.funding_information
                                    ?.min_drawdown_consent ||
                                    appliedFundingDeal?.funding_information
                                      ?.min_consent_for_drawdown_usd
                                ) && (
                                  <p>
                                    {appliedFundingDeal?.funding_information
                                      ?.investment_vehicle?.[0]?.currency ===
                                    "INR"
                                      ? formatNumberToStringWithCurrencyV2(
                                          appliedFundingDeal
                                            ?.funding_information
                                            ?.min_drawdown_consent,
                                          CURRENCY_MAP?.["INR"]
                                        )
                                      : formatNumberToStringWithCurrencyV2(
                                          appliedFundingDeal
                                            ?.funding_information
                                            ?.min_consent_for_drawdown_usd,
                                          CURRENCY_MAP?.["USD"]
                                        )}
                                  </p>
                                )}
                                {!isEmpty(
                                  appliedFundingDeal?.share_price ||
                                    appliedFundingDeal?.funding_information
                                      ?.share_price_usd
                                ) && (
                                  <p>
                                    {appliedFundingDeal?.funding_information
                                      ?.investment_vehicle?.[0]?.currency ===
                                    "INR" ? (
                                      <>
                                        {formatNumberToStringWithCurrencyV2(
                                          appliedFundingDeal?.share_price,
                                          CURRENCY_MAP?.["INR"]
                                        )}{" "}
                                        per share
                                      </>
                                    ) : (
                                      <>
                                        {formatNumberToStringWithCurrencyV2(
                                          appliedFundingDeal?.share_price_usd,
                                          CURRENCY_MAP?.["USD"]
                                        )}{" "}
                                        per share
                                      </>
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* <div className="custom-table-row ssection">
                              <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                <p>Deal Status</p>
                              </div>
                              <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                <p>
                                  {appliedFundingDeal?.current_status
                                    ? appliedFundingDeal?.current_status
                                    : ""}
                                </p>
                              </div>
                            </div> */}
                            {/* {!(
                              appliedFundingDeal?.drawdown_status ===
                              undefined ||
                              appliedFundingDeal?.drawdown_status ===
                              "undefined"
                            ) && (
                                <div className="custom-table-row ssection">
                                  <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                    <p>Drawdown Status</p>
                                  </div>
                                  <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                    <p>
                                      {!(
                                        appliedFundingDeal?.drawdown_status ===
                                        undefined ||
                                        appliedFundingDeal?.drawdown_status ===
                                        "undefined"
                                      )
                                        ? appliedFundingDeal?.drawdown_status
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              )} */}
                            {/* {startupInfo?.[0]?.basic_information
                              ?.pan_number && (
                                <div className="custom-table-row ssection">
                                  <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                    <p>PAN of the Startup</p>
                                  </div>
                                  <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                    <p>
                                      {
                                        startupInfo?.[0]?.basic_information
                                          ?.pan_number
                                      }
                                    </p>
                                  </div>
                                </div>
                              )} */}
                            {/* {!isEmpty(appliedFundingDeal?.scheme_name) && (
                              <div className="custom-table-row ssection">
                                <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                  <p>Scheme Name</p>
                                </div>
                                <div className="custom-table-content-right d-flex align-items-center justify-content-center">
                                  <p>{appliedFundingDeal?.scheme_name}</p>
                                </div>
                              </div>
                            )} */}
                            {/* {!isEmpty(
                              appliedFundingDeal?.funding_information
                                ?.min_drawdown_consent ||
                              appliedFundingDeal?.funding_information
                                ?.min_consent_for_drawdown_usd
                            ) && (
                                <div className="custom-table-row ssection">
                                  <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                    <p>Min Consent for Drawdown</p>
                                  </div>
                                  <div className="custom-table-content-right d-flex align-items-center justify-content-center gap-3">
                                  {appliedFundingDeal?.funding_information
                                      ?.investment_vehicle?.[0]?.currency ===
                                      "INR"
                                      ? formatNumberToStringWithCurrencyV2(
                                        appliedFundingDeal?.funding_information
                                          ?.min_drawdown_consent,
                                        CURRENCY_MAP?.["INR"],
                                      )
                                      : formatNumberToStringWithCurrencyV2(
                                        appliedFundingDeal?.funding_information
                                          ?.min_consent_for_drawdown_usd,
                                        CURRENCY_MAP?.["USD"]
                                      )}
                                  </div>
                                </div>
                              )} */}
                            {/* {!isEmpty(
                              appliedFundingDeal?.share_price ||
                              appliedFundingDeal?.funding_information
                                ?.share_price_usd
                            ) && (
                                <div className="custom-table-row ssection">
                                  <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                    <p>Share Price</p>
                                  </div>
                                  <div className="custom-table-content-right d-flex align-items-center justify-content-center gap-3">
                                    {appliedFundingDeal?.funding_information
                                      ?.investment_vehicle?.[0]?.currency ===
                                      "INR"
                                      ? <>{formatNumberToStringWithCurrencyV2(
                                        appliedFundingDeal?.share_price
                                      )} per share</>
                                      : <>
                                        {formatNumberToStringWithCurrencyV2(
                                          appliedFundingDeal?.share_price_usd
                                        )} per share
                                      </>}
                                  </div>
                                </div>
                              )} */}
                            {/* {!isEmpty(
                              appliedFundingDeal?.deal_manager?.user_id) && (
                                <div className="custom-table-row ssection">
                                  <div className="custom-table-content-left d-flex align-items-center justify-content-center">
                                    <p>Deal Manager</p>
                                  </div>
                                  <div className="custom-table-content-right d-flex align-items-center justify-content-center gap-3">
                                    {(appliedFundingDeal?.deal_manager?.user_id?.firstname || "") + " " + (appliedFundingDeal?.deal_manager?.user_id?.lastname || "")}
                                  </div>
                                </div>
                              )} */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col sm={12}>
                      {UpdateFundingDealStatusSuccess && (
                        <Alert color="success">
                          Funding deal status successfully updated.
                        </Alert>
                      )}
                    </Col>
                    <Col sm={12}>
                      {UpdateFundingDealStatusError && (
                        <Alert color="danger">
                          Deal NOT made LIVE as startup status is not Enabled as
                          yet !!
                        </Alert>
                      )}
                    </Col> */}
                    <Col sm={12}>
                      {CopyDealStatusSuccess && (
                        <Alert color="success">
                          Funding deal successfully copied.
                        </Alert>
                      )}
                    </Col>
                    <Col sm={12}>
                      {CopyDealStatusError && (
                        <Alert color="danger">
                          Funding deal has not been copied. Please try again
                          after sometime.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <AssessmentReport
                      appliedFundingDealId={appliedFundingDealId}
                      dealManagers={dealManagers}
                      startupInfo={startupInfo}
                      dealData={appliedFundingDeal}
                      fundDetails={fundDetails}
                    />
                  </Row>
                </TabPane>

                <TabPane tabId="5">
                  <Row>
                    <QuestionAnswers
                      appliedFundingDealId={appliedFundingDealId}
                      appliedFundingDeal={appliedFundingDeal}
                    />
                  </Row>
                </TabPane>

                <TabPane tabId="8">
                  <div className="mt-2 pt-3">
                    <Label className="m-0">
                      <h5>
                        Brief Description [Visibility will be displayed on the
                        Invstt Brew]
                      </h5>
                    </Label>
                    <Row className="flex-row-reverse align-items-center">
                      <Col md={2}>
                        <Label className="text-center d-flex align-items-center justify-content-center gap-3">
                          {(!!getUserRolles?.is_super_admin ||
                            !!getUserRolles?.investment_thesis) && (
                            <>
                              <Link
                                className="text-success"
                                to="#"
                                title="Edit"
                              >
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() =>
                                    toggleInvestmentThesisModal(
                                      appliedFundingDeal
                                        ?.investmentThesisDetailed?.description,
                                      "detailed"
                                    )
                                  }
                                ></i>
                              </Link>
                            </>
                          )}
                          <h6 className="m-0">
                            Last Updated Date <br />{" "}
                            {appliedFundingDeal?.investmentThesisDetailed
                              ?.modify_date
                              ? moment(
                                  appliedFundingDeal?.investmentThesisDetailed
                                    ?.modify_date
                                )?.format("D MMM YYYY, hh:mm a")
                              : "N/A"}
                          </h6>
                        </Label>
                      </Col>
                      <Col md={10}>
                        {!appliedFundingDeal?.investmentThesisDetailed
                          ?.description ||
                        appliedFundingDeal?.investmentThesisDetailed
                          ?.description === "<p><br></p>" ? (
                          <>
                            <div className="text-center border border-secondary rounded p-4">
                              <h5>No Data Available</h5>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="border border-secondary rounded p-4 react-quill-preview inv-thesis"
                              dangerouslySetInnerHTML={{
                                __html:
                                  appliedFundingDeal?.investmentThesisDetailed
                                    ?.description,
                              }}
                            ></div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-2 pt-3">
                    <Label className="m-0">
                      <h5>
                        Detailed Description [Visibility will be displayed on
                        the Startup Page Investment Overview]
                      </h5>
                    </Label>
                    <Row className="flex-row-reverse align-items-center">
                      <Col md={2}>
                        <Label className="text-center d-flex align-items-center justify-content-center gap-3">
                          {(!!getUserRolles?.is_super_admin ||
                            !!getUserRolles?.investment_thesis) && (
                            <>
                              <Link
                                className="text-success"
                                to="#"
                                title="Edit"
                              >
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() =>
                                    toggleInvestmentThesisModal(
                                      appliedFundingDeal?.investmentThesis,
                                      "short"
                                    )
                                  }
                                ></i>
                              </Link>
                            </>
                          )}
                          <h6 className="m-0">
                            Last Updated Date <br />{" "}
                            {appliedFundingDeal?.investmentThesisModifyDate
                              ? moment(
                                  appliedFundingDeal?.investmentThesisModifyDate
                                )?.format("D MMM YYYY, hh:mm a")
                              : "N/A"}
                          </h6>
                        </Label>
                      </Col>
                      <Col md={10}>
                        {!appliedFundingDeal?.investmentThesis ||
                        appliedFundingDeal?.investmentThesis ===
                          "<p><br></p>" ? (
                          <>
                            <div className="text-center border border-secondary rounded p-4">
                              <h5>No Data Available</h5>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="border border-secondary rounded p-4 react-quill-preview inv-thesis"
                              dangerouslySetInnerHTML={{
                                __html: appliedFundingDeal?.investmentThesis,
                              }}
                            ></div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </div>

        <Modal
          isOpen={toggleTermSheetStatus}
          toggle={() => toggleTermSheetStatusBtn()}
          size="lg"
          className="investment-modal"
        >
          <ModalHeader toggle={() => toggleTermSheetStatusBtn()} tag="h4">
            Update Term Sheet Status
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-3">
                {errors?.errortype == "TermSheet" ? (
                  <span style={{ color: "red" }}>{errors?.message}</span>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <form>
              <Row>
                <Col lg={12} className="mb-3">
                  <label>
                    <strong>Select Term Sheet Status:</strong>
                  </label>

                  <Select
                    isDisabled={
                      appliedFundingDeal?.term_sheet_status ===
                      "received_from_sebi"
                        ? true
                        : false
                    }
                    placeholder={"Select Term Status"}
                    name="term_sheet_status"
                    options={term_sheet_status_options}
                    onChange={e => handleSelectedTermSheetStatus(e)}
                    value={term_sheet_status_options.filter(
                      item => item.value === selectedTermSheetStatus
                    )}
                  />
                </Col>
                {selectedTermSheetStatus === "received_from_sebi" ? (
                  <Col lg={12} className="mb-3">
                    <label>
                      <strong>Term sheet Pdf :</strong>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="term_sheet_file"
                      accept=".pdf,.doc,.docx"
                      onChange={handleTermsheetUpload}
                    />
                    {appliedFundingDeal?.term_sheet_file !== undefined &&
                    appliedFundingDeal?.term_sheet_file !== null &&
                    appliedFundingDeal?.term_sheet_file !== "" ? (
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_API_URL}/${appliedFundingDeal.term_sheet_file}`}
                      >
                        View Uploaded Termsheet
                      </a>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  <></>
                )}

                <Col sm={12} className="mt-3">
                  {!updateTermSheet ? (
                    <Button onClick={updateTermSheetApi}>Update Status</Button>
                  ) : (
                    <Button>Updating...</Button>
                  )}
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={investmentThesisModal}
          toggle={() => toggleInvestmentThesisModal()}
          size="xl"
        >
          <ModalHeader toggle={() => toggleInvestmentThesisModal()} tag="h4">
            Update Investment Thesis
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-2">
                <Label>
                  {thesisType === "short"
                    ? "Detailed Description will be displayed on Startup Page Investment Overview"
                    : "Brief Description will be displayed on the Invstt Brew"}{" "}
                </Label>
                {thesisType === "short" ? (
                  <ReactQuill
                    id="description"
                    className="mb-4 "
                    style={{ height: "350px" }}
                    placeholder=""
                    value={investmentThesis?.thesis || ""}
                    onChange={answer => {
                      setInvestmentThesis({
                        ...investmentThesis,
                        thesis: answer,
                      });
                    }}
                    modules={eventDescriptionEditorModules}
                    formats={eventDescriptionEditorFormats}
                  />
                ) : (
                  <ReactQuill
                    id="description"
                    className="mb-4 "
                    style={{ height: "350px" }}
                    placeholder=""
                    value={investmentThesis?.thesis || ""}
                    onChange={answer => {
                      setInvestmentThesis({
                        ...investmentThesis,
                        thesis: answer,
                      });
                    }}
                    modules={eventDescriptionEditorModulesDetailed}
                    formats={eventDescriptionEditorFormatsDetailed}
                  />
                )}
              </Col>
              {thesisType !== "short" && (
                <p
                  className={`mt-2 ${
                    getCharacterRemaining(investmentThesis?.thesis) === 0
                      ? "text-danger"
                      : ""
                  }`}
                >
                  Remaining Characters :{" "}
                  {getCharacterRemaining(investmentThesis?.thesis)}
                </p>
              )}
              {!!investmentThesis?.successMsg ? (
                <>
                  <Col lg={12} className="mb-3 text-success mt-3">
                    {investmentThesis?.successMsg}
                  </Col>
                </>
              ) : (
                <></>
              )}

              {!!investmentThesis?.errorMsg ? (
                <>
                  <Col lg={12} className="mb-3 text-danger mt-2">
                    {investmentThesis?.errorMsg}
                  </Col>
                </>
              ) : (
                <></>
              )}

              <Col sm={12} className="mt-3 d-flex gap-2 justify-content-center">
                {!investmentThesisSaving ? (
                  <Button color="info" onClick={handleSaveInvestmentThesis}>
                    Save
                  </Button>
                ) : (
                  <Button color="info">
                    <Spinner size="sm" color="light" /> Saving...
                  </Button>
                )}
                <Button
                  color="danger"
                  onClick={() => toggleInvestmentThesisModal()}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={toggleUpdateDealStatus}
          toggle={() => toggleUpdateDealStatusBtn()}
          size="lg"
          className="investment-modal"
        >
          <ModalHeader toggle={() => toggleUpdateDealStatusBtn()} tag="h4">
            Investment Terms for{" "}
            {startupInfo?.[0]?.basic_information?.startup_name || ""}
          </ModalHeader>
          <ModalBody>
            <Row>
              <strong>
                <h3>Round Details</h3>
              </strong>
              <Row className="px-4">
                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Round:</strong>
                    </Label>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <div className="relative">
                      <span className="currency-ico">
                        {getCurrencySymbol(
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </span>
                      <Form.Control
                        type="text"
                        name="fundAskTarget"
                        className="form-control text-capitalize pl-2"
                        value={numberWithCommas(fundAskTarget)}
                        onChange={e => {
                          let floatValue = e.target.value || "0";
                          floatValue = parseInt(floatValue.split(",").join(""));
                          handleTarget({ target: { value: floatValue } });
                        }}
                        placeholder=" "
                        onKeyDown={e =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                      <small style={{ opacity: "0.7" }}>
                        {getValueinWords(
                          fundAskTarget,
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </small>
                    </div>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <Select
                      options={fundingRoundOptions}
                      onChange={e => handleSelectedRoundType(e)}
                      value={fundingRoundOptions?.filter(
                        item => item.value === round
                      )}
                    />
                    {/* <select
                      className="form-control"
                      onChange={e => handleSelectedRoundType(e)}
                      // disabled={!getUserRolles?.is_super_admin}
                    >
                      <option
                        value=""
                        selected={[
                          "",
                          null,
                          undefined,
                          "null",
                          "undefined",
                        ].includes(round)}
                      >
                        Select Round Name...
                      </option>
                      {fundingRoundList?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            selected={round === value.value}
                          >
                            {value.value}
                          </option>
                        </>
                      ))}
                    </select> */}
                  </Col>
                  {errors?.round ? (
                    <span style={{ color: "red" }}>{errors?.round}</span>
                  ) : (
                    ""
                  )}
                  {errors?.fundAskTarget ? (
                    <span style={{ color: "red" }}>
                      {errors?.fundAskTarget}
                    </span>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Instrument type:</strong>
                    </Label>
                  </Col>

                  <Col md={6} sm={8} xs={8} className="mt-2 mt-sm-0">
                  <Select
                      options={fundingDealOptions}
                      onChange={e => handleSelectedDealType(e)}
                      value={fundingDealOptions?.filter(
                        item => item.value === dealType
                      )}
                    />
                    {/* <select
                      className="form-control"
                      onChange={e => handleSelectedDealType(e)}
                      //disabled={!getUserRolles?.is_super_admin}
                    >
                      <option
                        value=""
                        selected={[
                          "",
                          null,
                          undefined,
                          "null",
                          "undefined",
                        ].includes(round)}
                      >
                        Select Instrument Type ...
                      </option>
                      {fundingDealTypeList?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            selected={dealType === value.value}
                          >
                            {value.value}
                          </option>
                        </>
                      ))}
                    </select> */}
                  </Col>
                  {errors?.dealType ? (
                    <span style={{ color: "red" }}>{errors?.dealType}</span>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <input
                        type="radio"
                        name="Valuation"
                        onChange={e => setValAmountRadio(true)}
                        checked={valAmountRadio}
                      />
                      <strong> Valuation Amount</strong>&nbsp;
                      <input
                        type="radio"
                        name="Valuation"
                        onChange={e => setValAmountRadio(false)}
                        checked={!valAmountRadio}
                      />
                      <strong> Valuation Floor - Cap</strong>
                    </Label>
                  </Col>
                  {valAmountRadio && (
                    <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                      <div className="relative">
                        <span className="currency-ico">
                          {getCurrencySymbol(
                            selectedInvestmentVehicle?.[0]?.currency
                          )}
                        </span>
                        <Form.Control
                          type="text"
                          name="What_is_your_valuation"
                          className="form-control pl-2"
                          value={numberWithCommas(whatIsYourValuation)}
                          onChange={e => {
                            let floatValue = e.target.value || "0";
                            floatValue = parseInt(
                              floatValue.split(",").join("")
                            );
                            handleWhatIsYourValuation({
                              target: { value: floatValue },
                            });
                          }}
                          placeholder=" "
                          onKeyDown={e =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                        <small style={{ opacity: "0.7" }}>
                          {getValueinWords(
                            whatIsYourValuation,
                            selectedInvestmentVehicle?.[0]?.currency
                          )}
                        </small>
                      </div>
                    </Col>
                  )}
                  {!valAmountRadio && (
                    <>
                      <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                        <div className="relative">
                          <span className="currency-ico">
                            {getCurrencySymbol(
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </span>
                          <Form.Control
                            type="text"
                            name="valuation_floor"
                            className="form-control pl-2"
                            value={numberWithCommas(valuationFloor)}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleValuationFloor({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(
                              valuationFloor,
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </small>
                        </div>
                      </Col>
                      <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                        <div className="relative">
                          <span className="currency-ico">
                            {getCurrencySymbol(
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </span>
                          <Form.Control
                            type="text"
                            name="valuation_cap"
                            className="form-control pl-2"
                            value={numberWithCommas(valuationCap)}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleValuationCap({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(
                              valuationCap,
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </small>
                        </div>
                      </Col>
                    </>
                  )}

                  {errors?.whatIsYourValuation ? (
                    <span style={{ color: "red" }}>
                      {errors?.whatIsYourValuation}
                    </span>
                  ) : (
                    ""
                  )}
                  {errors?.valuationFloor ? (
                    <span style={{ color: "red" }}>
                      {errors?.valuationFloor}
                    </span>
                  ) : (
                    ""
                  )}
                  {errors?.valuationCap ? (
                    <span style={{ color: "red" }}>{errors?.valuationCap}</span>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Valuation Type :</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <Select
                      options={VALUATION_TYPES}
                      onChange={e => handleSelectedValuationType(e)}
                      value={VALUATION_TYPES.filter(
                        item => item.value === valuationType
                      )}
                    />
                    {/* <select
                      className="form-control"
                      onChange={e => handleSelectedValuationType(e)}
                      //disabled={!getUserRolles?.is_super_admin}
                    >
                      {VALUATION_TYPES?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            selected={valuationType === value.value}
                          >
                            {value.label}
                          </option>
                        </>
                      ))}
                    </select> */}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Discount (%)</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <input
                      type="text"
                      //  onWheel={ event => event.currentTarget.blur()}
                      className="form-control text-capitalize"
                      name="Discount"
                      onChange={e => handleDiscount(e)}
                      value={getDiscount}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Month / Year </strong>
                    </Label>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleMonth(e)}
                    >
                      {MONTHS.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.label}
                            selected={getMonth == value.value}
                          >
                            {value.label}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleYears(e)}
                    >
                      {YEARS.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.label}
                            selected={getYears == value.label}
                          >
                            {value.label}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Allocation for Domain Expert</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <div className="relative">
                      <span className="currency-ico">
                        {getCurrencySymbol(
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </span>
                      <Form.Control
                        type="text"
                        name="how_much_are_you_raising"
                        className="form-control pl-2"
                        value={numberWithCommas(howMuchRaising)}
                        onChange={e => {
                          let floatValue = e.target.value || "0";
                          floatValue = parseInt(floatValue.split(",").join(""));
                          handleHowMuchRaising({
                            target: { value: floatValue },
                          });
                        }}
                        placeholder=" "
                        onKeyDown={e =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                      <small style={{ opacity: "0.7" }}>
                        {getValueinWords(
                          howMuchRaising,
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </small>
                    </div>
                  </Col>

                  {errors?.howMuchRaising ? (
                    <span style={{ color: "red" }}>
                      {errors?.howMuchRaising}
                    </span>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>{`Share Price:`}</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <div className="relative">
                      {selectedInvestmentVehicle?.[0]?.currency === "INR" ? (
                        <>
                          <span className="currency-ico">
                            {getCurrencySymbol("INR")}
                          </span>
                          <Form.Control
                            type="text"
                            name="sharePrice"
                            className="form-control pl-2"
                            value={numberWithCommas(sharePrice, "en-IN")}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleSharePrice({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(sharePrice, "INR")}
                          </small>
                        </>
                      ) : (
                        <>
                          <span className="currency-ico">
                            {getCurrencySymbol("USD")}
                          </span>
                          <Form.Control
                            type="text"
                            name="sharePriceUSD"
                            className="form-control pl-2"
                            value={numberWithCommas(sharePriceUSD, "en-US")}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleSharePriceUSD({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(sharePriceUSD, "USD")}
                          </small>
                        </>
                      )}
                    </div>
                  </Col>
                  {errors?.sharePrice && (
                    <span style={{ color: "red" }}>{errors?.sharePrice}</span>
                  )}
                  {errors?.sharePriceUSD && (
                    <span style={{ color: "red" }}>
                      {errors?.sharePriceUSD}
                    </span>
                  )}
                </Row>
              </Row>

              <hr />

              <strong>
                <h3>Fund Details</h3>
              </strong>

              <Row className="px-4">
                <Col sm={6}>
                  <label>
                    <strong>Investment Vehicle: </strong>
                  </label>
                  <br />
                  <Select
                    // onChange={e => handleInvestmentVehicleChange(e)}
                    // isMulti
                    options={
                      investmentVehicleOptions &&
                      investmentVehicleOptions.length > 0 &&
                      investmentVehicleOptions?.filter(el => {
                        let filterValue =
                          directCapCurrency === "INR"
                            ? "Direct Cap-Table USD"
                            : "Direct Cap-Table";
                        if (el?.value !== filterValue) {
                          return true;
                        }
                        return false;
                      })
                    }
                    value={selectedInvestmentVehicle?.[0]}
                    onChange={e => {
                      setSelectedInvestmentVehicle([e]);
                      setCarryOnCapitalGainsNew({});
                      setTransactionFeesForSyndicateNew({
                        [e?.value?.replace(/\s+/g, "")]: "",
                      });
                      if (e?.value?.includes("Direct Cap-Table")) {
                        setCapTableRadio(false);
                      }
                    }}
                    isDisabled={
                      appliedFundingDeal?.current_status !== "Applying/Draft" &&
                      appliedFundingDeal?.current_status !== "Applied For"
                    }
                  ></Select>
                </Col>

                {selectedInvestmentVehicle?.[0]?.label?.includes(
                  "Direct Cap-Table"
                ) && (
                  <Col sm={6} className="mb-3">
                    <label>
                      <strong>Direct Cap-Table Currency: </strong>
                    </label>
                    <br />
                    <Select
                      options={CURRENCY_VALUES}
                      value={CURRENCY_VALUES?.find(
                        el => el?.value === directCapCurrency
                      )}
                      onChange={e => {
                        setDirectCapCurrency(e?.value);
                        if (e?.value === "INR") {
                          setSelectedInvestmentVehicle(
                            investmentVehicleOptions?.filter(
                              el => el?.value === "Direct Cap-Table"
                            )
                          );
                        } else {
                          setSelectedInvestmentVehicle(
                            investmentVehicleOptions?.filter(
                              el => el?.value === "Direct Cap-Table USD"
                            )
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                <>
                  <Col sm={6} className="mb-3">
                    <label>
                      <strong>
                        Min Amount:
                        {/* [
                      {selectedInvestmentVehicle?.length > 0 &&
                        selectedInvestmentVehicle[0]?.value}
                      ]: */}
                      </strong>
                    </label>
                    <div className="d-flex flex-wrap">
                      <div className="col p-1">
                        {/* <label className="d-block">INR</label> */}
                        <div className="relative">
                          {selectedInvestmentVehicle?.[0]?.currency ===
                          "INR" ? (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("INR")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minAmount"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minAmount !== "undefined" ? minAmount : 0,
                                  "en-IN"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinAmount({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minAmount !== "undefined" ? minAmount : 0,
                                  "INR"
                                )}
                              </small>
                            </>
                          ) : (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("USD")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minAmountUsd"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minAmountUsd !== "undefined"
                                    ? minAmountUsd
                                    : 0,
                                  "en-US"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinAmountUsd({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minAmountUsd !== "undefined" ? minAmount : 0,
                                  "USD"
                                )}
                              </small>
                            </>
                          )}
                        </div>
                        {errors?.minAmount && (
                          <span style={{ color: "red" }}>
                            {errors?.minAmount}
                          </span>
                        )}
                        {errors?.minAmountUsd && (
                          <span style={{ color: "red" }}>
                            {errors?.minAmountUsd}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} className="mb-3">
                    <label>
                      <strong>Min Consent for Drawdown:</strong>
                    </label>
                    <div className="d-flex flex-wrap">
                      <div className="col p-1">
                        {/* <label className="d-block">INR</label> */}
                        <div className="relative">
                          {selectedInvestmentVehicle?.[0]?.currency ===
                          "INR" ? (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("INR")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minConsentDrawdown"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minConsentDrawdown !== "undefined"
                                    ? minConsentDrawdown
                                    : 0,
                                  "en-IN"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinConsentForDrawdown({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                disabled={!getUserRolles?.deal_live}
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minConsentDrawdown !== "undefined"
                                    ? minConsentDrawdown
                                    : 0,
                                  "INR"
                                )}
                              </small>
                            </>
                          ) : (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("USD")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minConsentDrawdownUsd"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minConsentDrawdownUsd !== "undefined"
                                    ? minConsentDrawdownUsd
                                    : 0,
                                  "en-US"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinConsentForDrawdownUsd({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minConsentDrawdownUsd !== "undefined"
                                    ? minConsentDrawdownUsd
                                    : 0,
                                  "USD"
                                )}
                              </small>
                            </>
                          )}
                        </div>
                        {errors?.minConsentDrawdown && (
                          <span style={{ color: "red" }}>
                            {errors?.minConsentDrawdown}
                          </span>
                        )}
                        {errors?.minConsentDrawdownUsd && (
                          <span style={{ color: "red" }}>
                            {errors?.minConsentDrawdownUsd}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                  {selectedInvestmentVehicle
                    ? selectedInvestmentVehicle.map(invVhcl => {
                        return (
                          <>
                            <Col lg={6} className="mb-3">
                              <label>
                                <strong>
                                  {invVhcl?.value?.includes("Direct Cap-Table")
                                    ? "Transaction Fees [Direct-Cap Tab (%) + GST]"
                                    : "Management Fees (%) + GST"}
                                  :{/* [{invVhcl.value} (%)]: */}
                                </strong>
                              </label>
                              <input
                                type="number"
                                max={100}
                                id={invVhcl.value.replace(/\s+/g, "")}
                                onWheel={event => event.currentTarget.blur()}
                                className="form-control text-capitalize"
                                name="transactionFeesForSyndicate"
                                onChange={e =>
                                  handleTransactionFeesForSyndicate(e)
                                }
                                value={
                                  transactionFeesForSyndicateNew?.[
                                    invVhcl?.value?.replace(/\s+/g, "")
                                  ]
                                    ? transactionFeesForSyndicateNew?.[
                                        invVhcl?.value?.replace(/\s+/g, "")
                                      ]
                                    : ""
                                }
                              />
                              {errors?.transactionFeesForSyndicateNew?.[
                                invVhcl?.value?.replace(/\s+/g, "")
                              ] && (
                                <span style={{ color: "red" }}>
                                  {
                                    errors?.transactionFeesForSyndicateNew?.[
                                      invVhcl?.value?.replace(/\s+/g, "")
                                    ]
                                  }
                                </span>
                              )}
                            </Col>

                            <Col lg={6} className="mb-3">
                              <label>
                                <strong>
                                  {invVhcl?.value?.includes("Direct Cap-Table")
                                    ? "Exit Documentation Fee [Direct-Cap Tab (%) + GST]"
                                    : "Carry on Capital Gains (%) + GST"}
                                  :
                                  {/* [{invVhcl.value}
                                ]: */}
                                </strong>
                              </label>
                              <input
                                type="number"
                                id={invVhcl.value.replace(/\s+/g, "")}
                                onWheel={event => event.currentTarget.blur()}
                                className="form-control text-capitalize"
                                name="carryOnCapitalGains"
                                onChange={e => handleCarryOnCapitalGains(e)}
                                value={
                                  carryOnCapitalGainsNew?.[
                                    invVhcl?.value?.replace(/\s+/g, "")
                                  ]
                                    ? carryOnCapitalGainsNew?.[
                                        invVhcl?.value?.replace(/\s+/g, "")
                                      ]
                                    : ""
                                }
                              />
                            </Col>
                          </>
                        );
                      })
                    : ""}

                  <Col lg={6} className="mb-3">
                    <label>
                      <strong>
                        Annual Management Fees (%) + GST:
                        {/* [{invVhcl.value} (%)]: */}
                      </strong>
                    </label>
                    <input
                      type="number"
                      max={100}
                      id={"transaction_fees_annual"}
                      onWheel={event => event.currentTarget.blur()}
                      className="form-control text-capitalize"
                      name="transaction_fees_annual"
                      onChange={e => handleAnnualTransactionFeesForSyndicate(e)}
                      value={transaction_fees_annual}
                    />
                    {errors?.transaction_fees_annual && (
                      <span style={{ color: "red" }}>
                        {errors?.transaction_fees_annual}
                      </span>
                    )}
                  </Col>
                </>

                <Row>
                  <Col sm={6} className="mb-3 mt-3">
                    <strong>
                      <h5>Is this on Direct Cap-Table?</h5>
                    </strong>
                  </Col>
                  <Col sm={6} className="mb-3 mt-3">
                    <div className="d-flex gap-5">
                      <div className="d-flex align-items-center mr-5">
                        <input
                          type="radio"
                          name="capTable"
                          className="mx-1"
                          onChange={() => setCapTableRadio(true)}
                          checked={capTableRadio}
                          disabled={selectedInvestmentVehicle?.[0]?.value?.includes(
                            "Direct Cap-Table"
                          )}
                        />
                        Yes
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          name="capTable"
                          className="mx-1"
                          onChange={() => setCapTableRadio(false)}
                          checked={!capTableRadio}
                          disabled={selectedInvestmentVehicle?.[0]?.value?.includes(
                            "Direct Cap-Table"
                          )}
                        />
                        No
                      </div>
                    </div>
                  </Col>

                  {capTableRadio && (
                    <>
                      <Col sm={6} className="mb-3">
                        <label>
                          <strong>Min Amount [Direct-Cap Tab]:</strong>
                        </label>
                        <div className="d-flex flex-wrap">
                          <div className="col p-1">
                            {/* <label className="d-block">INR</label> */}
                            <div className="relative">
                              {selectedInvestmentVehicle?.[0]?.currency ===
                              "INR" ? (
                                <>
                                  <span className="currency-ico">
                                    {getCurrencySymbol("INR")}
                                  </span>
                                  <Form.Control
                                    type="text"
                                    name="minAmountCapTab"
                                    className="form-control pl-2"
                                    value={numberWithCommas(
                                      minAmountCapTab,
                                      "en-IN"
                                    )}
                                    onChange={e => {
                                      let floatValue = e.target.value || "0";
                                      floatValue = parseInt(
                                        floatValue.split(",").join("")
                                      );
                                      handleMinAmountCapTab({
                                        target: { value: floatValue },
                                      });
                                    }}
                                    placeholder=" "
                                    onKeyDown={e =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                  />
                                  <small style={{ opacity: "0.7" }}>
                                    {getValueinWords(minAmountCapTab, "INR")}
                                  </small>
                                </>
                              ) : (
                                <>
                                  <span className="currency-ico">
                                    {getCurrencySymbol("USD")}
                                  </span>
                                  <Form.Control
                                    type="text"
                                    name="minAmountCapTabUsd"
                                    className="form-control pl-2"
                                    value={numberWithCommas(
                                      minAmountCapTabUsd,
                                      "en-US"
                                    )}
                                    onChange={e => {
                                      let floatValue = e.target.value || "0";
                                      floatValue = parseInt(
                                        floatValue.split(",").join("")
                                      );
                                      handleMinAmountCapTabUsd({
                                        target: { value: floatValue },
                                      });
                                    }}
                                    placeholder=" "
                                    onKeyDown={e =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                  />
                                  <small style={{ opacity: "0.7" }}>
                                    {getValueinWords(minAmountCapTabUsd, "USD")}
                                  </small>
                                </>
                              )}
                            </div>
                            {errors?.minAmountCapTab && (
                              <span style={{ color: "red" }}>
                                {errors?.minAmountCapTab}
                              </span>
                            )}
                            {errors?.minAmountCapTabUsd && (
                              <span style={{ color: "red" }}>
                                {errors?.minAmountCapTabUsd}
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} className="mb-3">
                        <label>
                          <strong>
                            Transaction Fees[Direct-Cap Tab (%) + GST]:
                          </strong>
                        </label>
                        <input
                          type="number"
                          // onWheel={ event => event.currentTarget.blur()}
                          className="form-control text-capitalize"
                          name="transactionFeesForSyndicate"
                          onChange={e => handleTransactionFeesForCapTab(e)}
                          value={transactionFeesForCapTab}
                        />
                        {errors?.transactionFeesForCapTab && (
                          <span style={{ color: "red" }}>
                            {errors?.transactionFeesForCapTab}
                          </span>
                        )}
                      </Col>

                      <Col lg={6} className="mb-3">
                        <label>
                          <strong>
                            Exit Documentation Fee[Direct-Cap Tab (%) + GST]:
                          </strong>
                        </label>
                        <input
                          type="number"
                          className="form-control text-capitalize"
                          name="exit_documentation_fees_captab"
                          onChange={e =>
                            handleExitDocumentationFeesForCapTab(e)
                          }
                          value={exit_documentation_fees_captab}
                        />
                        {errors?.exit_documentation_fees_captab && (
                          <span style={{ color: "red" }}>
                            {errors?.exit_documentation_fees_captab}
                          </span>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </Row>

              <hr />

              <strong>
                <h3>General Details</h3>
              </strong>

              <Row className="px-4">
                <Col lg={12} className="mb-3">
                  <label>
                    <strong>Existing Commitments :</strong>
                  </label>
                  {existingCommmitment?.length ? (
                    <>
                      <Row>
                        <Col lg={3}>
                          <label>
                            <span style={{ fontWeight: 500 }}>
                              Investor Name :
                            </span>
                          </label>
                        </Col>
                        <Col lg={3}>
                          <label>
                            <span style={{ fontWeight: 500 }}>
                              Committed Amount :
                            </span>
                          </label>
                        </Col>
                        <Col lg={3}>
                          <label>
                            <span style={{ fontWeight: 500 }}>
                              Is Lead Investor :
                            </span>
                          </label>
                        </Col>
                        <Col lg={2}>
                          <label>
                            <span style={{ fontWeight: 500 }}>Comment :</span>
                          </label>
                        </Col>
                        <Col lg={1}>
                          <label>
                            <span style={{ fontWeight: 500 }}>Actions</span>
                          </label>
                        </Col>
                      </Row>
                      {existingCommmitment?.length &&
                        existingCommmitment?.map((items, i) => {
                          return (
                            <Row>
                              <Col lg={3} className="mb-3">
                                <input
                                  type="text"
                                  className="form-control text-capitalize"
                                  name="Investor Name"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "investorName",
                                      i
                                    )
                                  }
                                  value={items?.investorName}
                                />
                                {i === existingCommmitment?.length - 1 && (
                                  <span style={{ color: "red" }}>
                                    {existingCommitmentErrors?.investorName ??
                                      ""}
                                  </span>
                                )}
                              </Col>
                              <Col lg={3} className="mb-3">
                                <input
                                  type="number"
                                  onWheel={event => event.currentTarget.blur()}
                                  className="form-control text-capitalize"
                                  name="Committed Amount"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "committedAmount",
                                      i
                                    )
                                  }
                                  value={items?.committedAmount}
                                />
                                {i === existingCommmitment?.length - 1 && (
                                  <span style={{ color: "red" }}>
                                    {existingCommitmentErrors?.committedAmount ??
                                      ""}
                                  </span>
                                )}
                              </Col>

                              <Col lg={3} className="mb-3">
                                <select
                                  className="form-control"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "isLeadInvestor",
                                      i
                                    )
                                  }
                                >
                                  <option value="">--Select Options--</option>
                                  {IS_HE_SHE_LEAD_INVESTOR.map((value, key) => (
                                    <>
                                      <option
                                        key={key}
                                        value={value.label}
                                        selected={
                                          items?.isLeadInvestor == value.label
                                        }
                                      >
                                        {value.label}
                                      </option>
                                    </>
                                  ))}
                                </select>
                              </Col>
                              <Col lg={2} className="mb-3">
                                <input
                                  type="text"
                                  className="form-control text-capitalize"
                                  name="Comment"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "comment",
                                      i
                                    )
                                  }
                                  value={items?.comment}
                                />
                              </Col>
                              <Col lg={1} className="mb-3 d-flex">
                                <i
                                  className="mdi commitment-delete mdi-plus-circle-outline"
                                  id="mdi-plus-circle-outline"
                                  onClick={() => addedExistingCommitmentData(i)}
                                ></i>
                                <i
                                  className="mdi commitment-delete mdi-delete"
                                  id="deletetooltip"
                                  onClick={() => handleDeleteCommitment(i)}
                                ></i>
                              </Col>
                            </Row>
                          );
                        })}
                    </>
                  ) : (
                    " -"
                  )}
                  {errors?.existingCommmitment && (
                    <span style={{ color: "red" }}>
                      {errors?.existingCommmitment}
                    </span>
                  )}
                </Col>
                <Col lg={12} className="mb-3 align-items-center">
                  <Label>
                    <strong>Additional Notes</strong>
                  </Label>
                  <textarea
                    className="form-control text-capitalize"
                    name="Remarks"
                    onChange={e => handleRemarks(e)}
                    value={getRemarks}
                  />
                </Col>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>PAN of the Startup:</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <input
                      type="text"
                      className="form-control"
                      name="schemeName"
                      value={pan_number}
                      onChange={e => setPanNumber(e.target.value)}
                      title="PAN Number can be updated in My Startup."
                      disabled={
                        !isEmpty(
                          startupInfo?.[0]?.basic_information?.pan_number
                        )
                      }
                    />
                  </Col>
                  {errors?.pan_number && (
                    <span style={{ color: "red" }}>{errors?.pan_number}</span>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Deal Manager: </strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleDealManagerChange(e)}
                    >
                      <option value="">--Select Deal Manager--</option>
                      {dealManagers &&
                        dealManagers.length > 0 &&
                        dealManagers.map((val, key) => (
                          <option
                            key={key}
                            value={val._id}
                            selected={dealManagerId === val._id}
                          >
                            {" "}
                            {val.name}
                          </option>
                        ))}
                    </select>
                  </Col>
                  {errors?.dealManagerId && (
                    <span style={{ color: "red" }}>
                      {errors?.dealManagerId}
                    </span>
                  )}
                </Row>

                <Row className="mb-3 ">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Scheme Name:</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <input
                      type="text"
                      className="form-control"
                      name="schemeName"
                      onChange={e => handleSchemeName(e)}
                      value={schemeName}
                    />
                  </Col>
                  {errors?.schemeName && (
                    <span style={{ color: "red" }}>{errors?.schemeName}</span>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Status: </strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleSelectedStatus(e)}
                    >
                      {/* <option value="">{dealStatus?.length!=0?dealStatus:"AppliedFor"}</option> */}
                      {fundingDealStatusList.map(
                        (value, key) =>
                          ((getUserRolles?.applying == true &&
                            value.label == "ApplyingDraft") ||
                            (getUserRolles?.applied_for == true &&
                              value.label == "AppliedFor") ||
                            (getUserRolles?.deal_live == true &&
                              value.label == "DealLive") ||
                            (getUserRolles?.deal_rejected == true &&
                              value.label == "DealRejected") ||
                            (getUserRolles?.closed_internally_funded == true &&
                              value.label == "Closed-InternallyFunded") ||
                            (getUserRolles?.closed_externally_funded == true &&
                              value.label == "Closed-ExternallyFunded")) && (
                            <>
                              <option
                                key={key}
                                value={value.label}
                                selected={
                                  appliedFundingDeal?.current_status ===
                                  value.value
                                }
                              >
                                {value.value}
                              </option>
                            </>
                          )
                      )}
                    </select>
                  </Col>
                </Row>

                {getUserRolles?.update_term_sheet_status === true && (
                  <Row className="mb-3">
                    <Col
                      md={6}
                      sm={4}
                      xs={4}
                      className="mt-2 mt-sm-0 d-flex align-items-center"
                    >
                      <Label className="mb-0">
                        <strong>Update Term Sheet: </strong>
                      </Label>
                    </Col>
                    <Col
                      md={
                        selectedTermSheetStatus === "received_from_sebi" ? 3 : 6
                      }
                      sm={4}
                      xs={4}
                      className="mb-3"
                    >
                      <Label>
                        <strong>Term Sheet Status:</strong>
                      </Label>
                      <Select
                        isDisabled={
                          appliedFundingDeal?.term_sheet_status ===
                          "received_from_sebi"
                            ? true
                            : false
                        }
                        title={"Select Term Status"}
                        placeholder={"Select Term Status"}
                        name="term_sheet_status"
                        options={term_sheet_status_options}
                        onChange={e => handleSelectedTermSheetStatus(e)}
                        value={term_sheet_status_options.filter(
                          item => item.value === selectedTermSheetStatus
                        )}
                      />
                    </Col>
                    {selectedTermSheetStatus === "received_from_sebi" ? (
                      <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                        <Label>
                          <strong>Term sheet Pdf :</strong>
                        </Label>
                        <input
                          type="file"
                          className="form-control"
                          name="term_sheet_file"
                          accept=".pdf,.doc,.docx"
                          onChange={handleTermsheetUpload}
                        />
                        {appliedFundingDeal?.term_sheet_file !== undefined &&
                        appliedFundingDeal?.term_sheet_file !== null &&
                        appliedFundingDeal?.term_sheet_file !== "" ? (
                          <a
                            target="_blank"
                            href={`${process.env.REACT_APP_API_URL}/${appliedFundingDeal.term_sheet_file}`}
                          >
                            View Uploaded Termsheet
                          </a>
                        ) : (
                          ""
                        )}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                )}
              </Row>

              {/* )} */}
              <Row>
                <Col sm={12}>
                  {UpdateFundingDealStatusSuccess && (
                    <Alert color="success">
                      Funding deal status successfully updated.
                    </Alert>
                  )}
                </Col>
                <Col sm={12}>
                  {UpdateFundingDealStatusError && (
                    <Alert color="danger">
                      Deal NOT made LIVE as startup status is not Enabled as yet
                      !!
                    </Alert>
                  )}
                </Col>
              </Row>
              <Col sm={12} className="mt-3">
                {!updateClicked ? (
                  <Button
                    onClick={() => {
                      if (
                        startupInfo?.[0]?.business_information
                          ?.company_incorporation_location === "India" &&
                        startupInfo?.[0]?.currency?.value !== "INR"
                      ) {
                        setCountryMismatch(true);
                        setUpdateType("current");
                        return;
                      } else {
                        updateFundingDealStatus();
                      }
                    }}
                  >
                    Update Status
                  </Button>
                ) : (
                  <Button>Updating...</Button>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={toggleCountryMismatch}
          toggle={() => setCountryMismatch(!toggleCountryMismatch)}
          size="md"
          className="attention-modal"
        >
          <ModalHeader
            toggle={() => setCountryMismatch(!toggleCountryMismatch)}
            tag="h4"
          >
            Attention
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Startup Country-Currency mismatch detected.
              <br /> Do you still want to continue?
            </p>
            <div className="d-flex justify-content-center gap-4">
              <Button
                className="btn-outline-primary"
                onClick={() => {
                  if (updateType === "current") {
                    updateFundingDealStatus();
                  } else if (updateType === "copy") {
                    copyFundingDealStatus();
                  }
                  setCountryMismatch(false);
                }}
              >
                Yes
              </Button>
              <Button
                className="btn-outline-primary"
                onClick={() => setCountryMismatch(false)}
              >
                No
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={toggleCopyDealStatus}
          toggle={() => toggleCopyDealStatusBtn()}
          size="lg"
          className="investment-modal"
        >
          <ModalHeader toggle={() => toggleCopyDealStatusBtn()} tag="h4">
            Copy Funding Deal
          </ModalHeader>
          <ModalBody>
            <Row>
              <strong>
                <h3>Round Details</h3>
              </strong>
              <Row className="px-4">
                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Select Syndicate:</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      id="select"
                      placeholder="Select"
                      // option={optionsSaas}
                      onChange={e => handleSyndicate(e)}
                      // value={selectSaas}
                    >
                      {optionsSaas?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            label={value.label}
                            // selected={selectSaas === value.value}
                          >
                            {/* {value.label} */}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                  {errors?.selectSaas && (
                    <span style={{ color: "red" }}>{errors?.selectSaas}</span>
                  )}
                </Row>
                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Round:</strong>
                    </Label>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <div className="relative">
                      <span className="currency-ico">
                        {getCurrencySymbol(
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </span>
                      <Form.Control
                        type="text"
                        name="fundAskTarget"
                        className="form-control text-capitalize pl-2"
                        value={numberWithCommas(fundAskTarget)}
                        onChange={e => {
                          let floatValue = e.target.value || "0";
                          floatValue = parseInt(floatValue.split(",").join(""));
                          handleTarget({ target: { value: floatValue } });
                        }}
                        placeholder=" "
                        onKeyDown={e =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        disabled={true}
                      />
                      <small style={{ opacity: "0.7" }}>
                        {getValueinWords(
                          fundAskTarget,
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </small>
                    </div>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleSelectedRoundType(e)}
                      disabled={true}
                    >
                      <option
                        value=""
                        selected={[
                          "",
                          null,
                          undefined,
                          "null",
                          "undefined",
                        ].includes(round)}
                      >
                        Select Round Name...
                      </option>
                      {fundingRoundList?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            selected={round === value.value}
                          >
                            {value.value}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                  {errors?.round ? (
                    <span style={{ color: "red" }}>{errors?.round}</span>
                  ) : (
                    ""
                  )}
                  {errors?.fundAskTarget ? (
                    <span style={{ color: "red" }}>
                      {errors?.fundAskTarget}
                    </span>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mb-3 ">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Instrument type:</strong>
                    </Label>
                  </Col>

                  <Col md={6} sm={8} xs={8} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleSelectedDealType(e)}
                      disabled={true}
                    >
                      <option
                        value=""
                        selected={[
                          "",
                          null,
                          undefined,
                          "null",
                          "undefined",
                        ].includes(round)}
                      >
                        Select Instrument Type ...
                      </option>
                      {fundingDealTypeList?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            selected={dealType === value.value}
                          >
                            {value.value}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                  {errors?.dealType ? (
                    <span style={{ color: "red" }}>{errors?.dealType}</span>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <input
                        type="radio"
                        name="Valuation"
                        onChange={e => setValAmountRadio(true)}
                        checked={valAmountRadio}
                      />
                      <strong> Valuation Amount</strong>&nbsp;
                      <input
                        type="radio"
                        name="Valuation"
                        onChange={e => setValAmountRadio(false)}
                        checked={!valAmountRadio}
                      />
                      <strong> Valuation Floor - Cap</strong>
                    </Label>
                  </Col>
                  {valAmountRadio && (
                    <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                      <div className="relative">
                        <span className="currency-ico">
                          {getCurrencySymbol(
                            selectedInvestmentVehicle?.[0]?.currency
                          )}
                        </span>
                        <Form.Control
                          type="text"
                          name="What_is_your_valuation"
                          className="form-control pl-2"
                          value={numberWithCommas(whatIsYourValuation)}
                          onChange={e => {
                            let floatValue = e.target.value || "0";
                            floatValue = parseInt(
                              floatValue.split(",").join("")
                            );
                            handleWhatIsYourValuation({
                              target: { value: floatValue },
                            });
                          }}
                          placeholder=" "
                          onKeyDown={e =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          disabled={true}
                        />
                        <small style={{ opacity: "0.7" }}>
                          {getValueinWords(
                            whatIsYourValuation,
                            selectedInvestmentVehicle?.[0]?.currency
                          )}
                        </small>
                      </div>
                    </Col>
                  )}
                  {!valAmountRadio && (
                    <>
                      <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                        <div className="relative">
                          <span className="currency-ico">
                            {getCurrencySymbol(
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </span>
                          <Form.Control
                            type="text"
                            name="valuation_floor"
                            className="form-control pl-2"
                            value={numberWithCommas(valuationFloor)}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleValuationFloor({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(
                              valuationFloor,
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </small>
                        </div>
                      </Col>
                      <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                        <div className="relative">
                          <span className="currency-ico">
                            {getCurrencySymbol(
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </span>
                          <Form.Control
                            type="text"
                            name="valuation_cap"
                            className="form-control pl-2"
                            value={numberWithCommas(valuationCap)}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleValuationCap({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            disabled={true}
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(
                              valuationCap,
                              selectedInvestmentVehicle?.[0]?.currency
                            )}
                          </small>
                        </div>
                      </Col>
                    </>
                  )}

                  {errors?.whatIsYourValuation ? (
                    <span style={{ color: "red" }}>
                      {errors?.whatIsYourValuation}
                    </span>
                  ) : (
                    ""
                  )}
                  {errors?.valuationFloor ? (
                    <span style={{ color: "red" }}>
                      {errors?.valuationFloor}
                    </span>
                  ) : (
                    ""
                  )}
                  {errors?.valuationCap ? (
                    <span style={{ color: "red" }}>{errors?.valuationCap}</span>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Valuation Type :</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleSelectedValuationType(e)}
                      disabled={true}
                    >
                      {VALUATION_TYPES?.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.value}
                            selected={valuationType === value.value}
                          >
                            {value.label}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Discount (%)</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <input
                      type="text"
                      //  onWheel={ event => event.currentTarget.blur()}
                      className="form-control text-capitalize"
                      name="Discount"
                      onChange={e => handleDiscount(e)}
                      value={getDiscount}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Month / Year </strong>
                    </Label>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleMonth(e)}
                    >
                      {MONTHS.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.label}
                            selected={getMonth == value.value}
                          >
                            {value.label}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                  <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleYears(e)}
                    >
                      {YEARS.map((value, key) => (
                        <>
                          <option
                            key={key}
                            value={value.label}
                            selected={getYears == value.label}
                          >
                            {value.label}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Allocation for Domain Expert</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <div className="relative">
                      <span className="currency-ico">
                        {getCurrencySymbol(
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </span>
                      <Form.Control
                        type="text"
                        name="how_much_are_you_raising"
                        className="form-control pl-2"
                        value={numberWithCommas(howMuchRaising)}
                        onChange={e => {
                          let floatValue = e.target.value || "0";
                          floatValue = parseInt(floatValue.split(",").join(""));
                          handleHowMuchRaising({
                            target: { value: floatValue },
                          });
                        }}
                        placeholder=" "
                        onKeyDown={e =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                      <small style={{ opacity: "0.7" }}>
                        {getValueinWords(
                          howMuchRaising,
                          selectedInvestmentVehicle?.[0]?.currency
                        )}
                      </small>
                    </div>
                  </Col>

                  {errors?.howMuchRaising ? (
                    <span style={{ color: "red" }}>
                      {errors?.howMuchRaising}
                    </span>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>{`Share Price:`}</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <div className="relative">
                      {selectedInvestmentVehicle?.[0]?.currency === "INR" ? (
                        <>
                          <span className="currency-ico">
                            {getCurrencySymbol("INR")}
                          </span>
                          <Form.Control
                            type="text"
                            name="sharePrice"
                            className="form-control pl-2"
                            value={numberWithCommas(sharePrice, "en-IN")}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleSharePrice({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(sharePrice, "INR")}
                          </small>
                        </>
                      ) : (
                        <>
                          <span className="currency-ico">
                            {getCurrencySymbol("USD")}
                          </span>
                          <Form.Control
                            type="text"
                            name="sharePriceUSD"
                            className="form-control pl-2"
                            value={numberWithCommas(sharePriceUSD, "en-US")}
                            onChange={e => {
                              let floatValue = e.target.value || "0";
                              floatValue = parseInt(
                                floatValue.split(",").join("")
                              );
                              handleSharePriceUSD({
                                target: { value: floatValue },
                              });
                            }}
                            placeholder=" "
                            onKeyDown={e =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <small style={{ opacity: "0.7" }}>
                            {getValueinWords(sharePriceUSD, "USD")}
                          </small>
                        </>
                      )}
                    </div>
                  </Col>
                  {errors?.sharePrice && (
                    <span style={{ color: "red" }}>{errors?.sharePrice}</span>
                  )}
                  {errors?.sharePriceUSD && (
                    <span style={{ color: "red" }}>
                      {errors?.sharePriceUSD}
                    </span>
                  )}
                </Row>
              </Row>

              <hr />

              <strong>
                <h3>Fund Details</h3>
              </strong>

              <Row className="px-4">
                <Col sm={6}>
                  <label>
                    <strong>Investment Vehicle: </strong>
                  </label>
                  <br />
                  <Select
                    // onChange={e => handleInvestmentVehicleChange(e)}
                    // isMulti
                    options={
                      investmentVehicleOptions &&
                      investmentVehicleOptions.length > 0 &&
                      investmentVehicleOptions?.filter(el => {
                        let filterValue =
                          directCapCurrency === "INR"
                            ? "Direct Cap-Table USD"
                            : "Direct Cap-Table";
                        if (el?.value !== filterValue) {
                          return true;
                        }
                        return false;
                      })
                    }
                    value={selectedInvestmentVehicle?.[0]}
                    onChange={e => {
                      setSelectedInvestmentVehicle([e]);
                      setCarryOnCapitalGainsNew({});
                      setTransactionFeesForSyndicateNew({
                        [e?.value?.replace(/\s+/g, "")]: "",
                      });
                      if (e?.value?.includes("Direct Cap-Table")) {
                        setCapTableRadio(false);
                      }
                    }}
                  ></Select>
                </Col>

                {selectedInvestmentVehicle?.[0]?.label?.includes(
                  "Direct Cap-Table"
                ) && (
                  <Col sm={6} className="mb-3">
                    <label>
                      <strong>Direct Cap-Table Currency: </strong>
                    </label>
                    <br />
                    <Select
                      options={CURRENCY_VALUES}
                      value={CURRENCY_VALUES?.find(
                        el => el?.value === directCapCurrency
                      )}
                      onChange={e => {
                        setDirectCapCurrency(e?.value);
                        if (e?.value === "INR") {
                          setSelectedInvestmentVehicle(
                            investmentVehicleOptions?.filter(
                              el => el?.value === "Direct Cap-Table"
                            )
                          );
                        } else {
                          setSelectedInvestmentVehicle(
                            investmentVehicleOptions?.filter(
                              el => el?.value === "Direct Cap-Table USD"
                            )
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                <>
                  <Col sm={6} className="mb-3">
                    <label>
                      <strong>
                        Min Amount:
                        {/* [
                      {selectedInvestmentVehicle?.length > 0 &&
                        selectedInvestmentVehicle[0]?.value}
                      ]: */}
                      </strong>
                    </label>
                    <div className="d-flex flex-wrap">
                      <div className="col p-1">
                        {/* <label className="d-block">INR</label> */}
                        <div className="relative">
                          {selectedInvestmentVehicle?.[0]?.currency ===
                          "INR" ? (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("INR")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minAmount"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minAmount !== "undefined" ? minAmount : 0,
                                  "en-IN"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinAmount({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minAmount !== "undefined" ? minAmount : 0,
                                  "INR"
                                )}
                              </small>
                            </>
                          ) : (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("USD")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minAmountUsd"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minAmountUsd !== "undefined"
                                    ? minAmountUsd
                                    : 0,
                                  "en-US"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinAmountUsd({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minAmountUsd !== "undefined" ? minAmount : 0,
                                  "USD"
                                )}
                              </small>
                            </>
                          )}
                        </div>
                        {errors?.minAmount && (
                          <span style={{ color: "red" }}>
                            {errors?.minAmount}
                          </span>
                        )}
                        {errors?.minAmountUsd && (
                          <span style={{ color: "red" }}>
                            {errors?.minAmountUsd}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} className="mb-3">
                    <label>
                      <strong>Min Consent for Drawdown:</strong>
                    </label>
                    <div className="d-flex flex-wrap">
                      <div className="col p-1">
                        {/* <label className="d-block">INR</label> */}
                        <div className="relative">
                          {selectedInvestmentVehicle?.[0]?.currency ===
                          "INR" ? (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("INR")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minConsentDrawdown"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minConsentDrawdown !== "undefined"
                                    ? minConsentDrawdown
                                    : 0,
                                  "en-IN"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinConsentForDrawdown({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                disabled={!getUserRolles?.deal_live}
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minConsentDrawdown !== "undefined"
                                    ? minConsentDrawdown
                                    : 0,
                                  "INR"
                                )}
                              </small>
                            </>
                          ) : (
                            <>
                              <span className="currency-ico">
                                {getCurrencySymbol("USD")}
                              </span>
                              <Form.Control
                                type="text"
                                name="minConsentDrawdownUsd"
                                className="form-control pl-2"
                                value={numberWithCommas(
                                  minConsentDrawdownUsd !== "undefined"
                                    ? minConsentDrawdownUsd
                                    : 0,
                                  "en-US"
                                )}
                                onChange={e => {
                                  let floatValue = e.target.value || "0";
                                  floatValue = parseInt(
                                    floatValue.split(",").join("")
                                  );
                                  handleMinConsentForDrawdownUsd({
                                    target: { value: floatValue },
                                  });
                                }}
                                placeholder=" "
                                onKeyDown={e =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              <small style={{ opacity: "0.7" }}>
                                {getValueinWords(
                                  minConsentDrawdownUsd !== "undefined"
                                    ? minConsentDrawdownUsd
                                    : 0,
                                  "USD"
                                )}
                              </small>
                            </>
                          )}
                        </div>
                        {errors?.minConsentDrawdown && (
                          <span style={{ color: "red" }}>
                            {errors?.minConsentDrawdown}
                          </span>
                        )}
                        {errors?.minConsentDrawdownUsd && (
                          <span style={{ color: "red" }}>
                            {errors?.minConsentDrawdownUsd}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                  {selectedInvestmentVehicle
                    ? selectedInvestmentVehicle.map(invVhcl => {
                        return (
                          <>
                            <Col lg={6} className="mb-3">
                              <label>
                                <strong>
                                  {invVhcl?.value?.includes("Direct Cap-Table")
                                    ? "Transaction Fees [Direct-Cap Tab (%) + GST]"
                                    : "Management Fees (%) + GST"}
                                  :{/* [{invVhcl.value} (%)]: */}
                                </strong>
                              </label>
                              <input
                                type="number"
                                max={100}
                                id={invVhcl.value.replace(/\s+/g, "")}
                                onWheel={event => event.currentTarget.blur()}
                                className="form-control text-capitalize"
                                name="transactionFeesForSyndicate"
                                onChange={e =>
                                  handleTransactionFeesForSyndicate(e)
                                }
                                value={
                                  transactionFeesForSyndicateNew?.[
                                    invVhcl?.value?.replace(/\s+/g, "")
                                  ]
                                    ? transactionFeesForSyndicateNew?.[
                                        invVhcl?.value?.replace(/\s+/g, "")
                                      ]
                                    : ""
                                }
                              />
                              {errors?.transactionFeesForSyndicateNew?.[
                                invVhcl?.value?.replace(/\s+/g, "")
                              ] && (
                                <span style={{ color: "red" }}>
                                  {
                                    errors?.transactionFeesForSyndicateNew?.[
                                      invVhcl?.value?.replace(/\s+/g, "")
                                    ]
                                  }
                                </span>
                              )}
                            </Col>

                            <Col lg={6} className="mb-3">
                              <label>
                                <strong>
                                  {invVhcl?.value?.includes("Direct Cap-Table")
                                    ? "Exit Documentation Fee [Direct-Cap Tab (%) + GST]"
                                    : "Carry on Capital Gains (%) + GST"}
                                  :
                                  {/* [{invVhcl.value}
                                ]: */}
                                </strong>
                              </label>
                              <input
                                type="number"
                                id={invVhcl.value.replace(/\s+/g, "")}
                                onWheel={event => event.currentTarget.blur()}
                                className="form-control text-capitalize"
                                name="carryOnCapitalGains"
                                onChange={e => handleCarryOnCapitalGains(e)}
                                value={
                                  carryOnCapitalGainsNew?.[
                                    invVhcl?.value?.replace(/\s+/g, "")
                                  ]
                                    ? carryOnCapitalGainsNew?.[
                                        invVhcl?.value?.replace(/\s+/g, "")
                                      ]
                                    : ""
                                }
                              />
                            </Col>
                          </>
                        );
                      })
                    : ""}

                  <Col lg={6} className="mb-3">
                    <label>
                      <strong>
                        Annual Management Fees (%) + GST:
                        {/* [{invVhcl.value} (%)]: */}
                      </strong>
                    </label>
                    <input
                      type="number"
                      max={100}
                      id={"transaction_fees_annual"}
                      onWheel={event => event.currentTarget.blur()}
                      className="form-control text-capitalize"
                      name="transaction_fees_annual"
                      onChange={e => handleAnnualTransactionFeesForSyndicate(e)}
                      value={transaction_fees_annual}
                    />
                    {errors?.transaction_fees_annual && (
                      <span style={{ color: "red" }}>
                        {errors?.transaction_fees_annual}
                      </span>
                    )}
                  </Col>
                </>

                <Row>
                  <Col sm={6} className="mb-3 mt-3">
                    <strong>
                      <h5>Is this on Direct Cap-Table?</h5>
                    </strong>
                  </Col>
                  <Col sm={6} className="mb-3 mt-3">
                    <div className="d-flex gap-5">
                      <div className="d-flex align-items-center mr-5">
                        <input
                          type="radio"
                          name="capTable"
                          className="mx-1"
                          onChange={() => setCapTableRadio(true)}
                          checked={capTableRadio}
                          disabled={selectedInvestmentVehicle?.[0]?.value?.includes(
                            "Direct Cap-Table"
                          )}
                        />
                        Yes
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          name="capTable"
                          className="mx-1"
                          onChange={() => setCapTableRadio(false)}
                          checked={!capTableRadio}
                          disabled={selectedInvestmentVehicle?.[0]?.value?.includes(
                            "Direct Cap-Table"
                          )}
                        />
                        No
                      </div>
                    </div>
                  </Col>

                  {capTableRadio && (
                    <>
                      <Col sm={6} className="mb-3">
                        <label>
                          <strong>Min Amount [Direct-Cap Tab]:</strong>
                        </label>
                        <div className="d-flex flex-wrap">
                          <div className="col p-1">
                            {/* <label className="d-block">INR</label> */}
                            <div className="relative">
                              {selectedInvestmentVehicle?.[0]?.currency ===
                              "INR" ? (
                                <>
                                  <span className="currency-ico">
                                    {getCurrencySymbol("INR")}
                                  </span>
                                  <Form.Control
                                    type="text"
                                    name="minAmountCapTab"
                                    className="form-control pl-2"
                                    value={numberWithCommas(
                                      minAmountCapTab,
                                      "en-IN"
                                    )}
                                    onChange={e => {
                                      let floatValue = e.target.value || "0";
                                      floatValue = parseInt(
                                        floatValue.split(",").join("")
                                      );
                                      handleMinAmountCapTab({
                                        target: { value: floatValue },
                                      });
                                    }}
                                    placeholder=" "
                                    onKeyDown={e =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                  />
                                  <small style={{ opacity: "0.7" }}>
                                    {getValueinWords(minAmountCapTab, "INR")}
                                  </small>
                                </>
                              ) : (
                                <>
                                  <span className="currency-ico">
                                    {getCurrencySymbol("USD")}
                                  </span>
                                  <Form.Control
                                    type="text"
                                    name="minAmountCapTabUsd"
                                    className="form-control pl-2"
                                    value={numberWithCommas(
                                      minAmountCapTabUsd,
                                      "en-US"
                                    )}
                                    onChange={e => {
                                      let floatValue = e.target.value || "0";
                                      floatValue = parseInt(
                                        floatValue.split(",").join("")
                                      );
                                      handleMinAmountCapTabUsd({
                                        target: { value: floatValue },
                                      });
                                    }}
                                    placeholder=" "
                                    onKeyDown={e =>
                                      exceptThisSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                  />
                                  <small style={{ opacity: "0.7" }}>
                                    {getValueinWords(minAmountCapTabUsd, "USD")}
                                  </small>
                                </>
                              )}
                            </div>
                            {errors?.minAmountCapTab && (
                              <span style={{ color: "red" }}>
                                {errors?.minAmountCapTab}
                              </span>
                            )}
                            {errors?.minAmountCapTabUsd && (
                              <span style={{ color: "red" }}>
                                {errors?.minAmountCapTabUsd}
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} className="mb-3">
                        <label>
                          <strong>
                            Transaction Fees[Direct-Cap Tab (%) + GST]:
                          </strong>
                        </label>
                        <input
                          type="number"
                          // onWheel={ event => event.currentTarget.blur()}
                          className="form-control text-capitalize"
                          name="transactionFeesForSyndicate"
                          onChange={e => handleTransactionFeesForCapTab(e)}
                          value={transactionFeesForCapTab}
                        />
                        {errors?.transactionFeesForCapTab && (
                          <span style={{ color: "red" }}>
                            {errors?.transactionFeesForCapTab}
                          </span>
                        )}
                      </Col>

                      <Col lg={6} className="mb-3">
                        <label>
                          <strong>
                            Exit Documentation Fee[Direct-Cap Tab (%) + GST]:
                          </strong>
                        </label>
                        <input
                          type="number"
                          className="form-control text-capitalize"
                          name="exit_documentation_fees_captab"
                          onChange={e =>
                            handleExitDocumentationFeesForCapTab(e)
                          }
                          value={exit_documentation_fees_captab}
                        />
                        {errors?.exit_documentation_fees_captab && (
                          <span style={{ color: "red" }}>
                            {errors?.exit_documentation_fees_captab}
                          </span>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </Row>

              <hr />

              <strong>
                <h3>General Details</h3>
              </strong>

              <Row className="px-4">
                <Col lg={12} className="mb-3">
                  <label>
                    <strong>Existing Commitments :</strong>
                  </label>
                  {existingCommmitment?.length ? (
                    <>
                      <Row>
                        <Col lg={3}>
                          <label>
                            <span style={{ fontWeight: 500 }}>
                              Investor Name :
                            </span>
                          </label>
                        </Col>
                        <Col lg={3}>
                          <label>
                            <span style={{ fontWeight: 500 }}>
                              Committed Amount :
                            </span>
                          </label>
                        </Col>
                        <Col lg={3}>
                          <label>
                            <span style={{ fontWeight: 500 }}>
                              Is Lead Investor :
                            </span>
                          </label>
                        </Col>
                        <Col lg={2}>
                          <label>
                            <span style={{ fontWeight: 500 }}>Comment :</span>
                          </label>
                        </Col>
                        <Col lg={1}>
                          <label>
                            <span style={{ fontWeight: 500 }}>Actions</span>
                          </label>
                        </Col>
                      </Row>
                      {existingCommmitment?.length &&
                        existingCommmitment?.map((items, i) => {
                          return (
                            <Row>
                              <Col lg={3} className="mb-3">
                                <input
                                  type="text"
                                  className="form-control text-capitalize"
                                  name="Investor Name"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "investorName",
                                      i
                                    )
                                  }
                                  value={items?.investorName}
                                />
                                {i === existingCommmitment?.length - 1 && (
                                  <span style={{ color: "red" }}>
                                    {existingCommitmentErrors?.investorName ??
                                      ""}
                                  </span>
                                )}
                              </Col>
                              <Col lg={3} className="mb-3">
                                <input
                                  type="number"
                                  onWheel={event => event.currentTarget.blur()}
                                  className="form-control text-capitalize"
                                  name="Committed Amount"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "committedAmount",
                                      i
                                    )
                                  }
                                  value={items?.committedAmount}
                                />
                                {i === existingCommmitment?.length - 1 && (
                                  <span style={{ color: "red" }}>
                                    {existingCommitmentErrors?.committedAmount ??
                                      ""}
                                  </span>
                                )}
                              </Col>

                              <Col lg={3} className="mb-3">
                                <select
                                  className="form-control"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "isLeadInvestor",
                                      i
                                    )
                                  }
                                >
                                  <option value="">--Select Options--</option>
                                  {IS_HE_SHE_LEAD_INVESTOR.map((value, key) => (
                                    <>
                                      <option
                                        key={key}
                                        value={value.label}
                                        selected={
                                          items?.isLeadInvestor == value.label
                                        }
                                      >
                                        {value.label}
                                      </option>
                                    </>
                                  ))}
                                </select>
                              </Col>
                              <Col lg={2} className="mb-3">
                                <input
                                  type="text"
                                  className="form-control text-capitalize"
                                  name="Comment"
                                  onChange={e =>
                                    handleExistingCommmitment(
                                      e.target.value,
                                      "comment",
                                      i
                                    )
                                  }
                                  value={items?.comment}
                                />
                              </Col>
                              <Col lg={1} className="mb-3 d-flex">
                                <i
                                  className="mdi commitment-delete mdi-plus-circle-outline"
                                  id="mdi-plus-circle-outline"
                                  onClick={() => addedExistingCommitmentData(i)}
                                ></i>
                                <i
                                  className="mdi commitment-delete mdi-delete"
                                  id="deletetooltip"
                                  onClick={() => handleDeleteCommitment(i)}
                                ></i>
                              </Col>
                            </Row>
                          );
                        })}
                    </>
                  ) : (
                    " -"
                  )}
                  {errors?.existingCommmitment && (
                    <span style={{ color: "red" }}>
                      {errors?.existingCommmitment}
                    </span>
                  )}
                </Col>
                <Col lg={12} className="mb-3 align-items-center">
                  <Label>
                    <strong>Additional Notes</strong>
                  </Label>
                  <textarea
                    className="form-control text-capitalize"
                    name="Remarks"
                    onChange={e => handleRemarks(e)}
                    value={getRemarks}
                  />
                </Col>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>PAN of the Startup:</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <input
                      type="text"
                      className="form-control"
                      name="schemeName"
                      value={pan_number}
                      onChange={e => setPanNumber(e.target.value)}
                      title="PAN Number can be updated in My Startup."
                      disabled={
                        !isEmpty(
                          startupInfo?.[0]?.basic_information?.pan_number
                        )
                      }
                    />
                  </Col>
                  {errors?.pan_number && (
                    <span style={{ color: "red" }}>{errors?.pan_number}</span>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Deal Manager: </strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handleDealManagerChange(e)}
                    >
                      <option value="">--Select Deal Manager--</option>
                      {dealManagers &&
                        dealManagers.length > 0 &&
                        dealManagers.map((val, key) => (
                          <option
                            key={key}
                            value={val._id}
                            selected={dealManagerId === val._id}
                          >
                            {" "}
                            {val.name}
                          </option>
                        ))}
                    </select>
                  </Col>
                  {errors?.dealManagerId && (
                    <span style={{ color: "red" }}>
                      {errors?.dealManagerId}
                    </span>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Scheme Name:</strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <input
                      type="text"
                      className="form-control"
                      name="schemeName"
                      onChange={e => handleSchemeName(e)}
                      value={schemeName}
                    />
                  </Col>
                  {errors?.schemeName && (
                    <span style={{ color: "red" }}>{errors?.schemeName}</span>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col
                    md={6}
                    sm={4}
                    xs={4}
                    className="mt-2 mt-sm-0 d-flex align-items-center"
                  >
                    <Label className="mb-0">
                      <strong>Status: </strong>
                    </Label>
                  </Col>
                  <Col md={6} sm={4} xs={4} className="mt-2 mt-sm-0">
                    <select
                      className="form-control"
                      onChange={e => handlecopySelectedStatus(e)}
                    >
                      {/* <option value="">{dealStatus?.length!=0?dealStatus:"AppliedFor"}</option> */}
                      {fundingDealStatusList.map(
                        (value, key) =>
                          ((getUserRolles?.applying == true &&
                            value.label == "ApplyingDraft") ||
                            (getUserRolles?.applied_for == true &&
                              value.label == "AppliedFor") ||
                            (getUserRolles?.deal_live == true &&
                              value.label == "DealLive") ||
                            (getUserRolles?.deal_rejected == true &&
                              value.label == "DealRejected") ||
                            (getUserRolles?.closed_internally_funded == true &&
                              value.label == "Closed-InternallyFunded") ||
                            (getUserRolles?.closed_externally_funded == true &&
                              value.label == "Closed-ExternallyFunded")) && (
                            <>
                              <option
                                key={key}
                                value={value.label}
                                selected={
                                  appliedFundingDeal?.current_status ===
                                  value.value
                                }
                              >
                                {value.value}
                              </option>
                            </>
                          )
                      )}
                    </select>
                  </Col>
                </Row>
              </Row>

              <Col sm={12} className="mt-3">
                {!copyClicked ? (
                  <Button
                    onClick={() => {
                      if (
                        startupInfo?.[0]?.business_information
                          ?.company_incorporation_location === "India" &&
                        startupInfo?.[0]?.currency?.value !== "INR"
                      ) {
                        setCountryMismatch(true);
                        setUpdateType("copy");
                        return;
                      } else {
                        copyFundingDealStatus();
                      }
                    }}
                  >
                    Copy Deal
                  </Button>
                ) : (
                  <Button>Copying...</Button>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

// FounderDetails.propTypes = {
//     match: PropTypes.shape({
//         params: PropTypes.shape({
//             id: PropTypes.string.isRequired,
//         }),
//     }),
//     onGetDealManagers: PropTypes.func,
// };
FounderDetails.propTypes = {
  match: PropTypes.any,
  dealManagers: PropTypes.array,
  className: PropTypes.any,
  onGetAppliedFundings: PropTypes.func,
  onGetDealManagers: PropTypes.func,
  error: PropTypes.string,
  manager: PropTypes.string,
  onUpdateStatus: PropTypes.func,
  onUpdateStatusSuccess: PropTypes.bool,
  onCopyDealStatus: PropTypes.func,
  onUpdateEditFundingDetails: PropTypes.func,
  UpdateFundingDealStatusSuccess: PropTypes.bool,
  UpdateFundingDealStatusError: PropTypes.bool,
  CopyStatusSuccess: PropTypes.bool,
  CopyDealStatusError: PropTypes.bool,
  sendConsetSuccess: PropTypes.any,
  sendConsetFail: PropTypes.any,
  onGetFundingDealStatusList: PropTypes.func,
  // onGetValuationTypesList:PropTypes.func,
  onGetFundingDealTypeList: PropTypes.func,
  fundingDealStatusList: PropTypes.array,
  fundingDealTypeList: PropTypes.array,
  appliedFunding: PropTypes.array,
};
const mapStateToProps = ({ appliedFunding }) => {
  return {
    fundingDealStatusList: appliedFunding.fundingDealStatusListSuccess,
    fundingDealTypeList: appliedFunding.fundingDealTypeListSuccess,
    UpdateFundingDealStatusSuccess:
      appliedFunding.UpdateFundingDealStatusSuccess,
    CopyDealStatusSuccess: appliedFunding.CopyDealStatusSuccess,
    sendConsetSuccess: appliedFunding.sendConsetSuccess,
    sendConsetFail: appliedFunding.sendConsetFail,
    UpdateFundingDealStatusError: appliedFunding.UpdateFundingDealStatusError,
    CopyDealStatusError: appliedFunding.CopyDealStatusError,
    appliedFunding: appliedFunding?.appliedFundings?.data || [],
    dealManagers: appliedFunding.dealManagers || [],
    manager: appliedFunding.manager || "",
    error: appliedFunding.error,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAppliedFundings: async () => await dispatch(getAppliedFundings()),
  onGetDealManagers: async () => await dispatch(getDealManagers()),
  onUpdateStatus: async statusPayload =>
    await dispatch(updateStatus(statusPayload)),
  onUpdateStatusSuccess: async statusPayload =>
    await dispatch(updateStatusSuccess(statusPayload)),
  onSendConsentToInvestor: async statusPayload =>
    await dispatch(onSendConsentToInvestor(statusPayload)),
  onCopyDealStatus: async statusPayload =>
    await dispatch(copyDealStatus(statusPayload)),
  onUpdateEditFundingDetails: async payload =>
    await dispatch(updateFundingDetails(payload)),
  onGetFundingDealStatusList: async payload =>
    await dispatch(getFundingDealStatusList(payload)),
  onGetFundingDealTypeList: async payload =>
    await dispatch(getFundingDealTypeList(payload)),
  onUpdateZoomInvestorIntentStatus: async payload =>
    await dispatch(updateZoomInvestorIntentStatus(payload)),
  // onGetValuationTypesList: async payload =>
  // await dispatch(getValuationTypesList(payload)),
});

// export default FounderDetails;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FounderDetails));
