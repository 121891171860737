import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { Form } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { WithContext as ReactTags } from "react-tag-input";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/users/actions";

import { isEmpty, size } from "lodash";
import { extendOwn } from "underscore";

class FounderList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      founders: [],
      pitchday: {},
      tags: [],
      modal: false,
      selectedFounders: [],
      selected: [],
      tagError: "",
      userListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{user.id}</>,
        },
        // {
        //     text: "Name",
        //     dataField: "name",
        //     sort: true,
        //     formatter: (cellContent, user) => (
        //         <>
        //             <h5 className="font-size-14 mb-1">
        //                 <Link to="#" className="text-dark">
        //                     {user.firstname} {user.lastname}
        //                 </Link>
        //             </h5>
        //         </>
        //     ),
        // },
        {
          dataField: "firstname",
          text: "First Name",
          sort: true,
        },
        {
          dataField: "lastname",
          text: "Last Name",
          sort: true,
        },
        {
          dataField: "saas_detail.saas_name",
          text: "Syndicate Name",
          sort: true,
          formatter: (cellContent, user) =>{
            let value = user?.saas_detail?.saas_name;
    
            if (user?.is_saas_parent) {
              value = user?.saas_name;
            }
            return <>{value}</>;
          }, 
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
        },
        {
          dataField: "mobile_number",
          text: "Mobile Number",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {user.mobile_country_code} {user.mobile_number}
            </>
          ),
          //
        },
      ],
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleValidUserSubmit = this.handleValidUserSubmit.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const {
      founders,
      getAll,
      selectedFounders,
      outsideInvestor,
      isInvestors,
      defaultInvestor,
      defaultFounder,
      defaultPanellist
    } = this.props;
    if (isInvestors==="investor") {
      if (selectedFounders) {
        let data = selectedFounders.map(value => value._id);
        this.setState({ selected: data });
      }
    } else if (isInvestors==="founder"){
      if (selectedFounders) {
        let data = selectedFounders.map(value => value._id);
        this.setState({ selected: data });
      }
    }
    else if (isInvestors==="panellist"){
      if (selectedFounders) {
        let data = selectedFounders?.map(value => value._id);
        this.setState({ selected: data });
      }
    }

    // this.setState({ selectedFounders: selectedFounders, selected: selectedFounders.map((e, ind) => e._id), tags: outsideInvestor })
    this.setState({
      selectedFounders: selectedFounders,
      tags: outsideInvestor,
    });
    getAll({selectedSaasParent : this.props.selectedSaasParent});
    if (isInvestors==="founder") {
      this.setState({
        userListColumns: [
          ...this.state.userListColumns,
          {
            dataField: "description_by_admin",
            text: "Description",
            sort: true,
          },
          {
            dataField: "menu",
            editable: true,
            text: "Edit",
            formatter: (cellContent, pitchday, rowIndex, row) => (
              <div className="d-flex gap-3">
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleDescription(pitchday)}
                  ></i>
                </Link>
                {/* <Input
                                name="description_by_admin"
                                label="Description"
                                type="textarea"
                                onChange={(e) => this.handleDescription(e, pitchday, rowIndex)}
                            // value={pitchday?.description_by_admin || ""}
                            /> */}
              </div>
            ),
          },
        ],
      });
    }
console.log("===selectedFounders=>",selectedFounders);
    this.setState({
      founders: founders.map((e, i) => {
        if (selectedFounders.map((e, ind) => e._id).includes(e._id)) {
          return selectedFounders.find(el => el._id === e._id);
        } else {
          return e;
        }
      }),
    });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserClicks = arg => {
    this.setState({ users: "", isEdit: false });
    this.toggle();
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { founders, outsideInvestor } = this.props;
    if (!isEmpty(founders) && size(prevProps.founders) !== size(founders)) {
      this.setState({ founders, isEdit: false });
    }
    if (
      !isEmpty(
        outsideInvestor &&
        size(prevProps.outsideInvestor) !== size(outsideInvestor)
      )
    ) {
      this.setState({ tags: outsideInvestor });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteUser = user => {
    const { onDeleteFunding } = this.props;
    if (user._id !== undefined) {
      onDeleteFunding(user);
      this.onPaginationPageChange(1);
    }
  };

  handleUserClick = arg => {
    const user = arg;

    this.setState({
      users: {
        id: user._id,
        firstname: user.firstname,
        lastname: user.lastname,
        mobile_country_code: user.mobile_country_code,
        mobile_number: user.mobile_number,
        alternateMobileNumber: user.alternateMobileNumber,
        email: user.email,
        linkedin_profile_link: user.linkedin_profile_link,
        is_admin: user.is_admin,
        mobile_number_verified: user.mobile_number_verified,
        email_verified: user.email_verified,
        status: user.status,
        user_types: user.user_types,
      },
      isEdit: true,
    });

    this.toggle();
  };

  /**
   * Handling submit user on user form
   */
  handleValidUserSubmit = (e, values) => {
    const { isEdit, founders, pitchday } = this.state;

    if (isEdit) {
      // const updatePitchday = {
      //     ...pitchday,
      //     description_by_admin: values.description_by_admin,
      // };
      const newpitchdays = founders.map(ele => {
        if (ele._id === pitchday.id) {
          const newEl = {
            ...ele,
            description_by_admin: values.description_by_admin,
          };

          return newEl;
        } else {
          return ele;
        }
      });

      this.setState({ founders: newpitchdays });
      // update user
    }
    this.toggle();
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleDescription(arg) {
    const pitchday = arg;

    this.setState({
      pitchday: {
        id: pitchday._id,
        description_by_admin: pitchday.description_by_admin,
      },
      isEdit: true,
    });

    this.toggle();
    // const { founders } = this.state;
    // console.log("pitchdays======", rowIndex);
    // const newpitchdays = founders
    // newpitchdays[rowIndex][e.target.name] = e.target.value

    // const newpitchdays = founders.map((ele) => {
    //     if (ele.id === pitchday.id) {
    //         const newEl = { ...ele, [e.target.name]: e.target.value }
    //         console.log("ele====", newEl)
    //         return newEl
    //     } else {
    //         return ele;
    //     }

    // })

    // console.log("newpitchdays======", newpitchdays);

    // this.setState({ founders: newpitchdays });
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(tag.id).toLowerCase());
    this.setState({ tagError: "" });

    if (isValid) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
    } else {
      this.setState({ tagError: "Please enter valid email" });
    }
  }

  /* Insert,Update Delete data */
  render() {
    const { SearchBar } = Search;

    const { error, defaultFounder, defaultInvestor,defaultPanellist, isInvestors } = this.props;
    let { founders, selected } = this.state;

    // to display selected users on top
    if (this.state.selected?.length) {
      let selected = founders.filter(
        user =>
          !!this.state.selected.find(
            select => select?.toString() === user?._id?.toString()
          )
      );
      let unselected = founders.filter(
        user =>
          !this.state.selected.find(
            select => select?.toString() === user?._id?.toString()
          )
      );

      founders = [...selected, ...unselected];
    }

    const { isEdit } = this.state;
    const pageOptions = {
      sizePerPage: 10,
      totalSize: founders.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState({
            selectedFounders: [...this.state.selectedFounders, row],
            selected: [...this.state.selected, row._id],
          });
        } else {
          this.setState({
            selectedFounders: this.state.selectedFounders.filter(
              e => e._id !== row._id
            ),
            selected: this.state.selected.filter(x => x !== row._id),
          });
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map(r => r._id);
        const dataId = this.state.selected.concat(ids);
        const dataRow = this.state.selectedFounders.concat(rows);

        if (isSelect) {
          this.setState(() => ({
            selected: dataId,
            selectedFounders: dataRow,
          }));
        } else {
          this.setState(() => ({
            selected: [],
            selectedFounders: [],
          }));
        }
      },
    };

    // const defaultValues = {
    //     is_admin: !isEmpty(this.state.users.is_admin) ? this.state.users.is_admin : 'false',
    //     mobile_number_verified: !isEmpty(this.state.users.mobile_number_verified) ? this.state.users.mobile_number_verified : 'false',
    //     email_verified: !isEmpty(this.state.users.email_verified) ? this.state.users.email_verified : 'false',
    //     status: !isEmpty(this.state.users.status) ? this.state.users.status : 'false',
    // };

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="_id"
                      columns={this.state.userListColumns}
                      data={founders}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={this.state.userListColumns}
                          data={founders}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                {/* <Col sm="8">
                                  {this.props.isInvestors==="investor" &&
                                    this.props.isCreate ? (
                                    <div className="multi-input">
                                      <ReactTags
                                        tags={this.state.tags}
                                        handleDelete={this.handleDelete}
                                        handleAddition={this.handleAddition}
                                        placeholder="Enter email to invite"
                                      />
                                      <p className="text-danger">
                                        {this.state.tagError}
                                      </p>
                                    </div>
                                  ) : null}
                                </Col> */}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  {!isEmpty(error) ? (
                                    <p className="text-danger">
                                      Some error occured!
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div
                                    className="table-responsive"
                                    style={{
                                      overflowY: "auto",
                                      minHeight: "auto",
                                      maxHeight: "360px",
                                    }}
                                  >
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={"table align-middle table-hover"}
                                      bordered={false}
                                      striped={true}
                                      responsive
                                      ref={this.node}
                                    />
                                    <Modal
                                      size="lg"
                                      isOpen={this.state.modal}
                                      className={this.props.className}
                                      toggle={this.toggle}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {"Add Description"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <AvForm
                                          onValidSubmit={
                                            this.handleValidUserSubmit
                                          }
                                        // model={defaultValues}
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <AvField
                                                  name="description_by_admin"
                                                  label="Description"
                                                  type="text"
                                                  errorMessage="Invalid description"
                                                  value={
                                                    this.state.pitchday
                                                      .description_by_admin ||
                                                    ""
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col sm={12}>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                  <Button
                                    color="primary"
                                    className="inner"
                                    onClick={() => {
                                      this.props.setFounders(
                                        this.state.founders.filter(e =>
                                          this.state.selected.includes(e._id)
                                        ),
                                        this.state.tags
                                      );
                                      this.props.toggle();
                                    }}
                                    block
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

FounderList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onDeleteFunding: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  getAll: PropTypes.func,
  founders: PropTypes.array,
  setFounders: PropTypes.func,
  selectedFounders: PropTypes.array,
  isInvestors: PropTypes.bool,
  isCreate: PropTypes.bool,
  outsideInvestor: PropTypes.object,
  defaultFounder: PropTypes.array,
  defaultInvestor: PropTypes.array,
  defaultPanellist: PropTypes.array,
};

const mapStateToProps = ({ users }) => ({
  users: users.users,
  error: users.error,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
  onDeleteFunding: user => dispatch(deletefunding(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FounderList));
