import React from "react";
import { useState, useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import { Accordion, Table } from "react-bootstrap";
import { toCommaFormat } from "helpers/backend_helper";
import authHeader from "../../../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import moment from "moment";
import {
  checkDeviceType,
  getLocationString,
  // getOperatingSystem,
  // getUserBrowser,
  getDeviceDetails,
} from "../../../../helpers/logs_helper";

// TO DETECT USER BROWSER; p.s. i don't know how it works, it just does ;)
navigator.browserDetection = (function () {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
})();

function SignupLedger(props) {
  let { relatives, investor, currentlySelectedRelative } = props;
  const [selectedUserLedger, setSelectedUserLedger] = useState({});
  const [caAmount, setCAAmount] = useState({});
  const [geolocationData, setGeolocationData] = useState({});

  const getGeolocationData = async () => {
    // FETCHING USER DEVICE DATA;
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    await axios
      .get("https://geolocation-db.com/json/")
      .then(res => setGeolocationData(res.data))
      .catch(err => console.log("ERROR WHILE FETCHING GEOLOCATION DATA:", err));
  };

  const fetchAndPopulateLedger = (relation, fund_type_id, investorId) => {
    if (typeof selectedUserLedger[`${relation}`] === "undefined") {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api-v2/ca-agreement/details/${investorId}?relation=${relation}&fund_type_id=${fund_type_id}`
        )
        .then(res => {
          let { details } = res.data;
          let tempLedger = { ...selectedUserLedger };
          tempLedger[`${relation}`] = details;
          setSelectedUserLedger(tempLedger);
        })
        .catch(err => console.log("Error:", err));
    }
  };

  const handleNewCA = (relation, fund_type_id, investorId) => {
    // FETCHING USER DEVICE DATA;
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }

    let deviceInfo = getDeviceDetails(navigator.userAgent);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api-v2/contribution-agreement/add/${investorId}?relation=${relation}&fund_type_id=${fund_type_id}`,
        {
          amount: caAmount[`${relation}`],
          location: getLocationString(
            geolocationData?.city,
            geolocationData?.country_name
          ),
          ipAddress: geolocationData["IPv4"],
          browserName: deviceInfo.browser,
          osName: deviceInfo.os,
          deviceName: checkDeviceType(navigator),
        },
        configHeaders
      )
      .then(res => {
        let { details } = res.data;
        let tempLedger = { ...selectedUserLedger };
        tempLedger[`${relation}`] = details;
        setSelectedUserLedger(tempLedger);

        let obj = { ...caAmount };
        obj[relation] = "";
        setCAAmount(obj);
      })
      .catch(err => console.log("Error:", err));
  };

  const handleAmountChange = event => {
    let { name, value } = event.target;
    setCAAmount({
      ...caAmount,
      [name]: value,
    });
  };

  useEffect(() => {
    getGeolocationData();
  }, []);

  console.log("prp:::", props);

  return (
    <div className="sec-column full-width network-sec">
      <h4 className="mt-3 mb-3">Signup A New Contribution Agreement</h4>
      {relatives?.map((item, index) => {
        return (
          <Accordion className="mb-3">
            <Accordion.Item
              eventKey={`${index}`}
              onClick={() =>
                fetchAndPopulateLedger(
                  item?.value,
                  item?.fund_type_id,
                  investor
                )
              }
            >
              <Accordion.Header>{item?.label}</Accordion.Header>
              <Accordion.Body>
                <div className="table-responsive int_table mb-2">
                  <div className="react-bootstrap-table">
                    <Table>
                      <thead>
                        <tr>
                          <th>C.A. No.</th>
                          <th>Amount Sanctioned</th>
                          <th>Amount Used</th>
                          <th>Overall Amount Left</th>
                          <th>Created On</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedUserLedger[`${item?.value}`]?.map(
                          (item, index) => {
                            let createdOn =
                              typeof item?.created_on === "undefined" ||
                              item?.created_on === "" ||
                              item?.created_on === null
                                ? "--"
                                : moment(item?.created_on).format(
                                    "DD-MMM-YYYY"
                                  );
                            return (
                              <tr>
                                <td>{`${index + 1}`}</td>
                                <td>{`${item?.currency}${toCommaFormat(
                                  item?.amount_sanctioned
                                )}`}</td>
                                <td>{`${item?.currency}${toCommaFormat(
                                  item?.amount_used
                                )}`}</td>
                                <td>{`${item?.currency}${toCommaFormat(
                                  item?.overall_amount
                                )}`}</td>
                                <td>{createdOn}</td>
                                <td>{item?.status}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <Row>
                  <Col className="col-md-3">
                    <p>C.A. Amount:</p>
                  </Col>
                  <Col className="col-md-3">
                    <input
                      type="text"
                      value={caAmount[item?.value]}
                      name={`${item?.value}`}
                      onChange={handleAmountChange}
                    />
                  </Col>
                  <Col className="col-md-6 d-flex flex-row-reverse">
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() =>
                        handleNewCA(item?.value, item?.fund_type_id, investor)
                      }
                    >
                      Add
                    </button>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
}

export default SignupLedger;
