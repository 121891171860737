import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  Row,
} from "reactstrap";
import EventDetails from "./EventDetails";
import Participants from "./Participants";
import QuestionAnswers from "./QuestionAnswer";
import MediaDetails from "./MediaDetails";
import Poll from "./Poll";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getPostEventDetail,
  fetchPoll,
  fetchPollReport,
  fetchQa,
  fetchRecording,
  getQuestions,
  getAssociatePoll,
  fetchRegistrants,
  getEventParticipants,
} from "../../../store/pitchdays/actions";
import axios from "axios";

import { isEqual } from "lodash-es";
import DataFetchLoader from "components/Common/DataFetchLoader";
import EventImages from "./EventImages";
import authHeader from "helpers/jwt-token-access/auth-token-header";

class EventReportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      post_id: "",
      post: {},
      typescript: [],
      qa: [],
      poll: [],
      registrants: [],
      panelists: [],
      participants: [],
      eventReportData: {},
    };
  }
  async componentDidMount() {
    const {
      onGetQa,
      onGetPoll,
      onGetPollReports,
      onGetParticipants,
      onGetRegistrants,
      onGetRecording,
      onGetPostEventDetail,
      match,
    } = this.props;

    this.setState({ post_id: match.params.id });

    await onGetParticipants(match.params.id);
    await onGetPollReports(match.params.id);
    await onGetRegistrants(match.params.id);
    await onGetRecording(match.params.id);
    await onGetQa(match.params.id);
    await onGetPoll(match.params.id);

    await onGetPostEventDetail({ postId: match.params.id, showAll: true });
    // onGetPitchQuestions(match.params.id);
    // onGetAssociatedInvestors(match.params.id);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.postEvent, nextProps.postEvent)) {
      const { media_content } = nextProps.postEvent;

      const {
        participants,
        question_by_admin,
        _id,
        post_category,
        event_type,
        title,
        description,
        meeting_details,
        date,
        industry_sectors,
        invited_investor,
        invited_founder,
        totalInvited,
        totalRsvpAttending,
        totalRsvpDecline,
        totalRsvpNotSure,
        totalParticipants,
        totalUuninvitedUsers,
        totalDuration,
        totalRsvp,
      } = nextProps.postEvent;
      const e_date = new Date(date.event_date);
      e_date.setMinutes(e_date.getMinutes() - e_date.getTimezoneOffset());
      const counts = {};
      [].concat
        .apply(
          [],
          [].concat.apply(
            [],
            [...media_content]
              .filter(e => e.media_type[0] === "poll_report")
              ?.map(e =>
                e?.content?.body?.questions?.map(ele =>
                  ele.question_details?.map(que => que.answer)
                )
              )
          )
        )
        .forEach(function (x) {
          counts[x] = (counts[x] || 0) + 1;
        });

      this.setState({
        post: {
          id: _id,
          question_by_admin: question_by_admin || [],
          post_category: post_category,
          event_type: event_type,
          title: title.trim(),
          description: description.trim(),
          event_datetime: e_date.toISOString().slice(0, 16),
          zoom_link: meeting_details.zoom_link,
          additional_information: meeting_details.additional_information,
          zoom_meeting_id: meeting_details.zoom_meeting_id,
          zoom_encrypted_meeting_id: meeting_details.zoom_encrypted_meeting_id,
          industrySectors: industry_sectors.lenght
            ? industry_sectors.map(e => ({ sectorName: e }))
            : [{ sectorName: "" }],
          investors: invited_investor.length
            ? invited_investor.map(e => ({
                investorName: `${e.investor_info._id}`,
              }))
            : [{ investorName: "" }],
          founders: invited_founder.length
            ? invited_founder.map(e => ({ id: e.founder_info._id }))
            : [{ id: "" }],
          images: [{ fileName: "", file: "" }],
          selectedFounders: invited_founder,
          selectedInvestors: invited_investor,
          defaultFounder: invited_founder,
          defaultInvestor: invited_investor,
          date: date.event_date,
          totalInvited,
          totalParticipants,
          totalRsvpAttending,
          totalRsvpDecline,
          totalRsvpNotSure,
          totalUuninvitedUsers,
          totalDuration,
          totalRsvp,
        },
        typescript: [...media_content].filter(
          e => e.media_type[0] === "typescript" || e.media_type[0] === "image"
        ),
        qa: [...media_content].filter(e => e.media_type[0] === "qa"),
        poll: [...media_content].filter(e => e.media_type[0] === "poll"),
        poll_report: [...media_content].filter(
          e => e.media_type[0] === "poll_report"
        ),
        registrants: [...media_content].filter(
          e => e.media_type[0] === "registrants"
        ),
        panelists: [...media_content].filter(
          e => e.media_type[0] === "panelists"
        ),
        participants: participants,
        pollCounts: counts,
      });
    }
    if (!isEqual(this.props.participants, nextProps.participants)) {
      const { participants } = nextProps;

      this.setState({ participants: participants });
    }
  }

  toggle = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { postEvent } = this.props;
    const { steps, currentStep, post, activeTab } = this.state;
    const { onGetPostEventDetail } = this.props;
    const buttonStyle = {
      background: "#E0E0E0",
      width: 200,
      padding: 16,
      textAlign: "center",
      margin: "0 auto",
      marginTop: 32,
    };

    return (
      <React.Fragment>
        <div className="">
          <MetaTags>
            <title>View Event Report | Invstt - Admin</title>
          </MetaTags>
          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <Breadcrumbs title="Event" breadcrumbItem="View Event Report" />
                <DataFetchLoader
                  loading={postEvent?._id === this.state.post_id ? false : true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 && "active"}
                          onClick={() => {
                            this.toggle(1);
                          }}
                        >
                          Event Report Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 && "active"}
                          onClick={() => {
                            this.toggle(2);
                          }}
                        >
                          Participants
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 3 && "active"}
                          onClick={() => {
                            this.toggle(3);
                          }}
                        >
                          Media
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 4 && "active"}
                          onClick={() => {
                            this.toggle(4);
                          }}
                        >
                          Q&A
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 5 && "active"}
                          onClick={() => {
                            this.toggle(5);
                          }}
                        >
                          Poll
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 6 && "active"}
                          onClick={() => {
                            this.toggle(6);
                          }}
                        >
                          Event Images
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <Row>
                          <Col lg={12}>
                            <EventDetails postDetails={postEvent} onGetPostEventDetail={onGetPostEventDetail} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Row>
                          <Col lg={12}>
                            <Participants postDetails={postEvent} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={3}>
                        <Row>
                          <Col lg={12}>
                            <MediaDetails postDetails={postEvent} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={4}>
                        <Row>
                          <Col lg={12}>
                            <QuestionAnswers postDetails={postEvent} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={5}>
                        <Row>
                          <Col lg={12}>
                            <Poll postDetails={postEvent} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={6}>
                        <Row>
                          <Col lg={12}>
                            <EventImages postDetails={postEvent} />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EventReportDetails.propTypes = {
  match: PropTypes.any,
  className: PropTypes.any,
  error: PropTypes.any,
  onGetPostEventList: PropTypes.func,
  postEvent: PropTypes.object,
  onGetPostEventDetail: PropTypes.func,
  onGetRecording: PropTypes.func,
  onGetQa: PropTypes.func,
  onGetPoll: PropTypes.func,
  onGetAssociatedInvestors: PropTypes.func,
  onGetParticipants: PropTypes.func,
  onGetPollReports: PropTypes.func,
  onGetRegistrants: PropTypes.func,
  participants: PropTypes.array,
  panelist: PropTypes.array,
  onGetPitchQuestions: PropTypes.func,
  questionsList: PropTypes.object,
  questionsListError: PropTypes.object,
};

const mapStateToProps = ({ pitchdays }) => {
  return {
    panelist: pitchdays?.panelist,
    participants: pitchdays?.participants,
    postEvent: pitchdays?.postEvent,
    error: pitchdays?.errorPostEvent,
    questionsList: pitchdays?.questions,
    questionsListError: pitchdays?.questionsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetPostEventDetail: payload => dispatch(getPostEventDetail(payload)),
  onGetRecording: id => dispatch(fetchRecording(id)),
  onGetQa: id => dispatch(fetchQa(id)),
  onGetPoll: id => dispatch(fetchPoll(id)),
  onGetPollReports: id => dispatch(fetchPollReport(id)),
  onGetPitchQuestions: id => dispatch(getQuestions(id)),
  onGetAssociatedInvestors: id => dispatch(getAssociatePoll(id)),
  onGetRegistrants: id => dispatch(fetchRegistrants(id)),
  onGetParticipants: id => dispatch(getEventParticipants(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventReportDetails));
