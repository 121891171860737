import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import {
  getUsers,
  getUsersFromSaas,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/users/actions";

import { isEmpty, size } from "lodash";

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      selectedUsers: this.props.selectedUsers,
      userType: this.props.userType,
      modal: false,
      groupSaasParentId: this.props.groupSaasParentId,
      saasParentId: this.props.saasParentId,
    };
    this.saveSelectedUsers = this.saveSelectedUsers.bind(this);
  }

  saveSelectedUsers() {
    this.props.onSelectAll(this.state.selectedUsers);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.users.length !== state.users.length) {
      //Change in props
      return {
        users: props.users,
      };
    }
    return null; // No change to state
  }

  componentDidMount() {
    const { onGetUsers, onGetUsersFromSaas, userType, saasParentId } =
      this.props;
    if (userType === "All") {
      onGetUsers();
    } else {
      onGetUsersFromSaas({
        userType: userType.toLowerCase(),
        saas_parent_id: saasParentId,
      });
    }
    // console.log("----userType--",userType,groupSaasParentId)
    // console.log("----onGetUsers--")
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */
  render() {
    const { SearchBar } = Search;

    let { users, error } = this.props;

    // if (typeof saasName !== "undefined") {
    //   users = users.filter(user => {
    //     return user?.saas_parent_id?.saas_name === saasName;
    //   });
    // }

    // console.log("USERS LENGTH:", users);
    // console.log(">props user>>...",users)
    // to display selected users on top
    if (this.state.selectedUsers?.length) {
      let selected = users.filter(
        user =>
          !!this.state.selectedUsers.find(
            selected => selected?._id?.toString() === user?._id?.toString()
          )
      );
      let unselected = users.filter(
        user =>
          !this.state.selectedUsers.find(
            selected => selected?._id?.toString() === user?._id?.toString()
          )
      );
      users = [...selected, ...unselected];
    }

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );

    const { isEdit } = this.state;

    const pageOptions = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      sizePerPage: 10,
      totalSize: this.state.dataSize,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };

    const userListColumns = [
      {
        text: "id",
        dataField: "id",
        sort: true,
        hidden: true,
        formatter: (cellContent, user) => <>{user.id}</>,
      },
      {
        text: "Name",
        dataField: "name",
        sort: true,
        formatter: (cellContent, user) =>
          ((user.firstname || "") + " " + (user.lastname || "")).trim(),
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "other_primary_saas_detail",
        text: "All Syndicate",
        sort: true,
        formatter: (cellContent, user) => {
          let value = [];
          let k = 0;
          if (user?.saas_detail) {           
            value.push(user?.saas_detail?.saas_name);
          }
          if (user?.is_saas_parent) {
            value.push(user?.saas_name);
          }
          if (user?.other_primary_saas_detail?.length > 0) {
            user?.other_primary_saas_detail?.map(e => {
              if (e.saas_name) {
                value.push(e.saas_name);
              }
            });
          }
          value = value.toString();
          return <>{value}</>;
        },
        
      },
      {
        dataField: "user_types[0].user_type[0]",
        text: "User Type",
        sort: true,
        formatter: (cellContent, user) => (
          <div className="text-capitalize">
            {user?.user_types?.find(
              user => user?.user_type[0] === this.props.userType?.toLowerCase()
            )?.user_type[0] || this.props.userType}
          </div>
        ),
      },
      {
        dataField: "createdAt",
        text: "Created On",
        sort: true,
        formatter: (cellContent, user) => (
          <>{moment(new Date(user.createdAt)).format("DD MMM Y")}</>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const curSelected = () => {
      let userArr = [];
      this.state.selectedUsers?.map((ele, i) => {
        userArr.push(ele?._id);
      });
      // console.log("---userArr-----",userArr)
      return userArr;
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: curSelected(),
      onSelectAll: (isSelect, rows, e) => {
        //console.log(rows);
        let finalArry;

        if (isSelect) {
          finalArry = [...new Set([...this.state.selectedUsers, ...rows])];
        } else {
          finalArry = this.state.selectedUsers;
          rows.forEach((e, i) => {
            this.state.selectedUsers.forEach((ele, ind) => {
              if (e?._id === ele?._id) {
                finalArry.splice(ind, 1);
              }
            });
          });
        }
        this.setState({ selectedUsers: finalArry });
      },

      onSelect: (row, isSelect) => {
        //console.log(isSelect,row);
        let finalArry;

        if (isSelect) {
          finalArry = [...new Set([...this.state.selectedUsers, ...[row]])];
        } else {
          finalArry = this.state.selectedUsers;
          finalArry.forEach((e, i) => {
            if (e?._id === row?._id) {
              finalArry.splice(i, 1);
            }
          });
        }
        this.setState({ selectedUsers: finalArry });
      },
    };

    const defaultValues = {
      is_admin: !isEmpty(this.state.users.is_admin)
        ? this.state.users.is_admin
        : "false",
      mobile_number_verified: !isEmpty(this.state.users.mobile_number_verified)
        ? this.state.users.mobile_number_verified
        : "false",
      email_verified: !isEmpty(this.state.users.email_verified)
        ? this.state.users.email_verified
        : "false",
      status: !isEmpty(this.state.users.status)
        ? this.state.users.status
        : "Pending",
    };

    return (
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={userListColumns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={users}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitprops.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    onClick={this.saveSelectedUsers}
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                {!isEmpty(error) ? (
                                  <p className="text-danger">
                                    Some error occured!
                                  </p>
                                ) : null}
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                {/* <div className="table-responsive"> */}
                                <div>
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    ref={this.node}
                                  />

                                  <Modal
                                    size="lg"
                                    isOpen={this.state.modal}
                                    className={this.props.className}
                                    toggle={this.toggle}
                                  >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                      {!!isEdit ? "Edit User" : "Add User"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidUserSubmit
                                        }
                                        model={defaultValues}
                                      >
                                        <Row form>
                                          <Col className="col-12">
                                            <div className="mb-3">
                                              <AvField
                                                name="firstname"
                                                label="First Name"
                                                type="text"
                                                errorMessage="Invalid firstname"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  this.state.users.firstname ||
                                                  ""
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="lastname"
                                                label="Last Name"
                                                type="text"
                                                errorMessage="Invalid lastname"
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                value={
                                                  this.state.users.lastname ||
                                                  ""
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                errorMessage="Invalid Email"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  this.state.users.email || ""
                                                }
                                              />
                                            </div>

                                            {!!isEdit ? null : (
                                              <div className="mb-3">
                                                <AvField
                                                  name="password"
                                                  label="Password"
                                                  type="password"
                                                  errorMessage="Invalid password"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value=""
                                                />
                                              </div>
                                            )}

                                            {!!isEdit ? null : (
                                              <div className="mb-3">
                                                <label>User Type</label>
                                                <AvRadioGroup
                                                  name="user_types"
                                                  required
                                                >
                                                  <AvRadio
                                                    label="Investor"
                                                    value="investor"
                                                  />
                                                  <AvRadio
                                                    label="Founder"
                                                    value="founder"
                                                  />
                                                  <AvRadio
                                                    label="Professional"
                                                    value="professional"
                                                  />
                                                  <AvRadio
                                                    label="Invstt"
                                                    value="wfc"
                                                  />
                                                </AvRadioGroup>
                                              </div>
                                            )}

                                            <div className="mb-3">
                                              <AvField
                                                name="mobile_country_code"
                                                label="Mobile Country Code"
                                                type="text"
                                                errorMessage="Invalid mobile country code"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  this.state.users
                                                    .mobile_country_code || ""
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="mobile_number"
                                                label="Mobile Number"
                                                type="text"
                                                errorMessage="Invalid mobile number"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  this.state.users
                                                    .mobile_number || ""
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="alternateMobileNumber"
                                                label="Alternate Mobile Number"
                                                type="text"
                                                errorMessage="Invalid alternate Mobile Number"
                                                value={
                                                  this.state.users
                                                    .alternateMobileNumber || ""
                                                }
                                                validate={{
                                                  required: { value: false },
                                                }}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="linkedin_profile_link"
                                                label="Linkedin Profile Link"
                                                type="text"
                                                errorMessage="Invalid linkedin profile link"
                                                value={
                                                  this.state.users
                                                    .linkedin_profile_link || ""
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <label>Is Admin</label>
                                              <AvRadioGroup
                                                name="is_admin"
                                                required
                                              >
                                                <AvRadio
                                                  label="Yes"
                                                  value="true"
                                                />
                                                <AvRadio
                                                  label="No"
                                                  value="false"
                                                />
                                              </AvRadioGroup>
                                            </div>
                                            <div className="mb-3">
                                              <label>
                                                Mobile Number Verified
                                              </label>
                                              <AvRadioGroup
                                                name="mobile_number_verified"
                                                required
                                              >
                                                <AvRadio
                                                  label="Yes"
                                                  value="true"
                                                />
                                                <AvRadio
                                                  label="No"
                                                  value="false"
                                                />
                                              </AvRadioGroup>
                                            </div>
                                            <div className="mb-3">
                                              <label>Email Verified</label>
                                              <AvRadioGroup
                                                name="email_verified"
                                                required
                                              >
                                                <AvRadio
                                                  label="Yes"
                                                  value="true"
                                                />
                                                <AvRadio
                                                  label="No"
                                                  value="false"
                                                />
                                              </AvRadioGroup>
                                            </div>
                                            <div className="mb-3">
                                              <label>Status</label>
                                              <AvRadioGroup
                                                name="status"
                                                required
                                              >
                                                <AvRadio
                                                  label="Pending"
                                                  value="Pending"
                                                />
                                                <AvRadio
                                                  label="Approved"
                                                  value="Approved"
                                                />
                                                <AvRadio
                                                  label="Declined"
                                                  value="Declined"
                                                />
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            {/* <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row> */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.array,
  userType: PropTypes.string,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onGetUsersFromSaas: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  onSelectAll: PropTypes.func,
  onCloseModal: PropTypes.func,
  selectedUsers: PropTypes.array,
  selectedGroup: PropTypes.array,
  groupSaasParentId: PropTypes.string,
};

const mapStateToProps = ({ users }) => ({
  users: users.users,
  error: users.error,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: data => {
    // console.log("--data at usr-list----",data)
    dispatch(getUsers(data));
  },
  onGetUsersFromSaas: data => {
    dispatch(getUsersFromSaas(data));
  },
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersList));
