import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Select from "react-select";

import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";

import {
  getGroups,
  saveApplyForFundingInvestorGroups,
} from "../../../helpers/backend_helper";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import { Country, State, City } from "country-state-city";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getAppliedFundings } from "store/appliedFunding/actions";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

function InvestorGroupsModal({
  investorGroupsModalOpen,
  handleInvestorGroupsModal,
  selectedDeal,
  onGetAppliedFundings,
}) {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [country, setCountry] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [state, setState] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState([]);

  const [error, setError] = useState(null);

  const styles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#E27235" : "",
    }),
  };

  useEffect( () => {
    if (!investorGroupsModalOpen) {
      onGetAppliedFundings();
      setSelectedGroups([]);
      setCountry([]);
      setState([]);
      setCity([]);
    } else {
      let groups = selectedDeal?.investor_groups?.groups || [];
      let countries = selectedDeal?.investor_groups?.countries || [];
      let states = selectedDeal?.investor_groups?.states || [];
      let cities = selectedDeal?.investor_groups?.cities || [];

      setSelectedGroups(
        groups.map(group => ({ value: group._id, label: group.name }))
      );
      setCountry(countries);
      setState(states);
      setCity(cities);
    }
    return () => {};
  }, [investorGroupsModalOpen]);

  useEffect(() => {
    setCountryOptions(fetchCountryOptions);
    fetchGroups();
    return () => {};
  }, []);

  const fetchGroups = () => {
    getGroups()
      .then(res => {
        if (res?.length) {
          let filterGroup = res
            .filter(ele => ele.status === "Active")
            .map(el => {
              if (el.type === "Investor") {
                return el;
              }
              return null;
            })
            .filter(ele => !!ele);

          setGroups(filterGroup);
        } else {
          setGroups([]);
        }
      })
      .catch(error => {});
  };

  useEffect(() => {
    let temp = fetchStateOptions(country.map(country => country.value));
    setStateOptions(temp);
    return () => {};
  }, [country]);

  useEffect(() => {
    let temp = fetchCityOptions(
      country.map(country => country.value),
      state.map(state => state.value)
    );
    setCityOptions(temp);
    return () => {};
  }, [state]);

  const fetchCountryOptions = () =>
    Country.getAllCountries().map(({ name, isoCode }) => ({
      label: name,
      value: isoCode,
    }));

  const fetchStateOptions = (countryCodes = []) => {
    let stateOptions = [];

    for (let countryCode of countryCodes) {
      stateOptions.push(
        ...State.getStatesOfCountry(countryCode).map(({ name, isoCode }) => ({
          label: name,
          value: isoCode,
        }))
      );
    }

    return stateOptions;
  };

  const fetchCityOptions = (countryCodes = [], stateCodes = []) => {
    let cityOptions = [];

    for (let countryCode of countryCodes) {
      for (let stateCode of stateCodes) {
        cityOptions.push(
          ...City.getCitiesOfState(countryCode, stateCode).map(({ name }) => ({
            label: name,
            value: name,
          }))
        );
      }
    }

    return cityOptions;
  };

  return (
    <Card>
      <CardBody>
        <Label>Groups</Label>
        <Row className="mb-3">
          <Col className="col-12">
            <Select
              id="selectedGroups"
              placeholder="Select Group(s)"
              name="selectedGroups"
              className="mb-3"
              options={groups.map(group => ({
                label: group.name,
                value: group._id,
              }))}
              styles={styles}
              value={selectedGroups}
              isMulti={true}
              isClearable={true}
              onChange={groups => setSelectedGroups(groups || [])}
            />
          </Col>
        </Row>
        <Label>Select Location</Label>
        <Row className="mb-3 justify-content-center align-items-center">
          <Col md={4}>
            <FormGroup>
              <Label>Country</Label>
              <Select
                id="country"
                name="country"
                placeholder="Select country"
                options={countryOptions}
                styles={styles}
                value={country}
                onChange={countries => setCountry(countries || [])}
                isMulti
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>State</Label>
              <Select
                id="state"
                name="state"
                placeholder="Select state"
                options={stateOptions}
                styles={styles}
                value={state}
                onChange={states => setState(states || [])}
                isMulti
                isClearable
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>City</Label>
              <Select
                id="city"
                name="city"
                placeholder="Select city"
                options={cityOptions}
                styles={styles}
                value={city}
                onChange={cities => setCity(cities || [])}
                isMulti
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-success save-group"
                onClick={evt => {
                  evt.preventDefault();

                  let user_id = selectedDeal?.user_id;
                  let startup_id = selectedDeal?.startup_id;
                  let q = {
                    user_id,
                    startup_id,
                    groups: selectedGroups.map(group => group.value),
                    country,
                    state,
                    city,
                  }
                  let encryptedQuery = encryptDataToArray(q)
                  let data = {
                    q: JSON.stringify(encryptedQuery)
                  }

                  saveApplyForFundingInvestorGroups(data)
                    .then(response => {
                      if (!response.status) {
                        return setError(response.message);
                      }

                      setError(null);
                      alert(response.message);
                      return handleInvestorGroupsModal();
                    })
                    .catch(error => {
                      setError(error.message);
                    });
                }}
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
        <Row className="text-info">
          <Col>
            <i className="fa fa-asterisk text-danger" /> To display deal to all
            investors, please unselect all groups and locations
          </Col>
        </Row>
        {error && (
          <Row className="text-danger">
            <Col>{error}</Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}

InvestorGroupsModal.propTypes = {
  investorGroupsModalOpen: PropTypes.bool,
  handleInvestorGroupsModal: PropTypes.func,
  selectedDeal: PropTypes.object,
  onGetAppliedFundings: PropTypes.func,
};

const mapStateToProps = ({ appliedFunding }) => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onGetAppliedFundings: () => dispatch(getAppliedFundings()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestorGroupsModal);
