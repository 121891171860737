import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";

const TopInvestments = ({ investmentData = [] }) => {
  return (
    <div className="column-sec">
      <Row className="mb-1 align-items-center">
        <Col md="8">
          <h3 className="mb-3 mt-3 title-sm">Top Investments</h3>
        </Col>
      </Row>
      <div className="table-responsive dashtable">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Deal Name</th>
              <th>Total Investment Made</th>
              <th>No. of Investors</th>
            </tr>
          </thead>
          <tbody>
            {investmentData?.map((investment, index) => (
              <tr key={`${index}-investmentData`}>
                <td>{`${investment?.dealName} [${moment(investment?.deal_date).format("MMM YY")}]`} </td>
                <td>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(investment?.totalFunds)}
                </td>
                <td>{investment?.totalInvestorsCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TopInvestments;
