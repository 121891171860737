import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import moment from "moment";

const RaisedFund = () => {
  const [raisedFundData, setRaisedFundData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState({
    value: null,
    label: `Since ${moment().format("MMM YY")}`,
  });
  const [monthOptions, setMonthOptions] = useState([]);

  const handleChangeMonth = selectedOption => {
    setSelectedMonth(selectedOption);
  };

  const COLORS = [
    "#84b7d9",
    "#fd9d9a",
    "#e34143",
    "#f18e2e",
    "#a988a3",
    "#8cd17e",
  ];

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/raisedFunds`, {
        params: {
          saas_parent_id: authUser?.userInfo?.saas_parent_id,
          show_all_saas_data,
          is_super_admin: authUser?.userInfo?.is_super_admin,
          queryMonth: selectedMonth?.value,
        },
        headers: {
          Authorization: authUser?.token,
        },
      })
      .then(response => {
        const responseData = response?.data?.data;
        setRaisedFundData(responseData?.groupedFunds);

        let dataKeys = [];

        responseData?.groupedFunds?.forEach(funds => {
          Object.keys(funds).forEach(item => {
            if (!dataKeys.includes(item) && item !== "period") {
              dataKeys.push(item);
            }
          });
        });

        setDataKeys(dataKeys);

        const monthData = responseData?.resultMonths?.map(data => ({
          value: data?.period,
          label: "Since " + data?.period,
        }));

        let currentMonthOptionLabel = `Since ${moment().format("MMM YY")}`;
        if (
          !monthData?.find(
            month =>
              month?.label?.toLowerCase() ===
              currentMonthOptionLabel?.toLowerCase()
          )
        ) {
          monthData.unshift({
            label: currentMonthOptionLabel,
            value: null,
          });
        }
        setMonthOptions(monthData);
      })
      .catch(err => {});

    return () => {};
  }, [selectedMonth]);

  const CustomizedTick = graphVal => {
    const { x, y, stroke, payload } = graphVal;
    const [month, year] = payload.value.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={7} fill="#666" fontSize={11}>
          <tspan textAnchor="middle" x="0">
            {month}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="12">
            {year}
          </tspan>
        </text>
      </g>
    );
  };

  const getColorForName = name => {
    const colorMap = {
      "Invstt Trust Fund": "#84b7d9",
      "WFC Global Angels Fund": "#fd9d9a",
      "AngelList India": "#e34143",
      "AngelList US": "#a988a3",
      "Direct Cap-Table": "#8cd17e",
    };
    return (
      colorMap[name] || COLORS[Object.keys(colorMap).length % COLORS.length]
    );
  };
  return (
    <div className="column-sec">
      <Row className="mb-2 d-flex align-items-center">
        <Col md={8}>
          <h2 className="mb-4 mt-3 title-sm">Fund Raised</h2>
        </Col>
        <Col md={4}>
          <Select
            className="custom-select form-control-sm"
            id="period"
            name="period"
            options={monthOptions}
            value={selectedMonth}
            onChange={handleChangeMonth}
          />
        </Col>
      </Row>
      <Row>
        <div className="mt-4 mb-4">
          <ResponsiveContainer width="100%" height={285}>
            <BarChart
              data={raisedFundData}
              margin={{
                top: 5,
                right: 15,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="period" tick={<CustomizedTick />} interval={0} />
              <YAxis
                tickFormatter={value =>
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(value)
                }
              />
              <Tooltip
                formatter={value =>
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(value)
                }
              />
              {dataKeys.map((key, index) => (
                <Bar
                  dataKey={key}
                  key={"r" + index}
                  stackId="a"
                  fill={getColorForName(key)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Row>
    </div>
  );
};

export default RaisedFund;
