import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Col, Row, Table } from "reactstrap";
import { Link, withRouter } from "react-router-dom"
import { getPostEventList, storeOtherDetails } from "../../store/pitchdays/actions"
import { getAssessmentReport } from "../../store/appliedFunding/actions"
import axios from "axios";
import authHeader from "../../../src/helpers/jwt-token-access/auth-token-header";
class EventEngadgementActivity extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            engagementActivities: []
        };
    }

    async componentDidMount() {
        const { match } = this.props
        const token = authHeader();

        await axios.get(process.env.REACT_APP_API_URL + `/api/event/engagement/activity/${match?.params?.id}`,
            { headers: { Authorization: `${token}` } }
        ).then((response) => {
            if (response?.status === 200) {
                this.setState({ engagementActivities: response?.data?.events?.engagement_activities });
            }
        }).catch((error) => {
        });
    }

    render() {
        const { engagementActivities } = this.state
        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>

                        <Table className='table table-striped w-100'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Event Name</th>
                                    <th>Event Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    engagementActivities && engagementActivities.map((engagementActivitiesVal, engagementActivitiesKey) => {
                                        return (<tr key={engagementActivitiesKey}>
                                            <th>1</th>
                                            <td>{engagementActivitiesVal?.event?.title}</td>
                                            <td>{engagementActivitiesVal?.event?.date?.event_date}</td>
                                            <td>
                                                <Link to={`/create-event-report/${engagementActivitiesVal?.event?._id}`} className="btn btn-info">
                                                    View Report
                                                </Link>
                                            </td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment >
        )
    }
}

EventEngadgementActivity.propTypes = {
    onGetPitchDays: PropTypes.func,
    onGetAssessmentReport: PropTypes.func,
    className: PropTypes.any,
    error: PropTypes.any,
    match: PropTypes.any,
    onGetPostEventList: PropTypes.func,
    onStoreOtherDetails: PropTypes.func,
    postEvents: PropTypes.array,
    appliedFundingDealId: PropTypes.string,
    assessmentReport: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        assessmentReport: state?.appliedFunding?.assessmentReportSuccess?.assessmentReport,
        postEvents: state.pitchdays.postEvents,
        error: state.pitchdays.errorPostEvent,
    }
}

// const mapStateToProps = ({ pitchdays }) => ({
//     postEvents: pitchdays.postEvents,
//     error: pitchdays.errorPostEvent,
// })

const mapDispatchToProps = dispatch => ({
    onGetPostEventList: () => dispatch(getPostEventList()),
    onStoreOtherDetails: (payload) => dispatch(storeOtherDetails(payload)),
    onGetAssessmentReport: (payload) => dispatch(getAssessmentReport(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventEngadgementActivity))
