import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { Form, Tab, Tabs } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import Breadcrumbs from "components/Common/Breadcrumb";

import {
  addPaymentTransactions,
  getPartnerReferralAllTransactions,
  getPastInvestmentPaymentDetails,
  getUserAndPartnerDetails,
} from "../../../helpers/backend_helper";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import AddManualPayoutTransaction from "./AddManualPayoutTransaction";
import { uniq } from "lodash";
import {
  VALUE_SEPARATOR,
  formatNumberToCommaSeparatedCurrency,
  formatNumberToCommaSeparatedString,
} from "helpers/common";

const SyndicateDetails = props => {
  const userId = useRef(props.match.params.user_id);
  const [userData, setUserData] = useState({});
  const [partnerData, setPartnerData] = useState({});

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment().set({ month: 8, date: 1, year: 2022 }).format()
    ),
    endDate: new Date(moment().format()),
  });
  const [query, setQuery] = useState({
    search: null,
  });
  const [showAll, setShowAll] = useState(1);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const allCurrencies =
    JSON.parse(localStorage.getItem("allCurrencies") || "{}")?.data || [];
  const [syndicatePayouts, setSyndicatePayouts] = useState({});
  const [manualPayoutsV2, setManualPayoutsV2] = useState({});
  const [manualPayouts, setManualPayouts] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowPayoutType, setSelectedRowPayoutType] = useState({});

  const [payFlag, setPayFlag] = useState(false);

  const [isAddManualPayout, setIsAddManualPayout] = useState(true);
  const [editPayoutDetails, setEditPayoutDetails] = useState({});

  const [payModal, setPayModal] = useState(false);
  const [payDate, setPayDate] = useState(new Date());
  const [payReferenceId, setPayReferenceId] = useState("");
  const [payComment, setPayComment] = useState("");
  const [payBillingName, setPayBillingName] = useState("");
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [dueUSD, setDueUSD] = useState(null);
  const [UsdInrRate, setUsdInrRate] = useState(null);

  const togglePayModal = () => {
    if (payModal) {
      setSelectedRows([]);
    }
    setPayModal(!payModal);
  };

  const openPayModal = () => {
    setPayDate(new Date());
    setPayReferenceId("");
    setPayComment("");
    setPayBillingName("");
    setInvoiceFile(null);
    setPayModal(true);
    setDueUSD(null);
    setUsdInrRate(null);
  };

  const [payDetailsModal, setPayDetailsModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});

  const togglePayDetailsModal = () => {
    if (payDetailsModal) {
      setPaymentDetails({});
    }
    setPayDetailsModal(!payDetailsModal);
  };

  const [manualPayoutModal, setManualPayoutModal] = useState(false);
  const toggleManualPayoutModal = () => {
    if (manualPayoutModal) {
      setIsAddManualPayout(true);
      setEditPayoutDetails({});
    }

    setManualPayoutModal(!manualPayoutModal);
  };

  const getReferralTransactionTableColumns = (
    transactionType,
    transactionCurrency
  ) => {
    return [
      {
        text: "ID",
        dataField: "_id",
        sort: false,
        hidden: true,
        formatter: (cell, data) => data._id,
      },
      {
        text: "Transaction Date",
        dataField: "createdAt",
        sort: false,
        formatter: (cell, data) => {
          let createdAt = moment(data.createdAt).format("DD MMM, YYYY");

          return <>{createdAt}</>;
        },
      },
      {
        text: "Investor Name",
        dataField: "user_id",
        sort: false,
        formatter: (cell, data) => {
          let { user_id } = data || {};

          if (!user_id) {
            return <></>;
          }

          let { firstname, lastname, userName } = user_id || {};

          let investorName =
            `${firstname || ""} ${lastname || ""}`.trim() || userName;

          return <>{investorName}</>;
        },
      },
      {
        text: "Startup Name",
        dataField: "past_investment_id.startup_id",
        sort: false,
        formatter: (cell, data) => {
          let startupName =
            data?.past_investment_id?.startup_id?.basic_information
              ?.startup_name;

          return <>{startupName}</>;
        },
      },
      {
        text: "Round (Date)",
        dataField: "past_investment_id.deal_id",
        sort: false,
        formatter: (cell, data) => {
          let fundingRound =
            data?.past_investment_id?.deal_id?.funding_information
              ?.funding_round;
          let dealDate =
            data?.past_investment_id?.deal_id?.funding_information?.deal_date;

          if (dealDate) {
            dealDate = moment(dealDate).format("MMM, YYYY");
          }

          return (
            <>
              {fundingRound}
              {dealDate && <> ({dealDate})</>}
            </>
          );
        },
      },
      {
        text: "Amount Invested",
        dataField: "additional_details.investmentAmount",
        sort: false,
        formatter: (cell, data, index, extraData) => {
          let transactionCurrency = extraData?.transactionCurrency;

          let investmentAmount = formatNumberToCommaSeparatedCurrency(
            data?.additional_details?.investmentAmount,
            transactionCurrency
          );

          return <>{investmentAmount}</>;
        },
        formatExtraData: { transactionCurrency },
      },
      {
        text: "Payout Fees",
        dataField: "payment_amount",
        sort: false,
        formatter: (cell, data, index, extraData) => {
          let transactionCurrency = extraData?.transactionCurrency;

          let payment_amount = data?.payment_amount;

          let payoutFee = formatNumberToCommaSeparatedCurrency(
            payment_amount,
            transactionCurrency
          );

          let { syndicateFeeValue, dealManagementFeeValue } =
            data?.additional_details || {};

          if (data?.transaction_type === "Syndicate Payout") {
            payoutFee += ` @ [${formatNumberToCommaSeparatedString(
              dealManagementFeeValue,
              transactionCurrency
            )} - ${formatNumberToCommaSeparatedString(
              syndicateFeeValue,
              transactionCurrency
            )}]%`;
          }

          return <>{payoutFee}</>;
        },
        formatExtraData: { transactionCurrency },
      },
      {
        text: "Payout Reference ID",
        dataField: "payment_reference_id",
        sort: false,
        formatter: (cell, data) => {
          let { payment_reference_id } = data || {};

          return <>{payment_reference_id}</>;
        },
      },
      {
        text: "Action",
        dataField: "menu",
        isDummyField: true,
        editable: false,
        sort: false,
        formatter: (cell, data, index, extraData) => {
          let payButtonDisabled;
          let manualPayoutEditable = false;
          // for manual payouts
          if (
            data?.is_manual_payout &&
            data?.transaction_status === "pending" &&
            data?.payout_partner_id?.toString() !== userData?._id?.toString()
          ) {
            payButtonDisabled = true;
          }

          if (
            data?.is_manual_payout &&
            data?.transaction_status === "pending"
          ) {
            manualPayoutEditable = true;
          }

          // for referral investments
          if (
            !!data?.past_investment_id?.past_investment &&
            data?.past_investment_id?.past_investment?.partner_user_id?._id?.toString() !==
              userData?._id?.toString()
          ) {
            payButtonDisabled = true;
          }

          // for subscription payouts
          if (
            data?.transaction_type === "Subscription Payout" &&
            data?.saas_parent_id !== userData?._id?.toString()
          ) {
            payButtonDisabled = true;
          }

          // for self-investment payouts
          if (data?.transaction_type === "Self Investment") {
            payButtonDisabled = false;
          }

          if (
            data?.transaction_type === "Syndicate Payout" &&
            data?.saas_parent_id?.toString() === userData?._id?.toString()
          ) {
            payButtonDisabled = false;
          }

          return (
            <div className="d-flex gap-2 text-center">
              {data?.payment_date ? (
                <Button
                  size="sm"
                  color="success"
                  onClick={() => {
                    handleFetchPaymentDetails(data._id);
                  }}
                >
                  Details
                </Button>
              ) : (
                <>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => {
                      if (!data._id) {
                        return;
                      }
                      setSelectedRows([data]);
                      openPayModal();
                    }}
                    disabled={payButtonDisabled}
                  >
                    Pay
                  </Button>
                  {manualPayoutEditable && (
                    <>
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          if (!data._id) {
                            return;
                          }
                          setIsAddManualPayout(false);
                          setEditPayoutDetails(data);
                          toggleManualPayoutModal();
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          );
        },
        formatExtraData: { transactionType, transactionCurrency },
      },
    ];
  };

  const getManualPayoutTableColumns = (
    transactionType,
    transactionCurrency
  ) => {
    return [
      {
        text: "ID",
        dataField: "_id",
        sort: false,
        hidden: true,
        formatter: (cell, data) => data._id,
      },
      {
        text: "Date",
        dataField: "createdAt",
        sort: false,
        formatter: (cell, data) => {
          let createdAt;
          if (data?.is_manual_payout) {
            createdAt = moment(data?.additional_details?.payout_date).format(
              "DD MMM, YYYY"
            );
          } else {
            createdAt = moment(data.createdAt).format("DD MMM, YYYY");
          }
          return <>{createdAt}</>;
        },
      },
      {
        text: "Transaction Type",
        dataField: "transaction_type",
        sort: false,
        formatter: (cell, data) => {
          return <>{data?.transaction_type}</>;
        },
      },
      {
        text: "Payment Details",
        dataField: "user_id",
        sort: false,
        formatter: (cell, data, index, extraData) => {
          let { transactionType, transactionCurrency } = extraData;
          let detail = "";

          let { userName, firstname, lastname } = data?.user_id || {};
          let investorName =
            `${firstname || ""} ${lastname || ""}`.trim() || userName;

          if (data?.is_manual_payout) {
            let {
              transaction_type,
              additional_details: { paid_amount },
            } = data || {};

            paid_amount = parseFloat(paid_amount || 0);

            paid_amount = formatNumberToCommaSeparatedCurrency(
              paid_amount,
              transactionCurrency
            );

            detail = `${investorName}</br>${transaction_type} : <b>${paid_amount}</b>`;
          } else {
            switch (data?.transaction_type) {
              case "Subscription Payout":
                let {
                  additional_details: { credit_payment_amount },
                } = data || {};
                let amount = formatNumberToCommaSeparatedCurrency(
                  parseInt(credit_payment_amount || 0),
                  transactionCurrency
                );
                detail = `${investorName}</br>Purchased credit pack worth <b>${amount}</b>`;
                break;

              case "Self Investment":
                let {
                  additional_details: { service_fees: transactionFees },
                  past_investment_id: {
                    past_investment: { amount_invested: selfInvestmentAmount },
                    startup_id: {
                      basic_information: { startup_name: startupName },
                    },
                  },
                } = data || {};
                detail = `Self Investment of <b>${formatNumberToCommaSeparatedCurrency(
                  selfInvestmentAmount || 0,
                  transactionCurrency
                )}</b> in ${startupName}</br>Service Fee: <b>${formatNumberToCommaSeparatedCurrency(
                  transactionFees,
                  transactionCurrency
                )}</b>`;
                break;

              case "Referral Investment":
              default:
                let {
                  past_investment_id: {
                    past_investment: { amount_invested, service_fees },
                    startup_id: {
                      basic_information: { startup_name },
                    },
                  },
                } = data || {};

                amount_invested = parseInt(amount_invested || 0);
                service_fees = parseInt(service_fees || 0);

                let investedAmount = formatNumberToCommaSeparatedCurrency(
                  amount_invested,
                  transactionCurrency
                );
                let serviceFees = formatNumberToCommaSeparatedCurrency(
                  service_fees,
                  transactionCurrency
                );

                detail = `${investorName} invested <b>${investedAmount}</b> in ${startup_name}</br>Service Fee: <b>${serviceFees}</b>`;
                break;
            }
          }

          return <span dangerouslySetInnerHTML={{ __html: detail }}></span>;
        },
        formatExtraData: { transactionType, transactionCurrency },
      },
      {
        text: "Payout Fee",
        dataField: "payout_fee",
        sort: false,
        formatter: (cell, data, index, extraData) => {
          let { transactionType, transactionCurrency } = extraData;
          let shareAmount = 0;

          if (data?.is_manual_payout) {
            let {
              payment_amount,
              additional_details: { paid_amount_fee },
            } = data || {};

            payment_amount = parseFloat(payment_amount || 0);
            shareAmount = formatNumberToCommaSeparatedCurrency(
              payment_amount,
              transactionCurrency
            );

            paid_amount_fee = parseFloat(paid_amount_fee || 0);

            if (paid_amount_fee) {
              paid_amount_fee = formatNumberToCommaSeparatedString(
                paid_amount_fee,
                transactionCurrency
              );
              shareAmount += ` @ ${paid_amount_fee}%`;
            }
          } else {
            if (data?.past_investment_id?.past_investment === undefined) {
              shareAmount = `${formatNumberToCommaSeparatedCurrency(
                data?.payment_amount,
                transactionCurrency
              )} @ ${formatNumberToCommaSeparatedString(
                data?.additional_details?.saas_subscription_fee,
                transactionCurrency
              )}%`;
            } else {
              if (data?.transaction_type === "Self Investment") {
                let {
                  payment_amount,
                  additional_details: { transaction_fee_disc_self_investments },
                } = data || {};
                shareAmount = formatNumberToCommaSeparatedCurrency(
                  payment_amount || 0,
                  transactionCurrency
                );
                if (payment_amount && transaction_fee_disc_self_investments) {
                  shareAmount += ` @ ${formatNumberToCommaSeparatedString(
                    transaction_fee_disc_self_investments,
                    transactionCurrency
                  )}%`;
                }
              } else {
                let { service_fees, partner_transaction_fee } =
                  data?.past_investment_id?.past_investment || {};
                service_fees = service_fees || 0;
                partner_transaction_fee = partner_transaction_fee || 0;

                shareAmount = formatNumberToCommaSeparatedCurrency(
                  (
                    (parseInt(service_fees) *
                      parseInt(partner_transaction_fee)) /
                    100
                  ).toFixed(0) || 0,
                  transactionCurrency
                );

                if (partner_transaction_fee) {
                  shareAmount += ` @ ${formatNumberToCommaSeparatedString(
                    partner_transaction_fee,
                    transactionCurrency
                  )}%`;
                }
              }
            }
          }
          return <>{shareAmount}</>;
        },
        formatExtraData: { transactionType, transactionCurrency },
      },
      {
        text: "Payout Reference ID",
        dataField: "payment_reference_id",
        sort: false,
        formatter: (cell, data) => {
          let { payment_reference_id } = data || {};

          return <>{payment_reference_id}</>;
        },
      },
      {
        text: "Action",
        dataField: "menu",
        isDummyField: true,
        editable: false,
        sort: false,
        formatter: (cell, data) => {
          let payButtonDisabled;
          let manualPayoutEditable = false;
          // for manual payouts
          if (
            data?.is_manual_payout &&
            data?.transaction_status === "pending" &&
            data?.payout_partner_id?.toString() !== userData?._id?.toString()
          ) {
            payButtonDisabled = true;
          }

          if (
            data?.is_manual_payout &&
            data?.transaction_status === "pending"
          ) {
            manualPayoutEditable = true;
          }

          // for referral investments
          if (
            !!data?.past_investment_id?.past_investment &&
            data?.past_investment_id?.past_investment?.partner_user_id?._id?.toString() !==
              userData?._id?.toString()
          ) {
            payButtonDisabled = true;
          }

          // for subscription payouts
          if (
            data?.transaction_type === "Subscription Payout" &&
            data?.saas_parent_id !== userData?._id?.toString()
          ) {
            payButtonDisabled = true;
          }

          // for self-investment payouts
          if (data?.transaction_type === "Self Investment") {
            payButtonDisabled = false;
          }

          return (
            <div className="d-flex gap-2 text-center">
              {data?.payment_date ? (
                <Button
                  size="sm"
                  color="success"
                  onClick={() => {
                    handleFetchPaymentDetails(data._id);
                  }}
                >
                  Details
                </Button>
              ) : (
                <>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => {
                      if (!data._id) {
                        return;
                      }
                      setSelectedRows([data]);
                      openPayModal();
                    }}
                    disabled={payButtonDisabled}
                  >
                    Pay
                  </Button>
                  {manualPayoutEditable && (
                    <>
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          if (!data._id) {
                            return;
                          }
                          setIsAddManualPayout(false);
                          setEditPayoutDetails(data);
                          toggleManualPayoutModal();
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    ];
  };

  const TableOptions = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "Next page",
    prePageTitle: "Previous page",
    firstPageTitle: "First page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => {
      if (!size) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            No payouts to show
          </span>
        );
      }

      if (size === 1) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            Showing {size} of {size}
          </span>
        );
      }

      return (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from}-{to} of {size}
        </span>
      );
    },
    disablePageTitle: false,
  };

  const defaultSorted = [
    {
      dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: selectedRows.map(row => row._id),
    nonSelectable: paymentTransactions
      .filter(row => {
        // for manual payouts
        if (
          row?.is_manual_payout &&
          row?.transaction_status === "pending" &&
          row?.payout_partner_id?.toString() !== userData?._id?.toString()
        ) {
          return true;
        }

        // for self-investment payouts
        if (row?.transaction_type === "Self Investment") {
          return row?.transaction_status !== "pending";
        }

        // for syndicate payout
        if (
          row?.transaction_type === "Syndicate Payout" &&
          row?.saas_parent_id?.toString() === userData?._id?.toString()
        ) {
          return row?.transaction_status !== "pending";
        }

        // for referral investments
        if (
          !!row?.past_investment_id?.past_investment &&
          row?.past_investment_id?.past_investment?.partner_user_id?._id?.toString() !==
            userData?._id?.toString()
        ) {
          return true;
        }

        // for subscription payouts
        if (
          row?.transaction_type === "Subscription Payout" &&
          row?.saas_parent_id !== userData?._id?.toString()
        ) {
          return true;
        }

        // for pending transactions
        return row?.transaction_status !== "pending";
      })
      .map(row => row._id),

    onSelectAll: (isSelected, rows) => {
      let tempArray = [];
      if (isSelected) {
        tempArray = [
          ...new Map([
            ...selectedRows.map(row => [row._id, row]),
            ...rows.map(row => [row._id, row]),
          ]).values(),
        ];
      } else {
        tempArray = selectedRows.filter(
          row => !rows.find(row1 => row1._id === row._id)
        );
      }

      setSelectedRows(tempArray);
    },

    onSelect: (row, isSelected) => {
      let tempArray = [];
      if (isSelected) {
        tempArray = [
          ...new Map([
            ...selectedRows.map(row => [row._id, row]),
            [row._id, row],
          ]).values(),
        ];
      } else {
        tempArray = selectedRows.filter(row1 => !(row._id === row1._id));
      }

      setSelectedRows(tempArray);
    },
  };

  useEffect(() => {
    fetchUserAndPartnerDetails();

    return () => {};
  }, []);

  const fetchUserAndPartnerDetails = async () => {
    try {
      let response = await getUserAndPartnerDetails(userId?.current);

      if (!response.status) {
        // console.error(response);
        let message =
          response?.message || "Something went wrong while fetching data";
        setUserData({});
        setPartnerData({});
        return alert(message);
      }

      let { user, partner } = response?.data || {};

      let { saas_fullname, saas_name, email } = user;
      let userTitle = `${saas_fullname || ""}`.trim() || saas_name;
      userTitle = userTitle
        ? `${userTitle} ${email ? "(" + email + ")" : ""}`.trim()
        : email || "";

      setUserData({ ...(user || {}), userTitle });
      setPartnerData(partner || {});
      return;
    } catch (error) {
      // console.error(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while fetching data";
      setUserData({});
      setPartnerData({});
      return alert(message);
    }
  };

  useEffect(() => {
    if (!manualPayoutModal) {
      fetchPartnerReferralInvestments(showAll, dateRange);
    }

    return () => {};
  }, [showAll, payFlag, manualPayoutModal, dateRange]);

  const fetchPartnerReferralInvestments = async (showAll, dateRange) => {
    try {
      let fromDate, toDate;

      if (dateRange?.startDate && dateRange?.endDate) {
        fromDate = moment(dateRange.startDate)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format();
        toDate = moment(dateRange.endDate)
          .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
          .format();
      }

      let response = await getPartnerReferralAllTransactions(
        userId?.current,
        showAll,
        fromDate,
        toDate,
        "syndicate"
      );

      if (!response.status) {
        // console.error(response);
        let message =
          response?.message || "Something went wrong while fetching data";
        setPaymentTransactions([]);
        return alert(message);
      }

      setPaymentTransactions(response?.data || []);
      return;
    } catch (error) {
      //console.error(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while fetching data";
      setPaymentTransactions([]);
      alert(message);
      return;
    }
  };

  useEffect(() => {
    try {
      let referralTransactionTypes = paymentTransactions
        ?.filter(
          data =>
            !data?.is_manual_payout &&
            ["Syndicate Payout"].includes(data?.transaction_type)
        )
        ?.map(transaction => ({
          value:
            transaction?.past_investment_id?.investment_vehicle?.value ||
            "Invstt Trust",
          label:
            transaction?.past_investment_id?.investment_vehicle?.label ||
            "Invstt Trust",
          currency:
            transaction?.past_investment_id?.investment_vehicle?.currency ||
            "INR",
        }))
        ?.filter(data => data);

      let syndicatePayouts = {};

      referralTransactionTypes = [
        ...new Map(
          referralTransactionTypes.map(type => [type.label, type])
        ).values(),
      ];

      for (let { label, currency } of uniq(referralTransactionTypes).sort()) {
        let filteredTransactions = paymentTransactions.filter(
          data =>
            !data?.is_manual_payout &&
            ["Syndicate Payout"].includes(data?.transaction_type) &&
            [data?.past_investment_id?.investment_vehicle?.label].includes(
              label
            )
        );

        currency = currency || "INR";
        let fundCurrency = allCurrencies?.find(
          curr => curr?.value === currency
        );

        fundCurrency = {
          label: fundCurrency?.label || "Indian Rupee",
          value: fundCurrency?.value || "INR",
          symbol: fundCurrency?.symbol || "₹",
          locale: fundCurrency?.locale || "en-IN",
        };

        syndicatePayouts[label] = {
          values: filteredTransactions,
          currency: fundCurrency,
        };
      }
      setSyndicatePayouts(syndicatePayouts);

      let manualPayoutsFiltered =
        paymentTransactions?.filter(
          data =>
            !!data?.is_manual_payout ||
            (!data?.is_manual_payout &&
              ![
                "Self Investment",
                "Referral Investment",
                "Syndicate Payout",
              ].includes(data?.transaction_type))
        ) || [];

      let manualPayoutInvestmentVehicles = manualPayoutsFiltered
        ?.filter(
          transaction =>
            !!(
              transaction?.investment_vehicle?.value &&
              (transaction?.manual_payout_syndicate_id?.saas_name ||
                transaction?.manual_payout_syndicate_id?.saas_fullname)
            )
        )
        ?.map(transaction => {
          let syndicateName =
            transaction?.manual_payout_syndicate_id?.saas_name ||
            transaction?.manual_payout_syndicate_id?.saas_fullname;
          let investmentVehicle = transaction?.investment_vehicle?.value;
          let currency = transaction?.investment_vehicle?.currency;

          if (!(investmentVehicle && syndicateName && currency)) {
            return null;
          }

          return {
            value: `${syndicateName}${VALUE_SEPARATOR}${investmentVehicle}${VALUE_SEPARATOR}${currency}`,
            label: `Manual Payouts (${syndicateName} - ${investmentVehicle} [${currency}])`,
            syndicateName,
            investmentVehicle,
            currency,
          };
        })
        ?.filter(data => !!data?.value);

      manualPayoutInvestmentVehicles = [
        ...new Map(
          manualPayoutInvestmentVehicles?.map(type => [type?.value, type])
        ).values(),
      ];

      let manualPayoutsV2 = {};

      for (let {
        value,
        label,
        syndicateName,
        investmentVehicle,
        currency,
      } of manualPayoutInvestmentVehicles) {
        let filteredTransactions = manualPayoutsFiltered?.filter(data => {
          let syndicateName =
            data?.manual_payout_syndicate_id?.saas_name ||
            data?.manual_payout_syndicate_id?.saas_fullname;
          let investmentVehicle = data?.investment_vehicle?.value;
          let currency = data?.investment_vehicle?.currency;

          if (!(investmentVehicle && syndicateName && currency)) {
            return false;
          }
          return [
            `${syndicateName}${VALUE_SEPARATOR}${investmentVehicle}${VALUE_SEPARATOR}${currency}`,
          ].includes(value);
        });

        currency = currency || "INR";

        let fundCurrency = allCurrencies?.find(
          curr => curr?.value === currency
        );

        fundCurrency = {
          label: fundCurrency?.label || "Indian Rupee",
          value: fundCurrency?.value || "INR",
          symbol: fundCurrency?.symbol || "₹",
          locale: fundCurrency?.locale || "en-IN",
        };

        manualPayoutsV2[label] = {
          value,
          syndicateName,
          investmentVehicle,
          currency: fundCurrency,
          values: filteredTransactions || [],
        };
      }

      setManualPayoutsV2(manualPayoutsV2);

      // filter-out newly added manual-payouts
      let manualPayoutIds = [];
      for (let { values } of Object.values(manualPayoutsV2)) {
        manualPayoutIds.push(
          ...values?.map(data => data?._id)?.filter(data => data)
        );
      }
      manualPayoutsFiltered = manualPayoutsFiltered?.filter(
        data => !manualPayoutIds?.includes(data?._id)
      );

      let usdManualPayouts =
        manualPayoutsFiltered?.filter(
          data => data?.transaction_currency?.value === "USD"
        ) || [];
      let inrManualPayouts =
        manualPayoutsFiltered?.filter(
          data => (data?.transaction_currency?.value || "INR") === "INR"
        ) || [];

      let usdCurrency = allCurrencies?.find(curr => curr?.value === "USD");
      let inrCurrency = allCurrencies?.find(curr => curr?.value === "INR");

      let manualPayouts = {
        "Other Payouts (USD)": {
          currency: usdCurrency,
          values: usdManualPayouts,
        },
        "Other Payouts (INR)": {
          currency: inrCurrency,
          values: inrManualPayouts,
        },
      };

      setManualPayouts(manualPayouts);

      let allTabKeys = [
        ...Object.keys(syndicatePayouts)
          ?.filter(key => !!syndicatePayouts?.[key]?.values?.length)
          ?.map(key => ({
            label: key,
            currency: syndicatePayouts?.[key]?.currency,
          })),
        ...Object.keys(manualPayoutsV2)
          ?.filter(key => !!manualPayoutsV2?.[key]?.values?.length)
          ?.map(key => ({
            label: key,
            currency: manualPayoutsV2?.[key]?.currency,
          })),
        ...Object.keys(manualPayouts)
          ?.filter(key => !!manualPayouts?.[key]?.values?.length)
          ?.map(key => ({
            label: key,
            currency: manualPayouts?.[key]?.currency,
          })),
      ];

      setSelectedRowPayoutType(allTabKeys?.[0] || {});
      return () => {};
    } catch (error) {
      // console.log(error?.message);
      setSyndicatePayouts({});
      setManualPayoutsV2({});
      setManualPayouts({});
      setSelectedRowPayoutType({});
      return () => {};
    }
  }, [paymentTransactions]);

  const handleRemoveSelectedRow = index => {
    let tempArr = selectedRows.filter((row, idx) => idx !== index);
    if (!tempArr.length) {
      return togglePayModal();
    }
    setSelectedRows(tempArr);
  };

  const handleAddTransactionPayments = async () => {
    let transactionIds = selectedRows
      .map(row => row?._id || nul)
      .filter(data => !!data);

    if (!transactionIds?.length) {
      return;
    }

    if (!(payDate && payReferenceId)) {
      return alert("Please enter all required fields");
    }

    try {
      let dueINR = (
        parseFloat(dueUSD || 0) * parseFloat(UsdInrRate || 0)
      ).toFixed(2);
      let response = await addPaymentTransactions({
        transactionIds: transactionIds?.join(",") || "",
        partnerUserId: userData._id,
        payDate,
        payReferenceId,
        payComment,
        payBillingName,
        invoiceFile: invoiceFile?.file,
        transaction_currency: JSON.stringify(
          selectedRowPayoutType?.currency || {}
        ),
        amount_due_usd: parseFloat(dueUSD || 0),
        usd_inr_rate: parseFloat(UsdInrRate || 0),
        amount_due_inr: parseFloat(dueINR || 0),
      });

      if (!response?.status) {
        let message =
          response?.message || "Something went wrong while adding payments";
        return alert(message);
      }

      setPayFlag(!payFlag);
      setSelectedRows([]);

      let message = response?.message || "Payment Successfully Added";
      alert(message);

      return togglePayModal();
    } catch (error) {
      //  console.log(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while adding payments";
      return alert(message);
    }
  };

  const handleFetchPaymentDetails = async paymentId => {
    try {
      let response = await getPastInvestmentPaymentDetails({ paymentId });

      if (!response?.status) {
        let message =
          response?.message ||
          "Something went wrong while fetching payment details";
        return alert(message);
      }

      let payment = response?.data?.payment || {};
      let paymentCurrency = payment?.transaction_currency;
      let obj = {
        createdAt: moment(payment.createdAt).format("DD MMM, YYYY"),
        payment_date: moment(payment.payment_date).format("DD MMM, YYYY"),
        transaction_type: payment.transaction_type || "Investment",
        payment_amount: formatNumberToCommaSeparatedCurrency(
          payment.payment_amount || 0,
          paymentCurrency
        ),
        payment_reference_id: payment.payment_reference_id || "",
        payment_comments: payment.payment_comments || "",
        payBillingName: payment.pay_billing_name,
        invoiceFile: payment?.invoice_link,
        amount_due_usd: formatNumberToCommaSeparatedCurrency(
          payment?.amount_due_usd,
          allCurrencies?.find(curr => curr?.value === "USD")
        ),
        usd_inr_rate: formatNumberToCommaSeparatedString(
          payment?.usd_inr_rate,
          paymentCurrency
        ),
        amount_due_inr: formatNumberToCommaSeparatedCurrency(
          payment?.amount_due_inr,
          allCurrencies?.find(curr => curr?.value === "INR")
        ),
      };

      let { investor_id, past_investment, startup_id, deal_id } =
        payment?.past_investment_id || {};
      let { partner_user_id, paid_partner_user_id } = past_investment || {};

      if (investor_id) {
        let { firstname, lastname, userName } = investor_id;
        obj["investorName"] =
          `${firstname || ""} ${lastname || ""}`.trim() || userName;
      }

      // Subscription payout data
      if (payment?.user_id) {
        let { firstname, lastname, userName } = payment?.user_id;
        obj["investorName"] =
          `${firstname || ""} ${lastname || ""}`.trim() || userName;
      }

      if (payment?.additional_details) {
        let user_transaction_details =
          payment?.additional_details?.user_transaction_id?.additional_details;
        obj["investorCreditAmount"] =
          payment?.additional_details?.credit_payment_amount;
        obj["purchasedCredit"] = user_transaction_details?.credits;
        obj["planName"] = user_transaction_details?.plan_name;
      }

      if (payment?.saas_parent_id) {
        let { firstname, lastname } = payment?.saas_parent_id;
        obj["paidByName"] = `${firstname || ""} ${lastname || ""}`;
      }

      if (partner_user_id) {
        let { firstname, lastname, userName } = partner_user_id;
        obj["referenceName"] =
          `${firstname || ""} ${lastname || ""}`.trim() || userName;
      }

      if (paid_partner_user_id) {
        let { firstname, lastname, userName } = paid_partner_user_id;
        obj["paidByName"] =
          `${firstname || ""} ${lastname || ""}`.trim() || userName;
      }

      if (startup_id) {
        let { startup_name } = startup_id.basic_information;
        obj["startupName"] = startup_name;
      }

      if (deal_id) {
        let { funding_round, deal_date } = deal_id.funding_information;

        let value = funding_round;
        if (deal_date) {
          value += ` (${moment(deal_date).format("MMM, YYYY")})`;
        }
        obj["startupRound"] = value;
      }

      // for manual payouts
      if (payment?.is_manual_payout) {
        let {
          payout_partner_id,
          additional_details: { payout_date, paid_amount, paid_amount_fee },
        } = payment;
        let { firstname, lastname, userName } = payout_partner_id || {};
        obj = {
          ...obj,
          is_manual_payout: true,
          paidByName: `${firstname || ""} ${lastname || ""}`.trim() || userName,
          payoutDate: moment(payout_date).format("DD MMM, YYYY"),
          paidAmount: formatNumberToCommaSeparatedCurrency(
            paid_amount || 0,
            paymentCurrency
          ),
          paidAmountFee: formatNumberToCommaSeparatedString(
            paid_amount_fee || 0,
            paymentCurrency
          ),
        };
      }

      setPaymentDetails(obj);
      setPayDetailsModal(true);
    } catch (error) {
      // console.log(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while fetching payment details";
      return alert(message);
    }
  };

  const handleTabSelectChange = key => {
    let transactionCurrency = [
      ...Object.keys(syndicatePayouts)?.map(key => ({
        type: key,
        currency: syndicatePayouts?.[key]?.currency,
      })),
      ...Object.keys(manualPayoutsV2)?.map(key => ({
        type: key,
        currency: manualPayoutsV2?.[key]?.currency,
      })),
      ...Object.keys(manualPayouts)?.map(key => ({
        type: key,
        currency: manualPayouts?.[key]?.currency,
      })),
    ]?.find(type => type?.type === key)?.currency;

    setSelectedRowPayoutType({
      label: key,
      currency: transactionCurrency,
    });
    setSelectedRows([]);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Syndicate Payout | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Syndicate" breadcrumbItem="Syndicate Payout" />

          {!!userData?.userTitle && (
            <>
              <Row className="m-0">
                <Col>
                  <Label className="m-0">
                    Syndicate : {userData?.userTitle}
                  </Label>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="mb-2 text-end d-flex flex-sm-row flex-column gap-2 justify-content-end">
                    <div>
                      <Input
                        type="text"
                        placeholder="Filter by startup, investor..."
                        className="border-dark"
                        value={query?.search}
                        onChange={e => {
                          let tempQuery = query;
                          setQuery({
                            ...tempQuery,
                            search: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="d-flex gap-2 justify-content-end">
                      <DateRangePicker
                        initialSettings={{
                          ...dateRange,
                          minDate: moment().set({
                            month: 8,
                            date: 1,
                            year: 2022,
                          }),
                          maxDate: moment(),
                          autoApply: true,
                          linkedCalendars: false,
                          showDropdowns: true,
                          ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                            "Last Year": [
                              moment().subtract(1, "year").startOf("year"),
                              moment().subtract(1, "year").endOf("year"),
                            ],
                          },
                        }}
                        onApply={(event, picker) => {
                          setDateRange({
                            startDate: picker?.startDate?._d,
                            endDate: picker?.endDate?._d,
                          });
                        }}
                      >
                        <button className="btn btn-outline-dark">
                          {moment(dateRange?.startDate).format("DD MMM, YYYY")}{" "}
                          - {moment(dateRange?.endDate).format("DD MMM, YYYY")}
                        </button>
                      </DateRangePicker>
                    </div>

                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        className="height-fit-content"
                        color="warning"
                        onClick={() => {
                          setIsAddManualPayout(true);
                          toggleManualPayoutModal();
                        }}
                      >
                        Add Payout
                      </Button>

                      <Button
                        className="height-fit-content"
                        color="success"
                        onClick={() => {
                          if (!selectedRows.length) {
                            return;
                          }
                          openPayModal();
                        }}
                        disabled={!selectedRows.length}
                      >
                        Pay Selected
                      </Button>
                    </div>
                  </div>

                  <Row className="mb-2">
                    <Col className="text-end">
                      <div>
                        <Form.Check
                          id="show-pending"
                          name="show-pending"
                          type="radio"
                          inline
                          label="Show Due"
                          checked={showAll === 1}
                          onChange={event => {
                            if (event.target.checked) {
                              setShowAll(1);
                            }
                          }}
                        />
                        <Form.Check
                          id="show-completed"
                          name="show-completed"
                          type="radio"
                          inline
                          label="Show Paid"
                          checked={showAll === 2}
                          onChange={event => {
                            if (event.target.checked) {
                              setShowAll(2);
                            }
                          }}
                        />
                        <Form.Check
                          id="show-all"
                          name="show-all"
                          type="radio"
                          inline
                          label="Show All"
                          checked={showAll === 0}
                          onChange={event => {
                            if (event.target.checked) {
                              setShowAll(0);
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  {!Object.keys(syndicatePayouts).some(type => {
                    let transactions = syndicatePayouts?.[type]?.values || [];

                    return !!transactions?.length;
                  }) &&
                  !Object.keys(manualPayoutsV2)?.some(key => {
                    let transactions = manualPayoutsV2?.[key]?.values || [];

                    return !!transactions?.length;
                  }) &&
                  !Object.keys(manualPayouts)?.some(key => {
                    let transactions = manualPayouts?.[key]?.values || [];

                    return !!transactions?.length;
                  }) ? (
                    <>
                      <div className="mt-2 text-center">
                        <div>
                          <h4>Payouts Not Available</h4>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Tabs
                        onSelect={e => {
                          handleTabSelectChange(e);
                        }}
                      >
                        {Object.keys(syndicatePayouts)?.some(type => {
                          let transactions =
                            syndicatePayouts?.[type]?.values || [];

                          return !!transactions?.length;
                        }) ? (
                          Object.keys(syndicatePayouts)?.map(type => {
                            let transactions =
                              syndicatePayouts?.[type]?.values || [];
                            let transactionCurrency =
                              syndicatePayouts?.[type]?.currency;

                            if (!transactions?.length) {
                              return null;
                            }

                            let tableColumns =
                              getReferralTransactionTableColumns(
                                type,
                                transactionCurrency
                              );

                            transactions = transactions?.filter(transaction => {
                              if (!query?.search) {
                                return true;
                              }

                              let { user_id, past_investment_id } =
                                transaction || {};

                              let { firstname, lastname, userName } =
                                user_id || {};
                              let investorName =
                                `${firstname || ""} ${lastname || ""}`.trim() ||
                                userName;

                              let startupName =
                                past_investment_id?.startup_id
                                  ?.basic_information?.startup_name;

                              let fundingRound =
                                past_investment_id?.deal_id?.funding_information
                                  ?.funding_round;
                              let dealDate =
                                past_investment_id?.deal_id?.funding_information
                                  ?.deal_date;

                              if (dealDate) {
                                dealDate = moment(dealDate).format("MMM, YYYY");
                              }

                              let roundDetails = `${fundingRound}\n(${dealDate})`;

                              let matched =
                                investorName
                                  ?.toLowerCase()
                                  ?.includes(query?.search?.toLowerCase()) ||
                                startupName
                                  ?.toLowerCase()
                                  ?.includes(query?.search?.toLowerCase()) ||
                                roundDetails
                                  ?.toLowerCase()
                                  ?.includes(query?.search?.toLowerCase());

                              return !!matched;
                            });

                            return (
                              <Tab title={type} eventKey={type} key={type}>
                                <PaginationProvider
                                  pagination={paginationFactory(TableOptions)}
                                  keyField="_id"
                                  columns={tableColumns}
                                  data={transactions}
                                >
                                  {({
                                    paginationProps,
                                    paginationTableProps,
                                  }) => (
                                    <ToolkitProvider
                                      keyField="_id"
                                      columns={tableColumns}
                                      data={transactions}
                                      search={{
                                        searchFormatted: true,
                                      }}
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row className="mb-2">
                                            <Col xs={12}>
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                  defaultSorted={defaultSorted}
                                                  classes="table aling-middle table-hover"
                                                  bordered={false}
                                                  striped={true}
                                                  responsive
                                                  noDataIndication="No Data Available"
                                                  selectRow={selectRow}
                                                  rowClasses={row => {
                                                    let rowColor = "";

                                                    // for referral investments
                                                    if (
                                                      !!row?.past_investment_id
                                                        ?.past_investment &&
                                                      row?.past_investment_id?.past_investment?.partner_user_id?._id?.toString() !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for self-investment payouts
                                                    if (
                                                      row?.transaction_type ===
                                                      "Self Investment"
                                                    ) {
                                                      rowColor = "";
                                                    }

                                                    if (
                                                      row?.transaction_type ===
                                                        "Syndicate Payout" &&
                                                      row?.saas_parent_id?.toString() ===
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor = "";
                                                    }

                                                    // for successful transactions
                                                    if (!!row?.payment_date) {
                                                      rowColor =
                                                        "payouts-table-success";
                                                    }

                                                    return `${rowColor} align-middle`;
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              </Tab>
                            );
                          })
                        ) : (
                          <></>
                        )}

                        {Object.keys(manualPayoutsV2)?.some(key => {
                          let transactions =
                            manualPayoutsV2?.[key]?.values || [];

                          return !!transactions?.length;
                        }) ? (
                          Object.keys(manualPayoutsV2)?.map(key => {
                            let transactions =
                              manualPayoutsV2?.[key]?.values || [];
                            let transactionCurrency =
                              manualPayoutsV2?.[key]?.currency;

                            if (!transactions?.length) {
                              return null;
                            }

                            let tableColumns = getManualPayoutTableColumns(
                              key,
                              transactionCurrency
                            );

                            return (
                              <Tab title={key} eventKey={key} key={key}>
                                <PaginationProvider
                                  pagination={paginationFactory(TableOptions)}
                                  keyField="_id"
                                  columns={tableColumns}
                                  data={transactions}
                                >
                                  {({
                                    paginationProps,
                                    paginationTableProps,
                                  }) => (
                                    <ToolkitProvider
                                      keyField="_id"
                                      columns={tableColumns}
                                      data={transactions}
                                      search={{
                                        searchFormatted: true,
                                      }}
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row className="mb-2">
                                            <Col xs={12}>
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                  defaultSorted={defaultSorted}
                                                  classes="table aling-middle table-hover"
                                                  bordered={false}
                                                  striped={true}
                                                  responsive
                                                  noDataIndication="No Data Available"
                                                  selectRow={selectRow}
                                                  rowClasses={row => {
                                                    let rowColor = "";

                                                    // for manual payouts
                                                    if (
                                                      row?.is_manual_payout &&
                                                      row?.transaction_status ===
                                                        "pending" &&
                                                      row?.payout_partner_id?.toString() !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for referral investments
                                                    if (
                                                      !!row?.past_investment_id
                                                        ?.past_investment &&
                                                      row?.past_investment_id?.past_investment?.partner_user_id?._id?.toString() !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for self-investment payouts
                                                    if (
                                                      row?.transaction_type ===
                                                      "Self Investment"
                                                    ) {
                                                      rowColor = "";
                                                    }

                                                    // for subscription payouts
                                                    if (
                                                      row?.transaction_type ===
                                                        "Subscription Payout" &&
                                                      row?.saas_parent_id !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for successful transactions
                                                    if (!!row?.payment_date) {
                                                      rowColor =
                                                        "payouts-table-success";
                                                    }

                                                    return `${rowColor} align-middle`;
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              </Tab>
                            );
                          })
                        ) : (
                          <></>
                        )}

                        {Object.keys(manualPayouts)?.some(key => {
                          let transactions = manualPayouts?.[key]?.values || [];

                          return !!transactions?.length;
                        }) ? (
                          Object.keys(manualPayouts)?.map(key => {
                            let transactions =
                              manualPayouts?.[key]?.values || [];
                            let transactionCurrency =
                              manualPayouts?.[key]?.currency;

                            if (!transactions?.length) {
                              return null;
                            }

                            let tableColumns = getManualPayoutTableColumns(
                              key,
                              transactionCurrency
                            );

                            return (
                              <Tab title={key} eventKey={key} key={key}>
                                <PaginationProvider
                                  pagination={paginationFactory(TableOptions)}
                                  keyField="_id"
                                  columns={tableColumns}
                                  data={transactions}
                                >
                                  {({
                                    paginationProps,
                                    paginationTableProps,
                                  }) => (
                                    <ToolkitProvider
                                      keyField="_id"
                                      columns={tableColumns}
                                      data={transactions}
                                      search={{
                                        searchFormatted: true,
                                      }}
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row className="mb-2">
                                            <Col xs={12}>
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                  defaultSorted={defaultSorted}
                                                  classes="table aling-middle table-hover"
                                                  bordered={false}
                                                  striped={true}
                                                  responsive
                                                  noDataIndication="No Data Available"
                                                  selectRow={selectRow}
                                                  rowClasses={row => {
                                                    let rowColor = "";

                                                    // for manual payouts
                                                    if (
                                                      row?.is_manual_payout &&
                                                      row?.transaction_status ===
                                                        "pending" &&
                                                      row?.payout_partner_id?.toString() !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for referral investments
                                                    if (
                                                      !!row?.past_investment_id
                                                        ?.past_investment &&
                                                      row?.past_investment_id?.past_investment?.partner_user_id?._id?.toString() !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for self-investment payouts
                                                    if (
                                                      row?.transaction_type ===
                                                      "Self Investment"
                                                    ) {
                                                      rowColor = "";
                                                    }

                                                    // for subscription payouts
                                                    if (
                                                      row?.transaction_type ===
                                                        "Subscription Payout" &&
                                                      row?.saas_parent_id !==
                                                        userData?._id?.toString()
                                                    ) {
                                                      rowColor =
                                                        "payouts-table-danger";
                                                    }

                                                    // for successful transactions
                                                    if (!!row?.payment_date) {
                                                      rowColor =
                                                        "payouts-table-success";
                                                    }

                                                    return `${rowColor} align-middle`;
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              </Tab>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Tabs>

                      <div className="mt-2" hidden>
                        <div>
                          <div className="mb-1">
                            <span className="ps-3 pe-3 me-1 payouts-table-danger"></span>
                            <span>
                              Transactions in red belongs to child partners
                            </span>
                          </div>
                          <div>
                            <span className="ps-3 pe-3 me-1 payouts-table-success"></span>
                            <span>Transactions in green are paid</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal size="lg" isOpen={payModal} toggle={() => togglePayModal()}>
            <ModalHeader toggle={() => togglePayModal()} tag="h4">
              Payment Confirmation
            </ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <Table className="fs-6" size="sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedRows.map((row, index) => {
                        let transactionCurrency =
                          selectedRowPayoutType?.currency;

                        let date = moment(row.createdAt).format("DD MMM, YYYY");

                        let name;
                        let amount = formatNumberToCommaSeparatedCurrency(
                          row?.payment_amount || 0,
                          transactionCurrency
                        );
                        if (!row?.past_investment_id?.past_investment) {
                          let { firstname, lastname, userName } =
                            row?.user_id || {};
                          name =
                            `${firstname || ""} ${lastname || ""}`.trim() ||
                            userName;
                        } else {
                          let { firstname, lastname, userName } =
                            row?.past_investment_id?.investor_id || {};
                          name =
                            `${firstname || ""} ${lastname || ""}`.trim() ||
                            userName;
                        }

                        // commented due to previously changed logic
                        // let transactionCurrency =
                        //   selectedRowPayoutType?.currency;

                        // let date = moment(row.createdAt).format("DD MMM, YYYY");

                        // let name,
                        //   amount = "0";
                        // if (!row?.past_investment_id?.past_investment) {
                        //   let { firstname, lastname, userName } =
                        //     row?.user_id || {};
                        //   name =
                        //     `${firstname || ""} ${lastname || ""}`.trim() ||
                        //     userName;
                        //   amount = formatNumberToCommaSeparatedCurrency(
                        //     row?.payment_amount || 0,
                        //     transactionCurrency
                        //   );
                        // } else {
                        //   let { firstname, lastname, userName } =
                        //     row?.past_investment_id?.investor_id || {};
                        //   name =
                        //     `${firstname || ""} ${lastname || ""}`.trim() ||
                        //     userName;

                        //   let { service_fees, partner_transaction_fee } =
                        //     row?.past_investment_id?.past_investment;
                        //   service_fees = parseInt(service_fees || 0);
                        //   partner_transaction_fee = parseInt(
                        //     partner_transaction_fee || 0
                        //   );
                        //   amount = formatNumberToCommaSeparatedCurrency(
                        //     (
                        //       (service_fees * partner_transaction_fee) /
                        //       100
                        //     ).toFixed(0),
                        //     transactionCurrency
                        //   );

                        //   if (
                        //     ["Self Investment", "Syndicate Payout"].includes(
                        //       row?.transaction_type
                        //     )
                        //   ) {
                        //     amount = formatNumberToCommaSeparatedCurrency(
                        //       row?.payment_amount || 0,
                        //       transactionCurrency
                        //     );
                        //   }
                        // }

                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{date}</td>
                            <td>{name}</td>
                            <td>{amount}</td>
                            <td className="text-end">
                              <Link to="#">
                                <i
                                  data-toggle="tooltip"
                                  title="Remove item"
                                  className="bx bx-trash fs-5 text-danger"
                                  onClick={() => {
                                    handleRemoveSelectedRow(index);
                                  }}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th>Total</th>
                        <td></td>
                        <td></td>
                        <th>
                          {formatNumberToCommaSeparatedCurrency(
                            selectedRows
                              .map(row => {
                                return row?.payment_amount || 0;

                                // commented due to previously changed logic
                                // if (
                                //   row?.past_investment_id?.past_investment ===
                                //   undefined
                                // ) {
                                //   return row?.payment_amount || 0;
                                // }

                                // let { service_fees, partner_transaction_fee } =
                                //   row?.past_investment_id?.past_investment ||
                                //   {};
                                // service_fees = parseInt(service_fees || 0);
                                // partner_transaction_fee = parseInt(
                                //   partner_transaction_fee || 0
                                // );
                                // let amount = parseInt(
                                //   (
                                //     (service_fees * partner_transaction_fee) /
                                //     100
                                //   ).toFixed(0)
                                // );

                                // if (
                                //   [
                                //     "Self Investment",
                                //     "Syndicate Payout",
                                //   ].includes(row?.transaction_type)
                                // ) {
                                //   return row?.payment_amount || 0;
                                // }

                                // return amount;
                              })
                              .reduce((prev, curr) => prev + curr, 0),
                            selectedRowPayoutType?.currency
                          )}
                        </th>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>

                  <Row className="mt-3 align-items-center">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Payment Date<sup className="text-danger">*</sup>
                    </Col>
                    <Col>
                      <DatePicker
                        locale="en-IN"
                        selected={payDate}
                        onSelect={date => {
                          setPayDate(date);
                        }}
                        onChange={date => {
                          setPayDate(date);
                        }}
                        className="form-control"
                        dateFormat="d MMM, yyyy"
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 align-items-center">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Reference Id<sup className="text-danger">*</sup>
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        value={payReferenceId}
                        onChange={event => {
                          setPayReferenceId(event.target.value);
                        }}
                        placeholder="Enter Reference Id"
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 align-items-center">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Billing Name
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        value={payBillingName}
                        onChange={event => {
                          setPayBillingName(event.target.value);
                        }}
                        placeholder="Enter Billing Name"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 align-items-center">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Upload Invoice
                    </Col>
                    <Col>
                      <Input
                        type="file"
                        className="inner form-control"
                        accept=".png, .jpeg, .jpg, .gif, .pdf, .csv, .xls, .xlsx"
                        value={invoiceFile?.fileName}
                        multiple={false}
                        onChange={event => {
                          setInvoiceFile({
                            fileName: event.target.value,
                            file: event.target.files?.[0],
                          });
                        }}
                        placeholder="Upload Invoice"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Amount due in USD($)
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={4}>
                      <Input
                        type="number"
                        step={0.01}
                        min={0}
                        value={dueUSD}
                        onChange={event => {
                          setDueUSD(event.target.value);
                        }}
                        placeholder="Enter amount due in USD($)"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Conversion Rate (USD-INR)
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={4}>
                      <Input
                        type="number"
                        step={0.01}
                        min={0}
                        value={UsdInrRate}
                        onChange={event => {
                          setUsdInrRate(event.target.value);
                        }}
                        placeholder="Enter conversion rate (USD-INR)"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Amount due in INR(₹)
                    </Col>
                    <Col xs={8} sm={8} md={6} lg={4}>
                      <Input
                        type="number"
                        value={(
                          parseFloat(dueUSD || 0) * parseFloat(UsdInrRate || 0)
                        ).toFixed(2)}
                        disabled
                        placeholder="Enter amount due in INR(₹)"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={4} sm={4} md={3} lg={2}>
                      Comments
                    </Col>
                    <Col>
                      <Input
                        type="textarea"
                        value={payComment}
                        onChange={event => {
                          setPayComment(event.target.value);
                        }}
                        placeholder="Enter Additional Info/Comment"
                        rows={4}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6} className="text-end">
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => {
                          handleAddTransactionPayments();
                        }}
                      >
                        Confirm
                      </Button>
                    </Col>
                    <Col xs={6} className="text-start">
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          togglePayModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>

          <Modal
            size="md"
            isOpen={payDetailsModal}
            toggle={() => togglePayDetailsModal()}
          >
            <ModalHeader toggle={() => togglePayDetailsModal()} tag="h4">
              Payment Details
            </ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <th>Date :</th>
                        <td>{paymentDetails.createdAt}</td>
                      </tr>
                      <tr>
                        <th>Payout Date :</th>
                        <td>{paymentDetails.payment_date}</td>
                      </tr>
                      <tr>
                        <th>Reference Id :</th>
                        <td>{paymentDetails.payment_reference_id}</td>
                      </tr>
                      <tr>
                        <br />
                      </tr>
                      <tr>
                        <th>Transaction Type :</th>
                        <td>{paymentDetails.transaction_type}</td>
                      </tr>
                      <tr>
                        <th>Payout Amount :</th>
                        <td>{paymentDetails.payment_amount}</td>
                      </tr>
                      <tr>
                        <th>Billing Name :</th>
                        <td>{paymentDetails.payBillingName}</td>
                      </tr>
                      <tr>
                        <br />
                      </tr>
                      <tr>
                        <th>Investor :</th>
                        <td>{paymentDetails.investorName}</td>
                      </tr>
                      {paymentDetails?.is_manual_payout ? (
                        <>
                          <tr>
                            <th>Payment Amount :</th>
                            <td>{paymentDetails.paidAmount}</td>
                          </tr>
                          <tr>
                            <th>Payment Fee :</th>
                            <td>{paymentDetails.paidAmountFee}%</td>
                          </tr>
                          <tr>
                            <th>Payment Date :</th>
                            <td>{paymentDetails.payoutDate}</td>
                          </tr>
                          <tr>
                            <th>Payout To :</th>
                            <td>{paymentDetails.paidByName}</td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {paymentDetails.referenceName ? (
                            <tr>
                              <th>Reference :</th>
                              <td>{paymentDetails.referenceName}</td>
                            </tr>
                          ) : (
                            <tr>
                              <th>Investor paid amount :</th>
                              <td>{paymentDetails.investorCreditAmount}</td>
                            </tr>
                          )}
                          <tr>
                            <th>Payout To :</th>
                            <td>{paymentDetails.paidByName}</td>
                          </tr>
                          <tr>
                            <br />
                          </tr>
                          {paymentDetails.startupName ? (
                            <tr>
                              <th>Startup :</th>
                              <td>{paymentDetails.startupName}</td>
                            </tr>
                          ) : (
                            <tr>
                              <th>Purchased Credit :</th>
                              <td>{paymentDetails.purchasedCredit}</td>
                            </tr>
                          )}
                          {paymentDetails.startupRound ? (
                            <tr>
                              <th>Round :</th>
                              <td>{paymentDetails.startupRound}</td>
                            </tr>
                          ) : (
                            <tr>
                              <th>Plan Name :</th>
                              <td>{paymentDetails.planName}</td>
                            </tr>
                          )}
                        </>
                      )}
                      <tr>
                        <br />
                      </tr>
                      <tr>
                        <th>Invoice :</th>
                        <td>
                          {paymentDetails?.invoiceFile ? (
                            <>
                              <a
                                href={paymentDetails?.invoiceFile}
                                target="_blank"
                                className="btn btn-sm btn-info"
                              >
                                Download
                              </a>
                            </>
                          ) : (
                            <>No File Uploaded</>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <br />
                      </tr>
                      <tr>
                        <th>Amount due in USD($) :</th>
                        <td>{paymentDetails.amount_due_usd}</td>
                      </tr>
                      <tr>
                        <th>Conversion Rate (USD-INR) :</th>
                        <td>{paymentDetails.usd_inr_rate}</td>
                      </tr>
                      <tr>
                        <th>Amount due in INR(₹) :</th>
                        <td>{paymentDetails.amount_due_inr}</td>
                      </tr>
                      <tr>
                        <br />
                      </tr>
                      <tr>
                        <th>Comments :</th>
                        <td>{paymentDetails.payment_comments}</td>
                      </tr>
                    </tbody>
                  </table>

                  <Row className="mt-3">
                    <Col className="text-end">
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => {
                          togglePayDetailsModal();
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>

          <AddManualPayoutTransaction
            manualPayoutModal={manualPayoutModal}
            setManualPayoutModal={setManualPayoutModal}
            toggleManualPayoutModal={toggleManualPayoutModal}
            isAdd={isAddManualPayout}
            editPayoutDetails={editPayoutDetails}
            saas_parent_id={
              userData?.is_saas_parent
                ? userData?._id
                : userData?.saas_parent_id
            }
            userData={userData}
            partnerData={partnerData}
            allCurrencies={allCurrencies}
            manualPayoutFor="syndicate"
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SyndicateDetails);
