/* Documents Rules */
export const GET_RULES = "GET_RULES"
export const SET_RULES = "SET_RULES"
export const GET_RULES_FAIL = "GET_RULES_FAIL"
export const SET_RULES_FAIL = "SET_RULES_FAIL"
export const GET_RULES_SUCCESS = "GET_RULES_SUCCESS"
export const SET_RULES_SUCCESS = "SET_RULES_SUCCESS"

// alert box
export const CLOSE_ALERT_BOX = "CLOSE_ALERT_BOX"
