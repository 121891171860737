import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { displayImage } from "../../helpers/storage_helper";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";

import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { isEmpty } from "lodash";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";

// Quill Editor for Event Description
import ReactQuill from "react-quill";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

//Import Breadcrumb

function WeeklyBrewList() {
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: false,
  };
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [posts, setWeeklyBrewList] = useState([]);
  const [post, setPost] = useState({
    datetime: "01-01-1971",
  });

  const [dynamicContentImage, setDynamicContentImage] = useState({
    fileName: null,
    file: null,
  });

  const [marketingImgFile, setMarketingImgFile] = useState({
    fileName: null,
    file: null,
  });

  const [marketing_title, setMarketing_title] = useState();

  const [scheduleDate, setScheduleDate] = useState();
  const [postsData, setPostsData] = useState([]);
  const [selectedPostsData, setSelectedPostsData] = useState([]);
  const [savedPostsData, setSavedPostsData] = useState([]);

  const [access, setAccess] = useState({});

  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;
  const [isError, setIsError] = useState(false);
  const [errorPost, setErrorPost] = useState("");

  function fetchData() {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/weeklyBrew/getBrew`, {
        headers: { Authorization: `${token}` },
      })
      .then(
        ({ data }) => {
          setIsLoaded(true);
          setWeeklyBrewList(data.result);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  function fetchPostsData() {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/api/weeklyBrew/get_timeline_posts`,
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then(
        ({ data }) => {
          let postsData = [];
          if (data.posts) {
            let posts = data.posts;
            if (posts.length > 0) {
              for (let post of posts) {
                // console.log("postsDataaaaaaaaaa", post)

                let dropdownlebel =
                  post?.description
                    ?.replace(/<\/?[^>]+(>|$)/g, " ")
                    .substring(0, 100) +
                  " - " +
                  post?.creadted_by_user_name;
                postsData.push({
                  label: dropdownlebel,
                  value: post._id,
                });
              }
            }
            setPostsData(postsData);
          }
        },
        error => {
          setError(error);
        }
      );
  }

  useEffect(() => {
    fetchData();
    fetchPostsData();

    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    return () => {};
  }, []);
  const createPost = async brew => {
    setModal(false);
    let bodyFormData = {
      title: brew.title,
      content: brew.description,
      marketing_content: brew.marketing_content,
      datetime: brew.datetime,
    };
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL + "/api/weeklyBrew/createBrew"}`,
      data: bodyFormData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const updatePost = async brew => {
    if(selectedPostsData?.length===0){
      setIsError(true);
      return setErrorPost("The Timeline Posts is required.");
    }
    const formData = new FormData();
    let data = {
      title: brew.title,
      content: brew.content,
      marketing_title: brew.marketing_title,
      marketing_content: brew.marketing_content,
      datetime: brew.datetime,
      timeline_posts: fetchValues(selectedPostsData),
    }
    // formData.append("title", brew.title);
    // formData.append("content", brew.content);
    // formData.append('dynamicContentImage', dynamicContentImage);

    // formData.append("marketing_title", brew.marketing_title);
    // formData.append("marketing_content", brew.marketing_content);
    // formData.append('marketingImgFile', marketingImgFile);
    // formData.append("timeline_posts", fetchValues(selectedPostsData));
   
    // var expires = moment(brew.datetime).utc();

    // formData.append("datetime", brew.datetime);
    if (marketingImgFile?.file) {
      formData.append("marketingImgFile", marketingImgFile?.file);
    }
    if (dynamicContentImage?.file) {
      formData.append("dynamicContentImage", dynamicContentImage?.file);
    }

    let encryptedQuery = encryptDataToArray(data)
    formData.append("q", JSON.stringify(encryptedQuery));
    // let bodyFormData = {
    //     title: brew.title,
    //     content: brew.description,
    //     marketing_content: brew.marketing_content,
    //     datetime: brew.datetime
    // }
    setModal(false);
    setIsError(false);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/weeklyBrew/updateBrew/${brew._id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
      data: formData,
    };
    await axios(config)
      .then(res => {
        // setSelectedPostsData([])
        fetchData();

        if (res.data) {
          let messages = this.setState({
            successMessage: "Weekly Brew updated successfully!",
          });
          window.location.href = "/weekly-brew";
        }
      })
      .catch(error => {
        //  console.log(error);
      });
    

    // await axios({
    //     method: "POST",
    //     url: `${process.env.REACT_APP_API_URL}/api/weeklyBrew/updateBrew/${brew._id}`,
    //     headers: {
    //         Authorization: `${token}`,
    //     },
    //     data: bodyFormData
    // })
    //     .then(res => {
    //         fetchData();
    //     })
    //     .catch(err => {
    //         setError(err);
    //     });
  };

  const fetchValues = posts => {
    let allPostID = [];
    for (let post of posts) {
      // console.log("postsDataaaaaaaaaa", post)
      allPostID.push(post.value);
    }
    return allPostID;

    // console.log("changed data", e[0].value)
  };
  const filterRecords = postIDs => {
    let posts = postsData;
    let selectedPosts = [];

    posts.forEach(item => {
      if (postIDs.includes(item.value)) selectedPosts.push(item);
    });

    return selectedPosts;
  };

  const handlePostChange = e => {
    // console.log("changed data", e[0].value)
    setSelectedPostsData(e);
  };

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "title",
      text: "Business Title",
    },
    {
      dataField: "content",
      text: "Content",
      formatter: (cellContent, post) => (
        <div
          className="react-quill-preview"
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
      ),
    },

    {
      dataField: "newDateTime",
      text: "Scheduled On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(post?.newDateTime).format("DD MMM Y")}</>
      ),
    },

    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
      ),
    },

    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, post) => (
        <>
          {post?.status == "1"
            ? "Active"
            : post?.status[0].toUpperCase() + post?.status.substring(1)}
        </>
      ),
    },
    access?.communication_letter_edit
      ? {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, post) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  title="Edit"
                  onClick={() => {
                    if (is_super_admin === true) {
                      // setPost({ ...post, datetime: moment(post.datetime).format('DD-MM-YYYY')});
                      let selectedPost = filterRecords(post?.timeline_posts);
                      setSelectedPostsData(selectedPost);
                      setPost(post);
                      setModal(true);
                      setIsEdit(true);
                    } else {
                      // setPost({ ...post, datetime: moment(post.datetime).format('DD-MM-YYYY')});
                      let selectedPost = filterRecords(post?.timeline_posts);
                      setSelectedPostsData(selectedPost);
                      setPost(post);
                      setModal(true);
                      setIsEdit(true);
                    }
                  }}
                ></i>
              </Link>

              {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deletePost(post._id)}
            ></i>
          </Link> */}
            </div>
          ),
        }
      : "",
    {
      formatter: (cellContent, post) => (
        <>
          <Link
            className="font-16 btn-block btn btn-secondary justify-content-center"
            to={
              "/weekly-brew/view-logs-count?brew_date=" +
              moment(post?.newDateTime).format("DD MMM Y") +
              "&datetime=" +
              post?.newDateTime
            }
          >
            View Logs
          </Link>
        </>
      ),
    },
  ];

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Posts | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          <Row className="d-flex justify-content-between align-items-center">
            <Col sm="6">
              <h5 className="text-uppercase mb-0">Weekly Brew List</h5>
            </Col>
            <Col sm="3">
              <div className="text-sm-end mx-4">
                <Link
                  className="font-16 btn-block btn btn-primary justify-content-center"
                  to="/weekly-brew/create"
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create Weekly Brew
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <DataFetchLoader loading={posts ? false : true} />
                <CardBody>
                  <React.Fragment>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={posts || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={posts || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={posts}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) ? (
                          <p className="text-danger">Some error occured!</p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Modal
                            size="lg"
                            isOpen={modal}
                            className="mb-3"
                            toggle={() => setModal(false)}
                          >
                            <ModalHeader
                              toggle={() => setModal(false)}
                              tag="h4"
                            >
                              {!!isEdit
                                ? "Edit Weekly Brew"
                                : "Add Weekly Brew"}
                            </ModalHeader>
                            <ModalBody>
                              <AvForm
                                onValidSubmit={() => {
                                  isEdit ? updatePost(post) : createPost(post);
                                }}
                                model={
                                  post === undefined ? defaultValues : post
                                }
                              >
                                <Row form>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <AvField
                                        name="Title"
                                        label="Business Title"
                                        type="text"
                                        errorMessage="Invalid title"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            title: event.target.value,
                                          });
                                        }}
                                        value={post.title || ""}
                                      />
                                    </div>
                                    <div className="mb-5 pb-3">
                                      <Label>Business Content</Label>
                                      <ReactQuill
                                        id="description"
                                        style={{ height: "150px" }}
                                        placeholder="Enter Event Description"
                                        defaultValue={post.content || ""}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            content: event,
                                          });
                                        }}
                                        modules={eventDescriptionEditorModules}
                                        formats={eventDescriptionEditorFormats}
                                      />
                                    </div>

                                    <div className="mb-5 pb-3">
                                      <Label>Business Image</Label>
                                      <Input
                                        type="file"
                                        accept=".jpeg, .png, .jpg, .gif"
                                        className="form-control"
                                        placeholder="Select Favicon Logo"
                                        onChange={event => {
                                          setDynamicContentImage({
                                            fileName: event.target.value,
                                            file: event.target.files[0],
                                          });
                                        }}
                                        multiple={false}
                                      />
                                      <strong>Note:</strong> (Image Size:
                                      200px*200px)
                                      <br></br>
                                      {post?.dynamicContentImage && (
                                        <img
                                          src={post?.dynamicContentImage}
                                          className="img rounded"
                                          width="100px"
                                        />
                                      )}
                                    </div>

                                    <div className="mb-3">
                                      <AvField
                                        name="marketing_title"
                                        label="Marketing Title"
                                        type="text"
                                        errorMessage="Invalid Marketing title"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            marketing_title: event.target.value,
                                          });
                                        }}
                                        value={post.marketing_title || ""}
                                      />
                                    </div>

                                    <div className="mb-5 pb-3">
                                      <Label>Marketing Content</Label>
                                      <ReactQuill
                                        id="marketing_content"
                                        style={{ height: "150px" }}
                                        placeholder="Enter Event Description"
                                        defaultValue={
                                          post.marketing_content || ""
                                        }
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            marketing_content: event,
                                          });
                                        }}
                                        modules={eventDescriptionEditorModules}
                                        formats={eventDescriptionEditorFormats}
                                      />
                                    </div>

                                    <div className="mb-5 pb-3">
                                      <Label>Marketing Image</Label>
                                      <Input
                                        type="file"
                                        accept=".jpeg, .png, .jpg, .gif"
                                        className="form-control"
                                        placeholder="Select Favicon Logo"
                                        onChange={event => {
                                          setMarketingImgFile({
                                            fileName: event.target.value,
                                            file: event.target.files[0],
                                          });
                                        }}
                                        multiple={false}
                                      />
                                      <strong>Note:</strong> (Image Size:
                                      200px*200px)
                                      <br></br>
                                      {post?.marketingImgFile && (
                                        <img
                                          src={post?.marketingImgFile}
                                          className="img rounded"
                                          width="100px"
                                        />
                                      )}
                                    </div>

                                    <div className="mb-5 pb-3">
                                      <Label>Timeline Posts</Label>
                                      <Select
                                        id="selectedGroups"
                                        placeholder="Timeline Posts"
                                        name="timeline_posts"
                                        className="mb-3"
                                        options={postsData}
                                        // styles={styles}
                                        value={selectedPostsData}
                                        isMulti={true}
                                        isClearable={true}
                                        onChange={posts =>
                                          {handlePostChange(posts || [])
                                          setIsError(false)
                                          }
                                        }
                                        validate={{ required: { value: true } }}
                                      />
                                      
                                {isError?
                                <div className="text-danger">{errorPost}</div>:""}
                              
                                    </div>
                                    

                                    <div className="mb-3">
                                      <Label>Scheduled Date</Label>

                                      {/* <AvField
                                                                                label="Scheduled Date"
                                                                                id="event_datetime"
                                                                                name="event_datetime"
                                                                                type="date"
                                                                                className="form-control"
                                                                                errorMessage="Invalid Date"
                                                                                validate={{
                                                                                    required: { value: true },
                                                                                }}
                                                                                onChange={event => {
                                                                                    setPost({
                                                                                        ...post,
                                                                                        datetime: event.target.value,
                                                                                    });
                                                                                }}
                                                                                value={post.datetime || ""}
                                                                            /> */}
                                      <DatePicker
                                        className="form-control"
                                        // selected={moment(post?.datetime).format('DD-MM-YYYY') || ""}
                                        selected={
                                          post?.datetime
                                            ? new Date(post?.datetime)
                                            : ""
                                        }
                                        // filterDate={date => date.getDay() === 2}
                                        // filterDate={date => getDay(date) === 1}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            datetime: event,
                                          });
                                        }}
                                        minDate={new Date()}
                                        // onChange={e => setScheduleDate(e.target.value)}
                                        placeholderText="Select Upcoming Monday Date"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </ModalBody>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
WeeklyBrewList.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default WeeklyBrewList;
