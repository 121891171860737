import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Col,
  Container,
  Row,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { isEmpty, isEqual } from "lodash";
import { associatePoll, getPostEventDetail } from "store/actions";
import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import { Form } from "react-bootstrap";
import { updateEventReportPollVisibility } from "helpers/backend_helper";
import moment from "moment";
import {
  checkDeviceType,
  getLocationString,
  getDeviceDetails,
} from "../../../helpers/logs_helper";

// TO DETECT USER BROWSER; p.s. i don't know how it works, it just does ;)
navigator.browserDetection = (function () {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
})();

class Poll extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      post: {},
      poll_type: "",
      poll_detail: {},
      selected_poll_id: "",
      intentUpdateButton: false,
      selected_poll_question: "",
      modal: false,
      defaultFounder: [],
      associateStarupModal: false,
      minimum_amount: "",
      device_stats: this.props?.deviceStats,
      poll_type_list: [
        // 'Intent',
        // 'Range',
        // 'General',
        "Intent+Range",
      ],
      funding_deal_id: "",
      fundingDealList: [],
      geolocationData: {},
      userLocation: "",
      userIP: "",
      userBrowser: "",
      userOS: "",
      userDevice: "",
      loggedInAdminId: "",
    };
    this.toggleAssociateStartupModel =
      this.toggleAssociateStartupModel.bind(this);
  }

  toggleAssociateStartupModel = async (
    poll_id,
    question,
    poll_type,
    minimum_amount,
    user_id
  ) => {
    this.setState({
      selected_poll_id: poll_id,
      selected_poll_question: question,
      poll_type: poll_type,
      minimum_amount: minimum_amount,
      funding_deal_id: user_id,
      fundingDealList: [],
    });
    this.setState(prevState => ({
      associateStarupModal: !prevState.associateStarupModal,
    }));
  };

  async handleShowOnFrontendChange(reqBody) {
    try {
      reqBody = {
        ...reqBody,
        postId: this.state?.post?._id,
      };

      let response = await updateEventReportPollVisibility(reqBody);

      if (!response?.status) {
        let errorMessage =
          response?.message || "Error while updating poll frontend visibility";
        alert(errorMessage);
        return;
      }

      await this.props?.onGetPostEventDetail({
        postId: this.state?.post?._id,
        showAll: true,
      });
    } catch (error) {
      let errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating poll frontend visibility";
      alert(errorMessage);
    }
  }

  associatePollToFundingDeal = async (e, values) => {
    const { post, selected_poll_id } = this.state;
    this.setState({ intentUpdateButton: true });

    let user_info = JSON.parse(localStorage?.getItem("user_info"));
    let adminUserId = user_info?.user_id;

    const poll_admin_answer = {
      poll_type: values.poll_type,
      founder_id: values.founder,
      post_id: this.props?.match?.params?.id,
      poll_question: "",
      pollRangeData: {},
      pollRangeDataMin: {},
      pollRangeDataMax: {},
      pollRangeDataDollerMax: {},
      pollRangeDataDollerMin: {},
      pollRangeDataDoller: {},
      fundingDeal: values?.fundingDeal,
      deviceStats: JSON.stringify({
        source: this.state?.userDevice?.includes("Desktop")
          ? "admin-website"
          : "admin-mobile" || "admin-website",
        location: this.state?.userLocation || "",
        ipAddress: this.state?.userIP || "",
        browserName: this.state?.userBrowser || "",
        osName: this.state?.userOS || "",
        deviceName: this.state?.userDevice || "",
      }),
      adminId: this.state?.loggedInAdminId || adminUserId,
    };

    let poll = [];
    if (!isEmpty(post) && post !== undefined) {
      poll = post?.media_content.filter(val => val?.media_type[0] === "poll");
      poll = poll?.length > 0 && poll[0]["content"]["body"]["polls"];
    }

    poll &&
      poll.map((value, key) => {
        if (value?.id == selected_poll_id) {
          poll_admin_answer.title = value?.title;
          poll_admin_answer.poll_id = value?.id;
          value?.questions.map((questionsValue, questionsKey) => {
            poll_admin_answer.poll_question = questionsValue.name;
            questionsValue?.answers.map((answersValue, answersKey) => {
              for (let i = 0; i < e.target.length; i++) {
                let element = e.target[i].name;
                let elementDoller = e.target[i].name;
                if (element === answersValue + "Min") {
                  poll_admin_answer.pollRangeDataMin[answersValue] =
                    e.target[i].value;
                }
                if (element === answersValue + "Max") {
                  poll_admin_answer.pollRangeDataMax[answersValue] =
                    e.target[i].value;
                  let averageData =
                    ((e.target[i].value ? parseInt(e.target[i].value) : 0) +
                      (poll_admin_answer?.pollRangeDataMin?.[answersValue]
                        ? parseInt(
                            poll_admin_answer?.pollRangeDataMin?.[answersValue]
                          )
                        : 0)) /
                    2;
                  poll_admin_answer.pollRangeData[answersValue] = averageData
                    ? averageData
                    : "";
                }
                if (elementDoller === answersValue + "$" + "Min") {
                  poll_admin_answer.pollRangeDataDollerMin[answersValue] =
                    e.target[i].value;
                }
                if (elementDoller === answersValue + "$" + "Max") {
                  poll_admin_answer.pollRangeDataDollerMax[answersValue] =
                    e.target[i].value;
                  let averageDataDoller =
                    ((e.target[i].value ? parseInt(e.target[i].value) : 0) +
                      (poll_admin_answer?.pollRangeDataDollerMin?.[answersValue]
                        ? parseInt(
                            poll_admin_answer?.pollRangeDataDollerMin?.[
                              answersValue
                            ]
                          )
                        : 0)) /
                    2;
                  poll_admin_answer.pollRangeDataDoller[answersValue] =
                    averageDataDoller ? averageDataDoller : "";
                }
              }
            });
          });
        }
      });
    await this.props.onAssociatePoll(poll_admin_answer);
    setTimeout(() => {
      this.setState({ intentUpdateButton: false });
      this.toggleAssociateStartupModel();
    }, 7000);
  };

  handelPollType = e => {
    this.setState({ poll_type: e.target.value });
  };

  fetchGeolocationData = async () => {
    // FETCHING USER DEVICE DATA;
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    await axios
      .get("https://geolocation-db.com/json/")
      .then(res => {
        this.setState({ geolocationData: res.data });

        let deviceInfo = getDeviceDetails(navigator.userAgent);
        let userLocation = getLocationString(
          this.state?.geolocationData?.city,
          this.state?.geolocationData?.country_name
        );
        let userIP = this.state?.geolocationData["IPv4"];
        let userBrowser = deviceInfo.browser;
        let userOS = deviceInfo.os;
        let userDevice = checkDeviceType(navigator);

        this.setState({
          userLocation,
          userIP,
          userBrowser,
          userOS,
          userDevice,
        });

        let user_info = JSON.parse(localStorage?.getItem("user_info"));
        let adminUserId = user_info?.user_id;
        this.setState({ loggedInAdminId: adminUserId });
      })
      .catch(err => console.log("ERROR WHILE FETCHING GEOLOCATION DATA:", err));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.postDetails, nextProps.postDetails)) {
      this.setState({ post: nextProps.postDetails });

      this.fetchGeolocationData();
    }
  }

  getFundingDealList = async element => {
    let founderId = element.target.value;
    if (founderId) {
      const token = authHeader();
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/api/startup/fundingdeal/${founderId}`,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then(async response => {
          if (response?.status) {
            this.setState({ fundingDealList: response?.data?.fundingList });
          }
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };
  render() {
    console.log("State:::", this.state);
    const {
      post,
      poll_type,
      funding_deal_id,
      selected_poll_id,
      selected_poll_question,
      fundingDealList,
      intentUpdateButton,
    } = this.state;

    console.log("This state:::", this.state);

    const defaultValues = {
      poll_type: poll_type,
      founder: funding_deal_id,
    };

    let poll = [];
    let poll_report = [];
    if (!isEmpty(post) && post !== undefined) {
      poll = post?.media_content.filter(val => val?.media_type[0] === "poll");
      poll = poll?.length > 0 && poll[0]?.content?.body?.polls;
      poll_report = post?.media_content.filter(
        val => val?.media_type[0] === "poll_report"
      );
      poll_report =
        poll_report?.length > 0 && poll_report[0]?.content?.body?.questions;
    }
    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row className="my-5">
              {post?.event_type === "General Event" ? (
                <Col lg={12}>
                  {!!poll?.length && (
                    <>
                      <Row className="mb-4">
                        <Col md={12}>
                          <h4 className="text-capitalize">Poll List</h4>
                        </Col>
                        <Col md={12}>
                          {poll?.length &&
                            poll?.map(pollVal => {
                              return (
                                <Row key={pollVal.id}>
                                  <Table>
                                    {/* name */}
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Answers</th>
                                        <th>Show on frontend</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pollVal?.questions?.length > 0 &&
                                        pollVal?.questions?.map(
                                          (questionVal, questionKey) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{++questionKey}</td>
                                                  <td>{questionVal?.name}</td>
                                                  <td>
                                                    <ul>
                                                      {questionVal?.answers
                                                        .length > 0 &&
                                                        questionVal?.answers.map(
                                                          (
                                                            answerVal,
                                                            answersKey
                                                          ) => {
                                                            return (
                                                              <>
                                                                <li>
                                                                  {answerVal}
                                                                </li>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                    </ul>
                                                  </td>
                                                  <td>
                                                    <Form.Check
                                                      type="switch"
                                                      label=""
                                                      checked={
                                                        questionVal?.show_in_frontend
                                                      }
                                                      name={questionVal?.name}
                                                      onChange={e => {
                                                        this.handleShowOnFrontendChange(
                                                          {
                                                            questionName:
                                                              questionVal?.name,
                                                            show_in_frontend:
                                                              e.target.checked,
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </Table>
                                </Row>
                              );
                            })}
                        </Col>
                      </Row>
                    </>
                  )}

                  {!!poll_report?.length && (
                    <>
                      <Row>
                        <Col md={12}>
                          <h4 className="text-capitalize">Poll Report</h4>
                        </Col>
                      </Row>

                      {poll_report &&
                        poll_report.map((poll_reportVal, poll_reportKey) => {
                          return (
                            <div className="" key={poll_reportKey}>
                              <Row
                                className="bg-dark-50 pt-2"
                                style={{ backgroundColor: "#ced4da" }}
                              >
                                <Col lg={6}>
                                  <label>User Name:</label> &nbsp;{" "}
                                  <span className="px-2">
                                    {poll_reportVal?.name}
                                  </span>
                                </Col>
                                <Col lg={6}>
                                  <label>Email:</label> &nbsp;{" "}
                                  <span className="px-2">
                                    {poll_reportVal?.email}
                                  </span>
                                </Col>
                              </Row>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Question</th>
                                    <th>Answer</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {poll_reportVal?.question_details.length >
                                    0 &&
                                    poll_reportVal?.question_details.map(
                                      (
                                        question_detailsVal,
                                        question_detailsKey
                                      ) => {
                                        return (
                                          <tr key={question_detailsKey}>
                                            <td>
                                              {question_detailsVal?.question}
                                            </td>
                                            <td>
                                              {question_detailsVal?.answer}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </Table>
                            </div>
                          );
                        })}
                    </>
                  )}
                </Col>
              ) : (
                <Col lg="12">
                  <Card>
                    <Row>
                      <Col lg={12} className="">
                        {poll &&
                          poll.length > 0 &&
                          poll.map((pollVal, pollKey) => {
                            return (
                              <>
                                <h4 className="text-capitalize">
                                  {pollVal?.title}
                                </h4>
                                <Table>
                                  {/* name */}
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Question</th>
                                      <th>Answer</th>
                                      <th>Startup | Founder</th>
                                      <th>Action</th>
                                      <th>Show on frontend</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pollVal?.questions?.length > 0 &&
                                      pollVal?.questions?.map(
                                        (questionVal, questionKey) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{++questionKey}</td>
                                                <td style={{ width: "300px" }}>
                                                  {questionVal?.name}
                                                </td>
                                                <td style={{ width: "300px" }}>
                                                  <ul>
                                                    {questionVal?.answers
                                                      .length > 0 &&
                                                      questionVal?.answers.map(
                                                        (
                                                          answerVal,
                                                          answersKey
                                                        ) => {
                                                          return (
                                                            <>
                                                              <li>
                                                                {answerVal}
                                                              </li>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                  </ul>
                                                </td>
                                                <td style={{ width: "300px" }}>
                                                  {questionVal?.founder_detail !==
                                                    undefined && (
                                                    <>
                                                      <span className="text-capitalize">
                                                        {
                                                          questionVal
                                                            ?.founder_detail
                                                            ?.startup_name
                                                        }
                                                      </span>{" "}
                                                      |{" "}
                                                      <span className="text-capitalize">
                                                        {
                                                          questionVal
                                                            ?.founder_detail
                                                            ?.founder_name
                                                        }
                                                      </span>
                                                    </>
                                                  )}
                                                </td>
                                                <td>
                                                  {questionVal &&
                                                  questionVal?.is_associated ? (
                                                    "Associated"
                                                  ) : !questionVal?.is_associated &&
                                                    !questionVal?.poll_status ? (
                                                    <Button
                                                      color="primary"
                                                      className="inner"
                                                      onClick={() => {
                                                        this.toggleAssociateStartupModel(
                                                          pollVal.id,
                                                          questionVal.name,
                                                          questionVal
                                                            ?.founder_detail
                                                            ?.poll_type,
                                                          questionVal
                                                            ?.founder_detail
                                                            ?.minimum_amount,
                                                          questionVal
                                                            ?.founder_detail
                                                            ?.user_id
                                                        );
                                                      }}
                                                      block
                                                    >
                                                      Associate to Startup
                                                    </Button>
                                                  ) : (
                                                    "pending"
                                                  )}
                                                </td>
                                                <td>
                                                  <Form.Check
                                                    type="switch"
                                                    label=""
                                                    checked={
                                                      questionVal?.show_in_frontend
                                                    }
                                                    name={questionVal?.name}
                                                    onChange={e => {
                                                      this.handleShowOnFrontendChange(
                                                        {
                                                          questionName:
                                                            questionVal?.name,
                                                          show_in_frontend:
                                                            e.target.checked,
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </Table>
                              </>
                            );
                          })}
                      </Col>
                    </Row>
                  </Card>
                  <Modal
                    isOpen={this.state.associateStarupModal}
                    toggle={this.toggleAssociateStartupModel}
                    size="xl"
                  >
                    <ModalHeader
                      toggle={this.toggleAssociateStartupModel}
                      tag="h4"
                    >
                      Associate Startup Modal
                    </ModalHeader>
                    <ModalBody>
                      <AvForm
                        onValidSubmit={this.associatePollToFundingDeal}
                        model={defaultValues}
                      >
                        <Row form>
                          <Col className="col-12">
                            <div className="mb-3">
                              <AvField
                                type="select"
                                label="Poll Type"
                                name="poll_type"
                                errorMessage="Poll type is reuqired"
                                required
                                onChange={e => this.handelPollType(e)}
                              >
                                <option value="">-- Select Poll Type --</option>

                                {this.state.poll_type_list &&
                                  this.state.poll_type_list.length > 0 &&
                                  this.state.poll_type_list.map((val, key) => (
                                    <option key={key} value={val}>
                                      {val}
                                    </option>
                                  ))}
                              </AvField>
                            </div>
                            {(this.state.poll_type === "Range" ||
                              this.state.poll_type === "Intent+Range") && (
                              <div className="mb-3">
                                {poll.length > 0 &&
                                  poll.map(
                                    (val, key) =>
                                      val.id === selected_poll_id &&
                                      val.questions.length > 0 &&
                                      val.questions.map((v, k) => {
                                        if (
                                          (v.name === selected_poll_question &&
                                            v.name.includes("How")) ||
                                          this.state.poll_type ===
                                            "Intent+Range"
                                        ) {
                                          return (
                                            <>
                                              <p>
                                                <strong>Question:</strong>{" "}
                                                {v.name}
                                              </p>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    marginRight: "175px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  Min
                                                </span>
                                                <span
                                                  style={{
                                                    marginRight: "175px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  Max
                                                </span>
                                                <span
                                                  style={{
                                                    marginRight: "165px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  Min
                                                </span>
                                                <span
                                                  style={{
                                                    marginRight: "85px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  Max
                                                </span>
                                              </div>

                                              <Table hover>
                                                <tbody>
                                                  {v.answers.map(
                                                    (value, key) => (
                                                      <tr key={key}>
                                                        <th>
                                                          {value}{" "}
                                                          {key < 4 ? " *" : ""}
                                                        </th>
                                                        <td className="d-flex">
                                                          <span
                                                            style={{
                                                              marginRight:
                                                                "12px",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            ₹{" "}
                                                          </span>
                                                          <AvField
                                                            type="number"
                                                            required={
                                                              key < 4
                                                                ? true
                                                                : false
                                                            }
                                                            name={value + "Min"}
                                                            placeholder={value}
                                                            value={
                                                              v?.pollRangeDataMin !==
                                                                undefined &&
                                                              v
                                                                ?.pollRangeDataMin[
                                                                value
                                                              ]
                                                            }
                                                          ></AvField>
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "30px",
                                                              marginRight:
                                                                "12px",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            ₹{" "}
                                                          </span>
                                                          <AvField
                                                            type="number"
                                                            required={
                                                              key < 4
                                                                ? true
                                                                : false
                                                            }
                                                            name={value + "Max"}
                                                            placeholder={value}
                                                            value={
                                                              v?.pollRangeDataMax !==
                                                                undefined &&
                                                              v
                                                                ?.pollRangeDataMax[
                                                                value
                                                              ]
                                                            }
                                                          ></AvField>
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "30px",
                                                              marginRight:
                                                                "12px",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            ${" "}
                                                          </span>
                                                          <AvField
                                                            type="number"
                                                            required={
                                                              key < 4
                                                                ? true
                                                                : false
                                                            }
                                                            name={
                                                              value +
                                                              "$" +
                                                              "Min"
                                                            }
                                                            placeholder={value}
                                                            value={
                                                              v?.pollRangeDataDollerMin !==
                                                                undefined &&
                                                              v
                                                                ?.pollRangeDataDollerMin[
                                                                value
                                                              ]
                                                            }
                                                          ></AvField>
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "30px",
                                                              marginRight:
                                                                "12px",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            ${" "}
                                                          </span>
                                                          <AvField
                                                            type="number"
                                                            required={
                                                              key < 4
                                                                ? true
                                                                : false
                                                            }
                                                            name={
                                                              value +
                                                              "$" +
                                                              "Max"
                                                            }
                                                            placeholder={value}
                                                            value={
                                                              v?.pollRangeDataDollerMax !==
                                                                undefined &&
                                                              v
                                                                ?.pollRangeDataDollerMax[
                                                                value
                                                              ]
                                                            }
                                                          ></AvField>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </Table>
                                            </>
                                          );
                                        }
                                      })
                                  )}
                              </div>
                            )}
                            <div className="mb-3">
                              <AvField
                                type="select"
                                label="Select Startup | Founder *"
                                onChange={e => this.getFundingDealList(e)}
                                name="founder"
                                errorMessage="Founder required"
                                required
                              >
                                <option value="">
                                  -- Select Startup | Founder --
                                </option>
                                {post?.invited_founder?.map((e, i) => (
                                  <option
                                    className="text-capitalize"
                                    key={i}
                                    value={`${e?.founder_info?.user_id}`}
                                  >
                                    {
                                      e?.startup?.basic_information
                                        ?.startup_name
                                    }{" "}
                                    | {e?.founder_info?.founder_name}
                                  </option>
                                ))}
                              </AvField>
                            </div>
                            <div className="mb-3">
                              <label>Funding Deal *</label>
                              <AvRadioGroup
                                name="fundingDeal"
                                required
                                errorMessage="Please select anyone funding deal!"
                              >
                                {fundingDealList &&
                                  fundingDealList.map(
                                    (
                                      fundingDealListVal,
                                      fundingDealListKey
                                    ) => {
                                      let dealName =
                                        fundingDealListVal?.funding_information
                                          ?.funding_round;
                                      let dealDate = moment(
                                        fundingDealListVal?.funding_information
                                          ?.deal_date
                                      ).format("MMM, YYYY");

                                      let dealSaasName =
                                        fundingDealListVal?.saas_parent_id
                                          ?.saas_name ||
                                        fundingDealListVal?.saas_parent_id
                                          ?.saas_fullname;
                                      let dealStatus =
                                        fundingDealListVal?.current_status;

                                      let label = `${dealName} - ${dealDate} [${dealSaasName} | ${dealStatus}]`;

                                      return (
                                        <AvRadio
                                          label={label}
                                          value={fundingDealListVal?._id}
                                        />
                                      );
                                    }
                                  )}
                              </AvRadioGroup>
                            </div>
                          </Col>
                        </Row>
                        {intentUpdateButton && (
                          <Row>
                            <Col>
                              <div className="text-end pb-2">
                                *Poll association process may take upto 5-10
                                minutes, till that time please do not associate
                                poll again
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save{" "}
                                {intentUpdateButton && (
                                  <Spinner animation="border" size="sm" />
                                )}{" "}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Poll.propTypes = {
  match: PropTypes.object,
  postDetails: PropTypes.object,
  onUpdateRole: PropTypes.func,
  error: PropTypes.string,
  onAssociatePoll: PropTypes.func,
  onGetPostEventDetail: PropTypes.func,
};

const mapStateToProps = ({ pitchdays }) => {
  return {
    error: pitchdays.updateRoleError,
  };
};

const mapDispatchToProps = dispatch => ({
  // onUpdateRole: user => dispatch(updateRole(user)),
  onAssociatePoll: payload => dispatch(associatePoll(payload)),
  onGetPostEventDetail: payload => dispatch(getPostEventDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Poll));
