import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import PropTypes from "prop-types";
import { closeAlertBox, setKPI } from "../../store/kpi/actions";
import { encryptDataToArray, encryptDataToArrayV2 } from "helpers/EncryptDecrypt";

const KPI_VALUE_TYPES = ["Percentage", "Number", "Text", "Currency"];
const CHECK_OPTIONS = ["Yes", "No"];
const NEW_KPI = {
  name: "",
  is_mandatory: "Yes",
  value_type: "Text",
  symbol: "",
  show_on_profile: "Yes",
};

class AddKPI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup_id: null,
      error: "",
      newKpi: Object.assign({}, NEW_KPI),
      success: "",
      currencyCodes: [],
      startupCurrencySymbol: "",
    };
  }

  componentDidMount() {
    const { startup_id, currencyCodes, startupCurrencySymbol } = this.props;
    this.setState({
      startup_id: startup_id,
      currencyCodes: currencyCodes,
      startupCurrencySymbol: startupCurrencySymbol,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.setState({ error: nextProps.error, success: nextProps.success });
  }

  onCancel = () => {
    //const { onCancelKPI } = this.props;
    //onCancelKPI();
    this.props.onToggle();
  };

  handleSetKPI = () => {
    const { onSetKPI } = this.props;
    let kpi = this.state.newKpi;
    let q = {}
    Object.keys(kpi)?.map((el) => {
      if (el) {
        q[el] = kpi[el]
      }
    })
    q.startup_id = this.state.startup_id
    q.symbol = this.state.startupCurrencySymbol
    // kpi["startup_id"] = this.state.startup_id;
    // kpi["symbol"] = this.state.startupCurrencySymbol;
    let encryptedQuery = encryptDataToArray(q)
    let payload = {
      q: JSON.stringify(encryptedQuery)
    }
    onSetKPI(payload);
  };

  handleChange = e => {
    let kpi = this.state.newKpi;
    kpi[e.target.name] = e.target.value;
    this.setState({ newKpi: kpi });
  };

  render() {
    const renderOptions = values => {
      return values.map((value, i) => (
        <option key={i} value={value}>
          {value}
        </option>
      ));
    };

    return (
      <div className="mb-3 mt-3 bg-light p-4">
        <p>
          <strong>Enter New KPI details here:</strong>
        </p>
        <AvForm onValidSubmit={this.handleSetKPI} model={this.state.newKpi}>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="kpi_name">KPI Name</Label>
                <AvField
                  type="text"
                  name="name"
                  id="kpi_name"
                  onChange={this.handleChange}
                  value={this.state.newKpi.name}
                  placeholder="KPI Name"
                  errorMessage="KPI name is required"
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
            {/* <Col md={2}>
              <FormGroup>
                <AvField
                  name="value_type"
                  label="KPI Value Type"
                  type="select"
                  onChange={this.handleChange}
                  errorMessage="Please select KPI value type"
                  value={this.state.newKpi.value_type}
                  validate={{
                    required: { value: true },
                  }}
                >
                  {renderOptions(KPI_VALUE_TYPES)}
                </AvField>
              </FormGroup>
            </Col> */}
            {/* <Col md={2}>
              <FormGroup>
                <Label for="symbol">Symbol</Label>
                <AvField
                  type="select"
                  name="symbol"
                  id="symbol"
                  onChange={this.handleChange}
                  errorMessage="Please select a valid value"
                  value={this.state.newKpi.symbol}
                  validate={{
                    required: { value: true },
                  }}
                >
                  {renderOptions(this.state.currencyCodes)}
                </AvField>
              </FormGroup>
            </Col> */}
            <Col md={2}>
              <FormGroup>
                <AvField
                  name="is_mandatory"
                  label="Is Mandatory?"
                  type="select"
                  onChange={this.handleChange}
                  errorMessage="Please select a valid value"
                  value={this.state.newKpi.is_mandatory}
                  validate={{
                    required: { value: true },
                  }}
                >
                  {renderOptions(CHECK_OPTIONS)}
                </AvField>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <AvField
                  name="show_on_profile"
                  label="Show on Startup Page?"
                  type="select"
                  onChange={this.handleChange}
                  errorMessage="Please select a valid value"
                  value={this.state.newKpi.show_on_profile}
                  validate={{
                    required: { value: true },
                  }}
                >
                  {renderOptions(CHECK_OPTIONS)}
                </AvField>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="text-center">
              <Button color="secondary" onClick={this.onCancel}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button type="submit" color="primary" onClick={() => {}}>
                Set New KPI
              </Button>
            </Col>
          </Row>
        </AvForm>
        <br />
        <br />
      </div>
    );
  }
}

AddKPI.propTypes = {
  startup_id: PropTypes.string,
  onAlertClose: PropTypes.func,
  onToggle: PropTypes.func,
  onSetKPI: PropTypes.func,
  //onCancelKPI: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
};

const mapStateToProps = ({ kpi }) => ({
  error: kpi.error,
  success: kpi.success,
});

const mapDispatchToProps = dispatch => ({
  onSetKPI: kpi => dispatch(setKPI(kpi)),
  //onCancelKPI: (kpi) => dispatch(cancelKPI()),
  onAlertClose: () => dispatch(closeAlertBox()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddKPI);
