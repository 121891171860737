import axios from "axios";
import DataFetchLoader from "components/Common/DataFetchLoader";
import React, { useEffect, useState } from "react";
import { FormSelect } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import GeneralMasterAddModal from "./GeneralMasterAddModal";
import GeneralMasterModal from "./GeneralMasterModal";
import { toast, ToastContainer } from "react-toastify";

const GeneralMaster = () => {
  const [data, setData] = useState();
  const [typesData, setTypesData] = useState([]);
  const [show, setShow] = useState(undefined);
  const [addShow, setAddShow] = useState(undefined);
  const [selectedData, setSelectedData] = useState({});
  const [type, setType] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAll();
    getAllByType();
    return () => {};
  }, []);

  const getAll = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/generalmaster/getall`, {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      })
      .then(res => {
        setData(res.data.mastervalue);
        setLoading(false);
      })
      .catch(err => {
        // console.log(err)
      });
  };

  const getAllByType = e => {
    setType(e);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/generalmaster/getAllByType/${
          e ? e.target.value : "investor_category"
        }`,
        {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        }
      )
      .then(res => {
        setTypesData(res.data.mastervalue);
        setLoading(false);
        // setLabel('label' in typesData[0]?.values[0] ? 'label' : 'key' in typesData[0]?.values[0] ? 'key' : '')
        // setValue('value' in typesData[0]?.values[0] ? 'value' : 'status' in typesData[0]?.values[0] ? 'status' : '')
      })
      .catch(err => {
        setLoading(false);
        console.log({ err });
        toast.error(err.response.data.message);
      });
  };

  const columns = [
    {
      dataField: "label",
      text: "Label",
    },
    {
      dataField: "value",
      text: "Value",
    },
    {
      text: "Action",
      formatter: (cellContent, user) => (
        <Link className="text-success" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => updateData(user)}
          ></i>
        </Link>
      ),
    },
  ];

  const updateData = user => {
    setSelectedData({
      id: typesData[0]?._id,
      values: user,
    });
    setShow(!show);
  };

  const addModal = () => {
    setAddShow(!addShow);
  };
  let tableData = [];
  return (
    <>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormSelect onChange={getAllByType} style={{ width: "300px" }}>
          {data?.map((item, key) => {
            return (
              <>
                <option value={item.type} key={key}>
                  {item.type}
                </option>
              </>
            );
          })}
        </FormSelect>
        <Button onClick={addModal}>Add</Button>
      </div>
      {typesData?.map((item, key) => {
        tableData = item.values;
      })}
      <DataFetchLoader loading={loading} />
      <BootstrapTable
        classes={"table align-middle table-hover"}
        keyField="id"
        columns={columns}
        data={tableData}
      />
      <GeneralMasterModal
        show={show}
        selectedData={selectedData}
        updateData={updateData}
        getAllByType={getAllByType}
        type={type}
      />

      <GeneralMasterAddModal
        show={addShow}
        addModal={addModal}
        getAll={getAll}
      />
    </>
  );
};

export default GeneralMaster;
