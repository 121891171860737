import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import * as moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import DateRangePicker from "react-bootstrap-daterangepicker";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

const CallForMoney = props => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
  };
  const token = authHeader();
  const [callForMoney, setCallForMoney] = useState([]);
  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, appliedFunding) => <>{appliedFunding._id}</>,
    },
    {
      dataField: "deposit_type",
      text: "Deposit Type",
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter(),
    },
    {
      dataField: "status",
      text: "Status",
      filter: textFilter(),
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.updatedAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, post) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                //setPost(post);
                //setModal(true);
                //setIsEdit(true);
              }}
            ></i>
          </Link>
          {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deletePost(post._id)}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ];

  const deletePost = () => {
    alert("under development...");
  };

  async function fetchData() {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + `/api/call/for/money/all`,
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    //setDefaultCallForMoney(response.data.callForMoney);
    setCallForMoney(response.data.callForMoney);
  }
  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const handleRangeFilter = (start, end) => {
    const filteredResult = callForMoney.filter(item => {
      const createdAt = moment(new Date(item.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return item;
      } else {
        return;
      }
    });
    setCallForMoney(filteredResult);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Call For Money | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs breadcrumbItem="Call For Money" /> */}
          <Row className="mb-3">
            <Col sm="6">
              <h5 className="text-uppercase">Call For Money</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md={8}>
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <DateRangePicker
                              initialSettings={{
                                startDate: "12/20/2021",
                                endDate: "12/30/2021",
                              }}
                              onApply={(event, picker) =>
                                handleRangeFilter(
                                  picker.startDate._d,
                                  picker.endDate._d
                                )
                              }
                              onCancel={() => fetchData()}
                            >
                              <button className="btn btn-primary">
                                Select Range
                              </button>
                            </DateRangePicker>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={e =>
                              props.history.push("/create-call-for-money")
                            }
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Create Call for Money
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={callForMoney || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={callForMoney || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={callForMoney}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CallForMoney.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CallForMoney;
