import React, { useState, useEffect, useMemo } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import moment from "moment";

const FundCumulativeData = ({ fundOptionsList }) => {
  const [fundCumulativeData, setFundCumulativeData] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState({
    value: null,
    label: `Since ${moment().format("MMM YY")}`,
  });
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedFund, setSelectedFund] = useState({
    label: "All Funds",
    value: null,
    _id: null,
  });

  const removeNullUndefined = value => {
    return [null, "null", undefined, "undefined"].includes(value) ? "" : value;
  };

  const convertToDealDateConversionRate = (amount, dealDate) => {
    let allCurrencyRates = JSON.parse(
      localStorage.getItem("all-currency-rates") || "{}"
    );

    let matchedCurrencyDate;

    let allCurrencyDates = Object.keys(allCurrencyRates);

    if (!allCurrencyDates?.includes(dealDate)) {
      allCurrencyDates = allCurrencyDates
        ?.filter(d => d?.toLowerCase() !== "default_data")
        ?.sort((a, b) => b?.toLowerCase()?.localeCompare(a?.toLowerCase()));

      let flag = false;

      for (let curDate of allCurrencyDates) {
        if (moment(dealDate, "YYYY-MM-DD").diff(curDate, "YYYY-MM-DD") > 0) {
          matchedCurrencyDate = curDate;
          flag = true;
        } else {
          flag = false;
        }

        if (flag) {
          break;
        }
      }
    } else {
      matchedCurrencyDate = dealDate;
    }

    let dealDateCurrencyRate =
      allCurrencyRates?.[matchedCurrencyDate || "DEFAULT_DATA"]?.["INR"]?.[
        "USD"
      ] || 1;

    return (amount || 0) * dealDateCurrencyRate;
  };

  const sortedFundOptionsList = useMemo(() => {
    const priorityList = [
      "Invstt Trust",
      "WFC Global Angels Fund",
      "AngelList",
    ];

    return fundOptionsList.sort((firstEle, nextEle) => {
      const priorityFirstSubArray = priorityList.findIndex(item =>
        firstEle.label.includes(item)
      );
      const prioritySecSubArray = priorityList.findIndex(item =>
        nextEle.label.includes(item)
      );

      if (priorityFirstSubArray !== -1 && prioritySecSubArray !== -1) {
        return priorityFirstSubArray - prioritySecSubArray;
      } else if (priorityFirstSubArray !== -1) {
        return -1;
      } else if (prioritySecSubArray !== -1) {
        return 1;
      } else {
        return firstEle.label.localeCompare(nextEle.label);
      }
    });
  }, [fundOptionsList]);

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    const getCumulativeChartData = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/admin/dashboard/fundCumulativeData`,
          {
            params: {
              saas_parent_id: authUser?.userInfo?.saas_parent_id,
              show_all_saas_data,
              is_super_admin: authUser?.userInfo?.is_super_admin,
              queryMonth: selectedMonth?.value,
              fund_id: selectedFund._id,
            },
            headers: {
              Authorization: authUser?.token,
            },
          }
        )
        .then(response => {
          const responseData = response?.data?.data;

          let DealValuation = responseData?.DealValuation;
          let dates = responseData?.dates;

          let fundCumulativeDataPrev = [];
          let liveDeals = 0;
          let closedDeals = 0;
          let total_valuation = 0;

          DealValuation?.map((curr, index) => {
            let startupCurrency = curr?.startup?.currency?.value || "INR";
            let deal_status = curr?.current_status;

            let { deal_date, current_valuation, valuation_cap } =
              curr?.funding_information || {};

            if (deal_status === "Deal Live") {
              liveDeals++;
            } else {
              closedDeals++;
            }

            let dealDate = deal_date
              ? moment(deal_date).format("YYYY-MM-DD")
              : "DEFAULT_DATA";

            let dealPeriod = deal_date
              ? moment(deal_date).format("MMM YY")
              : "DEFAULT_DATA";

            current_valuation = removeNullUndefined(current_valuation);
            valuation_cap = removeNullUndefined(valuation_cap);

            let dealValuation = !!parseFloat(current_valuation)
              ? parseFloat(current_valuation)
              : !!parseFloat(valuation_cap)
              ? parseFloat(valuation_cap)
              : 0;

              if(startupCurrency=="INR"){
                dealValuation = convertToDealDateConversionRate(dealValuation,dealDate);
              }
              
              
                          
            total_valuation = total_valuation + dealValuation;

            let fundCumulativeDataEach = {
              period: dealPeriod,
              total_valuation: total_valuation,
              liveDeals: liveDeals,
              closedDeals: closedDeals,
            };
            fundCumulativeDataPrev.push(fundCumulativeDataEach);
          });

          let fundCumulativeData = [];
          let prev_total_valuation = 0;
          let prev_liveDeals = 0;
          let prev_closedDeals = 0;
          for (let i = 0; i < dates?.length; i++) {
            let periodeach = dates[i];
            let founddata = fundCumulativeDataPrev.findLast(
              element => element?.period == periodeach
            );
            if (!founddata) {
              founddata = {
                period: periodeach,
                total_valuation: prev_total_valuation,
                liveDeals: prev_liveDeals,
                closedDeals: prev_closedDeals,
              };              
            } else {
              prev_total_valuation = founddata?.total_valuation;
              prev_liveDeals = founddata?.liveDeals;
              prev_closedDeals = founddata?.closedDeals;
            }
            fundCumulativeData.push(founddata);
          }


          setFundCumulativeData(fundCumulativeData);
          const monthData = responseData?.resultMonths?.map(data => ({
            value: data?.period,
            label: "Since " + data?.period,
          }));

          let currentMonthOptionLabel = `Since ${moment().format("MMM YY")}`;
          if (
            !monthData?.find(
              month =>
                month?.label?.toLowerCase() ===
                currentMonthOptionLabel?.toLowerCase()
            )
          ) {
            monthData.unshift({
              label: currentMonthOptionLabel,
              value: null,
            });
          }
          setMonthOptions(monthData);
        })
        .catch(err => {});
    };
    if (selectedMonth && selectedFund && Object.keys(selectedFund).length > 0) {
      getCumulativeChartData();
    }
    return () => {};
  }, [selectedMonth, selectedFund]);

  const handleChangeMonth = selectedOption => {
    setSelectedMonth(selectedOption);
  };

  const handleChangeFund = selectedOption => {
    setSelectedFund(selectedOption);
  };

  const CustomizedTick = graphVal => {
    const { x, y, stroke, payload } = graphVal;
    const [month, year] = payload?.value?.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={7} fill="#666" fontSize={11}>
          <tspan textAnchor="middle" x="0">
            {month}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="12">
            {year}
          </tspan>
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label, ...rest }) => {
    if (active) {
      // let total_valuation = new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      //   notation: "compact",
      //   compactDisplay: "short",
      // }).format(payload[0]?.payload.total_valuation);

      // if (selectedFund?.currency === "USD") {
      let total_valuation = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        maximumSignificantDigits: 3,
        minimumSignificantDigits: 3,
      }).format(payload[0]?.payload.total_valuation);
      // }
      return (
        <div
          className="custom-tooltip"
          style={{
            background: payload[0]?.fill,
            margin: "0px",
            padding: "10px",
            border: " 1px solid rgb(204, 204, 204)",
            whiteSpace: "nowrap",
          }}
        >
          <p
            style={{ color: payload[0]?.stroke, marginBottom: "5px" }}
          >{`${payload[0]?.name} : ${payload[0]?.value}`}</p>
          <p
            style={{ color: payload[1]?.stroke, marginBottom: "5px" }}
          >{`${payload[1]?.name} : ${payload[1]?.value}`}</p>
          <p>
            Total Valuation: <b>{total_valuation}</b>
          </p>
          <div></div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="column-sec">
      <Row className="mb-2 align-items-center">
        <Col md={4}>
          <h2 className="mb-4 mt-3 title-sm">Funds Cumulative Data</h2>
        </Col>
        <Col md={4}>
          <Select
            className="custom-select form-control-sm"
            id="period"
            name="period"
            options={monthOptions}
            value={selectedMonth}
            onChange={handleChangeMonth}
          />
        </Col>
        <Col md={4}>
          <Select
            className="custom-select form-control-sm"
            id="fund"
            name="funds"
            options={[
              { _id: null, label: "All Funds" },
              ...sortedFundOptionsList,
            ]}
            value={selectedFund}
            onChange={handleChangeFund}
          />
        </Col>
      </Row>
      <Row>
        <div className="mt-4 mb-4">
          <ResponsiveContainer width="100%" height={280}>
            <LineChart
              data={fundCumulativeData}
              margin={{
                top: 5,
                right: 20,
                left: 5,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" tick={<CustomizedTick />} interval={0} />
              <YAxis
                tickFormatter={value =>
                  new Intl.NumberFormat("en").format(value)
                }
              />
              <Tooltip
                formatter={value => new Intl.NumberFormat("en").format(value)}
                content={<CustomTooltip />}
              />
              <Legend wrapperStyle={{ bottom: -20, left: 25 }} align="center" />
              <Line
                type="monotone"
                dataKey="liveDeals"
                stroke="#008010"
                activeDot={{ r: 8 }}
                name="Live Deals"
                legendType="rect"
              />
              <Line
                type="monotone"
                legendType="rect"
                dataKey="closedDeals"
                name="Close Deals"
                stroke="#c70000"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Row>
    </div>
  );
};

export default FundCumulativeData;
