/* KPIs */
export const GET_FOUNDER_STARTUPS = "GET_STARTUPS"
export const GET_STARTUPS_FAIL = "GET_STARTUPS_FAIL"
export const GET_FOUNDER_STARTUPS_SUCCESS = "GET_STARTUPS_SUCCESS"
export const SET_KPI = "SET_KPI"
export const SET_KPI_FAIL = "SET_KPI_FAIL"
export const SET_KPI_SUCCESS = "SET_KPI_SUCCESS"
export const REMOVE_KPI = "REMOVE_KPI"
export const REMOVE_KPI_FAIL = "REMOVE_KPI_FAIL"
export const REMOVE_KPI_SUCCESS = "REMOVE_KPI_SUCCESS"

export const GET_CURRENCY = "GET_CURRENCY"
export const GET_CURRENCY_FAIL = "GET_CURRENCY_FAIL"
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS"

export const UPDATE_KPIS = "UPDATE_KPIS"
export const UPDATE_KPIS_FAIL = "UPDATE_KPIS_FAIL"
export const UPDATE_KPIS_SUCCESS = "UPDATE_KPIS_SUCCESS"

export const CANCEL_KPI = "CANCEL_KPI"

// alert box
export const CLOSE_ALERT_BOX = "CLOSE_ALERT_BOX"
