import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Table,
  Alert,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import PropTypes from "prop-types";
import {
  closeAlertBox,
  addCredit,
  cancelAddCredit,
} from "../../store/credits/actions";
import { isEmpty } from "lodash";
import { isEqual } from "lodash-es";
import axios from "axios";

class AddCreditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      investor: null,
      error: "",
      show: true,
      count: 0,
      credits: {
        credit_amount: 0,
        credit_type: "Admin Credits",
        credit_action: "add",
        credit_comment: "",
        admin_name: "",
        admin_email: "",
      },
      success: "",
      saas_credit_limit: "",
      saas_credit_limit_validation: "",
      saas_amounts: "",
      errMsg: "",
      amtval: 0,
      errMsgShow: false,
      credit_text: "Amount to Credit",
    };
  }

  componentDidMount() {
    const { investor } = this.props;
    this.setState({ investor: investor });
    this.saas_detail(investor.saas_parent_id);

    let credits = this.state.credits;
    const userDetail = JSON.parse(localStorage.getItem("authUser"))?.userInfo;
    credits["admin_name"] = userDetail?.firstname + " " + userDetail?.lastname;
    credits["admin_email"] = userDetail?.email;
    this.setState({ credits: credits });
    this.setState({
      saas_credit_limit: userDetail?.subscription_fee?.credit_limit,
    });
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //     let error = "", errObj = nextProps?.error?.errors || nextProps?.error;
  //     if(typeof(errObj) === 'object'){
  //       let keys = Object.keys(errObj);
  //       error = errObj[keys[0]];
  //     } else {
  //         error = errObj;
  //     }

  //     this.setState({ error: error, success: nextProps.success, show: nextProps.show });

  //     console.log("tettttt"+this.state.success);
  // }

  static getDerivedStateFromProps(props, state) {
    let error = "",
      errObj = props?.error?.errors || props?.error;
    if (typeof errObj === "object") {
      let keys = Object.keys(errObj);
      error = errObj[keys[0]];
    } else {
      error = errObj;
    }
    if (props.count !== state.count) {
      //Change in props
      return {
        success: props.success,
        error: error,
        show: true,
      };
    }
    return null; // No change to state
  }

  handleAlertClose = () => {
    const { onAlertClose } = this.props;
    onAlertClose();
  };

  onCancel = () => {
    const { onCancelCredit } = this.props;
    onCancelCredit();
    this.props.onClose();
  };

  onClose = () => {
    this.setState({ show: !this.state.show });
    this.props.onCreditUpdateDone();
    setTimeout(() => this.props.onClose(), 3000);
  };

  onCloseWithoutDelay = () => {
    this.setState({ show: !this.state.show });
    this.props.onCreditUpdateDone();
    this.props.onClose();
  };

  handleAddCredits = () => {
    const { onAddCredit } = this.props;
    let submitaction = this.customValidation(this.state.amtval);
    this.setState({
      count: this.state.count + 1,
    });
    if (!submitaction) {
      return false;
    }
    let credits = this.state.credits;
    credits.investor_id = this.state.investor?._id;
    onAddCredit(credits);
    this.onClose();
  };

  handleChange = e => {
    let credits = this.state.credits;
    credits[e.target.name] = e.target.value;
    if (e.target.value === "remove") {
      this.setState({ credit_text: "Amount to Remove" });
    } else {
      this.setState({ credit_text: "Amount to Credit" });
    }
    this.setState({ credits: credits });
    if (e.target.id == "credit_amount") {
      this.setState({ amtval: e.target.value });
      this.customValidation(e.target.value);
    }
  };

  saas_detail = uid => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/getdetail/${uid}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      })
      .then(res => {
        this.setState({
          saas_credit_limit_validation:
            res.data.user?.subscription_fee?.credit_limit,
        });
        this.setState({ saas_credit_limit_validation: res.data.user?.amounts });
      });
  };

  customValidation = amt => {
    // console.log(amt);
    if (this.state.credits?.credit_action === "add") {
      if (
        this.state.saas_amounts?.quarterly_amount == 0 &&
        this.state.saas_amounts?.annually_amount == 0
      ) {
        this.setState({
          errMsg: `User's saas parent has no annually or quarterly amount`,
        });
        this.setState({ errMsgShow: true });
        return false;
      } else if (this.state.saas_credit_limit_validation > 0) {
        if (amt <= 0) {
          this.setState({
            errMsg: `Credit amount is required and must be greater than 0`,
          });
          this.setState({ errMsgShow: true });
          return false;
        } else if (amt > this.state.saas_credit_limit_validation) {
          this.setState({
            errMsg: `Credit amount must greater than 0 and below ${this.state.saas_credit_limit_validation}`,
          });
          this.setState({ errMsgShow: true });
          return false;
        }
      } else {
        if (amt <= 0) {
          this.setState({ errMsg: `Credit amount must be greater than 0` });
          this.setState({ errMsgShow: true });
          return false;
        } else {
          this.setState({ errMsg: `` });
          this.setState({ errMsgShow: false });
          return true;
        }
      }
    } else {
      if (amt < 0) {
        this.setState({
          errMsg: `Credit amount is required and must be greater than 0`,
        });
        this.setState({ errMsgShow: true });
        return false;
      } else {
        this.setState({ errMsg: `` });
        this.setState({ errMsgShow: false });
        return true;
      }
    }
  };

  render() {
    const { investor } = this.state;
    return (
      <div className="page-content">
        <Modal isOpen={this.state.show} toggle={() => this.onClose()} size="lg">
          <ModalHeader toggle={() => this.onCloseWithoutDelay()} tag="h4">
            Add/Remove Credits
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xl="12">
                {!isEmpty(this.state.error) ? (
                  <Alert color="danger" toggle={this.handleAlertClose}>
                    {this.state.error}
                  </Alert>
                ) : null}
                {!!this.state.success ? (
                  <Alert color="success" toggle={this.handleAlertClose}>
                    {this.state.success}
                  </Alert>
                ) : null}
              </Col>
            </Row>
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <AvForm
                    className="outer-repeater"
                    onValidSubmit={() => this.handleAddCredits()}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-6 mt-3" row>
                          <Label
                            htmlFor="user_name"
                            className="col-form-label col-lg-2"
                          >
                            User Name
                          </Label>
                          <Col lg="4">
                            <AvField
                              id="user_name"
                              name="user_name"
                              type="text"
                              className="form-control text-capitalize"
                              value={`${investor?.firstname || ""} ${
                                investor?.lastname || ""
                              }`}
                              disabled
                            />
                          </Col>

                          <Label
                            htmlFor="email"
                            className="col-form-label col-lg-2"
                          >
                            Email
                          </Label>
                          <Col lg="4">
                            <AvField
                              id="email"
                              name="email"
                              type="text"
                              className="form-control text-capitalize"
                              value={investor?.email || ""}
                              disabled
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-6 mt-3" row>
                          <Label
                            htmlFor="active_plan"
                            className="col-form-label col-lg-2"
                          >
                            Active Plan
                          </Label>
                          <Col lg="4">
                            <AvField
                              id="active_plan"
                              name="active_plan"
                              type="text"
                              className="form-control text-capitalize"
                              value={
                                investor?.credit_info?.active_plan_name || ""
                              }
                              disabled
                            />
                          </Col>

                          <Label
                            htmlFor="active_plan_type"
                            className="col-form-label col-lg-2"
                          >
                            Plan Type
                          </Label>
                          <Col lg="4">
                            <AvField
                              id="plan_type"
                              name="plan_type"
                              type="text"
                              className="form-control text-capitalize"
                              value={
                                investor?.credit_info?.active_plan_type || ""
                              }
                              disabled
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-6 mt-3" row>
                          <Label
                            htmlFor="credits"
                            className="col-form-label col-lg-2"
                          >
                            Available credits
                          </Label>
                          <Col lg="4">
                            <AvField
                              id="available_credits"
                              name="available_credits"
                              type="text"
                              className="form-control text-capitalize"
                              value={investor?.credit_info?.credit_amount || ""}
                              disabled
                            />
                          </Col>
                          <Label
                            htmlFor="expires_on"
                            className="col-form-label col-lg-2"
                          >
                            Plan Expire On
                          </Label>
                          <Col lg="4">
                            <AvField
                              id="expires_on"
                              name="expires_on"
                              type="text"
                              className="form-control text-capitalize"
                              value={investor?.credit_info?.expires_on || ""}
                              disabled
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4 mt-3" row>
                          <Label
                            htmlFor="credit_action"
                            className="col-form-label col-lg-2"
                          >
                            Credit Action
                          </Label>
                          <Col lg="10">
                            <FormGroup check inline>
                              <Input
                                defaultChecked
                                value="add"
                                type="radio"
                                onChange={e => this.handleChange(e)}
                                name="credit_action"
                              />
                              <Label check>Add Credit</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                value="remove"
                                onChange={e => this.handleChange(e)}
                                type="radio"
                                name="credit_action"
                              />
                              <Label check>Remove Credit</Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4 mt-3" row>
                          <Label
                            htmlFor="credit_amount"
                            className="col-form-label col-lg-2"
                          >
                            {this.state.credit_text}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Col lg="10">
                            <AvField
                              id="credit_amount"
                              name="credit_amount"
                              type="number"
                              className="form-control"
                              placeholder="Enter credit amount"
                              errorMessage={
                                this.state.errMsgShow == true
                                  ? this.state.errMsg
                                  : ""
                              }
                              value={this.state.credits?.credit_amount || "0"}
                              onChange={e => this.handleChange(e)}
                            />
                            <span className="text-danger">
                              {this.state.errMsgShow == true
                                ? this.state.errMsg
                                : ""}
                            </span>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4 mt-3" row>
                          <Label
                            htmlFor="credit_comment"
                            className="col-form-label col-lg-2"
                          >
                            Comments <span className="text-danger">*</span>
                          </Label>
                          <Col lg="10">
                            <AvField
                              id="credit_comment"
                              name="credit_comment"
                              type="text"
                              className="form-control"
                              placeholder="Reason of credit add or remove"
                              errorMessage="Credit Comment is required"
                              validate={{
                                required: { value: true },
                              }}
                              value={this.state.credits?.credit_comment || ""}
                              onChange={e => this.handleChange(e)}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                    <Row>
                      <Col md={12} className="text-center">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => {}}
                        >
                          Submit
                        </Button>
                        &nbsp;&nbsp;
                        <Button color="secondary" onClick={this.onCancel}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </React.Fragment>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddCreditModal.propTypes = {
  investor: PropTypes.any,
  onAlertClose: PropTypes.func,
  onAddCredit: PropTypes.func,
  onCancelCredit: PropTypes.func,
  className: PropTypes.any,
  show: PropTypes.bool,
  error: PropTypes.any,
  success: PropTypes.any,
};

const mapStateToProps = ({ credits }) => ({
  error: credits.error,
  success: credits.success,
  // show: credits.show,
});

const mapDispatchToProps = dispatch => ({
  onAddCredit: data => dispatch(addCredit(data)),
  onCancelCredit: kpi => dispatch(cancelAddCredit()),
  onAlertClose: () => dispatch(closeAlertBox()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditModal);
