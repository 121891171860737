export const displayImage = url => {
  if (typeof url === "undefined") {
    return "";
  }

  let re = "wfc-development.s3";
  let re_new = "wfc-developments.s3";
  let re1 = "wfc-pdevelopment.s3";
  let re1_new = "wfc-pdevelopments.s3";
  if (
    url?.indexOf(re) > -1 ||
    url?.indexOf(re_new) > -1 ||
    url?.indexOf(re1) > -1 ||
    url?.indexOf(re1_new)
  ) {
    url = `${url}`;
  } else if (
    url.indexOf(window.location.origin) > -1 ||
    url.indexOf(window.location.protocol) > -1
  ) {
    url = `${url}`;
  } else {
    url = `${process.env.REACT_APP_API_URL}/${url}`;
  }
  return url;
};

export const profilePicture = image => {
  let pic = "/img/profile.png";
  let re = "wfc-development.s3";
  let re_new = "wfc-developments.s3";
  let re1 = "wfc-pdevelopment.s3";
  let re1_new = "wfc-pdevelopments.s3";
  if (typeof image !== "string") {
    return pic;
  }
  if (image) {
    if (
      image.indexOf(re) > -1 ||
      image.indexOf(re1) > -1 ||
      image.indexOf(re_new) > -1 ||
      image.indexOf(re1_new) > -1
    ) {
      pic = `${image}`;
    } else if (
      image.indexOf(window.location.origin) > -1 ||
      image.indexOf(window.location.protocol) > -1
    ) {
      pic = `${image}`;
    } else {
      pic = `${process.env.REACT_APP_API_URL}/${image}`;
    }
  }
  return pic;
};

export const getAdminUserInfo = () => {
  try {
    let userData = localStorage.getItem("user_info");

    return JSON.parse(userData);
  } catch (error) {
    return null;
  }
};

export const DEMAT_STATUS_OPTIONS = [
  {
    value: "incomplete",
    label: "Demat Pending",
    option: "incomplete",
  },
  {
    value: "pending",
    label: "Demat Filled, Approval Pending",
    option: "pending",
  },
  {
    value: "disapproved",
    label: "Demat Disapproved",
    option: "disapproved",
  },
  {
    value: "approved",
    label: "Demat Approved",
    option: "approved",
  },
  {
    value: "dropped",
    label: "Demat Dropped",
    option: "dropped",
  },
];

export const RELATION_OPTIONS = [
  {
    label: "Self",
    value: "Self",
    option: "Self",
  },
  {
    label: "Spouse",
    value: "Spouse",
    option: "Spouse",
  },
  {
    label: "Mother",
    value: "Mother",
    option: "Mother",
  },
  {
    label: "Father",
    value: "Father",
    option: "Father",
  },
  {
    label: "Children",
    value: "Children",
    option: "Children",
  },
  {
    label: "Entity",
    value: "Entity",
    option: "Entity",
  },
];
