// // import React from "react";
// // import ReactDOM from "react-dom";

// // function getData(callback) {
// //   fetch("http://localhost:4000/api/chat/all")
// //     .then(results => {
// //         console.log(results,"results of getdata")
// //       return results.json();
// //     })
// //     .then(callback);
// // }

// // class Button extends React.Component {
// //   constructor(props) {
// //     super(props);

// //     this.handleClick = this.handleClick.bind(this);
// //     this.state = {
// //       data : null
// //     };
// // }

// // handleClick() {
// //   this.props.getData(data => this.setState({ data }));
// // }

// // _renderUsers() {
// //   return (
// //     <ol>
// //       { 
// //         this.state.data.map(user => <li key={ user.id }>{ user.firstname }</li>) 
// //       }
// //     </ol>
// //   );
// // }

// // render() {
// //     return (
// //       <div>
// //         <button onClick={ this.handleClick }>Get All Users</button>
// //         { this.state.data && this._renderUsers() }
// //       </div>
// //     );
// //   }
// // }

// // ReactDOM.render(<Button getData={ getData }/>, document.getElementById("root"));


// import React, { Component } from 'react';
// import { render } from 'react-dom';
// // import Details from './Details';
// // import './index.css';

// class Msg extends Component {
//   constructor() {
//     super();
//     this.state = {
//       usersData: [],
//       error: null,
//       selectedUser: null
//     };
//   }

//   handleClick = (id) => {
//     const selectedUser = this.state.usersData.find(user => user.id === id)
//     this.setState(() => ({selectedUser}))
//     this.setState(() => ({selectedUser}), () => console.log(this.state.selectedUser))
//   }

  

//   render() {
//     const usersList = this.state.usersData.map(user => {
//       return <li key={user.id} onClick={()=>this.handleClick(user.id)}>{user.firstname}</li>
//     })
//     return (
//       <>
//         <div className="UserList">
//           <h1>Users</h1>
//           <ul>{usersList}</ul>
//         </div>
//           {/* <Details user={this.state.selectedUser} /> */}
//       </>
//     );
//   }

//   componentDidMount() {
//     fetch('https://api.github.com/users')
//       .then(response => response.json())
//       .then(data => {
//         this.setState({usersData: data})
//         })
//       .catch(err => this.setState({error: err.message}))
//     // const token = authHeader();
//     // axios
//     //   .get('http://localhost:4000/api/chat/all', {
//     //     headers: { Authorization: `${token}` },
//     //   })
//     //   .then(res => {
//     //     console.log(res.data);
//     //     this.setState({usersData: res.data});
//     //   })
//     //   .catch(err => {
//     //     setError(err);
//     //   });
//   }
// }

// export default Msg;

// import React, { Component } from 'react';
// import { render } from 'react-dom';
// import { Col, Row, Form, Button, Figure, Dropdown } from "react-bootstrap";
// import Chat from './Chatbox';
// import axios from "axios";

// class Msg extends Component {
//   constructor() {
//     super();
//     this.state = {
//       usersData: [],
//       error: null,
//       selectedUser: null
//     };
//   }

//   handleClick = (id) => {
//     const selectedUser = this.state.usersData.find(user => user.id === id)
//     this.setState(() => ({selectedUser}))
//     // this.setState(() => ({selectedUser}), () => console.log(this.state.selectedUser))
//   }

//   GetLeftPanel = () => {

//     axios.get(`${process.env.REACT_APP_API_URL}/api/chat/all`,
//          {
//            headers: { Authorization: localStorage.getItem("auth_token") },
//          }
//        ).then(async res => {

//          console.log(res.data, "=========res.data===========")       
        
//        })
//        .catch((err) => console.error(err));
//  } 


//   render() {
//     const usersList = this.state.usersData.map(user => {
//       return <li key={user.id} onClick={()=>this.handleClick(user.id)}>{user.login}</li>
//     })
//     return (
//       <>
//       <Row>
//         <Col lg={3} md={4} className="message">
//           <div className="user_list column-card">
//             <h1>Users</h1>
//             <ul>{usersList}</ul>
//           </div>
//         </Col>
//         <Col lg={9} md={8}>
//           <Chat user={this.state.selectedUser} />
//         </Col>
//       </Row>
        
         
//       </>
//     );
//   }

//   componentDidMount() {
//     fetch('https://api.github.com/users')
//       .then(response => response.json())
//       .then(data => {
//         this.setState({usersData: data})
//         })
//       .catch(err => this.setState({error: err.message}))  
//   }


  
// }

// export default Msg;


// import React, { Component } from 'react';
// import React, { useState, useEffect, useRef } from "react";
// import { render } from 'react-dom';
// import { Col, Row, Form, Button, Figure, Dropdown } from "react-bootstrap";
// import Chat from './Chatbox';
// import axios from "axios";


// function Msg() {

//   const [users, setUsers] = useState([]);


  

//   useEffect(() => {
    
//     // GetLeftPanel();

//     // const interval = setInterval(() =>{
//     //   GetLeftPanel();
//     // } , 3000);
//     // return () => clearInterval(interval);

//     fetch('https://api.github.com/users')
//     .then(response => response.json())
//     .then(data => {
//       setUsers(res?.data)
//       })
//     .catch(err => ({error: err.message}))  
//   }, []);

//   // const usersList = users.map(user => {
//   //   return <li key={user.id} onClick={()=> handleClick(user.id)}>{user.login}</li>
//   // })
  
//   return (

    

// <>
//       <Row>
//         <Col lg={3} md={4} className="message">
//           <div className="user_list column-card">
//             <h1>Users</h1>
//             <ul>
//               {/* { users.map(user =>{<li>sapna naresh  panwar</li>})}</ul> */}
//             {users?.map((e,i) => 
//                           <li>{e.login}</li>
//                         )}
//             </ul>
//           </div>
//         </Col>
//         <Col lg={9} md={8}>
//           {/* <Chat user={this.state.selectedUser} /> */}
//           <Chat/>
        
//         </Col>
//       </Row>
        
         
//       </>

//   );
// }

// export default Msg;


import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Container, Row, Col, Form, Button, Figure } from 'react-bootstrap';
// import MainSidebar from '../components/MainSidebar';
// import Messages from './Messages';
// import {
//   profilePicture,
// } from "../helpers/storageHelper";
import Chat from './Chatbox';


// function loadScript(src) {
// 	return new Promise((resolve) => {
// 		const script = document.createElement('script')
// 		script.src = src
// 		script.onload = () => {
// 			resolve(true)
// 		}
// 		script.onerror = () => {
// 			resolve(false)
// 		}
// 		document.body.appendChild(script)
// 	})
// }

// let socket;

function Msg() {
  const [users, setUsers] = useState([]);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [input, setInput] = useState('');
  const [thread, setThread] = useState('');
  const [unread,setUnreadMsg] = useState(null);
  const [timeupdate,setTime] = useState(null);

  
  
  const scrollRef = useRef(null);
  // const socket = useRef(io("ws://localhost:4000"));
  const updateInput = async input => {
    const searched = defaultUsers.filter(user => {
      return user.users.firstname
        .toLowerCase()
        .includes(input.toLowerCase());
    });
    setInput(input);
    setUsers(searched);
  };


  const value = JSON.parse(localStorage.getItem("authUser"));
  console.log(value.token,"auth token value======");
  
  const GetLeftPanel = () => {
     axios.get(`${process.env.REACT_APP_API_URL}/api/chat/all`,
          {
            headers: { Authorization: value.token },
          }
        ).then(async res => {
          console.log(res?.data?.users,"res.data.info;;;;;;;;;;;;!")
          await setUsers(res?.data?.users);
          // await setUser(res?.data?.info[0]);
          // await setDefaultUsers(res?.data?.info);
          // await setTime(res.data.threads.updatedAt);
        })
       .catch((err) => {
         // console.error(err)
       });
  } 

  useEffect(() => {
    // loadScript('/socket.io/socket.io.js')
    
    GetLeftPanel();

    const interval = setInterval(() =>{
      GetLeftPanel();
    } , 3000);
    return () => clearInterval(interval); 

  }, []);
  
  return (
    <section className="panel-wrapper">
        <Container>
          <div className="admin-wrapper">
            
              <div className="sec-column messages-sec">
                <Row>
                  <Col lg={4}>
                    <div className="column-card">
                      <header className="entry-title mb-4">
                        <h2 className="title-xs">Messaging</h2>
                        <div className="search-bar">
                          <Form>
                            <Form.Group>
                              <Form.Control type="text" value={input} placeholder="Type here to search..." onChange={(e)=>updateInput(e.target.value)} />
                              <Button variant="add" type="submit"><img alt="" src="../img/search-ico.svg" /></Button>
                            </Form.Group>
                          </Form>
                        </div>
                      </header>
                      <div className="user-listing-sec cus-scr" style={{cursor:"pointer"}}>

                        {users?.map((e,i) => 
                          <div className={e?.id === user?.id?"item active":"item"} Key={e.id}>
                          <a onClick={()=>{ setUser(e) }} className="listing-col">
                            <Figure>
                              {/* <Figure.Image alt="" src={profilePicture(e?.users?.profile_pic)} /> */}
                            </Figure>
                            <div className="contect-sec mt-0">
                              <h3 className="title-xxs">{e?.firstname}{" "}{e?.lastname}</h3>
                              {/* <p>{ e?.users?.user_types?.map((event) => event.user_type + ", " ) }</p> */}
                              {/* {((e?.unread) > 0 ) ? <span className="tag">{e?.unread}</span>: ""} */}
                              {/* <span className="time-sec"><TimeAgo date={Date.parse(user?.updatedAt)} /></span> */}
                            </div>
                          </a>
                        </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  {/* <Messages conversation={user}/> */}
                  { user != null ? <Chat conversation={"user"}/> : <div className="col-lg-8"></div>}
                </Row>
              </div>

          </div>
        </Container>
      </section>
  );
}

export default Msg;
