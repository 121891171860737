/* USERS */
export const GET_USERS = "GET_USERS";
export const GET_USERS_FROM_SAAS = "GET_USERS_FROM_SAAS";
export const GET_FOUNDERS = "GET_FOUNDERS";
export const GET_INVESTORS = "GET_INVESTORS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_FROM_SAAS_SUCCESS = "GET_USERS_FROM_SAAS_SUCCESS";
export const GET_USERS_FROM_SAAS_FAIL = "GET_USERS_FROM_SAAS_FAIL";

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_FUNDING = "DELETE_FUNDING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
