import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  Modal,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { CardBody } from "reactstrap";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import classnames from "classnames";
import axios from "axios";
import { amountNumberToTextFrmt } from "../../helpers/utils";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const FounderReport = ({ match }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [reports, setReports] = useState([]);
  const [StartapName, setStartapName] = useState("");
  const [refrances, setMyrefrance] = useState([]);
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggles = () => setModal(!modal);
  const token = authHeader();

  const Myrefrances = async () => {
    const token = authHeader();
    try {
      let { data } = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API_URL +
          `/api/connection/userReferences/${match.params.id}`,
        headers: { Authorization: token },
      });
      if (data) {
        setMyrefrance(data.references);
      }
    } catch (error) {
      //   console.log(error);
    }
  };

  const fetchFunding = async () => {
    // await axios({ method: 'get', url: `/api/applyforfunding/past/round`, headers: headers }),
    await axios({
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/api/applyforfunding/past/round/founder/${match.params.id}`,
      headers: { Authorization: token },
    })
      .then(response => {
        setReports(response.data.details.rounds);

        // formatting report data for listing
        let data = response.data.details.rounds;
        let tempReport = [];
        data.forEach(item => {
          let investmentDetailsObj = {
            funding_round: item.funding_round,
            current_valuation: item.current_valuation,
            how_much_fund_raising: item.how_much_fund_raising,
            funding_source: item.funding_source,
            platform: item.platform,
            deal_type: item.deal_type,
            current_status: item.current_status,
            valuation_type: item.valuation_type,
          };

          let investors = item.internal_funded_investors.filter(
            user => user.verify_by_founder === true
          );

          let investorObj = {};
          let total_investment = 0,
            round_name = "";
          investors.forEach((user, index) => {
            investorObj["fullname"] = `${user.firstname} ${user.lastname}`;
            investorObj["total_invested"] = amountNumberToTextFrmt(
              parseInt(user.total_invested)
            );
            investorObj["funding_round"] =
              investmentDetailsObj["funding_round"];
            investorObj["current_valuation"] =
              investmentDetailsObj["current_valuation"];
            investorObj["how_much_fund_raising"] =
              investmentDetailsObj["how_much_fund_raising"];
            investorObj["funding_source"] =
              investmentDetailsObj["funding_source"];
            investorObj["platform"] = investmentDetailsObj["platform"];
            investorObj["deal_type"] = investmentDetailsObj["deal_type"];
            investorObj["current_status"] =
              investmentDetailsObj["current_status"];
            investorObj["valuation_type"] =
              investmentDetailsObj["valuation_type"];

            total_investment += user.total_invested;
            round_name = investmentDetailsObj["funding_round"];
            tempReport.push(investorObj);
            if (investors.length - 1 === index) {
              investorObj = {};
              investorObj["fullname"] = `Total Investment for ${round_name}:`;
              investorObj["total_invested"] = amountNumberToTextFrmt(
                parseInt(total_investment)
              );
              tempReport.push(investorObj);
            }
            investorObj = {};
          });
        });

        setReports(tempReport);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  const fetchStartupName = async () => {
    // await axios({ method: 'get', url: `/api/applyforfunding/past/round`, headers: headers }),
    await axios({
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/api/applyforfunding/past/round/founder/${match.params.id}`,
      headers: { Authorization: token },
    })
      .then(response => {
        setStartapName(response.data.details.startup_name);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  useEffect(() => {
    Myrefrances();
    fetchFunding();
    fetchStartupName();
    return () => {};
  }, []);
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const rowClasses = row => {
    return row?.fullname?.startsWith("Total Investment")
      ? "row-class"
      : "" || false;
  };

  const columns = [
    {
      dataField: "fullname",
      text: "Investor's Name",
      sort: true,
    },
    {
      dataField: "total_invested",
      text: "Total Invested",
      sort: true,
    },
    {
      dataField: "funding_round",
      text: "Funding Round",
      sort: true,
    },
    {
      dataField: "current_valuation",
      text: "Amount",
      sort: true,
      formatter: (cellContent, round) => {
        return (
          <>{amountNumberToTextFrmt(parseInt(round.current_valuation)) || ""}</>
        );
      },
    },
    {
      dataField: "how_much_fund_raising",
      text: "Total Platform Ask",
      sort: true,
      formatter: (cellContent, round) => {
        return (
          <>
            {amountNumberToTextFrmt(parseInt(round.how_much_fund_raising)) ||
              ""}
          </>
        );
      },
    },
    {
      dataField: "funding_source",
      text: "Source",
      sort: true,
    },
    {
      dataField: "platform",
      text: "Platform",
      sort: true,
    },
    {
      dataField: "deal_type",
      text: "Instrument Type",
      sort: true,
    },
    {
      dataField: "current_status",
      text: "Deal Status",
      sort: true,
    },
    {
      dataField: "valuation_type",
      text: "Valuation Type",
      sort: true,
    },
    // {

    //   formatter: (cellContent) => (
    //     <div className="d-flex gap-3">
    //       <button type="button" onClick={toggles} className="font-16 btn-block btn btn-primary">View More</button>
    //     </div >
    //   ),
    // },
  ];

  const columnss = [
    {
      dataField: "user_id",
      text: "Name",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio.user_id.firstname} {portfolio.user_id.lastname}
        </>
      ),
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "user_types",
      text: "User type",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>{portfolio.user_id.user_types[0].user_type[0]}</>
      ),
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
    },
  ];
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
  };
  return (
    <div>
      <Modal isOpen={modal} size="lg" toggle={toggles}>
        <ModalBody>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </ModalBody>
      </Modal>
      <Row>
        <h5>Startup Name: {StartapName}</h5>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Funding Round
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            My Reference
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={columns}
                    data={reports}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={reports}
                        search
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    rowClasses={rowClasses}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="_id"
                columns={columnss}
                data={refrances}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columnss}
                    data={refrances}
                    search
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-hover"}
                                bordered={false}
                                striped={true}
                                responsive
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
};

FounderReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default FounderReport;
