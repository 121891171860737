import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { getPostEventList } from "../../store/pitchdays/actions";
import { isEmpty, size } from "lodash";
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import DataFetchLoader from "components/Common/DataFetchLoader";
import {
  getAllUsersForReference,
} from "../../helpers/backend_helper";

class CreateEventReportList extends Component {
  constructor(props) {

    super(props);
    this.node = React.createRef();
    this.state = {
      roleWiseAccess: {},
      postEvents: [],
      modal: false,
      selectOptions:[],
      loading: false,
      pitchDayListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, pitchday) => <>{pitchday.id}</>,
        },
        {
          text: "Title",
          dataField: "title",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {pitchday.title}
                </Link>
              </h5>
            </>
          ),
          filter: textFilter(),
        },
        {
          dataField: "event_type",
          text: "Event Type",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "saas_parent_id",
          text: "Syndicate Name",
          filter: selectFilter({ options: this.selectDataSaas }),
          formatter: (cellContent, pitchday) => (
            <>{pitchday.saas.length ? pitchday.saas[0].saas_name : ""}</>
          ),
        },
        {
          dataField: "date.event_date",
          text: "Event On",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              {moment
                .utc(pitchday.date.event_date)
                .local()
                .format("DD MMM YYYY")}<br />
              {moment
                .utc(pitchday.date.event_date)
                .local()
                .format("hh:mm A")}

            </>
          ),
        },
        {
          dataField: "updatedAt",
          text: "Updated On",
          sort: true,
          formatter: (cellContent, pitchday) => (
            <>
              {moment.utc(pitchday.updatedAt).local().format("DD MMM YYYY")}
              <br />{moment.utc(pitchday.updatedAt).local().format("hh:mm A")}
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, pitchday) => {
            // let date = moment.utc().local().format("YYYY-MM-DD HH:mm") + ':00';
            // let abc = date.split('-');
            // let s = abc[2].replace(/^0+/, '');
            // let newDate = abc[0] + "-" + abc[1] + "-" + s;
            // console.log("---newDate---", newDate);
            return (
              <div className="d-flex gap-3">
                {
                  (pitchday?.event_type === "General Event") ?
                    this.state.roleWiseAccess?.eventAttendanceReport ?
                      (moment.utc().local().format("YYYY-MM-DD HH:mm") >= moment.utc(pitchday.date.event_date).local().add(2, "hours").format("YYYY-MM-DD HH:mm")) ?
                        <Link className={`btn btn-${pitchday?.isGenerated?"success":"primary"}`} to={`/create-event-report/${pitchday._id}`}>
                          {pitchday?.isGenerated?"View Report":"Generate Report"}
                        </Link> :
                        // <span className="btn btn-success">Attendance Report</span>
                        <Link className="btn btn-primary" to={`/create-event-report/${pitchday._id}`} >Invitees Report</Link> 
                      : ''
                    : (pitchday?.is_associated) ?
                      this.state.roleWiseAccess?.eventAttendanceReport ?
                        <Link className="btn btn-success" to={`/create-event-report/${pitchday._id}`}>Attendance Report</Link>
                        : ''
                      :
                      this.state.roleWiseAccess?.eventGenerateReport ?
                        (moment.utc().local().format("YYYY-MM-DD HH:mm") >= moment.utc(pitchday.date.event_date).local().add(2, "hours").format("YYYY-MM-DD HH:mm")) ?
                          <Link className="btn btn-primary" to={`/create-event-report/${pitchday._id}`} >
                            {pitchday?.isGenerated?"View Report":"Generate Report"}
                            </Link> :
                          // <span className="btn btn-primary">Generate Report</span> 
                          <Link className="btn btn-primary" to={`/create-event-report/${pitchday._id}`} >Invitees Report</Link> 
                        : ''
                }
              </div>
            )
          },
        },
      ],
    };
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );
    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };
  }

  async getAllUsersForReferenceNEW() {
    this.setState({ loading: true })
    await getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_fullname,
              saas_name,
              saas_alias,
              saas_parent_id,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              let temp2 = saas_fullname;
              if (temp2) {
                temp2 += ` (${saas_name})`;
              } else {
                temp2 = saas_alias;
              }
              temp = temp2 || temp;
              saas_parent_id = _id;

              saasPartners.push({
                value: saas_parent_id,
                label: temp,
              });
            }
            // console.log(users);
            this.setState({ selectOptions: saasPartners });

            return saasPartners;
          })
          .filter(data => !!data);
        this.setState({ loading: false })


        // setSaasPartnersList(saasPartners);
      })
      .catch(error => {
        console.trace(error);
        this.setState({ loading: false })
      });
  }

  selectDataSaas = () => {
    return this.state.selectOptions;
  }
  componentDidMount() {
    const { onGetPostEventList, postEvents } = this.props;

    onGetPostEventList();
    this.setState({ postEvents });

    const roleId = JSON.parse(localStorage.getItem('authUser')).userInfo.roleId

    axios.get(`${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('authUser')).token
      }
    }).then(res => {
      this.setState({ roleWiseAccess: res.data.roleWiseAccess })
    }).catch(error => {
      console.log("route-error", error?.message);
    });

    this.getAllUsersForReferenceNEW();
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { postEvents } = this.props;
    if (
      !isEmpty(postEvents) &&
      size(prevProps.postEvents) !== size(postEvents)
    ) {
      this.setState({ postEvents: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    const { postEvents, error } = this.props;
    const { isEdit } = this.state;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: postEvents.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "date.event_date", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Event Report | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
          <DataFetchLoader loading={this.state.loading} />
            <Breadcrumbs title="Event" breadcrumbItem="Event Report" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <DataFetchLoader loading={(this.state.roleWiseAccess?.eventAttendanceReport || this.state.roleWiseAccess?.eventGenerateReport) ? false : true} />
                    {(this.state.roleWiseAccess?.eventAttendanceReport || this.state.roleWiseAccess?.eventGenerateReport) &&
                      <PaginationProvider
                        pagination={paginationFactory(this.options)}
                        keyField="_id"
                        columns={this.state.pitchDayListColumns}
                        data={postEvents}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="_id"
                            columns={this.state.pitchDayListColumns}
                            data={postEvents}
                            search
                          >
                            {toolkitprops => (
                              <React.Fragment>
                                {/* <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end"></div>
                                </Col>
                              </Row> */}

                                <Row>
                                  {/* <Col xl="12">
                                    {!isEmpty(error) ? (
                                      <p className="text-danger">
                                        Some error occured!
                                      </p>
                                    ) : null}
                                  </Col> */}
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="event-report-table">
                                      <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        defaultSorted={defaultSorted}
                                        classes={"table event-list align-middle table-hover"}
                                        bordered={false}
                                        striped={true}
                                        responsive
                                        ref={this.node}
                                        filter={filterFactory()}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CreateEventReportList.propTypes = {
  onGetPitchDays: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  onGetPostEventList: PropTypes.func,
  postEvents: PropTypes.array,
};

const mapStateToProps = ({ pitchdays }) => ({
  postEvents: pitchdays.postEvents,
  error: pitchdays.errorPostEvent,
});

const mapDispatchToProps = dispatch => ({
  onGetPostEventList: () => dispatch(getPostEventList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateEventReportList));
