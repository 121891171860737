import * as moment from "moment";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { concat, isEqual } from "lodash";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import DatePicker from "react-datepicker";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  updatePitchDay,
  getPitchFounders,
  getPitchInvestors,
  getPitchDayDetail,
  getPitchPanellist,
} from "store/pitchdays/actions";
import FounderList from "./FounderList";
import UserGroupsModal from "./Modals/UserGroupsModal";

// Quill Editor for Event Description
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import Select from "react-select";
import { getAllUsersForReference, getTimezones, handleShowCaseFounder } from "helpers/backend_helper";
import { getNumberOfParticipantsOptions } from "helpers/common";
let counter = 0;

class PitchDaysUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video_link: "",
      id: "",
      post_category: "",
      event_type: "",
      title: "",
      event_slug: "",
      event_register_for: "",
      description: "",
      descriptionText: "",
      post_pitchday_marketing_content: "",
      event_datetime: "",
      event_end_datetime: "",
      zoom_link: "",
      additional_information: "",
      zoom_meeting_id: "",
      // industrySectors: [],
      industrySectors: [],
      investors: [{ investorName: "" }],
      founders: [{ founderName: "" }],
      images: [{ fileName: "", file: "" }],
      pitch_day_marketing_images: [{ fileName: "", file: "" }],
      pitch_day_marketing_image: "",
      moderators_link: "",
      moderatorlink_see_index: null,
      login_banner: { fileName: "", file: "" },
      small_banner: { fileName: "", file: "" },
      // big_banner: { fileName: "", file: "" },
      loginBanner: "",
      smallBanner: "",
      event_image: "",
      bigBanner: "",
      enableRecordings: "",
      // disableReminders: "",
      eventInHours: false,
      eventToday: false,
      eventTommorow: false,
      eventInAWeek:false,

      fieldType: [],
      fieldTypeOptions: [
        { label: "Text Box", value: "text" },
        { label: "Single Choice", value: "radio" },
        { label: "Multiple Choice", value: "checkbox" },
      ],
      fieldLabel: "",
      newFieldAddIndex: -1,
      customFieldCount: 0,
      customFieldIndex: 1,

      alert_visible: false,
      modal: false,
      selectedFounders: [],
      selectedInvestors: [],
      selectedPanellist: [],
      selectedFounder: [],
      selectedInvestor: [],
      defaultFounder: [],
      defaultInvestor: [],
      selectedRanges: [],
      TotalParticipantsOptions: getNumberOfParticipantsOptions(),
      TotalParticipants: 11,
      isInvestors: "",
      userGroupsModalOpen: false,
      groupType: "investor",
      event_address: "",
      timezonelist: [],
      selectedTimezon: "IST",
      event_plateform: "enablex",
      referenceUserList: [],
      saasParentList: [],
      saas_parent_id: "",
      errors: {
        errorStatus: false,
        founder: "",
        founderSelect: false,
      },
      investor_groups: {
        groups: [],
        countries: [],
        states: [],
        cities: [],
      },
      founder_groups: {
        groups: [],
        countries: [],
        states: [],
        cities: [],
      },
      selectAllInvestors: false,
      selectAllFounders: false,
      isDisable: false,
      isDisableMsg: "",
      saasName: "",
      authUser: JSON.parse(localStorage.getItem("authUser")),
    };

    this.eventDateTimeChange.bind(this);
    this.eventEndDateTimeChange.bind(this);
    this.handleAddIndustrySectors.bind(this);
    this.handleRemoveIndustrySectors.bind(this);
    this.handleAddInvestors.bind(this);
    this.handleRemoveInvestors.bind(this);
    this.handleAddFounders.bind(this);
    this.handleRemoveFounders.bind(this);
    this.handleAddImages.bind(this);
    this.handleRemoveImages.bind(this);
    this.handleRemovePostPitchDayMarketingImages.bind(this);
    this.handleValidSubmit.bind(this);
    this.handleChangeIndustrySector.bind(this);
    this.handleChangeInvestor.bind(this);
    this.handleChangeFounder.bind(this);
    this.handleChangeImage.bind(this);
    this.handleChangePostPitchDayMarketingImage.bind(this);
    this.handleChangeVideo.bind(this);
    this.onAlertDismiss.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setFounders = this.setFounders.bind(this);
    this.toggleInv = this.toggleInv.bind(this);
    this.setUserGroupsModal = this.setUserGroupsModal.bind(this);
    this.handleUserGroupsModal = this.handleUserGroupsModal.bind(this);
    this.handleGroupsValuesChange = this.handleGroupsValuesChange.bind(this);
    this.setSelectAllInvestors = this.setSelectAllInvestors.bind(this);
    this.setSelectAllFounders = this.setSelectAllFounders.bind(this);
    this.getSaasParentUserList = this.getSaasParentUserList.bind(this);
    this.handleShowCaseChange = this.handleShowCaseChange.bind(this);
  }

  setSelectAllInvestors(selectAllInvestors) {
    this.setState({ selectAllInvestors });
  }

  setSelectAllFounders(selectAllFounders) {
    this.setState({ selectAllFounders });
  }

  async componentDidMount() {
    const {
      match,
      onGetPithDay,
      onGetPitchFounders,
      onGetPitchInvestors,
      onGetPitchPanellist,
    } = this.props;
    const video_link = this.props.pitchday?.media_content?.find(
      m => m.media_type[0] === "video"
    );
    if (video_link !== undefined) {
      this.setState({ video_link: video_link.media });
    }
    const { id } = match.params;
    const currentTime = moment().utc().format("YYYY-MM-DD hh:mm:ss A");
    await onGetPithDay({ _id: id, currentTime: currentTime });
    await onGetPitchFounders();
    await onGetPitchInvestors();
    this.getSaasParentUserList();
    this.getTimezonDetails();
    await onGetPitchPanellist();
  }
  getTimezonDetails = () => {
    getTimezones()
      .then(response => {
        let storeTimezone = [];
        response?.zonelist?.map(item => {
          if (item) {
            storeTimezone.push({
              value: item?.alias,
              label: item?.alias + " (" + item?.location + ")",
              offset: item?.offset,
            });
          }
        });
        this.setState({ timezonelist: storeTimezone });
      })
      .catch(error => {
        console.trace(error);
      });
  };
  getSaasParentUserList = () => {
    getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let { _id, firstname, lastname, email, saas_name } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              temp = temp + " - " + user?.saas_fullname;
              saasPartners.push({
                value: _id,
                label: temp,
                saas_name: saas_name,
              });
            }

            return { value: _id, label: temp, saas_name: saas_name };
          })
          .filter(data => !!data);
        this.setState({
          referenceUserList: users,
          saasParentList: saasPartners,
        });
      })
      .catch(error => {
        console.trace(error);
        this.setState({ referenceUserList: [], saasParentList: [] });
      });
  };

  handleShowCaseChange = async (payload) => {
    try {
      await handleShowCaseFounder(payload).then((res) => {
      }).catch(err => {
      })
    } catch (err) {
    }
  }

  setUserGroupsModal = userGroupsModalOpen =>
    this.setState({ userGroupsModalOpen });

  handleUserGroupsModal = () =>
    this.setState(prevState => ({
      userGroupsModalOpen: !prevState.userGroupsModalOpen,
    }));

  handleGroupsValuesChange = (groupKey, key, value) => {
    this.setState(prevState => ({
      [groupKey]: { ...prevState[groupKey], [key]: value },
    }));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.pitchday, nextProps.pitchday)) {
      let {
        _id,
        post_category,
        saas_parent_id,
        event_type,
        title,
        event_slug,
        event_register_for,
        description,
        descriptionText,
        post_pitchday_marketing_content,
        meeting_details,
        date,
        industry_sectors,
        invited_investor,
        invited_founder,
        invited_panellist,
        investor_groups,
        founder_groups,
        selectAllInvestors,
        selectAllFounders,
        selectedPanellist,
        event_image,
        login_banner,
        small_banner,
        // big_banner,
        customFields,
        pitch_day_marketing_images,
        is_disable,
        saasName,
        selectedTimezon,
        event_plateform,
        event_address,
        enablex_meeting_details,
        enableRecordings,
        eventBookedSlotes,
        disableReminders,
      } = nextProps.pitchday;

      investor_groups = {
        ...investor_groups,
        groups: investor_groups.groups.map(group => ({
          value: group._id,
          label: group.name,
        })),
      };
      founder_groups = {
        ...founder_groups,
        groups: founder_groups.groups.map(group => ({
          value: group._id,
          label: group.name,
        })),
      };
      let offsetdata = "+05:30";
      if (
        localStorage.getItem("zonelist") &&
        JSON.parse(localStorage.getItem("zonelist")) &&
        JSON.parse(localStorage.getItem("zonelist"))?.length &&
        selectedTimezon
      )
        offsetdata = JSON.parse(localStorage.getItem("zonelist"))?.filter(
          item => item?.alias === selectedTimezon
        )?.[0]?.offset;

      let event_date = moment
        .utc(date.event_date)
        // .local()
        .add(offsetdata)
        .format();
      let event_end_date = moment
        .utc(date.event_end_date)
        .add(offsetdata)
        .format();
      let newValue = [];
      let oldValue = this.state.industrySectors;
      nextProps?.pitchday?.industry_sectors.map(e => {
        const data1 = { sectorName: e };
        newValue.push(data1);
      });
      this.setState({
        id: _id,
        post_category: post_category,
        saas_parent_id: saas_parent_id?._id,
        event_type: event_type,
        title: title.trim(),
        event_slug: event_slug?.trim(),
        event_register_for: event_register_for,
        saasName: saasName,
        // isDisable: is_disable,
        description: description?.trim(),
        post_pitchday_marketing_content:
          post_pitchday_marketing_content?.trim(),
        event_datetime: event_date.slice(0, 16),
        event_end_datetime: event_end_date.slice(0, 16),
        zoom_link: meeting_details?.zoom_link,
        additional_information: meeting_details?.additional_information,
        zoom_meeting_id: meeting_details?.zoom_meeting_id,
        TotalParticipants: enablex_meeting_details?.meeting?.participants,
        industrySectors: newValue,
        selectedTimezon: selectedTimezon ?? "IST",
        event_plateform: event_plateform,
        event_address: event_address,
        investors: invited_investor.length
          ? invited_investor.map(e => ({
            investorName: `${e.investor_info._id}`,
          }))
          : [{ investorName: "" }],
        panellists: invited_panellist?.length
          ? invited_panellist.map(e => ({
            investorName: `${e?.user_id}`,
          }))
          : [{ investorName: "" }],
        founders: invited_founder.length
          ? invited_founder.map(e => ({ founderName: e.founder_info._id }))
          : [{ founderName: "" }],
        images: [{ fileName: "", file: "" }],
        selectedFounders:
          invited_founder.length > 0
            ? invited_founder.map(e => ({
              _id: e?.founder_info?.user_id,
              mobile_number: e?.founder_info?.founder_contact_number,
              mobile_country_code:
                e?.founder_info?.founder_mobile_country_code,
              founder_name: e?.founder_info?.founder_name,
              startup_name: e?.startup?.basic_information?.startup_name,
              firstname: e?.founder_info?.founder_first_name,
              lastname: e?.founder_info?.founder_last_name,
              email: e?.founder_info?.founder_email,
              rsvp: e?.founder_info?.rsvp,
              description_by_admin: e?.founder_info?.description_by_admin,
              showcaseStartup: e?.founder_info?.showcaseStartup || false,
            }))
            : [],
        selectedInvestors:
          invited_investor.length > 0
            ? invited_investor.map(e => ({
              _id: e?.investor_info.user_id,
              firstname: e?.investor_info?.investor_first_name,
              lastname: e?.investor_info?.investor_last_name,
              email: e?.investor_info?.investor_email,
              rsvp: e?.investor_info?.rsvp,
              mobile_number: e?.investor_info?.investor_contact_number,
              mobile_country_code:
                e?.investor_info?.investor_mobile_country_code,
            }))
            : [],
        selectedPanellist:
          invited_panellist && invited_panellist?.length > 0
            ? invited_panellist?.map(e => ({
              _id: e?.user_id,
              firstname: e?.investor_first_name
                ? e?.investor_first_name
                : e?.founder_first_name,
              lastname: e?.investor_last_name
                ? e?.investor_last_name
                : e?.founder_last_name,
              email: e?.investor_email ? e?.investor_email : e?.founder_email,
              rsvp: e?.rsvp,
              mobile_number: e?.investor_contact_number
                ? e?.investor_contact_number
                : e?.founder_contact_number,
              mobile_country_code: e?.investor_mobile_country_code
                ? e?.investor_mobile_country_code
                : e?.founder_mobile_country_code,
            }))
            : [],
        defaultFounder: invited_founder.length
          ? invited_founder.map(e => e.founder_info._id)
          : [],
        defaultInvestor: invited_investor.length
          ? invited_investor.map(e => e.investor_info._id)
          : [],
        defaultPanellist: invited_panellist?.length
          ? invited_panellist.map(e => e?.user_id)
          : [],
        investor_groups,
        founder_groups,
        selectAllInvestors: selectAllInvestors || false,
        selectAllFounders: selectAllFounders || false,
        event_image,
        loginBanner: login_banner,
        smallBanner: small_banner,
        // bigBanner:big_banner,
        fieldType: customFields,
        customFieldCount: customFields?.length,
        pitch_day_marketing_images: [{ fileName: "", file: "" }],
        pitch_day_marketing_image: pitch_day_marketing_images,
        moderators_link: enablex_meeting_details?.meeting?.direct_url_moderator,
        selectedRanges: eventBookedSlotes,
        enableRecordings: enablex_meeting_details?.meeting?.auto_recording,
        disableReminders: disableReminders,
        eventInHours: false,
        eventToday: false,
        eventTommorow: false,
        eventInAWeek:false
      });

      // Invited Investor Changes
      if (selectAllInvestors === true) {
        this.props.onGetPitchInvestors({
          selectedSaasParent: saas_parent_id?._id,
        });
      }

      setTimeout(() => {
        this.generateExcludedTimes("start", new Date(event_date.slice(0, 16)));
      }, 100);
    }
  }

  toggleInv = value => {
    this.setState(prevState => ({
      isInvestors: value,
    }));
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  setFounders = data => {
    const {
      selectedFounders,
      selectedInvestors,
      selectedPanellist,
      defaultFounder,
      defaultInvestor,
      defaultPanellist,
    } = this.state;
    if (this.state.isInvestors === "investor") {
      let dataNew = data;
      dataNew = dataNew.concat(defaultInvestor);
      let set = new Set(dataNew);
      let result = [...set];
      let newdata = result.filter(function (el) {
        return data.indexOf(el) < 0;
      });
      result = result.filter(o1 => !newdata.some(o2 => o1._id === o2._id));

      const changedData = result.map(e => ({
        rsvp: { status: "No RSVP Yet" },
        firstname: `${e.firstname}`,
        lastname: `${e.lastname}`,
        _id: e._id,
        email: e?.email,
        mobile_number: e?.mobile_number,
        mobile_country_code: e?.mobile_country_code,
        startup_name: e?.startup_name,
      }));

      this.setState({
        selectedInvestors: [...changedData],
        defaultFounder: [...defaultInvestor],
      });
    } else if (this.state.isInvestors === "founder") {
      let dataNew = data;
      dataNew = dataNew.concat(defaultFounder);
      let set = new Set(dataNew);
      let result = [...set];
      let newdata = result.filter(function (el) {
        return data.indexOf(el) < 0;
      });
      result = result.filter(o1 => !newdata.some(o2 => o1._id === o2._id));

      const changedData = result.map(e => ({
        _id: e?._id,
        mobile_number: e?.mobile_number,
        mobile_country_code: e?.mobile_country_code,
        founder_name: `${e?.firstname} ${e?.lastname}`,
        startup_name: e?.startup_name,
        firstname: e?.firstname,
        lastname: e?.lastname,
        email: e?.email,
        rsvp: e?.rsvp ? e?.rsvp : { status: "No RSVP Yet" },
        description_by_admin: e?.description_by_admin,
      }));
      this.setState({
        selectedFounders: [...changedData],
        defaultFounder: [...changedData],
      });
      if (
        !this.state.selectAllFounders ||
        this.state.selectAllFounders?.length > 0
      ) {
        this.setState({
          errors: {
            founderSelect: false,
          },
          error: {
            description: "",
          },
        });
      }
    } else if (this.state.isInvestors === "panellist") {
      let dataNew = data;
      dataNew = dataNew.concat(defaultPanellist);
      let set = new Set(dataNew);
      let result = [...set];
      let newdata = result.filter(function (el) {
        return data.indexOf(el) < 0;
      });
      result = result.filter(o1 => !newdata.some(o2 => o1._id === o2._id));

      const changedData = result.map(e => ({
        rsvp: e?.rsvp ? e?.rsvp : { status: "No RSVP Yet" },
        firstname: `${e.firstname}`,
        lastname: `${e.lastname}`,
        _id: e._id,
        email: e?.email,
        mobile_number: e?.mobile_number,
        mobile_country_code: e?.mobile_country_code,
        startup_name: e?.startup_name,
      }));
      this.setState({
        selectedPanellist: [...changedData],
        defaultFounder: [...defaultPanellist],
      });
    }
  };

  eventDateTimeChange = e => {
    this.setState({
      event_datetime: e.currentTarget.value,
    });
  };

  eventEndDateTimeChange = e => {
    this.setState({
      event_end_datetime: e.currentTarget.value,
    });
  };

  handleEventAddressChange = e => {
    this.setState({
      event_address: e.currentTarget.value,
    });
  };

  // Function for change Input Fields for IndustrySector
  handleChangeIndustrySector = (e, index) => {
    const sectors = this.state.industrySectors;
    sectors[index]["sectorName"] = e.currentTarget.value;
    this.setState({
      industrySectors: sectors,
    });
  };

  // Function for Create Input Fields for IndustrySector
  handleAddIndustrySectors = e => {
    var values = this.state.industrySectors;
    values.push({ sectorName: "" });
    this.setState({ industrySectors: values });
  };

  // Function for Remove Input Fields for IndustrySector
  handleRemoveIndustrySectors = index => {
    var values = this.state.industrySectors;
    values.splice(index, 1);
    this.setState({ industrySectors: values });
  };

  // Function for change Input Fields for Investors
  handleChangeInvestor = (e, index) => {
    const investors = this.state.investors;
    investors[index]["investorName"] = e?.value || "";
    this.setState({
      investors,
    });
  };

  // Function for Create Input Investors
  handleAddInvestors = e => {
    var values = this.state.investors;
    values.push({ investorName: "" });
    this.setState({ investors: values });
  };

  // Function for Remove Input Investors
  handleRemoveInvestors = index => {
    var values = this.state.investors;
    values.splice(index, 1);
    this.setState({ investors: values });
  };

  // Function for change Input Fields for Founder
  handleChangeFounder = (e, index) => {
    const founders = this.state.founders;
    founders[index]["founderName"] = e?.value || "";
    this.setState({
      founders,
    });
  };

  // Function for Create Input Founder
  handleAddFounders = e => {
    var values = this.state.founders;
    values.push({ founderName: "" });
    this.setState({ founders: values });
  };

  // Function for Remove Input Founder
  handleRemoveFounders = index => {
    var values = this.state.founders;
    values.splice(index, 1);
    this.setState({ founders: values });
  };

  // Function for change Input Fields for Founder
  handleChangeImage = (e, index) => {
    const images = this.state.images;
    images[index]["fileName"] = e.target.value;
    images[index]["file"] = e.target.files[0];
    this.setState({
      images,
    });
  };

  handleChangePostPitchDayMarketingImage = (e, index) => {
    const pitch_day_marketing_images = this.state.pitch_day_marketing_images;
    pitch_day_marketing_images[index]["fileName"] = e.target.value;
    pitch_day_marketing_images[index]["file"] = e.target.files[0];
    this.setState({
      pitch_day_marketing_images,
    });
  };

  // Function for Create File Image
  handleAddImages = e => {
    var values = this.state.images;
    values.push({ fileName: "", file: "" });
    this.setState({ images: values });
  };

  // Function for Remove File Image
  handleRemoveImages = index => {
    var values = this.state.images;
    values.splice(index, 1);
    this.setState({ images: values });
    this.setState({ event_image: "" });
  };

  handleRemovePostPitchDayMarketingImages = index => {
    var values = this.state.pitch_day_marketing_images;
    values.splice(index, 1);
    this.setState({ pitch_day_marketing_images: values });
  };

  handleChangeVideo = e => {
    this.setState({
      video_link: e.target.value,
    });
  };
  handleTitleChange = e => {
    this.setState({
      title: e.currentTarget.value,
    });
  };

  handleAliasChange = e => {
    this.setState({
      event_slug: e.currentTarget.value,
    });
  };
  handleDescriptionChange = e => {
    this.setState({
      description: e.currentTarget.value,
    });
  };

  handleZoomLinkChange = e => {
    this.setState({
      zoom_link: e.currentTarget.value,
    });
  };
  handleChangeParticipants = e => {
    this.setState({
      TotalParticipants: e.currentTarget.value,
    });
  };
  handleAdditionalInformationChange = e => {
    this.setState({
      additional_information: e.currentTarget.value,
    });
  };

  handleZoomMeetingIdChange = e => {
    this.setState({
      zoom_meeting_id: e.currentTarget.value,
    });
  };

  handleEventType = e => {
    this.setState({
      event_type: e.currentTarget.value,
    });
  };
  handleEventPlateform = e => {
    this.setState({
      event_plateform: e.currentTarget.value,
    });
  };
  handleSaasParentType = async e => {
    await this.setState({
      saas_parent_id: e.value,
    });

    const name = this.state.saasParentList.filter(item => {
      return item.value == this.state.saas_parent_id;
    });
    this.setState({ saasName: name[0]?.saas_name });
    this.props.onGetPitchInvestors({
      selectedSaasParent: this.state.saas_parent_id,
    });
    this.props.onGetPitchPanellist({
      selectedSaasParent: this.state.saas_parent_id,
    });
  };
  handleTimezone = async e => {
    this.setState({
      selectedTimezon: e.value,
    });
  };
  handleChangeDisableEvent = async e => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/pitchday/changeEventDisableStatus`,
      {
        isDisable: e,
        post_id: this.state.id,
      },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      }
    );
    if (response.data.status === true) {
      this.setState({ isDisable: e });
      this.setState({ isDisableMsg: "Event status changed successfully" });
      setTimeout(() => {
        this.setState({ isDisableMsg: "" });
      }, 1000);
    } else {
      this.setState({
        error: {
          event_disable: response?.data?.message,
        },
      });
    }
  };
  handleValidSubmit = async (e, values) => {
    e.preventDefault();
    const { onUpdatePitchDay } = this.props;
    if (this.state.selectAllInvestors === true && !this.props.investorDataLoaded) {
      toast.error("Please wait while Investor Data is being loaded.");
      return false;
    }
    if (this.state.event_plateform === "enablex") {
      this.setState({
        error: {
          description: "Enablex Events can no longer be updated!",
        },
      });
      return false;
    }
    let description = this.state.description.replace(/(<([^>]+)>)/gi, "");
    if (description === "" || description.length < 5) {
      this.setState({
        error: {
          description:
            "Description should be of minimum length of five character",
        },
      });
      return false;
    }

    // let post_pitchday_marketing_content =
    //   this.state.post_pitchday_marketing_content.replace(/(<([^>]+)>)/gi, "");

    if (
      this.state.event_type === "Pitch Day" &&
      this.state.selectedFounders.length == 0
    ) {
      this.setState({
        errors: {
          founderSelect: true,
        },
        error: {
          description: "Please select founders.",
        },
      });
      return false;
    }

    if (Date.parse(this.state.event_datetime) - Date.parse(new Date()) < 0) {
      this.setState({
        error: {
          description: "Event Start Time cannot be in the past.",
        },
      });
      this.setState({ is_saving_event: false });
      return false;
    }

    if (
      Date.parse(this.state.event_end_datetime) -
      Date.parse(this.state.event_datetime) <
      0
    ) {
      this.setState({
        error: {
          description: "Event End Time cannot be before Event Start Time.",
        },
      });
      this.setState({ is_saving_event: false });
      return false;
    }

    let checkValid = await this.checkUniqueAlias(this.state.event_slug);
    if (checkValid && this.state.error.event_slug) {
      return false;
    }

    this.setState({ error: "" });

    let industrySectorValues = "";
    if (this.state.industrySectors.length > 0) {
      const industrySectorValueArr = [];
      this.state.industrySectors.map(item => {
        industrySectorValueArr.push(item.sectorName);
      });
      industrySectorValues = industrySectorValueArr.join(",");
    }

    let investorsValues = "";
    if (this.state.selectAllInvestors) {
      const investorsArr = [];
      this.props.investors.map(investor =>
        investorsArr.push(investor._id.toString())
      );
      investorsValues = investorsArr.join(",");
    } else {
      const investorsArr = [];
      if (this.state.selectedInvestors.length > 0) {
        this.state.selectedInvestors.map(item => {
          investorsArr.push(item._id);
        });
      }
      investorsValues = investorsArr.join(",");
    }
    if (this.state?.selectedPanellist?.length > 9) {
      this.setState({
        error: {
          panellist: "Can not select more then 9 panel list",
        },
      });
      this.setState({ is_saving_event: false });
      return false;
    } else {
      this.setState({
        error: {
          panellist: "",
        },
      });
    }
    let panellistValues = "";
    if (this.state.selectedPanellist.length > 0) {
      const panellistArr = [];
      this.state.selectedPanellist.map(item => {
        panellistArr.push(item?._id);
      });
      panellistValues = panellistArr.join(",");
    }

    const pitch_day_marketing_images_files = [];
    if (this.state.pitch_day_marketing_images.length > 0) {
      this.state.pitch_day_marketing_images.map(item => {
        pitch_day_marketing_images_files.push(item.file);
      });
    }
    let foundersValues = "";
    if (this.state.selectedFounders.length > 0) {
      const foundersValueArr = [];
      this.state.selectedFounders.map(item => {
        foundersValueArr.push(item._id);
      });
      foundersValues = foundersValueArr.join(",");
    }
    const files = [];
    if (this.state.images.length > 0) {
      this.state.images.map(item => {
        files.push(item.file);
      });
    }
    let deleted_event_images = false;
    if (this.state.images?.length === 0) {
      deleted_event_images = true;
    }

    let { investor_groups, founder_groups } = this.state;

    investor_groups = {
      ...investor_groups,
      groups: investor_groups.groups.map(group => group.value),
    };
    founder_groups = {
      ...founder_groups,
      groups: founder_groups.groups.map(group => group.value),
    };

    let localoffset = moment().utcOffset();
    let offsetData = this.state?.timezonelist?.filter(
      item => item?.value === (this.state.selectedTimezon ?? "IST")
    )?.[0]?.offset;
    let ChangedEventDate = moment(this.state.event_datetime)
      .utc()
      .format()
      .trim();
    let ChangedEndEventDate = moment(this.state.event_end_datetime)
      .utc()
      .format()
      .trim();
    if (offsetData) {
      ChangedEventDate = moment(this.state.event_datetime)
        .utc()
        .add(localoffset, "minutes")
        .subtract(offsetData)
        .format()
        .trim();
      ChangedEndEventDate = moment(this.state.event_end_datetime)
        .utc()
        .add(localoffset, "minutes")
        .subtract(offsetData)
        .format()
        .trim();
    }
    if (this.state.event_plateform === "enablex") {
      let currentTimeOf = moment()
        .utc()
        .add(localoffset, "minutes")
        .subtract(offsetData)
        .format()
        .trim();

      const minimumStartTime = new Date(
        new Date(currentTimeOf).getTime() + 45 * 60000
      );
      if (new Date(ChangedEventDate) <= minimumStartTime) {
        this.setState({
          error: {
            description: "Event can only be updated prior 45 minutes of event",
            //  `Event Start Date & Time must be after ${moment(
            //   minimumStartTime
            // ).format("MM/DD/yyyy h:mm A")}.`,
          },
        });
        this.setState({ is_saving_event: false });
        return false;
      }
    }
    let userData = JSON.parse(localStorage.getItem("user_info"));
    const loggedInBySuperAdmin = userData?.is_super_admin || "";
    const adminUserId = userData?.user_id || "";
    const newPitchDay = {
      id: this.state.id,
      post_category: "Events",
      saas_parent_id: this.state.saas_parent_id,
      event_type: this.state.event_type,
      event_plateform: this.state?.event_plateform,
      event_address: this.state?.event_address,
      title: this.state.title,
      event_slug: this.state.event_slug,
      event_register_for: this.state.event_register_for,
      // isDisable: this.state.isDisable,
      post_pitchday_marketing_content:
        this.state.post_pitchday_marketing_content?.trim(),
      description: this.state.description,
      descriptionText: this.state.descriptionText,
      event_datetime: ChangedEventDate,
      event_end_datetime: ChangedEndEventDate,
      zoom_link: this.state.zoom_link?.trim(),
      additional_information: this.state.additional_information?.trim(),
      zoom_meeting_id: this.state.zoom_meeting_id.replace(/\s+/g, "")?.trim(),
      industry_sectors: industrySectorValues?.trim(),
      invited_investor: investorsValues?.trim(),
      invited_founder: foundersValues?.trim(),
      inveted_panellist: panellistValues.trim(),
      images: files,
      pitch_day_marketing_images: pitch_day_marketing_images_files,
      login_banner:
        this.state.login_banner?.fileName !== ""
          ? this.state.login_banner?.file
          : "",
      small_banner: this.state.small_banner?.file,
      // big_banner: this.state.big_banner?.file,

      custom_fields: JSON.stringify(this.state.fieldType),
      investor_groups: JSON.stringify(investor_groups),
      founder_groups: JSON.stringify(founder_groups),
      selectAllInvestors: this.state.selectAllInvestors,
      selectAllFounders: this.state.selectAllFounders,
      video_link: this.state.video_link,
      saasName: this.state.saasName,
      selectedTimezon: this.state.selectedTimezon,
      totalParticipants: this.state.TotalParticipants,
      moderators: this.state.founders?.length,
      deleted_event_images: deleted_event_images,
      // saas_name: saasParentLis.enableRecordings
      enableRecordings: this.state.enableRecordings,
      disableReminders: this.state.disableReminders,
      eventInHours: this.state?.disableReminders?.eventInHours,
      eventToday: this.state?.disableReminders?.eventToday,
      eventTommorow: this.state?.disableReminders?.eventTommorow,
      eventInAWeek:this.state?.disableReminders?.eventInAWeek,
      loggedInBySuperAdmin: loggedInBySuperAdmin,
      adminUserId: adminUserId,
    };

    // save new pitchDay
    onUpdatePitchDay(newPitchDay);
    this.onAlertDismiss();
  };

  checkUniqueAlias = async event_slug => {
    this.setState({ is_saving_event: true });
    this.setState({
      error: {
        event_slug: "",
      },
    });
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/pitchday/checkUniqueSlug`,
      {
        event_slug: event_slug,
        post_id: this.state.id,
      },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      }
    );
    if (response.data.status === true) {
      this.setState({
        error: {
          event_slug: response.data.message,
        },
      });
      this.setState({ is_saving_event: false });
    }
    return true;
  };

  create_slug_from_title = str => {
    let generated_slug = String(this.state.title)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
    this.setState({ event_slug: generated_slug });
    this.checkUniqueAlias(generated_slug);
  };

  onAlertDismiss = () => {
    this.setState({ alert_visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alert_visible: false });
        this.props.onGetPithDay({ _id: this.props.match.params.id });
        // window.location.href = "/event";
      }, 2000);
    });
  };

  //handle copy enablex moderator link
  handleCopyEnablexLink = async link => {
    const unsecuredCopyToClipboard = text => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        // console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };

    const moderatorLink = link;
    if (window?.isSecureContext && navigator?.clipboard) {
      await navigator?.clipboard?.writeText(moderatorLink);
    } else {
      unsecuredCopyToClipboard(moderatorLink);
    }
  };
  eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  handleStartDateChange = date => {
    this.setState(
      { event_datetime: moment(date).format("YYYY-MM-DDTHH:mm") },
      this.generateExcludedTimes("start", date)
    );
  };

  handleEndDateChange = date => {
    this.setState(
      { event_end_datetime: moment(date).format("YYYY-MM-DDTHH:mm") },
      this.generateExcludedTimes("end", date)
    );
  };

  generateExcludedTimes = (type, selectedDate) => {
    const { selectedRanges } = this.state;
    const excludedTimes = [];
    for (const range of selectedRanges) {
      const start = new Date(
        moment
          .utc(range?.date?.event_date, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
      );
      const end = new Date(
        moment
          .utc(range?.date?.event_end_date, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
      );
      const startDate = new Date(moment(start).format("yyyy-MM-DD"));
      const endDate = new Date(moment(end).format("yyyy-MM-DD"));
      let currentDate = new Date(startDate);
      let selected = new Date(selectedDate);
      if (type === "start") {
        currentDate = new Date(startDate);
      } else {
        currentDate = new Date(endDate);
      }
      if (currentDate.getDate() === selected.getDate()) {
        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const startTime =
            date.getTime() === startDate.getTime() ? start : new Date(date);
          const endTime =
            date.getTime() === endDate.getTime() ? end : new Date(date);

          const startHour = startTime.getHours();
          const endHour = endTime.getHours();
          const startMinute = startTime.getMinutes();
          const endMinute = endTime.getMinutes();

          for (let hour = startHour; hour <= endHour; hour++) {
            const minuteStart = hour === startHour ? startMinute : 0;
            const minuteEnd = hour === endHour ? endMinute : 59;

            for (let minute = minuteStart; minute <= minuteEnd; minute += 15) {
              const excludedTime = new Date(date);
              excludedTime.setHours(hour);
              excludedTime.setMinutes(minute);
              excludedTimes.push(excludedTime);
            }
          }
        }
      }
    }
    this.setState({ excludedTimes });
  };

  // handleCustomFieldAddMore = e => {
  //   var values = this.state.fieldType;
  //   counter++;
  //   this.setState({ customFieldCount: counter });
  //   values.push({ field_name: "", field_type: "text" });
  //   this.setState({ fieldType: values });
  // };

  handleCustomFieldAddMore = e => {
    var values = this.state.fieldType;
    if (this.state.customFieldCount <= 4) {
      this.setState({ customFieldCount: this.state.customFieldCount + 1 });
      values.push({ field_name: "", field_type: "text" });
      this.setState({ fieldType: values });
    }
  };
  // Function for Remove Input Fields for Custom Field
  handleRemoveCustomField = index => {
    var values = this.state.fieldType;
    values.splice(index, 1);
    this.setState({ fieldType: values });
    let customFieldCount = this.state.customFieldCount;
    this.setState({ customFieldCount: customFieldCount - 1 });
  };

  handleSelectAllInvestors = async () => {
    this.props.onGetPitchInvestors({
      selectedSaasParent:
        this.state.saas_parent_id,
    });
  }

  setFieldType = (field, value, index, inner_index) => {
    counter++;
    // this.setState({ customFieldCount: counter });
    this.setState({ customFieldIndex: index });
    const tempArr = this.state.fieldType;
    const tempObj = tempArr[index];
    if (field === "option") {
      const tempOption = tempObj["option"];
      tempOption[inner_index] = { [value]: value };
      tempArr[index]["option"] = tempOption;
      this.setState({ fieldType: tempArr });
    } else if (
      field === "field_type" &&
      (value === "radio" ||
        value === "checkbox" ||
        value === "dropdown_selection")
    ) {
      tempObj["option"] = [{ "": "" }, { "": "" }];
      tempObj[field] = value;
      tempArr[index] = tempObj;
      this.setState({ fieldType: tempArr });
    } else {
      tempObj[field] = value;
      tempArr[index] = tempObj;
      this.setState({ fieldType: tempArr });
    }
  };
  render() {
    const { pitchday } = this.props;
    const { video_link, selectedRanges, excludedTimes } = this.state;
    const defaultValues = {
      event_type: this.state?.event_type,
    };
    let saasName = this.state?.saasParentList.filter(b => {
      return b.value == this.state?.saas_parent_id;
    });
    this.state.saasName = saasName[0]?.saas_name?.toUpperCase();

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Update Event | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Event" breadcrumbItem="Update Event" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ToastContainer />
                    {/* <CardTitle className="mb-4">Update Event Day</CardTitle> */}
                    <AvForm
                      className="outer-repeater"
                      onValidSubmit={this.handleValidSubmit}
                      model={defaultValues}
                    >
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Syndicate Parent:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Select
                                placeholder={"Select Syndicate Parent"}
                                id="select_saas_parent1"
                                options={this.state.saasParentList?.sort(
                                  (a, b) => {
                                    if (
                                      a?.label?.toLowerCase() <
                                      b?.label?.toLowerCase()
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.label?.toLowerCase() >
                                      b?.label?.toLowerCase()
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  }
                                )}
                                onChange={this.handleSaasParentType}
                                value={this.state.saasParentList.filter(
                                  item =>
                                    item.value === this.state.saas_parent_id
                                )}
                                errorMessage="Required Field"
                                validate={{ required: { value: true } }}
                                required={!this.state.user?.is_saas_parent}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Event Platform:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                type="select"
                                name="event_platform"
                                value={this.state.event_plateform}
                                onChange={this.handleEventPlateform}
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                }}
                              >
                                {/* <option value="enablex">EnableX</option> */}
                                {(this.state.authUser?.userInfo?.email
                                  .toLowerCase()
                                  .includes(
                                    "@wefoundercircle.com".toLowerCase()
                                  ) ||
                                  this.state.authUser?.userInfo?.email
                                    .toLowerCase()
                                    .includes("@invstt.com".toLowerCase()) ||
                                  this.state.authUser?.userInfo
                                    ?.is_super_admin) && (
                                    <option value="zoom" selected>
                                      Zoom
                                    </option>
                                  )}

                                <option value="offline">Offline</option>
                                <option value="online_others">
                                  Online - Others
                                </option>
                              </AvField>
                            </Col>
                          </FormGroup>
                          {(this.state.event_plateform === "zoom" ||
                            this.state.event_plateform === "online_others") && (
                              <FormGroup className="mb-4" row>
                                <Col lg="3">
                                  <Label
                                    htmlFor="zoom_link"
                                    className="col-form-label"
                                  >
                                    {this.state.event_plateform ===
                                      "online_others"
                                      ? "Meeting Link: "
                                      : "Zoom Link: "}
                                    <span className="text-danger">
                                      {this.state.event_plateform ===
                                        "online_others"
                                        ? ""
                                        : "*"}
                                    </span>
                                  </Label>
                                </Col>
                                <Col lg="9">
                                  <AvField
                                    id="zoom_link"
                                    name="zoom_link"
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      this.state.event_plateform ===
                                        "online_others"
                                        ? "Enter Meeting Link"
                                        : "Enter Zoom Link"
                                    }
                                    required
                                    value={this.state.zoom_link || ""}
                                    onChange={this.handleZoomLinkChange}
                                    errorMessage="Required Field"
                                    validate={{
                                      required: {
                                        value:
                                          this.state.event_plateform ===
                                            "online_others"
                                            ? false
                                            : true,
                                      },
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                            )}
                          {this.state?.event_plateform === "enablex" && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="enablex_link"
                                  className="col-form-label"
                                >
                                  Total Participants:{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  type="select"
                                  name="participants"
                                  value={this.state.TotalParticipants}
                                  onChange={this.handleChangeParticipants}
                                  errorMessage="Required Field"
                                  validate={{
                                    required: { value: true },
                                  }}
                                >
                                  {this.state.TotalParticipantsOptions?.map(
                                    opt => {
                                      return (
                                        <option value={opt?.value}>
                                          {opt?.label}
                                        </option>
                                      );
                                    }
                                  )}
                                </AvField>
                              </Col>
                            </FormGroup>
                          )}
                          {this.state.event_plateform === "offline" && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="event_address"
                                  className="col-form-label"
                                >
                                  Event Address :
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  id="event_address"
                                  name="event_address"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Event Address"
                                  value={this.state.event_address || ""}
                                  onChange={this.handleEventAddressChange}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          {this.state.event_plateform === "zoom" && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="zoom_meeting_id"
                                  className="col-form-label"
                                >
                                  Zoom Meeting Id{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  id="zoom_meeting_id"
                                  name="zoom_meeting_id"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Zoom Meeting Id"
                                  required
                                  value={this.state.zoom_meeting_id || ""}
                                  onChange={this.handleZoomMeetingIdChange}
                                  errorMessage="Required Field"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Event Type:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <select
                                name="event_type"
                                className="form-control"
                                onChange={this.handleEventType}
                              >
                                <option value="">--Select Event Type--</option>
                                <option
                                  value="Pitch Day"
                                  selected={
                                    this.state.event_type === "Pitch Day"
                                  }
                                >
                                  Pitch Day Event
                                </option>
                                <option
                                  value="General Event"
                                  selected={
                                    this.state.event_type === "General Event"
                                  }
                                >
                                  General Event
                                </option>
                              </select>
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Title
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="title"
                                name="title"
                                type="text"
                                className="form-control"
                                placeholder="Enter Event Title"
                                required
                                value={this.state.title || ""}
                                onChange={this.handleTitleChange}
                                onBlur={this.create_slug_from_title}
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label
                                htmlFor="event_slug"
                                className="col-form-label"
                              >
                                Event Alias:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="event_slug"
                                name="event_slug"
                                type="text"
                                className="form-control"
                                // readOnly="true"
                                placeholder="Event Alias"
                                errorMessage="The Event Alias is required."
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[a-z0-9]+(?:-[a-z0-9]+)*$",
                                    errorMessage: "Invalid Event Alias.",
                                  },
                                }}
                                value={this.state.event_slug || ""}
                                onChange={this.handleAliasChange}
                                onBlur={event => {
                                  this.checkUniqueAlias(event.target.value);
                                }}
                              />
                              <small>
                                <div className="text-danger">
                                  {this.state.error?.event_slug}
                                </div>
                              </small>
                              <small>
                                <strong>Note:</strong> (Only small letter and
                                dash(-) are allowed. E.g : sample-event)
                              </small>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                Start Date Time:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              {/* <AvField
                                id="event_datetime"
                                name="event_datetime"
                                type="datetime-local"
                                className="form-control"
                                value={this.state.event_datetime || ""}
                                onChange={this.eventDateTimeChange}
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                }}
                              /> */}
                              <DatePicker
                                selected={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                onChange={this.handleStartDateChange}
                                selectsStart
                                startDate={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                endDate={
                                  this.state.event_end_datetime
                                    ? new Date(this.state.event_end_datetime)
                                    : ""
                                }
                                showTimeSelect
                                placeholderText="mm/dd/yyyy --:-- --"
                                timeFormat="h:mm aa"
                                timeIntervals={15}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                timeCaption="Time"
                                className="form-control"
                                excludeTimes={
                                  this.state.event_plateform === "enablex"
                                    ? excludedTimes
                                    : []
                                }
                                onKeyDown={e => e.preventDefault()}
                              />
                              {this.state.event_plateform === "enablex" && (
                                <small>
                                  <strong>Note:</strong> (The already booked
                                  time slot is displayed as disabled)
                                </small>
                              )}
                              {/* <DatePicker
                                  className="form-control" 
                                  // selected={this.state.event_datetime || ""}
                                  onChange={this.eventDateTimeChange}
                                  timeInputLabel="Time:"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  showTimeInput

                                  /> */}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                End Date Time:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              {/* <AvField
                                id="event_end_datetime"
                                name="event_end_datetime"
                                type="datetime-local"
                                className="form-control"
                                value={this.state.event_end_datetime || ""}
                                onChange={this.eventEndDateTimeChange}
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                }}
                              /> */}
                              <DatePicker
                                keyboard={false}
                                selected={
                                  this.state.event_end_datetime
                                    ? new Date(this.state.event_end_datetime)
                                    : ""
                                }
                                onChange={this.handleEndDateChange}
                                onClick={this.handleEndDateChange}
                                selectsEnd
                                startDate={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                endDate={
                                  this.state.event_end_datetime
                                    ? new Date(this.state.event_end_datetime)
                                    : ""
                                }
                                minDate={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                showTimeSelect
                                placeholderText="mm/dd/yyyy --:-- --"
                                timeFormat="h:mm aa"
                                timeIntervals={15}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                timeCaption="Time"
                                className="form-control"
                                excludeTimes={
                                  this.state.event_plateform === "enablex"
                                    ? excludedTimes
                                    : []
                                }
                                onKeyDown={e => e.preventDefault()}
                              />
                              {this.state.event_plateform === "enablex" && (
                                <small>
                                  <strong>Note:</strong> (The already booked
                                  time slot is displayed as disabled)
                                </small>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Select Timezone:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Select
                                placeholder={"Select Timezone"}
                                id="select_timezon"
                                options={this.state.timezonelist?.sort(
                                  (a, b) => {
                                    if (
                                      a?.alias?.toLowerCase() <
                                      b?.alias?.toLowerCase()
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.alias?.toLowerCase() >
                                      b?.alias?.toLowerCase()
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  }
                                )}
                                onChange={this.handleTimezone}
                                value={this.state.timezonelist?.filter(
                                  item =>
                                    item?.value === this.state.selectedTimezon
                                )}
                                errorMessage="Required Field"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Description's Header:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <textarea
                                id="title1"
                                name="title1"
                                type="text"
                                rows="5"
                                disabled
                                className="form-control"
                                placeholder="Attendees name"
                                value={`Dear [Attendees name], \n\nYou have been invited for [${this.state.title || "Event Title"
                                  }] conducted by [${this.state.saasName || "Syndicate Name"
                                  }] \n\nEvent On: [${this.state.event_datetime
                                    ? moment(this.state.event_datetime).format(
                                      "Do MMMM YYYY, h:mm A"
                                    )
                                    : "Date-Time"
                                  } ${this.state.selectedTimezon || "IST"}]`}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup
                            className=""
                            row
                            style={{ height: "300px" }}
                          >
                            <Col lg="3">
                              <Label className="col-form-label">
                                Description
                              </Label>
                            </Col>
                            <Col lg="9">
                              <ReactQuill
                                id="description"
                                style={{ height: "258px" }}
                                placeholder="Enter Event Description"
                                value={this.state.description || ""}
                                onChange={(content, delta, source, editor) => {
                                  this.setState({ description: content });
                                  this.setState({
                                    descriptionText: editor.getText(),
                                  });
                                }}
                                modules={this.eventDescriptionEditorModules}
                                formats={this.eventDescriptionEditorFormats}
                              />
                              {/* <AvField
                                id="description"
                                name="description"
                                type="textarea"
                                className="form-control"
                                placeholder="Enter Event Description"
                                required
                                value={this.state.description || ""}
                                onChange={this.handleDescriptionChange}
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                }}
                              /> */}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Description's Footer:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <textarea
                                id="title2"
                                name="title2"
                                type="text"
                                disabled
                                className="form-control"
                                placeholder="desc footer"
                                value={`[${this.state.saasName || "Syndicate name"
                                  }] is looking forward to see you! \nTeam Invstt`}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label
                                htmlFor="additional_information"
                                className="col-form-label"
                              >
                                Additional Information
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="additional_information"
                                name="additional_information"
                                type="text"
                                className="form-control"
                                placeholder="Enter Additional Information"
                                value={this.state.additional_information || ""}
                                onChange={
                                  this.handleAdditionalInformationChange
                                }
                              />
                            </Col>
                          </FormGroup>

                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Industry Sectors
                                </Label>
                              </Col>
                              <Col
                                className="inner ms-md-auto"
                                id="repeater_industry_sector"
                              >
                                {this.state.industrySectors.map(
                                  (field, key) => (
                                    <Fragment key={"_field_" + key}>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="11">
                                          <AvField
                                            type="text"
                                            name="sectorName"
                                            className="inner form-control"
                                            placeholder="Enter Industry Sector..."
                                            value={field.sectorName}
                                            onChange={e =>
                                              this.handleChangeIndustrySector(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col md="1">
                                          <div className="mt-2 mt-md-0 d-grid">
                                            <Button
                                              className="inner btn btn-danger"
                                              onClick={() => {
                                                this.handleRemoveIndustrySectors(
                                                  key
                                                );
                                              }}
                                              block
                                            >
                                              <i className="bx bx-trash fs-3"></i>
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                    </Fragment>
                                  )
                                )}
                              </Col>
                            </div>
                            <Row className="justify-content-end">
                              <Col lg="9">
                                <Button
                                  color="success"
                                  className="inner"
                                  onClick={this.handleAddIndustrySectors}
                                >
                                  <i className="bx bx-plus-circle fs-4 m-0"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Add Investors
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row">
                                    <Col md={12}>
                                      <Form.Check
                                        type="switch"
                                        label="Select All Investors"
                                        checked={this.state.selectAllInvestors}
                                        onChange={e => {
                                          this.setState({
                                            selectAllInvestors:
                                              e.target.checked,
                                          });
                                          // Invited Investor Changes
                                          if (e.target.checked === true) {
                                            this.handleSelectAllInvestors()
                                          }
                                        }}
                                      />
                                    </Col>
                                  </div>
                                  {!this.state.selectAllInvestors && (
                                    <div className="mb-3 row">
                                      <Col md={9}>
                                        <ul
                                          style={{
                                            maxHeight: "100px",
                                            overflowX: "auto",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {this.state.selectedInvestors
                                            .length ? (
                                            this.state.selectedInvestors.map(
                                              (e, ind) => (
                                                <li key={ind}>{e.firstname}</li>
                                              )
                                            )
                                          ) : (
                                            <li className="placeHolder">
                                              {"No investors added."}
                                            </li>
                                          )}
                                        </ul>
                                        {/* <Table bordered>
                                        <thead>
                                          <tr>
                                            <th>Investor</th>
                                            <th>RSVP status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.selectedInvestors.map(
                                            (e, ind) => (
                                              <tr key={ind}>
                                                <td>
                                                  {e?.firstname} {e?.lastname}
                                                </td>
                                                <td>{e?.rsvp.status}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table> */}
                                      </Col>
                                      <Col md={3}>
                                        <div className="mt-2 mt-md-0 d-flex">
                                          <Button
                                            color="success"
                                            className="inner mx-1 p-1"
                                            onClick={() => {
                                              this.toggleInv("investor");
                                              this.toggle();
                                            }}
                                          // block
                                          >
                                            <i className="bx bx-plus-circle fs-3 m-0"></i>
                                          </Button>

                                          <Button
                                            color="warning"
                                            onClick={() => {
                                              this.setState({
                                                groupType: "investor",
                                              });
                                              this.handleUserGroupsModal();
                                            }}
                                          >
                                            Edit Group
                                          </Button>
                                        </div>
                                      </Col>
                                    </div>
                                  )}
                                </Fragment>
                              </Col>
                            </div>

                            {/* Investor Groups Row */}
                            <div className="inner justify-content-end form-group mb-0 row">
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row align-items-center">
                                    <Col
                                      md="12"
                                      className="mb-3"
                                      hidden={
                                        !this.state.investor_groups.groups
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.groups
                                          .length ? (
                                          this.state.investor_groups.groups.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No groups selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.investor_groups.countries
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.countries
                                          .length ? (
                                          this.state.investor_groups.countries.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No countries selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.investor_groups.states
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.states
                                          .length ? (
                                          this.state.investor_groups.states.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No states selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.investor_groups.cities
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.cities
                                          .length ? (
                                          this.state.investor_groups.cities.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No cities selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                  </div>
                                </Fragment>
                              </Col>
                            </div>
                          </div>
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Add Founders
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  {/* <div className="mb-3 row">
                                    <Col md={12}>
                                      <Form.Check
                                        type="switch"
                                        label="Select All Founders"
                                        checked={this.state.selectAllFounders}
                                        onChange={e => {
                                          this.setState({
                                            selectAllFounders: e.target.checked,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </div> */}
                                  {/* {!this.state.selectAllFounders && ( */}
                                  <div className="mb-3 row">
                                    <Col md={9}>
                                      <Table bordered>
                                        <thead>
                                          <tr>
                                            <th>Founder</th>
                                            <th>Startup</th>
                                            <th>Description</th>
                                            <th>RSVP status</th>
                                            <th><span >Post Event</span>&nbsp;&nbsp;&nbsp;
                                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">To showcase startup in post-marketing event communications</Tooltip>}>
                                                <span className="d-inline-block font-size-16">
                                                  ⓘ
                                                </span>
                                              </OverlayTrigger>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.selectedFounders.map(
                                            (e, ind) => (
                                              <tr key={ind}>
                                                <td>{e?.founder_name}</td>
                                                <td>{e?.startup_name}</td>
                                                <td>
                                                  {e?.description_by_admin}
                                                </td>
                                                <td>{e?.rsvp?.status}</td>
                                                <td className="text-center">
                                                  <div className="notibtn mt-0">
                                                    {/* <span className="notilab mr-2">OFF</span> */}
                                                    <label
                                                      className="switch"
                                                      htmlFor={`${e?._id}`}
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        id={`${e?._id}`}
                                                        onClick={(event) => {
                                                          event.persist();
                                                          this.handleShowCaseChange(
                                                            {
                                                              eId: this.state.id,
                                                              fUserId: e?._id,
                                                              isShowcase: event.target.checked,
                                                            }
                                                          );
                                                        }}
                                                        defaultChecked={
                                                          e?.showcaseStartup || false
                                                        }
                                                      />
                                                      <div className="slider round"></div>
                                                    </label>
                                                    {/* <span className="notilab ml-2">ON</span> */}
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </Col>
                                    <Col md={3}>
                                      <div className="mt-2 mt-md-0 d-flex">
                                        <Button
                                          color="success"
                                          className="inner mx-1 p-1"
                                          onClick={() => {
                                            this.toggleInv("founder");
                                            this.toggle();
                                          }}
                                          block
                                        >
                                          <i className="bx bx-plus-circle fs-3 m-0"></i>
                                        </Button>

                                        {/* <Button
                                          color="warning"
                                          onClick={() => {
                                            this.setState({
                                              groupType: "founder",
                                            });
                                            this.handleUserGroupsModal();
                                          }}
                                        >
                                          Edit Group
                                        </Button> */}
                                      </div>
                                    </Col>
                                    {this.state?.errors?.founderSelect && (
                                      <p className="text-danger">
                                        Required Field
                                      </p>
                                    )}
                                    {/* <p className="text-warning">
                                      Note: Only founder with live deal are
                                      applicable for invitation of Pitch Day
                                    </p> */}
                                  </div>
                                  {/* )} */}
                                </Fragment>
                              </Col>
                            </div>

                            {/* Founder Groups Row */}
                            <div className="inner justify-content-end form-group mb-0 row">
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row align-items-center">
                                    <Col
                                      md="12"
                                      className="mb-3"
                                      hidden={
                                        !this.state.founder_groups.groups.length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.groups
                                          .length ? (
                                          this.state.founder_groups.groups.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No groups selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.founder_groups.countries
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.countries
                                          .length ? (
                                          this.state.founder_groups.countries.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No countries selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.founder_groups.states.length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.states
                                          .length ? (
                                          this.state.founder_groups.states.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No states selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.founder_groups.cities.length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.cities
                                          .length ? (
                                          this.state.founder_groups.cities.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No cities selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                  </div>
                                </Fragment>
                              </Col>
                            </div>

                            {this.state.event_plateform === "enablex" && (
                              <div className="inner-repeater mb-4">
                                <div className="inner form-group mb-0 row">
                                  <Col lg="3">
                                    <Label className="col-form-label">
                                      Add Panellist:
                                    </Label>
                                  </Col>
                                  <Col
                                    lg={9}
                                    className="inner ms-md-auto"
                                    id="repeater"
                                  >
                                    <Fragment>
                                      <div className="mb-3 row ">
                                        <Col md={9}>
                                          <Table
                                            bordered
                                            className="moderator-table"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Moderator Link</th>
                                                <th>Copy Link</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.selectedPanellist?.map(
                                                (e, ind) => (
                                                  <tr key={ind}>
                                                    <td>
                                                      {(e?.firstname ?? "") +
                                                        " " +
                                                        (e?.lastname ?? "")}
                                                    </td>
                                                    <td>
                                                      <div
                                                        className={
                                                          this.state
                                                            .moderatorlink_see_index ===
                                                            ind
                                                            ? ""
                                                            : "overflow-ellipsis"
                                                        }
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href={`${this.state.moderators_link}?user_ref=${e?.email}`}
                                                        >{`${this.state.moderators_link}?user_ref=${e?.email}`}</a>
                                                      </div>
                                                      {this.state
                                                        .moderatorlink_see_index ===
                                                        ind ? (
                                                        <div
                                                          className="more-text"
                                                          onClick={() =>
                                                            this.setState({
                                                              moderatorlink_see_index:
                                                                null,
                                                            })
                                                          }
                                                        >
                                                          - less
                                                        </div>
                                                      ) : (
                                                        <div
                                                          className="more-text"
                                                          onClick={() =>
                                                            this.setState({
                                                              moderatorlink_see_index:
                                                                ind,
                                                            })
                                                          }
                                                        >
                                                          + more
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td>
                                                      <Link to="#">
                                                        <i
                                                          className="mdi mdi-content-copy font-size-18"
                                                          id="editooltip6"
                                                          title="enablex moderator meeting link"
                                                          onClick={() =>
                                                            this.handleCopyEnablexLink(
                                                              `${this.state.moderators_link}?user_ref=${e?.email}`
                                                            )
                                                          }
                                                          style={{
                                                            color: "#34c38f",
                                                          }}
                                                        ></i>
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                        {/* <Col md="9">
                                        <ul
                                          style={{
                                            maxHeight: "100px",
                                            overflowX: "auto",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {this.state?.selectedPanellist?.length ? (
                                            this.state?.selectedPanellist?.map(
                                              (e, ind) => (
                                                <li key={ind}>{e.firstname}</li>
                                              )
                                            )
                                          ) : (
                                            <li className="placeHolder">
                                              {"No Panelist added."}
                                            </li>
                                          )}
                                        </ul>
                                      </Col> */}
                                        <Col md={3}>
                                          <div className="mt-md-0 d-flex">
                                            <Button
                                              color="success"
                                              className="inner mx-1 p-1"
                                              onClick={() => {
                                                this.toggleInv("panellist");
                                                this.toggle();
                                              }}
                                              block
                                            >
                                              <i className="bx bx-plus-circle fs-3 m-0"></i>
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                    </Fragment>
                                  </Col>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Event Image
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                <Fragment key={"_field_" + 0}>
                                  <div className="mb-3 row align-items-center">
                                    <Col md={11}>
                                      <AvField
                                        name="fileName"
                                        type="file"
                                        className="inner form-control"
                                        accept=".jpeg, .png, .jpg, .gif"
                                        onChange={e =>
                                          this.handleChangeImage(e, 0)
                                        }
                                      />
                                    </Col>
                                    <Col md={1}>
                                      {this.state?.event_image && (
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="danger"
                                            className="inner"
                                            onClick={() => {
                                              this.handleRemoveImages(0);
                                            }}
                                            block
                                          >
                                            <i className="bx bx-trash fs-3"></i>
                                          </Button>
                                        </div>
                                      )}
                                    </Col>
                                    <Col md={11}>[Dimension 621*200 px]</Col>
                                  </div>
                                  {this.state?.event_image && (
                                    <>
                                      <img
                                        alt=""
                                        height={120}
                                        width="auto"
                                        src={this.state?.event_image}
                                        className="img-responsive"
                                      />
                                      <br></br>
                                    </>
                                  )}
                                </Fragment>
                              </Col>
                            </div>

                            {this.state.event_plateform == "enablex" && (
                              <>
                                <div className="inner form-group mb-0 row">
                                  <Col lg="3">
                                    <Label className="col-form-label">
                                      Meeting Login Banner
                                    </Label>
                                  </Col>
                                  <Col
                                    lg={9}
                                    className="inner ms-md-auto"
                                    id="image_repeater"
                                  >
                                    <Fragment>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="11">
                                          <AvField
                                            id="login_banner"
                                            name="login_banner"
                                            // errorMessage="The field is required."
                                            // validate={{ required: { value: true } }}
                                            accept=".jpeg, .png, .jpg, .gif"
                                            type="file"
                                            className="inner form-control"
                                            // value={this.state.login_banner.fileName}
                                            onChange={e => {
                                              const login_banner = [];
                                              login_banner["fileName"] =
                                                e.target.value;
                                              login_banner["file"] =
                                                e.target.files[0];

                                              const scope = this;
                                              var _URL =
                                                window.URL || window.webkitURL;
                                              var file;
                                              if ((file = e.target.files[0])) {
                                                let img = new Image();
                                                img.onload = function () {
                                                  // alert("width : "+this.width + " and height : " + this.height);
                                                  if (
                                                    this.width !== 525 ||
                                                    this.height !== 300
                                                  ) {
                                                    alert(
                                                      "Meeting Login Banner Hight and Width are not matched "
                                                    );
                                                    e.target.value = "";
                                                  } else {
                                                    scope.setState({
                                                      login_banner:
                                                        login_banner,
                                                    });
                                                  }
                                                };
                                                img.src =
                                                  _URL.createObjectURL(file);
                                              }
                                            }}
                                          />
                                        </Col>

                                        <Col md={11}>
                                          [Dimension 525x300 px]
                                        </Col>
                                      </div>
                                    </Fragment>
                                    {this.state?.loginBanner && (
                                      <>
                                        <img
                                          alt=""
                                          height={120}
                                          width={120}
                                          src={this.state?.loginBanner}
                                          className="img-responsive"
                                        />
                                        <br></br>
                                      </>
                                    )}
                                  </Col>
                                </div>

                                <div className="inner form-group mb-0 row">
                                  <Col lg="3">
                                    <Label className="col-form-label">
                                      Meeting Topbar Banner
                                    </Label>
                                  </Col>
                                  <Col
                                    lg={9}
                                    className="inner ms-md-auto"
                                    id="image_repeater"
                                  >
                                    <Fragment>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="11">
                                          <AvField
                                            id="small_banner"
                                            name="small_banner"
                                            // errorMessage="The field is required."
                                            // validate={{ required: { value: true } }}
                                            accept=".jpeg, .png, .jpg, .gif"
                                            type="file"
                                            className="inner form-control"
                                            // value={this.state.small_banner.fileName}
                                            onChange={e => {
                                              const small_banner = [];
                                              small_banner["fileName"] =
                                                e.target.value;
                                              small_banner["file"] =
                                                e.target.files[0];
                                              const scope1 = this;

                                              var _URL =
                                                window.URL || window.webkitURL;
                                              let file;
                                              if ((file = e.target.files[0])) {
                                                let img = new Image();
                                                img.onload = function () {
                                                  // alert("width : "+this.width + " and height : " + this.height);
                                                  if (
                                                    this.width !== 330 ||
                                                    this.height !== 45
                                                  ) {
                                                    alert(
                                                      "Meeting Topbar Banner Hight and Width are not matched "
                                                    );
                                                    e.target.value = null;
                                                  } else {
                                                    scope1.setState({
                                                      small_banner:
                                                        small_banner,
                                                    });
                                                  }
                                                };
                                                img.src =
                                                  _URL.createObjectURL(file);
                                              }
                                            }}
                                          />
                                        </Col>

                                        <Col md={11}>[Dimension 330x45 px]</Col>
                                      </div>
                                    </Fragment>
                                    {this.state?.smallBanner && (
                                      <>
                                        <img
                                          alt=""
                                          height={120}
                                          width={120}
                                          src={this.state?.smallBanner}
                                          className="img-responsive"
                                        />
                                        <br></br>
                                      </>
                                    )}
                                  </Col>
                                </div>

                                {/* <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                Big Banner
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                  <Fragment >
                                    <div className="mb-3 row align-items-center">
                                      <Col md="11">
                                        <AvField
                                          id="big_banner"
                                          name="big_banner"
                                          // errorMessage="The field is required."
                                          // validate={{ required: { value: true } }}
                                          accept=".jpeg, .png, .jpg, .gif"
                                          type="file"
                                          className="inner form-control"
                                          // value={this.state.big_banner.fileName}
                                          onChange={e =>{
                                            const big_banner = [];
                                            big_banner["fileName"] = e.target.value;
                                            big_banner["file"] = e.target.files[0];
                                            const scope2 = this

                                            var _URL = window.URL || window.webkitURL;
                                            let file;
                                            if ((file = e.target.files[0])) {
                                              let img = new Image();
                                              img.onload = function () {
                                                // alert("width : "+this.width + " and height : " + this.height);
                                                if(this.width !== 330 || this.height !== 330){
                                                  alert("Big Banner Hight and Width are not matched")
                                                  e.target.value = '';
                                                  
                                                }else{
                                                  scope2.setState({
                                                      big_banner:big_banner,
                                                    });
                                                }
                                              };
                                              img.src = _URL.createObjectURL(file);
                                            }
                                          }
                                        }


                                        />
                                      </Col>
                                     
                                      <Col md={11}>[Dimension 330x330 px]</Col>
                                    </div>
                                  </Fragment>
                                    {this.state?.bigBanner && (
                                      <>
                                      <img
                                      alt=""
                                      height={120}
                                      width={120}
                                      src={this.state?.bigBanner}
                                      className="img-responsive"
                                    />
                                    <br></br>
                                    </>
                                    )}
                              </Col>
                            </div> */}
                              </>
                            )}

                            {/* <Row className="justify-content-end">
                              <Col lg={9}>
                                <Button
                                  color="success"
                                  className="inner"
                                  onClick={this.handleAddImages}
                                >
                                  <i className="bx bx-plus-circle fs-4 m-0"></i>
                                </Button>
                              </Col>
                            </Row> */}
                          </div>
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Event Video
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row align-items-center">
                                    <Col md={11}>
                                      <AvField
                                        name="video_link"
                                        type="text"
                                        placeholder="Event Video Link"
                                        className="inner form-control"
                                        value={video_link}
                                        onChange={e =>
                                          this.handleChangeVideo(e)
                                        }
                                      />
                                    </Col>
                                  </div>
                                </Fragment>
                              </Col>
                            </div>
                          </div>

                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label
                                  htmlFor="title"
                                  className="col-form-label"
                                >
                                  Event Is For:{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvRadioGroup
                                  name="event_register_for"
                                  onChange={event => {
                                    this.setState({
                                      event_register_for: event.target.value,
                                    });
                                  }}
                                  value={this.state.event_register_for}

                                // label="Radio Buttons!"
                                // required
                                // errorMessage="Pick one!"
                                >
                                  <AvRadio label="Investors" value="investor" />
                                  <AvRadio label="Founders" value="founder" />
                                </AvRadioGroup>
                              </Col>
                            </div>
                          </div>

                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Custom Fields:{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater_industry_sector"
                              >
                                {this.state.fieldType.map((field, key) => (
                                  <Fragment key={"_field_" + key}>
                                    <div className="mb-3 row align-items-center">
                                      <Col md="5">
                                        <AvField
                                          id="field_name"
                                          type="text"
                                          name="field_name"
                                          className="inner form-control"
                                          placeholder="Label Text"
                                          value={field.field_label}
                                          onChange={e =>
                                            this.setFieldType(
                                              "field_name",
                                              e.target.value,
                                              key
                                            )
                                          }
                                        />
                                      </Col>

                                      <Col md="5">
                                        <Select
                                          id="selectedGroups"
                                          name="field_type"
                                          options={this.state.fieldTypeOptions}
                                          // styles={styles}
                                          value={this.state.fieldTypeOptions.filter(
                                            function (option) {
                                              return (
                                                option.value ===
                                                field.field_type
                                              );
                                            }
                                          )}
                                          onChange={e => {
                                            this.setFieldType(
                                              "field_type",
                                              e.value,
                                              key
                                            );
                                          }}
                                        />
                                      </Col>

                                      <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="danger"
                                            title="Remove"
                                            // disabled={
                                            //   this.state.fieldType.length < 2
                                            //     ? true
                                            //     : false
                                            // }
                                            className="inner"
                                            onClick={() => {
                                              this.handleRemoveCustomField(key);
                                            }}
                                            block
                                          >
                                            <i className="bx bx-trash fs-3"></i>
                                          </Button>
                                        </div>
                                      </Col>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      {this.state.fieldType[key]?.field_type ===
                                        "dropdown_selection" ||
                                        this.state.fieldType[key]?.field_type ===
                                        "radio" ||
                                        this.state.fieldType[key]?.field_type ===
                                        "checkbox" ? (
                                        <>
                                          {this.state.fieldType[
                                            key
                                          ]?.option?.map(
                                            (item, inner_index) => {
                                              return (
                                                <Col md={4}>
                                                  <div className="my-form-input d-flex align-items-center">
                                                    <AvField
                                                      id="option"
                                                      type="text"
                                                      name="option"
                                                      className="inner form-control"
                                                      value={
                                                        Object.keys(item)[0]
                                                      }
                                                      onChange={e => {
                                                        this.setFieldType(
                                                          "option",
                                                          e.target.value,
                                                          key,
                                                          inner_index
                                                        );
                                                      }}
                                                      placeholder={
                                                        "Option " +
                                                        (inner_index + 1)
                                                      }
                                                    />
                                                    <i
                                                      class="bx bx-trash fs-3 ms-2"
                                                      onClick={() => {
                                                        const tempArr =
                                                          this.state.fieldType;
                                                        const tempObj =
                                                          tempArr[key];
                                                        if (
                                                          tempObj["option"]
                                                            .length > 2
                                                        ) {
                                                          counter++;
                                                          // setCount(counter);
                                                          tempObj[
                                                            "option"
                                                          ].splice(
                                                            inner_index,
                                                            1
                                                          );
                                                          tempArr[key] =
                                                            tempObj;
                                                          this.setState({
                                                            fieldType: tempArr,
                                                          });
                                                        }
                                                      }}
                                                    ></i>
                                                  </div>
                                                </Col>
                                              );
                                            }
                                          )}
                                          <Col md={4} className="mb-3">
                                            <Button
                                              title="Add More Option"
                                              color="primary"
                                              className="inner p-1"
                                              onClick={() => {
                                                counter++;
                                                this.setState({
                                                  counter: counter,
                                                });
                                                const tempArr =
                                                  this.state.fieldType;
                                                const tempObj = tempArr[key];
                                                tempObj["option"].push({
                                                  "": "",
                                                });
                                                tempArr[key] = tempObj;
                                                this.setState({
                                                  fieldType: tempArr,
                                                });
                                              }}
                                            >
                                              <i className="bx bx-plus-circle fs-4"></i>
                                            </Button>
                                          </Col>
                                        </>
                                      ) : null}
                                    </div>
                                  </Fragment>
                                ))}
                              </Col>
                            </div>
                            <Row className="justify-content-end">
                              <Col lg="9">
                                <Button
                                  title="Add More"
                                  color="success"
                                  className="inner"
                                  onClick={this.handleCustomFieldAddMore}
                                // onClick={() => {
                                //   setNewFieldAddIndex(index + 1);
                                //   let data = [...fieldType];
                                //   data.splice(index + 1, 0, {
                                //     field_type: 'text',
                                //     field_label: '',
                                //   });
                                //   setForm(data);
                                // }}
                                >
                                  <i className="bx bx-plus-circle fs-3"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              {/* <Col lg="3">
                                <Label className="col-form-label">
                                  Is Event Disable
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                style={{ alignSelf: "center" }}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                <Fragment>
                                  <div className="row align-items-center">
                                    <Col md={9}>
                                      <Form.Check
                                        type="switch"
                                        checked={this.state.isDisable}
                                        onChange={e => {
                                          this.handleChangeDisableEvent(
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </Col>
                                  </div>
                                  {!!this.state.isDisableMsg ? (
                                    <Alert color="success" className="mt-2">
                                      {this.state.isDisableMsg}
                                    </Alert>
                                  ) : null}
                                </Fragment>
                              </Col> */}
                              <hr
                                className="mb-4 mt-4"
                                style={{
                                  height: "2px",
                                  border: "none",
                                  color: "#333",
                                  backgroundColor: "#333",
                                }}
                              />
                              <FormGroup
                                className="mb-4"
                                row
                                style={{ height: "300px" }}
                              >
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Post Pitchday Marketing Content:{" "}
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                </Col>
                                <Col lg="9">
                                  <ReactQuill
                                    id="description"
                                    style={{ height: "258px" }}
                                    placeholder="Enter post pitchday marketing content"
                                    value={
                                      this.state
                                        .post_pitchday_marketing_content || ""
                                    }
                                    onChange={content => {
                                      this.setState({
                                        post_pitchday_marketing_content:
                                          content,
                                      });
                                    }}
                                    modules={this.eventDescriptionEditorModules}
                                    formats={this.eventDescriptionEditorFormats}
                                  />
                                </Col>
                              </FormGroup>
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Post Pitch Day Marketing Images:
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="image_repeater"
                                >
                                  {this.state.pitch_day_marketing_images.map(
                                    (field, key) => (
                                      <Fragment key={"_field_" + key}>
                                        <div className="mb-3 row align-items-center">
                                          <Col md={12}>
                                            <AvField
                                              name="fileName"
                                              type="file"
                                              className="inner form-control"
                                              value={field.fileName}
                                              onChange={e =>
                                                this.handleChangePostPitchDayMarketingImage(
                                                  e,
                                                  key
                                                )
                                              }
                                            />
                                          </Col>
                                          {/* <Col md={1}>
                                            <div className="mt-2 mt-md-0 d-grid">
                                              <Button
                                                color="danger"
                                                className="inner"
                                                onClick={() => {
                                                  this.handleRemovePostPitchDayMarketingImages(
                                                    key
                                                  );
                                                }}
                                                block
                                              >
                                                <i className="bx bx-trash fs-3"></i>
                                              </Button>
                                            </div>
                                          </Col> */}
                                          <Col md={11}>
                                            [Dimension 621*200 px]
                                          </Col>
                                        </div>
                                      </Fragment>
                                    )
                                  )}
                                </Col>

                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="image_repeater"
                                >
                                  <img
                                    alt=""
                                    height={200}
                                    width="auto"
                                    src={this.state?.pitch_day_marketing_image}
                                    className="img-responsive"
                                  />
                                </Col>
                              </div>
                            </div>
                          </div>
                          {this.state.event_plateform === "enablex" && (
                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Enable Recording:
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="repeater"
                                >
                                  <AvCheckboxGroup
                                    inline
                                    name="maxCheckedCheckboxList"
                                    validate={{ max: { value: 1 } }}
                                  >
                                    <AvCheckbox
                                      value="true"
                                      checked={this.state.enableRecordings}
                                      name="enableRecordings"
                                      onClick={() => {
                                        this.setState({
                                          enableRecordings:
                                            !this.state.enableRecordings,
                                        });
                                      }}
                                    />
                                  </AvCheckboxGroup>
                                </Col>
                              </div>
                            </div>
                          )}
                          {this.state.event_plateform && (
                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Disable Event Reminders:
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="repeater"
                                >
                                  <AvCheckboxGroup inline name="checkbox-group">
                                  <AvCheckbox
                                      label="7 days"
                                      value="true"
                                      checked={
                                        this.state.disableReminders
                                          ?.eventInAWeek
                                      }
                                      onClick={() => {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          disableReminders: {
                                            ...this.state.disableReminders,
                                            eventInAWeek:
                                              !this.state.disableReminders
                                                ?.eventInAWeek,
                                          },
                                        }));
                                      }}
                                    />
                                    <AvCheckbox
                                      label="48 Hrs"
                                      value="true"
                                      checked={
                                        this.state.disableReminders
                                          ?.eventTommorow
                                      }
                                      onClick={() => {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          disableReminders: {
                                            ...this.state.disableReminders,
                                            eventTommorow:
                                              !this.state.disableReminders
                                                ?.eventTommorow,
                                          },
                                        }));
                                      }}
                                    />
                                    <AvCheckbox
                                      label="24 Hrs"
                                      value="true"
                                      checked={
                                        this.state.disableReminders?.eventToday
                                      }
                                      onClick={() => {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          disableReminders: {
                                            ...this.state.disableReminders,
                                            eventToday:
                                              !this.state.disableReminders
                                                ?.eventToday,
                                          },
                                        }));
                                      }}
                                    />
                                    <AvCheckbox
                                      label="1 Hr"
                                      value="true"
                                      checked={
                                        this.state.disableReminders
                                          ?.eventInHours
                                      }
                                      onClick={() => {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          disableReminders: {
                                            ...this.state.disableReminders,
                                            eventInHours:
                                              !this.state.disableReminders
                                                ?.eventInHours,
                                          },
                                        }));
                                      }}
                                    />
                                  </AvCheckboxGroup>
                                  {/* <AvCheckboxGroup
                                    inline
                                    name="maxCheckedCheckboxList"
                                    validate={{ max: { value: 1 } }}
                                  >
                                    <AvCheckbox
                                      value="true"
                                      checked={this.state.disableReminders}
                                      name="disableReminders"
                                      onClick={() => {
                                        this.setState({
                                          disableReminders:
                                            !this.state.disableReminders,
                                        });
                                      }}
                                    />
                                  </AvCheckboxGroup> */}
                                </Col>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary">
                            Update Event
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                    {this.state?.error?.description && (
                      <Alert color="danger" className="mt-4">
                        {this.state?.error?.description}
                      </Alert>
                    )}
                    {this.state?.error?.panellist && (
                      <Alert color="danger" className="mt-4">
                        {this.state?.error?.panellist}
                      </Alert>
                    )}
                    {!!this.props.error ? (
                      <Alert
                        color="danger"
                        className="mt-4"
                        isOpen={this.state.alert_visible}
                      >
                        {this.props.error}
                      </Alert>
                    ) : null}

                    {!!this.props.updateSuccess ? (
                      <Alert
                        color="success"
                        className="mt-4"
                        isOpen={this.state.alert_visible}
                      >
                        {this.props.updateSuccess}
                      </Alert>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Modal for user group of investors/founders */}
        <UserGroupsModal
          userGroupsModalOpen={this.state.userGroupsModalOpen}
          setUserGroupsModal={this.setUserGroupsModal}
          handleUserGroupsModal={this.handleUserGroupsModal}
          selectedGroupType={
            this.state.groupType === "investor"
              ? this.state.investor_groups
              : this.state.founder_groups
          }
          groupType={this.state.groupType}
          handleGroupsValuesChange={this.handleGroupsValuesChange}
        />

        <Modal
          isOpen={this.state.modal}
          toggle={() => {
            this.toggle();
          }}
          size="xl"
        >
          <ModalHeader toggle={this.toggle} tag="h4">
            {this.state.isInvestors === "investor" ? "Investors list" : ""}
            {this.state.isInvestors === "founder" ? "Founders list" : ""}
            {this.state.isInvestors === "panellist" ? "Panel list" : ""}
          </ModalHeader>
          <FounderList
            toggle={this.toggle}
            getAll={
              this.state.isInvestors === "investor"
                ? this.props.onGetPitchInvestors
                : this.state.isInvestors === "founder"
                  ? this.props.onGetPitchFounders
                  : this.props.onGetPitchPanellist
            }
            // saas_parent_id={this.state.saas_parent_id}
            selectedSaasParent={this.state.saas_parent_id}
            founders={
              this.state.isInvestors === "investor"
                ? this.props.investors
                : this.state.isInvestors === "founder"
                  ? this.props.founders.filter(e => e.status === "Approved")
                  : this.props.panellist
            }
            selectedFounders={
              this.state.isInvestors === "investor"
                ? this.state.selectedInvestors
                : this.state.isInvestors === "founder"
                  ? this.state.selectedFounders
                  : this.state.selectedPanellist
            }
            defaultFounder={this.state.defaultFounder}
            defaultInvestor={this.state.defaultInvestor}
            defaultPanellist={this.state.defaultPanellist}
            isInvestors={this.state.isInvestors}
            setFounders={this.setFounders}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

PitchDaysUpdate.propTypes = {
  className: PropTypes.any,
  onUpdatePitchDay: PropTypes.func,
  error: PropTypes.string,
  updateSuccess: PropTypes.string,
  onSubmit: PropTypes.func,
  onGetPitchFounders: PropTypes.func,
  onGetPitchInvestors: PropTypes.func,
  founders: PropTypes.array,
  investors: PropTypes.array,
  onGetPitchPanellist: PropTypes.func,
  panellist: PropTypes.array,
  match: PropTypes.object,
  pitchday: PropTypes.object,
  onGetPithDay: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    error: state.pitchdays.updateError,
    updateSuccess: state.pitchdays.updateSuccess,
    founders: state.pitchdays.founders,
    investors: state.pitchdays.investors,
    pitchday: state.pitchdays.pitchday,
    panellist: state.pitchdays.panellist,
    investorDataLoaded: state.pitchdays.investorDataLoaded,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdatePitchDay: pitchday => dispatch(updatePitchDay(pitchday)),
  onGetPitchFounders: payload => dispatch(getPitchFounders(payload)),
  onGetPitchInvestors: payload => dispatch(getPitchInvestors(payload)),
  onGetPithDay: pitchday => dispatch(getPitchDayDetail(pitchday)),
  onGetPitchPanellist: payload => dispatch(getPitchPanellist(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PitchDaysUpdate));
