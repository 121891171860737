import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";
import { getUserProfile } from "store/actions";
import { isEmpty } from "lodash";
import { Spinner } from "reactstrap";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleWiseAccess: {},
    };

    this.refDiv = React.createRef();
  }

  async localStorageRoleid(authUser, user_id) {
    let roleId = "";
    let response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/user/getdetail/${user_id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        authUser.userInfo.roleId = res?.data?.user?.roleId;
        localStorage.setItem("authUser", JSON.stringify(authUser));
        roleId = res?.data?.user?.roleId;
        return roleId;
      });

    return response;
  }

  async componentDidMount() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    const userInfo = authUser?.userInfo;

    let roleId = userInfo?.roleId;
    const user_id = userInfo?.user_id;

    if (roleId == null || roleId == "") {
      roleId = await this.localStorageRoleid(authUser, user_id);
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        this.setState({ roleWiseAccess: res.data.roleWiseAccess });
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    this.initMenu();
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    const access = this.state.roleWiseAccess;

    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/* <li className="menu-title">Menu</li> */}
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-task" />
                  <span>Dashboard</span>
                </Link>
              </li>

              <li
                className={
                  !isEmpty(access) &&
                  (access?.startup_list ||
                    access?.startup_kpi ||
                    access?.applied_funding_list ||
                    access?.investment_reports)
                    ? ""
                    : "d-none"
                }
              >
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dollar-circle" />
                  <span>Startups</span>
                </Link>
                {!isEmpty(access) ? (
                  <ul className="sub-menu">
                    {access?.startup_list ? (
                      <li>
                        <Link to="/startup-list">Startup List</Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {access?.startup_kpi ? (
                      <li>
                        <Link to="/startup-kpi">Startup KPIs</Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {access?.applied_funding_list ? (
                      <li>
                        <Link to="/applied-funding-list">Startup Rounds</Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* <li>
                    <Link to="/call-for-money">Call for Money List</Link>
                  </li> */}
                    {/* 
                        {access?.investment_reports ? (
                          <li>
                            <Link to="/investment-reports">Investment Reports</Link>
                          </li>
                        ) : (
                          ""
                        )} */}
                  </ul>
                ) : (
                  <ul className="sub-menu">
                    <li style={{ textAlign: "center" }}>
                      <span style={{ color: "gray" }}>
                        {" "}
                        <Spinner animation="border" size="sm" />
                      </span>
                    </li>
                  </ul>
                )}
              </li>

              <li
                className={
                  !isEmpty(access) &&
                  (access?.users_list ||
                    access?.user_groups ||
                    access?.partners_list ||
                    access?.user_credits ||
                    access?.investor_communication_access ||
                    JSON.parse(localStorage.getItem("authUser"))?.userInfo
                      ?.is_super_admin)
                    ? ""
                    : "d-none"
                }
              >
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user" />
                  <span>Users</span>
                </Link>

                {!isEmpty(access) ? (
                  <ul className="sub-menu">
                    {access?.users_list ? (
                      <li>
                        <Link to="/users-list">Users List</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.user_groups ? (
                      <li>
                        <Link to="/user-groups">User Groups</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* {access?.user_groups ? (
                      <li>
                        <Link to="/saas-wise-investors">
                          SaaS wise investors
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {/* {access?.partners_list ? (
                      <li>
                        <Link to="/partners-list">Partners</Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {access?.kyc_list ? (
                      <>
                        <li>
                          <Link to="/onboarding-list">Onboarding List</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {access?.demat_list ? (
                      <>
                        <li>
                          <Link to="/demat-list">Demat List</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {access?.user_credits ? (
                      <li>
                        <Link to="/user-credits">Credits</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.investor_communication_access ? (
                      <li>
                        <Link to="/investor-communications">
                          Investor Communication
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {JSON.parse(localStorage.getItem("authUser"))?.userInfo
                      ?.is_super_admin ? (
                      <li>
                        <Link to="/deletion-requests">
                          Account Deletion Requests
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : (
                  <ul className="sub-menu">
                    <li style={{ textAlign: "center" }}>
                      <span style={{ color: "gray" }}>
                        {" "}
                        <Spinner animation="border" size="sm" />
                      </span>
                    </li>
                  </ul>
                )}
              </li>

              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>Founders</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/founders-list">Founders List</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>Investors</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/investor-list">Investors List</Link>
                  </li>

                  <li>
                    <Link to="/investor-req-list">Investors Request List</Link>
                  </li>
                </ul>
              </li> */}

              <li
                className={
                  !isEmpty(access) &&
                  (access?.user_report ||
                    access?.investment_reports ||
                    access?.payout_report ||
                    access?.event ||
                    access?.create_event_report_list)
                    ? ""
                    : "d-none"
                }
              >
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-report" />
                  <span>Report</span>
                </Link>
                {!isEmpty(access) ? (
                  <ul className="sub-menu">
                    {access?.user_report ? (
                      <li>
                        <Link to="/user-report">Investment User Wise</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.investment_reports ? (
                      <li>
                        <Link to="/investment-reports">
                          Investment Startup Wise
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.payout_report ? (
                      <li>
                        <Link to="/payout-report">Partners Payout</Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : (
                  <ul className="sub-menu">
                    <li style={{ textAlign: "center" }}>
                      <span style={{ color: "gray" }}>
                        {" "}
                        <Spinner animation="border" size="sm" />
                      </span>
                    </li>
                  </ul>
                )}
              </li>

              {/* Implementation completed */}

              <li
                className={
                  !isEmpty(access) &&
                  (access?.communication_letter ||
                    access?.zoom ||
                    access?.demo_day ||
                    access?.currency_conversion ||
                    access?.upload_media ||
                    access?.job_approval ||
                    access?.job_edit)
                    ? ""
                    : "d-none"
                }
              >
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>Resources</span>
                </Link>
                {!isEmpty(access) ? (
                  <ul className="sub-menu">
                    {/* {(access?.mentoships && access?.is_super_admin) ? (
                      <li>
                        <Link to="/mentoships">Mentorship List</Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {access?.communication_letter ? (
                      <li>
                        <Link to="/weekly-brew">Weekly Brew</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.zoom ? (
                      <li>
                        <Link to="/zoom">Generate Zoom Token</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.demo_day ? (
                      <li>
                        <Link to="/demo-day">Demo Day</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.currency_conversion ? (
                      <li>
                        <Link to="/currency-conversion">
                          Currency Conversion Matrix
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.upload_media ? (
                      <li>
                        <Link to="/upload-invstt-media">Upload Media</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.job_approval || access?.job_edit ? (
                      <li>
                        <Link to="/approve-invstt-jobs">Approve Jobs</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.event ? (
                      <li>
                        <Link to="/event">Event</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* {access?.create_event_report_list ? (
                      <li>
                        <Link to="/create-event-report-list">Event Report</Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {/* <li>
                    <Link to="/document-rules">Document Rules</Link>
                  </li> */}
                    {/* {access?.message ? (
                      <li>
                        <Link to="/message">Messages</Link>
                      </li>
                    ) : (
                      ""
                    )} */}
                    {/* {access?.is_super_admin ? (
                      <li>
                        <Link to="/daily-deduct-credits">
                          Daily deduct credits
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}
                  </ul>
                ) : (
                  <ul className="sub-menu">
                    <li style={{ textAlign: "center" }}>
                      <span style={{ color: "gray" }}>
                        {" "}
                        <Spinner animation="border" size="sm" />
                      </span>
                    </li>
                  </ul>
                )}
              </li>

              {/* <li>
                <Link to="/general-master" className="has-arrow">
                  <i className="bx bxs-user-detail" /><span>General Master</span>
                </Link>
              </li> */}
              {/* //get subscription_list */}
              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>Subscription</span>
                </Link>
                {
                  !isEmpty(access) ?
                    access?.subscription_list ? (
                      <ul className="sub-menu">
                        {access?.subscription_list ? (
                          <li>
                            <Link to="/subscription-list">Subscription List</Link>
                          </li>
                        ) : (
                          ""
                        )}
                        {/* <li>
                    <Link to="/users-report">Users Report</Link>
                  </li> */}
              {/* </ul>
                    ) : (
                      <ul className="sub-menu">
                        <li style={{ textAlign: "center" }}>
                          <span style={{ color: "gray" }}>No Access</span>
                        </li>
                      </ul>
                    ) : <ul className="sub-menu">
                      <li style={{ textAlign: "center" }}>
                        <span style={{ color: "gray" }}> <Spinner animation="border" size="sm" /></span>
                      </li>
                    </ul>}

              </li> */}
              {/* {
                access?.general_master ?
                  <li>
                    <Link to="/general-master">
                      <i className="bx bxs-data" /><span>General Master</span>
                    </Link>
                  </li> : ''
              } */}
              {/* {((JSON.parse(localStorage.getItem("authUser"))?.userInfo
                ?.user_id ===
                JSON.parse(localStorage.getItem("authUser"))?.userInfo
                  ?.saas_parent_id)) ? ( */}

              <li
                className={
                  JSON.parse(localStorage.getItem("authUser"))?.userInfo
                    ?.is_super_admin == true
                    ? ""
                    : "d-none"
                }
              >
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-wrench" />
                  <span>Access Settings</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/admin-settings">Admin Settings</Link>
                  </li>

                  <li>
                    <Link to="/frontend-settings">Frontend Settings</Link>
                  </li>
                </ul>
              </li>

              <li
                className={
                  !isEmpty(access) &&
                  (access?.general_posts ||
                    access?.user_posts ||
                    access?.report_posts)
                    ? ""
                    : "d-none"
                }
              >
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-note" />
                  <span>Posts</span>
                </Link>
                {!isEmpty(access) ? (
                  <ul className="sub-menu">
                    {access?.general_posts ? (
                      <li>
                        <Link to="/general-posts">Admin Posts list</Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {access?.user_posts ? (
                      <li>
                        <Link to="/user-posts">User Posts list</Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {access?.report_posts ? (
                      <li>
                        <Link to="/report-posts">Report Posts </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : (
                  <ul className="sub-menu">
                    <li style={{ textAlign: "center" }}>
                      <span style={{ color: "gray" }}>
                        {" "}
                        <Spinner animation="border" size="sm" />
                      </span>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
