import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Label,
} from "recharts";
import { Col, Row } from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { useMediaQuery } from "react-responsive";

const COLORS = [
  "#84b7d9",
  "#fd9d9a",
  "#e34143",
  "#f18e2e",
  "#8cd17e",
  "#a988a3",
];

const InvestorFunds = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [investorFundData, setInvestorFundData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState({
    value: null,
    label: "Till Date",
  });
  const [monthOptions, setMonthOptions] = useState([]);

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard/investorFunds`,
        {
          params: {
            saas_parent_id: authUser?.userInfo?.saas_parent_id,
            show_all_saas_data,
            is_super_admin: authUser?.userInfo?.is_super_admin,
            queryMonth: selectedMonth?.value,
          },
          headers: {
            Authorization: authUser?.token,
          },
        }
      )
      .then(response => {
        const responseData = response?.data?.data;
        let tempData = [];
        responseData?.findFundsDetails?.map(data => {
          tempData.push({
            value: data?.totalInvestors,
            name: data?.fund_type,
          });
        });
        setInvestorFundData(tempData);
        const monthData = responseData?.resultMonths?.map(data => ({
          value: data?.period,
          label: "In " + data?.period,
        }));

        monthData.unshift({ label: "Till Date", value: null });
        setMonthOptions(monthData);
      })
      .catch(err => {});

    return () => {};
  }, [selectedMonth]);

  const handleChangeMonth = selectedOption => {
    setSelectedMonth(selectedOption);
  };

  const CustomLabel = ({ viewBox, labelText, value }) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fontSize="15"
          fontWeight={600}
        >
          {new Intl.NumberFormat("en").format(value)}
        </text>
        <text
          x={cx}
          y={cy + 17}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fontSize="11"
        >
          {labelText}
        </text>
      </g>
    );
  };

  const getColorForName = name => {
    const colorMap = {
      "Invstt Trust Fund": "#84b7d9",
      "WFC Global Angels Fund": "#fd9d9a",
      "AngelList India": "#e34143",
    };

    return (
      colorMap[name] || COLORS[Object.keys(colorMap).length % COLORS.length]
    );
  };

  return (
    <div className="column-sec">
      <Row className="mb-2 d-flex align-items-center">
        <Col md={8}>
          <h2 className="mb-4 mt-3 title-sm">Onboarded Investors on Funds</h2>
        </Col>
        <Col md={4}>
          <Select
            className="custom-select form-control-sm"
            id="period"
            name="period"
            options={monthOptions}
            value={selectedMonth}
            onChange={handleChangeMonth}
          />
        </Col>
      </Row>
      <Row className="mb-2 d-flex align-items-center">
        <Col>
          <div className="mt-4 mb-4">
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={investorFundData}
                  innerRadius="45%"
                  outerRadius="90%"
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {investorFundData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColorForName(entry.name)}
                    />
                  ))}
                  <Label
                    content={
                      <CustomLabel
                        labelText="Total Investors"
                        value={investorFundData.reduce(
                          (sum, entry) => sum + entry.value,
                          0
                        )}
                      />
                    }
                    position="center"
                  />
                </Pie>
                <Tooltip
                  formatter={value => new Intl.NumberFormat("en").format(value)}
                />
                <Legend
                  align={isTabletOrMobile ? "center" : "right"}
                  layout={isTabletOrMobile ? "horizontal" : "vertical"}
                  verticalAlign={isTabletOrMobile ? "bottom" : "center"}
                  payload={investorFundData.map((item, index) => ({
                    id: item.fund_type,
                    type: "square",
                    value: `${item.name} : ${new Intl.NumberFormat("en").format(
                      item?.value
                    )}`,
                    color: getColorForName(item.name),
                  }))}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InvestorFunds;
