import React, { useCallback, useEffect, useRef } from "react";

import Select, { createFilter } from "react-select";
import { VariableSizeList as List } from "react-window";

import { useWindowResize } from "pages/Users/Partners/useWindowResize";

const InvestorListQA = ({
  allInvestors = [],
  mappedInvestors = [],
  setMappedInvestors,
  ...props
}) => {
  const listRef = useRef();
  const sizeMap = useRef({});

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index, true);
  }, []);

  const getSize = index => sizeMap.current[index] || 35;

  const [windowWidth] = useWindowResize();

  const OptionRow = ({ children, index, setSize, windowWidth }) => {
    const rowRef = useRef();

    useEffect(() => {
      setSize(index, rowRef.current?.getBoundingClientRect()?.height);

      return () => {};
    }, [setSize, index, windowWidth]);

    return <div ref={rowRef}>{children[index]}</div>;
  };

  return (
    <>
      <Select
        placeholder={`Select User`}
        isClearable
        isMulti
        filterOption={createFilter({ ignoreAccents: false })}
        options={allInvestors}
        value={allInvestors?.filter(
          investor =>
            investor?.value && mappedInvestors?.includes(investor?.value)
        )}
        onChange={values => {
          setMappedInvestors(values);
        }}
        components={{
          MenuList: props => {
            const { options, children, maxHeight } = props;

            return (
              <List
                ref={listRef}
                height={maxHeight}
                width="100%"
                itemCount={children.length}
                itemSize={getSize}
                itemData={options}
              >
                {({ index, style }) => {
                  return (
                    <div style={style}>
                      <OptionRow
                        children={children}
                        index={index}
                        setSize={setSize}
                        windowWidth={windowWidth}
                      />
                    </div>
                  );
                }}
              </List>
            );
          },
        }}
      />
    </>
  );
};

export default InvestorListQA;
