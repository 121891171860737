import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { displayImage } from "../../helpers/storage_helper";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";

import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { isEmpty } from "lodash";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";

// Quill Editor for Event Description
import ReactQuill from "react-quill";

//Import Breadcrumb

function WeeklyBrewLogsCount() {
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [brewNotificationDate, setBrewNotificationDate] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [weeklyBrewLogCount, setWeeklyBrewLogCount] = useState({});
  const [brewdate, setBrewdate] = useState("");
  const [post, setPost] = useState({
    datetime: "01-01-1971",
  });

  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;

  function fetchData() {
    const queryParameters = new URLSearchParams(window.location.search);
    let brew_date = queryParameters.get("brew_date");
    let datetime = queryParameters.get("datetime");
    setBrewNotificationDate(brew_date);

    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/weeklyBrew/getBrewLogCount?brew_date=${brew_date}&datetime=${datetime}`,
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then(
        ({ data }) => {
          console.log("data.result", data.result);
          setIsLoaded(true);
          setWeeklyBrewLogCount(data.result);
          setBrewdate(data.datetime);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Posts | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          <Row className="mb-3">
            <Col sm="6">
              <h5 className="text-uppercase">Weekly Brew Email Logs Count</h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              {console.log(
                "weeklyBrewLogCount?.totalOpen",
                weeklyBrewLogCount?.totalSend
              )}

              <Link
                to={
                  "/weekly-brew/view-logs?event=Send&brew_date=" +
                  brewNotificationDate +
                  "&datetime=" +
                  brewdate
                }
                style={
                  weeklyBrewLogCount?.totalSend === 0 ||
                  weeklyBrewLogCount?.totalSend === undefined
                    ? { pointerEvents: "none" }
                    : null
                }
              >
                <div className="dash-column text-center color4">
                  <span className="dash-head">
                    {weeklyBrewLogCount?.totalSend || 0}
                  </span>
                  <span className="dash-subhead">Total Send</span>
                </div>
              </Link>
            </Col>

            <Col md="3">
              <Link
                to={
                  "/weekly-brew/view-logs?event=Open&brew_date=" +
                  brewNotificationDate
                }
                style={
                  weeklyBrewLogCount?.totalOpen === 0 ||
                  weeklyBrewLogCount?.totalOpen === undefined
                    ? { pointerEvents: "none" }
                    : null
                }
              >
                <div className="dash-column text-center color2">
                  <span className="dash-head">
                    {weeklyBrewLogCount?.totalOpen || 0}
                  </span>
                  <span className="dash-subhead">
                    Total Open
                    {/* Onboarding Info Received */}
                  </span>
                </div>
              </Link>
            </Col>

            <Col md="3">
              <Link
                to={
                  "/weekly-brew/view-logs?event=Click&brew_date=" +
                  brewNotificationDate
                }
                style={
                  weeklyBrewLogCount?.eventType_click === 0 ||
                  weeklyBrewLogCount?.eventType_click === undefined
                    ? { pointerEvents: "none" }
                    : null
                }
              >
                <div className="dash-column text-center color3">
                  <span className="dash-head">
                    {weeklyBrewLogCount?.eventType_click || 0}
                  </span>
                  <span className="dash-subhead">
                    Total Click
                    {/* Onboarding Info Received */}
                  </span>
                </div>
              </Link>
            </Col>

            <Col md="3">
              <Link
                to={
                  "/weekly-brew/view-logs?event=Bounce&brew_date=" +
                  brewNotificationDate
                }
                style={
                  weeklyBrewLogCount?.eventType_bounce === 0 ||
                  weeklyBrewLogCount?.eventType_bounce === undefined
                    ? { pointerEvents: "none" }
                    : null
                }
              >
                <div className="dash-column text-center ">
                  <span className="dash-head">
                    {weeklyBrewLogCount?.eventType_bounce || 0}
                  </span>
                  <span className="dash-subhead">Total Bounce</span>
                </div>
              </Link>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="3">
              <Link
                to={
                  "/weekly-brew/view-logs?event=Complaint&brew_date=" +
                  brewNotificationDate
                }
                style={
                  weeklyBrewLogCount?.eventType_complaint === 0 ||
                  weeklyBrewLogCount?.eventType_complaint === undefined
                    ? { pointerEvents: "none" }
                    : null
                }
              >
                <div className="dash-column text-center color1">
                  <span className="dash-head">
                    {weeklyBrewLogCount?.eventType_complaint || 0}
                  </span>
                  <span className="dash-subhead">
                    Total Complaint
                    {/* Onboarding Info Clear */}
                  </span>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
WeeklyBrewLogsCount.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default WeeklyBrewLogsCount;
