import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/index";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import authHeader from "helpers/jwt-token-access/auth-token-header";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout 
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };

  diff_minutes(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    // Convert the difference from seconds to minutes
    diff /= 60;
    //console.log(diff);
    // Return the absolute value of the rounded difference in minutes
    return Math.abs(Math.round(diff));
  }

  setClickedChecker = () => {
    if (!window?.location?.origin?.toLowerCase()?.includes("invstt.com")) {
      return;
    }

    let date1 = JSON.parse(localStorage.getItem("firstactivitytime"));
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      if (date1) {
        date1 = new Date(date1);
        let date2 = new Date();
        let diffminutes = this.diff_minutes(date1, date2);
        //console.log(diffminutes);
        if (diffminutes > 10) {
          localStorage.clear();
          window.location.href = "/";
        } else {
          localStorage.setItem("firstactivitytime", JSON.stringify(new Date()));
        }
      } else {
        localStorage.setItem("firstactivitytime", JSON.stringify(new Date()));
      }
    }
  };

  componentDidMount() {
    window.addEventListener("mousedown", this.setClickedChecker);
    window.addEventListener("mousemove", this.setClickedChecker);
    window.addEventListener("mouseup", this.setClickedChecker);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.setClickedChecker);
    window.removeEventListener("mousemove", this.setClickedChecker);
    window.removeEventListener("mouseup", this.setClickedChecker);
  }

  render() {
    const Layout = this.getLayout();

    const logoutUserWithOldToken = () => {
      const { jwtDecode } = require("jwt-decode");

      try {
        let ogToken = authHeader();

        if (!ogToken) {
          localStorage.clear();
          // window.location.href = "/";
          return <></>;
        }

        let decodedToken = jwtDecode(ogToken);

        if (!["key", "frontend"].includes(decodedToken?.tokenGeneratedFrom)) {
          localStorage.clear();
          // window.location.href = "/";
          return <></>;
        }
      } catch (err) {
        localStorage.clear();
        // window.location.href = "/";
        return <></>;
      }

      return <></>;
    };

    return (
      <React.Fragment>
        {logoutUserWithOldToken()}
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
