import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Table,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { isEqual } from "lodash";
import { getPostEventDetail } from "store/actions";
import { updateEventReportMediaURL } from "helpers/backend_helper";

class MediaDetails extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      post: {},
      oldMediaLink: null,
      newMediaLink: null,
      mediaUpdateModal: false,
      updateLoading: false,
      errorMessage:
        "** Use YouTube Long-URL Only. For Example https://www.youtube.com/watch?xxxxxxxxxxxxxx",
    };

    this.toggleMediaUpdateModal = this.toggleMediaUpdateModal.bind(this);
    this.handleEditMediaUpdateButtonClick =
      this.handleEditMediaUpdateButtonClick.bind(this);
    this.handleEditMediaLinkSubmit = this.handleEditMediaLinkSubmit.bind(this);
  }

  toggleMediaUpdateModal() {
    this.setState(prevState => ({
      mediaUpdateModal: !prevState.mediaUpdateModal,
      // errorMessage: null,
      updateLoading: false,
    }));
  }

  handleEditMediaUpdateButtonClick(oldMediaLink, newMediaLink) {
    this.setState({
      oldMediaLink: oldMediaLink,
      newMediaLink: newMediaLink,
    });
    this.toggleMediaUpdateModal();
  }

  async handleEditMediaLinkSubmit(e) {
    e.preventDefault();
    this.setState({
      updateLoading: true,
    });
    try {
      if (!this.state?.newMediaLink) {
        this.setState({
          // errorMessage: "Please enter new media url link",
          updateLoading: false,
        });
        return;
      }

      if (
        !this.state.newMediaLink?.startsWith("https://www.youtube.com/watch?")
      ) {
        this.setState({
          // errorMessage: "Please enter a valid youtube video link",
          updateLoading: false,
        });
        return;
      }

      let reqBody = {
        postId: this.state?.post?._id,
        oldMediaLink: this.state.oldMediaLink,
        newMediaLink: this.state.newMediaLink,
      };

      let response = await updateEventReportMediaURL(reqBody);

      if (!response?.status) {
        let errorMessage =
          response?.message || "Error while updating media url";
        alert(errorMessage);
        this.setState({
          // errorMessage,
          updateLoading: false,
        });
        return;
      }

      await this.props?.onGetPostEventDetail({
        postId: this.state?.post?._id,
        showAll: true,
      });

      this.setState({
        // errorMessage: null,
        oldMediaLink: null,
        newMediaLink: null,
        updateLoading: false,
      });

      this.toggleMediaUpdateModal();
    } catch (error) {
      let errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating media url";
      alert(errorMessage);
      this.setState({
        // errorMessage,
        updateLoading: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.postDetails, nextProps.postDetails)) {
      this.setState({ post: nextProps.postDetails });
    }
  }

  render() {
    const { post } = this.state;

    let recording =
      post &&
      post?.media_content &&
      post?.media_content.find(
        mediaContentVal =>
          mediaContentVal?.media_type[0] === "recordings" &&
          mediaContentVal?.content?.body
      );

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row className="my-5">
              <Col lg="12">
                <Card>
                  <Row>
                    <Col lg={12} className="border rounded p-0">
                      {recording?.content?.body?.recording_files.length > 0 && (
                        <Table>
                          <tbody>
                            {recording?.content?.body?.recording_files.length >
                              0 &&
                              recording?.content?.body?.recording_files.map(
                                (recordingFilesVal, index) => {
                                  if (recordingFilesVal?.file_type === "MP4") {
                                    return (
                                      <>
                                        <tr>
                                          Video - {recordingFilesVal?.file_type}
                                        </tr>

                                        {!!recordingFilesVal?.play_url && (
                                          <>
                                            <br />
                                            <tr>
                                              <td>Event Fetched URL:</td>

                                              <td>
                                                <a
                                                  href={
                                                    recordingFilesVal?.play_url
                                                  }
                                                  target="_blank"
                                                  className="text-break"
                                                >
                                                  {recordingFilesVal?.play_url}
                                                </a>
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Moderated URL:</td>

                                              <td>
                                                {this.state.mediaUpdateModal ? (
                                                  <>
                                                    <Col md={6} sm={12}>
                                                      <Input
                                                        type="text"
                                                        value={
                                                          this.state
                                                            .newMediaLink
                                                        }
                                                        onChange={e => {
                                                          this.setState({
                                                            newMediaLink:
                                                              e.target.value,
                                                          });
                                                        }}
                                                      />

                                                      <Label className="text-info mt-1">
                                                        {
                                                          this.state
                                                            .errorMessage
                                                        }
                                                      </Label>

                                                      <div className="mt-2 d-flex gap-2 align-items-center">
                                                        <Button
                                                          className="btn btn-sm btn-success d-flex gap-2 align-items-center"
                                                          onClick={
                                                            this
                                                              .handleEditMediaLinkSubmit
                                                          }
                                                          disabled={
                                                            this.state
                                                              .updateLoading
                                                          }
                                                        >
                                                          {!!this.state
                                                            .updateLoading && (
                                                            <>
                                                              <Spinner
                                                                animation="border"
                                                                size="sm"
                                                                color="white"
                                                              />
                                                            </>
                                                          )}
                                                          Save
                                                        </Button>

                                                        <Button
                                                          className="btn btn-sm btn-danger"
                                                          onClick={
                                                            this
                                                              .toggleMediaUpdateModal
                                                          }
                                                          disabled={
                                                            this.state
                                                              .updateLoading
                                                          }
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </div>
                                                    </Col>
                                                  </>
                                                ) : (
                                                  <>
                                                    {!!recordingFilesVal?.new_media_link && (
                                                      <>
                                                        <a
                                                          href={
                                                            recordingFilesVal?.new_media_link
                                                          }
                                                          target="_blank"
                                                          className="me-4 text-info text-break"
                                                        >
                                                          {
                                                            recordingFilesVal?.new_media_link
                                                          }
                                                        </a>
                                                      </>
                                                    )}

                                                    <Link
                                                      className="text-success"
                                                      to="#"
                                                      title="Edit URL"
                                                    >
                                                      <i
                                                        className="mdi mdi-pencil font-size-18"
                                                        id="edittooltip"
                                                        onClick={() => {
                                                          this.handleEditMediaUpdateButtonClick(
                                                            recordingFilesVal?.play_url,
                                                            recordingFilesVal?.new_media_link
                                                          );
                                                        }}
                                                      ></i>
                                                    </Link>
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    );
                                  } else if (
                                    recordingFilesVal?.file_type === "M4A"
                                  ) {
                                    return (
                                      <>
                                        <tr>
                                          Audio - {recordingFilesVal?.file_type}
                                        </tr>

                                        {!!recordingFilesVal?.play_url && (
                                          <>
                                            <tr>
                                              <td>Event Fetched URL:</td>

                                              <td>
                                                <a
                                                  href={
                                                    recordingFilesVal?.play_url
                                                  }
                                                  target="_blank"
                                                  className="text-break"
                                                >
                                                  {recordingFilesVal?.play_url}
                                                </a>

                                                {!!recordingFilesVal?.download_url && (
                                                  <>
                                                    <a
                                                      className="text-success text-break"
                                                      href={
                                                        recordingFilesVal?.download_url
                                                      }
                                                      target="_blank"
                                                      title="Download File"
                                                    >
                                                      <i
                                                        className="ms-4 bx bx-import font-size-18"
                                                        id="downloadtooltip"
                                                      ></i>
                                                    </a>
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    );
                                  } else if (
                                    recordingFilesVal?.file_type === "CHAT"
                                  ) {
                                    return (
                                      <>
                                        <tr>
                                          Conversation -{" "}
                                          {recordingFilesVal?.file_type}
                                        </tr>

                                        {!!recordingFilesVal?.play_url && (
                                          <>
                                            <tr>
                                              <td>Event Fetched URL:</td>

                                              <td>
                                                <a
                                                  href={
                                                    recordingFilesVal?.play_url
                                                  }
                                                  target="_blank"
                                                  className="text-break"
                                                >
                                                  {recordingFilesVal?.play_url}
                                                </a>

                                                {!!recordingFilesVal?.download_url && (
                                                  <>
                                                    <a
                                                      className="text-success text-break"
                                                      href={
                                                        recordingFilesVal?.download_url
                                                      }
                                                      target="_blank"
                                                      title="Download File"
                                                    >
                                                      <i
                                                        className="ms-4 bx bx-import font-size-18"
                                                        id="downloadtooltip"
                                                      ></i>
                                                    </a>
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                }
                              )}
                          </tbody>
                        </Table>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

MediaDetails.propTypes = {
  match: PropTypes.object,
  postDetails: PropTypes.object,
  onGetPostEventDetail: PropTypes.func,
  error: PropTypes.string,
};

const mapStateToProps = ({ pitchdays }) => ({
  error: pitchdays.updateRoleError,
});

const mapDispatchToProps = dispatch => ({
  onGetPostEventDetail: payload => dispatch(getPostEventDetail(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MediaDetails));
