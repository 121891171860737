import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { displayImage } from "../../helpers/storage_helper";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./post.css";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { isEmpty } from "lodash";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";

// Quill Editor for Event Description
import ReactQuill from "react-quill";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

//Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// you will also need the css that comes with bootstrap-daterangepicker
// import "bootstrap-daterangepicker/daterangepicker.css";

function GeneralPosts() {
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: false,
  };
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [posts, setPosts] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [post, setPost] = useState({});

  const [access, setAccess] = useState({});

  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;
  const show_all_saas_data = JSON.parse(
    localStorage.getItem("roleWiseAccess")
  )?.show_all_saas_data;

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  function fetchData(
    query = `?is_super_admin=${is_super_admin}&saas_parent_id=${saas_parent_id}&show_all_saas_data=${show_all_saas_data}`
  ) {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/post/general/getall${query}`, {
        headers: { Authorization: `${token}` },
      })
      .then(
        ({ data }) => {
          setIsLoaded(true);
          setPosts(data.posts);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }
  useEffect(() => {
    fetchData();
    // console.log("-----post---",post);
    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
    return () => {};
  }, []);

  const handleRangeFilter = (start, end) => {
    const filteredResult = posts.filter(item => {
      const createdAt = moment(new Date(item.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return item;
      } else {
        return;
      }
    });
    setPosts(filteredResult);
  };
  const createPost = async post => {
    setModal(false);
    let bodyFormData = new FormData();
    let compressedFile;
    const options = {
      maxSizeMB: 0.8,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      compressedFile = await imageCompression(selectedFile, options);
    } catch (error) {
      // console.log(error);
    }
    let data = {
      title: post.title,
      description: post.description,
      active: post.active,
      post_category: "General",
      selectAllInvestors: post.selectAllInvestors ? post.selectAllInvestors : false,
    }

    bodyFormData.append("image", compressedFile);
    let encryptedQuery = encryptDataToArray(data)
    bodyFormData.append("q", JSON.stringify(encryptedQuery));
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL + "/api/post/general/create"}`,
      data: bodyFormData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const togglePost = post => {
    let bodyFormData = new FormData();
    let data = {
      title: post.title,
      description: post.description,
      active: !post.active,
      post_category: "General",
    }
    // bodyFormData.append("title", post.title);
    // bodyFormData.append("description", post.description);
    // bodyFormData.append("active", !post.active);
    // bodyFormData.append("post_category", "General");
    let encryptedQuery = encryptDataToArray(data)
    bodyFormData.append("q", JSON.stringify(encryptedQuery));
    setModal(false);
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/post/general/update/${post._id}`,
      data: bodyFormData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const updatePost = async post => {
    let compressedFile;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      compressedFile = await imageCompression(selectedFile, options);
    } catch (error) {
      //  console.log(error);
    }
    let bodyFormData = new FormData();
    let data = {
      title: post.title,
      description: post.description,
      active: post.active,
      post_category: "General",
    }
    // bodyFormData.append("title", post.title);
    // bodyFormData.append("description", post.description);
    // bodyFormData.append("active", post.active);
    // bodyFormData.append("post_category", "General");
    bodyFormData.append("image", compressedFile);
    let encryptedQuery = encryptDataToArray(data)
    bodyFormData.append("q", JSON.stringify(encryptedQuery));
    setModal(false);
    await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/post/general/update/${post._id}`,
      data: bodyFormData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const deletePost = async id => {
    await Swal.fire({
      title: "Do you want to delete this Post ?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (id !== undefined) {
          await axios({
            url:
              process.env.REACT_APP_API_URL + `/api/post/general/delete/${id}`,
            method: "DELETE",
            headers: {
              Authorization: `${token}`,
            },
          })
            .then(res => {
              fetchData();
            })
            .catch(err => {
              setError(err);
            });
        }
      }
    });
  };
  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "title",
      text: "Title",
      filter: textFilter(),
    },
    {
      dataField: "creadted_by_user_name",
      text: "User Name",
      filter: textFilter(),
    },
    {
      dataField: "saas_detail.saas_name",
      text: "Domain Expert name",
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter(),
      formatter: (cellContent, post) => (
        <div
          className="react-quill-preview"
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: (post?.description?.length > 50 ? post?.description?.slice(0, 50) + "..." : post?.description),
          }}
        ></div>
      ),
    },
    access?.adminPostStatus
      ? {
          dataField: "active",
          text: "Status",
          formatter: (cellContent, post) => (
            <>
              <p>
                <label
                  className="toggleSwitch large"
                  // onClick={() => togglePost(post)}
                >
                  <input
                    onChange={() => togglePost(post)}
                    type="checkbox" defaultChecked={post.active}
                  />
                  <span>
                    <span></span>
                    <span></span>
                  </span>
                  <a></a>
                </label>
              </p>
            </>
          ),
        }
      : "",
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.updatedAt)).format("DD MMM Y")}</>
      ),
    },
    access?.adminPostEditAction
      ? {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, post) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    if (is_super_admin === true) {
                      setPost(post);
                      setModal(true);
                      setIsEdit(true);
                    } else {
                      setPost(post);
                      setModal(false);
                      setIsEdit(false);
                    }
                  }}
                ></i>
              </Link>
              {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deletePost(post._id)}
            ></i>
          </Link> */}
            </div>
          ),
        }
      : "",
  ];

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  // if(Object.keys(post).length===0){
  //    //active status true on add
  //    setPost({active:true })
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Posts | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="General Posts" breadcrumbItem="Posts List" /> */}
          <Row className="d-flex align-items-center">
            <Col sm="6">
              <h5 className="text-uppercase">Admin Posts List</h5>
            </Col>
            <Col sm="6">
              <div className="text-sm-end">
                {access?.createAdminPost ? (
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={() => {
                      setPost({});
                      setModal(true);
                    }}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New Post
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={posts || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={posts || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={posts}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) ? (
                          <p className="text-danger">Some error occured!</p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Modal
                            size="lg"
                            isOpen={modal}
                            className="mb-3"
                            toggle={() => setModal(false)}
                          >
                            <ModalHeader
                              toggle={() => setModal(false)}
                              tag="h4"
                            >
                              {!!isEdit ? "Edit Post" : "Add Post"}
                            </ModalHeader>
                            <ModalBody>
                              <AvForm
                                onValidSubmit={() => {
                                  isEdit ? updatePost(post) : createPost(post);
                                }}
                                model={
                                  post === undefined ? defaultValues : post
                                }
                              >
                                <Row form>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <AvField
                                        name="Title"
                                        label="Title"
                                        type="text"
                                        errorMessage="Invalid title"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            title: event.target.value,
                                          });
                                        }}
                                        value={post.title || ""}
                                      />
                                    </div>
                                    <div className="mb-5 pb-3">
                                      <ReactQuill
                                        id="description"
                                        style={{ height: "258px" }}
                                        placeholder="Enter Event Description"
                                        defaultValue={post.description || ""}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            description: event,
                                          });
                                        }}
                                        modules={eventDescriptionEditorModules}
                                        formats={eventDescriptionEditorFormats}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <AvField
                                        name="Thumbnail"
                                        label="Thumbnail"
                                        type="file"
                                        className="inner form-control"
                                        onChange={onSelectFile}
                                        errorMessage="Thumbnail Required"
                                        validate={{
                                          required: { value: false },
                                        }}
                                      />
                                    </div>
                                    <div className="mb-3 row">
                                      <Col md={12}>
                                        <Form.Check
                                          type="switch"
                                          label="Select All Investors"
                                          checked={
                                            post.selectAllInvestors || false
                                          }
                                          onChange={e => {
                                            setPost({
                                              ...post,
                                              selectAllInvestors:
                                                e.target.checked,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </div>
                                    {isEdit &&
                                      post?.media_content[0]?.media_type[0] ===
                                        "image" && (
                                        <Row>
                                          <Col sm={12} className="pb-3">
                                            <a
                                              className=""
                                              href={
                                                post?.media_content[0]?.media
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={displayImage(
                                                  post?.media_content[0]?.media
                                                )}
                                                className="img img-fluid img-thumbnail"
                                                style={{
                                                  objectFit: "scale-down",
                                                  height: "300px",
                                                  width: "100%",
                                                }}
                                              />
                                            </a>
                                          </Col>
                                        </Row>
                                      )}

                                    {/* <div className="mb-3">
                                      <label>Status</label>
                                      <AvRadioGroup
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            active: event.target.checked,
                                          });
                                        }}
                                        name="active"
                                        required
                                      >
                                        <AvRadio
                                          label="Active"
                                          value={true}
                                          name="active-group"

                                        />
                                        <AvRadio
                                          label="Inactive"
                                          value={false}
                                          name="active-group"

                                        />
                                      </AvRadioGroup>
                                    </div> */}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </ModalBody>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
GeneralPosts.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default GeneralPosts;
