import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { toCommaFormat } from "../../helpers/backend_helper";
import Select from "react-select";
import {
  Button,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  Label,
  Col,
  Row,
  ModalFooter,
  Card,
  Table,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import * as moment from "moment";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkDeviceType,
  getLocationString,
  getDeviceDetails,
} from "../../helpers/logs_helper";

const { Country } = require("country-state-city");

import {
  getPostEventList,
  storeOtherDetails,
} from "../../store/pitchdays/actions";
import { getAssessmentReport } from "../../store/appliedFunding/actions";

import { isEmpty, size } from "lodash";
import axios from "axios";

import authHeader from "../../../src/helpers/jwt-token-access/auth-token-header";
import { CSVDownload } from "react-csv";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { getPastLogsData } from "../../helpers/backend_helper";
import { Spinner } from "reactstrap";
import { CountryMobileCode } from "components/Common/CityStateCountryComponent";
import { checkIntentCurrency, exchangeCurrencyRates } from "helpers/common";
import { amountNumberToTextFrmt } from "helpers/utils";
import DataFetchLoader from "components/Common/DataFetchLoader";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

function formatUpdatedForDistinctRecords(data) {
  let obj = {};
  let formattedData = Object.keys(data)?.forEach(key => {
    if (
      typeof data[key] !== "object" &&
      data[key] ===
        ("amount_invested" ||
          "service_fees" ||
          "transaction_date" ||
          "on_behalf_of")
    ) {
      if (key !== "investment_vehicle") {
        obj[key] = {
          newValue: data[key],
          oldValue: null,
        };
      }
    } else {
      obj[key] = data[key];
    }
  });
  return obj;
}

function SwitchField(field) {
  switch (field) {
    case "DealCommittedAmount":
      return "Deal Committed Amount";
    default:
      return field;
  }
}

function formatAmountValue(field, value, intentCurrency) {
  if (field === "Committed Amount") return `₹${value}`;

  if (field === "Consent Amount") return `₹${value}`;
  if (field === "Consent Amount Global") return `$${value}`;
  if (field === "dealCommittedAmount") return `$${value}`;

  return value;
}

function amountToComma(amount, locale) {
  try {
    let value = parseInt(amount).toLocaleString(locale || "en-IN");
    return value;
  } catch (err) {
    // console.log(err)
    return "";
  }
}

function formatAmount(amount, currency) {
  let formattedAmount = "--";
  if (amount) {
    if (typeof amount === "number" || typeof amount === "string") {
      let symbol = currency === "INR" ? "₹" : "US$";
      formattedAmount = `${symbol}${toCommaFormat(amount)}`;
    }
  } else if (amount === 0) {
    if (typeof amount === "number" || typeof amount === "string") {
      let symbol = currency === "INR" ? "₹" : "US$";
      formattedAmount = `${symbol}${toCommaFormat(amount)}`;
    }
  }

  return formattedAmount;
}

function formatLabel(label) {
  if (typeof item === "undefined") return "--";

  if (typeof item === "number") return item;

  if (typeof item === "string") {
    let capitalizedValue = item
      .split("_")
      ?.map(item => item.charAt(0).toUpperCase() + item.substring(1))
      ?.join(" ");
  }

  return capitalizedValue;
}

function formatValue(value, label) {
  if (label === "Committed Amount") return `₹${value}`;

  if (label === "consent_amount") return `₹${value}`;

  if (label === "dealCommittedAmount") return `$${value}`;

  return value;
}

function transformObject(obj) {
  return obj;
}

const LabelSwitch = label => {
  switch (label) {
    case "areYouIntrested":
      return "Intent";
    case "transaction_date":
      return `Transaction Date`;
    // case "referenceName":
    //   return "Reference Name";
    case "committed_amount":
      return `Committed Amount`;
    case "on_behalf_of":
      return `On Behalf Of`;
    case "investment_vehicle":
      return "Investment Vehicle";
    case "investment_type":
      return "Investment Type";
    case "service_fees":
      return `Service Fees`;
    case "amount_invested":
      return `Amount Invested`;
    // case "comments":
    //   return "Comment";
    case "currencyConversionRate":
      return "Conversion Rate (INR to USD)";
    case "createdAt":
    case "updatedAt":
      return "Updated At";
    case "poll_details":
    case "deal_angel_account_manager":
    case "currency":
    case "userCurrency":
    case "dealCurrency":
      return "";
    default:
      return label;
  }
};
const IntentOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Maybe", value: "Maybe" },
];
const selectKycStatus = [
  {
    value: "Intent Shared, KYC Initiated",
    label: "Intent Shared, KYC Initiated",
    fmode: "isoi",
  },
  {
    value: "KYC Complete, Approval Pending",
    label: "KYC Complete, Approval Pending",
    fmode: "ocap",
  },
  { value: "KYC Approved", label: "KYC Approved", fmode: "oa" },

  {
    value: "KYC Disapproved",
    label: "KYC Disapproved",
    fmode: "oreject",
  },
  {
    value: "KYC Process Dropped",
    label: "KYC Process Dropped",
    fmode: "ocancelled",
  },
  {
    value: "Contribution Agreement Shared",
    label: "Contribution Agreement Shared",
    fmode: "ca_shared",
  },
  // {
  //   value: "Contribution Agreement Not Shared",
  //   label: "Contribution Agreement Not Shared",
  //   fmode: "ca_not_shared",
  // },
  {
    value: "Contribution Agreement Signed by Investor, Pending by Trustee",
    label: "Contribution Agreement Signed by Investor, Pending by Trustee",
    fmode: "ca_sbi_pbt",
  },
  {
    value: "Contribution Agreement Signed by All - Process Completed",
    label: "Contribution Agreement Signed by All - Process Completed",
    fmode: "ca_sba_pc",
  },
  {
    value: "Contribution Agreement Dropped",
    label: "Contribution Agreement Dropped",
    fmode: "ca_cancelled",
  },
];
const statusfilter = [
  {
    value: "Sent",
    label: "Sent",
  },
  {
    value: "Received",
    label: "Received",
  },
  {
    value: "Awaited",
    label: "Awaited",
  },
  {
    value: "Dropped",
    label: "Dropped",
  },
];
const INR_cur = {
  label: "Indian Rupee",
  value: "INR",
  symbol: "₹",
  locale: "en-IN",
};
const USD_cur = {
  label: "United States Dollar",
  value: "USD",
  symbol: "US$",
  locale: "en-US",
};
const COUNTRY_CODES = Country.getAllCountries()?.map(item => {
  if (item?.phonecode?.charAt(0) !== "+") {
    item.phonecode = "+" + item?.phonecode;
    return {
      label: item?.name + " " + item?.phonecode,
      value: item?.phonecode,
    };
  } else {
    return {
      label: item?.name + " " + item?.phonecode,
      value: item?.phonecode,
    };
  }
});

function sortDataDesc(data) {
  let updatedData = data.map(item => {
    switch (item?.field) {
      case "Committed_amount":
        return {
          ...item,
          newValue: `₹${toCommaFormat(item?.newValue)}`,
          oldValue: `₹${toCommaFormat(item?.oldValue)}`,
        };
        break;
      case "DealCommittedAmount":
        return {
          ...item,
          newValue: `$${toCommaFormat(item?.newValue)}`,
          oldValue: `$${toCommaFormat(item?.oldValue)}`,
        };
        break;
      case "Consent_amount":
        return {
          ...item,
          newValue: `₹${toCommaFormat(item?.newValue)}`,
          oldValue: `₹${toCommaFormat(item?.oldValue)}`,
        };
        break;
      default:
        return item;
        break;
    }
  });
  let sortedData = updatedData.sort((a, b) =>
    moment(b.createdAt).diff(a.createdAt)
  );
  return sortedData || [];
}

// TO DETECT USER BROWSER; p.s. i don't know how it works, it just does ;)
navigator.browserDetection = (function () {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
})();

class AssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      geolocationData: {},
      demoCsvExportData: [],
      importLoading: false,
      exportLoading: false,
      exportLoadingConsentDrop:false,
      exportInterestedLoading: false,
      intentUpdateButton: false,
      assessmentReport: {},
      postEvents: [],
      modalUpdate: false,
      intent: "",
      intentType: "individual",
      check_intenttype: "",
      applicant_pan_number: "",
      passport_number: "",
      check_applicant_pan_number: "",
      userRolles: JSON.parse(localStorage.getItem("roleWiseAccess")),
      authUser: JSON.parse(localStorage.getItem("authUser")),
      committedAmount: "",
      tax_return_filling_countries: [],
      dealCommittedAmount: "",
      dollarConsentAmount: "",
      // reference: "",
      dealManager: "",
      relation: "",
      // comment: "",
      investorId: "",
      assessmentReportId: "",
      res_error: "",
      csvFile: {},
      btnDisable: false,
      country_code: "",
      check_country_code: "",
      check_contact_number: "",
      contact_number: "",
      hideSendConsentBtn: "",
      modalConsentAmountUpdate: false,
      modalDrawDownDrop: false,
      consentUpdateButton: false,
      drawdownDropButton: false,
      consentUpdateErrors: {},
      drawdownDropErrors: {},
      consent_update_userid: "",
      consent_update_email: "",
      drawdown_drop_userid: "",
      drawdown_drop_email: "",
      consent_old_amount: "",
      consent_amount: "",
      termsAccept: "",
      consent_drop_status: "no",
      notify: "Yes",
      notify_drawdown: "Yes",
      notify_consent: "Yes",
      drawdown_drop_status: "",
      drawdown_consent_drop_status: "no",
      finalizeAmount: "",
      user_currency: {},
      intentCurrency: {},
      intentUpdateErrors: "",
      selectedApplicant: [],
      logsModalOpen: false,
      logsModalData: {},
      logsDateFormat: "DD-MMM-YY hh:mm:ss A",
      investorKYCOptions: [],
    };

    this.toggleUpdateModel = this.toggleUpdateModel.bind(this);
    this.setLogsModal = this.setLogsModal.bind(this);
    this.toggleLogsModal = this.toggleLogsModal.bind(this);
  }
  filterBySent = (filterVal, data) => {
    if (filterVal) {
      return data.filter(row => {
        let value = [];
        let status = "-";
        if (
          row?.kyc_status ==
            "Contribution Agreement Signed by All - Process Completed" &&
          parseInt(row?.consent_amount) > 0 &&
          row?.term_sheet_status == "received_from_sebi" &&
          (!row?.consent_drop_status || row?.consent_drop_status == "no")
        ) {
          if (
            row?.consent_send == "yes" &&
            row?.terms_accept &&
            !row?.contribution_limit_exceeded
          ) {
            status = "Received";
            value.push(status);
            value.push("Sent");
          } else if (row?.consent_send == "yes" && !row?.terms_accept) {
            status = "Awaited";
            value.push(status);
            value.push("Sent");
          }
        } else if (
          row?.kyc_status ==
            "Contribution Agreement Signed by All - Process Completed" &&
          row?.term_sheet_status == "received_from_sebi"
        ) {
          if (row?.consent_drop_status == "yes") {
            status = "Dropped";
            value.push(status);
            value.push("Sent");
          }
        }

        value = value.toString();
        if (value.toLowerCase().includes(filterVal.toLowerCase())) {
          return true;
        }
      });
    }
    return data;
  };
  filterBySentDradown = (filterVal, data) => {
    if (filterVal) {
      return data.filter(row => {
        let value = [];
        let status = "-";
        value.push(status);
        if (
          row?.kyc_status ==
            "Contribution Agreement Signed by All - Process Completed" &&
          parseInt(row?.consent_amount) > 0 &&
          !row?.contribution_limit_exceeded
        ) {
          if (
            row?.drawdown_status == "Drawdown Sent" &&
            row?.consent_send == "yes" &&
            row?.terms_accept &&
            (!row?.consent_drop_status || row?.consent_drop_status == "no") &&
            parseInt(row?.consent_amount) > 0 &&
            (!row?.drawdown_drop_status || row?.drawdown_drop_status == "no")
          ) {
            if (row?.pastinvestment?.past_investment?.amount_invested > 0) {
              status = "Received";
              value.push(status);
              value.push("Sent");
            } else {
              status = "Awaited";
              value.push(status);
              value.push("Sent");
            }
          } else if (
            row?.drawdown_status == "Drawdown Sent" &&
            row?.drawdown_drop_status == "yes"
          ) {
            status = "Dropped";
            value.push(status);
            value.push("Sent");
          }
        }
        value = value.toString();

        if (value.toLowerCase().includes(filterVal.toLowerCase())) {
          return true;
        }
      });
    }
    return data;
  };
  filterByPrice = (filterVal, data) => {
    if (filterVal) {
      return data.filter(user => {
        let value = [];
        let investment_manager_name =
          user?.investment_manager_detail?.firstname;
        if (
          investment_manager_name &&
          user?.investment_manager_detail?.lastname
        ) {
          investment_manager_name =
            investment_manager_name +
            " " +
            user?.investment_manager_detail?.lastname;
        }

        if (investment_manager_name) {
          value.push(investment_manager_name);
        }

        let syndicate_manager_name = user?.syndicate_manager_detail?.firstname;

        if (
          syndicate_manager_name &&
          user?.syndicate_manager_detail?.lastname
        ) {
          syndicate_manager_name =
            syndicate_manager_name +
            " " +
            user?.syndicate_manager_detail?.lastname;
        }
        if (syndicate_manager_name) {
          value.push(syndicate_manager_name);
        }
        value = value.toString();
        if (value.toLowerCase().includes(filterVal.toLowerCase())) {
          return true;
        }

        //   let investment_manager_fullname = user?.investment_manager_detail?.firstname;
        //   let syndicate_manager_fullname = user?.investment_manager_detail?.firstname;
        //   (
        //   user?.investment_manager_detail?.firstname?.toLowerCase().includes(filterVal.toLowerCase()) || user?.investment_manager_detail?.lastname?.toLowerCase().includes(filterVal.toLowerCase()) || user?.syndicate_manager_detail?.firstname?.toLowerCase().includes(filterVal.toLowerCase()) || user?.syndicate_manager_detail?.lastname?.toLowerCase().includes(filterVal.toLowerCase())
        // )
      });
    }
    return data;
  };
  setSelectedRowValue = value => {
    let intent_cur =
      this?.props?.fundDetails?.fund_type?.currency_details || INR_cur;
    //   checkIntentCurrency(
    //   value?.user_currency,
    //   this?.props?.startupInfo?.[0]?.currency,
    //   value?.tax_return_filling_countries
    // );
    let dollar_amt =
      value?.consent_amount_global === ""
        ? value?.dealCommittedAmount
        : value?.consent_amount_global;
    let consent_amt =
      value?.consent_amount === ""
        ? value?.committed_amount
        : value?.consent_amount;
    this.setState({
      dealManager: value?.deal_manager_id,
      intent: value?.areYouIntrested,
      contribution_limit_exceeded: value?.contribution_limit_exceeded,
      payment_received_status: value?.payment_received_status,
      terms_accept: value?.terms_accept,
      committedAmount: value?.committed_amount,
      tax_return_filling_countries: value?.tax_return_filling_countries ?? [],
      intentCurrency: intent_cur,
      user_currency: value?.user_currency,
      dealCommittedAmount: value?.dealCommittedAmount,
      dollarConsentAmount: dollar_amt,
      intentType:
        value?.intentType === "individual" ||
        value?.intentType === "relative" ||
        !value?.intentType
          ? "individual"
          : value?.intentType === "entity"
          ? "entity"
          : "",
      relation: value?.relation || "Self",
      applicant_pan_number: value?.applicant_pan_number,
      passport_number: value?.passport_number,
      country_code: COUNTRY_CODES?.find(
        item => item?.value === value?.country_code
      ),
      contact_number: value?.contact_number,
      // reference: value?.referenceName,
      // comment: value?.comments,
      investorId: value?.user_id,
      consent_drop_status: value?.consent_drop_status || "no",
      drawdown_drop_status:
        this.state.drawdown_drop_status === ""
          ? value?.drawdown_drop_status
          : this.state.drawdown_drop_status,
      consent_amount: consent_amt,
      notify:
        value?.notify_intent_consent?.toLowerCase() === "no" ? "No" : "Yes",
      notify_drawdown:
        value?.notify_drawdown?.toLowerCase() === "no" ? "No" : "Yes",
      notify_consent:
        value?.notify_consent?.toLowerCase() === "no" ? "No" : "Yes",
    });

    this.getSelfRelativeKYCOptionsForInvestor(
      value,
      this?.props?.fundDetails?.fund_type
    );
  };

  toggleUpdateModel = value => {
    if (!!this.state?.modalUpdate) {
      this.setState({
        relation: "",
        relativeOptionValue: null,
        investorKYCOptions: [],
      });
    }

    this.setState(prevState => ({
      modalUpdate: !prevState.modalUpdate,
    }));
    this.setState({ intentUpdateErrors: "" });
  };

  setLogsModal = logsModalOpen => {
    if (!logsModalOpen) {
      this.setState({ logsModalData: {} });
    }
    this.setState({ logsModalOpen });
  };

  toggleLogsModal = () => {
    this.setLogsModal(!this.state.logsModalOpen);
  };

  async getAllInvestmentVehicles() {
    if (!JSON.parse(localStorage.getItem("allFundDetails"))) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/applyforfunding/getFundDetails`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser"))
                ?.token,
            },
          }
        )
        .then(res => {
          let data = res?.data?.data;
          localStorage.setItem("allFundDetails", JSON.stringify(data));
        });
    }
  }

  async componentDidMount() {
    const {
      appliedFundingDealId,
      onGetPostEventList,
      postEvents,
      onGetAssessmentReport,
      assessmentReport,
      startupInfo,
    } = this.props;
    await this.getAllInvestmentVehicles();
    await onGetAssessmentReport({ appliedFundingDealId });
    await onGetPostEventList();
    this.setState({ postEvents });

    // FETCHING USER DEVICE DATA;
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    await axios
      .get("https://geolocation-db.com/json/")
      .then(res => this.setState({ geolocationData: res.data }))
      .catch(err => console.log("ERROR WHILE FETCHING GEOLOCATION DATA:", err));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { postEvents, assessmentReport } = this.props;
    if (
      !isEmpty(assessmentReport) &&
      size(prevProps.assessmentReport) !== size(assessmentReport)
    ) {
      this.setState({ assessmentReportId: assessmentReport?._id });
    }
    if (
      !isEmpty(postEvents) &&
      size(prevProps.postEvents) !== size(postEvents)
    ) {
      this.setState({ postEvents: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  onChangeIntent = e => {
    this.setState({ intent: e.target.value });
    if (e.target.value !== "Yes") {
      this.setState({ check_applicant_pan_number: "" });
      this.setState({ check_country_code: "" });
      this.setState({ intentType: "" });
      this.setState({ relation: "Self" });
    }
  };
  onChangeIntentType = e => {
    this.setState({ intentType: e.target.value });
    this.setState({ check_intenttype: "" });
  };
  onChangeCommittedAmount = e => {
    this.setState({ committedAmount: e.target.value });
    let inr_to_usd = exchangeCurrencyRates(e.target.value, INR_cur, USD_cur);
    this.setState({ dealCommittedAmount: inr_to_usd?.toFixed(0) });
  };
  onChangedealCommittedAmount = e => {
    this.setState({ dealCommittedAmount: e.target.value });
    let usd_to_inr = exchangeCurrencyRates(e.target.value, USD_cur, INR_cur);
    this.setState({ committedAmount: usd_to_inr?.toFixed(0) });
  };
  // onChangeReference = e => {
  //   this.setState({ reference: e.target.value });
  // };
  // onChangeComment = e => {
  //   this.setState({ comment: e.target.value });
  // };
  onChangeDealManager = e => {
    this.setState({ dealManager: e.target.value });
  };
  onChangeRelation = e => {
    this.setState({ relation: e.target.value });
  };
  onChangeApplicantPanNumber = e => {
    this.setState({ applicant_pan_number: e.target.value });
    if (
      this.state?.applicant_pan_number &&
      this.state?.check_applicant_pan_number
    ) {
      this.setState({ check_applicant_pan_number: "" });
    }
  };
  onChangeCountryCode = value => {
    this.setState({ country_code: value });
    if (this.state?.country_code && this.state?.check_country_code) {
      this.setState({ check_country_code: "" });
    }
  };
  onChangeContactNumber = e => {
    this.setState({ contact_number: e.target.value });
    if (this.state?.contact_number && this.state?.check_contact_number) {
      this.setState({ check_contact_number: "" });
    }
  };

  onChangeNotify = e => {
    this.setState({ notify: e.target.checked ? "Yes" : "No" });
  };
  onChangeNotifyDrawdown = e => {
    this.setState({ notify_drawdown: e.target.checked ? "Yes" : "No" });
  };
  onChangeNotifyConsent = e => {
    this.setState({ notify_consent: e.target.checked ? "Yes" : "No" });
  };

  toggleUpdateConsentAmountModal = (
    user_id,
    email,
    commited_amount,
    terms_accept
  ) => {
    this.setState({
      consentUpdateErrors: {},
    });
    this.setState({
      consent_update_userid: user_id,
      consent_update_email: email,
      finalizeAmount: commited_amount,
      termsAccept: terms_accept,
    });
    this.setState(prevState => ({
      modalConsentAmountUpdate: !prevState.modalConsentAmountUpdate,
    }));
  };

  setConsentAmount = e => {
    this.setState({
      consentUpdateErrors: {},
    });
    if (e.target.value === "") {
      this.setState({
        consentUpdateErrors: {
          consent_amount: "Please enter consent amount.",
        },
      });
    }
    this.setState({ consent_amount: e.target.value });
    let inr_to_usd = exchangeCurrencyRates(e.target.value, INR_cur, USD_cur);
    this.setState({ dollarConsentAmount: inr_to_usd?.toFixed(0) });
  };

  onChangeDollarConsentAmount = e => {
    this.setState({
      consentUpdateErrors: {},
    });
    if (e.target.value === "") {
      this.setState({
        consentUpdateErrors: {
          dollarConsentAmount: "Please enter consent amount in dollar.",
        },
      });
    }
    this.setState({ dollarConsentAmount: e.target.value });
    let usd_to_inr = exchangeCurrencyRates(e.target.value, USD_cur, INR_cur);

    this.setState({ consent_amount: usd_to_inr?.toFixed(0) });
  };

  handleConsentDropStatus = e => {
    let consent_drop_status = e.value;
    this.setState({ consent_drop_status: consent_drop_status });
  };

  consentDropOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  toggleDrawDownDropModal = (user_id, email) => {
    this.setState({
      drawdown_drop_userid: user_id,
      drawdown_drop_email: email,
    });
    this.setState(prevState => ({
      modalDrawDownDrop: !prevState.modalDrawDownDrop,
    }));
    this.setState({
      drawdown_consent_drop_status: "no",
      drawdown_drop_status: "no",
    });
    this.setState({
      drawdownDropErrors: {},
    });
  };

  drawdownDropOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  drawdownConsentDropOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  handleDrawDownDropStatus = e => {
    let drawdown_drop_status = e.value;
    this.setState({ drawdown_drop_status: drawdown_drop_status });
  };

  handleDrawDownConsentDropStatus = e => {
    let drawdown_consent_drop_status = e.value;
    this.setState({
      drawdown_consent_drop_status: drawdown_consent_drop_status,
    });
  };

  updateDrawDownDropStatus = async () => {
    const {
      terms_accept,
      contribution_limit_exceeded,
      payment_received_status,
    } = this.state;

    if (
      this.props.dealData?.drawdown_status === "Drawdown Sent" &&
      [undefined, "", null, "null"].includes(payment_received_status) &&
      contribution_limit_exceeded !== true &&
      terms_accept === true
    ) {
    } else {
      return;
    }
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    let admindata = localStorage.getItem("user_info");
    let loggedInUserData = JSON.parse(localStorage.getItem("user_info"));
    let userEmail = loggedInUserData?.email;
    let userId = loggedInUserData?.user_id;
    let userName =
      loggedInUserData?.firstname + " " + loggedInUserData?.lastname;
    let deviceInfo = getDeviceDetails(navigator.userAgent);

    let req = {
      intent_user_id: this.state?.drawdown_drop_userid,
      assessment_report_id: this.props?.assessmentReport?._id,
      drawdown_drop_status: this.state?.drawdown_drop_status,
      funding_deal_id: this.props.appliedFundingDealId,
      drawdown_drop_admin_id: admindata?.user_id,
      email: this.state?.drawdown_drop_email,
      source_of_drawdown_consent_drop: `admin-${
        checkDeviceType(navigator)?.includes("Desktop") ? "website" : "mobile"
      }`,
      consent_dropped_admin_id: userId,
      consent_dropped_admin_email: userEmail,
      consent_dropped_admin_name: userName,
      userLocation: getLocationString(
        this.state?.geolocationData?.city,
        this.state?.geolocationData?.country_name
      ),
      userIP: this.state?.geolocationData["IPv4"],
      userBrowser: deviceInfo.browser,
      userOS: deviceInfo.os,
      userDevice: checkDeviceType(navigator),
      notify_drawdown: this.state?.notify_drawdown,
    };
    let encryptedQuery = encryptDataToArray(req)
    let data = {
      q: JSON.stringify(encryptedQuery)
    }
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/applyforfunding/update-drawdown-drop-status`,
        data,
        configHeaders
      )
      .then(async response => {
        if (response?.data?.status === true) {
          alert(response?.data?.message);
          this.setState({
            modalDrawDownDrop: false,
            drawdown_drop_status: this.state?.drawdown_drop_status,
          });
        }
      })
      .catch(error => {
        if (error?.response) {
          if (error?.response?.data?.message?.errors) {
            try {
              this.setState({
                drawdownDropErrors: error?.response?.data?.message?.errors,
              });
            } catch (err) {}
          }
        }
      });
  };

  updateConsentForInvestor = async (
    row = {},
    notifyconsent = "",
    functionality_case = "",
    lastexecution = 0
  ) => {
    let c_amount = row?.consent_amount || this.state?.committedAmount;
    let d_amount =
      row?.consent_amount_global || this.state?.dealCommittedAmount;
    if (functionality_case != "multiple") {
      c_amount = this.state?.consent_amount || this.state?.committedAmount;
      d_amount =
        this.state?.dollarConsentAmount || this.state?.dealCommittedAmount;
      if (this.state.intentCurrency?.value === "INR") {
        if (c_amount <= 0) {
          this.setState({
            consentUpdateErrors: {
              consent_amount: "Please enter consent amount.",
            },
          });
          return;
        }
      } else if (this.state.intentCurrency?.value === "USD") {
        if (d_amount <= 0) {
          this.setState({
            consentUpdateErrors: {
              dollarConsentAmount: "Please enter consent amount in dollar.",
            },
          });
          return;
        }
      }
      // Handle various condition on consent amount update
      const {
        intent,
        terms_accept,
        contribution_limit_exceeded,
        payment_received_status,
      } = this.state;

      if (
        ((intent === "Yes" && !terms_accept) ||
          (terms_accept &&
            contribution_limit_exceeded !== true &&
            this.props.dealData?.drawdown_status !== "Drawdown Sent") ||
          (terms_accept && contribution_limit_exceeded === true)) &&
        [undefined, "", null, "null"].includes(payment_received_status)
      ) {
      } else {
        this.setState({
          consentUpdateErrors: {
            consent_update_error:
              "Consent update and Consent drop not possible on this step.",
          },
        });
        return;
      }

      if (!isEmpty(this.state.consentUpdateErrors)) {
        return;
      }
    }
    this.setState({
      consentUpdateErrors: {},
    });
    this.setState({ consentUpdateButton: true });
    let token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    let deviceInfo = getDeviceDetails(navigator.userAgent);
    let admindata = JSON.parse(localStorage.getItem("user_info"));

    let intent_user_id = this.state?.consent_update_userid;
    let consent_drop_status = this.state?.consent_drop_status;
    let consent_update_email = this.state?.consent_update_email;
    let notify_consent = this.state?.notify_consent;
    if (functionality_case == "multiple") {
      intent_user_id = row?.user_id;
      consent_drop_status = "yes";
      consent_update_email = row?.email;
      notify_consent = notify_consent;
    }
    // Saving logs for consent changes
    let req = {
      user_id: intent_user_id,
      assessment_report_id: this.props?.assessmentReport?._id,
      userEmail: `${JSON.parse(localStorage.getItem("user_info"))?.email}`,
      firstName: `${JSON.parse(localStorage.getItem("user_info"))?.firstname}`,
      lastName: `${JSON.parse(localStorage.getItem("user_info"))?.lastname}`,
      consent_changed_admin_id: admindata?.user_id,
      consent_drop_status: consent_drop_status,
      consent_updated_from: "admin-website",
      intentCurrency: this.state.intentCurrency,
      consent_amount: c_amount,
      consent_amount_global: d_amount,
      funding_deal_id: this.props.appliedFundingDealId,
      userLocation: getLocationString(
        this.state?.geolocationData?.city,
        this.state?.geolocationData?.country_name
      ),
      userIP: this.state?.geolocationData["IPv4"],
      userBrowser: deviceInfo.browser,
      userOS: deviceInfo.os,
      userDevice: checkDeviceType(navigator),
      source: "admin",
    };
    let encryptedQuery = encryptDataToArray(req)

    let data = {
      q: JSON.stringify(encryptedQuery)
    }

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/applyforfunding/saveconsenttoinvestorlogs`,
        data,
        configHeaders
      )
      .then(res => {
        let { status, msg } = res.data;

        // if (status === true) console.log(msg);
      })
      .catch(error => console.log("Error:", error));

    // Updating consent here
    req = {
      intent_user_id: intent_user_id,
      assessment_report_id: this.props?.assessmentReport?._id,
      consent_drop_status: consent_drop_status,
      consent_amount: c_amount,
      funding_deal_id: this.props.appliedFundingDealId,
      consent_changed_admin_id: admindata?.user_id,
      email: consent_update_email,
      finalizeAmount: this.state.finalizeAmount,
      userCurrency: INR_cur,
      dealCurrency: USD_cur,
      dollarConsentAmount: d_amount,
      intentCurrency: this.state.intentCurrency,
      currencyConversionRateConsent:
        exchangeCurrencyRates(c_amount, INR_cur, USD_cur) / c_amount,
      userLocation: getLocationString(
        this.state?.geolocationData?.city,
        this.state?.geolocationData?.country_name
      ),
      userIP: this.state?.geolocationData["IPv4"],
      userBrowser: deviceInfo.browser,
      userOS: deviceInfo.os,
      userDevice: checkDeviceType(navigator),
      notify_consent: this.state?.notify_consent,
    };

    let encryptedQueryConsent = encryptDataToArray(req)
    let dataConsent = {
      q: JSON.stringify(encryptedQueryConsent)
    }

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/applyforfunding/sendconsenttoinvestor`,
        dataConsent,
        configHeaders
      )
      .then(async response => {
        this.setState({ consentUpdateButton: false });

        if (response?.data?.status === true) {
          if (functionality_case == "multiple") {
            if (lastexecution) {
              alert(response?.data?.message);
            }
          } else {
            alert(response?.data?.message);
          }
          this.setState({ modalConsentAmountUpdate: false });

          const {
            onGetPostEventList,
            postEvents,
            onGetAssessmentReport,
            appliedFundingDealId,
          } = this.props;
          await onGetAssessmentReport({ appliedFundingDealId });
          await onGetPostEventList();
          this.setState({ postEvents });
          this.setState({ res_error: "" });
          this.setState({ investorKYCOptions: [] });
        }
      })
      .catch(error => {
        if (error?.response) {
          if (error?.response?.data?.message?.errors) {
            try {
              this.setState({
                consentUpdateErrors: error?.response?.data?.message?.errors,
              });
            } catch (err) {}
          }
        }
      });
      this.setState({ consentUpdateButton: false });
  };

  saveDetails = async () => {
    const {
      intent,
      committedAmount,
      dealCommittedAmount,
      dealManager,
      investorId,
      assessmentReportId,
      assessmentReport,
      relation,
      intentType,
      applicant_pan_number,
      passport_number,
      contact_number,
      country_code,
      geolocationData,
      notify,
      terms_accept,
    } = this.state;

    if (terms_accept) {
      this.setState({
        intentUpdateErrors: "Consent received already.",
      });
      return;
    } else {
      this.setState({ intentUpdateErrors: "" });
    }

    if (
      (committedAmount && parseInt(committedAmount) < 0) ||
      (dealCommittedAmount && parseInt(dealCommittedAmount) < 0)
    ) {
      this.setState({
        intentUpdateErrors: "Intent amount can not be negative",
      });
      return;
    } else {
      this.setState({ intentUpdateErrors: "" });
    }

    let applicant_pan_number_data = applicant_pan_number;
    let contact_number_data = contact_number;
    let country_code_data = country_code;
    if (intent === "Yes" && !intentType) {
      this.setState({ check_intenttype: "Please select intent type" });
      return;
    }
    let regPanCard = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (intentType === "individual" && relation !== "Self") {
      if (
        !passport_number &&
        !applicant_pan_number &&
        !contact_number &&
        !country_code
      ) {
        this.setState({
          check_applicant_pan_number: "Relative's required data is missing.",
          check_contact_number: "Relative's required data is missing.",
        });
        return;
      }

      if (applicant_pan_number) {
        if (!regPanCard.test(applicant_pan_number)) {
          this.setState({
            check_applicant_pan_number:
              "Relative Pan Card is in invalid format",
          });
          return;
        }
      }
      // if (contact_number && !country_code) {
      //   this.setState({
      //     check_applicant_pan_number: "",
      //     check_country_code: "country code is required",
      //   });
      //   return;
      // }
      // if (!contact_number && country_code) {
      //   this.setState({
      //     check_applicant_pan_number: "",
      //     check_contact_number: "Mobile number is required",
      //   });
      //   return;
      // }
    }
    if (
      (intentType === "individual" && relation === "Self") ||
      intentType === "entity"
    ) {
      applicant_pan_number_data = "";
      contact_number_data = "";
      country_code_data = "";
    }
    this.setState({ intentUpdateButton: true });

    const { appliedFundingDealId } = this.props;

    let token = authHeader();

    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    }
    let intenttype = intentType;
    if (intentType === "individual" && relation !== "Self") {
      intenttype = "relative";
    }
    if (intentType === "Entity") {
      relation = "";
    }
    // let emailId = JSON.parse(localStorage.getItem("user_info"))?.email || ""
    let deviceInfo = getDeviceDetails(navigator.userAgent);
    let req = {
      committed_amount: intent?.toLowerCase() === "yes" ? committedAmount : 0,
      currencyConversionRate:
        intent?.toLowerCase() === "yes"
          ? exchangeCurrencyRates(committedAmount, INR_cur, USD_cur) /
            committedAmount
          : 1,
      dealCommittedAmount:
        intent?.toLowerCase() === "yes" ? dealCommittedAmount : 0,
      deal_manager: dealManager !== undefined ? dealManager : "",
      areYouIntrested: intent,
      source: "admin",
      intent_updated_from: "admin-website",
      userEmail: `${JSON.parse(localStorage.getItem("user_info"))?.email}`,
      firstName: `${JSON.parse(localStorage.getItem("user_info"))?.firstname}`,
      lastName: `${JSON.parse(localStorage.getItem("user_info"))?.lastname}`,
      changedBy: JSON.parse(localStorage.getItem("user_info"))?.email || "",
      intentType: intenttype,
      relation: relation,
      applicant_pan_number,
      passport_number,
      contact_number,
      country_code: country_code?.value,
      userCurrency: INR_cur,
      dealCurrency: USD_cur,
      intentCurrency: this.state.intentCurrency,
      notify_intent_consent: notify,
      userLocation: getLocationString(
        geolocationData?.city,
        geolocationData?.country_name
      ),
      userIP: geolocationData["IPv4"],
      // userBrowser: getUserBrowser(navigator.browserDetection),
      userBrowser: deviceInfo.browser,
      // userOS: getOperatingSystem(window),
      userOS: deviceInfo.os,
      userDevice: checkDeviceType(navigator),
    };

    let encryptedQuery = encryptDataToArray(req)

    let data = {
      q: JSON.stringify(encryptedQuery)
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/reports/assessmentreport/${this.props?.assessmentReport?._id}/investor/${investorId}`,
        data,
        configHeaders
      )
      .then(async response => {
        this.setState({ intentUpdateButton: false });
        if (response?.data?.status === false) {
          this.setState({ res_error: response?.data?.message });
        } else {
          this.toggleUpdateModel();

          const { onGetPostEventList, postEvents, onGetAssessmentReport } =
            this.props;

          await onGetAssessmentReport({ appliedFundingDealId });
          await onGetPostEventList();
          this.setState({ postEvents });
          this.setState({ res_error: "" });
          this.setState({ investorKYCOptions: [] });
        }
        // }
      })
      .catch(err => {});
  };

  getSelfRelativeKYCOptionsForInvestor = async (
    selectedInvestorDetails,
    fund_type
  ) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reports/assessmentreport/self-relative-kyc-options/${selectedInvestorDetails?.user_id}/${fund_type?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader(),
          },
        }
      );

      if (!response?.data?.status) {
        // console.error("after response error", response?.data);
        this.setState({ investorKYCOptions: [], relativeOptionValue: null });
        return;
      }

      let investorKYCOptions =
        response?.data?.data?.selfRelativeKYCOptions || [];
      if (fund_type?.currency_details?.value === "INR") {
        this.setState({
          investorKYCOptions: investorKYCOptions,
          relativeOptionValue: investorKYCOptions?.find(
            option =>
              option?.applicant_pan_number ===
                selectedInvestorDetails?.applicant_pan_number ||
              (option?.country_code === selectedInvestorDetails?.country_code &&
                option?.contact_number ===
                  selectedInvestorDetails?.contact_number)
          )?.value,
        });
      } else {
        this.setState({
          investorKYCOptions: investorKYCOptions,
          relativeOptionValue: investorKYCOptions?.find(
            option =>
              option?.passport_number ===
              selectedInvestorDetails?.passport_number
          )?.value,
        });
      }

      return;
    } catch (error) {
      // console.error("catch error", error?.message);
      this.setState({ investorKYCOptions: [], relativeOptionValue: null });
    }
  };

  uploadCSV = async () => {
    this.setState({ importLoading: true });

    const { assessmentReportId, csvFile } = this.state;
    if (csvFile?.name !== undefined) {
      this.setState({ btnDisable: true });
      const token = authHeader();
      const formData = new FormData();

      // DEVICE STATS FOR LOGS;
      let deviceInfo = getDeviceDetails(navigator.userAgent);
      let userLocation = getLocationString(
        this.state?.geolocationData?.city,
        this.state?.geolocationData?.country_name
      );
      let userIP = this.state?.geolocationData["IPv4"];
      let userBrowser = deviceInfo.browser;
      let userOS = deviceInfo.os;
      let userDevice = checkDeviceType(navigator);
      // let source = `admin-${
      //   checkDeviceType(navigator)?.includes("Desktop") ? "website" : "mobile"
      // }`;
      let source = `Excel-Via Admin`;

      let user_info = JSON.parse(localStorage?.getItem("user_info"));
      let adminUserId = user_info?.user_id?.toString();

      formData.append("csv_file", csvFile);
      formData.append("assessment_report_id", assessmentReportId);
      formData.append("appliedFundingDealId", this.props.appliedFundingDealId);
      formData.append("location", userLocation);
      formData.append("ipAddress", userIP);
      formData.append("browserName", userBrowser);
      formData.append("osName", userOS);
      formData.append("deviceName", userDevice);
      formData.append("source", source);
      formData.append("adminId", adminUserId);

      let configHeaders;
      if (token) {
        configHeaders = {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: token,
          },
        };
      }

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/import-assessment-report`,
          formData,
          configHeaders
        )
        .then(async response => {
          this.setState({ csvFile: {} });
          this.setState({ btnDisable: false });
          this.setState({ importLoading: false });

          if (response.status === 200) {
            const {
              appliedFundingDealId,
              onGetPostEventList,
              postEvents,
              onGetAssessmentReport,
              assessmentReport,
            } = this.props;
            await onGetAssessmentReport({ appliedFundingDealId });
            await onGetPostEventList();
            this.setState({ postEvents });
            alert("CSV imported successfully.");
          }
          document.getElementById("fileUpload").value = null;
        })
        .catch(error => {
          toast.error(error.response.data.message);
          this.setState({ btnDisable: false });
          this.setState({ importLoading: false });
        });
    }
  };

  exportCSV = async assessmentReportId => {
    this.setState({ exportLoading: true });
    const req = { assessment_report_id: assessmentReportId };
    this.setState({ btnDisable: true });
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/export-assessment-report`,
        req,
        configHeaders,
        { responseType: "blob" }
      )
      .then(response => {
        this.setState({ btnDisable: false });
        this.setState({ exportLoading: false });
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data?.signedUrl;
          link.setAttribute("download", "AssessmentReport.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          alert("CSV exported successfully.");
        }
      })
      .catch(error => {
        this.setState({ btnDisable: false });
        this.setState({ exportLoading: false });
      });
  };

  exportInterestedUserCSV = async assessmentReportId => {
    this.setState({ exportInterestedLoading: true });
    const req = { assessment_report_id: assessmentReportId };
    this.setState({ btnDisable: true });
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/export-interested-user-assessment-report`,
        req,
        configHeaders,
        { responseType: "blob" }
      )
      .then(response => {
        this.setState({ btnDisable: false });
        this.setState({ exportInterestedLoading: false });
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data?.signedUrl;
          link.setAttribute("download", "AssessmentReport.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          alert("CSV exported successfully.");
        }
      })
      .catch(error => {
        this.setState({ btnDisable: false });
        this.setState({ exportInterestedLoading: false });
      });
  };

  getCSVFile = (e, key) => {
    this.setState({
      csvFile: e.target.files[0],
    });
  };

  render() {
    const { assessmentReport, dealManagers } = this.props;
    const { intentUpdateButton } = this.state;

    const customTotal = (from, to, size) => {
      if (!size) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            No Data Available
          </span>
        );
      }
      return (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from || 0} to {to || 0} of {size || 0}
        </span>
      );
    };

    const selectRow = {
      mode: "checkbox",
      hideSelectColumn: this.state?.authUser?.userInfo?.is_saas_parent
        ? true
        : false,
      onSelect: (row, isSelect, rowIndex, e) => {
        let selectedApplicant = this.state.selectedApplicant;
        if (isSelect) {
          selectedApplicant.push(row);
        } else {
          for (let i = 0; i < selectedApplicant?.length; i++) {
            if (
              row?.user_id == selectedApplicant[i]?.user_id &&
              row?.relation == selectedApplicant[i]?.relation
            ) {
              selectedApplicant.splice(i, 1);
            }
          }
        }
        this.setState({ selectedApplicant: selectedApplicant });
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          this.setState({ selectedApplicant: rows });
        } else {
          this.setState({ selectedApplicant: [] });
        }
      },
      headerColumnStyle: { "vertical-align": "middle" },
    };

    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };

    // const pageOptions = {
    //     sizePerPage: 50,
    //     totalSize: assessmentReport?.investment_report.length, // replace later with size(users),
    //     custom: true,
    // };

    const defaultSorted = [
      {
        dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const assessmentReportColumns = [
      {
        text: "User Name",
        dataField: "user_name",
        sort: true,
        filterValue: (cell, row) => row.user_name,
        filter: textFilter(),
      },
      {
        text: "Applicant Name",
        dataField: "applicant_name",
        sort: true,
        filterValue: (cell, row) => row?.applicant_name,
        filter: textFilter(),
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
        formatter: (cellContent, value) => {
          if (value?.applicant_name) {
            let customrealtion = value?.relation || "Self";
            return (
              <>
                {value?.applicant_name} ({customrealtion}){" "}
              </>
            );
          } else {
            return <>-</>;
          }
        },
      },

      {
        text: "Intent",
        sort: true,
        sortValue: (cellContent, value) => {
          let { startupInfo, assessmentReport } = this.props;

          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              assessmentReport?.fund_type_id?.toString()
          );
          // checkIntentCurrency(
          //   value?.user_currency,
          //   startupInfo?.[0]?.currency,
          //   value?.tax_return_filling_countries
          // );
          let committed_amount_currency = 0;

          if (intent_cur?.currency === "INR") {
            committed_amount_currency = value?.committed_amount;
          } else if (intent_cur?.currency === "USD") {
            committed_amount_currency = value?.dealCommittedAmount;
          }

          return value?.committed_amount;
        },
        dataField: "areYouIntrested",
        // filterValue: (cell, row) => row.areYouIntrested,
        filter: selectFilter({
          options: IntentOptions.sort((a, b) => {
            if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
              return -1;
            }
            if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          delay: 1500,
        }),
        formatter: (cellContent, value) => {
          let { startupInfo, assessmentReport } = this.props;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              assessmentReport?.fund_type_id?.toString()
          );
          //  checkIntentCurrency(
          //   value?.user_currency,
          //   startupInfo?.[0]?.currency,
          //   value?.tax_return_filling_countries
          // );

          let committed_amount_currency = 0;

          if (intent_cur?.currency === "INR") {
            committed_amount_currency = value?.committed_amount;
          } else if (intent_cur?.currency === "USD") {
            committed_amount_currency = value?.dealCommittedAmount;
          }

          if (value?.areYouIntrested == "Yes") {
            return (
              <>
                {intent_cur?.symbol}
                {committed_amount_currency
                  ? toCommaFormat(committed_amount_currency)
                  : "0"}{" "}
                (
                {value?.intent_update_date
                  ? moment(value?.intent_update_date).format(
                      "DD-MMM-YY hh:mm:ss A"
                    )
                  : value?.createdAt
                  ? moment(value?.createdAt).format("DD-MMM-YY hh:mm:ss A")
                  : "--"}
                )
              </>
            );
          } else {
            return <>{value?.areYouIntrested ? value?.areYouIntrested : "-"}</>;
          }
        },
        headerStyle: (colum, colIndex) => {
          return { "text-alwa ign": "center" };
        },
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
      },

      {
        text: "KYC/C.A. Status",
        dataField: "kyc_status",
        sort: true,
        filterValue: (cell, row) => row?.kyc_status,
        filter: selectFilter({
          options: selectKycStatus.sort((a, b) => {
            if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
              return -1;
            }
            if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          delay: 1500,
        }),
        formatter: (cellContent, value) =>
          value?.kyc_status ? value?.kyc_status : "-",
        headerStyle: (colum, colIndex) => {
          return { "text-align": "center" };
        },
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
      },
      {
        text: "Consent Amount",
        sort: true,
        dataField: "consent_amount",
        formatter: (cellContent, value) => {
          let { startupInfo, assessmentReport } = this.props;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              assessmentReport?.fund_type_id?.toString()
          );

          let consent_amount_sent = 0;
          let consent_amount_rec = 0;
          let consent_amount_currency = 0;
          if (intent_cur?.currency === "INR") {
            consent_amount_currency = value?.consent_amount;
          } else if (intent_cur?.currency === "USD") {
            consent_amount_currency = value?.consent_amount_global;
          }

          if (consent_amount_currency > 0) {
            consent_amount_sent = toCommaFormat(consent_amount_currency);
          }
          if (value?.terms_accept && consent_amount_currency > 0) {
            consent_amount_rec = toCommaFormat(consent_amount_currency);
          } else {
            consent_amount_rec = consent_amount_sent;
          }

          return (
            <>
              {intent_cur?.symbol}
              {consent_amount_rec} (
              {value?.consent_sent_date &&
              value?.kyc_status ==
                "Contribution Agreement Signed by All - Process Completed"
                ? moment(value?.consent_sent_date).format(
                    "DD-MMM-YY hh:mm:ss A"
                  )
                : "--"}
              )
            </>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { "text-align": "center" };
        },
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
      },

      {
        text: "Consent Status",
        sort: true,
        dataField: "consent_send",
        filter: selectFilter({
          options: statusfilter.sort((a, b) => {
            if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
              return -1;
            }
            if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          delay: 1500,
          onFilter: this.filterBySent,
        }),
        formatter: (cellContent, row) => {
          let status = "-";
          if (
            row?.kyc_status ==
              "Contribution Agreement Signed by All - Process Completed" &&
            parseInt(row?.consent_amount) > 0 &&
            row?.term_sheet_status == "received_from_sebi" &&
            (!row?.consent_drop_status || row?.consent_drop_status == "no")
          ) {
            if (
              row?.consent_send == "yes" &&
              row?.terms_accept &&
              !row?.contribution_limit_exceeded
            ) {
              status = "Received";
            } else if (row?.consent_send == "yes" && !row?.terms_accept) {
              status = "Awaited";
            }
          } else if (
            row?.kyc_status ==
              "Contribution Agreement Signed by All - Process Completed" &&
            row?.term_sheet_status == "received_from_sebi"
          ) {
            if (row?.consent_drop_status == "yes") {
              status = "Dropped";
            }
          }
          if (status == "Received") {
            return (
              <>
                <p style={{ color: "green" }}>
                  {status} (
                  {row?.consent_received_date
                    ? moment(row?.consent_received_date).format(
                        "DD-MMM-YY hh:mm:ss A"
                      )
                    : "--"}
                  )
                </p>
              </>
            );
          } else if (status == "Dropped") {
            return (
              <>
                <p style={{ color: "red" }}>{status}</p>
              </>
            );
          } else {
            return (
              <>
                <p style={{ color: "grey" }}>{status}</p>
              </>
            );
          }
        },
        headerStyle: (colum, colIndex) => {
          return { "text-alwa ign": "center" };
        },
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
      },

      {
        text: "Drawdown (excl of fees/taxes)",
        sort: true,
        headerFormatter: (column, colIndex, components) => {
          return (
            <>
              Drawdown <br />
              (excl of fees/taxes)
            </>
          );
        },
        dataField: "consent_amount",
        formatter: (cell, value) => {
          let { startupInfo, assessmentReport } = this.props;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              assessmentReport?.fund_type_id?.toString()
          );

          let consent_amount_sum = 0;
          let consent_amount_currency = 0;

          if (intent_cur?.currency === "INR") {
            consent_amount_currency = value?.consent_amount;
            if (value?.drawdown_status == "Drawdown Sent") {
              if (consent_amount_currency > 0) {
                consent_amount_sum = toCommaFormat(consent_amount_currency);
              }
            } else if (
              value?.pastinvestment?.past_investment?.amount_invested > 0
            ) {
              consent_amount_sum = toCommaFormat(
                value?.pastinvestment?.past_investment?.amount_invested
              );
            }
          } else if (intent_cur?.currency === "USD") {
            consent_amount_currency = value?.consent_amount_global;
            if (value?.drawdown_status == "Drawdown Sent") {
              if (consent_amount_currency > 0) {
                consent_amount_sum = toCommaFormat(consent_amount_currency);
              }
            } else if (
              value?.pastinvestment?.past_investment?.startupAmountInvested > 0
            ) {
              consent_amount_sum = toCommaFormat(
                value?.pastinvestment?.past_investment?.startupAmountInvested
              );
            }
          }

          return (
            <>
              {intent_cur?.symbol}
              {consent_amount_sum}
            </>
          );
        },
      },

      {
        text: "Drawdown Status",
        dataField: "drawdown_drop_status",
        sort: true,
        filter: selectFilter({
          options: statusfilter.sort((a, b) => {
            if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
              return -1;
            }
            if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          delay: 1500,
          onFilter: this.filterBySentDradown,
        }),
        formatter: (cellContent, row) => {
          let status = "-";
          if (
            row?.kyc_status ==
              "Contribution Agreement Signed by All - Process Completed" &&
            parseInt(row?.consent_amount) > 0 &&
            !row?.contribution_limit_exceeded
          ) {
            if (
              row?.drawdown_status == "Drawdown Sent" &&
              row?.consent_send == "yes" &&
              row?.terms_accept &&
              (!row?.consent_drop_status || row?.consent_drop_status == "no") &&
              parseInt(row?.consent_amount) > 0 &&
              (!row?.drawdown_drop_status || row?.drawdown_drop_status == "no")
            ) {
              if (row?.pastinvestment?.past_investment?.amount_invested > 0) {
                status = "Received";
              } else {
                status = "Awaited";
              }
            } else if (
              row?.drawdown_status == "Drawdown Sent" &&
              row?.drawdown_drop_status == "yes"
            ) {
              status = "Dropped";
            }
          }
          if (status == "Received") {
            return (
              <>
                <p style={{ color: "green" }}>{status}</p>
              </>
            );
          } else if (status == "Dropped") {
            return (
              <>
                <p style={{ color: "red" }}>{status}</p>
              </>
            );
          } else {
            return (
              <>
                <p style={{ color: "grey" }}>{status}</p>
              </>
            );
          }
        },

        headerStyle: (colum, colIndex) => {
          return { "text-align": "center" };
        },
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
      },

      {
        text: "Portfolio Amount",
        sort: true,
        dataField: "amount_invested",
        formatter: (cell, value) => {
          let { startupInfo, assessmentReport } = this.props;
          let intent_cur = JSON.parse(
            localStorage.getItem("allFundDetails")
          )?.find(
            funddetail =>
              funddetail?._id?.toString() ==
              assessmentReport?.fund_type_id?.toString()
          );
          // checkIntentCurrency(
          //   value?.user_currency,
          //   startupInfo?.[0]?.currency,
          //   value?.tax_return_filling_countries
          // );

          let portfolio_amt = 0;

          if (intent_cur?.currency === "INR") {
            if (value?.pastinvestment?.past_investment?.amount_invested > 0) {
              portfolio_amt = toCommaFormat(
                value?.pastinvestment?.past_investment?.amount_invested
              );
            }
          } else if (intent_cur?.currency === "USD") {
            if (
              value?.pastinvestment?.past_investment?.startupAmountInvested > 0
            ) {
              portfolio_amt = toCommaFormat(
                value?.pastinvestment?.past_investment?.startupAmountInvested
              );
            }
          }

          return (
            <>
              {intent_cur?.symbol}
              {portfolio_amt}
            </>
          );
        },
      },

      {
        text: "IM/SM",
        hidden: this.state?.authUser?.userInfo?.is_saas_parent ? true : false,
        dataField: "investment_manager_detail",
        filter: textFilter({
          onFilter: this.filterByPrice,
        }),
        
        formatter: (cellContent, user) => {
          let value = [];
          let investment_manager_name =
            user?.investment_manager_detail?.firstname;
          if (
            investment_manager_name &&
            user?.investment_manager_detail?.lastname
          ) {
            investment_manager_name =
              investment_manager_name +
              " " +
              user?.investment_manager_detail?.lastname;
          }

          if (investment_manager_name) {
            value.push(investment_manager_name);
          }

          let syndicate_manager_name =
            user?.syndicate_manager_detail?.firstname;

          if (
            syndicate_manager_name &&
            user?.syndicate_manager_detail?.lastname
          ) {
            syndicate_manager_name =
              syndicate_manager_name +
              " " +
              user?.syndicate_manager_detail?.lastname;
          }
          if (syndicate_manager_name) {
            value.push(syndicate_manager_name);
          }
          value = value.join(", ");
          return <>{value}</>;
        },
      },

      {
        text: `Action`,
        sort: true,
        hidden: this.state?.authUser?.userInfo?.is_saas_parent ? true : false,
        formatter: (cellContent, value) => {
          return (
            <div className="d-flex gap-3">
              {this.state?.userRolles?.intent_report_edit == true && (
                <Button
                  color="info"
                  // className="inner"
                  onClick={() => {
                    this.toggleUpdateModel();
                    this.setSelectedRowValue(value);
                  }}
                >
                  Update Intent
                </Button>
              )}
              {this.state?.userRolles?.update_consent ? (
                // ((value?.areYouIntrested === "Yes" && !value?.terms_accept) ||
                //   (value?.terms_accept &&
                //     value?.contribution_limit_exceeded !== true &&
                //     this.props.dealData?.drawdown_status !== "Drawdown Sent") ||
                //   (value?.terms_accept &&
                //     value?.contribution_limit_exceeded === true)) &&
                // [undefined, "", null, "null"].includes(
                //   value?.payment_received_status
                // )
                <Button
                  color="info"
                  onClick={() => {
                    //this.updateConsentForInvestor(value?.user_id);
                    this.toggleUpdateConsentAmountModal(
                      value?.user_id,
                      value?.email,
                      this.state.intentCurrency?.value === "INR"
                        ? value?.consent_amount
                        : value?.consent_amount_global,
                      value?.terms_accept
                    );
                    this.setSelectedRowValue(value);
                  }}
                >
                  Add/Update Consent
                </Button>
              ) : (
                <></>
              )}
              {this.state?.userRolles?.update_drawdown_status ? (
                // this.props.dealData?.drawdown_status === "Drawdown Sent" &&
                // [undefined, "", null, "null"].includes(
                //   value?.payment_received_status
                // ) &&
                // !value?.contribution_limit_exceeded &&
                // value?.terms_accept &&
                <Button
                  color="info"
                  onClick={() => {
                    //this.updateConsentForInvestor(value?.user_id);
                    this.toggleDrawDownDropModal(value?.user_id, value?.email);
                    this.setSelectedRowValue(value);
                  }}
                >
                  Drawdown Drop
                </Button>
              ) : (
                <></>
              )}
              {this.state.userRolles?.intent_consent_report_logs == true && (
                <Button
                  color="secondary"
                  className="inner"
                  onClick={async () => {
                    try {
                      this.setState({ logsModalData: {} });
                      let investor_id = value.user_id || value.email;
                      let data = await getPastLogsData(
                        // this.state.assessmentReportId,
                        this.props?.assessmentReport?._id,
                        investor_id,
                        value?.intentType,
                        value?.applicant_pan_number || value?.passport_number,
                        this.props?.assessmentReport?.fund_type_id,
                        this.props.appliedFundingDealId
                      );

                      if (!data.status) return alert(data.message);
                      // if (
                      //   value?.consent_sent_date &&
                      //   value?.investor_ca_status === "process_completed"
                      // )
                      //   data.data.added.push({
                      //     consent_sent_date: value?.consent_sent_date,
                      //   });
                      // if (value?.consent_received_date)
                      //   data.data.added.push({
                      //     consent_received_date: value?.consent_received_date,
                      //   });
                      // if (value?.drawdown_sent_date)
                      //   data.data.added.push({
                      //     drawdown_sent_date: value?.drawdown_sent_date,
                      //   });
                      this.setState({ logsModalData: data.data });
                      this.setLogsModal(true);
                    } catch (err) {
                      this.setLogsModal(false);
                      return alert(
                        err?.response?.data?.message ||
                          err?.message ||
                          "Cannot get past logs"
                      );
                    }
                  }}
                >
                  Logs
                </Button>
              )}
            </div>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { "text-align": "center" };
        },
        style: (colum, colIndex) => {
          return { "text-align": "center" };
        },
      },
    ];

    const downloadDemoCsv = () => {
      const demoCsv = [
        [
          "FirstName",
          "LastName",
          "Email",
          "PhoneNumber",
          "City",
          // "State",
          // "Country",
          "CommittedAmount(₹)",
          "CommittedAmount($)",
          "Reference",
          // "Investment Amount",
          "Duration(minute)",
          "Investment Allocated For Year",
          "Is Attended",
          "Is Polled",
          "RSVP",
          "ConsentAmount",
          "Do you file Tax Returns in India",
          "Intent | Are you Interested? | Yes,No,Maybe",
          "Range | How much amount you want to invest? | 3L to 5L,5L to 8L",
          "Notify",
        ],
        [
          "Bob",
          "John",
          "BoB@yopmail.com",
          9090090909,
          "Chicago",
          // "Chicago",
          // "India",
          1000000,
          1200,
          "Preet",
          // "300000",
          140,
          "upto $25,000",
          "Yes",
          "No",
          "Attending",
          0,
          "Yes",
          "Yes",
          "Yes, I would like to invest Rs. 3 L to 8 L / $ 4 K to 10 K",
          "No",
        ],
        [
          "Peeter",
          "John",
          "peeter@yopmail.com",
          9876543210,
          "Chicago",
          // "New Jersey",
          // "USA",
          9000000,
          11000,
          "Bob",
          // "500000",
          30,
          "$25,000 - $50,000",
          "No",
          "No",
          "No RSVP Yet",
          500000,
          "No",
          "Yes",
          "Yes, I would like to invest Rs. 8 L to 16 L / $ 10 K to 20 K",
          "Yes",
        ],
      ];

      this.setState({ demoCsvExportData: demoCsv });
    };

    const confirmAndCommunicationFlag = () => {
      this.setState({ exportLoadingConsentDrop: true });
      if (this.state.selectedApplicant?.length > 0) {
        let confirmflag = confirm("Are you sure?");
        if (confirmflag) {
          let enableflag = confirm("Do you want to send notification?");
          let notifyconsent = false;
          if (enableflag) {
            notifyconsent = true;
          }

          if (this.state.selectedApplicant?.length > 0) {
            // To check selected row's consent can be dropped or not
            for (
              let index = 0;
              index < this.state.selectedApplicant?.length;
              index++
            ) {
              let element = this.state.selectedApplicant[index];
              if (
                ((element?.areYouIntrested === "Yes" &&
                  !element?.terms_accept) ||
                  (element?.terms_accept &&
                    element?.contribution_limit_exceeded !== true &&
                    element?.drawdown_status !== "Drawdown Sent") ||
                  (element?.terms_accept &&
                    element?.contribution_limit_exceeded === true)) &&
                [undefined, "", null, "null"].includes(
                  element?.payment_received_status
                )
              ) {
              } else {
                alert("Consent drop not possible for " + element?.user_name);
                return false;
              }
            }
            for (
              let index = 0;
              index < this.state.selectedApplicant?.length;
              index++
            ) {
              let element = this.state.selectedApplicant[index];
              let lastexecution = 0;
              if (index == this.state.selectedApplicant?.length - 1) {
                lastexecution = 1;
              }
              this.updateConsentForInvestor(
                element,
                notifyconsent,
                "multiple",
                lastexecution
              );
            }
          }
        } else {
          this.setState({ exportLoadingConsentDrop: false });
          return false;
        }
      } else {
        alert("Please select an applicant");
        this.setState({ exportLoadingConsentDrop: false });
        return false;
      }
      this.setState({ exportLoadingConsentDrop: false });
    };

    return (
      <React.Fragment>
        <ToastContainer />

        <DataFetchLoader loading={this?.props?.investmentReportLoading} />

        <Row>
          <Col lg={12}>
            <Form controlId="formFile" className="mb-3">
              <div className="d-flex mt-3">
                {this.state.userRolles?.investor_report_import_csv ? (
                  <div className="mx-1">
                    <input
                      id="fileUpload"
                      type="file"
                      className="form-control"
                      onChange={(e, key) => this.getCSVFile(e, key)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {this.state.userRolles?.investor_report_import_csv ? (
                  <div className="mx-1">
                    {this.state.importLoading ? (
                      <Button
                        className="btn btn-info"
                        onClick={() => this.uploadCSV()}
                        disabled={this.state.btnDisable ? "disabled" : ""}
                      >
                        <Spinner animation="border" size="sm" /> Import CSV
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-info"
                        onClick={() => this.uploadCSV()}
                        disabled={this.state.btnDisable ? "disabled" : ""}
                      >
                        <i className="bx bx-import fs-4"></i> Import CSV
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.userRolles?.investor_report_export_csv ? (
                  <div className="mx-1">
                    {this.state.exportLoading ? (
                      <Button
                        className="btn btn-success"
                        onClick={() => this.exportCSV(assessmentReport?._id)}
                      >
                        <Spinner animation="border" size="sm" /> Export CSV
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-success"
                        onClick={() => this.exportCSV(assessmentReport?._id)}
                      >
                        <i className="bx bx-export fs-4"></i> Export CSV
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.userRolles?.export_interested_user_csv ? (
                  <div className="mx-1">
                    {this.state.exportInterestedLoading ? (
                      <Button
                        className="btn btn-success"
                        onClick={() =>
                          this.exportInterestedUserCSV(assessmentReport?._id)
                        }
                      >
                        <Spinner animation="border" size="sm" /> Export
                        Interested User CSV
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-success"
                        onClick={() =>
                          this.exportInterestedUserCSV(assessmentReport?._id)
                        }
                      >
                        <i className="bx bx-export fs-4"></i> Export Interested
                        User CSV
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.userRolles?.drop_consent_for_selected_applicant ? (
                  <div className="mx-1">
                    {this.state.exportLoadingConsentDrop ? (
                      <Button
                        className="btn btn-info"
                        onClick={() => confirmAndCommunicationFlag()}
                      >
                        <Spinner animation="border" size="sm" /> Drop Consent
                        for Selected Applicant
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-info"
                        onClick={() => confirmAndCommunicationFlag()}
                      >
                        Drop Consent for Selected Applicant
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {this.state.demoCsvExportData.length > 0 ? (
                  <CSVDownload
                    data={this.state.demoCsvExportData}
                    target="_blank"
                  />
                ) : null}
              </div>
              {this.state.userRolles?.investor_report_import_csv ? (
                <p
                  onClick={downloadDemoCsv}
                  style={{
                    color: "#485ec4",
                    marginLeft: 30,
                    cursor: "pointer",
                  }}
                >
                  Reference for assessment import format
                </p>
              ) : (
                ""
              )}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <PaginationProvider
                pagination={paginationFactory(this.options)}
                keyField="_id"
                columns={assessmentReportColumns}
                data={
                  assessmentReport?.investment_report?.length > 0 &&
                  assessmentReport?.investment_report
                }
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={assessmentReportColumns}
                    data={
                      (assessmentReport?.investment_report?.length > 0 &&
                        assessmentReport?.investment_report) ||
                      []
                    }
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive int_table">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-hover"}
                                bordered={false}
                                striped={true}
                                responsive
                                ref={this.node}
                                filter={filterFactory()}
                                selectRow={selectRow}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2"></Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.modalUpdate}
          toggle={() => this.toggleUpdateModel()}
          size="md"
        >
          <ModalHeader toggle={() => this.toggleUpdateModel()} tag="h4">
            Update Intent Report
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={6} className="mb-2">
                <label className="">Intent:</label>
                <div className="form-control btn-group">
                  <label className="mx-2 mb-0">
                    <input
                      type="radio"
                      name="intent"
                      value="Yes"
                      onChange={e => this.onChangeIntent(e)}
                      checked={this.state.intent === "Yes" ? "checked" : ""}
                    />{" "}
                    Yes
                  </label>
                  <label className="mx-2 mb-0">
                    <input
                      type="radio"
                      name="intent"
                      value="No"
                      onChange={e => this.onChangeIntent(e)}
                      checked={this.state.intent === "No" ? "checked" : ""}
                    />{" "}
                    No
                  </label>
                  <label className="mx-2 mb-0">
                    <input
                      type="radio"
                      name="intent"
                      value="Maybe"
                      onChange={e => this.onChangeIntent(e)}
                      checked={this.state.intent === "Maybe" ? true : ""}
                    />{" "}
                    May Be
                  </label>
                </div>
              </Col>
              <Col sm={6} className="mb-2">
                <label className="">Intent amount (in ₹):</label>
                <div className="form-group">
                  <input
                    type="number"
                    disabled={this.state.intentCurrency?.value === "USD"}
                    name="committedAmount"
                    value={this.state.committedAmount}
                    onChange={e => this.onChangeCommittedAmount(e)}
                    className="form-control"
                  />
                </div>
                <span className="text-danger">
                  Min. Ticket Size -{" "}
                  {`Rs ` +
                    amountToComma(
                      this.props?.dealData?.funding_information?.min_amount,
                      "en-IN"
                    )}
                </span>
              </Col>
              {/* <Col sm={6} className="mb-2">
                <label className="">Reference:</label>
                <div className="form-group">
                  <input
                    type="text"
                    name="reference"
                    value={this.state.reference}
                    onChange={e => this.onChangeReference(e)}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col sm={6} className="mb-2">
                <label className="">Comment:</label>
                <div className="form-group">
                  <input
                    type="text"
                    name="comment"
                    value={this.state.comment}
                    onChange={e => this.onChangeComment(e)}
                    className="form-control"
                  />
                </div>
              </Col> */}
              <Col sm={6} className="mb-2">
                <label className="">Deal Manager:</label>
                <select
                  className="form-control"
                  onChange={e => this.onChangeDealManager(e)}
                >
                  <option value="">--Select Deal Manager--</option>
                  {dealManagers &&
                    dealManagers.length > 0 &&
                    dealManagers.map((val, key) => (
                      <option
                        key={key}
                        value={val._id}
                        selected={this.state.dealManager === val._id}
                      >
                        {val.name}
                      </option>
                    ))}
                </select>
              </Col>
              <Col sm={6} className="mb-2">
                <label className="">Intent amount (in US$):</label>
                <div className="form-group">
                  <input
                    type="number"
                    disabled={this.state.intentCurrency?.value === "INR"}
                    name="dealCommittedAmount"
                    value={this.state.dealCommittedAmount}
                    onChange={e => this.onChangedealCommittedAmount(e)}
                    className="form-control"
                  />
                </div>
                <span className="text-danger">
                  Min. Ticket Size -{" "}
                  {`USD ` +
                    amountToComma(
                      this.props?.dealData?.funding_information?.min_amount_usd,
                      "en-US"
                    )}
                </span>
              </Col>
            </Row>
            {this.state.intent === "Yes" ? (
              <>
                <Row>
                  {/* <Col sm={7} className="mb-2">
                    <label className="">Intent Type:</label>
                    <div className="form-control btn-group">
                      <label className="mx-2 mb-0">
                        <input
                          type="radio"
                          name="intenttype"
                          value="individual"
                          onChange={e => this.onChangeIntentType(e)}
                          checked={
                            this.state.intentType === "individual"
                              ? "checked"
                              : ""
                          }
                        />{" "}
                        Individual/Relative
                      </label>
                      <label className="mx-2 mb-0">
                        <input
                          type="radio"
                          name="intenttype"
                          value="entity"
                          onChange={e => this.onChangeIntentType(e)}
                          checked={
                            this.state.intentType === "entity" ? "checked" : ""
                          }
                        />{" "}
                        Entity
                      </label>
                    </div>
                    {this.state?.check_intenttype ? (
                      <span style={{ color: "red" }}>
                        {this.state?.check_intenttype}
                      </span>
                    ) : (
                      ""
                    )}
                  </Col> */}

                  {this.state.intentType === "individual" ? (
                    <>
                      {/* <Col sm={4} className="mb-2"> */}
                      <Col sm={12} className="mb-2">
                        <label className="">Relation:</label>
                        <div className="">
                          <Select
                            options={this.state?.investorKYCOptions || []}
                            value={this.state?.investorKYCOptions?.find(
                              option =>
                                option?.value ===
                                this.state?.relativeOptionValue
                            )}
                            onChange={option => {
                              this.setState({
                                relativeOptionValue: option?.value,
                                relation: option?.relation,
                                applicant_pan_number:
                                  option?.applicant_pan_number,
                                passport_number: option?.passport_number,
                                country_code: COUNTRY_CODES?.find(
                                  item => item?.value === option?.country_code
                                ),
                                contact_number: option?.contact_number,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                {this.state.intentType === "individual" &&
                this.state.relation !== "Self" ? (
                  <>
                    {this?.props?.fundDetails?.fund_type?.currency_details
                      ?.value === "INR" ? (
                      <Col sm={6} className="mb-2">
                        <label className="">Relative PAN Number:</label>
                        <div className="form-group">
                          <input
                            disabled
                            type="text"
                            name="applicant_pan_number"
                            value={this.state.applicant_pan_number}
                            onChange={e => this.onChangeApplicantPanNumber(e)}
                            className="form-control"
                          />
                        </div>
                        {this.state?.check_applicant_pan_number ? (
                          <span style={{ color: "red" }}>
                            {this.state?.check_applicant_pan_number}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                    ) : (
                      <Col sm={6} className="mb-2">
                        <label className="">Relative Passport Number:</label>
                        <div className="form-group">
                          <input
                            disabled
                            type="text"
                            name="passport_number"
                            value={this.state.passport_number}
                            // onChange={e => this.onChangeApplicantPanNumber(e)}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    )}
                    {/* <Row>
                      <Col sm={5} className="mb-2">
                        <label className="">Country Code:</label>
                        <CountryMobileCode
                          id="countryCode"
                          placeholder="Select"
                          // options={this.state.country_code}
                          value={this.state.country_code}
                          name="country_code"
                          onChange={value => this.onChangeCountryCode(value)}
                          isDisabled={true}
                        />
                        {this.state?.check_country_code ? (
                          <span style={{ color: "red" }}>
                            {this.state?.check_country_code}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col sm={7} className="mb-2">
                        <label className="">Mobile number:</label>
                        <div className="form-group">
                          <input
                            disabled
                            type="text"
                            name="contact_number"
                            value={this.state.contact_number}
                            onChange={e => this.onChangeContactNumber(e)}
                            className="form-control"
                          />
                        </div>
                        {this.state?.check_contact_number ? (
                          <span style={{ color: "red" }}>
                            {this.state?.check_contact_number}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row> */}
                    <Row>
                      <Col sm={12} className="mb-2">
                        <span style={{ color: "red" }}>
                          {this.state?.res_error ?? ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <ModalFooter>
              <Row>
                <Col sm={12}>
                  {this.state.intentUpdateErrors && (
                    <span style={{ color: "red" }}>
                      {this.state.intentUpdateErrors}
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  lg={9}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4px",
                  }}
                >
                  <label style={{ paddingTop: "1px", paddingRight: "4px" }}>
                    Enable Communication:
                  </label>
                  <div>
                    {" "}
                    <input
                      type="checkbox"
                      checked={this.state.notify === "No" ? false : true}
                      style={{ width: "15px", height: "15px" }}
                      onChange={e => this.onChangeNotify(e)}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  {intentUpdateButton ? (
                    <Button
                      className="btn btn-info"
                      onClick={() => this.saveDetails()}
                      disabled={true}
                    >
                      <Spinner animation="border" size="sm" /> Update
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className="btn btn-info"
                      onClick={() => this.saveDetails()}
                    >
                      Update
                    </Button>
                  )}
                </Col>
              </Row>
            </ModalFooter>
            {/* <GroupsList
              usedForEvent={true}
              userRoleType={this.state.isInvestors}
              onSelectGroup={this.selectedGroups}
            /> */}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.logsModalOpen}
          toggle={() => this.toggleLogsModal()}
          size="xl"
        >
          <ModalHeader toggle={() => this.toggleLogsModal()} tag="h4">
            Intent/Consent Report Past Logs
          </ModalHeader>
          <ModalBody>
            {!isEmpty(this.state.logsModalData) && (
              <>
                <Accordion defaultActiveKey={["0", "1", "2", "3"]}>
                  {this.state.logsModalData?.added?.length > 0 && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Values Added</Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive int_table">
                          <div className="react-bootstrap-table">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Field</th>
                                  <th>Value</th>
                                  <th>Amount</th>
                                  <th>Source</th>
                                  <th>Username</th>
                                  <th>Email</th>
                                  <th>Browser</th>
                                  <th>OS</th>
                                  <th>Device</th>
                                  <th>Location</th>
                                  <th>IP</th>
                                  <th>Approved On</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.logsModalData.added.map(key => {
                                  return (
                                    <tr>
                                      <td>{key?.field}</td>
                                      <td>{key?.value}</td>
                                      <td>
                                        {formatAmount(
                                          key?.amount,
                                          key?.amount_currency
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {key?.source?.toLowerCase() ===
                                        "enablex"
                                          ? "EnableX"
                                          : key?.source?.toLowerCase() ===
                                            "zoom"
                                          ? "Zoom"
                                          : key?.source}
                                      </td>
                                      <td>{key?.username}</td>
                                      <td>{key?.email}</td>
                                      <td>{key?.browser}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {key?.os}
                                      </td>
                                      <td>{key?.device}</td>
                                      <td>{key?.location}</td>
                                      <td>{key?.ipAddress}</td>
                                      <td>
                                        {moment(
                                          key?.changedOn || key?.changed_on
                                        ).format("DD-MMM-YY hh:mm:ss A")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {this.state.logsModalData?.updated?.length > 0 && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Values Changed</Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive int_table">
                          <div className="react-bootstrap-table">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Field</th>
                                  <th>Old Value</th>
                                  <th>New Value</th>
                                  <th>Source</th>
                                  <th>Username</th>
                                  <th>Email</th>
                                  <th>Browser</th>
                                  <th>OS</th>
                                  <th>Device</th>
                                  <th>Location</th>
                                  <th>IP</th>
                                  {/* <th>Changed By</th> */}
                                  <th>Changed On</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sortDataDesc(
                                  this.state.logsModalData.updated
                                )?.map((updated, index) => {
                                  // return Object.keys(updated).map(key => {
                                  // if (
                                  //   key === "createdAt" ||
                                  //   key === "source" ||
                                  //   key === "updatedBy" ||
                                  //   key === "changedBy"
                                  // )
                                  //   return null;

                                  // if (
                                  //   key === "tax_return_filling_countries"
                                  // ) {
                                  //   let values = updated[key];
                                  //   let oldValue = values?.oldValue;
                                  //   let newValue = values?.newValue;

                                  //   if (oldValue?.length === newValue?.length)
                                  //     return null;
                                  // }

                                  // let label = LabelSwitch(key);
                                  // if (!label) return null;

                                  // let values = updated[key];
                                  // let oldValue = values.oldValue;
                                  // let newValue = values.newValue;

                                  // if (key !== "currencyConversionRate") {
                                  //   if (!Number.isNaN(oldValue)) {
                                  //     oldValue = toCommaFormat(oldValue);
                                  //   }
                                  //   if (!Number.isNaN(newValue)) {
                                  //     newValue = toCommaFormat(newValue);
                                  //   }
                                  // }

                                  return (
                                    <tr>
                                      <td>{SwitchField(updated?.field)}</td>
                                      <td>
                                        {formatAmountValue(
                                          updated?.field,
                                          updated?.oldValue,
                                          updated?.intentCurrency
                                        )}
                                      </td>
                                      <td>
                                        {formatAmountValue(
                                          updated?.field,
                                          updated?.newValue,
                                          updated?.intentCurrency
                                        )}
                                      </td>
                                      <td>{updated?.source}</td>
                                      <td>{updated?.username}</td>
                                      <td>{updated?.email}</td>
                                      <td>{updated?.browser}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {updated?.os}
                                      </td>
                                      <td>{updated?.device}</td>
                                      <td>{updated?.location}</td>
                                      <td>{updated?.ipAddress}</td>
                                      <td>
                                        {moment(updated?.changed_on).format(
                                          this.state.logsDateFormat
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {this.state.logsModalData?.removed?.length > 0 && (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Values Removed</Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive int_table">
                          <div className="react-bootstrap-table">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Field</th>
                                  <th>Value</th>
                                  <th>Amount</th>
                                  <th>Source</th>
                                  <th>Username</th>
                                  <th>Email</th>
                                  <th>Browser</th>
                                  <th>OS</th>
                                  <th>Device</th>
                                  <th>Location</th>
                                  <th>IP</th>
                                  <th>Dropped On</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.logsModalData.removed.map(key => {
                                  return (
                                    <tr>
                                      <td>{key?.field}</td>
                                      <td>{key?.value}</td>
                                      <td>
                                        {formatAmount(
                                          key?.amount,
                                          key?.amount_currency
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {key?.source}
                                      </td>
                                      <td>{key?.username}</td>
                                      <td>{key?.email}</td>
                                      <td>{key?.browser}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {key?.os}
                                      </td>
                                      <td>{key?.device}</td>
                                      <td>{key?.location}</td>
                                      <td>{key?.ipAddress}</td>
                                      <td>
                                        {moment(key?.changedOn).format(
                                          "DD-MMM-YY hh:mm:ss A"
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {this.state.logsModalData?.portfolio?.updated?.length > 0 && (
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        My Portfolio - Investments
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive int_table">
                          <div
                            className="react-bootstrap-table"
                            style={{ height: "300px" }}
                          >
                            <Table>
                              <thead>
                                <tr>
                                  <th>Field</th>
                                  <th>New Value</th>
                                  <th>Investment Vehicle</th>
                                  <th>Source</th>
                                  <th>User Name</th>
                                  <th>User Email</th>
                                  {/* <th>Update Source</th> */}
                                  <th>IP</th>
                                  <th>OS</th>
                                  <th>Location</th>
                                  <th>Device</th>
                                  <th>Browser</th>
                                  <th>Changed On</th>
                                  {/* <th>Admin Email</th>
                                <th>Admin Name</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.logsModalData?.portfolio?.updated?.map(
                                  (updated, index) => {
                                    let newUpdated =
                                      formatUpdatedForDistinctRecords(updated);
                                    return Object.keys(newUpdated).map(key => {
                                      if (
                                        key === "createdAt" ||
                                        key === "source" ||
                                        key === "updatedBy" ||
                                        key === "user_details" ||
                                        // key === "on_behalf_of" ||
                                        key === "investment_vehicle" ||
                                        // key === "service_fees" ||
                                        key === "investment_detail" ||
                                        // key === "transaction_date" ||
                                        key === "investment_type" ||
                                        key === "changedBy"
                                      )
                                        return null;

                                      let label = LabelSwitch(key);
                                      if (!label) return null;

                                      let values = newUpdated[key];
                                      let source =
                                        updated["source"]?.source || "--";

                                      // let oldValue = values.oldValue;
                                      let newValue = values;
                                      let amount_value = "";
                                      if (
                                        label === "Amount Invested" ||
                                        label === "Service Fees"
                                      ) {
                                        amount_value = formatAmount(
                                          newValue,
                                          key?.investment_detail?.currency
                                        );
                                      } else if (label === "Transaction Date") {
                                        amount_value = moment(newValue).format(
                                          this.state.logsDateFormat
                                        );
                                      } else {
                                        amount_value = newValue;
                                      }
                                      return (
                                        <tr>
                                          <td>{`${label}`}</td>
                                          <td>{amount_value}</td>
                                          <td>
                                            {newUpdated["investment_vehicle"]}
                                          </td>
                                          <td style={{ whiteSpace: "nowrap" }}>
                                            {source}
                                          </td>
                                          <td>
                                            {updated?.source?.adminUsername}
                                          </td>
                                          <td>{updated?.source?.adminEmail}</td>
                                          {/* <td>
                                        {updated?.user_details?.user_name}
                                      </td>
                                      <td>
                                        {updated?.user_details?.user_email}
                                      </td> */}
                                          <td>
                                            {updated?.source?.adminIpAddress ||
                                              ""}
                                          </td>
                                          {/* <td>{updated?.source?.newValue}</td> */}
                                          {/* <td>{updated?.source?.adminUsername}</td>
                                      <td>{updated?.source?.adminEmail}</td> */}
                                          <td style={{ whiteSpace: "nowrap" }}>
                                            {updated?.source?.adminOsName || ""}
                                          </td>
                                          <td>
                                            {updated?.source?.adminLocation ||
                                              ""}
                                          </td>
                                          <td>
                                            {updated?.source?.adminDeviceName ||
                                              ""}
                                          </td>
                                          <td>
                                            {updated?.source
                                              ?.adminBrowserName || ""}
                                          </td>
                                          <td>
                                            {moment(
                                              updated["createdAt"]
                                            ).format(this.state.logsDateFormat)}
                                          </td>
                                        </tr>
                                      );
                                    });
                                  }
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  {this.state.logsModalData?.my_portfolio_deleted?.length >
                    0 && (
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        My Portfolio - Deleted Investments
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive int_table">
                          <div
                            className="react-bootstrap-table"
                            style={{ height: "300px" }}
                          >
                            <Table>
                              <thead>
                                <tr>
                                  <th>Amount Invested</th>
                                  <th>Service Fee</th>
                                  <th>On Behalf Of</th>
                                  <th>Investment Vehicle</th>
                                  <th>Transaction Date</th>
                                  <th>Verified By Founder</th>
                                  <th>Source</th>
                                  <th>Username</th>
                                  <th>Email</th>
                                  <th>Browser</th>
                                  <th>OS</th>
                                  <th>Device</th>
                                  <th>Location</th>
                                  <th>IP</th>
                                  <th>Changed On</th>
                                  {/* <th>Admin Email</th>
                                <th>Admin Name</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.logsModalData?.my_portfolio_deleted?.map(
                                  (item, index) => {
                                    let currency_symbol =
                                      item?.["intent_currency"]?.[
                                        "currencySymbol"
                                      ] || "";
                                    return (
                                      <tr>
                                        <td>
                                          {`${currency_symbol}${toCommaFormat(
                                            item["Amount Invested"]
                                          )}`}
                                        </td>
                                        <td>
                                          {`${currency_symbol}${toCommaFormat(
                                            item["Service Fee"]
                                          )}`}
                                        </td>
                                        {/* <td>{item["Amount Invested"]}</td>
                                        <td>{item["Service Fee"]}</td> */}
                                        <td>{item["On Behalf Of"]}</td>
                                        <td>{item["Investment Vehicle"]}</td>
                                        <td>
                                          {moment(
                                            item["Transaction Date"]
                                          ).format(this.state.logsDateFormat)}
                                        </td>
                                        <td>
                                          {item["Verified By Founder"]
                                            ? "Verified"
                                            : "Not Verified"}
                                        </td>
                                        <td>{item["Source"]}</td>
                                        <td>{item["Username"]}</td>
                                        <td>{item["Email"]}</td>
                                        <td>{item["Browser"]}</td>
                                        <td>{item["OS"]}</td>
                                        <td>{item["Device"]}</td>
                                        <td>{item["Location"]}</td>
                                        <td>{item["IP"]}</td>
                                        <td>
                                          {moment(item["Deleted On"]).format(
                                            this.state.logsDateFormat
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </>
            )}
            {(isEmpty(this.state.logsModalData) ||
              Object.values(this.state.logsModalData).every(
                value => value?.length === 0
              )) && (
              <Row className="text-center">
                <Col>
                  <Label tag="h4">Past Logs Not Available</Label>
                </Col>
              </Row>
            )}
            <ModalFooter>
              <Row>
                <Col lg={12}>
                  <Button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.setLogsModal(false)}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalDrawDownDrop}
          toggle={() => this.toggleDrawDownDropModal()}
          size="md"
        >
          <ModalHeader toggle={() => this.toggleDrawDownDropModal()} tag="h4">
            Drawdown Drop Status
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12} className="mb-2">
                <label className="">Drop Status</label>
                <div className="form-group">
                  <Select
                    placeholder={"Select Drop Status"}
                    name="drawdown_drop_status"
                    options={this.drawdownDropOptions}
                    onChange={e => this.handleDrawDownDropStatus(e)}
                    value={this.drawdownDropOptions.filter(
                      item => item.value === this.state.drawdown_drop_status
                    )}
                  />
                </div>
              </Col>
              {this.state.drawdown_drop_status === "yes" && (
                <Col sm={12} className="mb-2 mt-2">
                  <label className="">
                    If you want to drop the drawdown, you need to drop the
                    consent as well. Want to drop the consent?
                  </label>
                  <div className="form-group">
                    <Select
                      placeholder={"Select Drop Status"}
                      name="drawdown_consent_drop_status"
                      options={this.drawdownConsentDropOptions}
                      onChange={e => this.handleDrawDownConsentDropStatus(e)}
                      value={this.drawdownConsentDropOptions?.filter(
                        item =>
                          item.value === this.state.drawdown_consent_drop_status
                      )}
                    />
                  </div>
                </Col>
              )}
              {this.state.drawdownDropErrors?.drawdown_drop && (
                <p className="text-danger">
                  {" "}
                  {this.state.drawdownDropErrors?.drawdown_drop}{" "}
                </p>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col
                lg={9}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <label style={{ paddingTop: "1px", paddingRight: "4px" }}>
                  Enable Communication:
                </label>
                <div>
                  {" "}
                  <input
                    type="checkbox"
                    checked={this.state.notify_drawdown === "No" ? false : true}
                    style={{ width: "15px", height: "15px" }}
                    onChange={e => this.onChangeNotifyDrawdown(e)}
                  />
                </div>
              </Col>
              <Col lg={3} style={{ paddingLeft: "0px" }}>
                {this.state.drawdownDropButton ? (
                  <Button
                    className="btn btn-info"
                    onClick={this.updateDrawDownDropStatus}
                    disabled={true}
                  >
                    <Spinner animation="border" size="sm" /> Update
                  </Button>
                ) : (
                  <Button
                    type="button"
                    disabled={
                      this.state.drawdown_drop_status === "yes" &&
                      this.state.drawdown_consent_drop_status === "no"
                    }
                    className="btn btn-info"
                    onClick={this.updateDrawDownDropStatus}
                  >
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalConsentAmountUpdate}
          toggle={() => this.toggleUpdateConsentAmountModal()}
          size="md"
        >
          <ModalHeader
            toggle={() => this.toggleUpdateConsentAmountModal()}
            tag="h4"
          >
            Send Consent Amount
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12} className="mb-2">
                <label className="">Enter Consent Amount (in ₹):</label>
                <div className="form-group">
                  <input
                    type="number"
                    disabled={
                      this.state.intentCurrency?.value === "USD" ||
                      this.state.termsAccept === true
                    }
                    name="consent_amount"
                    value={
                      this.state?.consent_amount || this.state?.committedAmount
                    }
                    onChange={e => this.setConsentAmount(e)}
                    className="form-control"
                    min={1}
                  />
                </div>
                {this.state.consentUpdateErrors?.consent_amount && (
                  <p className="text-danger">
                    {" "}
                    {this.state.consentUpdateErrors?.consent_amount}{" "}
                  </p>
                )}
              </Col>
              <Col sm={12} className="mb-2">
                <label className="">Enter Consent amount (in US$):</label>
                <div className="form-group">
                  <input
                    type="number"
                    disabled={
                      this.state.intentCurrency?.value === "INR" ||
                      this.state.termsAccept === true
                    }
                    name="consent_amount_global"
                    value={
                      this.state?.dollarConsentAmount ||
                      this.state?.dealCommittedAmount
                    }
                    onChange={e => this.onChangeDollarConsentAmount(e)}
                    className="form-control"
                  />
                </div>
                {this.state.consentUpdateErrors?.dollarConsentAmount && (
                  <p className="text-danger">
                    {" "}
                    {this.state.consentUpdateErrors?.dollarConsentAmount}{" "}
                  </p>
                )}
              </Col>
              <Col sm={12} className="mb-2">
                <label className="">Drop Status</label>
                <div className="form-group">
                  <Select
                    placeholder={"Select Drop Status"}
                    name="drop_status"
                    options={this.consentDropOptions}
                    onChange={e => this.handleConsentDropStatus(e)}
                    value={this.consentDropOptions.filter(
                      item => item.value === this.state.consent_drop_status
                    )}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col lg={12}>
                {this.state.consentUpdateErrors?.consent_update_error && (
                  <p className="text-danger">
                    {" "}
                    {this.state.consentUpdateErrors?.consent_update_error}{" "}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                lg={9}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <label style={{ paddingTop: "1px", paddingRight: "4px" }}>
                  Enable Communication:
                </label>
                <div>
                  {" "}
                  <input
                    type="checkbox"
                    checked={this.state.notify_consent === "No" ? false : true}
                    style={{ width: "15px", height: "15px" }}
                    onChange={e => this.onChangeNotifyConsent(e)}
                  />
                </div>
              </Col>
              <Col lg={3} style={{ paddingLeft: "0px" }}>
                {this.state.consentUpdateButton ? (
                  <Button
                    className="btn btn-info"
                    onClick={this.updateConsentForInvestor}
                    disabled={true}
                  >
                    <Spinner animation="border" size="sm" /> Update
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="btn btn-info"
                    onClick={this.updateConsentForInvestor}
                  >
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

AssessmentReport.propTypes = {
  onGetPitchDays: PropTypes.func,
  onGetAssessmentReport: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  onGetPostEventList: PropTypes.func,
  onStoreOtherDetails: PropTypes.func,
  postEvents: PropTypes.array,
  appliedFundingDealId: PropTypes.string,
  assessmentReport: PropTypes.object,
  storeOtherDetails: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    storeOtherDetails: state?.pitchdays?.storeOtherDetails,
    assessmentReport:
      state?.appliedFunding?.assessmentReportSuccess?.assessmentReport,
    investmentReportLoading: state?.appliedFunding?.assessmentReportLoading,
    postEvents: state.pitchdays.postEvents,
    error: state.pitchdays.errorPostEvent,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetPostEventList: async () => await dispatch(getPostEventList()),
  onStoreOtherDetails: async payload =>
    await dispatch(storeOtherDetails(payload)),
  onGetAssessmentReport: async payload =>
    await dispatch(getAssessmentReport(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssessmentReport));
