// import React from 'react';
// // import './Details.css'

// export default ({ user }) => {
//   const userDetails = <div>
//     <div className="UserName">Name: {user ? user.login : null}</div>
//     <div className="UserId">Id: {user ? user.id : null}</div>
//     <img className="UserAvatar" src={user? user.avatar_url : null} />
//   </div>
//   return <div className="UserDetails">
//     <h1>User Details</h1>
//     {user ? userDetails : null}
//   </div>
// }

import React, { Component } from 'react';
import { render } from 'react-dom';
import { Col, Form, Button, Figure, Dropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Details from './Details';
// import './index.css';


function Chat() {
    // console.log(conversation, "chat")
    return (
        <div className="messages-sec">
          <div className="column-card">
            <header className="timeline-box justify-content-between">
              <div className="pro-outer-sec">
                <figure className="figure"><img alt="" src="https://wfc-development.s3.ap-south-1.amazonaws.com/documents/456e0952-0d13-4335-b92a-d89dc88417ab.jpeg" className="figure-img img-fluid" /></figure>
                <div className="contect-sec">
                  <h3 className="title-xxs">Praveen Kumar</h3>
                </div>
              </div>
            </header>
            <div className="message-chat-box cus-scr"></div>
            <footer className="chatfooter">
              <form className="">
                <div className="form-group">
                  <input placeholder="Type a message" type="text" className="form-control" />
                  {/* <button type="submit" className="btn btn-add"><img alt="" src="http://3.109.78.246:3000/img/message-sbt-ico.png" /></button> */}
                  <button type="submit" color="primary"
className="font-16 btn-block btn btn-primary">Send</button>
               
                </div>
              </form>
            </footer>
          </div>
        </div>
    
        
      );;
  }

export default Chat;
