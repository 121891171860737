import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { toast, ToastContainer } from "react-toastify";
import {
  Input,
  Button,
  Collapse,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import axios from "axios";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { isEmpty } from "lodash";
import { encryptDataToArray } from "helpers/EncryptDecrypt";
import Dropdown from "react-multilevel-dropdown";
import { CSVLink } from "react-csv";

const AdminSettings = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [showData, setShowData] = useState(true);
  const [showUser, setShowUser] = useState({});
  const [userRole, setUserRole] = useState();
  const [userRoleId, setUserRoleId] = useState();
  const [modal, setModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState("");
  const [search, setSearch] = useState("");
  const [startupCollapse, setStartupCollapse] = useState(true);
  const [userCollapse, setUserCollapse] = useState(true);
  const [eventCollapse, setEventCollapse] = useState(true);
  const [postCollapse, setPostCollapse] = useState(true);
  const [ComLetterCollapse, setComLetterCollapse] = useState(true);
  const [demoCollapse, setDemoCollapse] = useState(true);
  const [otherCollapse, setOtherCollapse] = useState(true);
  const [reportCollapse, setReportCollapse] = useState(true);
  const [subscriptionCollapse, setSubscriptionCollapse] = useState(true);
  const [roleUsers, setRoleUsers] = useState([]);

  const saas_parent_id = JSON.parse(localStorage.getItem("authUser"))?.userInfo
    ?.saas_parent_id;
  const is_super_admin = JSON.parse(localStorage.getItem("authUser"))?.userInfo
    ?.is_super_admin;

  useEffect(() => {
    getAllAccessSettings();
    // if(userRole==undefined || userRole==""){
    //   handleRoleSubmitDefault();
    // }
    return () => {};
  }, []);

  const getAllAccessSettings = () => {
    setLoading(true);
    // axios.get(`${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access?saas_parent_id=${saas_parent_id}`, {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/role/getroles`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      })
      .then(res => {
        setData(res.data.roleWiseAccess);

        if (isEmpty(showUser)) {
          setShowUser(res.data.roleWiseAccess[0]);
          setUserRole(res.data.roleWiseAccess[0]?.name);
          setUserRoleId(res.data.roleWiseAccess[0]?._id);
        }
        setLoading(false);
      })
      .catch(err => {
        // console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const handelChange = (e, value) => {
    let data = {};
    data[value] = e.target.checked;

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/api/admin/role/updateby-roleid/${userRoleId}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
      data,
    };

    axios(config)
      .then(function (response) {
        getAllAccessSettings();
        setShowUser(response.data.role);
        toast.success("Updated Successfully");
      })
      .catch(function (error) {
        // console.log(error);
        toast.error(error.response.data.message);
      });

    // logic for update role wise access
  };

  const toggle = (e, id) => {
    setUserRole(e);
    setUserRoleId(id);
    data &&
      data.map(data => {
        data._id === id ? setShowUser(data) : "";
      });
    setShowData(true);
  };

  useEffect(() => {
    data &&
      data.map(data => {
        setShowUser(data);
      });

    return () => {};
  }, []);

  useEffect(() => {
    if (userRoleId) {
      fetchRoleUsers(userRoleId)
    }
  }, [userRoleId])

  const fetchRoleUsers = async (roleid) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/admin/role/getroleusers/${roleid}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };

    axios(config)
      .then(response => {
        setRoleUsers(response?.data?.roleUsers || [])
      })
      .catch(error => {
        setRoleUsers([])
      });
  }

  const handleRoleSubmit = e => {
    e.preventDefault();
    if (roleName == "") {
      setRoleNameError("Role name cannot be blank");
      return false;
    }

    let payload = {
      name: roleName,
      saas_parent_id: saas_parent_id,
    }

    let encryptedQuery = encryptDataToArray(payload)
    let reqData = {
      q: JSON.stringify(encryptedQuery)
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/admin/role/create`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
      data: reqData,
    };

    axios(config)
      .then(response => {
        setModal(false);
        getAllAccessSettings();
        toast.success("New Role Created Successfully!");
      })
      .catch(error => {
        console.log({ error });
        setModal(false);
        toast.error(error.response.data.message);
      });
  };

  const handleChange = e => {
    setRoleName(e.target.value.toUpperCase());
    if (e.target.value == "") {
      setRoleNameError("Role name cannot be blank");
    } else {
      setRoleNameError("");
    }
  };

  const handleDeleteRole = () => {
    const cnfrm = confirm("Are you sure?");
    if (!cnfrm) {
      return false;
    }
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/api/admin/role/deleteby-roleid/${userRoleId}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };

    axios(config)
      .then(function (response) {
        setUserRoleId(response?.data?.role?._id);
        getAllAccessSettings();
        setShowUser(response.data.role);
        toast.success("Deleted Successfully");
      })
      .catch(function (error) {
        console.log({ error });
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <MetaTags>
        <title>Admin Settings | Invstt - Admin</title>
      </MetaTags>

      <ToastContainer />

      {modal ? (
        <div>
          <Modal isOpen={modal} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)}>Add Role</ModalHeader>
            <ModalBody>
              <Label for="exampleEmail">Name</Label>
              <Input
                type="text"
                value={roleName}
                onChange={e => handleChange(e)}
                required
                name="role"
                placeholder="Enter the role name"
                autoCapitalize="on"
                autoComplete="off"
              />
              <span className="text-danger">
                {roleNameError != "" ? roleNameError : ""}
              </span>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleRoleSubmit}>
                Save
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
      ) : (
        ""
      )}

      <Card>
        <CardBody>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {data?.map(data => {
                if (data._id == userRoleId) {
                  return (
                    <Button
                      onClick={e => {
                        toggle(data.name, data._id);
                      }}
                      style={{
                        color: "green",
                        marginBottom: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      {data.name}
                    </Button>
                  );
                }
                return (
                  <Button
                    color="white"
                    onClick={e => {
                      toggle(data.name, data._id);
                    }}
                    style={{
                      border: "1px solid gray",
                      marginBottom: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    {data.name}
                  </Button>
                );
              })}
            </div>
            <div>
              <Button
                color="primary"
                onClick={() => {
                  setModal(true);
                }}
                style={{
                  marginBottom: "1rem",
                  marginRight: "1rem",
                  justifyContent: "end",
                }}
              >
                Add Role
              </Button>
            </div>
          </div>



          <Collapse isOpen={showData}>
            <hr />

            {showUser && (
              <>
                {/* Top Div */}
                <div style={{ backgroundColor: "whitesmoke", margin: "20px" }}>
                  {/* <Collapse isOpen={startupCollapse}> */}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      backgroundColor: "whitesmoke",
                    }}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div
                      style={{
                        fontSize: "medium",
                        padding: "20px",
                        width: "25%",
                      }}
                    >
                      <Input
                        type="checkbox"
                        onChange={e => {
                          handelChange(e, "show_all_saas_data");
                        }}
                        checked={showUser && showUser["show_all_saas_data"]}
                      />{" "}
                      <span>Show all Syndicate Data</span>
                    </div>
                    {/* {roleUsers?.length > 0 && is_super_admin && (
                      <div className="mx-4 d-flex flex-row gap-3"
                        style={{
                          width: '35%',
                        }}>
                        <select
                          className="form-control"
                        >
                          {roleUsers?.map((el) => (
                            <option value={`${el?.userName}`}>{el?.userName || ""} ({el?.email})</option>
                          ))}
                        </select>
                        <CSVLink
                          data={roleUsers}
                          headers={[
                            {
                              key: "userName",
                              label: "User Name",
                            },
                            {
                              key: "email",
                              label: "Email",
                            },
                            {
                              key: "roleName",
                              label: "Role Name",
                            },
                          ]}
                          filename={`Admin Access List.csv`}
                        >
                          <Button color="info" outline>
                            Export&nbsp;CSV
                          </Button>
                        </CSVLink>
                      </div>
                    )} */}
                  </div>

                  {/* </Collapse> */}
                </div>

                {/* <div className="mx-4">
                  <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
                      Startups <div>&gt;</div>
                      <Dropdown.Submenu position="right" className="dropdown_item">
                        <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "startup_list");
                              }}
                              checked={showUser && showUser["startup_list"]}
                            />
                            &nbsp;Startup List
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["startup_list"] && (
                            <Dropdown.Submenu position="right" className="dropdown_item">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "startupListEditAction");
                                    }}
                                    checked={showUser && showUser["startupListEditAction"]}
                                  />
                                  &nbsp;Edit Startup List
                                </div></Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "exportStartupList");
                                    }}
                                    checked={showUser && showUser["exportStartupList"]}
                                  />
                                  &nbsp;Export Startups
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "startup_kpi");
                              }}
                              checked={showUser && showUser["startup_kpi"]}
                            />
                            &nbsp;Startup KPIs
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["startup_kpi"] && (
                            <Dropdown.Submenu position="right" className="dropdown_item">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "startupKpiEditAction");
                                    }}
                                    checked={showUser && showUser["startupKpiEditAction"]}
                                  />
                                  &nbsp;Edit Startup List
                                </div></Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "applied_funding_list");
                              }}
                              checked={showUser && showUser["applied_funding_list"]}
                            />
                            &nbsp;Startup Rounds
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["applied_funding_list"] && (
                            <Dropdown.Submenu position="right" className="dropdown_item">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "cummulativeStartupDataExportCSVButton");
                                    }}
                                    checked={showUser && showUser["cummulativeStartupDataExportCSVButton"]}
                                  />
                                  &nbsp;Export Cummulative Startup Data CSV
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "fundingDealListViewEye");
                                    }}
                                    checked={showUser && showUser["fundingDealListViewEye"]}
                                  />
                                  &nbsp;View Funding Deals
                                </div>
                                <div>&gt;</div>
                                {showUser && showUser["fundingDealListViewEye"] && (
                                  <Dropdown.Submenu position="right" className="dropdown_item">
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "applying");
                                          }}
                                          checked={showUser && showUser["applying"]}
                                        />
                                        &nbsp;Applying/Draft
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "applied_for");
                                          }}
                                          checked={showUser && showUser["applied_for"]}
                                        />
                                        &nbsp;Applied For
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "deal_live");
                                          }}
                                          checked={showUser && showUser["deal_live"]}
                                        />
                                        &nbsp;Deal Live
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "deal_rejected");
                                          }}
                                          checked={showUser && showUser["deal_rejected"]}
                                        />
                                        &nbsp;Deal Rejected
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "closed_internally_funded");
                                          }}
                                          checked={showUser && showUser["closed_internally_funded"]}
                                        />
                                        &nbsp;Closed - Internally Funded
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "closed_externally_funded");
                                          }}
                                          checked={showUser && showUser["closed_externally_funded"]}
                                        />
                                        &nbsp;Closed - Externally Funded
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Submenu>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "view_deal_basic_details");
                                    }}
                                    checked={showUser && showUser["view_deal_basic_details"]}
                                  />
                                  &nbsp;Basic Details
                                </div>
                                <div>&gt;</div>
                                {showUser && showUser["view_deal_basic_details"] && (
                                  <Dropdown.Submenu position="right" className="dropdown_item">
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "update_funding_deal_status");
                                          }}
                                          checked={showUser && showUser["update_funding_deal_status"]}
                                        />
                                        &nbsp;Update Deal Status
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "update_term_sheet_status");
                                          }}
                                          checked={showUser && showUser["update_term_sheet_status"]}
                                        />
                                        &nbsp;Update Term Sheet
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "copy_to_another_saas");
                                          }}
                                          checked={showUser && showUser["copy_to_another_saas"]}
                                        />
                                        &nbsp;Copy to Another Syndicate
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Submenu>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "intent_report");
                                    }}
                                    checked={showUser && showUser["intent_report"]}
                                  />
                                  &nbsp;Investor Report
                                </div>
                                <div>&gt;</div>
                                {showUser && showUser["intent_report"] && (
                                  <Dropdown.Submenu position="right" className="dropdown_item">
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "intent_report_edit");
                                          }}
                                          checked={showUser && showUser["intent_report_edit"]}
                                        />
                                        &nbsp;Intent Report Edit
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "investor_report_import_csv");
                                          }}
                                          checked={showUser && showUser["investor_report_import_csv"]}
                                        />
                                        &nbsp;Import CSV
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "investor_report_export_csv");
                                          }}
                                          checked={showUser && showUser["investor_report_export_csv"]}
                                        />
                                        &nbsp;Export CSV
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "export_interested_user_csv");
                                          }}
                                          checked={showUser && showUser["export_interested_user_csv"]}
                                        />
                                        &nbsp;Export Interested User CSV
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "drop_consent_for_selected_applicant");
                                          }}
                                          checked={showUser && showUser["drop_consent_for_selected_applicant"]}
                                        />
                                        &nbsp;Drop Consent for Selected Applicant
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "intent_consent_report_logs");
                                          }}
                                          checked={showUser && showUser["intent_consent_report_logs"]}
                                        />
                                        &nbsp;Logs
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "update_consent");
                                          }}
                                          checked={showUser && showUser["update_consent"]}
                                        />
                                        &nbsp;Update Consent
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "update_drawdown_status");
                                          }}
                                          checked={showUser && showUser["update_drawdown_status"]}
                                        />
                                        &nbsp;Update Drawdown Status
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Submenu>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "q_and_a");
                                    }}
                                    checked={showUser && showUser["q_and_a"]}
                                  />
                                  &nbsp;Q&A Access
                                </div>
                                <div>&gt;</div>
                                {showUser && showUser["q_and_a"] && (
                                  <Dropdown.Submenu position="right" className="dropdown_item">
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "q_and_a_edit");
                                          }}
                                          checked={showUser && showUser["intent_report_edit"]}
                                        />
                                        &nbsp;Edit Q&A
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Submenu>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "investment_thesis");
                                    }}
                                    checked={showUser && showUser["investment_thesis"]}
                                  />
                                  &nbsp;Investment Thesis
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "fundingDealListInvestorGroups");
                                    }}
                                    checked={showUser && showUser["fundingDealListInvestorGroups"]}
                                  />
                                  &nbsp;Investor Groups
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                  <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
                      Users <div>&gt;</div>
                      <Dropdown.Submenu position="right" className="dropdown_item">
                        <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "users_list");
                              }}
                              checked={showUser && showUser["users_list"]}
                            />
                            &nbsp;Users List
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["users_list"] && (
                            <Dropdown.Submenu position="right" className="dropdown_item">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userListLoginAction");
                                    }}
                                    checked={showUser && showUser["userListLoginAction"]}
                                  />
                                  &nbsp;Login Access
                                </div></Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userListEditAction");
                                    }}
                                    checked={showUser && showUser["userListEditAction"]}
                                  />
                                  &nbsp;Edit Action
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "show_secondary_saas_details");
                                    }}
                                    checked={showUser && showUser["show_secondary_saas_details"]}
                                  />
                                  &nbsp;Secondary Syndicate Details
                                </div>
                                <div>&gt;</div>
                                {showUser && showUser["show_secondary_saas_details"] && (
                                  <Dropdown.Submenu className="dropdown_item" position="right">
                                    <Dropdown.Item className="dropdown_item">
                                      <div className="d-flex flex-row align-items-center" title="Secondary Syndicate Enable/Disable
                                        Communication">
                                        <Input
                                          type="checkbox"
                                          className="m-0"
                                          onChange={e => {
                                            handelChange(e, "secondary_syndicate_communication_access");
                                          }}
                                          checked={showUser && showUser["secondary_syndicate_communication_access"]}
                                        />
                                        &nbsp;Secondary Syndicate Enable/Disable ..
                                      </div></Dropdown.Item>
                                  </Dropdown.Submenu>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "importUsers");
                                    }}
                                    checked={showUser && showUser["importUsers"]}
                                  />
                                  &nbsp;Import Users
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "exportUsers");
                                    }}
                                    checked={showUser && showUser["exportUsers"]}
                                  />
                                  &nbsp;User Export CSV
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "founderExport");
                                    }}
                                    checked={showUser && showUser["founderExport"]}
                                  />
                                  &nbsp;Founder Export CSV
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "edit_investment_manager");
                                    }}
                                    checked={showUser && showUser["edit_investment_manager"]}
                                  />
                                  &nbsp;Edit Investment Manager
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "user_groups");
                              }}
                              checked={showUser && showUser["user_groups"]}
                            />
                            &nbsp;User Groups
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["user_groups"] && (
                            <Dropdown.Submenu position="right" className="dropdown_item">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "createNewGroup");
                                    }}
                                    checked={showUser && showUser["createNewGroup"]}
                                  />
                                  &nbsp;Create New Groups
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userGroupsMembersEdit");
                                    }}
                                    checked={showUser && showUser["userGroupsMembersEdit"]}
                                  />
                                  &nbsp;Edit Members
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userGroupsStatus");
                                    }}
                                    checked={showUser && showUser["userGroupsStatus"]}
                                  />
                                  &nbsp;Status
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userGroupsAction");
                                    }}
                                    checked={showUser && showUser["userGroupsAction"]}
                                  />
                                  &nbsp;Edit Action
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex justify-content-between dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "kyc_list");
                              }}
                              checked={showUser && showUser["kyc_list"]}
                            />
                            &nbsp;Onboarding List
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["kyc_list"] && (
                            <Dropdown.Submenu position="right" className="dropdown_item">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "export_kyc_data");
                                    }}
                                    checked={showUser && showUser["export_kyc_data"]}
                                  />
                                  &nbsp;Export KYC Data CSV
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "view_ca_reports");
                                    }}
                                    checked={showUser && showUser["view_ca_reports"]}
                                  />
                                  &nbsp;View CA Reports
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "view_kyc_logs");
                                    }}
                                    checked={showUser && showUser["view_kyc_logs"]}
                                  />
                                  &nbsp;View Logs
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "demat_list");
                              }}
                              checked={showUser && showUser["demat_list"]}
                            />
                            &nbsp;Demat List
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "user_credits");
                              }}
                              checked={showUser && showUser["user_credits"]}
                            />
                            &nbsp;Credits
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "investor_communication_access");
                              }}
                              checked={showUser && showUser["investor_communication_access"]}
                            />
                            &nbsp;Investor Communication
                          </div>
                        </Dropdown.Item>

                      </Dropdown.Submenu>
                    </Dropdown.Item>
                  <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
                      Report <div>&gt;</div>
                      <Dropdown.Submenu className="dropdown_item" position="right">
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "user_report");
                              }}
                              checked={showUser && showUser["user_report"]}
                            />
                            &nbsp;Investment User Wise
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "investment_reports");
                              }}
                              checked={showUser && showUser["investment_reports"]}
                            />
                            &nbsp;Investment Startup Wise
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["investment_reports"] && (
                            <Dropdown.Submenu className="dropdown_item" position="right">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "investmentReportsViewEye");
                                    }}
                                    checked={showUser && showUser["investmentReportsViewEye"]}
                                  />
                                  &nbsp;View Investments
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "payout_report");
                              }}
                              checked={showUser && showUser["payout_report"]}
                            />
                            &nbsp;Partners Payout
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "event");
                              }}
                              checked={showUser && showUser["event"]}
                            />
                            &nbsp;Event List
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["event"] && (
                            <Dropdown.Submenu className="dropdown_item" position="right">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "eventAttendanceReport");
                                    }}
                                    checked={showUser && showUser["eventAttendanceReport"]}
                                  />
                                  &nbsp;Attendance Report
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "eventGenerateReport");
                                    }}
                                    checked={showUser && showUser["eventGenerateReport"]}
                                  />
                                  &nbsp;Generate Report
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                  <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
                      Resources <div>&gt;</div>
                      <Dropdown.Submenu className="dropdown_item" position="right">
                        <Dropdown.Item className="dropdown_item justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "communication_letter");
                              }}
                              checked={showUser && showUser["communication_letter"]}
                            />
                            &nbsp;Weekly Brew
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["communication_letter"] && (
                            <Dropdown.Submenu className="dropdown_item" position="right">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "communication_letter_edit");
                                    }}
                                    checked={showUser && showUser["communication_letter_edit"]}
                                  />
                                  &nbsp;Edit
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "zoom");
                              }}
                              checked={showUser && showUser["zoom"]}
                            />
                            &nbsp;Generate Zoom Token
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "demo_day");
                              }}
                              checked={showUser && showUser["demo_day"]}
                            />
                            &nbsp;Demo Day
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["demo_day"] && (
                            <Dropdown.Submenu className="dropdown_item" position="right">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "demo_day_edit");
                                    }}
                                    checked={showUser && showUser["demo_day_edit"]}
                                  />
                                  &nbsp;Demo Day Edit
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "currency_conversion");
                              }}
                              checked={showUser && showUser["currency_conversion"]}
                            />
                            &nbsp;Currency Conversion Matrix
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "job_posting_behalf_of");
                              }}
                              checked={showUser && showUser["job_posting_behalf_of"]}
                            />
                            &nbsp;Job Posting On Behalf Of
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "upload_media");
                              }}
                              checked={showUser && showUser["upload_media"]}
                            />
                            &nbsp;Upload Media
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "job_approval");
                              }}
                              checked={showUser && showUser["job_approval"]}
                            />
                            &nbsp;Job Approval
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "job_edit");
                              }}
                              checked={showUser && showUser["job_edit"]}
                            />
                            &nbsp;Job Edit
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Submenu>
                    </Dropdown.Item>
                  <Dropdown.Item className="d-flex justify-content-between dropdown_item dropdown_border">
                      Posts <div>&gt;</div>
                      <Dropdown.Submenu className="dropdown_item" position="right">
                        <Dropdown.Item className="dropdown_item justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "general_posts");
                              }}
                              checked={showUser && showUser["general_posts"]}
                            />
                            &nbsp;Admin Posts List
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["general_posts"] && (
                            <Dropdown.Submenu className="dropdown_item" position="right">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "createAdminPost");
                                    }}
                                    checked={showUser && showUser["createAdminPost"]}
                                  />
                                  &nbsp;Create New Post
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "adminPostStatus");
                                    }}
                                    checked={showUser && showUser["adminPostStatus"]}
                                  />
                                  &nbsp;Post Status
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "adminPostEditAction");
                                    }}
                                    checked={showUser && showUser["adminPostEditAction"]}
                                  />
                                  &nbsp;Edit Action
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item justify-content-between">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "user_posts");
                              }}
                              checked={showUser && showUser["user_posts"]}
                            />
                            &nbsp;User Posts List
                          </div>
                          <div>&gt;</div>
                          {showUser && showUser["user_posts"] && (
                            <Dropdown.Submenu className="dropdown_item" position="right">
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userPostStatus");
                                    }}
                                    checked={showUser && showUser["userPostStatus"]}
                                  />
                                  &nbsp;Post Status
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userPostEditAction");
                                    }}
                                    checked={showUser && showUser["userPostEditAction"]}
                                  />
                                  &nbsp;Edit Action
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown_item">
                                <div className="d-flex flex-row align-items-center">
                                  <Input
                                    type="checkbox"
                                    className="m-0"
                                    onChange={e => {
                                      handelChange(e, "userPostExport");
                                    }}
                                    checked={showUser && showUser["userPostExport"]}
                                  />
                                  &nbsp;Export Posts
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Submenu>
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown_item">
                          <div className="d-flex flex-row align-items-center">
                            <Input
                              type="checkbox"
                              className="m-0"
                              onChange={e => {
                                handelChange(e, "report_posts");
                              }}
                              checked={showUser && showUser["report_posts"]}
                            />
                            &nbsp;Report Posts
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Submenu>
                  </Dropdown.Item>
                </div> */}
                {/* Startups */}
                <div style={{ backgroundColor: "whitesmoke", margin: "20px" }}>
                  <Button
                    onClick={() => {
                      setStartupCollapse(!startupCollapse);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    Startups {startupCollapse ? "▲" : "▼"}
                  </Button>
                  <Collapse isOpen={startupCollapse}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "startup_list");
                          }}
                          checked={showUser && showUser["startup_list"]}
                        />
                        <span> Startup List</span>
                        {showUser && showUser["startup_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "startupListEditAction");
                              }}
                              checked={
                                showUser && showUser["startupListEditAction"]
                              }
                            />
                            <span>
                              {" "}
                              Edit Startup List{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "exportStartupList");
                              }}
                              checked={
                                showUser && showUser["exportStartupList"]
                              }
                            />
                            <span> Export Startups</span>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "startup_kpi");
                          }}
                          checked={showUser && showUser["startup_kpi"]}
                        />
                        <span> Startup KPIs</span>
                        {showUser && showUser["startup_kpi"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "startupKpiEditAction");
                              }}
                              checked={
                                showUser && showUser["startupKpiEditAction"]
                              }
                            />
                            <span>
                              {" "}
                              Edit Startup List{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "27%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "applied_funding_list");
                          }}
                          checked={showUser && showUser["applied_funding_list"]}
                        />
                        {/* <span> Funding Deals List</span> */}
                        <span> Startup Rounds</span>
                        {showUser?.["applied_funding_list"] && (
                          <>
                            <div
                              style={{
                                fontSize: "medium",
                                paddingLeft: "20px",
                              }}
                            >
                              <Input
                                type="checkbox"
                                onChange={e => {
                                  handelChange(
                                    e,
                                    "cummulativeStartupDataExportCSVButton"
                                  );
                                }}
                                checked={
                                  showUser?.[
                                    "cummulativeStartupDataExportCSVButton"
                                  ]
                                }
                              />
                              <span>
                                {" "}
                                Export Cummulative Startup Data CSV{" "}
                                <i className="bx bx-export text-primary font-size-18"></i>
                              </span>
                            </div>
                          </>
                        )}

                        {showUser && showUser["applied_funding_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "fundingDealListViewEye");
                              }}
                              checked={
                                showUser && showUser["fundingDealListViewEye"]
                              }
                            />
                            <span>
                              {" "}
                              View Funding Deals{" "}
                              <i className="mdi mdi-eye text-success font-size-18"></i>
                            </span>
                            {showUser && showUser["fundingDealListViewEye"] && (
                              <div
                                style={{
                                  fontSize: "medium",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "applying");
                                  }}
                                  checked={showUser && showUser["applying"]}
                                />
                                <span> Applying/Draft</span> <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "applied_for");
                                  }}
                                  checked={showUser && showUser["applied_for"]}
                                />
                                <span> Applied For</span>
                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "deal_live");
                                  }}
                                  checked={showUser && showUser["deal_live"]}
                                />
                                <span> Deal Live</span>
                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "deal_rejected");
                                  }}
                                  checked={
                                    showUser && showUser["deal_rejected"]
                                  }
                                />
                                <span> Deal Rejected</span>
                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "closed_internally_funded");
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["closed_internally_funded"]
                                  }
                                />
                                <span> Closed - Internally Funded</span>
                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "closed_externally_funded");
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["closed_externally_funded"]
                                  }
                                />
                                <span> Closed - Externally Funded</span>
                                <br />
                              </div>
                            )}
                          </div>
                        )}

                        {showUser && showUser["applied_funding_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "view_deal_basic_details");
                              }}
                              checked={
                                showUser && showUser["view_deal_basic_details"]
                              }
                            />
                            <span> Basic Details</span>
                            {showUser && showUser["view_deal_basic_details"] && (
                              <>
                                <div
                                  style={{
                                    fontSize: "medium",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <Input
                                    type="checkbox"
                                    onChange={e => {
                                      handelChange(
                                        e,
                                        "update_funding_deal_status"
                                      );
                                    }}
                                    checked={
                                      showUser &&
                                      showUser["update_funding_deal_status"]
                                    }
                                  />
                                  <span> Update Deal Status </span>
                                </div>
                                <div
                                  style={{
                                    fontSize: "medium",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <Input
                                    type="checkbox"
                                    onChange={e => {
                                      handelChange(
                                        e,
                                        "update_term_sheet_status"
                                      );
                                    }}
                                    checked={
                                      showUser &&
                                      showUser["update_term_sheet_status"]
                                    }
                                  />
                                  <span> Update Term Sheet </span>
                                </div>
                                <div
                                  style={{
                                    fontSize: "medium",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <Input
                                    type="checkbox"
                                    onChange={e => {
                                      handelChange(e, "copy_to_another_saas");
                                    }}
                                    checked={
                                      showUser &&
                                      showUser["copy_to_another_saas"]
                                    }
                                  />
                                  <span> Copy to Another Syndicate </span>
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {showUser && showUser["applied_funding_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "intent_report");
                              }}
                              checked={showUser && showUser["intent_report"]}
                            />
                            <span> Investor Report</span>
                            {showUser && showUser["intent_report"] && (
                              <div
                                style={{
                                  fontSize: "medium",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "intent_report_edit");
                                  }}
                                  checked={
                                    showUser && showUser["intent_report_edit"]
                                  }
                                />
                                <span>
                                  {" "}
                                  Intent Report Edit{" "}
                                  <i className="mdi mdi-pencil text-success font-size-18"></i>
                                </span>

                                <br />

                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(
                                      e,
                                      "investor_report_import_csv"
                                    );
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["investor_report_import_csv"]
                                  }
                                />
                                <span> Import CSV</span>

                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(
                                      e,
                                      "investor_report_export_csv"
                                    );
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["investor_report_export_csv"]
                                  }
                                />
                                <span> Export CSV</span>

                                <br />

                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(
                                      e,
                                      "export_interested_user_csv"
                                    );
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["export_interested_user_csv"]
                                  }
                                />
                                <span> Export Interested User CSV</span>

                                <br />

                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(
                                      e,
                                      "drop_consent_for_selected_applicant"
                                    );
                                  }}
                                  checked={
                                    showUser &&
                                    showUser[
                                      "drop_consent_for_selected_applicant"
                                    ]
                                  }
                                />
                                <span>
                                  {" "}
                                  Drop Consent for Selected Applicant
                                </span>

                                <br />

                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(
                                      e,
                                      "intent_consent_report_logs"
                                    );
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["intent_consent_report_logs"]
                                  }
                                />
                                <span> Logs </span>
                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "update_consent");
                                  }}
                                  checked={
                                    showUser && showUser["update_consent"]
                                  }
                                />
                                <span> Update Consent</span>
                                <br />
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "update_drawdown_status");
                                  }}
                                  checked={
                                    showUser &&
                                    showUser["update_drawdown_status"]
                                  }
                                />
                                <span> Update Drawdown Status</span>
                              </div>
                            )}
                          </div>
                        )}

                        {showUser && showUser["applied_funding_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "q_and_a");
                              }}
                              checked={showUser && showUser["q_and_a"]}
                            />
                            <span> Q&A Access</span>
                            {showUser && showUser["q_and_a"] && (
                              <div
                                style={{
                                  fontSize: "medium",
                                  paddingLeft: "20px",
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  onChange={e => {
                                    handelChange(e, "q_and_a_edit");
                                  }}
                                  checked={showUser && showUser["q_and_a_edit"]}
                                />
                                <span>
                                  {" "}
                                  Edit Q&A{" "}
                                  <i className="mdi mdi-pencil text-success font-size-18"></i>
                                </span>
                              </div>
                            )}
                          </div>
                        )}

                        {showUser && showUser["applied_funding_list"] && (
                          <div
                            style={{
                              fontSize: "medium",
                              paddingLeft: "20px",
                            }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "investment_thesis");
                              }}
                              checked={
                                showUser && showUser["investment_thesis"]
                              }
                            />
                            <span> Investment Thesis </span>
                          </div>
                        )}

                        {showUser && showUser["applied_funding_list"] && (
                          <div
                            style={{
                              fontSize: "medium",
                              paddingLeft: "20px",
                            }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(
                                  e,
                                  "fundingDealListInvestorGroups"
                                );
                              }}
                              checked={
                                showUser &&
                                showUser["fundingDealListInvestorGroups"]
                              }
                            />
                            <span> Investor Groups</span>
                          </div>
                        )}
                      </div>

                      {/* <div style={{ fontSize: 'medium', padding: '20px', width: '20%' }} >
                        <Input type="checkbox" onChange={(e) => { handelChange(e, 'investment_reports') }} checked={showUser && showUser['investment_reports']} /><span> Investment Reports</span>
                        {showUser && showUser['investment_reports'] &&
                          <div style={{ fontSize: 'medium', paddingLeft: '20px' }}>
                            <Input type="checkbox" onChange={(e) => { handelChange(e, 'investmentReportsViewEye') }} checked={showUser && showUser['investmentReportsViewEye']} /><span> View Investments</span>
                          </div>
                        }
                      </div> */}
                    </div>
                  </Collapse>
                </div>

                {/* Users */}
                <div style={{ backgroundColor: "whitesmoke", margin: "20px" }}>
                  <Button
                    onClick={() => {
                      setUserCollapse(!userCollapse);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    Users {userCollapse ? "▲" : "▼"}
                  </Button>
                  <Collapse isOpen={userCollapse}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "22%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "users_list");
                          }}
                          checked={showUser && showUser["users_list"]}
                        />
                        <span> Users List</span>
                        {showUser && showUser["users_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userListLoginAction");
                              }}
                              checked={
                                showUser && showUser["userListLoginAction"]
                              }
                            />
                            <span className="">
                              {" "}
                              Login Access{" "}
                              <i className="mdi mdi-share text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userListEditAction");
                              }}
                              checked={
                                showUser && showUser["userListEditAction"]
                              }
                            />
                            <span className="">
                              {" "}
                              Edit Action{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "show_secondary_saas_details");
                              }}
                              checked={
                                showUser &&
                                showUser["show_secondary_saas_details"]
                              }
                            />
                            <span>
                              {" "}
                              Secondary Syndicate Details{" "}
                              <i className="mdi mdi-puzzle-edit text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                            {showUser &&
                              showUser["show_secondary_saas_details"] && (
                                <div
                                  style={{
                                    fontSize: "medium",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <Input
                                    type="checkbox"
                                    onChange={e => {
                                      handelChange(
                                        e,
                                        "secondary_syndicate_communication_access"
                                      );
                                    }}
                                    checked={
                                      showUser &&
                                      showUser[
                                        "secondary_syndicate_communication_access"
                                      ]
                                    }
                                  />
                                  <span>
                                    {" "}
                                    Secondary Syndicate Enable/Disable
                                    Communication
                                  </span>{" "}
                                  <br />
                                </div>
                              )}
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "importUsers");
                              }}
                              checked={showUser && showUser["importUsers"]}
                            />
                            <span> Import Users</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "exportUsers");
                              }}
                              checked={showUser && showUser["exportUsers"]}
                            />
                            <span> User Export CSV</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "founderExport");
                              }}
                              checked={showUser && showUser["founderExport"]}
                            />
                            <span> Founder Export CSV</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "edit_investment_manager");
                              }}
                              checked={
                                showUser && showUser["edit_investment_manager"]
                              }
                            />
                            <span> Edit Investment Manager</span> <br />
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "22%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "user_groups");
                          }}
                          checked={showUser && showUser["user_groups"]}
                        />
                        <span> User Groups</span>
                        {showUser && showUser["user_groups"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "createNewGroup");
                              }}
                              checked={showUser && showUser["createNewGroup"]}
                            />
                            <span> Create New Groups</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userGroupsMembersEdit");
                              }}
                              checked={
                                showUser && showUser["userGroupsMembersEdit"]
                              }
                            />
                            <span>
                              {" "}
                              Edit Members{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userGroupsStatus");
                              }}
                              checked={showUser && showUser["userGroupsStatus"]}
                            />
                            <span> Status</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userGroupsAction");
                              }}
                              checked={showUser && showUser["userGroupsAction"]}
                            />
                            <span>
                              {" "}
                              Edit Action{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                          </div>
                        )}
                      </div>

                      {/* <div style={{ fontSize: 'medium', padding: '20px', width: '20%' }} >
                        <Input type="checkbox" onChange={(e) => { handelChange(e, 'partners_list') }} checked={showUser && showUser['partners_list']} /><span> Partners</span>
                        {showUser && showUser['partners_list'] && ""
                        }
                      </div> */}

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "kyc_list");
                          }}
                          checked={showUser && showUser["kyc_list"]}
                        />
                        <span> Onboarding List</span>
                        {showUser && showUser["kyc_list"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "export_kyc_data");
                              }}
                              checked={showUser && showUser["export_kyc_data"]}
                            />
                            <span> Export KYC Data CSV</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "view_ca_reports");
                              }}
                              checked={showUser && showUser["view_ca_reports"]}
                            />
                            <span> View CA Reports</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "view_kyc_logs");
                              }}
                              checked={showUser && showUser["view_kyc_logs"]}
                            />
                            <span> View Logs</span> <br />
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "demat_list");
                          }}
                          checked={showUser && showUser["demat_list"]}
                        />
                        <span> Demat List</span>
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "15%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "user_credits");
                          }}
                          checked={showUser && showUser["user_credits"]}
                        />
                        <span> Credits</span>
                        {showUser && showUser["user_credits"] && ""}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "investor_communication_access");
                          }}
                          checked={
                            showUser &&
                            showUser["investor_communication_access"]
                          }
                        />
                        <span> Investor Communication</span>
                        {showUser && showUser["investor_communication_access"] && (
                          <div
                            style={{
                              fontSize: "medium",
                              paddingLeft: "20px",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>

                {/* user report */}
                <div style={{ backgroundColor: "whitesmoke", margin: "20px" }}>
                  <Button
                    onClick={() => {
                      setReportCollapse(!reportCollapse);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    Report {reportCollapse ? "▲" : "▼"}
                  </Button>
                  <Collapse isOpen={reportCollapse}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "user_report");
                          }}
                          checked={showUser && showUser["user_report"]}
                        />
                        <span> Investment User Wise</span>
                        {showUser && showUser["user_report"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "investment_reports");
                          }}
                          checked={showUser && showUser["investment_reports"]}
                        />
                        <span> Investment Startup Wise</span>
                        {showUser && showUser["investment_reports"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "investmentReportsViewEye");
                              }}
                              checked={
                                showUser && showUser["investmentReportsViewEye"]
                              }
                            />
                            <span>
                              {" "}
                              View Investments{" "}
                              <i className="mdi mdi-eye text-success font-size-18"></i>
                            </span>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "payout_report");
                          }}
                          checked={showUser && showUser["payout_report"]}
                        />
                        <span> Partners Payout</span>
                        {showUser && showUser["payout_report"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "event");
                          }}
                          checked={showUser && showUser["event"]}
                        />
                        <span> Event List</span>
                        {showUser && showUser["event"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "eventAttendanceReport");
                              }}
                              checked={
                                showUser && showUser["eventAttendanceReport"]
                              }
                            />
                            <span> Attendance Report</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "eventGenerateReport");
                              }}
                              checked={
                                showUser && showUser["eventGenerateReport"]
                              }
                            />
                            <span> Generate Report</span> <br />
                            <br />
                          </div>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>

                {/* Resources */}
                <div style={{ backgroundColor: "whitesmoke", margin: "20px" }}>
                  <Button
                    onClick={() => {
                      setOtherCollapse(!otherCollapse);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    Resources {otherCollapse ? "▲" : "▼"}
                  </Button>
                  <Collapse isOpen={otherCollapse}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      {/* <div style={{ fontSize: 'medium', padding: '20px', width: '20%' }} >
                        <Input type="checkbox" onChange={(e) => { handelChange(e, 'mentoships') }} checked={showUser && showUser['mentoships']} /><span> Mentorship List</span>
                        {showUser && showUser['mentoships'] &&
                          <div style={{ fontSize: 'medium', paddingLeft: '20px' }}>
                          </div>
                        }
                      </div> */}

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "communication_letter");
                          }}
                          checked={showUser && showUser["communication_letter"]}
                        />{" "}
                        <span>Weekly Brew</span>
                        {showUser && showUser["communication_letter"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "communication_letter_edit");
                              }}
                              checked={
                                showUser &&
                                showUser["communication_letter_edit"]
                              }
                            />
                            <span>
                              {" "}
                              Edit{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "zoom");
                          }}
                          checked={showUser && showUser["zoom"]}
                        />
                        <span> Generate Zoom Token</span>
                        {showUser && showUser["zoom"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "15%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "demo_day");
                          }}
                          checked={showUser && showUser["demo_day"]}
                        />{" "}
                        <span>Demo Day</span>
                        {showUser && showUser["demo_day"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "demo_day_edit");
                              }}
                              checked={showUser && showUser["demo_day_edit"]}
                            />
                            <span>
                              {" "}
                              Edit{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "currency_conversion");
                          }}
                          checked={showUser && showUser["currency_conversion"]}
                        />
                        <span> Currency Conversion Matrix</span>
                        {showUser && showUser["currency_conversion"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "job_posting_behalf_of");
                          }}
                          checked={
                            showUser && showUser["job_posting_behalf_of"]
                          }
                        />
                        <span> Job Posting On Behalf Of</span>
                        {showUser && showUser["job_posting_behalf_of"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "upload_media");
                          }}
                          checked={showUser && showUser["upload_media"]}
                        />
                        <span> Upload Media</span>
                        {showUser && showUser["upload_media"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "job_approval");
                          }}
                          checked={showUser && showUser["job_approval"]}
                        />
                        <span> Job Approval</span>
                        {showUser && showUser["job_approval"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "16%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "job_edit");
                          }}
                          checked={showUser && showUser["job_edit"]}
                        />
                        <span> Job Edit</span>
                        {showUser && showUser["job_edit"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>

                {/* Posts */}
                <div style={{ backgroundColor: "whitesmoke", margin: "20px" }}>
                  <Button
                    onClick={() => {
                      setPostCollapse(!postCollapse);
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    Posts {postCollapse ? "▲" : "▼"}
                  </Button>
                  <Collapse isOpen={postCollapse}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "general_posts");
                          }}
                          checked={showUser && showUser["general_posts"]}
                        />
                        <span> Admin Posts List</span>
                        {showUser && showUser["general_posts"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "createAdminPost");
                              }}
                              checked={showUser && showUser["createAdminPost"]}
                            />
                            <span> Create New Post</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "adminPostStatus");
                              }}
                              checked={showUser && showUser["adminPostStatus"]}
                            />
                            <span> Post Status</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "adminPostEditAction");
                              }}
                              checked={
                                showUser && showUser["adminPostEditAction"]
                              }
                            />
                            <span>
                              {" "}
                              Edit Action{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "user_posts");
                          }}
                          checked={showUser && showUser["user_posts"]}
                        />
                        <span> User Posts List</span>
                        {showUser && showUser["user_posts"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          >
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userPostStatus");
                              }}
                              checked={showUser && showUser["userPostStatus"]}
                            />
                            <span> Post Status</span> <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userPostEditAction");
                              }}
                              checked={
                                showUser && showUser["userPostEditAction"]
                              }
                            />
                            <span>
                              {" "}
                              Edit Action{" "}
                              <i className="mdi mdi-pencil text-success font-size-18"></i>
                            </span>{" "}
                            <br />
                            <Input
                              type="checkbox"
                              onChange={e => {
                                handelChange(e, "userPostExport");
                              }}
                              checked={showUser && showUser["userPostExport"]}
                            />
                            <span> Export Posts</span> <br />
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "medium",
                          padding: "20px",
                          width: "20%",
                        }}
                      >
                        <Input
                          type="checkbox"
                          onChange={e => {
                            handelChange(e, "report_posts");
                          }}
                          checked={showUser && showUser["report_posts"]}
                        />
                        <span> Report Posts</span>
                        {showUser && showUser["report_posts"] && (
                          <div
                            style={{ fontSize: "medium", paddingLeft: "20px" }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>

                {/* Subscription */}
                {/* <div style={{ backgroundColor: 'whitesmoke', margin: '20px' }}>
                  <Button onClick={() => { setSubscriptionCollapse(!subscriptionCollapse) }} style={{ width: '100%', textAlign: 'left', fontSize: '16px' }}>Subscription {reportCollapse ? '▲' : '▼'}</Button>
                  <Collapse isOpen={reportCollapse}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', backgroundColor: 'whitesmoke' }}>
                      <div style={{ fontSize: 'medium', padding: '20px', width: '20%' }} >
                        <Input type="checkbox" onChange={(e) => { handelChange(e, 'subscription_list') }} checked={showUser && showUser['subscription_list']} /><span> Subscription List</span>
                        {showUser && showUser['subscription_list'] &&
                          <div style={{ fontSize: 'medium', paddingLeft: '20px' }}>
                          </div>
                        }
                      </div>

                    </div>
                  </Collapse>
                </div> */}

                {/* <div style={{ fontSize: 'medium', paddingLeft: '20px' }} >
                  <Input type="checkbox" onChange={(e) => { handelChange(e, 'general_master') }} checked={showUser && showUser['general_master']} /><span> General Master</span>
                  {showUser && showUser['general_master'] &&
                    <div style={{ fontSize: 'medium', paddingLeft: '20px' }}>
                    </div>
                  }
                </div> */}

                {/* <div style={{ fontSize: 'medium', padding: '20px', width: '20%' }} >
              <Input type="checkbox" onChange={(e) => { handelChange(e, 'access_setting') }} checked={showUser['access_setting']} /><span> Access Setting</span>
              {showUser['access_setting'] &&
                <div style={{ fontSize: 'medium', paddingLeft: '20px' }}>
                </div>
              }
            </div> */}

                {/* <div style={{ display: 'flex', flexWrap: 'wrap', height: '50%' }}>
              {Object.keys(showUser).map(value => {

                if (value !== 'createdAt' && value !== 'name' && value !== 'updatedAt' && value !== '__v' && value !== '_id') {
                  if (value.includes(search)) {
                    return (
                      <tr className='access-checkbox' style={{ width: '33%', fontSize: 'medium' }}>
                        <td>
                          <Input type="checkbox" onChange={(e) => { handelChange(e, value) }} checked={showUser[value]} />
                        </td>
                        <td>
                          &nbsp;&nbsp;
                        </td>
                        <td>
                          {value[0]?.toUpperCase() + value?.slice(1)?.replaceAll('_', ' ').split(/(?=[A-Z])/)?.join(' ')}
                        </td>
                      </tr>
                    )
                  }
                }
              })}
            </div> */}
                <div style={{ margin: "20px", width: "100%" }}>
                  <Button color="danger" onClick={handleDeleteRole}>
                    Delete
                  </Button>
                </div>
              </>
            )}
          </Collapse>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminSettings;
