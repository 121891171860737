import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "reactstrap";

const TopInvestors = ({ investorData = [] }) => {
  return (
    <div className="column-sec">
      <Row className="mb-1 align-items-center">
        <Col md="8">
          <h3 className="mb-3 mt-3 title-sm">Top Investors</h3>
        </Col>
        <Col md="4">
          <div className="achlink">
            <a href="/users-list">
              All Investors{" "}
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                />
              </svg>
            </a>
          </div>
        </Col>
      </Row>
      <div className="table-responsive dashtable">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Investor Name</th>
              <th>Total Investment</th>
              <th>No. of Deals</th>
            </tr>
          </thead>
          <tbody>
            {investorData?.map((investor, index) => (
              <tr key={`${index}-investorData`}>
                <td>{investor?.fullname}</td>
                <td>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(investor?.totalFunds)}
                </td>
                <td>{investor?.totalDealsCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TopInvestors;
