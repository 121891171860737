import React from "react";
import { useDropzone } from "react-dropzone";
import profile from "../assets/images/upload.png";
import addButton from "../assets/images/edit-ico.svg";

export default function DragDropRounded(props) {
  const {
    //acceptedFiles,
    //fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    maxSize: "5242880",
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        let acceptable = ["jpg", "jpeg", "png"];
        if (acceptable.includes(acceptedFiles[0].path.split(".")[1])) {
          props.setFile({ ...props.file, file: acceptedFiles });

          // props.setFileErrors("");
        }
      } else {
        console.log("FILE REJECTIONS>>>>>>>>>", fileRejections);
        props.setFileErrors("Image must be smaller than 5MB");
      }
    },
  });

  return (
    <div className="upload-image text-center">
      <div
        className="file-upload-form d-flex"
        style={{ justifyContent: "center" }}
      >
        <div {...getRootProps({ className: "dropzone-rounded drop-100" })}>
          <input {...getInputProps()} multiple={false} />
          <label htmlFor="file-upload2" id="file-drag">
            <img
              style={{
                margin: "auto",
                // objectFit: "cover",
                display: "block",
                height: "180px",
                // maxWidth: "200px",
              }}
              src={props?.src ? props.src : profile}
              alt=""
              id={props.prevId || "prev-img"}
            />
          </label>
          <div style={{ marginTop: "-15px" }}>
            <span>
              <strong>{props.titleText}</strong>
              {/* <br /> */}
              {/* <small>Supported file : JPG, JPEG, PNG (max 5.0 MB)</small> */}
              {typeof props.section !== "undefined" &&
                props.section === "investor_startup_profile" && (
                  <small>Recommended Size : 60*60px</small>
                )}
            </span>
          </div>
          <button
            type="button"
            className="btn btn-remove"
            style={{
              position: "absolute",
              left: "10%",
              top: "0px",
            }}
            title="Edit Image"
          >
            <img src={addButton} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}
