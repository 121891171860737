import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
} from "reactstrap";
import { Form as Form1, Accordion } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

import {
  getPayoutReports,
  getAllPartnersForEmployees,
  updatePartnerUserInfoUserList,
  getAllUsersForReference,
  getPartnerAdditionalDetails,
  getAllInvestmentVehicles,
  getAllVPPayoutEnabledSyndicates,
  verifyAdminEmailOTP,
} from "../../helpers/backend_helper";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { loginAsUser } from "../../helpers/backend_helper";

// Loader for data fetch
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  formatNumberToCommaSeparatedCurrency,
  getReferralInvestmentSettingsKey,
  getSelfInvestmentSettingsKey,
} from "helpers/common";

const CURRENCY_MAP = {
  USD: {
    value: "USD",
    label: "United States Dollar",
    symbol: "US$",
    locale: "en-US",
  },
  INR: {
    value: "INR",
    label: "Indian Rupee",
    symbol: "₹",
    locale: "en-IN",
  },
};

const PayoutReport = () => {
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortQuery, setSortQuery] = useState("createdAt=-1");
  const upArrow = <i className="bx bx-up-arrow-alt fs-5"></i>;
  const downArrow = <i className="bx bx-down-arrow-alt fs-5"></i>;
  const sortOptions = [
    {
      value: "createdAt=-1",
      label: (
        <>
          <span>{downArrow} Created On</span>
        </>
      ),
    },
    {
      value: "createdAt=1",
      label: (
        <>
          <span>{upArrow} Created On</span>
        </>
      ),
    },
    {
      value: "name=-1",
      label: (
        <>
          <span>{downArrow} Name</span>
        </>
      ),
    },
    {
      value: "name=1",
      label: (
        <>
          <span>{upArrow} Name</span>
        </>
      ),
    },
    {
      value: "email=-1",
      label: (
        <>
          <span>{downArrow} Email</span>
        </>
      ),
    },
    {
      value: "email=1",
      label: (
        <>
          <span>{upArrow} Email</span>
        </>
      ),
    },
    {
      value: "status=-1",
      label: (
        <>
          <span>{downArrow} Status</span>
        </>
      ),
    },
    {
      value: "status=1",
      label: (
        <>
          <span>{downArrow} Status</span>
        </>
      ),
    },
  ];

  const [savedFlag, setSavedFlag] = useState(false);

  const [venturePartnersList, setVenturePartnersList] = useState([]);

  const [userData, setUserData] = useState({});
  const [editModal, setEditModal] = useState(false);

  const [allRefPartners, setAllRefPartners] = useState([]);
  const [selectedRefPartner, setSelectedRefPartner] = useState(null);

  const [saasPartnersList, setSaasPartnersList] = useState([]);

  const [expandedRows, setExpandedRows] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment().set({ month: 3, date: 1, year: 2022 }).format()
    ),
    endDate: new Date(moment().format()),
  });
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [details, setDetails] = useState({});

  const [rangefilter, setRangefilter] = useState(0);

  const [investmentVehicles, setInvestmentVehicles] = useState([]);
  const [partnerSettingsError, setPartnerSettingsError] = useState([]);
  const [vpPayoutEnabledSyndicates, setVPPayoutEnabledSyndicates] = useState(
    []
  );

  const fetchAllInvestmentVehicles = async (currencies = []) => {
    try {
      let data = await getAllInvestmentVehicles();

      if (!data?.status) {
        setInvestmentVehicles([]);
        return;
      }

      setInvestmentVehicles(data?.investmentVehicles || []);
    } catch (error) {
      setInvestmentVehicles([]);
    }
  };

  const fetchVPPayoutEnabledSyndicates = async (req, res) => {
    try {
      let data = await getAllVPPayoutEnabledSyndicates();

      if (!data?.status) {
        setVPPayoutEnabledSyndicates([]);
        return;
      }

      setVPPayoutEnabledSyndicates(data?.vpPayoutEnabledSyndicates || []);
    } catch (error) {
      setVPPayoutEnabledSyndicates([]);
    }
  };

  const fetchPartnerDetails = async userId => {
    if (!userId) {
      return;
    }
    try {
      setDetailsLoading(true);

      let response = await getPartnerAdditionalDetails({
        userId,
        fromDate: moment(dateRange.startDate)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        toDate: moment(dateRange.endDate)
          .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
          .format(),
      });

      if (!response?.status) {
        let message =
          response?.message || "Error while fetching partner details";
        setDetails({});
        setDetailsLoading(false);
        alert(message);
        return;
      }

      let data = response?.data || {};
      setDetails(data);
      setDetailsLoading(false);
      return;
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching partner details";
      setDetails({});
      setDetailsLoading(false);
      alert(message);
      return;
    }
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const closeModalSuccess = () => {
    setUserData({});
    setSavedFlag(!savedFlag);
  };

  useEffect(() => {
    fetchAllInvestmentVehicles();
    fetchVPPayoutEnabledSyndicates();
    return () => {};
  }, []);

  useEffect(() => {
    setLoading(true);
    getPayoutReports(
      page,
      limit,
      searchText,
      sortQuery,
      dateRange.startDate,
      dateRange.endDate
    )
      .then(response => {
        if (response?.status) {
          let { venturePartners, totalData } = response.data;
          setVenturePartnersList(venturePartners);
          setTotal(totalData);
        } else {
          setVenturePartnersList([]);
          setTotal(0);
          alert(response?.message || "Error while fetching venture partners");
        }
      })
      .catch(error => {
        setVenturePartnersList([]);
        setTotal(0);
        alert(error?.message || "Error while fetching venture partners");
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, [page, limit, searchText, sortQuery, savedFlag, rangefilter]);

  useEffect(() => {
    if (!editModal) {
      getAllPartnersForEmployees()
        .then(response => {
          let partners = response.data || [];
          partners = partners
            .map(partner => {
              if (!partner) return null;
              // let {
              //   _id: partnerId,
              //   user_id: { _id, userName, firstname, lastname, email },
              // } = partner;
              let {
                _id,
                userName,
                firstname,
                lastname,
                email,
                is_saas_parent,
                saas_fullname,
                saas_name,
                saas_alias,
              } = partner;
              if (!_id) return null;
              let temp =
                `${firstname || ""} ${lastname || ""}`.trim() || userName;
              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }
              if (!temp) return null;

              if (is_saas_parent) {
                let temp2 = saas_fullname;
                if (temp2) {
                  temp2 += ` (${saas_name})`;
                } else {
                  temp2 = saas_alias;
                }
                temp = temp2 || temp;
              }

              return { value: _id, label: temp };
            })
            .filter(data => data);
          setAllRefPartners(partners);
        })
        .catch(error => {
          console.trace(error);
          setAllRefPartners([]);
        });

      return () => {};
    }

    return () => {};
  }, [editModal]);

  useEffect(() => {
    if (!editModal) {
      getAllUsersForReference()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let { _id, firstname, lastname, email } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                saasPartners.push({
                  value: _id,
                  label: temp,
                });
              }

              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          // setReferenceUserList(users);
          setSaasPartnersList(saasPartners);
        })
        .catch(error => {
          console.trace(error);
          // setReferenceUserList([]);
          setSaasPartnersList([]);
        });

      return () => {};
    }

    return () => {};
  }, [editModal]);

  const handleUserLogin = async user => {
    try {
      let flag = await verifyAdminEmailOTP();

      if (!flag) {
        alert("Error while logging in to the user.");
        return;
      }

      //login admin as user
      let url = process.env.REACT_APP_FRONTEND_URL;
      if (!url) {
        url = window.location.protocol + "//" + window.location.hostname;
      }

      let loggedUser = await loginAsUser({ userId: user._id });

      if (loggedUser) {
        window.open(url + "/loginAsUser/" + loggedUser.token);
      }
    } catch (err) {
      // console.log(err.response);
      return false;
    }
  };

  const handleUserClick = async user => {
    if (!user?.partnerData) {
      return alert("Partner data cannot be edited");
    }

    let partnerData = {
      ...(user?.partnerData || {}),
      user: user,
    };
    setUserData(partnerData);

    let userId = user?._id;
    let tempList = saasPartnersList.filter(user => user.value !== userId);
    setSaasPartnersList(tempList);

    // setAllRefPartners(
    //   allRefPartners.filter(partner => partner?.value !== userId)
    // );

    let tempRefPartner = {
      value: null,
      label: "Select Partner",
    };
    let value = user?.partnerData?.reference_user_id?.[0] || null;
    if (value) {
      let label = allRefPartners.find(part => part?.value === value)?.label;
      if (!label) {
        value = null;
        label = "Select Partner";
      }
      tempRefPartner = { value, label };
    }
    setSelectedRefPartner(tempRefPartner?.value ? tempRefPartner : null);

    setEditModal(true);
  };

  const defaultSorted = [
    {
      dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const userListColumns = [
    {
      text: "id",
      dataField: "_id",
      sort: false,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      text: "Name",
      dataField: "firstname",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.firstname} {user.lastname}
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "saas_parent",
      text: "Domain Expert Name",
      sort: false,
      formatter: (cellContent, user) => {
        let value = user?.saas_parent?.saas_name;

        if (user?.is_saas_parent) {
          value = `Parent - ${user?.saas_name}`;
        }
        return <>{value}</>;
      },
    },
    {
      dataField: "user_types[0].user_type[0]",
      text: "User Type",
      sort: false,
      formatter: (cellContent, user) => (
        <div className="text-capitalize">
          {user?.user_types
            ?.map(userType => userType?.user_type?.[0] || "")
            .filter(data => !!data)
            .join(", ")}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, user) => {
        let status = user?.status?.toLowerCase();
        if (status === "declined") status = "disabled";
        return <div className="text-capitalize">{status}</div>;
      },
    },
    {
      dataField: "payout.totalPayoutAmount",
      text: "Total Payout (Amt)",
      sort: false,
      formatter: (cellContent, user) => {
        let totalPayoutAmount = user?.payout?.totalPayoutAmount || {};
        let cellValue = Object.keys(totalPayoutAmount || {})
          ?.map(value =>
            formatNumberToCommaSeparatedCurrency(
              totalPayoutAmount?.[value],
              CURRENCY_MAP[value]
            )
          )
          ?.filter(data => !!data);

        return (
          <>
            {!!cellValue?.length ? (
              <span
                dangerouslySetInnerHTML={{ __html: cellValue?.join("<br />") }}
              ></span>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      dataField: "payout.totalPendingAmount",
      text: "Payout pending (Amt)",
      sort: false,
      formatter: (cellContent, user) => {
        let totalPendingAmount = user?.payout?.totalPendingAmount || {};
        let cellValue = Object.keys(totalPendingAmount || {})
          ?.map(value =>
            formatNumberToCommaSeparatedCurrency(
              totalPendingAmount?.[value],
              CURRENCY_MAP[value]
            )
          )
          ?.filter(data => !!data);

        return (
          <>
            {!!cellValue?.length ? (
              <span
                dangerouslySetInnerHTML={{ __html: cellValue?.join("<br />") }}
              ></span>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: false,
      formatter: (cellContent, user) => (
        <>{moment(user.createdAt).format("DD MMM YYYY")}</>
      ),
    },
    {
      dataField: "payouts",
      isDummyField: true,
      editable: false,
      text: "Payouts",
      formatter: (cellContent, user) => {
        return (
          <div className="d-flex flex-column gap-2">
            {!!user?.is_saas_parent && (
              <Link
                className="text-success"
                to={`/syndicate-details/${user?._id}`}
                title="Syndicate Payouts"
              >
                Syndicate
              </Link>
            )}
            {!!user?.partnerData && (
              <Link
                className="text-info"
                to={`/partner-details/${user._id}`}
                title="Partner Payouts"
              >
                Partner
              </Link>
            )}
          </div>
        );
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, user) => {
        return (
          <div className="d-flex gap-2">
            <Link className="text-success" to="#" title="Login with this user">
              <i
                className="mdi mdi-share font-size-18"
                id="logintooltip"
                onClick={() => handleUserLogin(user)}
              ></i>
            </Link>
            {false && !!user?.partnerData && (
              <>
                <Link className="text-success" to="#" title="Edit User Data">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => handleUserClick(user)}
                  ></i>
                </Link>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "Next page",
    prePageTitle: "Previous page",
    firstPageTitle: "First page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
      setLimit(size);
    },
    onSizePerPageChange: (page, size) => {
      setPage(page);
      setLimit(size);
    },
    onSortChange: (...args) => {
      console.log(args);
    },
  };

  const handleValidEditPartnerSubmit = async event => {
    event.preventDefault();

    let userId = userData?.user?._id;
    if (!userId) {
      return alert("Invalid User Id");
    }

    try {
      if (!userData?.user?.is_saas_parent && !selectedRefPartner?.value) {
        return alert("Please Select Reference Partner");
      }

      if (
        partnerSettingsError?.some(settingsError =>
          Object.keys(settingsError?.settings || {})?.some(
            key => !!settingsError?.settings?.[key]
          )
        )
      ) {
        return;
      }

      if (
        userData?.user?.is_saas_parent ||
        (userData?.can_get_payout && !userData?.apply_parent_settings)
      ) {
        let level_1_settings = userData?.level_1_settings || {};
        let { subscription_fee, carry_fee } = level_1_settings;

        if (subscription_fee) {
          let { amount } = subscription_fee;
          if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
            return alert("Please enter subscription fee % between 0-100");
          }
        }

        if (carry_fee) {
          let { amount } = carry_fee;
          if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
            return alert("Please enter carry fee % between 0-100");
          }
        }

        let credits_per_lac_of_investments =
          userData?.credits_per_lac_of_investments || {};

        let { for_self, for_referral } = credits_per_lac_of_investments;

        if (for_self && parseInt(for_self) < 0) {
          return alert(
            "Please enter credits for self investments greater than 0"
          );
        }

        if (for_referral && parseInt(for_referral) < 0) {
          return alert(
            "Please enter credits for referral investments greater than 0"
          );
        }
      }

      let reqBody = {};

      reqBody["isPartner"] = true;
      reqBody["partnerType"] = "partner";
      reqBody["level_1_settings"] = userData?.level_1_settings;
      reqBody["vp_settings"] = userData?.vp_settings;
      reqBody["refPartnerId"] = selectedRefPartner?.value || null;
      // reqBody["see_all_startups"] = userData?.see_all_startups;
      // reqBody["see_all_investors"] = userData?.see_all_investors;
      reqBody["credits_per_lac_of_investments"] =
        userData?.credits_per_lac_of_investments;
      reqBody["display_collection"] = userData?.display_collection;
      reqBody["display_commision"] = userData?.display_commision;
      reqBody["can_get_payout"] = userData?.can_get_payout;
      reqBody["apply_parent_settings"] = userData?.apply_parent_settings;
      reqBody["vp_individual"] = userData?.vp_individual;
      reqBody["vp_company"] = userData?.vp_company;
      reqBody["vp_syndicate"] = userData?.vp_syndicate;
      reqBody["vp_syndicate_subuser"] = userData?.vp_syndicate_subuser;

      let response = await updatePartnerUserInfoUserList(userId, reqBody);
      let data = response?.data;
      if (data?.status) {
        alert(data?.message || "Something went wrong while updating partner");
      } else {
        alert(data?.message || "Data updated successfully");
        closeModalSuccess();
        setEditModal(false);
      }
    } catch (error) {
      console.trace(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while updating partner";
      alert(message);
    }
  };

  const handleRemovePartner = async e => {
    e.preventDefault();

    let userId = userData?.user?._id;
    if (!userId) {
      return alert("Invalid User Id");
    }

    try {
      let reqBody = {};
      reqBody["isPartner"] = false;
      let response = await updatePartnerUserInfoUserList(userId, reqBody);

      if (!response?.status) {
        return alert(response?.message || "Cannot remove partner details");
      }

      alert(response?.message || "Partner removed successfully");
      closeModalSuccess();
      toggleEditModal();
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while removing partner details";
      return alert(message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Partner List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          <Row className="">
            <Col sm="6">
              <h5 className="text-uppercase">Partners Payout</h5>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={userListColumns}
                    data={venturePartnersList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={venturePartnersList}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="">
                              <Col md={8} sm={12} className="d-block d-sm-flex">
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={4}
                                  xs={12}
                                  className="mb-2 mb-sm-0"
                                >
                                  <Select
                                    placeholder="Sort By"
                                    options={sortOptions}
                                    styles={{
                                      option: (styles, { data }) => ({
                                        ...styles,
                                      }),
                                    }}
                                    value={sortOptions.find(
                                      ({ value }) => value === sortQuery
                                    )}
                                    onChange={({ value }) => {
                                      setSortQuery(value);
                                    }}
                                  />
                                </Col>
                                <Col lg={9} md={9} sm={8} xs={12}>
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                        searchText={searchText}
                                        onSearch={setSearchText}
                                        delay={1500}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Col>
                              <Col md={4} sm={12} className="text-end">
                                <Row>
                                  <Col>
                                    <DateRangePicker
                                      initialSettings={{
                                        ...dateRange,
                                        minDate: moment().set({
                                          month: 3,
                                          date: 1,
                                          year: 2022,
                                        }),
                                        maxDate: moment(),
                                        autoApply: true,
                                        linkedCalendars: false,
                                        showDropdowns: true,
                                        ranges: {
                                          Today: [moment(), moment()],
                                          Yesterday: [
                                            moment().subtract(1, "days"),
                                            moment().subtract(1, "days"),
                                          ],
                                          "Last 7 Days": [
                                            moment().subtract(6, "days"),
                                            moment(),
                                          ],
                                          "Last 30 Days": [
                                            moment().subtract(29, "days"),
                                            moment(),
                                          ],
                                          "This Month": [
                                            moment().startOf("month"),
                                            moment().endOf("month"),
                                          ],
                                          "Last Month": [
                                            moment()
                                              .subtract(1, "month")
                                              .startOf("month"),
                                            moment()
                                              .subtract(1, "month")
                                              .endOf("month"),
                                          ],
                                          "This Year": [
                                            moment().startOf("year"),
                                            moment().endOf("year"),
                                          ],
                                          "Last Year": [
                                            moment()
                                              .subtract(1, "year")
                                              .startOf("year"),
                                            moment()
                                              .subtract(1, "year")
                                              .endOf("year"),
                                          ],
                                        },
                                      }}
                                      onApply={(event, picker) => {
                                        setDateRange({
                                          startDate: picker.startDate._d,
                                          endDate: picker.endDate._d,
                                        });
                                        setRangefilter(rangefilter + 1);
                                        setExpandedRows([]);
                                      }}
                                    >
                                      <button className="btn btn-outline-dark">
                                        {moment(dateRange.startDate).format(
                                          "DD MMM, YYYY"
                                        ) +
                                          " - " +
                                          moment(dateRange.endDate).format(
                                            "DD MMM, YYYY"
                                          )}
                                      </button>
                                    </DateRangePicker>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      pagination: true,
                                      search: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    onTableChange={() => {
                                      return false;
                                    }}
                                    responsive
                                    noDataIndication="No Partners Available" //
                                    rowStyle={row => ({
                                      backgroundColor: row?.is_saas_parent
                                        ? "lightgray"
                                        : "",
                                      fontWeight: row?.is_saas_parent
                                        ? "600"
                                        : "0",
                                    })}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>

                {/* Edit Partner Modal */}
                <Modal size="lg" isOpen={editModal} toggle={toggleEditModal}>
                  <ModalHeader toggle={toggleEditModal} tag="h4">
                    Edit Partner
                  </ModalHeader>
                  <ModalBody>
                    <Form onSubmit={handleValidEditPartnerSubmit}>
                      <Row className="text-end">
                        <Col>
                          <Button
                            className="me-3"
                            type="button"
                            color="danger"
                            size="sm"
                            onClick={handleRemovePartner}
                          >
                            Remove Partner
                          </Button>
                        </Col>
                      </Row>

                      {!userData?.user?.is_saas_parent && (
                        <>
                          <Row className="mt-3 align-items-center">
                            <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                              <Label>
                                Reference Partner{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col md={9} sm={8} xs={8} className="mt-2 mt-sm-0">
                              <Select
                                className="mt-1"
                                placeholder={"Select Partner"}
                                options={allRefPartners
                                  .filter(
                                    partner =>
                                      partner?.value !== userData?.user?._id
                                  )
                                  .sort((a, b) => {
                                    if (
                                      a?.label?.toLowerCase() <
                                      b?.label?.toLowerCase()
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.label?.toLowerCase() >
                                      b?.label?.toLowerCase()
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  })}
                                onChange={value => {
                                  setSelectedRefPartner(value);
                                }}
                                value={selectedRefPartner}
                                // isClearable
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="mt-3 align-items-middle" hidden>
                        <Col sm={6} xs={6}>
                          <Row>
                            <Col md={6} sm={8} xs={9} className="mt-2 mt-sm-0">
                              <Label>All Startups Visible</Label>
                            </Col>
                            <Col md={6} sm={4} xs={3} className="mt-2 mt-sm-0">
                              <Form1.Check
                                type="switch"
                                checked={userData?.see_all_startups}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    see_all_startups: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col sm={6} xs={6}>
                          <Row>
                            <Col md={6} sm={8} xs={9} className="mt-2 mt-sm-0">
                              <Label>All Investors Visible</Label>
                            </Col>
                            <Col md={6} sm={4} xs={3} className="mt-2 mt-sm-0">
                              <Form1.Check
                                type="switch"
                                checked={userData?.see_all_investors}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    see_all_investors: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                          <Label>VP Type</Label>
                        </Col>
                        <Col md={9} sm={8} xs={8} className="mt-2 mt-sm-0">
                          <Row>
                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-individual"
                                name="vp-individual"
                                type="radio"
                                inline
                                label="VP - Individual"
                                checked={userData?.vp_individual}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: event.target.checked,
                                    vp_company: !event.target.checked,
                                    vp_syndicate: !event.target.checked,
                                    vp_syndicate_subuser: !event.target.checked,
                                  });
                                }}
                              />
                            </Col>

                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-company"
                                name="vp-company"
                                type="radio"
                                inline
                                label="VP - Company"
                                checked={userData?.vp_company}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: !event.target.checked,
                                    vp_company: event.target.checked,
                                    vp_syndicate: !event.target.checked,
                                    vp_syndicate_subuser: !event.target.checked,
                                  });
                                }}
                              />
                            </Col>

                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-syndicate"
                                name="vp-syndicate"
                                type="radio"
                                inline
                                label="VP - Syndicate"
                                checked={userData?.vp_syndicate}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: !event.target.checked,
                                    vp_company: !event.target.checked,
                                    vp_syndicate: event.target.checked,
                                    vp_syndicate_subuser: !event.target.checked,
                                  });
                                }}
                              />
                            </Col>

                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-syndicate-subuser"
                                name="vp-syndicate-subuser"
                                type="radio"
                                inline
                                label="VP - Syndicate SubUser"
                                checked={userData?.vp_syndicate_subuser}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: !event.target.checked,
                                    vp_company: !event.target.checked,
                                    vp_syndicate: !event.target.checked,
                                    vp_syndicate_subuser: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {selectedRefPartner?.value && (
                        <>
                          <Row className="mt-3 align-items-middle" hidden>
                            <Col>
                              <Form1.Check
                                type="switch"
                                label="Display Collection"
                                checked={userData?.display_collection}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    display_collection: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                            <Col>
                              <Form1.Check
                                type="switch"
                                label="Display Commision"
                                checked={userData?.display_commision}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    display_commision: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3 align-items-middle">
                            <Col>
                              <Form1.Check
                                type="switch"
                                label="Get Payout"
                                checked={userData?.can_get_payout}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    can_get_payout: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                            {!!userData?.user?.is_saas_parent ||
                            !!userData?.can_get_payout ? (
                              <>
                                <Col>
                                  <Form1.Check
                                    type="switch"
                                    label="Apply Parent Settings"
                                    checked={userData?.apply_parent_settings}
                                    onChange={event => {
                                      setUserData({
                                        ...userData,
                                        apply_parent_settings:
                                          event.target.checked,
                                      });
                                    }}
                                  />
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </>
                      )}

                      {/* Level 1 settings */}
                      {!!userData?.user?.is_saas_parent ||
                      (!!userData?.can_get_payout &&
                        !userData?.apply_parent_settings) ? (
                        <>
                          {!!vpPayoutEnabledSyndicates?.length &&
                            !!investmentVehicles?.length && (
                              <>
                                <Row className="mt-3">
                                  <Accordion flush>
                                    {vpPayoutEnabledSyndicates?.map(
                                      (syndicate, idx) => {
                                        let {
                                          label: syndicateLabel,
                                          value: syndicateValue,
                                        } = syndicate;

                                        return (
                                          <Accordion.Item
                                            eventKey={syndicateValue}
                                          >
                                            <Accordion.Header>
                                              <span
                                                className={`${
                                                  !!partnerSettingsError?.find(
                                                    settingsError =>
                                                      settingsError?.saas_parent_id ===
                                                        syndicateValue &&
                                                      Object.keys(
                                                        settingsError?.settings ||
                                                          {}
                                                      ).some(
                                                        key =>
                                                          !!settingsError
                                                            ?.settings?.[key]
                                                      )
                                                  )
                                                    ? "text-danger"
                                                    : ""
                                                }`}
                                              >
                                                {syndicateLabel}
                                              </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <Row>
                                                <Accordion flush alwaysOpen>
                                                  {investmentVehicles?.map(
                                                    (vehicle, index) => {
                                                      let {
                                                        value,
                                                        label,
                                                        currency,
                                                        currencySymbol,
                                                      } = vehicle || {};

                                                      if (!label) {
                                                        return null;
                                                      }

                                                      let referralInvestmentFieldKey =
                                                        getReferralInvestmentSettingsKey(
                                                          label
                                                        );
                                                      let selfInvestmentFieldKey =
                                                        getSelfInvestmentSettingsKey(
                                                          label
                                                        );

                                                      return (
                                                        <Accordion.Item
                                                          eventKey={
                                                            syndicateValue +
                                                            index.toString()
                                                          }
                                                        >
                                                          <Accordion.Header>
                                                            <span
                                                              className={`${
                                                                !!partnerSettingsError?.find(
                                                                  settingsError =>
                                                                    settingsError?.saas_parent_id ===
                                                                    syndicateValue
                                                                )?.settings?.[
                                                                  referralInvestmentFieldKey
                                                                ] ||
                                                                !!partnerSettingsError?.find(
                                                                  settingsError =>
                                                                    settingsError?.saas_parent_id ===
                                                                    syndicateValue
                                                                )?.settings?.[
                                                                  selfInvestmentFieldKey
                                                                ]
                                                                  ? "text-danger"
                                                                  : ""
                                                              }`}
                                                            >{`${label} (${currencySymbol})`}</span>
                                                          </Accordion.Header>
                                                          <Accordion.Body>
                                                            <Row className="mt-3">
                                                              <Col
                                                                sm={6}
                                                                xs={12}
                                                                className="mt-2 mt-sm-0 d-flex flex-column"
                                                              >
                                                                <Label>
                                                                  Investor/Referral
                                                                  Investment
                                                                  Amount (%)
                                                                  {!!partnerSettingsError?.find(
                                                                    settingsError =>
                                                                      settingsError?.saas_parent_id ===
                                                                      syndicateValue
                                                                  )?.settings?.[
                                                                    referralInvestmentFieldKey
                                                                  ] && (
                                                                    <>
                                                                      <br />
                                                                      <span className="text-danger">
                                                                        {
                                                                          partnerSettingsError?.find(
                                                                            settingsError =>
                                                                              settingsError?.saas_parent_id ===
                                                                              syndicateValue
                                                                          )
                                                                            ?.settings?.[
                                                                            referralInvestmentFieldKey
                                                                          ]
                                                                        }
                                                                      </span>
                                                                    </>
                                                                  )}
                                                                </Label>
                                                                <Input
                                                                  type="number"
                                                                  step="any"
                                                                  min="0"
                                                                  max="100"
                                                                  placeholder="Enter % Of Investor/Referral Investment Amount"
                                                                  name={
                                                                    syndicateValue +
                                                                    referralInvestmentFieldKey
                                                                  }
                                                                  value={
                                                                    userData?.vp_settings?.find(
                                                                      syndicate =>
                                                                        syndicate?.saas_parent_id ===
                                                                        syndicateValue
                                                                    )
                                                                      ?.settings?.[
                                                                      referralInvestmentFieldKey
                                                                    ]
                                                                  }
                                                                  onChange={event => {
                                                                    let value =
                                                                      event
                                                                        .target
                                                                        .value;

                                                                    let error =
                                                                      !!value &&
                                                                      (parseInt(
                                                                        value
                                                                      ) > 100 ||
                                                                        parseInt(
                                                                          value
                                                                        ) < 0)
                                                                        ? "Please enter value between 0-100"
                                                                        : "";

                                                                    let existingErrors =
                                                                      partnerSettingsError?.find(
                                                                        settingsError =>
                                                                          settingsError?.saas_parent_id ===
                                                                          syndicateValue
                                                                      ) || {};
                                                                    existingErrors[
                                                                      "saas_parent_id"
                                                                    ] = syndicateValue;
                                                                    existingErrors[
                                                                      "settings"
                                                                    ] = {
                                                                      ...(existingErrors?.settings ||
                                                                        {}),
                                                                      [referralInvestmentFieldKey]:
                                                                        error,
                                                                    };

                                                                    setPartnerSettingsError(
                                                                      [
                                                                        ...partnerSettingsError?.filter(
                                                                          settingsError =>
                                                                            settingsError?.saas_parent_id !==
                                                                            syndicateValue
                                                                        ),
                                                                        existingErrors,
                                                                      ]
                                                                    );

                                                                    let existingSettings =
                                                                      userData?.vp_settings?.find(
                                                                        syndicate =>
                                                                          syndicate?.saas_parent_id ===
                                                                          syndicateValue
                                                                      ) || {};

                                                                    existingSettings[
                                                                      "saas_parent_id"
                                                                    ] = syndicateValue;
                                                                    existingSettings[
                                                                      "settings"
                                                                    ] = {
                                                                      ...(existingSettings?.settings ||
                                                                        {}),
                                                                      [referralInvestmentFieldKey]:
                                                                        value
                                                                          ? parseInt(
                                                                              value
                                                                            )
                                                                          : value,
                                                                    };

                                                                    setUserData(
                                                                      {
                                                                        ...userData,
                                                                        vp_settings:
                                                                          [
                                                                            ...(userData?.vp_settings?.filter(
                                                                              syndicate =>
                                                                                syndicate?.saas_parent_id !==
                                                                                syndicateValue
                                                                            ) ||
                                                                              []),
                                                                            existingSettings,
                                                                          ],
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              </Col>

                                                              {userData?.vp_individual && (
                                                                <>
                                                                  <Col
                                                                    sm={6}
                                                                    xs={12}
                                                                    className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                                                                  >
                                                                    <Label>
                                                                      Refund For
                                                                      Self-Investment
                                                                      Amount (%)
                                                                      {!!partnerSettingsError?.find(
                                                                        settingsError =>
                                                                          settingsError?.saas_parent_id ===
                                                                          syndicateValue
                                                                      )
                                                                        ?.settings?.[
                                                                        selfInvestmentFieldKey
                                                                      ] && (
                                                                        <>
                                                                          <br />
                                                                          <span className="text-danger">
                                                                            {
                                                                              partnerSettingsError?.find(
                                                                                settingsError =>
                                                                                  settingsError?.saas_parent_id ===
                                                                                  syndicateValue
                                                                              )
                                                                                ?.settings?.[
                                                                                selfInvestmentFieldKey
                                                                              ]
                                                                            }
                                                                          </span>
                                                                        </>
                                                                      )}
                                                                    </Label>
                                                                    <Input
                                                                      type="number"
                                                                      step="any"
                                                                      min="0"
                                                                      max="100"
                                                                      placeholder="Enter Transaction Fee Refund For Self-Investments (%)"
                                                                      name={
                                                                        syndicateValue +
                                                                        selfInvestmentFieldKey
                                                                      }
                                                                      value={
                                                                        userData?.vp_settings?.find(
                                                                          syndicate =>
                                                                            syndicate?.saas_parent_id ===
                                                                            syndicateValue
                                                                        )
                                                                          ?.settings?.[
                                                                          selfInvestmentFieldKey
                                                                        ]
                                                                      }
                                                                      onChange={event => {
                                                                        let value =
                                                                          event
                                                                            .target
                                                                            .value;

                                                                        let error =
                                                                          !!value &&
                                                                          (parseInt(
                                                                            value
                                                                          ) >
                                                                            100 ||
                                                                            parseInt(
                                                                              value
                                                                            ) <
                                                                              0)
                                                                            ? "Please enter value between 0-100"
                                                                            : "";

                                                                        let existingErrors =
                                                                          partnerSettingsError?.find(
                                                                            settingsError =>
                                                                              settingsError?.saas_parent_id ===
                                                                              syndicateValue
                                                                          ) ||
                                                                          {};
                                                                        existingErrors[
                                                                          "saas_parent_id"
                                                                        ] = syndicateValue;
                                                                        existingErrors[
                                                                          "settings"
                                                                        ] = {
                                                                          ...(existingErrors?.settings ||
                                                                            {}),
                                                                          [selfInvestmentFieldKey]:
                                                                            error,
                                                                        };

                                                                        setPartnerSettingsError(
                                                                          [
                                                                            ...partnerSettingsError?.filter(
                                                                              settingsError =>
                                                                                settingsError?.saas_parent_id !==
                                                                                syndicateValue
                                                                            ),
                                                                            existingErrors,
                                                                          ]
                                                                        );

                                                                        let existingSettings =
                                                                          userData?.vp_settings?.find(
                                                                            syndicate =>
                                                                              syndicate?.saas_parent_id ===
                                                                              syndicateValue
                                                                          ) ||
                                                                          {};

                                                                        existingSettings[
                                                                          "saas_parent_id"
                                                                        ] = syndicateValue;
                                                                        existingSettings[
                                                                          "settings"
                                                                        ] = {
                                                                          ...(existingSettings?.settings ||
                                                                            {}),
                                                                          [selfInvestmentFieldKey]:
                                                                            value
                                                                              ? parseInt(
                                                                                  value
                                                                                )
                                                                              : value,
                                                                        };

                                                                        setUserData(
                                                                          {
                                                                            ...userData,
                                                                            vp_settings:
                                                                              [
                                                                                ...(userData?.vp_settings?.filter(
                                                                                  syndicate =>
                                                                                    syndicate?.saas_parent_id !==
                                                                                    syndicateValue
                                                                                ) ||
                                                                                  []),
                                                                                existingSettings,
                                                                              ],
                                                                          }
                                                                        );
                                                                      }}
                                                                    />
                                                                    <span className="text-secondary">
                                                                      <i className="fa fa-asterisk text-secondary" />{" "}
                                                                      Only for
                                                                      VP -
                                                                      Individual
                                                                    </span>
                                                                  </Col>
                                                                </>
                                                              )}
                                                            </Row>
                                                          </Accordion.Body>
                                                        </Accordion.Item>
                                                      );
                                                    }
                                                  )}
                                                </Accordion>
                                              </Row>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        );
                                      }
                                    )}
                                  </Accordion>
                                </Row>
                              </>
                            )}

                          <Row className="mt-3">
                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                            >
                              <Label>
                                Subscription Fee (%){" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                type="number"
                                step="any"
                                min="0"
                                max="100"
                                placeholder="Enter Subscription Fee (%)"
                                // required
                                value={
                                  userData?.level_1_settings?.subscription_fee
                                    ?.amount
                                }
                                onChange={event => {
                                  let level_1_settings =
                                    userData?.level_1_settings || {};
                                  let subscription_fee =
                                    level_1_settings?.subscription_fee || {};
                                  subscription_fee["amount"] =
                                    event.target.value;
                                  level_1_settings["subscription_fee"] =
                                    subscription_fee;
                                  setUserData({
                                    ...userData,
                                    level_1_settings,
                                  });
                                }}
                              />
                            </Col>
                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 d-flex flex-column justify-content-between"
                            >
                              <Label>
                                Carry Fee (%){" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                type="number"
                                step="any"
                                min="0"
                                max="100"
                                placeholder="Enter Carry Fee (%)"
                                // required
                                value={
                                  userData?.level_1_settings?.carry_fee?.amount
                                }
                                onChange={event => {
                                  let level_1_settings =
                                    userData?.level_1_settings || {};
                                  let carry_fee =
                                    level_1_settings?.carry_fee || {};
                                  carry_fee["amount"] = event.target.value;
                                  level_1_settings["carry_fee"] = carry_fee;
                                  setUserData({
                                    ...userData,
                                    level_1_settings,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      <Row className="mt-3 text-end">
                        <Col>
                          <Button type="submit">Update</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PayoutReport;
