import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";

import {
  getInvestors2 as getinvestorsAll,
  getUserAdditionalInfo,
  getAllUsersForReference,
  getAllPartnersForEmployees,
} from "../../../helpers/backend_helper";
import axios from "axios";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

// Loader for data fetch
import DataFetchLoader from "../../../components/Common/DataFetchLoader";

import FilteredSyndicates from "./filteredSyndicates";

const SaasWiseInvestor = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [is_admin, setIsAdmin] = useState("false");
  const [user, setUser] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [userInfo, setUserInfo] = useState({});
  const [partnerUserInfo, setPartnerUserInfo] = useState({});
  const [partnerLogo, setPartnerLogo] = useState({
    fileName: null,
    file: null,
  });
  const [faviconLogo, setFaviconLogo] = useState({
    fileName: null,
    file: null,
  });
  const [saasAliasError, setSaasAliasError] = useState(null);

  const [roleWiseAccess, setRoleWiseAccess] = useState([]);
  const [roleId, setRoleId] = useState();

  const [country, setCountry] = useState();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();

  const [referenceUserList, setReferenceUserList] = useState([]);
  const [selectedRefUser, setSelectedRefUser] = useState(null);

  const [loginUser, setLoginUserData] = useState({});

  const [saasPartnersList, setSaasPartnersList] = useState([]);
  const [selectedSaasPartner, setSelectedSaasPartner] = useState({
    value: JSON.parse(localStorage.getItem("authUser"))?.userInfo
      ?.saas_parent_id,
  });

  const [partnerType, setPartnerType] = useState("partner");
  const [allPartners, setAllPartners] = useState([]);
  const [selectedRefPartner, setSelectedRefPartner] = useState(null);
  const [access, setAccess] = useState({});

  const [saasFilterOptions, setSaasFilterOptions] = useState([]);

  const [show_all_saas_data, setShowAllSaasData] = useState(
    JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data
  );

  const [isEditSecSaas, setIsEditSecSaas] = useState(false);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    const loginUserInfo = JSON.parse(localStorage.getItem("authUser")).userInfo;
    setLoginUserData(loginUserInfo);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
        setShowAllSaasData(res.data.roleWiseAccess?.show_all_saas_data);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
    return () => {};
  }, []);

  useEffect(() => {
    if (!modal) {
      getAllUsersForReference()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let {
                _id,
                firstname,
                lastname,
                email,
                saas_fullname,
                saas_name,
                saas_alias,
              } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                let temp2 = saas_name;
                if (temp2) {
                  temp2 += ` (${saas_fullname})`;
                } else {
                  temp2 = saas_fullname || saas_alias;
                }
                temp = temp2 || temp;

                saasPartners.push({
                  value: _id,
                  label: temp,
                });
                setUserInfo;
              }

              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          setReferenceUserList(users);
          setSaasPartnersList(saasPartners);
          setSaasFilterOptions(saasPartners);
        })
        .catch(error => {
          console.trace(error);
          setReferenceUserList([]);
          setSaasPartnersList([]);
          setSaasFilterOptions([]);
        });
    }
    return () => {};
  }, [modal]);

  useEffect(() => {
    if (!modal) {
      getAllPartnersForEmployees()
        .then(response => {
          let partners = response.data || [];
          partners = partners
            .map(partner => {
              if (!partner) return null;
              // let {
              //   _id: partnerId,
              //   user_id: {
              //     _id,
              //     userName,
              //     firstname,
              //     lastname,
              //     email,
              //     is_saas_parent,
              //     saas_fullname,
              //     saas_name,
              //     saas_alias,
              //   },
              // } = partner;
              let {
                _id,
                userName,
                firstname,
                lastname,
                email,
                is_saas_parent,
                saas_fullname,
                saas_name,
                saas_alias,
              } = partner;
              if (!_id) return null;
              let temp =
                `${firstname || ""} ${lastname || ""}`.trim() || userName;
              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }
              if (!temp) return null;

              if (is_saas_parent) {
                let temp2 = saas_fullname;
                if (temp2) {
                  temp2 += ` (${saas_name})`;
                } else {
                  temp2 = saas_alias;
                }
                temp = temp2 || temp;
              }

              return { value: _id, label: temp };
            })
            .filter(data => data);
          setAllPartners(partners);
        })
        .catch(error => {
          console.trace(error);
          setAllPartners([]);
        });
    }
    return () => {};
  }, [modal]);

  useEffect(() => {
    setLoading(true);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let fmode = params.get("fmode");

    // const show_all_saas_data = JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data;
    getinvestorsAll(
      `?page=${page}${searchQuery}&show_all_saas_data=${show_all_saas_data}`
    )
      .then(response => {
        // console.log("RESPONSE USERS:", response);
        let users = response.users.filter(user => {
          if (user?.status?.toLowerCase() === "declined")
            user.status = "Disabled";

          return user;
        });
        setUsers(users);
        setTotal(response.dataSize);
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
    return () => {};
  }, [page, load, searchQuery, show_all_saas_data]);

  useEffect(() => {
    if (!modal) {
      setCountry();
      setStateData();
      setCityData();
    }
    return () => {};
  }, [modal]);
  const [countryCurrencyCodes, setCountryCurrencyCodes] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/role/getroles`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      })
      .then(res => {
        let arr = [];
        res.data.roleWiseAccess?.map(data => {
          let obj = {};
          obj.label = data.name;
          obj.value = data._id;
          arr.push(obj);
        });
        setRoleWiseAccess(arr);
      })
      .catch(err => {
        // console.log(err)
      });
    return () => {};
  }, [selectedSaasPartner]);

  // to check saas-alias regex
  useEffect(() => {
    if (user.saas_alias !== "/") {
      if (!RegExp(/^[a-z0-9-]{1,}$/g).test(user.saas_alias)) {
        setSaasAliasError(
          "Invalid SAAS Alias, only small-case alphabets (a-z), numbers (0-9) and dash (-) allowed"
        );
      } else {
        setSaasAliasError(null);
      }
    } else {
      setSaasAliasError(null);
    }
    return () => {};
  }, [user]);

  const toggleActiveTab = activeTab => {
    setActiveTab(activeTab);
  };

  const toggle = (isEdit = false, user = {}, satmode = 0) => {
    setPartnerLogo({ fileName: null, file: null });
    setFaviconLogo({ fileName: null, file: null });
    setUser(user);
    setisEdit(isEdit);
    setModal(!modal);

    if (satmode) {
      setActiveTab("5");
    } else {
      setActiveTab("1");
    }
  };

  const handleUserClickSecSaas = user => {
    setIsEditSecSaas(true);
    handleUserClick(user, 1);
  };
  const handleUserClick = async (editUser, satmode = 0) => {
    let status = editUser.status === "Disabled" ? "Declined" : editUser.status;
    let isPartner = editUser?.user_types?.some(type =>
      ["partner", "partner employee"].includes(
        type?.user_type?.[0]?.toLowerCase()
      )
    );
    let currencyFlag = false;
    if (editUser?.currency) {
      let keys = Object.keys(editUser?.currency || {});
      if (keys.length === 3) {
        currencyFlag = Object.keys(editUser?.currency).every(
          key => editUser?.currency?.[key]
        );
      }
    }
    let currency = currencyFlag
      ? editUser?.currency
      : { label: "Indian Rupee", value: "INR", symbol: "₹" };
    let saas_type = editUser?.saas_type || "Incubator - College";
    editUser = { ...editUser, status, isPartner, currency, saas_type };
    setUser(editUser);

    let tempList = saasPartnersList.filter(user => user.value !== editUser._id);
    setSaasPartnersList(tempList);
    let refSaasPartner = editUser?.saas_parent_id?._id;
    let found = tempList.find(user => user.value === refSaasPartner);
    setSelectedSaasPartner(found);

    try {
      let response = await getUserAdditionalInfo(editUser._id);
      if (!response.status) {
        return alert(response.message || "User Info Not Found");
      }
      let userInfo = response.data || {};
      setUser({
        ...user,
        other_primary_saas: userInfo?.other_primary_saas || [],
      });
      let partnerUserInfo = userInfo["partner"] || {};
      delete userInfo["partner"];
      setIsAdmin(userInfo?.is_admin);
      setUserInfo(userInfo);
      setRoleId(userInfo?.role?._id);
      setPartnerUserInfo({
        ...partnerUserInfo,
        display_collection: [undefined, null, ""].includes(
          partnerUserInfo?.display_collection
        )
          ? true
          : partnerUserInfo?.display_collection,
        is_saas_parent: !!editUser?.is_saas_parent,
      });
      // setReferenceUserList(
      //   referenceUserList.filter(user => user.value !== editUser._id)
      // );
      // setAllPartners(allPartners.filter(user => user.value !== editUser._id));

      editUser = {
        ...editUser,
        isPartner,
      };

      let tempRefUser = {
        value: null,
        label: "Select User",
      };
      if (response.data?.invRefUserIds?.length) {
        let value = response.data?.invRefUserIds?.[0] || null;
        let label = referenceUserList.find(
          user => user?.value === value
        )?.label;

        if (!label) {
          value = null;
          label = "Select User";
        }
        tempRefUser = { value, label };
      }
      setSelectedRefUser(tempRefUser?.value ? tempRefUser : null);

      let tempRefPartner = {
        value: null,
        label: "Select Partner",
      };
      if (partnerUserInfo) {
        let value = partnerUserInfo?.reference_user_id?.[0] || null;
        if (value) {
          let label = allPartners.find(part => part?.value === value)?.label;
          if (!label) {
            value = null;
            label = "Select Partner";
          }
          tempRefPartner = { value, label };
        }
      }

      if (!isPartner) {
        let parentId = editUser?.is_saas_parent
          ? null
          : editUser?.saas_parent_id?._id;
        let parValue;
        if (parentId) {
          parValue = referenceUserList.find(user => user?.value === parentId);
        }
        setSelectedRefPartner(parValue ? parValue : null);
      } else {
        setSelectedRefPartner(tempRefPartner?.value ? tempRefPartner : null);
      }
      setPartnerType("partner");
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while fetching data";
      setUserInfo({});
      setPartnerUserInfo({});
      return alert(message);
    }

    toggle(true, editUser, satmode);
  };

  const defaultSorted = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const userListColumns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      text: "Name",
      dataField: "firstname",
      sort: true,
      filter: textFilter({
        defaultValue: history.location.search.split("&firstname=")?.[1] || "",
        delay: 1500,
      }),
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <div className="fw-bold">
              {user.firstname} {user.lastname}
            </div>
          </h5>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: textFilter({
        defaultValue: history.location.search.split("&email=")[1],
        delay: 1500,
      }),
    },
    {
      dataField: "is_admin",
      text: "Is Admin ",
      hidden: true,
      sort: true,
      formatter: (cellContent, user) => (
        <>{user.is_admin.charAt(0).toUpperCase() + user.is_admin.slice(1)}</>
      ),
      //
    },
    {
      dataField: "saas_parent_id",
      text: "SaaS Name",
      sort: false,
      formatter: (cellContent, user) => {
        let value = user?.saas_parent_id?.saas_name;

        // if (user?.is_saas_parent) {
        //   value = `Parent - ${user?.saas_name}`;
        // }
        return <>{value}</>;
      },
      filter:
        loginUser?.is_super_admin || show_all_saas_data
          ? selectFilter({
              options: saasFilterOptions.sort((a, b) => {
                if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                  return -1;
                }
                if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                  return 1;
                }
                return 0;
              }),
              delay: 1500,
            })
          : false,
    },
    {
      dataField: "user_types[0].user_type[0]",
      text: "User Type",
      hidden: true,
      sort: true,
      formatter: (cellContent, user) => (
        <div className="text-capitalize">
          {user?.user_types
            ?.map(userType => userType?.user_type?.[0] || "")
            .filter(data => !!data)
            .join(", ")}
        </div>
      ),
      filter: textFilter({
        delay: 1500,
      }),
    },
    {
      dataField: "totalPastEventAttended",
      text: "Events Attended",
      sort: true,
      hidden: true,
      // sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.totalPastEventAttended
            ? `${user.totalPastEventAttended} [${user.totalDuration} mins]`
            : user.totalPastEventAttended}
        </>
      ),
      // filter: textFilter(),
    },
    {
      dataField: "totalPastInvestments",
      text: "Past Investments",
      sort: true,
      hidden: true,
      // sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.totalPastInvestments
            ? `${user.totalPastInvestments} [${user?.totalInvestedAmount}]`
            : user.totalPastInvestments}
        </>
      ),
      // filter: textFilter(),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, user) => {
        if (user?.status?.toLowerCase() === "declined") return <>Disabled</>;
        return (
          <>{user.status.charAt(0).toUpperCase() + user.status.slice(1)}</>
        );
      },
      filter: selectFilter({
        options: [
          { value: "accepted", label: "Accepted" },
          { value: "rejected", label: "Rejected" },
        ],
        delay: 1500,
      }),
    },
    {
      dataField: "email_verified",
      text: "Email Verified",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {user.email_verified.charAt(0).toUpperCase() +
            user.email_verified.slice(1)}
        </>
      ),
      filter: textFilter({
        delay: 1500,
      }),
      hidden: true,
    },
    {
      dataField: "mobile_number_verified",
      text: "Mobile number Verified",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {user.mobile_number_verified.charAt(0).toUpperCase() +
            user.mobile_number_verified.slice(1)}
        </>
      ),
      filter: textFilter({
        delay: 1500,
      }),
      hidden: true,
    },
    {
      dataField: "user_verified",
      text: "User verified",
      hidden: true,
      sort: false,
      formatter: (cellContent, user) => <>{user.user_verified}</>,
    },
    {
      dataField: "createdAt",
      text: "Joined On",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {user?.logs[0]
            ? moment(new Date(user?.logs[0]?.createdAt)).format("DD MMM Y")
            : ""}
        </>
      ),
      // csvFormatter: (cellContent,user) => `${moment(new Date(user.createdAt)).format("DD MMM Y")}`,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      csvExport: false,
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          {access?.show_secondary_saas_details &&
          user?.other_primary_saas?.length ? (
            <Link
              className="text-success"
              to="#"
              title="Secondary SaaS Details"
            >
              <i
                className="mdi mdi-puzzle-edit font-size-18"
                onClick={() => handleUserClickSecSaas(user)}
              ></i>
            </Link>
          ) : (
            ""
          )}

          {/* <Link
            className="text-success"
            to={`/saas-payout/${user._id}`}
            title="View Saas Payout Details"
          >
            <i className="mdi mdi-eye font-size-18" id="saastip"></i>
          </Link> */}

          {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteUser(user)}
            ></i>
          </Link> */}
          {/* <Link
            className="text-success"
            to={
              user.user_types[0]?.user_type[0] === "founder"
                ? `/founders-detail/${user._id}`
                : user.user_types[0]?.user_type[0] === "investor"
                ? `/investor-details/${user._id}`
                : `/professional-detail/${user._id}`
            }
          >
            <i color="primary" className="font-16 btn-block btn btn-primary">
              View Details
            </i>
          </Link> */}
        </div>
      ),
    },
  ];

  const onTableChange = async (action, { filters, sortOrder, sortField }) => {
    let str = "";
    Object.keys(filters).forEach(filter => {
      let filterVal = filters?.[filter]?.filterVal;
      if (filterVal) {
        switch (filter) {
          case "user_types[0].user_type[0]":
            str += `&user_type=${filterVal}`;
            break;
          case "status":
            str += `&status=${
              "disabled".startsWith(filterVal.toLowerCase())
                ? "Declined"
                : filterVal
            }`;
            break;
          default:
            str += `&${filter}=${filterVal}`;
            break;
        }
      }
    });
    let order = sortOrder === "asc" ? 1 : -1;
    str += `&sort=${
      sortField === "_id" ? "createdAt" : sortField
    }&order=${order}`;
    setSearchQuery(str);
  };

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
    },
  };

  const isUserDisabled = ["Disabled", "Declined"].includes(user?.status);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Investors List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          <Row>
            <Col sm="6">
              <h5 className="text-uppercase">Investors List </h5>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-0">
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="saas_parent_id"
                    columns={userListColumns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="saas_parent_id"
                        columns={userListColumns}
                        data={users}
                        // search={{
                        //   searchFormatted: true,
                        // }}
                        // exportCSV
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    // classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                    // rowStyle={row => ({
                                    //   backgroundColor: row?.is_saas_parent
                                    //     ? "lightgray"
                                    //     : "",
                                    //   fontWeight: row?.is_saas_parent
                                    //     ? "600"
                                    //     : "0",
                                    // })
                                    // }
                                  />
                                  <Modal
                                    size="lg"
                                    isOpen={modal}
                                    toggle={() => toggle()}
                                  >
                                    <ModalHeader
                                      toggle={() => toggle()}
                                      tag="h4"
                                    >
                                      {isEdit
                                        ? "User SaaS details"
                                        : "Add User"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <div>
                                        <Nav tabs>
                                          {!!user?.other_primary_saas?.length &&
                                            isEditSecSaas && (
                                              <NavItem>
                                                <NavLink
                                                  className={
                                                    activeTab === "5" &&
                                                    "active"
                                                  }
                                                  onClick={() => {
                                                    toggleActiveTab("5");
                                                  }}
                                                  disabled={isUserDisabled}
                                                >
                                                  Secondary SAAS Details
                                                </NavLink>
                                              </NavItem>
                                            )}
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                          <TabPane tabId="5">
                                            <Row form className="mt-3">
                                              <Col className="col-12">
                                                <Row className="">
                                                  <Col>
                                                    <FilteredSyndicates
                                                      user_id={user?._id}
                                                      userData={user}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </TabPane>
                                        </TabContent>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SaasWiseInvestor.propTypes = {
  // users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
};
export default SaasWiseInvestor;
