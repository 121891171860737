import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import { CardBody } from "reactstrap";
import moment from "moment";
import classnames from "classnames";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const InvestorReport = ({ match }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [portfolio, setPortfolio] = useState([]);
  const [Myrefrance, setMyrefrance] = useState([]);

  const fetchData = async () => {
    const token = authHeader();
    const { data } = await axios({
      url:
        process.env.REACT_APP_API_URL +
        `/api/user/investor/admin/portfolio/${match.params.id}`,
      headers: { Authorization: token },
    });
    if (data) {
      setPortfolio(data.portfolio);
    }
  };

  const fetchrefrances = async () => {
    const token = authHeader();
    try {
      let { data } = await axios({
        method: "get",
        url:
          process.env.REACT_APP_API_URL +
          `/api/admin/userReport/userReferences/${match.params.id}`,
        headers: { Authorization: token },
      });
      if (data?.status) {
        setMyrefrance(data.data);
      } else {
        setMyrefrance([]);
      }
    } catch (error) {
      // console.log(error);
      setMyrefrance([]);
    }
  };

  useEffect(() => {
    fetchData();
    fetchrefrances();
    return () => {};
  }, []);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const defaultSorted = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const columns = [
    {
      dataField: "startup_name",
      text: "Startup Name",
      sort: true,
    },
    {
      dataField: "round_name",
      text: "Funding Round",
      sort: true,
    },

    {
      dataField: "time",
      text: "Round Month/Year",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>{moment(portfolio.time).format("MMM YYYY")}</>
      ),
    },
    {
      dataField: "deal_id",
      text: "Valuation/ Valuation Floor-Cap",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.valuation_cap && portfolio?.valuation_floor ? (
            <>
              {/* ₹ */}
              {amountNumberToTextFrmt(portfolio?.valuation_floor || "")}-
              {amountNumberToTextFrmt(portfolio?.valuation_cap || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {amountNumberToTextFrmt(portfolio?.valuation_at_time || "")}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "my_investment",
      text: "Amount Invested",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.amount_invested > 999 ? (
            <>
              {/* ₹ */}
              {toThousandsFormatIn(portfolio?.amount_invested || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {portfolio?.amount_invested}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "service_fee",
      text: "Service Fees",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.service_fee > 999 ? (
            <>
              {/* ₹ */}
              {toThousandsFormatIn(portfolio?.service_fee || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {portfolio?.service_fee}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "on_behalf_of",
      text: "On Behalf Of",
      sort: true,
    },
    {
      dataField: "investment_type",
      text: "Investment Type",
      sort: true,
    },
    {
      dataField: "share_count",
      text: "No. of Shares/Units",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.share_count > 999 ? (
            <>
              {/* ₹ */}
              {toThousandsFormatIn(portfolio?.share_count || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {portfolio?.share_count}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "amount_invested",
      text: "Share/Unit Price",
      formatter: (cellContent, portfolio) => (
        <>
          {calcPricePerShare(portfolio) && calcPricePerShare(portfolio) > 0 && (
            <>
              {portfolio.investment_type === "Syndicate"
                ? amountNumberToTextFrmt(calcPricePerShare(portfolio)) + ""
                : portfolio.investment_type === "Direct"
                ? amountNumberToTextFrmt(calcPricePerShare(portfolio)) + ""
                : amountNumberToTextFrmt(calcPricePerShare(portfolio))}
            </>
          )}
        </>
      ),
    },
  ];

  const calcPricePerShare = value => {
    let price_per_share = " ";
    try {
      if (value?.amount_invested && value?.share_count) {
        price_per_share = Math.round(
          parseInt(value?.amount_invested) / parseInt(value?.share_count)
        );
      }
    } catch (err) {
      // console.log(err);
    }
    return price_per_share;
  };

  const amountNumberToTextFrmt = val => {
    let fmt_val = val;
    // clean number
    let isNegativeNum = false;
    if (val) {
      val = `${val}`;
      val = val.replace("+", "");
      isNegativeNum = val.startsWith("-");
      val = val.replace("-", "");
    }
    // && /^[0-9]+$/.test(val) temporary closed
    let trimStr = val.toString().replace(/^\s+|\s+$/gm, "");
    val = trimStr;
    if (val) {
      val = val.toString();
      let text_len = val.split(".")[0].length;
      // val to two decimal place
      const exactVal = (str_val, d) => {
        let v = "";
        try {
          v = (parseInt(str_val) / d).toFixed(2);
        } catch (err) {
          v = str_val;
        }
        return v;
      };

      switch (true) {
        case text_len <= 5:
          fmt_val = val;
          break;
        case text_len <= 7:
          fmt_val = `${exactVal(val, 100000)} Lac`;
          break;
        case text_len >= 8:
          fmt_val = `${exactVal(val, 10000000)} Cr`;
          break;
        default:
          fmt_val = val;
      }

      fmt_val = fmt_val.replace(".00", "");
    }
    // convert to comma format
    fmt_val = toCommaFormat(fmt_val);
    if (isNegativeNum) fmt_val = `-${fmt_val}`;
    return fmt_val;
  };

  const toCommaFormat = v => {
    let fmt_val = "";
    const formatter = new Intl.NumberFormat("en-US");
    try {
      fmt_val = formatter.format(v);
      if (fmt_val === "NaN") fmt_val = v;
    } catch (err) {
      // console.log(err);
    }
    return fmt_val;
  };

  const toThousandsFormat = v => {
    let fmt_val = "";
    const formatter = new Intl.NumberFormat("en-US");
    try {
      fmt_val = formatter.format(v);
      if (fmt_val === "NaN") fmt_val = v;
    } catch (err) {
      // console.log(err);
    }
    return fmt_val;
  };
  const toThousandsFormatIn = v => {
    let fmt_val = "";
    const formatter = new Intl.NumberFormat("en-IN");
    try {
      fmt_val = formatter.format(v);
      if (fmt_val === "NaN") fmt_val = v;
    } catch (err) {
      //  console.log(err);
    }
    return fmt_val;
  };

  const columnss = [
    {
      text: "Name",
      dataField: "referenceName",
      sort: true,
      filterValue: (cell, row) => row.referenceName,
      filter: textFilter(),
      formatter: (cell, row) => row.referenceName || "",
    },
    {
      text: "Country",
      dataField: "country",
      sort: true,
      filterValue: (cell, row) => row.country,
      filter: textFilter(),
      formatter: (cell, row) => row.country || "",
    },
    {
      text: "User Type",
      dataField: "userType",
      sort: true,
      filterValue: (cell, row) => row.userType,
      filter: textFilter(),
      formatter: (cell, row) => row.userType || "",
    },
    {
      text: "Designation",
      dataField: "designation",
      sort: true,
      filterValue: (cell, row) => row.designation,
      filter: textFilter(),
      formatter: (cell, row) => row.designation || "",
    },
    {
      text: "Gender",
      dataField: "gender",
      sort: true,
      filterValue: (cell, row) => row.gender,
      filter: textFilter(),
      formatter: (cell, row) => row.gender || "",
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
  };
  return (
    <div>
      <Row>
        <h5>Investor Report</h5>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            My Investment
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            My Reference
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={columns}
                    data={portfolio}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={portfolio}
                        search
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(options)}
                keyField="_id"
                columns={columnss}
                data={Myrefrance}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columnss}
                    data={Myrefrance}
                    search
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-hover"}
                                bordered={false}
                                striped={true}
                                responsive
                                filter={filterFactory()}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
};

InvestorReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default InvestorReport;
