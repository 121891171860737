import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";

function EvaluationReport(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    setLoading(false);
    return () => {};
  }, []);

  const toggle = tab => {
    setActiveTab(tab);
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    let primaryEvaluation =
      props &&
      props?.evaluationReport.length > 0 &&
      props.evaluationReport[0]?.primary_information &&
      props.evaluationReport[0]?.primary_information;
    let evaluationCheckList =
      props &&
      props?.evaluationReport.length > 0 &&
      props.evaluationReport[0]?.evaluationchecklist_checklist &&
      props.evaluationReport[0]?.evaluationchecklist_checklist;
    let legalDueDiligence =
      props &&
      props?.evaluationReport.length > 0 &&
      props.evaluationReport[0]?.legalduediligencechecklist_grouplist &&
      props.evaluationReport[0]?.legalduediligencechecklist_grouplist;
    let finalDueDiligence =
      props &&
      props?.evaluationReport.length > 0 &&
      props.evaluationReport[0]?.financialduediligencereport_checklist &&
      props.evaluationReport[0]?.financialduediligencereport_checklist
        ?.grouplist;
    let capTableReport =
      props &&
      props?.evaluationReport.length > 0 &&
      props.evaluationReport[0]?.captablereport_share_distribution &&
      props.evaluationReport[0]?.captablereport_share_distribution;
    return (
      <React.Fragment>
        <div className="mt-5">
          <Container fluid>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" && "active"}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Primary Evaluation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" && "active"}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Evaluation Checklist
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" && "active"}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Legal Due Diligence
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "4" && "active"}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Final Due Diligence Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "5" && "active"}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Cap Table Report
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row className="my-5">
                    <Col sm="6">
                      {primaryEvaluation && (
                        <table className="table table-hover table-responsive width-100">
                          <tbody>
                            <tr>
                              <th>Company Name: </th>
                              <td>{primaryEvaluation?.company_name}</td>
                            </tr>
                            <tr>
                              <th>App Available: </th>
                              <td>
                                {primaryEvaluation &&
                                  primaryEvaluation?.app_available.length > 0 &&
                                  (primaryEvaluation?.app_available[0] === "Y"
                                    ? "Yes"
                                    : "No")}
                              </td>
                            </tr>
                            <tr>
                              <th>Countries of operation: </th>
                              <td>
                                {primaryEvaluation &&
                                  primaryEvaluation?.countries_of_operation.join()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </Col>
                    <Col sm="6">
                      {primaryEvaluation && (
                        <table className="table table-hover table-responsive width-100">
                          <tbody>
                            <tr>
                              <th>Company Website: </th>
                              <td>
                                <a href={primaryEvaluation?.website}>
                                  {primaryEvaluation?.website}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>Application Type: </th>
                              <td>
                                {primaryEvaluation &&
                                primaryEvaluation?.app_type.length > 0 &&
                                primaryEvaluation?.app_type[0] === "A"
                                  ? "Android"
                                  : primaryEvaluation?.app_type[0] === "i"
                                  ? "iOS"
                                  : primaryEvaluation?.app_type[0] === "B"
                                  ? "Both"
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th>Cities of operation: </th>
                              <td>
                                {primaryEvaluation &&
                                  primaryEvaluation?.cities_of_operation
                                    .length > 0 &&
                                  primaryEvaluation?.cities_of_operation.join()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </Col>
                    <Col sm="12">
                      <h4>List of Co-Founders</h4>
                      <table className="table table-hover table-responsive width-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Co-Foudner Name:</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>City</th>
                          </tr>
                        </thead>
                        <tbody>
                          {primaryEvaluation &&
                            primaryEvaluation?.founder?.map((value, key) => (
                              <tr key={key}>
                                <th>{++key}</th>
                                <td>{value.founder_name}</td>
                                <td>{value.founder_country}</td>
                                <td>{value.founder_state}</td>
                                <td>{value.founder_city}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="12">
                      <h4>Foudner Details</h4>
                    </Col>
                    <Col sm="6">
                      <table className="table table-hover table-responsive width-100">
                        <tbody>
                          <tr>
                            <th>Founder Collective Holding%: </th>
                            <td>
                              {primaryEvaluation?.founders_collective_holding}
                            </td>
                          </tr>
                          <tr>
                            <th>Headquarter: </th>
                            <td>{primaryEvaluation?.headquarter}</td>
                          </tr>
                          <tr>
                            <th>Market Type: </th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.market_type.length &&
                                primaryEvaluation?.market_type.join()}
                            </td>
                          </tr>
                          <tr>
                            <th>Existing Global Players: </th>
                            <td>
                              {primaryEvaluation?.existing_global_players}
                            </td>
                          </tr>
                          <tr>
                            <th>Indirect Competition: </th>
                            <td>{primaryEvaluation?.indirect_competition}</td>
                          </tr>
                          <tr>
                            <th>
                              Risks - Potential new entrants, Legislations,
                              Others:{" "}
                            </th>
                            <td>{primaryEvaluation?.risks}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="6">
                      <table className="table table-hover table-responsive width-100">
                        <tbody>
                          <tr>
                            <th>Company founded in: </th>
                            <td>{primaryEvaluation?.founded_in}</td>
                          </tr>
                          <tr>
                            <th>Team Size: </th>
                            <td>{primaryEvaluation?.current_team_size}</td>
                          </tr>
                          <tr>
                            <th>Customers: existing & in pipeline: </th>
                            <td>{primaryEvaluation?.customers}</td>
                          </tr>
                          <tr>
                            <th>Direct Competition: </th>
                            <td>{primaryEvaluation?.direct_competition}</td>
                          </tr>
                          <tr>
                            <th>Entry Barriers/ Substitutes: </th>
                            <td>
                              {primaryEvaluation?.entry_barriers_substitutes}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="12">
                      <h3>Funding Utilization</h3>
                      <table className="table table-hover table-responsive width-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Key Area</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {primaryEvaluation &&
                            primaryEvaluation?.funding_utilization.length > 0 &&
                            primaryEvaluation?.funding_utilization?.map(
                              (value, key) => (
                                <tr key={key}>
                                  <th>{++key}</th>
                                  <tr>{value?.key_areas}</tr>
                                  <td>{value?.amount}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="12">
                      <h3>Current Status</h3>
                    </Col>
                    <Col sm="6">
                      <table className="table table-hover table-responsive width-100">
                        <tbody>
                          <tr>
                            <th>Revenue in Last Fin Year FY21 (INR)</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.revenue_in_last_fin_year}
                            </td>
                          </tr>
                          <tr>
                            <th>Cost of Customer Acquisition (INR)</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.cost_of_customer_acquisition}
                            </td>
                          </tr>
                          <tr>
                            <th>Current Cash Position (INR)</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.current_cash_position}
                            </td>
                          </tr>
                          <tr>
                            <th>Total Funds Sought (in Crores)</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.total_fund_sought}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              Slab carved out specifically for Invstt investors*
                              (in crores)
                            </th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.slab_carved_out}
                            </td>
                          </tr>
                          <tr>
                            <th>How much fund are you raising?</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.how_much_fund_raising}
                            </td>
                          </tr>
                          <tr>
                            <th>Valuation Type</th>
                            <td>
                              {primaryEvaluation?.valuation_type === "preMoney"
                                ? "Pre Money"
                                : primaryEvaluation?.valuation_type ===
                                    "postMoney" && "Post Money"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="6">
                      <table className="table table-hover table-responsive width-100">
                        <tbody>
                          <tr>
                            <th>Average Revenue in Last 3 Months (INR)</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.average_revenue_in_last_3_months}
                            </td>
                          </tr>
                          <tr>
                            <th>Monthly Burn Rate (INR)</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.burn_rate_per_month}
                            </td>
                          </tr>
                          <tr>
                            <th>Breakeven on target? If not, reasons</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.breakeven_on_target}
                            </td>
                          </tr>
                          <tr>
                            <th>Fund Usage Plan</th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.fund_usage_plan}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              Pre - Money Valuation (can provide a range) * (in
                              crores)
                            </th>
                            <td>
                              {primaryEvaluation &&
                                primaryEvaluation?.current_status
                                  ?.pre_money_valuation}
                            </td>
                          </tr>
                          <tr>
                            <th>Current Valuation (proposed by the founder)</th>
                            <td>{primaryEvaluation?.current_valuation}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="12">
                      <h3>Financial Revenue</h3>
                    </Col>
                    <Col sm="12">
                      <table className="table table-hover table-responsive width-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Financial Year</th>
                            <th>Revenue</th>
                            <th>Gross Profit</th>
                            <th>Margin%</th>
                            <th>Capex</th>
                            <th>Cash Requirement</th>
                          </tr>
                        </thead>
                        <tbody>
                          {primaryEvaluation &&
                            primaryEvaluation.revenue_status.length > 0 &&
                            primaryEvaluation.revenue_status.map(
                              (value, key) => (
                                <tr key={key}>
                                  <th>{++key}</th>
                                  <td>{value?.financial_year}</td>
                                  <td>{value?.revenue}</td>
                                  <td>{value?.gross_profit}</td>
                                  <td>{value?.margin}</td>
                                  <td>{value?.capex}</td>
                                  <td>{value?.cash_requirement}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </Col>
                    <Col sm="12">
                      <h3>Key Team Members</h3>
                    </Col>
                    <Col sm="12">
                      <table className="table table-hover table-responsive width-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Full Name</th>
                            <th>Qualifications</th>
                            <th>Current Designation</th>
                            <th>Past Experience</th>
                            <th>Core Strength</th>
                            <th>Linkedin URL</th>
                            <th>Location</th>
                            <th>Contact Number</th>
                            <th>Email Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {primaryEvaluation &&
                            primaryEvaluation?.key_team_members.length > 0 &&
                            primaryEvaluation?.key_team_members.map(
                              (value, key) => (
                                <tr key={key}>
                                  <th>{++key}</th>
                                  <td>{value?.full_name}</td>
                                  <td>{value?.qualifications}</td>
                                  <td>{value?.current_role}</td>
                                  <td>{value?.past_experience}</td>
                                  <td>{value?.core_strength}</td>
                                  <td>
                                    <a href={value?.linkedin_url}>
                                      LinkedIn Profile
                                    </a>
                                  </td>
                                  <td>{value?.location}</td>
                                  <td>{value?.contact_number}</td>
                                  <td>{value?.email}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="my-5">
                    <Col sm="12">
                      <table className="table table-hover table-responsive width-100">
                        <tbody>
                          {evaluationCheckList &&
                            evaluationCheckList.length > 0 &&
                            evaluationCheckList.map((value, key) => (
                              <tr key={key}>
                                <td style={{ width: "50%" }}>
                                  {value?.checklist_title}
                                </td>
                                <td style={{ width: "25%" }}>
                                  {value?.checklist_status[0] !== "undefined" &&
                                    value?.checklist_status[0]}
                                </td>
                                <td style={{ width: "25%" }}>
                                  {value?.upload_doc_list.length > 0 &&
                                    value?.upload_doc_list.map((value, key) => (
                                      <a
                                        href={value?.upload_doc}
                                        key={key}
                                        download
                                      >
                                        {value?.doc_title}
                                      </a>
                                    ))}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row className="my-5">
                    <Col sm="12">
                      {legalDueDiligence &&
                        legalDueDiligence.length > 0 &&
                        legalDueDiligence.map((value, key) => (
                          <div key={key} className="my-4">
                            <h3>{value.groupname}</h3>
                            <Col sm="12">
                              <table className="table table-hover table-responsive width-100">
                                <tbody>
                                  {value.checklist.length > 0 &&
                                    value.checklist.map((value, key) => (
                                      <tr key={key}>
                                        <td style={{ width: "50%" }}>
                                          {value?.checklist_title}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                          {value?.checklist_status[0] !==
                                            "undefined" &&
                                            value?.checklist_status[0]}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                          {value?.upload_doc_list.length > 0 &&
                                            value?.upload_doc_list.map(
                                              (value, key) => (
                                                <a
                                                  href={value?.upload_doc}
                                                  key={key}
                                                  download
                                                >
                                                  {value?.doc_title}
                                                </a>
                                              )
                                            )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </Col>
                            <hr />
                          </div>
                        ))}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  {/* <Row>
                                        <Col sm="12"><h4>Final Due Diligence Report</h4></Col>
                                    </Row> */}
                  <Row className="my-5">
                    <Col sm="12">
                      {finalDueDiligence &&
                        finalDueDiligence.length > 0 &&
                        finalDueDiligence.map((value, key) => (
                          <div key={key} className="my-4">
                            <h3>{value.groupname}</h3>
                            <Col sm="12">
                              <table className="table table-hover table-responsive width-100">
                                <tbody>
                                  {value.checklist.length > 0 &&
                                    value.checklist.map((value, key) => (
                                      <tr key={key}>
                                        <td style={{ width: "50%" }}>
                                          {value?.checklist_title}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                          {value?.checklist_status[0] !==
                                            "undefined" &&
                                            value?.checklist_status[0]}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                          {value?.upload_doc_list.length > 0 &&
                                            value?.upload_doc_list.map(
                                              (value, key) => (
                                                <a
                                                  href={value?.upload_doc}
                                                  key={key}
                                                  download
                                                >
                                                  {value?.doc_title}
                                                </a>
                                              )
                                            )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </Col>
                            <hr />
                          </div>
                        ))}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row className="my-5">
                    <Col sm="12">
                      <table className="table table-hover table-responsive width-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name of the Shareholder</th>
                            <th>Role of Shareholder</th>
                            <th>Percentage of Shares</th>
                            <th>Number of Shares</th>
                            <th>Holding Since</th>
                          </tr>
                        </thead>
                        <tbody>
                          {capTableReport &&
                            capTableReport.length > 0 &&
                            capTableReport.map((value, key) => (
                              <tr key={key}>
                                <td>{++key}</td>
                                <td>{value?.name_of_share_holder}</td>
                                <td>{value?.role_of_share_holder}</td>
                                <td>{value?.percentage_of_share}</td>
                                <td>{value?.number_of_shares}</td>
                                <td>{value?.holding_since}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EvaluationReport.propTypes = {
  evaluationReport: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default EvaluationReport;
