import Swal from "sweetalert2";
import * as moment from "moment";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvRadio,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";

import Breadcrumbs from "../../components/Common/Breadcrumb";
// Quill Editor for Event Description
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import _, { filter } from "lodash";
import Select from "react-select";
import axios from "axios";
import { encryptDataToArray } from "helpers/EncryptDecrypt";


class CreateDemoDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            alias: "",
            errors: {},
            postsData: [],
            selectedPostsData: {},
            investmentDeal: [{ selectedDeal: "", selectedCalendlyLink: "" }],
            status: [{ label: "Active", value: "1"}, { label: "Inactive", value: "0"}],
            selectedStatus: { label: "Active", value: "1"},

            description: "",
            demo_day_banner: {fileName: null, file: null},
            demo_day_host_logo: {fileName: null, file: null},
            calender_link:'',
            // marketing_content: "",
            successMessage: "",
            errorMessage: "",
            // datetime: ""
        }
        // const newWeeklyBrew = {
        //     title: this.state.title.trim(),
        //     description: this.state.description.trim(),
        //     marketing_content: this.state.marketing_content.trim(),
        // };
    };

    componentDidMount() {

        const token = authHeader();

        let config = {
            method: 'get',
            // url: `${process.env.REACT_APP_API_URL}/api/weeklyBrew/get_timeline_posts`,
            url: `${process.env.REACT_APP_API_URL}/api/applyforfunding/getall_deals?saas_parent_id=null&is_super_admin=true&show_all_saas_data=true`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        };
        axios(config)
            .then((res) => {

                let postsData =[]
                if (res.data.status) {
                    let deals = res?.data?.data;
 
                    if(deals.length > 0){

                        for (let deal of deals) {


                            let deal_detail = deal?.applyforfundings

                            let deal_lebel =  deal_detail?.startup_id?.basic_information?.startup_name +" (" +
                                deal_detail?.funding_information
                                  ?.funding_round +
                                ")";
                            let deal_date = (deal_detail?.funding_information?.deal_date !== undefined || deal_detail?.funding_information?.deal_date !== "undefined")? " ("+moment(deal_detail?.funding_information?.deal_date
                              ).format("MMM YYYY") +")":'';

                            let sassName = deal?.userDetailsObj?.saas_name;
                            let dealDetail = deal_lebel + deal_date +" - "+sassName;
                            postsData.push({
                                label: dealDetail,
                                value: deal_detail._id
                            });
                        }

                    }
              
                    this.setState({ postsData: postsData });

                }
            })
            .catch((error) => {
                // console.log(error)
            });

    }

    handleTitleChange = e => {
        this.setState({
            title: e.currentTarget.value,
        });
    };
    handleAliasChange = e => {
        this.setState({
            alias: e.currentTarget.value,
        });
    };
    


    // handleCalenderLinkChange = e => {
    //     this.setState({
    //         calender_link: e.currentTarget.value,
    //     });
    // };
    
    handleDescriptionChange = e => {
        this.setState({
            description: e.currentTarget.value,
        });
    };


    handleStatusChange = e => {
        this.setState({
            selectedStatus: e,
        });
    };

    fetchValues = posts => {

        let allPostID = [];
        for (let post of posts) {
            // console.log("postsDataaaaaaaaaa", post)
            allPostID.push(post.value);
        }
        return allPostID;

        // console.log("changed data", e[0].value)
        
    };
    


  // Function for change Input Fields for IndustrySector
  handleChangeIndustrySector = (e, index) => {
   
    const sectors = this.state.investmentDeal;
    if(e.currentTarget?.name == 'calendly_link'){
        sectors[index]["selectedCalendlyLink"] = e.currentTarget.value;
    }else{
        sectors[index]["selectedDeal"] = e.value;
    }
    this.setState({
        investmentDeal: sectors,
    });

  };

  // Function for Remove Input Fields for IndustrySector
  handleRemoveIndustrySectors = index => {
    var values = this.state.investmentDeal;
    values.splice(index, 1);
    this.setState({ investmentDeal: values });
  };

  // Function for Create Input Fields for IndustrySector
  handleAddIndustrySectors = e => {
    var values = this.state.investmentDeal;
    values.push({ selectedDeal: "", selectedCalendlyLink: "" });
    this.setState({ investmentDeal: values });
  };

    eventDescriptionEditorModules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "+1" },
                { indent: "-1" },
            ],
            [{ script: "sub" }, { script: "super" }],
            ["link", "image"],
            ["clean"],
        ],
    };

    eventDescriptionEditorFormats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "script",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "clean",
    ];

    render() {


        const {
            title,
            alias,
            description,
            demo_day_banner,
            demo_day_host_logo,
            datetime,
            investmentDeal,
            errors
          } = this.state;

        const findFormErrors = () => {
          
            const newErrors = {};
            // let regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
            // if (alias !=='' && (regexExp.test(alias) === false)){
            //     // console.log("testing aliassssssssssssss", regexExp.test(alias));
            //     newErrors.alias = "Invalid Demo Day Alias.";
            // }

            if (!demo_day_host_logo?.fileName){
                newErrors.demo_day_host_logo = "The host logo is required.";
            }
            let host_logo_file_type = demo_day_host_logo?.fileName?.split('.').pop();
            // console.log("file typeeeeeeeeeeeeeeeeeeeee", host_logo_file_type)
            if(host_logo_file_type && (host_logo_file_type !== 'jpeg' && host_logo_file_type !== 'jpg' && host_logo_file_type !== 'png')){
                newErrors.demo_day_host_logo = "Invalid file type selected.";
            }

            if (!demo_day_banner?.fileName){
                newErrors.demo_day_banner = "The Demo Day banner is required.";
            }
            let file_type = demo_day_banner?.fileName?.split('.').pop();
            // console.log("file typeeeeeeeeeeeeeeeeeeeee", file_type)

            if(file_type && (file_type !== 'jpeg' && file_type !== 'jpg' && file_type !== 'png')){
                newErrors.demo_day_banner = "Invalid file type selected.";
            }


            let scriptReg = /<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/;
            if (!description || description === "" || scriptReg.test(description) == false){
                newErrors.description = "The Description is required.";
            }
    
            let blankDeals = investmentDeal.filter(item => item.selectedDeal == "");
            if(blankDeals.length > 0){
            // if (investmentDeal[0].selectedDeal== ""){
                newErrors.investmentDeal = "The Investment Deal is required.";
            }

            // if (investmentDeal[0].selectedDeal== ""){
            //     newErrors.investmentDeal = "The Investment Deal is required.";
            // }

            let dealid = investmentDeal.filter(item => item.selectedDeal !== "");
            console.log("investmentDeal", dealid)

            if(dealid.length >0){

                let newData = []; 
                let idArr = []; 
                let isDuplicate = false;
                
                dealid.forEach(item => { 
                    if(item.selectedDeal && !idArr.includes(item.selectedDeal)) { 
                        idArr.push(item.selectedDeal); 
                    
                    } else{
                        isDuplicate =true
                    }
                    })

                    if(isDuplicate === true){
                        newErrors.investmentDeal = "The Investment Deal is duplicate.";
                    }
            }

            // if (!datetime || datetime === "")
            //   newErrors.datetime = "The Scheduled Date is required.";
          
            return newErrors;
          };
    

          const updateKPI = async (e) => {
            this.setState({ errors: {} });
            this.setState({successMessage: "" });
            this.setState({errorMessage: "" });

            const newErrors = findFormErrors();
            if (Object.keys(newErrors).length > 0) {
              this.setState({ errors: newErrors });
            } else {
    
                const token = authHeader();
               
                
                let user_detail = localStorage.getItem("user_info") ? JSON.parse(localStorage.getItem("user_info")):{};
                let created_by = user_detail?.firstname+ " "+user_detail?.lastname;
                let created_user_mail_id = user_detail?.email;

                
                const formData = new FormData();
                let data = {
                    title: this.state.title,
                    alias: this.state.alias,
                    description: this.state.description,
                    status: this.state.selectedStatus?.value,
                    created_by: created_by,
                    created_user_mail_id: created_user_mail_id,
                    deals: JSON.stringify(this.state.investmentDeal),
                }

                if (this.state.demo_day_host_logo?.file) {
                    formData.append('demo_day_host_logo', this.state.demo_day_host_logo?.file);
                }
                if (this.state.demo_day_banner?.file) {
                    formData.append('demo_day_banner', this.state.demo_day_banner?.file);
                }

                let encryptedQuery = encryptDataToArray(data)
                formData.append("q", JSON.stringify(encryptedQuery));


                let config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}/api/demoDay/createDemoDay`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: token,
                    },
                    data: formData
                };
                await axios(config)
                    .then((res) => {
                        if (res.data.status == true) {
                            let messages = this.setState({successMessage: "Demo day added successfully!" });

                            window.scrollTo(0, 0);
                            setTimeout(function () {
                                window.location.href = "/demo-day";
                            }, 4000);
        
                        }else{
                            console.log("failed reponced data", res.data.message);

                            let messages = this.setState({ errorMessage: res.data.message });
                        }
                    })
                    .catch((error) => {
                        // console.log(error)
                    });    
            }
    
        }


        // console.log("investmentDeal data" , this.state.investmentDeal)



        const { is_saving_weeklyBrew } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Create Demo Day | Invstt - Admin</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Demo Day" breadcrumbItem="Create Demo Day" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <AvForm
                                            className="outer-repeater"
                                        >
                                            <div data-repeater-list="outer-group" className="outer">
                                                <div data-repeater-item className="outer">

                                                    {this?.state?.successMessage &&
                                                        < div class="alert alert-success" role="alert">
                                                            {this.state.successMessage}</div>
                                                    }

                                                    {this?.state?.errorMessage &&
                                                        < div class="alert alert-danger" role="alert">
                                                            {this.state.errorMessage}</div>
                                                    }


                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="3">
                                                            <Label htmlFor="title" className="col-form-label">
                                                                Title: <span className="text-danger">*</span>
                                                            </Label>
                                                        </Col>
                                                        <Col lg="9">
                                                            <AvField
                                                                id="title"
                                                                name="title"
                                                                type="text"
                                                                className="form-control"
                                                                // readOnly="true"
                                                                placeholder="Demo Day Title"
                                                                errorMessage="The Demo Day is required."
                                                                validate={{ required: { value: true } }}
                                                                value={this.state.title || ""}
                                                                onChange={this.handleTitleChange}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="3">
                                                            <Label htmlFor="title" className="col-form-label">
                                                                Alias: <span className="text-danger">*</span>
                                                            </Label>
                                                        </Col>
                                                        <Col lg="9">
                                                            <AvField
                                                                id="alias"
                                                                name="alias"
                                                                type="text"
                                                                className="form-control"
                                                                // readOnly="true"
                                                                placeholder="Demo Day Alias"
                                                                errorMessage="The Alias is required."
                                                                validate={{ required: { value: true }, pattern: {value: '^[a-z0-9]+(?:-[a-z0-9]+)*$', errorMessage: 'Invalid Demo Day Alias.'}, }}
                                                                value={this.state.alias || ""}
                                                                onChange={this.handleAliasChange}
                                                            />
                                                            <small>
                                                            <div className="text-danger">{errors?.alias}</div>       
                                                            <strong>Note:</strong> (Only small letter and dash(-) are allowed. E.g : blume-ventures)</small>
                                                            


                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup
                                                        className="mb-4"
                                                        row
                                                        style={{ height: "300px" }}
                                                    >
                                                        <Col lg="3">
                                                            <Label className="col-form-label">
                                                            Description:{" "}
                                                                <span className="text-danger">*</span>
                                                            </Label>
                                                        </Col>
                                                        <Col lg="9">
                                                            <ReactQuill
                                                                id="description"
                                                                style={{ height: "258px" }}
                                                                placeholder="Demo Day Description"
                                                                errorMessage="Required Field"
                                                                validate={{ required: { value: true } }}

                                                                value={this.state.description || ""}
                                                                onChange={content => {
                                                                    this.setState({ description: content });
                                                                }}
                                                                modules={this.eventDescriptionEditorModules}
                                                                formats={this.eventDescriptionEditorFormats}
                                                                required
                                                            />
                                                            <br></br>
                                                            <br></br>
                                                            <small><div className="text-danger">{errors?.description}</div></small>
                                                            
                                                            

                                                        </Col>

                                                    </FormGroup>


                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="3">
                                                            <Label className="col-form-label">Host Logo: <span className="text-danger">*</span>
                                                            </Label>
                                                        </Col>
                                                        <Col lg="9">
                                                        <Input
                                                            type="file"
                                                            accept=".jpeg, .png, .jpg"
                                                            className="form-control"
                                                            placeholder="Select host logo"
                                                            onChange={event => {
                                                            // console.log("event.target.files[0]",event.target.files[0]) 
                                                                this.setState(prevState => {
                                                                    let demo_day_host_logo = Object.assign({}, prevState.demo_day_host_logo);  // creating copy of state variable jasper
                                                                    demo_day_host_logo.file = event.target.files[0];
                                                                    demo_day_host_logo.fileName = event.target.value;    
                                                                    // update the name property, assign a new value 
                                                                    return { demo_day_host_logo };  
                                                                     // return new object jasper object
                                                                    })

                                                            }}
                                                            multiple={false}
                                                           
                                                        />
                                                            <small>
                                                            <div className="text-danger">{errors?.demo_day_host_logo}</div>
                                                            <strong>Note:</strong> (Only jpg, jpeg, png files are allowed. Standard Size: 127px*45px)
                                                            {/* Size: Same size like syndicates */}
                                                            </small>

                                                            
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="3">
                                                            <Label className="col-form-label">Demo day banner: <span className="text-danger">*</span>
                                                            </Label>
                                                        </Col>
                                                        <Col lg="9">
                                                        <Input
                                                            type="file"
                                                            accept=".jpeg, .png, .jpg"
                                                            className="form-control"
                                                            placeholder="Select Demo day banner"
                                                            onChange={event => {
                                                          
                                                                this.setState(prevState => {
                                                                    let demo_day_banner = Object.assign({}, prevState.demo_day_banner);  // creating copy of state variable jasper
                                                                    demo_day_banner.file = event.target.files[0];
                                                                    demo_day_banner.fileName = event.target.value;    
                                                                    // update the name property, assign a new value 
    
                                                                    return { demo_day_banner };  
    
                                                                     // return new object jasper object
                                                                    })

                                                            }}
                                                            multiple={false}
                                                           
                                                        />
                                                        <small><div className="text-danger">{errors?.demo_day_banner}</div>
                                                           <strong>Note:</strong> (Only jpg, jpeg, png files are allowed. Standard Size: 1920px*400px)</small>

                                                            
                                                        </Col>
                                                    </FormGroup>




                                    <div className="inner-repeater mb-4">
                                    <div className="inner form-group mb-0 row">
                                    <Col lg="3">
                                        <Label className="col-form-label">
                                        Investment Deals: <span className="text-danger">*</span>
                                        </Label>
                                    </Col>
                                    <Col
                                        lg={9}
                                        className="inner ms-md-auto"
                                        id="repeater_industry_sector"
                                        >
                                            {/* {console.log("investmentDeal data list", this.state.postsData)} */}

                                        {this.state.investmentDeal.map(
                                            (field, key) => (
                                                <Fragment key={"_field_" + key}>
                                                <div className="mb-3 row align-items-center">
                                                    <Col md="5">
                                                    {/* {console.log("investmentDeal data checking", field.selectedDeal)} */}

                                                    <Select
                                                        id="selectedGroups"
                                                        placeholder="Investment Deals"
                                                        name="deals"
                                                        options={this.state.postsData}
                                                        // styles={styles}
                                                        value={this.state.postsData.filter(function(option) {
                                                            return option.value === field.selectedDeal;
                                                          })}
                                                        onChange={e =>
                                                            this.handleChangeIndustrySector(
                                                                e,
                                                                key
                                                            )
                                                            }
                                                    />

                                                    </Col>
                                                    <Col md="5">
                                                    <AvField
                                                        id="calendly_link"
                                                        type="text"
                                                        name="calendly_link"
                                                        className="inner form-control"
                                                        placeholder="Calendly link"
                                                        value={field.selectedCalendlyLink}
                                                        onChange={e =>
                                                        this.handleChangeIndustrySector(
                                                            e,
                                                            key
                                                        )
                                                        }
                                                    />
                                                    </Col>

                                                    <Col md="2">
                                                    <div className="mt-2 mt-md-0 d-grid">
                                                        <Button
                                                        color="danger"
                                                        title="Remove"
                                                        disabled={investmentDeal.length < 2 ? true : false}
                                                        className="inner"
                                                        onClick={() => {
                                                            this.handleRemoveIndustrySectors(
                                                            key
                                                            );
                                                        }}
                                                        block
                                                        >
                                                        <i className="bx bx-trash fs-3"></i>
                                                        </Button>
                                                    </div>
                                                    </Col>

                                                    
                                                </div>
                                                </Fragment>
                                            )

                                        )}
                                        <small><div className="text-danger">{errors?.investmentDeal}</div></small>


                                    </Col>
                                    </div>
                                    <Row className="justify-content-end">
                                    <Col lg="9">
                                        <Button
                                        title="Add More"
                                        color="success"
                                        className="inner"
                                        onClick={this.handleAddIndustrySectors}
                                        >
                                        <i className="bx bx-plus-circle fs-3" ></i>
                                        </Button>
                                    </Col>
                                    </Row>
                                </div>


                                <FormGroup className="mb-4" row>
                                    <Col lg="3">
                                        <Label className="col-form-label">Status: <span className="text-danger">*</span> </Label>
                                    </Col>
                                    <Col lg="3">
                                  

                                    <Select
                                        id="status_idd"
                                        placeholder="Select Status"
                                        name="status"
                                        className="mb-3"
                                        options={this.state.status}
                                        value={this.state.selectedStatus}
                                        onChange={status_data => this.handleStatusChange(status_data)}

                                    />
                                    </Col>
                                </FormGroup>



                                    </div>
                                </div>
                                            <div data-repeater-list="outer-group" className="outer">
                                                <br></br>
                                                <Row className="justify-content-end ">
                                                    <Col lg={9}>
                                                        {this.state?.error?.description && (
                                                            <h6 className="text-danger mt-2 mb-2">
                                                                {this.state?.error?.description}
                                                            </h6>
                                                        )}
                                                        <Button
                                                            type="submit"
                                                            color="success"
                                                            disabled={is_saving_weeklyBrew ? true : false}
                                                            onClick={(e) => {updateKPI(e) }}>
                                                            Add Demo DAY
                                                        </Button>
                                                        &nbsp;
                                                        <Button
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.location.href = '/demo-day';
                                                            }}
                                                        > Cancel</Button>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </AvForm>
                                        {!!this.props.error ? (
                                            <Alert
                                                color="danger"
                                                className="mt-4"
                                                isOpen={this.state.alert_visible}
                                            >
                                                {this.props.error}
                                            </Alert>
                                        ) : null}
                                        {!!this.props.success ? (
                                            <>
                                                <Alert
                                                    color="success"
                                                    className="mt-4"
                                                    isOpen={this.state.alert_visible}
                                                >
                                                    {this.props.success}
                                                </Alert>
                                            </>
                                        ) : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }

}
CreateDemoDay.propTypes = {
    className: PropTypes.any,
    onAddWeeklyBrew: PropTypes.func,
    error: PropTypes.string,
    success: PropTypes.string,
    onSubmit: PropTypes.func,
};
export default connect()(withRouter(CreateDemoDay));