import axios from "axios";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import { RELATION_OPTIONS } from "helpers/storage_helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { MetaTags } from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { getDealManagers } from "../../../helpers/backend_helper";
const DematList = props => {
  const params = new URLSearchParams(window.location.search);

  let defaultSearchQuery = {
    sortOrder: -1,
    demat_status: "incomplete",
  };
  for (let [key, value] of params) {
    defaultSearchQuery[key] = value;
  }

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);

  const [dematList, setDematList] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const [pageno, setPageno] = useState(1);
  const [totalList, setTotalList] = useState(0);
  // create a state variable for SM/IM data
  const [investmentManagersFilter, setinvestmentManagersFilter] = useState([]);
  const [investmentManagers, setinvestmentManagers] = useState([]);

  const KYC_OPTIONS = [
    {
      value: "incomplete",
      label: "Intent Shared, KYC Initiated",
    },
    {
      value: "pending",
      label: "KYC Complete, Approval Pending",
    },
    { value: "approved", label: "KYC Approved" },

    {
      value: "rejected",
      label: "KYC Disapproved",
    },
    {
      value: "cancelled",
      label: "KYC Process Dropped",
    },
  ];

  const DEMAT_STATUS_OPTIONS_WITHOUT_APPROVED = [
    {
      value: "incomplete",
      label: "Demat Pending",
      option: "incomplete",
    },
    {
      value: "pending",
      label: "Demat Filled, Approval Pending",
      option: "pending",
    },
    {
      value: "disapproved",
      label: "Demat Disapproved",
      option: "disapproved",
    },
    {
      value: "dropped",
      label: "Demat Dropped",
      option: "dropped",
    },
  ];

  const fetchDematList = async (searchQuery, pageno) => {
    try {
      setLoading(true);
      setDematList([]);

      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/demat/get-all`,
        {
          ...searchQuery,
          pageno,
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      setDematList(response?.data?.data?.allDemats || []);
      setTotalList(response?.data?.data?.totalDemats || 0);
      setLoading(false);
    } catch (error) {
      setDematList([]);
      setTotalList(0);
      setLoading(false);
    }
  };

  const getDealManagerData = async () => {
    getDealManagers()
      .then(response => {
        let managers = response.managers;
        (managers = managers.sort((a, b) => {
          if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
            return -1;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return 0;
        })),
          setinvestmentManagers(managers);

        managers = managers?.filter((el) => el?.status !== "Declined")?.map(mngr => {
          return { value: mngr._id, label: mngr.name };
        });
        setinvestmentManagersFilter(managers);
      })
      .catch(error => {
        console.trace(error);
        setinvestmentManagers([]);
      });
  };

  useEffect(() => {
    getDealManagerData();
    fetchDematList(searchQuery, pageno);
  }, [searchQuery, pageno]);

  const defaultSorted = [
    {
      dataField: "updatedAt",
      order: "desc",
    },
  ];

  const dematListColumns = [
    {
      text: "Name",
      dataField: "username",
      sort: false,
      filterValue: (cell, demat) =>
        `${demat?.user?.firstname || ""} ${demat?.user?.lastname || ""}`.trim(),
      filter: textFilter({
        defaultValue: params?.get("username")?.toLowerCase(),
        delay: 1500,
      }),
      formatter: (cell, demat) => (
        <>
          <h5 className="font-size-14 mb-1">
            <div className="fw-bold">
              {`${demat?.user?.firstname || ""} ${
                demat?.user?.lastname || ""
              }`.trim()}
            </div>
          </h5>
        </>
      ),
    },
    {
      text: "Applicant Name",
      dataField: "applicant_name",
      sort: false,
      filter: textFilter(),
      formatter: (cell, demat) => <>{demat?.applicant_name || ""}</>,
    },
    {
      text: "Last Updated Date",
      dataField: "updatedAt",
      sort: true,
      formatter: (cell, demat) => (
        <>
          {demat?.demat?.updatedAt
            ? moment(demat?.demat?.updatedAt).format("DD MMM, YYYY")
            : "-"}
        </>
      ),
    },
    {
      text: "Relation",
      dataField: "relation",
      sort: false,
      filter: selectFilter({
        options: RELATION_OPTIONS,
        defaultValue: RELATION_OPTIONS?.find(
          status =>
            status?.option?.toLowerCase() ===
            params?.get("relation")?.toLowerCase()
        )?.value,
        delay: 1500,
      }),
      formatter: (cell, demat) => (
        <>
          {RELATION_OPTIONS?.find(opt => opt?.value === demat?.relation)
            ?.label || "Self"}
        </>
      ),
    },
    {
      text: "Client ID",
      dataField: "client_id",
      sort: false,
      filter: textFilter(),
      formatter: (cell, demat) => <>{demat?.demat?.client_id || "-"}</>,
    },
    {
      text: "DP ID",
      dataField: "dp_id",
      sort: false,
      filter: textFilter(),
      formatter: (cell, demat) => <>{demat?.demat?.dp_id || "-"}</>,
    },
    {
      text: "Status",
      dataField: "demat_status",
      sort: false,
      filter: selectFilter({
        options: DEMAT_STATUS_OPTIONS_WITHOUT_APPROVED,
        defaultValue: DEMAT_STATUS_OPTIONS_WITHOUT_APPROVED?.find(
          status =>
            status?.option?.toLowerCase() ===
            "incomplete"
        )?.value,
        delay: 1500,
      }),
      formatter: (cell, demat) => {
        let demat_status = "";
        if (demat?.demat?.demat_status) {
          demat_status = demat?.demat?.demat_status;
        } else {
          demat_status = "incomplete";
        }

        return (
          <>
            {DEMAT_STATUS_OPTIONS_WITHOUT_APPROVED?.find(
              opt => opt?.value === demat_status
            )?.label || "-"}
          </>
        );
      },
    },
    {
      text: "IM/DEM",
      dataField: "investment_manager_id",
      sort: true,
      filter: selectFilter({
        options: investmentManagersFilter,
        delay: 1500,
      }),
      formatter: (cellContent, user) => {
        let value = [];
        let investment_manager_name = user?.investment_manager_data?.firstname;
        if (
          investment_manager_name &&
          user?.investment_manager_data?.lastname
        ) {
          investment_manager_name =
            investment_manager_name +
            " " +
            user?.investment_manager_data?.lastname;
        }

        if (investment_manager_name) {
          value.push(investment_manager_name);
        }

        let syndicate_manager_name =
          user?.syndicate_manager_info?.syndicate_manager_detail?.firstname;

        if (
          syndicate_manager_name &&
          user?.syndicate_manager_info?.syndicate_manager_detail?.lastname
        ) {
          syndicate_manager_name =
            syndicate_manager_name +
            " " +
            user?.syndicate_manager_info?.syndicate_manager_detail?.lastname;
        }
        if (syndicate_manager_name) {
          value.push(syndicate_manager_name);
        }
        value = value.toString();
        return <>{value}</>;
      },
    },
    // {
    //   text: "KYC Status",
    //   dataField: "kyc_status",
    //   sort: false,
    //   // filter: selectFilter({
    //   //   options: KYC_OPTIONS,
    //   //   defaultValue: KYC_OPTIONS?.find(
    //   //     status =>
    //   //       status?.value?.toLowerCase() ===
    //   //       params?.get("kyc_status")?.toLowerCase()
    //   //   )?.value,
    //   //   delay: 1500,
    //   // }),
    //   formatter: (cell, demat) => {
    //     // console.log(demat);
    //     let kyc_status = "";
    //     if (demat?.kyc_status) {
    //       kyc_status = demat?.kyc_status;
    //     }
    //     if(kyc_status=="incomplete" || !kyc_status){
    //       if(!demat?.onboarding_status){
    //         kyc_status="NA";
    //       }else{
    //         kyc_status="incomplete";
    //       }
    //     }

    //     kyc_status = KYC_OPTIONS?.find(opt => opt?.value === kyc_status)
    //     ?.label || "-";

    //     return (
    //       <>
    //         {kyc_status}
    //       </>
    //     );
    //   },
    // },
  ];

  const onTableChange = (action, { filters, sortOrder, sortField }) => {
    let searchQuery = Object.entries(filters).reduce(
      (prev, [key, value]) => ({ ...prev, [key]: value?.filterVal }),
      {}
    );

    searchQuery.sortOrder = sortOrder === "asc" ? 1 : -1;

    setSearchQuery(searchQuery);
  };

  const customTotal = (from, to, size) => {
    if (loading) {
      return <></>;
    }

    if (!size) {
      return <></>;
    }

    let className = "react-bootstrap-table-pagination-total";
    let text = `Showing rows ${from || 0} to ${to || 0} of ${size || 0}`;

    return (
      <>
        <span className={className}>{text}</span>
      </>
    );
  };

  const NoDataIndication = () => {
    if (loading) {
      return (
        <>
          <h5>
            <Spinner animation="border" size="sm" /> Loading . . .
          </h5>
        </>
      );
    }

    if (!dematList?.length) {
      return (
        <>
          <h5>Data Not Available</h5>
        </>
      );
    }

    return <></>;
  };

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "Next page",
    prePageTitle: "Previos page",
    firstPageTitle: "First page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: totalList,
    disablePageTitle: false,
    paginationTotalRenderer: customTotal,
    onPageChange: async (page, size) => {
      setPageno(page);
    },
  };

  const exportCSV = async () => {
    setExportLoading(true)
    // const authUser = JSON.parse(localStorage.getItem("authUser"));
    // const saas_parent_id = authUser?.userInfo?.saas_parent_id;
    // const is_super_admin = authUser?.userInfo?.is_super_admin;
    // const show_all_saas_data = JSON.parse(
    //   localStorage.getItem("roleWiseAccess")
    // )?.show_all_saas_data;
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/admin/demat/export-demat`,
        {},
        configHeaders,
        { responseType: "blob" }
      )
      .then(response => {
        setExportLoading(false)
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data?.signedUrl;
          link.setAttribute("download", "demat.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          alert("CSV exported successfully.");
        }
      })
      .catch(error => {
        setExportLoading(false)
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Demat List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Row className="d-flex align-items-center">
            <Col sm="6">
              <h5 className="text-uppercase">Demat List</h5>
            </Col>
            <Col sm="6" className="text-end">
              {/* <span>Startups / Startup List</span> */}
              <div className="mx-1 mb-2">
                {exportLoading ? (
                  <Button
                    className="btn btn-success"
                    onClick={() => exportCSV()}
                  >
                    <Spinner animation="border" size="sm" /> Export CSV
                  </Button>
                ) : (
                  <Button
                    className="btn btn-success"
                    onClick={() => exportCSV()}
                  >
                    <i className="bx bx-export fs-4"></i> Export CSV
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-0">
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={dematListColumns}
                    data={dematList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={dematListColumns}
                        data={dematList}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                    noDataIndication={<NoDataIndication />}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DematList;
