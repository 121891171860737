import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import faDownload from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { isEmpty, isEqual, size, startCase } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  // Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { Form as Form1 } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";

import {
  getKyc as getUsersAll,
  getUserAdditionalInfo,
  addNewUser,
  updateUser,
  deleteUser,
  getAllUsersForReference,
  saveUserExportMail,
  updatePartnerUserInfoUserList,
  getAllPartnersForEmployees,
  getDealManagers,
  toCommaFormat,
  getInvestorKycUpdateLogs,
} from "../../../helpers/backend_helper";
import axios from "axios";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { loginAsUser } from "../../../helpers/backend_helper";

// Loader for data fetch
import DataFetchLoader from "../../../components/Common/DataFetchLoader";

import {
  CountryData,
  StateData,
  CityData,
} from "../../../components/Common/CityStateCountryComponent";

// For User CSV Export
import { CSVDownload } from "react-csv";
import ReactQuill from "react-quill";
import SyndicateOptions from "./SyndicateOptions";
import LedgerDetails from "./CALedger/LedgerDetails";
import SignupLedger from "./CALedger/SignupLedger";

function sortDataInAsc(history) {
  history.sort(function (a, b) {
    let date_1 = moment(a.approvedAt);
    let date_2 = moment(b.approvedAt);
    return date_1 - date_2;
  });

  return history;
}

function calculateBaseConsentAmountFeeINR(values) {
  let {
    consent_amount,
    transaction_fees,
    iv_mapped_transaction_fees,
    iv_mapped_transaction_fees_cap_table,
    transaction_fees_cap_table,
    min_amount_cap_amount_tab,
  } = values;
  let managementFees = 0;
  // let gstPercent = parseInt(gst) / 100;
  // let transaction_fee_percent = parseInt(transaction_fee) / 100;

  // let amountCalculated =
  //   parseInt(consent_amount) *
  //   (transaction_fee_percent + transaction_fee_percent * gstPercent);

  // return amountCalculated;
  if (
    !parseInt(min_amount_cap_amount_tab) ||
    consent_amount < parseInt(min_amount_cap_amount_tab)
  ) {
    let in_syndicate_transaction_fees =
      iv_mapped_transaction_fees &&
      Object.values(iv_mapped_transaction_fees)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees)?.[0])
        : "";
    if (
      in_syndicate_transaction_fees ||
      (transaction_fees && parseInt(transaction_fees))
    ) {
      //transaction fee for syndicate i.e. 2% +GST(18%) =2.36% total trans fee
      let managementFeesData =
        ((in_syndicate_transaction_fees
          ? in_syndicate_transaction_fees
          : parseInt(transaction_fees)) +
          ((in_syndicate_transaction_fees
            ? in_syndicate_transaction_fees
            : parseInt(transaction_fees)) *
            18) /
            100) /
        100;
      managementFees = consent_amount * managementFeesData;
    }
  } else if (consent_amount >= parseInt(min_amount_cap_amount_tab)) {
    //transaction fee for direct cap tab i.e. 2% +GST(18%) =2.36% total trans fee
    let in_captab_transaction_fees =
      iv_mapped_transaction_fees_cap_table &&
      Object.values(iv_mapped_transaction_fees_cap_table)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees_cap_table)?.[0])
        : "";

    if (
      in_captab_transaction_fees ||
      (transaction_fees_cap_table && parseInt(transaction_fees_cap_table))
    ) {
      let managementFeesData =
        ((in_captab_transaction_fees
          ? in_captab_transaction_fees
          : parseInt(transaction_fees_cap_table)) +
          ((in_captab_transaction_fees
            ? in_captab_transaction_fees
            : parseInt(transaction_fees_cap_table)) *
            18) /
            100) /
        100;
      managementFees = consent_amount * managementFeesData;
    }
  }

  console.log("Management fee calculated:", managementFees);
  return managementFees;
}

function fetchConsentAmount({ baseAmount, fee }) {
  return parseInt(baseAmount) + parseInt(fee);
}

function calculateBaseConsentAmountFeeForeign({
  consent_amount_global,
  transaction_fees,
  iv_mapped_transaction_fees,
  iv_mapped_transaction_fees_cap_table,
  transaction_fees_cap_table,
  min_amount_cap_amount_tab_usd,
}) {
  // let transaction_fee_percent = parseInt(transaction_fee) / 100;
  // let amountCalculated = transaction_fee_percent * parseInt(consent_amount);
  // return amountCalculated;
  let managementFees = 0;
  if (
    !parseInt(min_amount_cap_amount_tab_usd) ||
    consent_amount_global < parseInt(min_amount_cap_amount_tab_usd)
  ) {
    let in_syndicate_transaction_fees =
      iv_mapped_transaction_fees &&
      Object.values(iv_mapped_transaction_fees)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees)?.[0])
        : "";

    if (
      in_syndicate_transaction_fees ||
      (transaction_fees && parseInt(transaction_fees))
    ) {
      //transaction fee for syndicate i.e. 2%  =2.00% total trans fee
      let managementFeesData =
        (in_syndicate_transaction_fees
          ? in_syndicate_transaction_fees
          : parseInt(transaction_fees)) / 100;
      managementFees = consent_amount_global * managementFeesData;
    }
  }
  if (
    parseInt(min_amount_cap_amount_tab_usd) &&
    consent_amount_global >= parseInt(min_amount_cap_amount_tab_usd)
  ) {
    //transaction fee for direct cap tab i.e. 2%  =2.00% total trans fee
    let in_captab_transaction_fees =
      iv_mapped_transaction_fees_cap_table &&
      Object.values(iv_mapped_transaction_fees_cap_table)?.[0]
        ? parseInt(Object.values(iv_mapped_transaction_fees_cap_table)?.[0])
        : "";

    if (
      in_captab_transaction_fees ||
      (transaction_fees_cap_table && parseInt(transaction_fees_cap_table))
    ) {
      let managementFeesData =
        (in_captab_transaction_fees
          ? in_captab_transaction_fees
          : parseInt(transaction_fees_cap_table)) / 100;
      managementFees = consent_amount_global * managementFeesData;
    }
  }

  return managementFees;
}

function numberToEnglish(n) {
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words,
    and = "and";

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
}

function getMomentDateString(dateStr) {
  let date = moment(dateStr).format("MMM DD, YYYY");
  let time = moment(dateStr).format("hh:mm A");

  return `${date} ${time}`;
}

function getInvestorFolio(name, applicant_email, user_email) {
  return name && !applicant_email
    ? `${name} ${user_email}`
    : !name && applicant_email
    ? `${email} (Name not available)`
    : `${name}, ${applicant_email}`;
}

const DeleteRequestList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [kycdata, setKycdata] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [csvFile, setCsvFile] = useState({});
  const [formedCSVData, setFormedCSVData] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [modal, setModal] = useState(false);
  const [is_admin, setIsAdmin] = useState("false");
  const [user, setUser] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [execution, setExecution] = useState(0);
  const [modelCommentData, setModelCommentData] = useState([]);
  const [showCommentModel, setShowCommentModel] = useState(false);
  const [investmentManagers, setinvestmentManagers] = useState([]);
  const [relatives, setRelatives] = useState(null);
  const [currentlySelectedRelative, setCurrentySelectedRelative] = useState({
    label: "Self",
    value: "self",
  });
  const [saasAliasError, setSaasAliasError] = useState(null);

  const [roleWiseAccess, setRoleWiseAccess] = useState([]);
  const [roleId, setRoleId] = useState();

  const [referenceUserList, setReferenceUserList] = useState([]);

  const [loginUser, setLoginUserData] = useState({});
  const [logsModalData, setLogsModalData] = useState({});
  const [logsModalOpen, setLogsModalOpen] = useState(false);
  const [logsDateFormat] = useState("DD-MMM-YYYY hh:mm:ss A");
  const [userLoggedDetails, setUserLoggedDetails] = useState("");
  const [saasPartnersList, setSaasPartnersList] = useState([]);
  const [selectedSaasPartner, setSelectedSaasPartner] = useState({
    value: JSON.parse(localStorage.getItem("authUser"))?.userInfo
      ?.saas_parent_id,
  });

  const [allPartners, setAllPartners] = useState([]);
  const [allDeleteRequests, setAllDeleteRequests] = useState([]);
  const [access, setAccess] = useState({});
  const [selectRequestStatus, setSelectRequestStatus] = useState([
    {
      value: "Pending",
      label: "Pending",
      fmode: "Pending",
    },
    {
      value: "Deleted",
      label: "Deleted",
      fmode: "Deleted",
    },
    { value: "Rejected", label: "Rejected", fmode: "Rejected" },
  ]);

  const [saasFilterOptions, setSaasFilterOptions] = useState([]);

  const setLogsModal = logsModalOpen => {
    if (!logsModalOpen) {
      setLogsModalData({});
    }
    setLogsModalOpen(logsModalOpen);
  };

  const [show_all_saas_data, setShowAllSaasData] = useState(
    JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data
  );

  let params = new URLSearchParams(window.location.search);
  let fmode = params.get("fmode");

  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let is_super_admin = authUser?.userInfo?.is_super_admin;
  const getAllDeleteRequests = async (page, searchQuery) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/public/getAllDeleteRquests?page=${page}${searchQuery}`
        //&saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`
      )
      .then(response => {
        let requestData = response?.data?.allDeleteRequests;
        let dataSize = response?.data?.dataSize;
        setAllDeleteRequests(requestData);
        setTotal(dataSize);
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
    // setAllDeleteRequests(response.data.allDeleteRequests);
  };
  useEffect(() => {
    getAllDeleteRequests(page, searchQuery);
    return () => {};
  }, [page, load, searchQuery]);
  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    const loginUserInfo = JSON.parse(localStorage.getItem("authUser")).userInfo;
    setLoginUserData(loginUserInfo);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
        setShowAllSaasData(res.data.roleWiseAccess?.show_all_saas_data);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
    return () => {};
  }, []);

  useEffect(() => {
    if (!modal) {
      getAllUsersForReference()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let {
                _id,
                firstname,
                lastname,
                email,
                saas_fullname,
                saas_name,
                saas_alias,
              } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                let temp2 = saas_name;
                if (temp2) {
                  temp2 += ` (${saas_fullname})`;
                } else {
                  temp2 = saas_fullname || saas_alias;
                }
                temp = temp2 || temp;

                saasPartners.push({
                  value: _id,
                  label: temp,
                });
              }

              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          setReferenceUserList(users);
          setSaasPartnersList(saasPartners);
          setSaasFilterOptions(saasPartners);
        })
        .catch(error => {
          console.trace(error);
          setReferenceUserList([]);
          setSaasPartnersList([]);
          setSaasFilterOptions([]);
        });
    }
    return () => {};
  }, [modal]);

  useEffect(() => {
    if (!modal) {
      getAllPartnersForEmployees()
        .then(response => {
          let partners = response.data || [];
          partners = partners
            .map(partner => {
              if (!partner) return null;

              let {
                _id,
                userName,
                firstname,
                lastname,
                email,
                is_saas_parent,
                saas_fullname,
                saas_name,
                saas_alias,
              } = partner;
              if (!_id) return null;
              let temp =
                `${firstname || ""} ${lastname || ""}`.trim() || userName;
              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }
              if (!temp) return null;

              if (is_saas_parent) {
                let temp2 = saas_fullname;
                if (temp2) {
                  temp2 += ` (${saas_name})`;
                } else {
                  temp2 = saas_alias;
                }
                temp = temp2 || temp;
              }

              return { value: _id, label: temp };
            })
            .filter(data => data);
          setAllPartners(partners);
        })
        .catch(error => {
          console.trace(error);
          setAllPartners([]);
        });

      getDealManagers()
        .then(response => {
          let managers = response.managers;
          managers = managers.sort((a, b) => {
            if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
              return -1;
            }
            if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          managers = managers.map(mngr => {
            return { value: mngr._id, label: mngr.name };
          });
          setinvestmentManagers(managers);
        })
        .catch(error => {
          console.trace(error);
          setinvestmentManagers([]);
        });
    }
    return () => {};
  }, [modal]);

  // useEffect(() => {
  //   fetchAndSetKYCData(page, searchQuery, show_all_saas_data);

  //   return () => {};
  // }, [page, load, searchQuery, show_all_saas_data]);

  // const fetchAndSetKYCData = async (page, searchQuery, show_all_saas_data) => {
  //   setLoading(true);

  //   const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  //   setExecution(1);
  //   let exeflag = 0;
  //   if (fmode && execution) {
  //     exeflag = 1;
  //   } else if (!fmode) {
  //     exeflag = 1;
  //   }
  //   if (exeflag) {
  //     getUsersAll(
  //       `?page=${page}${searchQuery}&saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`
  //     )
  //       .then(response => {
  //         // let kycdata = response?.data?.kycrelationdata;
  //         // let dataSize = response?.data?.dataSize;
  //         // //console.log("USERS:", users.length);
  //         // setKycdata(kycdata);
  //         // setTotal(dataSize);
  //         // setLoading(false);
  //       })
  //       .catch(error => {
  //         // console.log(error);
  //         // setLoading(false);
  //       });
  //   }
  // };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/role/getroles`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      })
      .then(res => {
        let arr = [];
        res.data.roleWiseAccess?.map(data => {
          let obj = {};
          obj.label = data.name;
          obj.value = data._id;
          arr.push(obj);
        });
        setRoleWiseAccess(arr);
      })
      .catch(err => {
        // console.log(err)
      });
    return () => {};
  }, [selectedSaasPartner]);

  // to check saas-alias regex
  useEffect(() => {
    if (user.saas_alias !== "/") {
      if (!RegExp(/^[a-z0-9-]{1,}$/g).test(user.saas_alias)) {
        setSaasAliasError(
          "Invalid SAAS Alias, only small-case alphabets (a-z), numbers (0-9) and dash (-) allowed"
        );
      } else {
        setSaasAliasError(null);
      }
    } else {
      setSaasAliasError(null);
    }
    return () => {};
  }, [user]);

  const handleDeleteUser = async user => {
    await Swal.fire({
      title: "Do you want to delete this user ?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (user._id !== undefined) {
          await deleteUser(user).then(() => {
            setLoad(prev => !prev);
          });
        }
      }
    });
  };

  const sortedUsers = users => {
    return users.sort((a, b) =>
      `${a?.kyc_details?.applicant_name}`
        .toLowerCase()
        .localeCompare(`${a?.kyc_details?.applicant_name}`.toLowerCase())
    );
  };

  // const generateCsvDataForAllSaaS = async () => {
  //   setExportLoading(true);
  //   const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  //   const token = authHeader();
  //   let configHeaders;
  //   if (token) {
  //     configHeaders = {
  //       headers: {
  //         Authorization: token,
  //       },
  //     };
  //   }
  //   let response = await axios.get(
  //     `${process.env.REACT_APP_API_URL}/api/admin/userReport/getkycAllData?saas_parent_id_role=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`,
  //     configHeaders
  //   );

  //   if (response.data.status === true) {
  //     let kycExportData = response.data.data.kycrelationdata;
  //     kycExportData = kycExportData.map((item, index) => ({
  //       id: index,
  //       ...item,
  //     }));

  //     // removing similar records and keeping only the final status records
  //     let tempData = {};
  //     let name_entries = {};
  //     // kycExportData.forEach(item => {
  //     //   let name = `${item?.kyc_details?.applicant_name}`.split(" ").join("_");
  //     //   let relative_name = `${item?.applicant_name}`.split(" ").join("_");
  //     //   let tempName = `${name}.${relative_name}`;

  //     //   if (typeof name_entries[`${tempName}`] === "undefined") {
  //     //     name_entries[`${tempName}`] = item?.kyc_status.toLowerCase();

  //     //     if (getCAstatus(item?.kyc_status) !== "")
  //     //       tempData[`${tempName}`] = item;

  //     //     if (getOnboardingStatus(item?.kyc_status) !== "")
  //     //       tempData[`${tempName}`] = item;
  //     //   } else {
  //     //     if (getCAstatus(item?.kyc_status) !== "")
  //     //       tempData[`${tempName}`] = item;

  //     //     if (getOnboardingStatus(item?.kyc_status) !== "")
  //     //       tempData[`${tempName}`] = item;
  //     //   }
  //     // });

  //     // kycExportData = Object.values(tempData);
  //     // setUsers(kycExportData);
  //     if (kycExportData.length > 0) {
  //       let csvData = [],
  //         headers = [
  //           "Name",
  //           "Applicant Name",
  //           "Investor Folio",
  //           "Primary Email",
  //           "Primary Mobile",
  //           "Syndicate Manager Name",
  //           "Investment Manager Name",
  //           "Individual/Entity",
  //           "Syndicate Name",
  //           "Onboarding Status",
  //           "C.A. Status",
  //           "Relation",
  //           "Nationailty",
  //           "PEP Status",
  //           "Occupation",
  //           "Father/Spouse Name",
  //           "DOB",
  //           "Pan Number",
  //           "Permanent Address",
  //           "Communication Address same as Permanent Address",
  //           "Communication Address",
  //           "Overseas Address",
  //           "Gross Income",
  //           "Bank Name",
  //           "Bank Address",
  //           "Bank Account Number",
  //           "Bank Account Type",
  //           "Bank SWIFT Code",
  //           "Name In Bank Account",
  //           "Application Committment",
  //           "Application Committment (in words)",
  //           "IFSC Code",
  //           "City and Country Of Birth",
  //           "Is US Person?",
  //           "Is Citizen of Country Other than India",
  //           "Is Resident for TAX purpose in Country other than India",
  //           "Country 1",
  //           "Country 2",
  //           "Country 3",
  //           "TIN 1",
  //           "TIN 2",
  //           "TIN 3",
  //           "Nominee 1 Name",
  //           "Nominee 1 Date of Birth",
  //           "Is Nominee 1 Minor",
  //           "Nominee 1 Share (%)",
  //           "Nominee 1 Relationship",
  //           "Nominee 1 Address",
  //           "Nominee 1 Guardian Name",
  //           "Nominee 1 Guardian DOB",
  //           "Nominee 1 Guardian Relationship with Nominee",
  //           "Nominee 1 Guardian Address",
  //           "Nominee 2 Name",
  //           "Nominee 2 Date of Birth",
  //           "Is Nominee 2 Minor",
  //           "Nominee 2 Share (%)",
  //           "Nominee 2 Relationship",
  //           "Nominee 2 Address",
  //           "Nominee 2 Guardian Name",
  //           "Nominee 2 Guardian DOB",
  //           "Nominee 2 Guardian Relationship with Nominee",
  //           "Nominee 2 Guardian Address",
  //           "Nominee 3 Name",
  //           "Nominee 3 Date of Birth",
  //           "Is Nominee 3 Minor",
  //           "Nominee 3 Share (%)",
  //           "Nominee 3 Relationship",
  //           "Nominee 3 Address",
  //           "Nominee 3 Guardian Name",
  //           "Nominee 3 Guardian DOB",
  //           "Nominee 3 Guardian Relationship with Nominee",
  //           "Nominee 3 Guardian Address",
  //           "Overseas Address",
  //           "Wish to Nominate",
  //           "Source of Funds",
  //           "Date/Time",
  //         ];

  //       csvData.push(headers);
  //       let sorted = sortedUsers(kycExportData || []);
  //       sorted.forEach((invt, i) => {
  //         csvData.push([
  //           `${invt?.user?.fullname || ""}`,
  //           `${
  //             invt?.applicant_name &&
  //             !invt?.applicant_name.toLowerCase().includes("undefined")
  //               ? invt?.applicant_name
  //               : invt?.user?.fullname || ""
  //           }`,
  //           `${getInvestorFolio(
  //             invt?.user?.fullname,
  //             invt?.mail_id,
  //             invt?.user?.email
  //           )}`,
  //           `${invt?.mail_id || invt?.user?.email || ""}`,
  //           `${invt?.contact_number || ""}`,
  //           `${
  //             invt?.user?.saas_parent?.syndicate_manager_detail?.fullname || ""
  //           }`,
  //           `${invt?.user?.investment_manager_detail?.fullname || ""}`,
  //           `${invt?.kyc_type || ""}`,
  //           `${invt?.saas_name || ""}`,
  //           `${invt?.onboarding_status || ""}`,
  //           `${invt?.ca_status || ""}`,
  //           `${invt?.relation || ""}`,
  //           `${invt?.nationality || ""}`,
  //           `${invt?.politically_exposed_person_status || ""}`,
  //           `${invt?.individual_steps?.investment_details?.occupation || ""}`,
  //           `${invt?.father_husband_name || ""}`,
  //           `${invt?.dob || ""}`,
  //           `${invt?.pan_number || ""}`,
  //           `${
  //             invt?.permanent_address_extra?.street_address ||
  //             invt?.permanent_address_extra?.address_line_2 ||
  //             ""
  //           }`,
  //           `${invt?.communication_address_same || ""}`,
  //           `${invt?.communication_address || invt?.address || ""}`,
  //           `${invt?.overseas_address || ""}`,
  //           `${
  //             invt?.individual_steps?.investment_details?.gross_annual_income ||
  //             ""
  //           }`,
  //           `${invt?.bank_name || ""}`,
  //           `${invt?.bank_address || ""}`,
  //           `${invt?.account_number || ""}`,
  //           `${invt?.bank_type || ""}`,
  //           `${invt?.swift_code || ""}`,
  //           `${invt?.beneficiary_name || ""}`,
  //           `${
  //             invt?.individual_steps?.investment_details
  //               ?.total_commitment_over_five_Years || ""
  //           }`,
  //           `${
  //             numberToEnglish(
  //               `${invt?.individual_steps?.investment_details?.total_commitment_over_five_Years}`
  //             ) || ""
  //           }`,
  //           `${invt?.ifsc_code || ""}`,
  //           `${invt?.city || ""} ${invt?.country || ""}`,
  //           `${invt?.individual_steps?.fatca_crs?.is_us_person || ""}`,
  //           `${
  //             invt?.individual_steps?.fatca_crs?.is_citizenship_outside_india ||
  //             ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.fatca_crs?.is_outside_tex_residency || ""
  //           }`,
  //           `${invt?.individual_steps?.fatca_crs?.country_1 || ""}`,
  //           `${invt?.individual_steps?.fatca_crs?.country_2 || ""}`,
  //           `${invt?.individual_steps?.fatca_crs?.country_3 || ""}`,
  //           `${invt?.individual_steps?.fatca_crs?.tin_1 || ""}`,
  //           `${invt?.individual_steps?.fatca_crs?.tin_2 || ""}`,
  //           `${invt?.individual_steps?.fatca_crs?.tin_3 || ""}`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[0]?.name || ""
  //           }`,
  //           `${invt?.individual_steps?.nominee?.nominee_details[0]?.dob || ""}`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[0]?.dob &&
  //             moment(
  //               invt?.individual_steps?.nominee?.nominee_details[0]?.dob
  //             ).isValid()
  //               ? moment().diff(
  //                   invt?.individual_steps?.nominee?.nominee_details[0]?.dob,
  //                   "years"
  //                 ) < 18
  //                 ? "Yes"
  //                 : "No"
  //               : ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[0]?.share || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[0]
  //               ?.relation_with_contributor || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[0]?.address || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[0]?.name || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[0]?.dob || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[0]
  //               ?.relation_with_nominee || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[0]?.address ||
  //             ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[1]?.name || ""
  //           }`,
  //           `${invt?.individual_steps?.nominee?.nominee_details[1]?.dob || ""}`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[1]?.dob &&
  //             moment(
  //               invt?.individual_steps?.nominee?.nominee_details[1]?.dob
  //             ).isValid()
  //               ? moment().diff(
  //                   invt?.individual_steps?.nominee?.nominee_details[1]?.dob,
  //                   "years"
  //                 ) < 18
  //                 ? "Yes"
  //                 : "No"
  //               : ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[1]?.share || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[1]
  //               ?.relation_with_contributor || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[1]?.address || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[1]?.name || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[1]?.dob || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[1]
  //               ?.relation_with_nominee || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[1]?.address ||
  //             ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[2]?.name || ""
  //           }`,
  //           `${invt?.individual_steps?.nominee?.nominee_details[2]?.dob || ""}`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[2]?.dob &&
  //             moment(
  //               invt?.individual_steps?.nominee?.nominee_details[2]?.dob
  //             ).isValid()
  //               ? moment().diff(
  //                   invt?.individual_steps?.nominee?.nominee_details[2]?.dob,
  //                   "years"
  //                 ) < 18
  //                 ? "Yes"
  //                 : "No"
  //               : ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[2]?.share || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[2]
  //               ?.relation_with_contributor || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.nominee_details[2]?.address || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[2]?.name || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[2]?.dob || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[2]
  //               ?.relation_with_nominee || ""
  //           }`,
  //           `${
  //             invt?.individual_steps?.nominee?.guardian_details[2]?.address ||
  //             ""
  //           }`,
  //           `${
  //             invt?.overseas_address_extra?.street_address ||
  //             invt?.overseas_address_extra?.address_line_2 ||
  //             ""
  //           }`,
  //           `${invt?.individual_steps?.nominee?.is_wish_nominee || ""}`,
  //           `${invt?.individual_steps?.investment_details?.source_of_funds}`,
  //           `${getMomentDateString(invt?.date)}`,
  //         ]);
  //       });

  //       setFormedCSVData(csvData);
  //       setExportLoading(false);
  //       setFormedCSVData([]);
  //     } else {
  //       setFormedCSVData([]);
  //       setExportLoading(false);
  //     }
  //   }
  // };

  const handleUserLogin = async user => {
    //login admin as user
    let loggedUser;
    let url = process.env.REACT_APP_FRONTEND_URL;
    if (!url) {
      url = window.location.protocol + "//" + window.location.hostname;
    }

    try {
      loggedUser = await loginAsUser({ userId: user._id });
    } catch (err) {
      // console.log(err.response);
      return false;
    }

    if (loggedUser) {
      window.open(url + "/loginAsUser/" + loggedUser.token);
    }
  };

  const defaultSorted = [
    {
      dataField: "date", //  if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const userListColumns = [
    {
      text: "Name",
      dataField: "name",
      sort: false,
      filterValue: (cell, allDeleteRequests) => `${allDeleteRequests?.name}`,
      filter: textFilter({
        // defaultValue: history.location.search.split("&name=")[1],
        delay: 1500,
      }),
      formatter: (cellContent, allDeleteRequests) => (
        <>
          <h5 className="font-size-14 mb-1">
            <div className="fw-bold">{allDeleteRequests?.name}</div>
          </h5>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter(),
      formatter: (cellContent, allDeleteRequests) => (
        <>{allDeleteRequests?.email}</>
      ),
    },

    // {
    //   dataField: "relation",
    //   text: "Relation",
    //   formatter: (cellContent, user) => <>{user?.relation}</>,
    // },
    // {
    //   dataField: "saas_parent_id",
    //   text: "Syndicate Name",
    //   sort: false,
    //   formatter: (cellContent, user) => {
    //     let value = user?.saas_name;
    //     return <>{value}</>;
    //   },
    //   filter:
    //     is_super_admin || show_all_saas_data
    //       ? selectFilter({
    //           options: saasFilterOptions.sort((a, b) => {
    //             if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
    //               return -1;
    //             }
    //             if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
    //               return 1;
    //             }
    //             return 0;
    //           }),
    //           delay: 1500,
    //         })
    //       : false,
    // },
    // {
    //   dataField: "kyc_type",
    //   text: "Investor Type",
    //   filter: selectFilter({
    //     options: selectType.sort((a, b) => {
    //       if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
    //         return -1;
    //       }
    //       if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
    //         return 1;
    //       }
    //       return 0;
    //     }),
    //     delay: 1500,
    //   }),

    //   formatter: (cellContent, user) => (
    //     <div className="text-capitalize">{user?.kyc_type}</div>
    //   ),
    // },

    {
      dataField: "user_id.status",
      text: "User Status",
      // style: {
      //   paddingLeft: "4px",
      //   paddingRight: "4px",
      // },
      // filter: selectFilter({
      //   options: selectRequestStatus.sort((a, b) => {
      //     if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
      //       return -1;
      //     }
      //     if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
      //       return 1;
      //     }
      //     return 0;
      //   }),
      //   delay: 1500,
      //   defaultValue: selectRequestStatus.find(person => person.fmode === fmode)
      //     ?.value,
      // }),
      // formatter: (cellContent, user) => (
      //   <div className="text-capitalize">{user?.status}</div>
      // ),
    },
    {
      dataField: "reasonforDeletIng",
      text: "Reason",
      sort: false,
      editable: false,
      formatter: (cellContent, user) => {
        if (
          user?.reasonforDeletIng !== "" &&
          user?.reasonforDeletIng !== undefined
        ) {
          return (
            <>
              <div className="d-flex gap-3">
                <Button
                  color="secondary"
                  className="inner"
                  onClick={() => {
                    setModelCommentData(user?.reasonforDeletIng?.split("\n"));
                    setShowCommentModel(true);
                  }}
                >
                  View
                </Button>
              </div>
            </>
          );
        }
      },
    },
    {
      dataField: `${moment(new Date(user?.createdAt)).format("DD MMM Y")}`,
      text: "Requested On",
      formatter: (cellContent, user) => (
        <>{moment(new Date(user?.createdAt)).format("DD MMM Y")}</>
      ),
    },
    // {
    //   dataField: "investor_tags",
    //   text: "Approval Tags",
    //   sort: false,
    //   formatter: (cellContent, user) => {
    //     if (user?.investor_tags?.length > 0) {
    //       return (
    //         <div className="d-flex gap-3">
    //           <Button
    //             color="secondary"
    //             className="inner"
    //             onClick={() => {
    //               let value = [];

    //               user?.investor_tags?.map(e => {
    //                 if (e) {
    //                   value.push(e);
    //                 }
    //               });

    //               setApprovalModalData(value);
    //               setApprovalModal(true);
    //             }}
    //           >
    //             View
    //           </Button>
    //         </div>
    //       );
    //     }
    //   },
    // },
    // {
    //   dataField: "investment_manager_id",
    //   text: "IM/SM",
    //   filter: selectFilter({
    //     options: investmentManagers,
    //     delay: 1500,
    //   }),
    //   formatter: (cellContent, user) => {
    //     let value = [];
    //     let investment_manager_name =
    //       user?.user?.investment_manager_detail?.firstname;
    //     if (
    //       investment_manager_name &&
    //       user?.user?.investment_manager_detail?.lastname
    //     ) {
    //       investment_manager_name =
    //         investment_manager_name +
    //         " " +
    //         user?.user?.investment_manager_detail?.lastname;
    //     }

    //     if (investment_manager_name) {
    //       value.push(investment_manager_name);
    //     }

    //     let syndicate_manager_name =
    //       user?.user?.saas_parent?.syndicate_manager_detail?.firstname;

    //     if (
    //       syndicate_manager_name &&
    //       user?.user?.saas_parent?.syndicate_manager_detail?.lastname
    //     ) {
    //       syndicate_manager_name =
    //         syndicate_manager_name +
    //         " " +
    //         user?.user?.saas_parent?.syndicate_manager_detail?.lastname;
    //     }
    //     if (syndicate_manager_name) {
    //       value.push(syndicate_manager_name);
    //     }
    //     value = value.toString();
    //     return <>{value}</>;
    //   },
    // },
    // {
    //   dataField: "",
    //   text: "CA Report",
    //   formatter: (cellContent, user) => {
    //     let userDetails = {
    //       firstname: user?.user?.firstname,
    //       lastname: user?.user?.lastname,
    //       email: user?.user?.email,
    //       investor_id: user?.user_id,
    //       relation: "self",
    //     };

    //     if (
    //       user.kyc_status ===
    //         "Contribution Agreement Signed by All - Process Completed" &&
    //       user.kyc_type === "Individual"
    //     ) {
    //       // return caDdCount && caDdCount.user_id === user?.user_id ? (
    //       //   <p>{caDdCount?.count_cc_dd}</p>
    //       // ) : (
    //       //   <>
    //       //     <p onClick={() => fetchInvestDetail(user?.user_id)}>View Data</p>
    //       //   </>
    //       // );
    //       return (
    //         <>
    //           <i
    //             style={{ color: "#34C38F" }}
    //             className="mdi mdi-file-document font-size-18"
    //             id="logintooltip"
    //             onClick={() => handleReport(userDetails)}
    //           ></i>
    //         </>
    //       );
    //     }
    //   },
    // },
    // {
    //   dataField: "ledgerStats",
    //   text: "CA Report",
    //   formatter: (cellContent, item) => {
    //     let userDetails = {
    //       firstname: item?.user?.firstname,
    //       lastname: item?.user?.lastname,
    //       relation_key: `${item?.relation} (${item?.applicant_name})`,
    //       pan_number: item?.pan_number || "",
    //       email: item?.user?.email,
    //       investor_id: item?.user_id,
    //       relation: item?.kyc_type === "Individual" ? "self" : item?.pan_number,
    //     };

    //     if (
    //       item?.kyc_status ===
    //         "Contribution Agreement Signed by All - Process Completed" &&
    //       accessCAReport === true
    //     ) {
    //       return (
    //         <>
    //           <p
    //             style={{ color: "#34C38F", cursor: "pointer" }}
    //             onClick={() => handleReport(userDetails)}
    //           >
    //             {item?.ledgerStats}
    //           </p>
    //         </>
    //       );
    //     }
    //   },
    // },
    // {
    //   dataField: "menu",
    //   isDummyField: true,
    //   editable: false,
    //   text: `Action`,
    //   csvExport: false,
    //   formatter: (cellContent, user) => (
    //     <div className="d-flex gap-3">
    //       {/* {access ? ( */}
    //       <Link className="text-success" to="#" title="Delete user">
    //         <i
    //           className="mdi mdi-delete font-size-18"
    //           id="logintooltip"
    //           onClick={() => handleUserLogin(user.user)}
    //         ></i>
    //       </Link>
    //       <Link className="text-success" to="#" title="Reject request">
    //         <i
    //           className="mdi mdi-cancel font-size-18"
    //           id="logintooltip"
    //           onClick={() => handleUserLogin(user.user)}
    //         ></i>
    //       </Link>
    //       {/* // ) : (
    //       //   ""
    //       // )} */}
    //       {/* {access?.view_kyc_logs && (
    //         <Button
    //           color="secondary"
    //           className="inner"
    //           onClick={async () => {
    //             try {
    //               setLogsModalData({});

    //               let investor_id = user.user?._id;
    //               let investor_type = "";
    //               let email_id = "",
    //                 contact_number = "",
    //                 country_code = "",
    //                 pan_number = "";

    //               if (user?.relation === "Self") {
    //                 email_id = user?.user?.email;
    //                 investor_type = "Individual";
    //               } else {
    //                 investor_type = "Relative";
    //                 email_id = user?.mail_id;
    //                 contact_number = user?.contact_number;
    //                 country_code = user?.country_code;
    //                 pan_number = user?.pan_number;
    //               }
    //               let params = {
    //                 investor_id: investor_id,
    //                 investor_type: investor_type,
    //                 email: email_id,
    //                 pan_card: pan_number,
    //                 country_code: country_code,
    //                 contact_number: contact_number,
    //                 applicant_name: user?.applicant_name,
    //                 applicant_relation: user?.relation,
    //               };

    //               let data = await getInvestorKycUpdateLogs(params);
    //               if (!data.status) return alert(data.message);
    //               setUserLoggedDetails(user.user);
    //               setLogsModalData(data.data);
    //               setLogsModal(true);
    //             } catch (err) {
    //               setLogsModal(false);
    //               return alert(
    //                 err?.response?.data?.message ||
    //                   err?.message ||
    //                   "Cannot get past logs"
    //               );
    //             }
    //           }}
    //         >
    //           Logs
    //         </Button>
    //       )} */}
    //     </div>
    //   ),
    // },
  ];

  const onTableChange = async (action, { filters, sortOrder, sortField }) => {
    let str = "";
    Object.keys(filters).forEach(filter => {
      let filterVal = filters?.[filter]?.filterVal;
      if (filterVal) {
        // console.log(filterVal);
        switch (filter) {
          case "user_types[0].user_type[0]":
            str += `&user_type=${filterVal}`;
            break;
          case "status":
            str += `&status=${
              "disabled".startsWith(filterVal.toLowerCase())
                ? "Declined"
                : filterVal
            }`;
            break;
          default:
            str += `&${filter}=${filterVal}`;
            break;
        }
      }
    });
    let order = sortOrder === "asc" ? 1 : -1;
    str += `&sort=${
      sortField === "_id" ? "createdAt" : sortField
    }&order=${order}`;
    setSearchQuery(str);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing rows {from} to {to} of {size}
    </span>
  );

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    paginationTotalRenderer: customTotal,
    onPageChange: async (page, size) => {
      setPage(page);
    },
  };

  const selectRole = e => {
    setRoleId(e.value);
  };

  const isUserDisabled = ["Disabled", "Declined"].includes(user?.status);

  // console.log("CA DD COUNT:", caDdCount);
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>ACCOUNT DELETION REQUESTS | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          <Row>
            <Col sm="9">
              <h5 className="text-uppercase">ACCOUNT DELETION REQUESTS</h5>
            </Col>
            {/* {access?.export_kyc_data && (
              <Col sm="3" className="d-flex justify-content-end">
                {exportLoading ? (
                  <button
                    className="btn btn-success"
                    onClick={() => generateCsvDataForAllSaaS()}
                  >
                    <Spinner animation="border" size="sm" />
                    Export KYC Data CSV
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={() => generateCsvDataForAllSaaS()}
                  >
                    <i className="bx bx-export fs-4 mr-3"></i>Export KYC Data
                    CSV
                  </button>
                )}
                {formedCSVData.length > 0 ? (
                  <CSVDownload data={formedCSVData} target="_blank" />
                ) : null}
              </Col>
            )} */}
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-0">
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={userListColumns}
                    data={allDeleteRequests}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={allDeleteRequests}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      filter: true,
                                      pagination: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    onTableChange={onTableChange}
                                    filter={filterFactory()}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Modal size="lg" show={logsModalOpen} toggle={() => toggleLogsModal()}>
        <ModalHeader toggle={() => toggleLogsModal()} tag="h4">
          KYC status/CA status Report Past Logs
        </ModalHeader>
        <Modal.Body>
          {!isEmpty(logsModalData) && (
            <>
              <Accordion defaultActiveKey={["0", "1", "2"]}>
                {logsModalData?.added?.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Values Added</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive int_table">
                        <div
                          className="react-bootstrap-table"
                          style={{ height: "300px" }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Relation</th>
                                <th>KYC Type</th>
                                <th>Status</th>
                                <th>Added On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {logsModalData?.added?.map((added, index) => {
                                return (
                                  <tr>
                                    <td>{added?.applicant_name}</td>
                                    <td>{added?.applicant_relation}</td>
                                    <td>{added?.investor_type}</td>
                                    <td>
                                      Onboarding Complete, Approval Pending
                                    </td>
                                    <td>
                                      {moment(added?.createdAt).format(
                                        logsDateFormat
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {logsModalData?.updated?.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Values Changed</Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive int_table">
                        <div
                          className="react-bootstrap-table"
                          style={{ height: "300px" }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>User Name</th>
                                <th>User Email</th>
                                <th>Status</th>
                                <th>Old Status</th>
                                <th>New Status</th>
                                <th>Changed On</th>
                                <th>Update Source</th> 
                                <th>IP Address</th>
                                <th>OS</th>
                                <th>Location</th>
                                <th>Device</th>
                                <th>Browser</th>
                                {/* <th>Admin Email</th>
                                <th>Admin Name</th> 
                              </tr>
                            </thead>
                            <tbody>
                              {logsModalData?.updated?.map((updated, index) => {
                                return Object.keys(updated).map(key => {
                                  if (
                                    key === "createdAt" ||
                                    key === "source" ||
                                    key === "updatedBy" ||
                                    key === "user_details" ||
                                    key === "changedBy"
                                  )
                                    return null;

                                  let label = LabelSwitch(key);
                                  if (!label) return null;

                                  let values = updated[key];

                                  let oldValue = StatusSwitch(values.oldValue);
                                  let newValue = StatusSwitch(values.newValue);

                                  return (
                                    <tr>
                                      <td>{updated?.source?.adminUsername}</td>
                                      <td>{updated?.source?.adminEmail}</td>
                                      {/* <td>
                                        {updated?.user_details?.user_name}
                                      </td>
                                      <td>
                                        {updated?.user_details?.user_email}
                                      </td>
                                      <td>{label}</td>
                                      <td>{oldValue}</td>
                                      <td>{newValue}</td>
                                      <td>
                                        {moment(updated["createdAt"]).format(
                                          logsDateFormat
                                        )}
                                      </td>
                                      <td>
                                        {updated?.source?.adminIpAddress || ""}
                                      </td>
                                      <td>{updated?.source?.newValue}</td> 
                                      <td>{updated?.source?.adminUsername}</td>
                                      <td>{updated?.source?.adminEmail}</td> 
                                      <td>
                                        {updated?.source?.adminOsName || ""}
                                      </td>
                                      <td>
                                        {updated?.source?.adminLocation || ""}
                                      </td>
                                      <td>
                                        {updated?.source?.adminDeviceName || ""}
                                      </td>
                                      <td>
                                        {updated?.source?.adminBrowserName ||
                                          ""}
                                      </td>
                                    </tr>
                                  );
                                });
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </>
          )}
          {(isEmpty(logsModalData) ||
            Object.values(logsModalData).every(
              value => value?.length === 0
            )) && (
            <Row className="text-center">
              <Col>
                <Label tag="h4">Past Logs Not Available</Label>
              </Col>
            </Row>
          )}
          <ModalFooter>
            <Row>
              <Col lg={12}>
                <Button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setLogsModal(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal.Body>
      </Modal>
      {
        <Modal
          size="lg"
          // style={{ width: "1000px" }}
          show={reportPopup}
          onHide={() => {
            setReportPopup(false);
            setCaDdDetails(null);
            setRelatives(null);
            setCurrentySelectedRelative(relatives[0]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="tabsection">
                <Tabs
                  defaultActiveKey={"CA Ledger"}
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey={"CA Ledger"} title={"CA Ledger"}>
                    <LedgerDetails
                      relatives={relatives}
                      currentlySelectedRelative={
                        relatives === null || relatives[0]?.value === "self"
                          ? currentlySelectedRelative
                          : relatives[0]
                      }
                      setCurrentySelectedRelative={setCurrentySelectedRelative}
                      handleReport={handleReport}
                      caDdDetails={caDdDetails}
                    />
                  </Tab>
                  {relatives && relatives.find(item => item?.label === "Self") && ( 
                  <Tab eventKey={"Sign CA"} title={"Sign CA"}>
                    <SignupLedger
                      relatives={relatives}
                      currentlySelectedRelative={currentlySelectedRelative}
                      investor={caDdDetails?.investor_id}
                    />
                  </Tab>
                  {/* )} 
                </Tabs>
              </div>
            </>
          </Modal.Body>
        </Modal>
      }
      <Modal
        // className="auctionmodal multiplecomp contactModal"
        size="md"
        show={approvalModal}
        onHide={() => {
          setApprovalModal(false);
          setApprovalModalData("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ textAlign: "center" }}>Approval Tags</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {approvalModalData?.length > 0 &&
              approvalModalData.map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal
        // className="auctionmodal multiplecomp contactModal"
        size="md"
        show={showCommentModel}
        onHide={() => {
          setShowCommentModel(false);
          setModelCommentData("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ textAlign: "center" }}>Reason for requesting</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {modelCommentData?.length > 0 &&
              modelCommentData.map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

DeleteRequestList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
};
export default DeleteRequestList;
