import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button, Alert } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";

class generateToken extends Component {

    constructor(props) {
        super(props);
        this.node = React.createRef();

        this.generateAuthToken = this.generateAuthToken.bind(this);
    }
    componentDidMount() {

        const search = this.props.location.search;
        const code = search.split("=")[1];
        if (!this.state?.status) {
            this.setState({ code: code }, async () => {
                if (this.state?.code) {
                    const token = authHeader();
                    await axios({
                        url: `${process.env.REACT_APP_API_URL}/set_zoom_code?code=${this.state.code}`,
                        method: "GET",
                        headers: {
                            Authorization: `${token}`,
                        },
                    }).then(async (res) => {
                        let { status } = this.state
                        // let resSuccess = setInterval(
                            this.generateZoomAuthToken()
                            // , 1500);
                        // if (status) {
                        //     clearInterval(resSuccess);
                        // }

                    }).catch(err => {
                        this.setState({ err: "something went wrong" })
                    });
                }
            })
        }
    }


    generateZoomAuthToken = async () => {
        const token = authHeader();

        await axios({
            url: `${process.env.REACT_APP_API_URL}/api/post/pitchday/zoom_auth`,
            method: "GET",
            headers: {
                Authorization: `${token}`,
            },
        }).then(res => {
            this.setState({ status: res?.data?.status, code: null });
        }).catch(err => {
            return false;
        });

        return true;
    }

    generateAuthToken = async () => {
        const token = authHeader();
        await axios({
            url: `${process.env.REACT_APP_API_URL}/api/post/pitchday/zoom_token_refresh`,
            method: "GET",
            headers: {
                Authorization: `${token}`,
            },
        }).then(res => {

            console.log("---.split('Bearer ')[1]-----", res);
            const convertUrl = window?.atob(res?.data?.redirectURL)
            console.log("---convertUrl---", convertUrl);

            const u = `${convertUrl}&redirect_uri=${process.env.REACT_APP_ZOOM_URL}`
            window.location.replace(u);
        }).catch(err => {
            this.setState({ err: "Something went wrong" })
        });
    }

    render() {
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Generate token | Invstt - Admin</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Zoom" breadcrumbItem="Generate token" /> */}
                    <Row className="mb-3">
                        <Col sm="6">
                            <h5 className="text-uppercase">Generate token</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={() => this.generateAuthToken()}>
                                        Generate Auth Token
                                    </Button>

                                    {this.state?.status ? (
                                        <Alert color="success" className="mt-4">Token updated successfully</Alert>
                                    ) : null}

                                    {this.state?.err ? (
                                        <Alert color="danger" className="mt-4">{this.state?.err} </Alert>
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        )
    }
}

generateToken.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

const mapStateToProps = ({ users }) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(generateToken));