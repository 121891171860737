import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'


const GeneralMasterModal = ({ show, selectedData, updateData, getAllByType, type }) => {
  const [data, setData] = useState({})

  const toggle = () => {
    updateData()
  }


  const updatedData = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/api/generalmaster/update-value`, {
      mid: selectedData.id ? selectedData.id : '',
      label: data.label ? data.label : selectedData?.values?.label,
      value: data.value ? data.value : selectedData?.values?.value,
      valueId: selectedData?.values?._id ? selectedData?.values?._id : ''
    }, {
      headers:
      {
        'Authorization': JSON.parse(localStorage.getItem('authUser')).token
      }
    })
      .then(res => {
        getAllByType(type)
        updateData()
        toast.success('Update Successfully')
      })
      .catch(err => {
        toast.error('Something went wrong!')
      })
  }

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={show}
        toggle={toggle}
      >
        <ModalHeader>
          Update Data
        </ModalHeader>

        <ModalBody>
          <Label>Label: </Label>
          <Input
            name='label'
            type='text'
            defaultValue={selectedData?.values?.label}
            onChange={(e) => setData({
              [e.target.name]: e.target.value
            })}
          />
          <Label>Value: </Label>
          <Input
            name='value'
            type='text'
            defaultValue={selectedData?.values?.value}
            onChange={(e) => setData({
              [e.target.name]: e.target.value
            })}
          />

        </ModalBody>
        <ModalFooter>
          <Button onClick={updatedData}>Update</Button>
          <Button onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default GeneralMasterModal
