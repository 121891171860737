import React, { Component } from "react";
import MetaTags from "react-meta-tags";
// import { Card, CardBody, Col, Container, Nav, NavItem, Row, Table } from "reactstrap";
import {
  Table,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getPostEventDetail,
  fetchPoll,
  fetchQa,
  fetchRecording,
  getQuestions,
  getAssociatePoll,
  fetchRegistrants,
  getEventParticipants,
} from "../../../store/pitchdays/actions";

import { isEqual } from "lodash-es";

import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";

class EventReportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      post_id: "",
      report: [],
      attendedUsers: [],
    };
  }
  async componentDidMount() {
    const { match } = this.props;
    const token = authHeader();

    // /api/event/event-report/:eventId
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/event/event-report/${match?.params?.id}`,
        { headers: { Authorization: `${token}` } }
      )
      .then(response => {
        if (response?.status === 200) {
          this.setState({ report: response?.data?.data });

          response?.data &&
            response?.data?.data.length > 0 &&
            this.setState({
              attendedUsers: response?.data?.data[0].attendedUser,
            });
        }
      })
      .catch(error => {
        console.log("---error------", error);
      });
  }

  // async UNSAFE_componentWillReceiveProps(nextProps) {

  // }

  toggle = value => {
    this.setState({
      activeTab: value?.reportKey,
      attendedUsers: value?.attendedUser,
    });
  };

  render() {
    const { postEvent } = this.props;
    const { report, attendedUsers, activeTab } = this.state;

    const buttonStyle = {
      background: "#E0E0E0",
      width: 200,
      padding: 16,
      textAlign: "center",
      margin: "0 auto",
      marginTop: 32,
    };

    return (
      <React.Fragment>
        <div className="">
          <MetaTags>
            <title>View Event Report | Invstt - Admin</title>
          </MetaTags>
          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <Breadcrumbs title="Event" breadcrumbItem="View Event Report" />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  {report &&
                    report.length > 0 &&
                    report.map((reportVal, reportKey) => {
                      ++reportKey;
                      // this.toggle({ reportKey, attendedUser: reportVal?.attendedUser });
                      return (
                        <NavItem key={reportKey}>
                          <NavLink
                            className={activeTab === reportKey && "active"}
                            onClick={() => {
                              this.toggle({
                                reportKey,
                                attendedUser: reportVal?.attendedUser,
                              });
                            }}
                          >
                            {reportVal.startupId}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={activeTab}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <Table>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>User Name</th>
                                  <th>Email</th>
                                  <th>Intent</th>
                                  <th>RSVP Status</th>
                                  <th>Is Attended</th>
                                  <th>Is Poll</th>
                                  <th>Duration</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {attendedUsers &&
                                  attendedUsers.length > 0 &&
                                  attendedUsers.map(
                                    (attendedUsersVal, attendedUsersKey) => {
                                      return (
                                        <tr key={attendedUsersKey}>
                                          <td>{++attendedUsersKey}</td>
                                          <td>{`${attendedUsersVal?.firstName} ${attendedUsersVal?.lastName}`}</td>
                                          <td>{attendedUsersVal?.email}</td>
                                          <td>{attendedUsersVal?.intent}</td>
                                          <td>
                                            {attendedUsersVal?.rsvp?.status}
                                          </td>
                                          <td>
                                            {attendedUsersVal?.isParticipanted}
                                          </td>
                                          <td>{attendedUsersVal?.isPolled}</td>
                                          <td>{attendedUsersVal?.intent}</td>
                                          <td>{attendedUsersVal?.intent}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EventReportDetails.propTypes = {
  match: PropTypes.any,
  className: PropTypes.any,
  error: PropTypes.any,
};

const mapStateToProps = ({ pitchdays }) => {
  return {
    panelist: pitchdays?.panelist,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetPostEventDetail: payload => dispatch(getPostEventDetail(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventReportDetails));
