import DeviceDetector from "device-detector-js";

export const getUserBrowser = browserNameString => {
  return browserNameString.split(" ")[0];
};

export const getDeviceDetails = userAgentString => {
  const deviceDetector = new DeviceDetector();
  const userAgent = userAgentString;
  const device = deviceDetector.parse(userAgent);

  let { client, os } = device;

  let { name: browserName, version: browserVersion } = client;
  let { name: osName, version: osVersion, platform: osPlatform } = os;

  let versionObject = {
    browser: `${browserName}/${browserVersion}`,
    os: `${osName} ${osVersion} ${osPlatform ? `(${osPlatform})` : ``}`,
  };

  return versionObject;
};

export const getLocationString = (city, country) => {
  return city && country ? `${city}, ${country}` : !city ? country : "";
};

// DETECTING THE OPERATING SYSTEM
export const getOperatingSystem = window => {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "Macintosh";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux";
  }

  return operatingSystem;
};

// CHECKING USER DEVICE
export const checkDeviceType = navigator => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);
  let deviceType = "unknown";

  if (isMobileDevice) {
    if (details.toLowerCase().includes("iphone")) deviceType = "iPhone";
    if (details.toLowerCase().includes("android")) deviceType = "Android";
    if (details.toLowerCase().includes("kindle")) deviceType = "Amazon Kindle";
    if (details.toLowerCase().includes("ipad")) deviceType = "iPad";
  } else {
    deviceType = "Desktop/Laptop";
  }

  return deviceType;
};
