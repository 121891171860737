const exchangeCurrencyRates = (
  amount,
  baseCurrency = {
    value: "INR",
    locale: "en-IN",
  },
  targetCurrency = {
    value: "INR",
    locale: "en-IN",
  },
  exchangeRate
) => {
  try {
    if (Number(amount).toString() === "NaN") {
      return amount.toString();
    }

    baseCurrency = {
      ...(baseCurrency || {}),
      value: baseCurrency?.value || "INR",
      locale: baseCurrency?.locale || "en-IN",
    };
    targetCurrency = {
      ...(targetCurrency || {}),
      value: targetCurrency?.value || "INR",
      locale: targetCurrency?.locale || "en-IN",
    };

    if (baseCurrency?.value === targetCurrency?.value) {
      return amount;
    }

    if (parseFloat(exchangeRate)?.toFixed(6) === parseFloat(1)?.toFixed(6)) {
      return amount;
    }

    if (!exchangeRate) {
      let latestCurrencyRates =
        JSON.parse(localStorage.getItem("mainVars") || "{}") || {};
      exchangeRate =
        latestCurrencyRates?.[baseCurrency?.value || "INR"]?.[
          targetCurrency?.value || "INR"
        ];
    }

    exchangeRate = parseFloat(exchangeRate).toFixed(6);

    if (exchangeRate === "NaN") {
      return amount;
    }

    return amount * Number(exchangeRate);
  } catch (error) {
    // console.trace(error);
    return amount;
  }
};

const checkIntentCurrency = (
  investorCurrency,
  startupCurrency,
  investorTaxFillingCountries = []
) => {
  if (!["USD", "INR"].includes(startupCurrency?.value)) {
    //Check startup currency if INR then INR, otherwise USD
    startupCurrency = {
      label: "United States Dollar",
      value: "USD",
      symbol: "US$",
      locale: "en-US",
    };
  }

  investorCurrency = !!investorCurrency?.value
    ? investorCurrency
    : {
        label: "Indian Rupee",
        value: "INR",
        symbol: "₹",
        locale: "en-IN",
      };

  let investor_tax_return_india = "yes";
  if (investorTaxFillingCountries?.length) {
    let checkdata = investorTaxFillingCountries?.includes("India");
    if (!checkdata) {
      investor_tax_return_india = "no";
    }
  }

  const INR_cur = {
    label: "Indian Rupee",
    value: "INR",
    symbol: "₹",
    locale: "en-IN",
  };
  const USD_cur = {
    label: "United States Dollar",
    value: "USD",
    symbol: "US$",
    locale: "en-US",
  };

  let intentcur = INR_cur;

  switch (true) {
    case investorCurrency?.value === "INR" &&
      startupCurrency?.value === "INR" &&
      investor_tax_return_india === "yes":
      intentcur = INR_cur;
      break;

    case investorCurrency?.value === "INR" && startupCurrency?.value === "USD":
      intentcur = USD_cur;
      break;

    case investorCurrency?.value === "USD" &&
      startupCurrency?.value === "INR" &&
      investor_tax_return_india === "no":
      intentcur = USD_cur;
      break;

    case investorCurrency?.value === "USD" &&
      startupCurrency?.value === "INR" &&
      investor_tax_return_india === "yes":
      intentcur = INR_cur;
      break;

    case investorCurrency?.value === "USD" &&
      startupCurrency?.value === "USD" &&
      investor_tax_return_india === "no":
      intentcur = USD_cur;
      break;

    case startupCurrency?.value === "INR" &&
      investor_tax_return_india === "yes":
      intentcur = INR_cur;
      break;

    case investor_tax_return_india === "no":
      intentcur = USD_cur;
      break;

    default:
      intentcur = INR_cur;
      break;
  }

  return intentcur;
};

const getPartnerSettingsKey = (prefix = "", vehicleLabel = "Invstt Trust") => {
  if (!(prefix && vehicleLabel)) {
    return "";
  }

  let keyString = prefix?.toLowerCase();

  vehicleLabel = (vehicleLabel || "")
    ?.split(/[ -]/)
    ?.filter(data => data)
    ?.join("_")
    ?.toLowerCase();

  keyString += `_${vehicleLabel}`;

  return keyString;
};

const getReferralInvestmentSettingsKey = vehicleLabel => {
  return getPartnerSettingsKey("referral_investment_amount_fee", vehicleLabel);
};

const getSelfInvestmentSettingsKey = vehicleLabel => {
  return getPartnerSettingsKey("self_investment_amount_fee", vehicleLabel);
};

const getSyndicatePayoutSettingsKey = vehicleLabel => {
  return getPartnerSettingsKey("syndicate_payout_fee", vehicleLabel);
};

const CURRENCY_SYMBOLS = {
  INR: "₹",
  USD: "US$",
  SGD: "S$",
  GBP: "£",
  AED: "د.إ",
  EUR: "€",
  AUD: "A$",
};

const formatNumberToCommaSeparatedString = (
  amount = 0,
  currency = {
    value: "INR",
    locale: "en-IN",
  }
) => {
  try {
    if (!amount) {
      return 0;
    }

    if (Number(amount).toString() === "NaN") {
      return amount.toString();
    }

    let formatter = new Intl.NumberFormat(currency?.locale || "en-IN", {});

    let formattedValue = formatter.format(amount);

    if (formattedValue === "NaN") {
      formattedValue = amount?.toString();
    }

    let currencySymbol = CURRENCY_SYMBOLS?.[currency?.value || "INR"];

    if (formattedValue?.toLowerCase()?.includes("$")) {
      formattedValue = currencySymbol + formattedValue?.split("$")?.[1];
    }

    return formattedValue;
  } catch (error) {
    // console.trace(error);
    return amount.toString();
  }
};

const formatNumberToCommaSeparatedCurrency = (
  amount = 0,
  currency = {
    value: "INR",
    locale: "en-IN",
  }
) => {
  try {
    if (!amount) {
      return 0;
    }

    if (Number(amount).toString() === "NaN") {
      return amount.toString();
    }

    let formatter = new Intl.NumberFormat(currency?.locale || "en-IN", {
      style: "currency",
      currency: currency?.value || "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    let formattedValue = formatter.format(amount);

    if (formattedValue === "NaN") {
      formattedValue = amount?.toString();
    }

    let currencySymbol = CURRENCY_SYMBOLS?.[currency?.value || "INR"];

    if (formattedValue?.toLowerCase()?.includes("$")) {
      formattedValue = currencySymbol + formattedValue?.split("$")?.[1];
    }

    return formattedValue;
  } catch (error) {
    // console.trace(error);
    return amount.toString();
  }
};

const formatNumberToStringWithCurrency = (
  amount = 0,
  currency = {
    value: "INR",
    locale: "en-IN",
  }
) => {
  try {
    if (!amount) {
      return 0;
    }

    if (Number(amount).toString() === "NaN") {
      return amount.toString();
    }

    let options = {
      style: "currency",
      currency: currency?.value || "INR",
      notation: "compact",
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 3,
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 3,
      // compactDisplay: "long",
      // currencyDisplay: "name",
    };

    let formatter = new Intl.NumberFormat(currency?.locale || "en-IN", options);

    let formattedValue = formatter.format(amount);

    if (formattedValue === "NaN") {
      formattedValue = amount?.toString();
    }

    let currencySymbol = CURRENCY_SYMBOLS?.[currency?.value || "INR"];

    if (formattedValue?.toLowerCase()?.includes("$")) {
      formattedValue = currencySymbol + formattedValue?.split("$")?.[1];
    }

    return formattedValue;
  } catch (error) {
    // console.trace(error);
    return amount.toString();
  }
};

const formatNumberToStringWithCurrencyV2 = (
  amount = 0,
  currency = {
    value: "INR",
    locale: "en-IN",
  },
  needOneDigit = false,
  notcurrencyneed = false
) => {
  try {
    if (!amount) {
      return 0;
    }

    if (Number(amount).toString() === "NaN") {
      return amount.toString();
    }

    let options = {
      style: "currency",
      currency: currency?.value || "INR",
      notation: "compact",
      minimumSignificantDigits: needOneDigit ? 1 : 2,
      maximumSignificantDigits: 3,
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 3,
      // compactDisplay: "long",
      // currencyDisplay: "name",
    };
    if (notcurrencyneed) {
      options = {
        notation: "compact",
        minimumSignificantDigits: needOneDigit ? 1 : 2,
        maximumSignificantDigits: 3,
      };
    }

    let formatter = new Intl.NumberFormat(currency?.locale || "en-IN", options);

    let formattedValue = formatter.format(amount);

    if (formattedValue === "NaN") {
      formattedValue = amount?.toString();
    }

    let currencySymbol = CURRENCY_SYMBOLS?.[currency?.value || "INR"];

    if (formattedValue?.toLowerCase()?.includes("$")) {
      formattedValue = currencySymbol + formattedValue?.split("$")?.[1];
    }

    return formattedValue;
  } catch (error) {
    // console.trace(error);
    return amount.toString();
  }
};

const getNumberOfParticipantsOptions = () => {
  // total 9 slot booked for moderators
  const listofOptions = [
    {
      label: "up to 20",
      value: 11,
    },
    {
      label: "up to 50",
      value: 41,
    },
    {
      label: "up to 100",
      value: 91,
    },
    {
      label: "up to 250",
      value: 241,
    },
    {
      label: "up to 500",
      value: 491,
    },
  ];
  return listofOptions;
};
const formatNumberToStringWithCurrencyWithoutSymbol = (
  amount = 0,
  currency = {
    value: "INR",
    locale: "en-IN",
  }
) => {
  try {
    if (!amount) {
      return 0;
    }

    if (Number(amount).toString() === "NaN") {
      return amount.toString();
    }

    let options = {
      // style: "currency",
      currency: currency?.value || "INR",
      notation: "compact",
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 3,
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 3,
      // compactDisplay: "long",
      // currencyDisplay: "name",
    };

    let formatter = new Intl.NumberFormat(currency?.locale || "en-IN", options);

    let formattedValue = formatter.format(amount);

    if (formattedValue === "NaN") {
      formattedValue = amount?.toString();
    }

    let currencySymbol = CURRENCY_SYMBOLS?.[currency?.value || "INR"];

    if (formattedValue?.toLowerCase()?.includes("$")) {
      formattedValue = currencySymbol + formattedValue?.split("$")?.[1];
    }

    return formattedValue;
  } catch (error) {
    // console.trace(error);
    return amount.toString();
  }
};

export const VALUE_SEPARATOR = "=||=";

export {
  exchangeCurrencyRates,
  checkIntentCurrency,
  getReferralInvestmentSettingsKey,
  getSelfInvestmentSettingsKey,
  getSyndicatePayoutSettingsKey,
  formatNumberToCommaSeparatedString,
  formatNumberToCommaSeparatedCurrency,
  formatNumberToStringWithCurrency,
  formatNumberToStringWithCurrencyWithoutSymbol,
  getNumberOfParticipantsOptions,
  formatNumberToStringWithCurrencyV2
};
