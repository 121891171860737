export const GET_FUNDING_DEAL_STATUS_LIST = "GET_FUNDING_DEAL_STATUS_LIST";
export const GET_FUNDING_DEAL_TYPE_LIST = "GET_FUNDING_DEAL_TYPE_LIST";

export const GET_FUNDING_DEAL_STATUS_LIST_SUCCESS =
  "GET_FUNDING_DEAL_STATUS_LIST_SUCCESS";
export const GET_FUNDING_DEAL_STATUS_LIST_FAIL =
  "GET_FUNDING_DEAL_STATUS_LIST_FAIL";

export const GET_FUNDING_DEAL_TYPE_LIST_SUCCESS =
  "GET_FUNDING_DEAL_TYPE_LIST_SUCCESS";
export const GET_FUNDING_DEAL_TYPE_LIST_FAIL =
  "GET_FUNDING_DEAL_TYPE_LIST_FAIL";

export const GET_ASSESSMENT_REPORT_LOADING = "GET_ASSESSMENT_REPORT_LOADING";
export const GET_ASSESSMENT_REPORT = "GET_ASSESSMENT_REPORT";
export const GET_ASSESSMENT_REPORT_SUCCESS = "GET_ASSESSMENT_REPORT_SUCCESS";
export const GET_ASSESSMENT_REPORT_FAIL = "GET_ASSESSMENT_REPORT_FAIL";

export const GET_APPLIED_FUNDING_DEAL_DETAILS =
  "GET_APPLIED_FUNDING_DEAL_DETAILS";
export const GET_APPLIED_FUNDING_DEAL_DETAILS_SUCCESS =
  "GET_APPLIED_FUNDING_DEAL_DETAILS_SUCCESS";
export const GET_APPLIED_FUNDING_DEAL_DETAILS_FAIL =
  "GET_APPLIED_FUNDING_DEAL_DETAILS_FAIL";

export const GET_APPLIED_FUNDING = "GET_APPLIED_FUNDING";
export const GET_APPLIED_FUNDING_SUCCESS = "GET_APPLIED_FUNDING_SUCCESS";
export const GET_APPLIED_FUNDING_FAIL = "GET_APPLIED_FUNDING_FAIL";

export const GET_DEAL_MANAGER = "GET_DEAL_MANAGER";
export const GET_DEAL_MANAGER_SUCCESS = "GET_DEAL_MANAGER_SUCCESS";
export const GET_DEAL_MANAGER_FAIL = "GET_DEAL_MANAGER_FAIL";

export const UPDATE_ZOOM_INVESTOR_INTENT_STATUS =
  "UPDATE_ZOOM_INVESTOR_INTENT_STATUS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const COPY_DEAL_STATUS = "COPY_DEAL_STATUS";

export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";

export const COPY_DEAL_STATUS_SUCCESS = "COPY_DEAL_STATUS_SUCCESS";

export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL";
export const COPY_DEAL_STATUS_FAIL = "COPY_DEAL_STATUS_FAIL";

export const UPDATE_FUNDING_DETAILS = "UPDATE_FUNDING_DETAILS";
export const UPDATE_FUNDING_DETAILS_SUCCESS = "UPDATE_FUNDING_DETAILS_SUCCESS";
export const UPDATE_FUNDING_DETAILS_FAIL = "UPDATE_FUNDING_DETAILS_FAIL";

export const SEND_CONSENT_TO_INVESTOR = "SEND_CONSENT_TO_INVESTOR";
export const SEND_CONSENT_TO_INVESTOR_SUCCESS =
  "SEND_CONSENT_TO_INVESTOR_SUCCESS";
export const SEND_CONSENT_TO_INVESTOR_FAIL = "SEND_CONSENT_TO_INVESTOR_FAIL";
