import {
  getAllSyndicatesForUser,
  updateSyndicateStatus,
  getSaasLogsForUser,
} from "helpers/backend_helper";
import React, { useEffect, useState, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Col,
  Row,
  UncontrolledCollapse,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { SelectionState } from "draft-js";
import $ from "jquery";

const SyndicateOptions = ({ user_id, ...props }) => {
  const dateFormat = "DD MMM, YYYY";

  const [refresh, setRefresh] = useState(false);

  const [syndicateList, setSyndicateList] = useState([]);
  const [selected, setSelected] = useState();
  const [status, setStatus] = useState([]);
  const [reason, setReason] = useState([]);
  const [logsList, setLogsList] = useState([]);
  const [dd, setDD] = useState(true);
  const [enableCommunication, SetEnableComm] = useState([]);
  const [roleWiseAccess, SetRoleWiseAccess] = useState(
    JSON.parse(localStorage.getItem("roleWiseAccess")) || {}
  );

  const [updateModal, setUpdateModal] = useState(false);
  const toggleUpdateModal = () => {
    setUpdateModal(!updateModal);
  };

  const [message, setMessage] = useState({
    success: null,
    error: null,
  });

  const selectInputRef = useRef();

  const options = [
    { label: "Please Select", id: 0, color: "#0052CC", isDisabled: true },
    { value: "accepted", label: "Accept", id: 1 },
    { value: "rejected", label: "Reject", id: 2 },
  ];

  const handleSubmit = indexing => {
    try {
      updateSyndicate(indexing);
      // setStatus({ ...status, id: indexing, name: null });
      setReason({ ...reason, id: indexing, name: "" });
      fetchAllSyndicates();
      // selectInputRef.current.select.clearValue();
    } catch (error) {
      console.log("error in submitting", error.message);
    }
  };

  const stateClear = indexing => {
    setStatus({
      ...status,
      id: indexing,
      name: syndicateList[indexing]?.status || null,
    });
    setReason({ ...reason, id: indexing, name: "" });
    SetEnableComm(true);
  };

  const updateSyndicate = async indexing => {
    try {
      let indexedStatus, indexedReason;
      // {status.map(status => (
      if (status.id == indexing) {
        indexedStatus = status.name;
        // console.log("indexedStatus", indexedStatus);
      }
      if (reason.id == indexing) {
        indexedReason = reason.name;
        // console.log("indexedReason", indexedReason);
      }
      // ))}
      let saas_parent_id = syndicateList[indexing]._id;
      // console.log("syndicate", saas_parent_id);
      let body = {
        user_id: user_id,
        status: indexedStatus,
        reason: indexedReason,
        saas_parent_id: saas_parent_id,
        added_user_id: JSON.parse(localStorage.getItem("user_id")),
        enableCommunication: enableCommunication,
      };
      let k = 0;
      if (!indexedStatus) {
        alert("unsuccessful, please select status");
        k = 1;
      }
      if (indexedStatus !== "accepted") {
        if (!indexedReason) {
          alert("unsuccessful, please enter the reason!!");
          k = 1;
        }
      }
      if (k !== 1) {
        updateSyndicateStatus(body, user_id);
        alert("Successfully updated");
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while updating syndicate status";
      setMessage({
        error: message,
        success: null,
      });
    }
  };

  useEffect(() => {
    $(".grid-item .edit-col").on("click", function () {
      if ($(this).parent().parent().hasClass("is-active")) {
        //$(".grid-item").removeClass("is-active");
      } else {
        $(".grid-item").removeClass("is-active");
        $(this).parent().parent().addClass("is-active");
      }
    });
    return () => {};
  });

  useEffect(() => {
    if (user_id) {
      fetchAllSyndicates();
      fetchAllSaasLogs();
    }
    return () => {};
  }, [user_id, refresh]);

  const fetchAllSyndicates = async () => {
    try {
      let response = await getAllSyndicatesForUser(user_id);
      let syndicateList = response?.syndicateList || [];
      setSyndicateList(syndicateList);
      return;
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching syndicate list";
      // alert(message);
      return;
    }
  };

  const fetchAllSaasLogs = async indexing => {
    try {
      let saas_parent_id = syndicateList[indexing]._id;
      let body = { saas_parent_id };

      let response = await getSaasLogsForUser(user_id, body);
      let logsList = response?.logs || [];
      setLogsList(logsList);
      return;
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching Saas logs list";
      // alert(message);
      return;
    }
  };

  return (
    <>
      {!!syndicateList?.length && (
        <>
          <div
            style={{
              height: "300px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <label>Secondary Syndicate</label>
            <label> </label>

            {syndicateList?.map((syndicate, index) => {
              return (
                <Row
                  key={syndicate?._id}
                  className="mb-2 grid-item align-items-center"
                >
                  <Col sm={4}>
                    {syndicate?.saas_fullname || syndicate?.saas_name}
                  </Col>
                  <Col
                    sm={4}
                    className="text-capitalize d-flex align-items-center"
                  >
                    {!(syndicate?.joinedDate || syndicate?.rejectedDate) ? (
                      <></>
                    ) : (
                      <>
                        updated on:{" "}
                        {moment(
                          syndicate?.joinedDate || syndicate?.rejectedDate
                        ).format(`DD-MMM'YY hh:mm A`)}
                      </>
                    )}
                  </Col>
                  <Col
                    sm={1}
                    className="text-capitalize d-flex align-items-center"
                  >
                    <span className="me-3 d-block">{syndicate?.status}</span>
                  </Col>
                  <Col
                    sm={1}
                    className="text-capitalize d-flex align-items-center"
                  >
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <Button
                        color="transparent"
                        className="edit-col"
                        id={`toggler${index}`}
                        onClick={() => {
                          stateClear(index);
                        }}
                      >
                        <i className="mdi mdi-pencil font-size-18" />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>View log</Tooltip>}
                    >
                      <Button
                        color="transparent"
                        id={`togler${index}`}
                        onClick={() => {
                          fetchAllSaasLogs(index);
                        }}
                      >
                        <i className="mdi mdi-clock font-size-18" />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <div className="col-sm-12 mb-3 inner">
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="cstatus">Status</Label>
                          <span className="text-danger">*</span>
                          {/* <Select
                            name="select"
                            id="cstatus"
                            // value={options}
                            // selected={status}
                            // ref={selectInputRef}
                            placeholder="Please Select"
                            isClearable={true}
                            onChange={(event) => {
                              setStatus(event.value)
                            }}
                            maxMenuHeight={120}
                            options={options}
                            required
                          ></Select> */}
                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                value="accepted"
                                // name="syndicateStatus"
                                style={{ marginRight: "5px" }}
                                checked={status?.name === "accepted"}
                                onClick={() => {
                                  setStatus({ id: index, name: "accepted" });
                                }}
                              />
                              Accept
                            </label>
                          </div>

                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                value="rejected"
                                // name="syndicateStatus"
                                style={{ marginRight: "5px" }}
                                checked={status?.name === "rejected"}
                                onClick={() => {
                                  setStatus({ id: index, name: "rejected" });
                                }}
                              />
                              Reject
                            </label>
                          </div>
                        </FormGroup>
                        {roleWiseAccess?.secondary_syndicate_communication_access && (
                          <FormGroup>
                            <label>
                              <input
                                style={{ marginRight: "5px" }}
                                type="checkbox"
                                checked={enableCommunication}
                                onChange={e => SetEnableComm(e.target.checked)}
                              ></input>
                              Enable Communication
                            </label>
                          </FormGroup>
                        )}
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="reason">Reason</Label>
                          {status.name !== "accepted" ? (
                            <span className="text-danger">*</span>
                          ) : null}
                          <textarea
                            row="3"
                            className="form-control"
                            name="reason"
                            id="reason"
                            placeholder="Enter your reason"
                            value={reason.name}
                            onChange={event => {
                              setReason({
                                id: index,
                                name: event?.target.value,
                              });
                            }}
                            required
                          />
                          {status.name == "accepted" ? (
                            <span
                              className="error"
                              style={{
                                color: "grey",
                                marginTop: "4px",
                                display: "inline-block",
                                fontSize: "10px",
                              }}
                            >
                              (Can be left blank for "Approved" status)
                            </span>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="d-block">&nbsp;</Label>
                          <Button
                            // type="submit"
                            onClick={() => {
                              // updateSyndicate(index);
                              handleSubmit(index);
                            }}
                          >
                            Submit
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <UncontrolledCollapse
                    className="col-sm-12 mb-3"
                    toggler={`#togler${index}`}
                  >
                    <Row>
                      {!!logsList?.length && (
                        <>
                          <label>Logs List</label>
                          <Col sm={3}>
                            <label>Updated By</label>
                          </Col>
                          <Col sm={3}>
                            <label>Status</label>
                          </Col>
                          <Col sm={3}>
                            <label>Reason</label>
                          </Col>
                          <Col sm={3}>
                            <label>Updated on:</label>
                          </Col>
                          <div
                            style={{
                              height: "120px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {logsList?.map(logs => {
                              return (
                                <div>
                                  {logs.saas_parent_id ==
                                  syndicate.saas_parent_id ? (
                                    <Row key={logs?._id} className="mb-2">
                                      <Col sm={3}>
                                        {logs?.added_by_user_id.firstname}{" "}
                                        {logs?.added_by_user_id.lastname}
                                      </Col>
                                      <Col sm={3} className="text-capitalize">
                                        {logs?.status}
                                      </Col>
                                      <Col sm={3} className="text-capitalize">
                                        {logs?.status_reason}
                                      </Col>
                                      <Col sm={3} className="text-capitalize">
                                        {moment(logs?.createdAt).format(
                                          `DD-MMM'YY hh:mm A`
                                        )}
                                      </Col>
                                    </Row>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </Row>
                  </UncontrolledCollapse>
                </Row>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default SyndicateOptions;
