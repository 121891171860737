import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Col, Container, Label, Row } from "reactstrap";

import { isEmpty, isEqual } from "lodash";
import DataFetchLoader from "../../../components/Common/DataFetchLoader";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";

class Participants extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      post: {},
      userListColumns: [
        {
          text: "userId",
          dataField: "User ID",
          sort: true,
          hidden: true,
        },
        {
          dataField: "userName",
          text: "User Name",
          sort: true,
          filter: textFilter(),
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
        },
        // {
        //   dataField: "userEmail",
        //   text: "Email",
        //   sort: true,
        //   filter: textFilter(),
        //   headerStyle: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        // },
        // {
        //   dataField: "attendedStatus",
        //   text: "Attended Status",
        //   sort: true,
        //   filter: textFilter(),
        //   headerStyle: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        //   style: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        // },
        {
          dataField: "userTypes",
          text: "User Types",
          sort: true,
          filter: textFilter(),
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
          style: (colum, colIndex) => {
            return { textAlign: "center" };
          },
        },
        {
          dataField: "user_verified",
          text: "User verified",
          sort: true,
          filter: textFilter(),
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
          style: (colum, colIndex) => {
            return { textAlign: "center" };
          },
        },
        // {
        //   dataField: "startup_name",
        //   text: "Startup Name",
        //   sort: true,
        //   filter: textFilter(),
        //   headerStyle: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        //   style: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        // },
        {
          dataField: "rsvp",
          text: "RSVP / Event Status",
          sort: true,
          filter: textFilter(),
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
          style: (colum, colIndex) => {
            return { textAlign: "center" };
          },
        },
        // {
        //   dataField: "totalDuration",
        //   text: "Total Duration",
        //   sort: true,
        //   formatter: (cellContent, user) => {
        //     return <>{user?.totalDurationFrames}</>;
        //   },
        //   headerStyle: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        //   style: (colum, colIndex) => {
        //     return { textAlign: "center" };
        //   },
        // },
      ],
      eventCustomQuestions: [],
      eventCustomQuestionsAnswers: [],
    };
  }

  convertSecondsToTimeFrames = (seconds = 0) => {
    if (!seconds) {
      return "0 Seconds";
    }

    const SECOND = 1;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;

    let hours = Math.floor(seconds / HOUR);
    seconds = seconds % HOUR;

    let minutes = Math.floor(seconds / MINUTE);
    seconds = seconds % MINUTE;

    let value = [];

    if (!!hours) {
      value.push(`${hours} Hour${hours > 1 ? "s" : ""}`);
    }
    if (!!minutes) {
      value.push(`${minutes} Minute${minutes > 1 ? "s" : ""}`);
    }
    if (!!seconds) {
      value.push(`${seconds} Second${seconds > 1 ? "s" : ""}`);
    }

    return value.join(", ");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.postDetails, nextProps.postDetails)) {
      this.setState({ post: nextProps.postDetails || {} });
      this.setState({
        eventCustomQuestions: (nextProps?.postDetails?.customFields || [])?.map(
          (question, index) => ({
            ...question,
            keyFieldLabel: `Question-${index + 1}`,
          })
        ),
        eventCustomQuestionsAnswers:
          nextProps?.postDetails?.customFieldsAnswers || [],
      });
    }
  }
  checkUserVerifed(mobile_number_verified, email_verified) {
    if (
      mobile_number_verified?.toString() !== "true" &&
      email_verified?.toString() !== "true"
    ) {
      return "Not verified";
    } else if (
      mobile_number_verified?.toString() !== "true" &&
      email_verified?.toString() === "true"
    ) {
      return "Email";
    } else if (
      mobile_number_verified?.toString() === "true" &&
      email_verified?.toString() !== "true"
    ) {
      return "Mobile";
    } else if (
      mobile_number_verified?.toString() === "true" &&
      email_verified?.toString() === "true"
    ) {
      return "Both";
    }
  }
  render() {
    const { post } = this.state;

    let loading = true;

    let tableData = [];

    let participants = [];
    if (!isEmpty(post) && post !== undefined) {
      participants =
        post?.media_content?.find(
          content => content?.media_type?.[0]?.toLowerCase() === "participants"
        )?.content?.body?.participants || [];
    }

    if (!!post?.invited_investor?.length) {
      tableData = [
        ...tableData,
        ...post?.invited_investor?.map(investor => {
          let investorInfo = investor?.investor_info || {};
          let userData = investor?.userData || {};
          let userName =
            `${userData?.firstname || ""} ${userData?.lastname || ""}`.trim() ||
            userData?.userName;
          let userEmail = userData?.email;
          let rsvp = investorInfo?.rsvp?.status || "No RSVP Yet";

          let totalDuration = participants
            ?.filter(
              part =>
                part?.user_email?.toLowerCase() === userEmail?.toLowerCase()
            )
            ?.reduce((prev, curr) => prev + parseInt(curr?.duration || 0), 0);
          let startup_name = participants?.filter(
            part => part?.user_email?.toLowerCase() === userEmail?.toLowerCase()
          )?.[0]?.startup_name;

          const userTypes = userData?.user_types?.reduce(
            (accumulator, user, index) => {
              const separator = index === 0 ? "" : ", ";
              return accumulator + separator + user?.user_type.join(", ");
            },
            ""
          );
          let attendedStatus = totalDuration > 0 ? "Attended" : "Not Attended";
          const reference = investor?.investor?.reference_name;
          const city_name = investor?.investor?.city_text;
          const state_name = investor?.investor?.state_text;
          const country_name = investor?.investor?.country_text;
          const source = investor?.userData?.source;
          const medium = investor?.userData?.register_platform || source;
          return {
            userId: userData?._id,
            userName: userName,
            userEmail: userEmail,
            attendedStatus: totalDuration > 0 ? "Yes" : "No",
            rsvp: rsvp + " / " + attendedStatus,
            totalDuration: totalDuration,
            startup_name: startup_name?.join(", ") || "",
            userTypes: userTypes,
            reference: reference,
            city_name: city_name,
            country_name: country_name,
            source: source,
            medium: medium,
            industry: investor?.investor?.industry_preference?.join(", ") || "",
            user_verified: this.checkUserVerifed(
              userData?.mobile_number_verified,
              userData?.email_verified
            ),
            totalDurationFrames: this.convertSecondsToTimeFrames(
              totalDuration || 0
            ),
          };
        }),
      ];
    }

    if (!!post?.invited_founder?.length) {
      tableData = [
        ...tableData,
        ...post?.invited_founder?.map(founder => {
          let founderInfo = founder?.founder_info || {};
          let userData = founder?.userData || {};
          let userName =
            `${userData?.firstname || ""} ${userData?.lastname || ""}`.trim() ||
            userData?.userName;
          let userEmail = userData?.email;
          let rsvp = founderInfo?.rsvp?.status || "No RSVP Yet";

          let totalDuration = participants
            ?.filter(
              part =>
                part?.user_email?.toLowerCase() === userEmail?.toLowerCase()
            )
            ?.reduce((prev, curr) => prev + parseInt(curr?.duration || 0), 0);
          let startup_name = participants?.filter(
            part => part?.user_email?.toLowerCase() === userEmail?.toLowerCase()
          )?.[0]?.startup_name;
          const userTypes = userData?.user_types?.reduce(
            (accumulator, user, index) => {
              const separator = index === 0 ? "" : ", ";
              return accumulator + separator + user?.user_type.join(", ");
            },
            ""
          );
          let attendedStatus = totalDuration > 0 ? "Attended" : "Not Attended";
          const reference = founder?.founder?.reference_name;
          const city_name = founder?.founder?.city;
          const state_name = founder?.founder?.state;
          const country_name = founder?.founder?.country;
          const source = founder?.userData?.source;
          const medium = founder?.userData?.register_platform || source;
          return {
            userId: userData?._id,
            userName: userName,
            userEmail: userEmail,
            attendedStatus: totalDuration > 0 ? "Yes" : "No",
            rsvp: rsvp + " / " + attendedStatus,
            totalDuration: totalDuration,
            startup_name: startup_name?.join(", ") || "",
            userTypes: userTypes || "",
            reference: reference,
            city_name: city_name,
            country_name: country_name,
            source: source,
            medium: medium,
            user_verified: this.checkUserVerifed(
              userData?.mobile_number_verified,
              userData?.email_verified
            ),
            totalDurationFrames: this.convertSecondsToTimeFrames(
              totalDuration || 0
            ),
          };
        }),
      ];
    }

    let uniqueParticipants = [
      ...new Set([
        ...participants?.map(part => part?.user_email?.toLowerCase()),
      ]),
    ];

    uniqueParticipants = uniqueParticipants?.filter(
      part =>
        !tableData?.map(data => data?.userEmail?.toLowerCase())?.includes(part)
    );

    if (!!uniqueParticipants?.length) {
      tableData = [
        ...tableData,
        ...uniqueParticipants
          ?.map(email => {
            let participant = participants?.filter(
              part => part?.user_email?.toLowerCase() === email?.toLowerCase()
            );

            if (!participant?.length) {
              return null;
            }

            let { user_id, name, rsvp, startup_name, userData, investor } =
              participant?.[participant?.length - 1] ?? {};

            let totalDuration = participant?.reduce(
              (prev, curr) => prev + parseInt(curr?.duration || 0),
              0
            );
            const userTypes = userData?.user_types?.reduce(
              (accumulator, user, index) => {
                const separator = index === 0 ? "" : ", ";
                return accumulator + separator + user?.user_type.join(", ");
              },
              ""
            );
            let rsvp_status = rsvp || "Uninvited";
            let attendedStatus =
              totalDuration > 0 ? "Attended" : "Not Attended";
            const reference = investor?.reference_name;
            const city_name = investor?.city_text;
            const state_name = investor?.state_text;
            const country_name = investor?.country_text;
            const source = userData?.source;
            const medium = userData?.register_platform || source;
            return {
              userId: user_id,
              userName: name,
              userEmail: email,
              attendedStatus: totalDuration > 0 ? "Yes" : "No",
              rsvp: rsvp_status + " / " + attendedStatus,
              totalDuration: totalDuration,
              startup_name: startup_name?.join(", ") || "",
              userTypes: userTypes || "",
              userTypes: userTypes || "",
              reference: reference,
              city_name: city_name,
              country_name: country_name,
              source: source,
              medium: medium,
              industry: investor?.industry_preference?.join(", ") || "",
              user_verified: this.checkUserVerifed(
                userData?.mobile_number_verified,
                userData?.email_verified
              ),
              totalDurationFrames: this.convertSecondsToTimeFrames(
                totalDuration || 0
              ),
            };
          })
          ?.filter(data => !!data),
      ];
    }

    tableData = tableData?.map(data => {
      let customQuestionAnswers = (
        this.state.eventCustomQuestionsAnswers || []
      )?.find(qa => qa?.user_id?._id?.toString() === data?.userId?.toString());

      let qaData = {};

      if (!!customQuestionAnswers) {
        let answers = customQuestionAnswers?.fields || {};
        for (let question of this.state.eventCustomQuestions || []) {
          let key = question?.keyFieldLabel;
          let value = answers?.[question?.field_name];
          qaData[key] = value;
        }
      }

      return {
        ...data,
        ...qaData,
      };
    });

    loading = false;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );

    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };

    const defaultSorted = [
      {
        dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <DataFetchLoader loading={loading} />
            <Row className="my-5">
              <Col lg={12} hidden>
                <p className="">
                  <span className="text-danger">
                    <strong>Note: </strong>
                  </span>
                  Make sure you have confirmed paticipants roles. You can not
                  modify once the assessment report has been generated.
                </p>
              </Col>

              <Col sm={12} md={9}>
                {!!this.state?.eventCustomQuestions?.length && (
                  <>
                    <Row>
                      <Col md={12}>
                        <Label tag="h5">Event Custom Questions List</Label>
                      </Col>

                      {this.state?.eventCustomQuestions?.map(question => {
                        return (
                          <Col key={question?.field_name} md={12}>
                            <b>{question?.keyFieldLabel} :</b>{" "}
                            {question?.field_label}
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}
              </Col>

              <Col sm={12} md={3} className="mt-2 mt-md-0">
                <div className="text-end">
                  <div>
                    <CSVLink
                      data={tableData}
                      headers={[
                        {
                          key: "userName",
                          label: "User Name",
                        },
                        {
                          key: "userEmail",
                          label: "Email",
                        },
                        {
                          key: "userTypes",
                          label: "User Type",
                        },
                        {
                          key: "reference",
                          label: "Reference",
                        },
                        {
                          key: "industry",
                          label: "Industry",
                        },
                        {
                          key: "rsvp",
                          label: "RSVP / Event Status",
                        },
                        // {
                        //   key: "medium",
                        //   label: "Medium",
                        // },
                      ]}
                      filename={`RSVP Report.csv`}
                    >
                      <Button color="info" outline>
                        <i className="bx bx-export fs-4 mr-3"></i> Export CSV
                      </Button>
                    </CSVLink>
                  </div>
                </div>
              </Col>

              <Col lg="12">
                <Card>
                  <PaginationProvider
                    pagination={paginationFactory(this.options)}
                    keyField="_id"
                    columns={[
                      ...this.state.userListColumns,
                      ...(this.state.eventCustomQuestions || [])?.map(
                        question => ({
                          dataField: question?.keyFieldLabel,
                          text: question?.keyFieldLabel,
                          sort: true,
                          filter: textFilter(),
                          headerStyle: (colum, colIndex) => {
                            return { textAlign: "center" };
                          },
                        })
                      ),
                    ]}
                    data={tableData}
                  >
                    {({ paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={[
                          ...this.state.userListColumns,
                          ...(this.state.eventCustomQuestions || [])?.map(
                            question => ({
                              dataField: question?.keyFieldLabel,
                              text: question?.keyFieldLabel,
                              sort: true,
                              filter: textFilter(),
                              headerStyle: (colum, colIndex) => {
                                return { textAlign: "center" };
                              },
                            })
                          ),
                        ]}
                        data={tableData}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    ref={this.node}
                                    filter={filterFactory()}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Participants.propTypes = {
  match: PropTypes.object,
  postDetails: PropTypes.object,
  error: PropTypes.string,
};

const mapStateToProps = ({ pitchdays }) => ({
  error: pitchdays.updateRoleError,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Participants));
