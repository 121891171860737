import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import MetaTags from "react-meta-tags";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { toCommaFormat } from "../../helpers/backend_helper";
import { amountNumberToTextFrmt } from "../../helpers/utils";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const emptyDataMessage = () => {
  return "No Investment details to display.";
};

const StartupInvestments = ({ match }) => {
  const token = authHeader();
  const [investments, setInvestments] = useState([]);

  const [dealInvestments, setDealInvestments] = useState([]);
  const [error, setError] = useState("");
  const [fund_raised, setFundRaised] = useState(0);
  const [startup, setStartupInfo] = useState({});
  // const [total_wfc_ask, setTotalWfcAsk] = useState("");
  // const [total_fund_ask, setTotalFundAsk] = useState("");
  // const [total_valuation, settotalValuation] = useState("");
  const [apply_for_funding, setApplyForFunding] = useState("");
  const [funding_data, setFundingData] = useState([]);
  // const [valuation_cap, setValuationCap] = useState("");
  // const [valuation_floor, setValuationFloor] = useState("");

  const fetchData = async () => {
    await axios({
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/api/admin/startup/${match.params.sid}/investments`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then(response => {
        setApplyForFunding(response.data.applyforfunding);
        // setFundRaised(response.data.fund_raised);
        setStartupInfo(response.data.startup_info);
        setInvestments(response.data.investments);
      })
      .catch(error => {
        setError(error);
      });
  };

  // const pageOptions = {
  //   sizePerPage: 10,
  //   totalSize: startups || [], // replace later with size(users),
  //   custom: true,
  // };

  const columns = [
    {
      dataField: "investor_name",
      text: "Investor Name",
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, value) => <>{value?.investor_name}</>,
    },
    {
      dataField: "platform",
      text: "Syndicate Name",
      filter: textFilter(),
      formatter: (cellContent, value) => {
        let saasname = value?.investor_id?.platform;
        if (value?.investor_id?.is_saas_parent) {
          saasname = value?.investor_id?.saas_fullname;
        }
        return <>{value.platform}</>;
      },
    },

    {
      dataField: "investor_id.email",
      text: "Email ID",
      filter: textFilter(),
      formatter: (cellContent, value) => <>{value?.investor_id?.email}</>,
    },
    {
      dataField: "funding_round",
      text: "Funding Round",
      filter: textFilter(),
    },
    {
      dataField: "time",
      text: "Round Month/Year",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>{moment(portfolio?.investments?.time).format("MMM YYYY")}</>
      ),
    },
    {
      dataField: "valuation_at_time",
      text: "Valuation/ Valuation Floor-Cap",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.valuation_cap && portfolio?.valuation_floor ? (
            <>
              {/* ₹ */}
              {amountNumberToTextFrmt(portfolio?.valuation_floor || "")}-
              {amountNumberToTextFrmt(portfolio?.valuation_cap || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {amountNumberToTextFrmt(portfolio?.valuation_at_time || "")}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "my_investment",
      text: "Amount Invested",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, value) => (
        <>
          {value?.amount_invested > 999 ? (
            <>
              {/* ₹ */}
              {toThousandsFormatIn(value?.amount_invested || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {value?.amount_invested ? value.amount_invested : 0}
            </>
          )}
        </>
      ),
    },

    {
      dataField: "service_fee",
      text: "Service Fees",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.service_fee > 999 ? (
            <>
              {/* ₹ */}
              {toThousandsFormatIn(portfolio?.service_fee || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {portfolio?.service_fee}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "on_behalf_of",
      text: "On Behalf Of",
      sort: true,
    },
    {
      dataField: "investment_type",
      text: "Investment Type",
      sort: true,
    },
    {
      dataField: "share_count",
      text: "No. of Shares/Units",
      sort: true,
      formatter: (cellContent, portfolio) => (
        <>
          {portfolio?.share_count > 999 ? (
            <>
              {/* ₹ */}
              {toThousandsFormatIn(portfolio?.share_count || "")}
            </>
          ) : (
            <>
              {/* ₹ */}
              {portfolio?.share_count}
            </>
          )}
        </>
      ),
    },
    {
      dataField: "amount_invested",
      text: "Share/Unit Price",
      formatter: (cellContent, portfolio) => (
        <>
          {calcPricePerShare(portfolio) && calcPricePerShare(portfolio) > 0 && (
            <>
              {portfolio.investment_type === "Syndicate"
                ? amountNumberToTextFrmt(calcPricePerShare(portfolio)) + ""
                : portfolio.investment_type === "Direct"
                ? amountNumberToTextFrmt(calcPricePerShare(portfolio)) + ""
                : amountNumberToTextFrmt(calcPricePerShare(portfolio))}
            </>
          )}
        </>
      ),
    },

    // {
    //   dataField: "funding_source",
    //   text: "Funding Source",
    //   filter: textFilter(),
    // },
    // {
    //   dataField: "how_much_fund_raising",
    //   text: "Fund Raising Amount",
    //   filter: textFilter(),
    // },
    // {
    //   dataField: "number_of_investors",
    //   text: "Numbers Of Investors",
    //   filter: textFilter(),
    // },
    // {
    //   dataField: "deal_type",
    //   text: "Deal Type",
    //   filter: textFilter(),
    // },
    // {
    //   dataField: "menu",
    //   isDummyField: true,
    //   editable: false,
    //   text: "View Investment Reports",
    //   formatter: (cellContent, startup) => (
    //     <div className="d-flex gap-3">
    //       <Link className="text-success" to="#">
    //         <i
    //           className="mdi mdi-eye font-size-18"
    //           title="Click to View investment reports"
    //           id="edittooltip"
    //           onClick={() => handleSelect(startup)}
    //         ></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    const sid = match?.params?.sid || null;
    // console.log("sid ", sid);
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    if (apply_for_funding.length > 0) {
      const query = new URL(window.location.href);
      let funding_deal_id = query.searchParams.get("funding_round_id");
      let total_investment = query.searchParams.get("total_investment");
      let fundingData = apply_for_funding.filter(item => {
        if (item._id === funding_deal_id) return item;
      });

      setFundingData(fundingData[0]);
      setFundRaised(total_investment);
    }
    return () => {};
  }, [apply_for_funding]);

  useEffect(() => {
    const query = new URL(window.location.href);
    let funding_deal_id = query.searchParams.get("funding_round_id");
    let dealArray = [];
    dealArray = investments.filter(item => {
      if (item.deal_id === funding_deal_id) return item;
    });

    setDealInvestments(dealArray);
    return () => {};
  }, [investments]);

  const toThousandsFormatIn = v => {
    let fmt_val = "";
    const formatter = new Intl.NumberFormat("en-IN");
    try {
      fmt_val = formatter.format(v);
      if (fmt_val === "NaN") fmt_val = v;
    } catch (err) {
      // console.log(err);
    }
    return fmt_val;
  };

  const calcPricePerShare = value => {
    let price_per_share = " ";
    try {
      if (value?.amount_invested && value?.share_count) {
        price_per_share = Math.round(
          parseInt(value?.amount_invested) / parseInt(value?.share_count)
        );
      }
    } catch (err) {
      //console.log(err);
    }
    return price_per_share;
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    // totalSize: total,
    disablePageTitle: false,
    paginationTotalRenderer: customTotal,
    // onPageChange: async (page, size) => {
    //   setPage(page);
    // },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Investments Details | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col md="6">
              <div>
                {startup?.startup_logo && (
                  <img width="50" height="50" src={startup?.startup_logo} />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <h5>Registered Name : {startup?.registered_name}</h5>
            </Col>
            <Col md="6">
              <h5>
                {parseInt(funding_data?.funding_information?.valuation_floor) >
                  0 &&
                parseInt(funding_data?.funding_information?.valuation_cap) > 0
                  ? "Valuation Floor-Cap"
                  : "Valuation"}
                :{" "}
                {parseInt(funding_data?.funding_information?.valuation_floor) >
                  0 &&
                parseInt(funding_data?.funding_information?.valuation_cap) > 0
                  ? amountNumberToTextFrmt(
                      parseInt(
                        funding_data?.funding_information?.valuation_floor
                      )
                    ) +
                    "-" +
                    amountNumberToTextFrmt(
                      parseInt(funding_data?.funding_information?.valuation_cap)
                    )
                  : amountNumberToTextFrmt(
                      parseInt(
                        funding_data?.funding_information?.current_valuation
                      )
                    )}
              </h5>
            </Col>
            {/* <Col md="6">
              <div>
                {startup?.startup_logo && <img width="50" height="50" src={startup?.startup_logo} />}
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col md="6">
              <h5>Founder Name : {startup?.founder_name}</h5>
            </Col>
            <Col md="6">
              <h5> Startup Name : {startup?.startup_name}</h5>
            </Col>
          </Row>
          <Row>
            {/* <Col md="6">
              <h5>Tag Line : {startup?.tag_line}</h5>
            </Col> */}
            <Col md="6">
              <h5>
                {" "}
                Fund Ask-Target (Total) :{" "}
                {amountNumberToTextFrmt(
                  funding_data?.funding_information?.fund_ask_target
                    ? parseInt(
                        funding_data?.funding_information?.fund_ask_target
                      )
                    : ""
                )}
              </h5>
            </Col>
            <Col md="6">
              <h5>Investment : {amountNumberToTextFrmt(fund_raised)}</h5>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <h5>
                Fund Ask-Target (Syndicate) :{" "}
                {amountNumberToTextFrmt(
                  parseInt(
                    funding_data?.funding_information?.how_much_fund_raising
                  )
                )}
              </h5>
            </Col>
          </Row>
          <br />
          <Breadcrumbs
            title="Startup Investment Details"
            breadcrumbItem="Investments Details"
            removeBreadcrumb={true}
          />
          <React.Fragment>
            <Row>
              <Col xl="12">
                {error ? (
                  <p className="text-danger">Some error occured!</p>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <Col lg="12">
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={dealInvestments}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={dealInvestments}
                          search
                        >
                          {toolkitprops => (
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                keyField="_id"
                                data={dealInvestments}
                                columns={columns}
                                bordered={false}
                                striped={true}
                                responsive
                                filter={filterFactory()}
                                noDataIndication={emptyDataMessage}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Col>
                </CardBody>
              </Card>
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

StartupInvestments.propTypes = {
  startups: PropTypes.array,
  onGetStartups: PropTypes.func,
  className: PropTypes.any,
  match: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  disabled: PropTypes.bool,
};

export default StartupInvestments;
