/* INVESTORS */
export const GET_INVESTOR = "GET_INVESTOR"
export const GET_INVESTOR_SUCCESS = "GET_INVESTOR_SUCCESS"
export const GET_INVESTOR_FAIL = "GET_INVESTOR_FAIL"

/* INVESTORS STATUS */
export const TOGGLE_STATUS = "TOGGLE_STATUS"
export const TOGGLE_STATUS_SUCCESS = "TOGGLE_STATUS_SUCCESS"
export const TOGGLE_STATUS_FAIL = "TOGGLE_STATUS_FAIL"


/* INVESTORS DETAIL */
export const GET_INVESTOR_DETAIL = "GET_INVESTOR_DETAIL"
export const GET_INVESTOR_DETAIL_SUCCESS = "GET_INVESTOR_DETAIL_SUCCESS"
export const GET_INVESTOR_DETAIL_FAIL = "GET_INVESTOR_DETAIL_FAIL"
