import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { isEmpty } from "lodash";
import Select from "react-select";

import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import {
  getGroups,
  deleteGroup,
  addNewGroup,
  updateGroup,
  getAllUsersForReference,
} from "../../../helpers/backend_helper";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
//Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb";
import UsersList from "./users-list-embbed";
import * as moment from "moment";
import axios from "axios";
import DataFetchLoader from "components/Common/DataFetchLoader";

function GroupsList(props) {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
  };

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({}); // single selected group for edit
  const [selectedGroups, setSelectedGroups] = useState([]); // selected groups for uplift
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [saasName, setSaasName] = useState();
  const [saasParentId, setSaasParentId] = useState();
  const [errors, setErrors] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userRoleType, setUserRoleType] = useState(undefined);
  const node = React.createRef();

  const [editGroupModal, setEditGroupModal] = useState(false);
  const [editGroup, setEditGroup] = useState({});
  const [editErrorMessage, setEditErrorMessage] = useState("");
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedSaas, setSelectedSaas] = useState({
    value: "",
    label: "Select Domain Expert Parent",
  });
  // const [referenceUserList, setReferenceUserList] = useState([]);
  const [saasPartnersList, setSaasPartnersList] = useState([]);
  const [saasFilterOptions, setSaasFilterOptions] = useState([]);
  const [selectedSaasCondition, setSelectedSaasCondition] = useState(false);
  const loginUser = JSON.parse(localStorage.getItem("authUser")).userInfo;
  const show_all_saas_data = JSON.parse(
    localStorage.getItem("roleWiseAccess")
  )?.show_all_saas_data;
  const is_super_admin = loginUser?.is_super_admin;

  useEffect(() => {
    // if (!modal) {
    getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_fullname,
              saas_name,
              saas_alias,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            if (user?.is_saas_parent) {
              let temp2 = saas_name;
              if (temp2) {
                temp2 += ` (${saas_fullname})`;
              } else {
                temp2 = saas_name || saas_alias;
              }
              temp = temp2 || temp;

              saasPartners.push({
                value: _id,
                label: temp,
              });
            }

            return { value: _id, label: temp };
          })
          .filter(data => !!data);

        setSaasFilterOptions(saasPartners);
        if (show_all_saas_data == true) {
          saasPartners = [
            { value: "", label: "Select Domain Expert Parent" },
            ...saasPartners,
          ];
          setSelectedSaas({
            value: "",
            label: "Select Domain Expert Parent",
          });
        } else {
          if (is_super_admin == false) {
            saasPartners = [...saasPartners];
            setSelectedSaas({
              value: saasPartners[0].saas_parent_id,
              label: saasPartners[0].label,
            });
            setSelectedSaasCondition(true);
          } else {
            saasPartners = [
              { value: "", label: "Select Domain Expert Parent" },
              ...saasPartners,
            ];
            setSelectedSaas({
              value: "",
              label: "Select Domain Expert Parent",
            });
          }
        }

        // setReferenceUserList(users);
        setSaasPartnersList(saasPartners);
      })
      .catch(error => {
        console.trace(error);
        // setReferenceUserList([]);
        setSaasPartnersList([]);
        // setSaasFilterOptions([]);
      });
    //}
    return () => {};
  }, []);

  useEffect(() => {
    isVisible !== undefined && setIsVisible(props.usedForEvent);
    userRoleType === undefined && setUserRoleType(props.userRoleType);
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
    return () => {};
  }, []);

  const toggleEditGroupModal = () => setEditGroupModal(prevValue => !prevValue);

  const handleEditGroupSubmit = async (evt, form) => {
    evt.preventDefault();
    try {
      let group = {
        _id: editGroup._id,
        ...form,
        updateGroupDetails: true,
        saas_parent_id: selectedSaas?.value,
      };
      let data = await updateGroup(group);

      if (data.status) {
        setEditErrorMessage("");
        setEditGroup({});
        toggleEditGroupModal();
        await fetchData();
      } else {
        setEditErrorMessage(data.message);
      }
    } catch (error) {
      setEditErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const fetchData = () => {
    getGroups().then(res => {
      setLoading(true);
      if (res && res.length > 0) {
        let filterGroup = [];
        if (props.userRoleType === undefined) {
          filterGroup = res.map(el => {
            return el;
          });
        } else {
          if (props.userRoleType) {
            res.map(el => {
              if (el.type === "Investor") {
                filterGroup.push(el);
              }
            });
          } else {
            res.map(el => {
              if (el.type === "Founder") {
                filterGroup.push(el);
              }
            });
          }
        }
        setGroups(filterGroup);
      }
    });
  };

  const updateGroupMembers = group => {
    updateGroup(group).then(res => {
      fetchData();
    });
  };

  const handleDeleteGroup = async group => {
    if (group._id && window.confirm("Are you sure, want to delete?")) {
      await deleteGroup(group._id);
      onPaginationPageChange(1);
      await fetchData();
    }
  };

  const handleGroupClick = async group => {
    let saas_name = group?.saas_detail?.saas_name;
    let saas_parent_id = group?.saas_parent_id;
    setSaasName(saas_name);
    setSaasParentId(saas_parent_id);
    setSelectedGroup(group);
    //on change in group reset selected users for child
    setSelectedUsers(group?.members);
    setModal2(true);
  };

  const handleValidGroupSubmit = async (e, group) => {
    let status = { status: false };
    group.saas_parent_id = selectedSaas?.value;
    if (selectedSaasCondition == true) {
      group.saas_parent_id = saasPartnersList[0]?.value;
    }
    if (isEdit) {
      //update
      group.id = selectedGroup.id;
      status = await updateGroup(group);
    } else {
      //create new group
      status = await addNewGroup(group);
    }

    if (status.status === true) {
      setModal(false);
      await fetchData();
      return true;
    }
  };

  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const toggleStatus = group => {
    if (group.status === "Active") {
      group.status = "Suspended";
    } else {
      group.status = "Active";
    }
    //update group status
    updateGroupMembers(group);
  };

  const toggle = (type = "modal") => {
    let sts = true;

    if (selectedSaasCondition == false) {
      setSelectedSaas({
        value: "",
        label: "Select Domain Expert Parent",
      });
    }

    if (type === "modal") {
      if (modal === true) {
        sts = false;
      }
      setModal(sts);
    } else {
      if (modal2 === true) {
        sts = false;
      }
      setModal2(sts);
    }
  };

  const defaultValues = {
    is_admin: !isEmpty(groups.is_admin) ? groups.is_admin : "false",
    mobile_number_verified: !isEmpty(groups.mobile_number_verified)
      ? groups.mobile_number_verified
      : "false",
    email_verified: !isEmpty(groups.email_verified)
      ? groups.email_verified
      : "false",
    status: !isEmpty(groups.status) ? groups.status : "Pending",
  };

  const selectAllUsers = async users => {
    await setSelectedUsers(users);

    //update members on close of modal 2
    let currGroup = selectedGroup;
    currGroup.members = users;
    await updateGroupMembers(currGroup);
    toggle("modal2");
  };

  //groups embbed for another modules
  const curSelected = () => {
    let groupArr = [];
    selectedGroups.forEach((ele, i) => {
      groupArr.push(ele._id);
    });
    return groupArr;
  };

  const memberslength = (member, type, saas_name) => {
    let j = 0;
    member?.forEach((ele, i) => {
      let user_types = ele?.user_types;
      user_types?.forEach(user_type => {
        if (user_type?.user_type?.includes(type.toLowerCase())) {
          j++;
        }
      });
    });
    return j;
  };

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, group) => <>{group._id}</>,
    },
    {
      text: "Group Name",
      dataField: "name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "saas_parent_id",
      text: "Domain Expert Name",
      formatter: (cellContent, user) => {
        let value = user?.saas_detail?.saas_name;
        return <>{value}</>;
      },
      filter:
        loginUser?.is_super_admin || show_all_saas_data
          ? selectFilter({
              options: saasFilterOptions.sort((a, b) => {
                if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
                  return -1;
                }
                if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
                  return 1;
                }
                return 0;
              }),
              delay: 1500,
            })
          : false,
    },
    {
      text: "Type",
      dataField: "type",
      sort: true,
      filter: textFilter(),
    },
    access?.userGroupsMembersEdit
      ? {
          text: "Members",
          dataField: "members",
          sort: true,
          formatter: (cellContent, group) => {
            return (
              <>
                <span className="px-2">
                  {" "}
                  {memberslength(
                    group.user,
                    group.type,
                    group?.saas_detail?.saas_name
                  )}{" "}
                </span>
                <Link className="text-success" to="#" title="Add Members">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={() => {
                      handleGroupClick(group);
                    }}
                  ></i>
                </Link>
              </>
            );
          },
        }
      : "",
    access?.userGroupsStatus
      ? (isVisible === false || isVisible === undefined) && {
          dataField: "status",
          text: "Status",
          sort: true,
          formatter: (cellContent, groups) => (
            <label className="toggleSwitch large">
              <input
                type="checkbox"
                checked={groups.status === "Active" ? true : false}
                onChange={e => toggleStatus(groups)}
              />
              <span>
                <span></span>
                <span></span>
              </span>
              <a></a>
            </label>
          ),
        }
      : "",
    (isVisible === false || isVisible === undefined) &&
      ({
        text: "Created",
        dataField: "createdAt",
        sort: true,
        formatter: (cellContent, groups) => (
          <>{moment(new Date(groups.createdAt)).format("DD MMM Y")}</>
        ),
      },
      access?.userGroupsAction
        ? {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            formatter: (cellContent, groups) => (
              <div className="d-flex gap-3">
                <Link className="text-info" to="#" title="Edit Group">
                  <i
                    className="mdi mdi-clipboard-edit-outline font-size-18"
                    id="deletetooltip"
                    onClick={() => {
                      setEditGroup(groups);
                      setSelectedSaas({ value: groups.saas_parent_id });
                      toggleEditGroupModal();
                    }}
                  ></i>
                </Link>
                {/* <Link className="text-danger" to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => handleDeleteGroup(groups)}
              ></i>
            </Link> */}
              </div>
            ),
          }
        : ""),
  ];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: groups.length, // replace later with size(groups),
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: curSelected(),
    onSelectAll: (isSelect, rows, e) => {
      let finalArry;

      if (isSelect) {
        finalArry = [...new Set([...selectedGroups, ...rows])];
      } else {
        finalArry = selectedGroups;
        rows.forEach((e, i) => {
          selectedGroups.forEach((ele, ind) => {
            if (e._id === ele._id) {
              finalArry.splice(ind, 1);
            }
          });
        });
      }

      setSelectedGroups(finalArry);
    },

    onSelect: (row, isSelect) => {
      let finalArry;

      if (isSelect) {
        finalArry = [...new Set([...selectedGroups, ...[row]])];
      } else {
        finalArry = selectedGroups;
        finalArry.forEach((e, i) => {
          if (e._id === row._id) {
            finalArry.splice(i, 1);
          }
        });
      }

      setSelectedGroups(finalArry);
    },
  };

  const saveSelectedGroups = () => {
    props.onSelectGroup(selectedGroups);
  };

  const setSelectedSaasFun = e => {
    setSelectedSaas({ value: e.value, label: e.label });
  };

  return (
    <>
      <React.Fragment>
        <div
          className={
            (isVisible === false || isVisible === undefined) && `page-content`
          }
        >
          {(isVisible === false || isVisible === undefined) && (
            <MetaTags>
              <title>Group | Invstt - Admin</title>
            </MetaTags>
          )}
          <Container fluid>
            <DataFetchLoader loading={!loading} />
            <Row className="d-flex align-items-center">
              <Col sm="6">
                <h5 className="text-uppercase mb-0">User Groups</h5>
              </Col>
              <Col sm="6">
                {access?.createNewGroup
                  ? (isVisible === false || isVisible === undefined) && (
                    <Row className="">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative"></div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={e => toggle()}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Create New Group
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )
                  : ""}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  {/* {console.log(groups, "groups")} */}
                  <DataFetchLoader loading={groups ? false : true} />
                  <CardBody>
                    <React.Fragment>
                      {/* {access?.createNewGroup
                        ? (isVisible === false || isVisible === undefined) && (
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative"></div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={e => toggle()}
                                  >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Create New Group
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          )
                        : ""} */}
                      <Row>
                        <Col xl="12">
                          {!isEmpty(errors) ? (
                            <p className="text-danger">Some error occured!</p>
                          ) : null}
                        </Col>
                      </Row>
                      <PaginationProvider
                        pagination={paginationFactory(options)}
                        keyField="_id"
                        columns={columns}
                        data={groups}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="_id"
                            columns={columns}
                            data={groups}
                            search={{
                              searchFormatted: false,
                            }}
                          >
                            {toolkitprops => (
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                keyField="_id"
                                // selectRow={selectRow}
                                data={groups}
                                columns={columns}
                                bordered={false}
                                striped={true}
                                responsive
                                filter={filterFactory()}
                              />
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <Modal size="lg" isOpen={modal} toggle={e => toggle()}>
                        <ModalHeader toggle={e => toggle()} tag="h4">
                          Add Group
                        </ModalHeader>
                        <ModalBody>
                          <AvForm
                            onValidSubmit={handleValidGroupSubmit}
                            model={defaultValues}
                          >
                            <Row form>
                              <Col className="col-12">
                                <>
                                  {selectedSaasCondition == true ? (
                                    <div className="mb-3">
                                      <label>Domain Expert Parent</label>
                                      <Select
                                        placeholder={"Select Domain Expert Parent"}
                                        name="saas_parent_id"
                                        options={saasPartnersList}
                                        onChange={setSelectedSaas}
                                        value={saasPartnersList[0]}
                                      />
                                    </div>
                                  ) : (
                                    <div className="mb-3">
                                      <label>Domain Expert Parent</label>
                                      <Select
                                        placeholder={"Select Domain Expert Parent"}
                                        name="saas_parent_id"
                                        options={saasPartnersList}
                                        onChange={setSelectedSaasFun}
                                        value={saasPartnersList.filter(
                                          option =>
                                            option.value === selectedSaas.value
                                        )}
                                      />
                                    </div>
                                  )}
                                </>
                              </Col>
                            </Row>
                            <Row form>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="name"
                                    label="Group Name"
                                    type="text"
                                    errorMessage="Invalid Group Name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={groups.name || ""}
                                  />
                                </div>

                                <div className="mb-3">
                                  <label>Group Type</label>
                                  <AvRadioGroup name="group_type" required>
                                    <AvRadio
                                      label="Investor"
                                      value="Investor"
                                    />
                                    <AvRadio label="Founder" value="Founder" />
                                    {/* <AvRadio label="Professional" value="Professional" /> */}
                                  </AvRadioGroup>
                                </div>
                                <div className="mb-3">
                                  <AvField
                                    name="search_tags"
                                    label="Group Description"
                                    type="text"
                                    placeholder=""
                                    errorMessage="Invalid Search Tags"
                                    value={groups.search_tags || ""}
                                    validate={{
                                      required: { value: false },
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label>Status</label>
                                  <AvRadioGroup name="status" required>
                                    <AvRadio label="Active" value="Active" />
                                    <AvRadio
                                      label="Inactive"
                                      value="Suspended"
                                    />
                                  </AvRadioGroup>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-group"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                      {/* second modal */}
                      <Modal size="lg" isOpen={modal2} toggle={e => toggle()}>
                        <ModalHeader toggle={e => toggle("modal2")} tag="h4">
                          Add Members: <i>{selectedGroup.name}</i>
                        </ModalHeader>
                        <UsersList
                          saasName={saasName}
                          onSelectAll={selectAllUsers}
                          selectedUsers={selectedUsers}
                          userType={selectedGroup?.type}
                          saasParentId={saasParentId}
                        />
                      </Modal>

                      {/* Edit Group Details Modal */}
                      <Modal
                        size="lg"
                        isOpen={editGroupModal}
                        toggle={toggleEditGroupModal}
                      >
                        <ModalHeader toggle={toggleEditGroupModal} tag="h4">
                          Edit Group
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={handleEditGroupSubmit}>
                            <Row form>
                              <Col className="col-12">
                                <>
                                  <div className="mb-3">
                                    <label>Domain Expert Parent</label>
                                    <Select
                                      placeholder={"Select Domain Expert Parent"}
                                      name="saas_parent_id"
                                      options={saasPartnersList}
                                      onChange={setSelectedSaasFun}
                                      value={saasPartnersList.filter(
                                        option =>
                                          option.value === selectedSaas.value
                                      )}
                                    />
                                  </div>
                                </>
                              </Col>
                            </Row>
                            <Row form>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="name"
                                    label="Group Name"
                                    type="text"
                                    errorMessage="Invalid Group Name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={editGroup.name || ""}
                                  />
                                </div>

                                <div className="mb-3">
                                  <label>Group Type</label>
                                  <AvRadioGroup
                                    name="type"
                                    required
                                    value={editGroup.type || null}
                                  >
                                    <AvRadio
                                      label="Investor"
                                      value="Investor"
                                    />
                                    <AvRadio label="Founder" value="Founder" />
                                    {/* <AvRadio label="Professional" value="Professional" /> */}
                                  </AvRadioGroup>
                                </div>
                                <div className="mb-3">
                                  <AvField
                                    name="search_tags"
                                    label="Group Description"
                                    type="text"
                                    errorMessage="Invalid Search Tags"
                                    validate={{
                                      required: { value: false },
                                    }}
                                    value={editGroup.search_tags || ""}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label>Status</label>
                                  <AvRadioGroup
                                    name="status"
                                    required
                                    value={editGroup.status || null}
                                  >
                                    <AvRadio label="Active" value="Active" />
                                    <AvRadio
                                      label="Inactive"
                                      value="Suspended"
                                    />
                                  </AvRadioGroup>
                                </div>
                                {editErrorMessage && (
                                  <div className="mb-3">
                                    <Alert color="danger">
                                      {editErrorMessage}
                                    </Alert>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-group"
                                  >
                                    Update
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>

                      {/* <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row> */}
                    </React.Fragment>

                    {isVisible === true && (
                      <Col sm={4}>
                        <Button
                          color="info"
                          className="font-16 btn-block btn btn-primary"
                          onClick={e => saveSelectedGroups()}
                        >
                          Save
                        </Button>
                      </Col>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
GroupsList.propTypes = {
  selectedUsers: PropTypes.array,
  selectedGroups: PropTypes.array,
  selectedGroup: PropTypes.object,
  usedForEvent: PropTypes.any,
  userRoleType: PropTypes.string,
  onSelectGroup: PropTypes.func,
};

GroupsList.mapStateToProps = (state, ownProps) => {
  return {
    groups: state.isLoggedIn,
  };
};

export default GroupsList;
