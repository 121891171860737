import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Button,
  Table,
  Alert,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { closeAlertBox, removeKPI, updateKPIs } from "../../store/kpi/actions";
import AddKPI from "./addkpi-modal";
import { isEmpty } from "lodash";
import { encryptDataToArray } from "helpers/EncryptDecrypt";
const KPI_VALUE_TYPES = ["Percentage", "Number", "Text", "Currency"];
const CHECK_OPTIONS = ["Yes", "No"];

class KPIListingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup: null,
      addMore: false,
      anyKPIAddEdit: false, // Any new kpi added or edit
      edit: false,
      error: "",
      show: true,
      success: "",
      kpis_to_update: {},
      currencyCodes: [],
    };
  }

  componentDidMount() {
    const { startup, addMore, startup_kpis, CurrencyCodes } = this.props;
    this.setState({
      startup: startup || startup_kpis,
      currencyCodes: CurrencyCodes,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.error,
      success: nextProps.success,
      addMore: nextProps.addMore,
      startup: nextProps.startup,
    });
  }

  toggleAddMore = () => {
    this.setState({ addMore: !this.state.addMore, anyKPIAddEdit: true });
  };

  toggleEditKPIs = () => {
    this.setState({ edit: !this.state.edit, anyKPIAddEdit: true });
  };

  handleEditKPI = () => {
    this.toggleEditKPIs();
    const { onKPIEdit } = this.props;
    let updated_kpis = {},
      kpis = this.state.startup?.kpis || {};
    // clean up KPIs data
    // for each kpi type
    Object.keys(kpis).map((k_type, i) => {
      let user_kpi_type = kpis[k_type] || {};
      // for each kpi in kpi type
      Object.keys(user_kpi_type).map((kpi, i) => {
        let kpi_data = user_kpi_type[kpi];
        kpi_data.symbol = this.state.startup?.currency?.symbol;
        delete kpi_data["key_name"];
        if (["additional_kpi", "business_kpi"].includes(k_type)) {
          let kpi_type = "business_kpi";
          if (Object.keys(updated_kpis).includes(kpi_type)) {
            updated_kpis[kpi_type].push(kpi_data);
          } else {
            updated_kpis[kpi_type] = [kpi_data];
          }
        } else {
          if (Object.keys(updated_kpis).includes(k_type)) {
            updated_kpis[k_type][kpi] = kpi_data;
          } else {
            updated_kpis[k_type] = { [kpi]: kpi_data };
          }
        }
      });
    });

    let q = {
      kpis: updated_kpis,
      startup_id: this.state.startup?.startup_id || "",
    };
    let encryptedQuery = encryptDataToArray(q)
    let data = {
      q: JSON.stringify(encryptedQuery)
    }
    onKPIEdit(data);
  };

  onClose = () => {
    this.setState({ show: !this.state.show });
    this.props.onClose();
    if (this.state.anyKPIAddEdit) {
      this.props.onKPIUpdateDone();
    }
  };

  isChecked = v => {
    return ["true", true].includes(v);
  };

  handleChange = (e, k, kpi_type) => {
    let startup = this.state.startup || {};
    let kpi_name = e.target.name;
    kpi_name = kpi_name.replace("-" + k, "");

    if (
      [
        "financial_kpi",
        "engagement_kpi",
        "business_kpi",
        "additional_kpi",
      ].includes(kpi_type)
    ) {
      let val = e.target.value;
      if (k === "value_type") val = val.toLowerCase();
      if (k === "name") {
        let kpi = startup.kpis[kpi_type][kpi_name] || {};
        if (!kpi.old_name) {
          kpi.old_name = kpi.name;
        }
        kpi.name = val;
        startup.kpis[kpi_type][kpi_name] = kpi;
      } else {
        startup.kpis[kpi_type][kpi_name][k] = val;
      }
    }

    this.setState({ startup: startup });
  };

  handleCheck = (e, k, kpi_type) => {
    if (this.state.edit) {
      let startup = this.state.startup || {};
      let kpi_name = e.target.name;
      let val = [
        "is_mandatory",
        "show_on_profile",
        "show_to_all_users",
      ].includes(k)
        ? e.target.checked
        : e.target.value;
      startup.kpis[kpi_type][kpi_name][k] = val;
      this.setState({ startup: startup });
    }
  };

  render() {
    const capitalizeStr = str => {
      let cap_str = str;
      if (str) {
        cap_str = str
          .split(" ")
          .map((p, i) => `${p.charAt(0).toUpperCase()}${p.slice(1)}`)
          .join(" ");
      }
      return cap_str;
    };

    const canRenderKPI = k_type => {
      let user_kpis = {},
        kpis = this.state.startup?.kpis || {};
      if (kpis.hasOwnProperty(k_type)) {
        user_kpis = kpis[k_type];
      }

      return Object.keys(user_kpis).length > 0;
    };

    const renderOptions = values => {
      return values.map((value, i) => (
        <option key={i} value={value}>
          {value}
        </option>
      ));
    };

    // can column value can render as check box
    const canRenderAsCheck = c => {
      return ["is_mandatory", "show_on_profile", "show_to_all_users"].includes(
        c
      );
    };

    // method for rendering all kpis
    const render_kpis = (kpis, kpi_type, editMode) => {
      let user_kpis = [];
      if (kpis.hasOwnProperty(kpi_type)) {
        let user_kpi_type = kpis[kpi_type];
        user_kpis = Object.keys(user_kpi_type).map((k, i) => {
          let kpi = user_kpi_type[k];
          kpi.key_name = k;
          kpi.name = kpi?.name || capitalizeStr(k.replace("_", " "));
          // if (kpi?.is_delete === false) {
          //   return;
          // }
          return kpi;
        });
      }

      // dict key and col name map
      let key_col_map = {
        name: "Name",
        // value_type: "Value Type",
        // symbol: "Symbol",
        is_mandatory: "Is Mandatory",
        show_on_profile: "Show on Startup Page",
        show_to_all_users: "Show To All Users",
      };

      return (
        <AvForm>
          <Table bordered>
            <thead>
              <tr>
                {Object.keys(key_col_map).map((k, i) => (
                  <th key={i}>{key_col_map[k]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {user_kpis.map((kpi, i) => (
                <tr key={i}>
                  {Object.keys(key_col_map).map((k, i) => (
                    <td key={i}>
                      {!canRenderAsCheck(k) && !editMode && (
                        <span
                          style={{ fontFamily: k == "symbol" ? "auto" : "" }}
                        >
                          {kpi[k] || "-"}
                        </span>
                      )}
                      {!canRenderAsCheck(k) &&
                        editMode &&
                        ["name"].includes(k) && (
                          <FormGroup>
                            <AvField
                              type="text"
                              name={kpi.key_name || ""}
                              id={kpi.key_name}
                              onChange={e => this.handleChange(e, k, kpi_type)}
                              value={kpi[k]}
                              style={{
                                fontFamily: k == "symbol" ? "auto" : "",
                              }}
                              placeholder={`KPI ${kpi?.name || ""}`}
                              errorMessage={`KPI ${
                                kpi?.name || ""
                              } is required`}
                              validate={{
                                required: {
                                  value: k == "symbol" ? false : true,
                                },
                              }}
                            />
                          </FormGroup>
                        )}
                      {/* {!canRenderAsCheck(k) && editMode && k === "value_type" && (
                        <FormGroup>
                          <AvField
                            name={`${kpi.key_name}-${k}`}
                            id={`${kpi.key_name}-${k}`}
                            label=""
                            type="select"
                            onChange={e => this.handleChange(e, k, kpi_type)}
                            errorMessage="Please select KPI value type"
                            value={capitalizeStr(kpi[k])}
                            validate={{
                              required: { value: true },
                            }}
                          >
                            {renderOptions(KPI_VALUE_TYPES)}
                          </AvField>
                        </FormGroup>
                      )}
                      {!canRenderAsCheck(k) && editMode && k === "symbol" && (
                        <FormGroup>
                          <AvField
                            name={`${kpi.key_name}-${k}`}
                            id={`${kpi.key_name}-${k}`}
                            label=""
                            type="select"
                            onChange={e => this.handleChange(e, k, kpi_type)}
                            errorMessage="Please select symbol value"
                            value={kpi[k]}
                            validate={{
                              required: { value: true },
                            }}
                          >
                            {renderOptions(this.state.currencyCodes)}
                          </AvField>
                        </FormGroup>
                      )} */}
                      {canRenderAsCheck(k) && (
                        <FormGroup check>
                          <Label check>
                            {kpi[k]?.name}
                            <Input
                              type="checkbox"
                              name={kpi?.key_name}
                              id={kpi?.key_name}
                              title={key_col_map[k]}
                              onChange={e => this.handleCheck(e, k, kpi_type)}
                              checked={this.isChecked(kpi[k])}
                            />
                          </Label>
                        </FormGroup>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </AvForm>
      );
    };

    return (
      <div className="page-content">
        <Modal isOpen={this.state.show} toggle={() => this.onClose()} size="lg">
          <ModalHeader toggle={() => this.onClose()} tag="h4">
            <span className="text-primary">
              {this.state.startup?.startup_name}
            </span>{" "}
            KPIs Details
          </ModalHeader>
          <ModalBody>
            <React.Fragment>
              <Row>
                <Col xl="12">
                  {!isEmpty(this.state.error) ? (
                    <Alert color="danger">
                      {this.state.error || "Some error occurred!"}
                    </Alert>
                  ) : null}
                  {!!this.state.success ? (
                    <Alert color="success">{this.state.success}</Alert>
                  ) : null}
                </Col>
              </Row>
              {this.state.success === "" && (
                <>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative"></div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12" className="d-flex justify-content-end">
                      {!this.state.addMore && (
                        <Button
                          type="submit"
                          color="info"
                          title={`Click to Add More KPIs for ${
                            this.state?.startup?.startup_name || ""
                          }`}
                          onClick={this.toggleAddMore}
                        >
                          {" "}
                          Add More KPIs{" "}
                        </Button>
                      )}{" "}
                      &nbsp;&nbsp;&nbsp;
                      {!this.state.edit && (
                        <Button
                          type="submit"
                          color="primary"
                          className="ml-3"
                          title={`Click to edit KPIs for ${
                            this.state?.startup?.startup_name || ""
                          }`}
                          onClick={this.toggleEditKPIs}
                        >
                          {" "}
                          Edit KPIs{" "}
                        </Button>
                      )}
                      {this.state.edit && (
                        <Button
                          color="outline-success"
                          className="ml-3"
                          title={"Click to save KPIs changes"}
                          onClick={this.handleEditKPI}
                        >
                          {" "}
                          Save{" "}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {/* ADd new KPI fields */}
                  {this.state.addMore && (
                    <AddKPI
                      onToggle={this.toggleAddMore}
                      currencyCodes={this.state.currencyCodes}
                      startupCurrencySymbol={this.state.startup?.currency?.symbol}
                      startup_id={this.state.startup?.startup_id}
                    />
                  )}

                  {this.state.startup?.kpis &&
                    Object.keys(this.state.startup?.kpis).map((k, i) => (
                      <Row key={i}>
                        <Col xl="12">
                          <div className="table-responsive mb-4">
                            <div>
                              <h4 className="text-capitalize">
                                {!["additional_kpi", "business_kpi"].includes(
                                  k
                                ) && <span>{k.replace("_kpi", " KPIs")}</span>}
                                {"additional_kpi" === k && (
                                  <span>Business KPIs set by Founder</span>
                                )}
                                {"business_kpi" === k && (
                                  <span>Business KPIs set by Admin</span>
                                )}
                              </h4>
                            </div>
                            {/* KPI details table */}
                            {canRenderKPI(k) &&
                              render_kpis(
                                this.state.startup?.kpis || {},
                                k,
                                this.state.edit
                              )}
                            {!canRenderKPI(k) && (
                              <p className="text-center m-5">
                                No KPIs Info available!
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    ))}
                </>
              )}
            </React.Fragment>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

KPIListingModal.propTypes = {
  startup: PropTypes.any,
  startup_kpis: PropTypes.any,
  kpis_to_update: PropTypes.any,
  addMore: PropTypes.bool,
  anyKPIAddEdit: PropTypes.bool,
  onAlertClose: PropTypes.func,
  onKPIUpdateDone: PropTypes.func,
  onKPIEdit: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.any,
  //onRemoveKPI: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
};

const mapStateToProps = ({ kpi }) => ({
  error: kpi.error,
  success: kpi.success,
  addMore: kpi.addMore,
  startup: kpi.startup,
});

const mapDispatchToProps = dispatch => ({
  onAlertClose: () => dispatch(closeAlertBox()),
  //onRemoveKPI: (kpi) => dispatch(removeKPI(kpi)),
  onKPIEdit: kpis => dispatch(updateKPIs(kpis)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIListingModal);
