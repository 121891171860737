export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_ALL_SETTINGS_SUCCESS = "GET_ALL_SETTINGS_SUCCESS";
export const GET_ALL_SETTINGS_ERROR = "GET_ALL_SETTINGS_ERROR";

export const GET_ONE_SETTING = "GET_ONE_SETTING";
export const GET_ONE_SETTING_SUCCESS = "GET_ONE_SETTING_SUCCESS";
export const GET_ONE_SETTING_ERROR = "GET_ONE_SETTING_ERROR";

export const CREATE_NEW_SETTING = "CREATE_NEW_SETTING";
export const CREATE_NEW_SETTING_SUCCESS = "CREATE_NEW_SETTING_SUCCESS";
export const CREATE_NEW_SETTING_ERROR = "CREATE_NEW_SETTING_ERROR";

export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_ERROR = "UPDATE_SETTING_ERROR";

export const DELETE_SETTING = "DELETE_SETTING";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
export const DELETE_SETTING_ERROR = "DELETE_SETTING_ERROR";
