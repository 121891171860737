import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import * as moment from "moment";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { isEmpty, size } from "lodash";
import { addNewSubscription, getSubscriptions } from "store/actions";

class SubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      subscription: [],
      modal: false,
      subscriptionListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, subscription) => <>{subscription.id}</>,
        },

        {
          text: "Name",
          dataField: "plan_name",
          sort: true,
          formatter: (cellContent, subscription) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {subscription.plan_name}
                </Link>
              </h5>
            </>
          ),
          filter: textFilter(),
        },
        {
          dataField: "plan_description",
          text: "Description",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "monthly_plan_amount",
          text: "Monthly plan amount",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "yearly_plan_amount",
          text: "Yearly plan amount",
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: "plan_duration",
          text: "Duration",
          sort: true,
          filter: textFilter(),
        },

        {
          dataField: "createdAt",
          text: "Created On",
          sort: true,
          formatter: (cellContent, plan_name) => (
            <>{moment(new Date(plan_name.createdAt)).format("DD MMM Y")}</>
          ),
        },
      ],
      error: {},
    };
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );
    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleSubacriptionClicks = this.handleSubacriptionClicks.bind(this);
  }

  componentDidMount() {
    const { subscription, onGetSubscription } = this.props;
    onGetSubscription();
    this.setState({ subscription: subscription });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleSubacriptionClicks = arg => {
    this.setState({ subscription: "", isEdit: false });
    this.toggle();
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { subscription } = this.props;
    if (
      !isEmpty(subscription) &&
      size(prevProps.subscription) !== size(subscription)
    ) {
      this.setState({ subscription: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /**
   * Handling submit subscription form
   */
  handleValidSubmit = (e, values) => {
    const { onAddNewSubscription, subscription } = this.props;

    this.setState({ error: {} });

    if (values["plan_name"]) {
      subscription.map(e => {
        console.log("check===", e.plan_name === values["plan_name"]);
        if (e.plan_name === values["plan_name"]) {
          this.setState({
            error: {
              plan_name: "This plan name is already taken..Please add new name",
            },
          });
        }
      });
    }

    if (this.state?.error?.plan_name) {
      return false;
    }

    console.log("check 222===");
    const newSubscription = {
      plan_name: values["plan_name"],
      plan_description: values["plan_description"],
      // monthly_plan_amount: values["monthly_plan_amount"],
      // yearly_plan_amount: values["yearly_plan_amount"],
      plan_duration: values["plan_duration"],
      plan_user_type: values["plan_user_type"],
      plan_type: values["plan_type"],
      plan_id: values["plan_id"],
      plan_amount: values["plan_amount"],
    };
    // save new subscription
    onAddNewSubscription(newSubscription);

    this.toggle();
  };

  /* Insert,Update Delete data */
  render() {
    const { subscription, error } = this.props;

    const defaultSorted = [
      {
        dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Subscription List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs breadcrumbItem="Subscription List" /> */}
            <Row className="mb-3">
              <Col sm="6">
                <h5 className="text-uppercase">Subscription List</h5>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(this.options)}
                      keyField="_id"
                      columns={this.state.subscriptionListColumns}
                      data={subscription || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={this.state.subscriptionListColumns}
                          data={subscription || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleSubacriptionClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Subscription
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  {!isEmpty(error) ? (
                                    <p className="text-danger">
                                      Some error occured!
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={"table align-middle table-hover"}
                                      bordered={false}
                                      striped={true}
                                      responsive
                                      ref={this.node}
                                      filter={filterFactory()}
                                    />

                                    <Modal
                                      size="lg"
                                      isOpen={this.state.modal}
                                      className={this.props.className}
                                      toggle={this.toggle}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {"Add Subscription"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <AvForm
                                          onValidSubmit={this.handleValidSubmit}
                                        // model={defaultValues}
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_name"
                                                  label="Plan Name"
                                                  type="text"
                                                  errorMessage="Invalid plan name"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_name || ""
                                                  }
                                                />
                                              </div>
                                              <p className="text-danger">
                                                {this.state.error?.plan_name ||
                                                  ""}
                                              </p>
                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_description"
                                                  label="Plan Description"
                                                  type="text"
                                                  errorMessage="Invalid plan description"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_description || ""
                                                  }
                                                />
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_amount"
                                                  label="Plan Amount"
                                                  type="number"
                                                  errorMessage="Invalid Plan Amount"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_amount || ""
                                                  }
                                                />
                                              </div>

                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_type"
                                                  label="Plan Type"
                                                  type="text"
                                                  errorMessage="Invalid Plan Type"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_type || ""
                                                  }
                                                />
                                              </div>

                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_id"
                                                  label="Plan Id"
                                                  type="text"
                                                  errorMessage="Invalid Plan Id"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_id || ""
                                                  }
                                                />
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_duration"
                                                  label="Plan Duration"
                                                  type="number"
                                                  errorMessage="Invalid Plan Duration"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_duration || ""
                                                  }
                                                />
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="plan_user_type"
                                                  label="Plan User Type"
                                                  type="text"
                                                  errorMessage="Invalid Plan User Type"
                                                  value={
                                                    this.state.subscription
                                                      ?.plan_user_type || ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </AvForm>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

SubscriptionList.propTypes = {
  subscription: PropTypes.array,
  className: PropTypes.any,
  onGetSubscription: PropTypes.func,
  onAddNewSubscription: PropTypes.func,
  error: PropTypes.string,
};

const mapStateToProps = ({ subscription }) => ({
  subscription: subscription.subscription.plans,
  error: subscription.error,
});

const mapDispatchToProps = dispatch => ({
  onGetSubscription: () => dispatch(getSubscriptions()),
  onAddNewSubscription: subscription =>
    dispatch(addNewSubscription(subscription)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionList));
