import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getStartupDeals } from "../../store/reports/actions";
import { profilePicture } from "helpers/storage_helper";
import { amountNumberToTextFrmt } from "helpers/utils";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import axios from "axios";

function getMonthName(month) {
  let months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  return months[parseInt(month)];
}

function customize_date(date) {
  let dateString = date.split("T");
  let month = dateString[0].split("-")[1];
  let year = dateString[0].split("-")[0];

  let yearLastTwoDigits = year.slice(-2);
  month = getMonthName(month);

  return `${month.slice(0, 3)}'${yearLastTwoDigits}`;
}

class InvestmentReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleWiseAccess: {},
      startups: [],
      error: "",
      startup: null,
      show: false,
      success: "",
      valuation: [],
      wfc_ask: [],
      fund_ask: [],
    };
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );

    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };
  }

  componentDidMount() {
    const { startups, onGetStartupDeals } = this.props;
    onGetStartupDeals();
    this.setState({ startups });

    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        this.setState({ roleWiseAccess: res.data.roleWiseAccess });
      }).catch(error => {
        console.log("route-error", error?.message);
      });
  }

  render() {
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.startups || [], // replace later with size(users),
      custom: true,
    };

    const columns = [
      {
        text: "id",
        dataField: "data.startup._id",
        sort: true,
        hidden: true,
        formatter: (cellContent, data) => {
          let { _id } = data.startup;
          return _id;
        },
      },
      {
        text: "Startup Logo",
        dataField: "startup.basic_information.startup_logo",
        sort: false,
        formatter: (cellContent, data) => {
          let { basic_information } = data?.startup;
          return (
            <>
              {basic_information?.startup_logo && (
                <img
                  src={profilePicture(basic_information?.startup_logo)}
                  alt="profile"
                  className="rounded"
                  width="50"
                  height="50"
                />
              )}
            </>
          );
        },
      },
      {
        dataField: "startup.basic_information.startup_name",
        text: "Startup Name",
        filter: textFilter(),
        sort: true,
        formatter: (cellContent, data) => {
          let { basic_information } = data.startup;
          return basic_information.startup_name;
        },
      },
      {
        dataField: "startup.basic_information.registered_company_name",
        text: "Registered Name",
        filter: textFilter(),
        sort: true,
        formatter: (cellContent, data) => {
          let { basic_information } = data.startup;
          return basic_information.registered_company_name;
        },
      },
      {
        dataField: "applyforfundings.funding_information.funding_round",
        text: "Startup Rounds",
        sort: true,
        filter: textFilter(),
        formatter: (cellContent, data) => {
          let { funding_information } = data.applyforfundings;
          return (
            funding_information.funding_round +
            ` (${customize_date(funding_information?.deal_date)})`
          );
        },
      },
      // {
      //   dataField: "applyforfundings.current_status",
      //   text: "Status",
      //   filter: textFilter(),
      //   formatter: (cellContent, data) => {
      //     let { current_status } = data.applyforfundings;
      //     return current_status;
      //   },
      // },
      {
        dataField: "valuation",
        text: "Valuation/ Valuation Floor-Cap",
        sort: false,
        formatter: (cellContent, data) => {
          let item = data.applyforfundings;
          let valuation_floor = 0,
            valuation_cap = 0,
            total_valuation = 0;
          if (
            typeof item?.funding_information?.valuation_floor !== "undefined" &&
            item?.funding_information?.valuation_floor
          ) {
            valuation_floor += Number(
              item?.funding_information?.valuation_floor
            );
          }

          if (
            typeof item?.funding_information?.valuation_cap !== "undefined" &&
            item?.funding_information?.valuation_cap
          ) {
            valuation_cap += Number(item?.funding_information?.valuation_cap);
          }

          if (
            typeof item?.funding_information?.current_valuation !==
            "undefined" &&
            item?.funding_information?.current_valuation
          ) {
            total_valuation += Number(
              item?.funding_information?.current_valuation
            );
          }

          if (valuation_cap > 0) {
            valuation_floor = amountNumberToTextFrmt(valuation_floor);
            valuation_cap = amountNumberToTextFrmt(valuation_cap);
            let capStr = valuation_floor + "-" + valuation_cap;
            return capStr;
          }

          total_valuation = amountNumberToTextFrmt(total_valuation);
          return total_valuation || "--";
        },
      },
      {
        dataField: "funding",
        text: "WFC Ask-Target (Total)",
        sort: false,
        formatter: (cellContent, data) => {
          let item = data.applyforfundings;
          let total_fund_ask = 0;

          if (
            typeof item?.funding_information?.how_much_fund_raising !==
            "undefined" &&
            item?.funding_information?.how_much_fund_raising
          ) {
            total_fund_ask += Number(
              item?.funding_information?.how_much_fund_raising
            );
          }

          total_fund_ask = amountNumberToTextFrmt(total_fund_ask);
          return `${total_fund_ask}` || "--";
        },
        // filterValue: (cell, row) => {
        //   return row.user?.email;
        // },
        // filter: textFilter(),
      },
      {
        dataField: "ask-target",
        text: "Fund Ask-Target(Total)",
        sort: false,
        formatter: (cellContent, data) => {
          let item = data.applyforfundings;
          let total_wfc_ask = 0;

          if (
            typeof item?.funding_information?.fund_ask_target !== "undefined" &&
            item?.funding_information?.fund_ask_target
          ) {
            total_wfc_ask += Number(item?.funding_information?.fund_ask_target);
          }

          total_wfc_ask = amountNumberToTextFrmt(total_wfc_ask);
          return `${total_wfc_ask}` || "--";
        },
      },
      {
        dataField: "investment",
        text: "Investment",
        sort: false,
        formatter: (cellContent, data) => {
          let { internal_funded_investors } = data;
          let total_investment = 0;
          if (internal_funded_investors.length > 0) {
            internal_funded_investors.forEach(item => {
              total_investment += item?.total_invested;
            });
          }

          return amountNumberToTextFrmt(total_investment) || "--";
        },
      },
      this.state.roleWiseAccess?.investmentReportsViewEye
        ? {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "View Investments",
          formatter: (cellContent, data) => {
            let { internal_funded_investors } = data;
            let total_investment = 0;
            if (internal_funded_investors.length > 0) {
              internal_funded_investors.forEach(item => {
                total_investment += item?.total_invested;
              });
            }
            total_investment =
              amountNumberToTextFrmt(total_investment) || "--";

            return (
              <div className="d-flex gap-3">
                <Link
                  className="text-success"
                  to={`startup/investment-details/${data.startup._id}?funding_round_id=${data?.applyforfundings?._id}&total_investment=${total_investment}`}
                >
                  <i
                    className="mdi mdi-eye font-size-18"
                    title="Click to View investment reports"
                    id="edittooltip"
                  ></i>
                </Link>
              </div>
            );
          },
        }
        : "",
    ];
    console.log(this.props)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Investment List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/* <Breadcrumbs breadcrumbItem="Investments" /> */}
            <Row className="">
              <Col sm="6">
                <h5 className="text-uppercase">Investment Startup Wise</h5>
              </Col>
            </Row>
            <React.Fragment>
              <Row>
                <Col lg="12">
                  <PaginationProvider
                    pagination={paginationFactory(this.options)}
                    keyField="_id"
                    columns={columns}
                    data={this.props.startups}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={this.props.startups}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            {...paginationTableProps}
                            keyField="_id"
                            data={this.props.startups}
                            columns={columns}
                            bordered={false}
                            striped={true}
                            responsive
                            filter={filterFactory()}
                          />
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
            </React.Fragment>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

InvestmentReports.propTypes = {
  startups: PropTypes.array,
  onGetStartups: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ reports }) => ({
  startups: reports.startupDeals,
  error: reports.startupDealsError,
  success: reports.startupDealsSuccess,
});

const mapDispatchToProps = dispatch => ({
  onGetStartupDeals: () => dispatch(getStartupDeals()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvestmentReports));
