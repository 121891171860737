import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";

//import action
import { getChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import DashboardGraphs from "./DashboardGraphs";
import CardsData from "components/DashboardGraphs/CardsData";

let show_all_saas_data = JSON.parse(
  localStorage.getItem("roleWiseAccess")
)?.show_all_saas_data;
let authUser = JSON.parse(localStorage.getItem("authUser"));
let saas_parent_id = authUser?.userInfo?.saas_parent_id;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly",
      onboarding_email_sent: 0,
      onboarding_info_clear: 0,
      onboarding_info_received: 0,

      onboarding_rejected: 0,
      onboarding_incomplete: 0,
      ca_shared: 0,
      onboarding_drop_count: 0,
      ca_drop_count: 0,
      ca_signed_by_investor_pending_by_trustee: 0,
      ca_signed_by_all_process_completed: 0,
      saas_name: "Invstt",
      fundTypeList: [],
      fundOptionsList: [],
      selectedFundType: {},
      is_super_admin: JSON.parse(localStorage.getItem("authUser"))?.userInfo
        ?.is_super_admin,
      access: JSON.parse(localStorage.getItem("roleWiseAccess")),
    };

    this.togglemodal.bind(this);
    this.togglesubscribemodal.bind(this);
  }

  showAllDataSetting() {
    const roleId = JSON.parse(localStorage.getItem("authUser"))?.userInfo
      ?.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        localStorage.setItem(
          "roleWiseAccess",
          JSON.stringify(res.data.roleWiseAccess)
        );
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        this.setState({
          saas_name: authUser?.userInfo?.saas_name,
          access: res.data.roleWiseAccess,
          is_super_admin: authUser?.userInfo?.is_super_admin,
        });
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
  }

  async getAllInvestmentVehicles() {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/applyforfunding/getFundDetails`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser"))
                ?.token,
            },
          }
        )
        .then(res => {
          let data = res?.data?.data;
          let newdata = data.filter(row => {
            if (
              row?._id?.toString() == "64870dba37cf22f296df37fb" ||
              row?._id?.toString() == "64870da237cf22f296df37f9"
            ) {
              return true;
            }
          });
          this.setState({
            fundTypeList: newdata,
            fundOptionsList: data,
          });

          localStorage.setItem("allFundDetails", JSON.stringify(data));

          //For defualt selected fund type
          let selectedFundType = data?.find(
            row => row?._id?.toString() == "64870dba37cf22f296df37fb"
          );
          this.setState({ selectedFundType: selectedFundType });
        });
    } catch (error) {}
  }

  async handlefundType(e) {
    this.setState({ selectedFundType: e });
    this.kycrelation(e?._id);
  }
  async kycrelation(fundtypeid = "") {
    try {
      let show_all_saas_data = JSON.parse(
        localStorage.getItem("roleWiseAccess")
      )?.show_all_saas_data;
      let authUser = JSON.parse(localStorage.getItem("authUser"));
      let saas_parent_id = authUser?.userInfo?.saas_parent_id;
      if (saas_parent_id === undefined) {
        authUser = JSON.parse(localStorage.getItem("authUser"));
        saas_parent_id = authUser?.userInfo?.saas_parent_id;
      }
      //For defualt fund type data
      let fund_type_id = "64870dba37cf22f296df37fb";
      if (fundtypeid) {
        fund_type_id = fundtypeid;
      }
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/admin/investor/get/kycrelation/v2/?saas_parent_id=${saas_parent_id}&is_super_admin=${this.state.is_super_admin}&show_all_saas_data=${show_all_saas_data}&fund_type_id=${fund_type_id}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser"))
                ?.token,
            },
          }
        )
        .then(res => {
          let data = res?.data?.data;
          let authUser = JSON.parse(localStorage.getItem("authUser"));
          this.setState({
            onboarding_email_sent: data?.onboarding_email_sent,
            onboarding_info_received: data?.onboarding_info_received,
            onboarding_info_clear: data?.onboarding_info_clear,
            saas_name: authUser?.userInfo?.saas_name,
            onboarding_rejected: data?.onboarding_rejected,
            onboarding_incomplete: data?.onboarding_incomplete,
            ca_shared: data?.ca_shared,
            ca_not_shared: data?.ca_not_shared,
            ca_signed_by_investor_pending_by_trustee:
              data?.ca_signed_by_investor_pending_by_trustee,
            ca_signed_by_all_process_completed:
              data?.ca_signed_by_all_process_completed,
            onboarding_drop_count: data?.onboarding_drop_count,
            ca_drop_count: data?.ca_drop_count,
          });
        });
    } catch (error) {}
  }

  storeCurrencyRates() {
    let currencyRates = localStorage.getItem("mainVars");
    if (!currencyRates) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/admin/investor/latestCurrencyRate`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser"))
                ?.token,
            },
          }
        )
        .then(res => {
          let data = res?.data?.data;
          if (JSON.stringify(data?.currencyValues)) {
            localStorage.setItem(
              "mainVars",
              JSON.stringify(data?.currencyValues)
            );
          }
        })
        .catch(err => {});
    }
  }

  storeAllCurrencyRates() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/get-all-currencies`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser"))?.token,
          },
        }
      )
      .then(res => {
        let data = res?.data?.allCurrencyRates;

        let allCurrencyRates = data?.reduce(
          (prev, curr) => ({
            ...(prev || {}),
            [curr?.currencyDate || "DEFAULT_DATA"]: {
              ...(prev[curr?.currencyDate || "DEFAULT_DATA"] || {}),
              ...curr?.currencyValues,
            },
          }),
          {}
        );

        if (JSON.stringify(allCurrencyRates)) {
          localStorage.setItem(
            "all-currency-rates",
            JSON.stringify(allCurrencyRates)
          );
        }
      })
      .catch(err => {});
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    this.showAllDataSetting();
    this.getAllInvestmentVehicles();
    this.kycrelation();
    this.storeCurrencyRates();
    this.storeAllCurrencyRates();
    // setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    onGetChartsData("yearly");
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content no-bg">
          <MetaTags>
            <title>Dashboard | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <h3 className="mt-4 mb-4">Welcome to Invstt Admin Panel !</h3>
            <CardsData />
            <DashboardGraphs fundOptionsList={this.state.fundOptionsList} />
            <hr className="mt-5 mb-5" />
            <Row>
              <Col md="6">
                <h4 className="mb-4">
                  <strong>
                    {this.state.is_super_admin ? (
                      <>Invstt </>
                    ) : (
                      <>{this.state.saas_name} </>
                    )}
                    - Onboarding Status
                  </strong>
                </h4>
              </Col>
              <Col md="6">
                <div className="select-fund-col d-flex align-items-center">
                  <div className="text-start me-3">
                    <Label className="m-0">Select Fund Type</Label>
                  </div>
                  <div className="fund-type-drop align-items-center">
                    <Select
                      placeholder={"Select Fund Type"}
                      options={this.state.fundTypeList}
                      onChange={e => this.handlefundType(e)}
                      value={
                        this.state.selectedFundType?._id
                          ? this.state.selectedFundType
                          : null
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {this.state.access?.kyc_list && (
              <div className="dasboard-sec">
                <Row>
                  <Col md="6">
                    <div className="column-sec">
                      <h2 className="mb-3 mt-2 title-sm">
                        KYC (Know your customer)
                      </h2>
                      <Row>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=isoi&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color1">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.onboarding_email_sent
                                )}
                                <i className="icon-col">
                                  <img src="../img/intent-shared.svg" alt="" />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                Intent Shared, KYC Initiated
                              </span>
                              <span className="dash-subhead text-secondary">
                                (Owner : Business)
                              </span>
                            </div>
                          </Link>
                        </Col>

                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ocap&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color2">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.onboarding_info_received
                                )}
                                <i className="icon-col">
                                  <img src="../img/kyc-pending.svg" alt="" />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                KYC Complete, Approval Pending
                                {/* Onboarding Info Received */}
                                <span className="dash-subhead text-secondary">
                                  (Owner : Fund)
                                </span>
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=oreject&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color3">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.onboarding_rejected
                                )}
                                <i className="icon-col">
                                  <img
                                    src="../img/kyc-disapproved.svg"
                                    alt=""
                                  />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                KYC Disapproved
                              </span>
                              <span className="dash-subhead text-secondary">
                                (Owner : Business)
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=oa&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color4">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.onboarding_info_clear
                                )}
                                <i className="icon-col">
                                  <img src="../img/kyc-approved.svg" alt="" />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                KYC Approved
                                {/* Onboarding Info Clear */}
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ocancelled&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color5">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.onboarding_drop_count
                                )}
                                <i className="icon-col">
                                  <img src="../img/kyc-process.svg" alt="" />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                KYC Process Dropped
                              </span>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="column-sec">
                      <h2 className="mb-3 mt-2 title-sm">
                        Contribution Agreement
                      </h2>
                      <Row>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ca_not_shared&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color1">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.ca_not_shared
                                )}
                                <i className="icon-col">
                                  <img
                                    src="../img/aggrement-not-share.svg"
                                    alt=""
                                  />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                Contribution Agreement Not Shared
                              </span>
                              <span className="dash-subhead text-secondary">
                                (Owner : Fund)
                              </span>
                            </div>
                          </Link>
                        </Col>

                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ca_shared&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color2">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.ca_shared
                                )}
                                <i className="icon-col">
                                  <img
                                    src="../img/aggrement-shared.svg"
                                    alt=""
                                  />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                Contribution Agreement Shared
                              </span>
                              <span className="dash-subhead text-secondary">
                                (Owner : Business)
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ca_sbi_pbt&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color3">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state
                                    .ca_signed_by_investor_pending_by_trustee
                                )}
                                <i className="icon-col">
                                  <img
                                    src="../img/aggrement-signed-pending.svg"
                                    alt=""
                                  />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                Contribution Agreement Signed by Investor,
                                Pending by Trustee
                              </span>
                              <span className="dash-subhead text-secondary">
                                (Owner : Fund)
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ca_sba_pc&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color4">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.ca_signed_by_all_process_completed
                                )}
                                <i className="icon-col">
                                  <img
                                    src="../img/aggrement-signed-complete.svg"
                                    alt=""
                                  />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                Contribution Agreement Signed by All - Process
                                Completed
                              </span>
                            </div>
                          </Link>
                        </Col>

                        <Col md="4">
                          <Link
                            to={`/onboarding-list?fmode=ca_cancelled&fund_type_id=${this.state.selectedFundType?._id}`}
                          >
                            <div className="dash-column color5">
                              <span className="dash-head">
                                {new Intl.NumberFormat("en").format(
                                  this.state.ca_drop_count
                                )}
                                <i className="icon-col">
                                  <img
                                    src="../img/aggrement-dropped.svg"
                                    alt=""
                                  />
                                </i>
                              </span>
                              <span className="dash-subhead">
                                Contribution Agreement Dropped
                              </span>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <Row>
              <Col xl="4">
                {/* <WelcomeComp /> */}
                {/* <MonthlyEarning /> */}
              </Col>
              <Col xl="4">{/* <ActivityComp /> */}</Col>
              <Col xl="4">{/* <TopCities /> */}</Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
});

const mapDispatchToProps = dispatch => ({
  onGetChartsData: periodType => dispatch(getChartsData(periodType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard));
