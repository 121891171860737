import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
//Import Breadcrumb
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { isEmpty } from "lodash";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
function ReportPosts(props) {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
  };
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [posts, setPosts] = useState([]);
  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;
  const show_all_saas_data = JSON.parse(
    localStorage.getItem("roleWiseAccess")
  )?.show_all_saas_data;

  async function fetchData(
    query = `?saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`
  ) {
    await axios
      .get(process.env.REACT_APP_API_URL + `/api/post/general/all${query}`, {
        headers: { Authorization: `${token}` },
      })
      .then(
        result => {
          setIsLoaded(true);
          setPosts(result.data.posts);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }
  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const deletePost = async post => {
    await Swal.fire({
      title: "Do you want to delete this Post ?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (post !== undefined) {
          await axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_API_URL}/api/post/general/${post.post_id._id}/reports/${post._id}`,
            headers: {
              headers: { Authorization: `${token}` },
            },
          })
            .then(res => {
              fetchData();
            })
            .catch(err => {
              setError(err);
            });
        }
      }
    });
  };
  const handleRangeFilter = (start, end) => {
    const filteredResult = posts.filter(item => {
      const createdAt = moment(new Date(item.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return item;
      } else {
        return;
      }
    });
    setPosts(filteredResult);
  };
  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "reason",
      text: "Reason",
      filter: textFilter(),
    },
    {
      dataField: "post_id.creadted_by_user_name",
      text: "User Name",
      filter: textFilter(),
    },
    {
      dataField: "saas_detail.saas_name",
      text: "Domain Expert name",
      filter: textFilter(),
    },
    {
      dataField: "post_id.description",
      text: "Description",
      filter: textFilter(),
      formatter: (cellContent) => (
        <div
          className="react-quill-preview"
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: (cellContent?.length > 50 ? cellContent?.slice(0, 50) + "..." : cellContent),
          }}
        ></div>
      ),
    },
    {
      dataField: "createdAt",
      text: "Reporting Date",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "post_id.createdAt",
      text: "Posting Date",
      sort: true,
      formatter: (cellContent, post) => (
        <>
          {post.post_id != null
            ? moment(new Date(post.post_id.createdAt)).format("DD MMM Y")
            : ""}
        </>
      ),
    },
    // {
    //   dataField: "menu",
    //   isDummyField: true,
    //   editable: false,
    //   text: "Action",
    //   formatter: (cellContent, post) => (
    //     <div className="d-flex gap-3">
    //       {/* <Link className="text-danger" to="#">
    //         <i
    //           className="mdi mdi-delete font-size-18"
    //           id="deletetooltip"
    //           onClick={() => deletePost(post)}
    //         ></i>
    //       </Link> */}
    //     </div>
    //   ),
    // },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reported Posts List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          <Row className="">
            <Col sm="6">
              {/* <h5 className="text-uppercase">Reported Posts List</h5> */}
              <h5 className="text-uppercase">Report Posts</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <DateRangePicker
                              initialSettings={{
                                startDate: "12/20/2021",
                                endDate: "12/30/2021",
                              }}
                              onApply={(event, picker) =>
                                handleRangeFilter(
                                  picker.startDate._d,
                                  picker.endDate._d
                                )
                              }
                              onCancel={() => fetchData()}
                            >
                              <button className="btn btn-primary">
                                Select Range
                              </button>
                            </DateRangePicker>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={posts || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={posts || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={posts}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) ? (
                          <p className="text-danger">Some error occured!</p>
                        ) : null}
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
ReportPosts.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default ReportPosts;
