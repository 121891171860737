import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, Alert, Button, Spinner } from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { getStartups } from "../../store/startups/actions";
import StartupUrlEditModal from "../Founders/startup-url-edit-modal";
import { profilePicture } from "../../helpers/storage_helper";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import axios from "axios";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import DataFetchLoader from "components/Common/DataFetchLoader";
import { getAllUsersForReferenceEvenDisabled } from "helpers/backend_helper";
import { isEmpty } from "lodash";

function formatUrl(url) {
  return url?.split(" ").join("-");
}

const selectStartupStatus = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "approve",
    label: "Approved",
  },  
  {
    value: "rejected",
    label: "Rejected",
  },    
];

class StartupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportLoading: false,
      startups: [],
      error: "",
      startup: null,
      show: false,
      success: "",
      roleWiseAccess: {},
      loading: false,
      saasList: [],
    };
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );
    this.options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
    };
  }

  componentDidMount() {
    const { startups, onGetStartups } = this.props;
    onGetStartups();
    this.setState({ startups });

    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        this.setState({ roleWiseAccess: res.data.roleWiseAccess });
      }).catch(error => {
        console.log("route-error", error?.message);
      });

    try {
      getAllUsersForReferenceEvenDisabled()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let {
                _id,
                firstname,
                lastname,
                email,
                saas_fullname,
                saas_name,
                saas_alias,
              } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                let temp2 = saas_name;
                if (temp2) {
                  temp2 += ` (${saas_fullname})`;
                } else {
                  temp2 = saas_fullname || saas_alias;
                }
                temp = temp2 || temp;

                if (user?.status == "Declined") {
                  saasPartners.push({
                    value: saas_name,
                    label: `${temp}*`
                  });
                } else {
                  saasPartners.push({
                    value: saas_name,
                    label: temp,
                  });
                }
              }
              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          saasPartners = saasPartners.sort((a, b) => {
            if (a?.value?.toLowerCase() < b?.value?.toLowerCase()) {
              return -1;
            }
            if (a?.value?.toLowerCase() > b?.value?.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          this.setState({ saasList: saasPartners });
        })
        .catch(error => {
          this.setState({ saasList: [] });
        });
    }
    catch (err) { }
  }

  reloadStartups = () => {
    const { startups, onGetStartups } = this.props;
    onGetStartups();
    this.setState({ startups });
  };

  disableLoading = () => {
    // console.log("disabled")
    this.setState({ loading: false });
  };

  enableLoading = () => {
    // console.log("enabled")
    this.setState({ loading: true });
  };

  handleShow = () => {
    this.setState({ show: !this.state.show });
  };

  handleSelect = startup => {
    this.setState({ startup: startup });
    this.handleShow();
  };

  handleAlertClose = () => {
    const { onAlertClose } = this.props;
    onAlertClose();
  };

  exportCSV = async () => {
    this.setState({ exportLoading: true });
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const saas_parent_id = authUser?.userInfo?.saas_parent_id;
    const is_super_admin = authUser?.userInfo?.is_super_admin;
    const show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }
    let query = `?saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`;
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/export-startups${query}`,
        {},
        configHeaders,
        { responseType: "blob" }
      )
      .then(response => {
        this.setState({ exportLoading: false });
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data?.signedUrl;
          link.setAttribute("download", "startups.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          alert("CSV exported successfully.");
        }
      })
      .catch(error => {
        this.setState({ exportLoading: false });
      });
  };

  render() {
    const columns = [
      {
        text: "id",
        dataField: "_id",
        sort: true,
        hidden: true,
        formatter: (cellContent, startup) => <>{startup.id}</>,
      },
      {
        dataField: "basic_information.startup_logo",
        text: "Brand Logo",
        headerAlign: "center",
        formatter: (cellContent, startup) => (
          <div className="text-center">
            {startup.basic_information.startup_logo && (
              <img
                src={profilePicture(startup.basic_information.startup_logo)}
                className="img rounded"
                width="50px"
              />
            )}
          </div>
        ),
      },
      {
        dataField: "basic_information.startup_name",
        text: "Brand Name",
        sort: true,
        filter: textFilter(),
        formatter: (cellContent, startup) => (
          <>{startup?.basic_information?.startup_name || ""}</>
        ),
      },
      {
        dataField: "founderData.userName",
        text: "Founder Name",
        sort: true,
        filter: textFilter(),
        formatter: (cellContent, startup) => (
          <>
            {`${startup?.founderData?.firstname || ""} ${
              startup?.founderData?.lastname || ""
            }`.trim() ||
              startup?.founderData?.userName ||
              ""}
          </>
        ),
        filterValue: (cell, startup) => {
          let founderName = `${startup?.founderData?.firstname || ""} ${
            startup?.founderData?.lastname || ""
          }`.trim();

          if (founderName) {
            return founderName;
          }

          return startup?.founderData?.userName;
        },
      },
      {
        dataField: "saas_name",
        text: "Domain Expert Name",
        hidden: JSON.parse(localStorage.getItem("user_info"))?.is_saas_parent
          ? true
          : false,
        // filter: textFilter(),
        filter: selectFilter({
          options: this.state?.saasList,
          caseSensitive: false,
        }),
        formatter: (cellContent, startup) => {
          let saasName = "";
          saasName = startup?.saas_name || "";
          return `${saasName}`;
        },
      },
      {
        dataField: "basic_information.registered_company_name",
        text: "Registered Name",
        sort: true,
        filter: textFilter(),
        formatter: (cellContent, startup) => (
          <>{startup?.basic_information?.registered_company_name || ""}</>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        filter: selectFilter({
          options: selectStartupStatus?.sort((a, b) => {
            if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
              return -1;
            }
            if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
              return 1;
            }
            return 0;
          }),
          delay: 1500,
        }),
       
        formatter: (cellContent, startup) => {
          let status = startup?.status || "";
          if(startup?.status=="approve"){
            status = "Approved";
          }
          
          return (
          <span className="text-center text-capitalize">
            {status}
          </span>
        );
      }
      },
      {
        dataField: "basic_information.business_model_category",
        text: "Business Model Category",
        sort: true,
        formatter: (cellContent, startup) => {
          let business_model_category =
            startup?.basic_information?.business_model_category ===
              "undefined" ||
            isEmpty(startup?.basic_information?.business_model_category)
              ? ""
              : startup?.basic_information?.business_model_category;
          return <>{business_model_category}</>;
        },
        filter: textFilter(),
      },
      {
        dataField: "url_alias",
        text: "Startup Url",
        sort: true,
        formatter: (cellContent, startup) => (
          <a
            href={`https://www.invstt.com/company/${formatUrl(
              startup.url_alias
            )}`}
            target="_blank"
          >
            <span className="linebreak-all">
              https://www.invstt.com/company/{formatUrl(startup.url_alias)}
            </span>
          </a>
        ),
        filter: textFilter(),
      },

      {
        dataField: "menu",
        isDummyField: true,
        hidden: this.state.roleWiseAccess?.startupListEditAction ? false : true,
        editable: false,
        headerAlign: "center",
        text: <span className="display-linebreak">{"Edit Startup"}</span>,
        formatter: (cellContent, startup) => (
          <div className="d-flex gap-3 justify-content-center">
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                title="Edit Startup"
                id="edittooltip"
                onClick={() => this.handleSelect(startup)}
              ></i>
            </Link>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Startup List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            <DataFetchLoader loading={this.state.loading} />
            {/*<Breadcrumbs title="Startups" breadcrumbItem="Startup List" />*/}
            <Row className="d-flex align-items-center">
              <Col sm="6">
                <h5 className="text-uppercase mb-0">Startup List </h5>
              </Col>
              <DataFetchLoader
                loading={this.state.roleWiseAccess?.users_list ? false : true}
              />
              {this.state.roleWiseAccess?.exportStartupList ? (
                <Col sm="6" className="text-end">
                  {/* <span>Startups / Startup List</span> */}
                  <div className="mx-1">
                    {this.state.exportLoading ? (
                      <Button
                        className="btn btn-success"
                        onClick={() => this.exportCSV()}
                      >
                        <Spinner animation="border" size="sm" /> Export CSV
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-success"
                        onClick={() => this.exportCSV()}
                      >
                        <i className="bx bx-export fs-4"></i> Export CSV
                      </Button>
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col xl="12">
                {this.state.error ? (
                  <Alert color="danger" toggle={this.handleAlertClose}>
                    Some error occured!
                  </Alert>
                ) : null}
                {!!this.state.success ? (
                  <Alert color="success" toggle={this.handleAlertClose}>
                    {this.state.success}
                  </Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <React.Fragment>
                  <PaginationProvider
                    pagination={paginationFactory(this.options)}
                    keyField="_id"
                    columns={columns}
                    data={this.props.startups}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={this.props.startups}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <div className="startup-list-table">
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                              {...paginationTableProps}

                              keyField="_id"
                              data={this.props.startups}
                            columns={columns}
                            bordered={false}
                            striped={true}
                              responsive
                            filter={filterFactory()}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </React.Fragment>
              </Col>
            </Row>
          </Container>
          {this.state.show && this.state.startup && (
            <StartupUrlEditModal
              startup={this.state.startup}
              onClose={this.handleShow}
              reloadStartups={this.reloadStartups}
              enableLoading={this.enableLoading}
              disableLoading={this.disableLoading}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

StartupList.propTypes = {
  startups: PropTypes.array,
  onGetStartups: PropTypes.func,
  onAlertClose: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ kpi }) => ({
  startups: kpi.startups,
  error: kpi.error,
  success: kpi.success,
});

const mapDispatchToProps = dispatch => ({
  onGetStartups: () => dispatch(getStartups()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StartupList));
