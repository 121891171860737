import React, { Component, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import axios from "axios";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import DataFetchLoader from "components/Common/DataFetchLoader";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

const currencyConversion = () => {
  const [currencies, setCurrencies] = useState([]);
  const [allCurrencyRates, setAllCurrencyRates] = useState([]);
  const [state, setState] = useState({});
  const [currencyDate, setCurrencyDate] = useState("");
  const [rendering, setRendering] = useState("0");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal open state
  const [modalNew, setModalNew] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  useEffect(() => {
    fetchAllData();
    return () => {};
  }, [rendering]);

  const fetchAllData = () => {
    if (modalNew == false) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/get-all-currencies`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(res => {
          setLoading(true);
          let state = {};
          let currency = res.data.currencies;
          let currencyName = currency.map(name => {
            return name.value;
          });
          setCurrencies(currency);

          currencyName.map(outer => {
            currencyName.map(inner => {
              state = {
                ...state,
                [outer]: {
                  ...(state?.[outer] || {}),
                  [inner]: "",
                },
              };
              if (outer == inner) {
                state[outer][inner] = 1;
              }
              return 1;
            });
            return 1;
          });
          setState(state);
          setCurrencyDate(res?.data?.dateToday);

          if (res?.data?.allCurrencyRates?.length) {
            let defaultData = res?.data?.allCurrencyRates?.[0];
            setAllCurrencyRates([
              ...(res?.data?.allCurrencyRates || [])?.slice(1),
              defaultData,
            ]);
          }

          if (res?.data?.todayDataExists) {
            setState(res?.data?.todayDataExists?.currencyValues);
          }
        })
        .catch(err => {
          setLoading(true);
          console.warn(error);
        });
    }
  };

  const handleUpdateOnly = async datecurrency => {
    setCurrencyDate(datecurrency);
    let state = {};
    let currencyName = currencies.map(name => {
      return name.value;
    });
    currencyName.map(outer => {
      currencyName.map(inner => {
        state = {
          ...state,
          [outer]: {
            ...(state?.[outer] || {}),
            [inner]: "",
          },
        };
        if (outer == inner) {
          state[outer][inner] = 1;
        }
        return 1;
      });
      return 1;
    });

    setState(state);
    setErrors({});

    if (modal == false)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/existing-conversion/${datecurrency}`,
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          }
        )
        .then(res => {
          if (res?.data?.conversions !== null) {
            setState(res.data.conversions.currencyValues);
          }
        });
  };

  const handleChange = e => {
    let val = e.target.value;
    let [fromCurrency, toCurrency] = e.target.name.split("-");
    let fromCurrencyValue = val;
    let toCurrencyValue = !!val ? 1 / val : val;
    let finalState = {
      ...state,
      [fromCurrency]: {
        ...(state?.[fromCurrency] || {}),
        [toCurrency]: parseFloat(fromCurrencyValue),
      },
    };
    finalState = {
      ...finalState,
      [toCurrency]: {
        ...(state?.[toCurrency] || {}),
        [fromCurrency]: parseFloat(toCurrencyValue).toFixed(6),
      },
    };
    setState(finalState);
  };

  const handleDate = async e => {
    setCurrencyDate(e.target.value);
    let state = {};
    let currencyName = currencies.map(name => {
      return name.value;
    });
    currencyName.map(outer => {
      currencyName.map(inner => {
        state = {
          ...state,
          [outer]: {
            ...(state?.[outer] || {}),
            [inner]: "",
          },
        };
        if (outer == inner) {
          state[outer][inner] = 1;
        }
        return 1;
      });
      return 1;
    });

    setState(state);
    setErrors({});

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/existing-conversion/${e.target.value}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        if (res?.data?.conversions !== null) {
          setState(res.data.conversions.currencyValues);
        }
      });
  };

  const handleSubmit = async () => {
    let finalState = state;
    for (let { value: outer } of currencies) {
      // if (outer === "USD") {
      //   continue;
      // }
      for (let { value: inner } of currencies) {
        if (outer === "USD") {
          let existingValue = finalState?.[outer]?.[inner] || 0;
          let fixedValue = parseFloat(existingValue).toFixed(6);
          finalState = {
            ...(finalState || {}),
            [outer]: {
              ...(finalState?.[outer] || {}),
              [inner]: parseFloat(fixedValue),
            },
          };
          continue;
        }
        if (outer === inner) {
          finalState = {
            ...(finalState || {}),
            [outer]: {
              ...(finalState?.[outer] || {}),
              [inner]: 1,
            },
          };
          continue;
        }
        let val1 = finalState?.["USD"]?.[inner] || 0;
        let val2 = finalState?.[outer]?.["USD"] || 0;
        let finalVal = parseFloat((val1 * val2).toFixed(6));
        finalState = {
          ...(finalState || {}),
          [outer]: {
            ...(finalState?.[outer] || {}),
            [inner]: finalVal,
          },
        };
      }
    }

    let date = currencyDate;
    if (date == "" || date == null) {
      setErrors({ date: "please select date" });
      return 1;
    }
    let conversionsArr = Object.values(finalState);
    let conversionsInner = conversionsArr.map(e => {
      return Object.values(e);
    });
    let result = conversionsInner.map(e => {
      if (
        e.includes("") ||
        e.includes(null) ||
        e.includes("undefined") ||
        e.includes(NaN)
      )
        return true;
      else return false;
    });

    if (result.includes(true)) return alert("Please fill all the values");
    let data = { conversions: finalState, date }
    let encryptedQuery = {
      q: JSON.stringify(encryptDataToArray(data))
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/admin/multiCurrency/new-conversion`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        "Content-Type": "application/json",
      },
      data: encryptedQuery,
    };
    await axios(config).then(res => {
      if (res.status == 200) {
        alert("success");
        let latestCurrencyDate = "";
        if (allCurrencyRates?.length) {
          latestCurrencyDate = allCurrencyRates[0]?.currencyDate;
        }
        if (date === latestCurrencyDate)
          localStorage.setItem("mainVars", JSON.stringify(finalState));
        setModalNew(false);
        setModal(false);
        setRendering(!rendering);
      }
    });
  };

  // Toggle for Modal
  const toggle = date => {
    handleUpdateOnly(date);
    setModal(!modal);
  };
  const toggleNew = () => {
    fetchAllData();
    setModalNew(!modalNew);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Currency Conversion | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!loading} />
          <Row className="d-flex align-items-center">
            <Col sm="10">
              <h5 className="text-uppercase mb-0">Currency Conversion</h5>
            </Col>
            <Col sm="2">
              <Button color="primary" onClick={toggleNew}>
                Add New
              </Button>
            </Col>
          </Row>
          <div className="conversion-sec">
            <div className="table-responsive">
              <div className="react-bootstrap-table">
                <table className="table table align-middle table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Added By</th>
                      <th>Last Updated By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <DataFetchLoader
                      loading={allCurrencyRates ? false : true}
                    />
                    {allCurrencyRates?.map(rates => {
                      return (
                        <tr key={rates?._id}>
                          <td>
                            {rates?.currencyDate
                              ? rates?.currencyDate !== "DEFAULT_DATA"
                                ? moment(rates?.currencyDate).format(
                                    "DD MMM,YYYY"
                                  )
                                : "Default Conversion Rates"
                              : null}
                          </td>
                          <td>
                            {rates?.added_by
                              ? `${rates?.added_by?.firstname} ${rates?.added_by?.lastname}`
                              : "-"}
                          </td>
                          <td>
                            {rates?.last_updated_by
                              ? `${rates?.last_updated_by?.firstname} ${rates?.last_updated_by?.lastname}`
                              : "-"}
                          </td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => toggle(rates?.currencyDate)}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal size="lg" isOpen={modalNew} toggle={toggleNew}>
              <ModalHeader tag="h4" toggle={toggleNew}>
                Add New Conversion Rates
              </ModalHeader>
              <ModalBody>
                <Row className="mb-3">
                  <Col md="2">
                    <span>Please select date:</span>
                  </Col>
                  <Col md="3">
                    <AvForm>
                      <AvField
                        id="event_datetime"
                        name="event_datetime"
                        type="date"
                        value={currencyDate}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={e => {
                          handleDate(e);
                        }}
                      />
                      {errors.date && (
                        <span className="text-danger">{errors?.date}</span>
                      )}
                    </AvForm>
                  </Col>
                </Row>
                <AvForm>
                  <div className="table-responsive">
                    <div className="react-bootstrap-table">
                      <table className="table table align-middle table-hover">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            {currencies?.map(e => {
                              return (
                                <th>
                                  {e.value} ({e.symbol})
                                  <span className="text-danger"> *</span>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {currencies?.map(c => {
                            return ( */}
                          <tr>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <label className="fw-bold">
                                  {/* {c.value} */}
                                  USD
                                </label>
                                {/* <span className="text-danger"> *</span> */}
                              </h5>
                            </td>
                            {currencies?.map(d => {
                              return (
                                <td>
                                  <Input
                                    name={`USD-${d.value}`}
                                    id={`USD-${d.value}`}
                                    type="number"
                                    defaultValue={d == "USD" ? "1" : null}
                                    value={
                                      state?.["USD"]?.[d.value] !== "undefined"
                                        ? state?.["USD"]?.[d.value]
                                        : 0
                                    }
                                    disabled={d.value === "USD"}
                                    onChange={e => {
                                      handleChange(e);
                                    }}
                                  />
                                </td>
                              );
                            })}
                          </tr>
                          {/* ); */}
                          {/*  })} */}
                        </tbody>
                      </table>
                      <div className="text-center">
                        <Button
                          color="primary"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </AvForm>
              </ModalBody>
            </Modal>

            <Modal size="lg" isOpen={modal} toggle={toggle}>
              <ModalHeader tag="h4" toggle={toggle}>
                Edit Conversion Rates
              </ModalHeader>
              <ModalBody>
                <AvForm>
                  <div className="table-responsive">
                    <div className="react-bootstrap-table">
                      <table className="table table align-middle table-hover">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            {currencies?.map(e => {
                              return (
                                <th>
                                  {e.value} ({e.symbol})
                                  <span className="text-danger"> *</span>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {currencies?.map(c => {
                            return ( */}
                          <tr>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <label className="fw-bold">{"USD"}</label>
                                {/* <span className="text-danger"> *</span> */}
                              </h5>
                            </td>
                            {currencies?.map(d => {
                              return (
                                <td>
                                  <Input
                                    name={`USD-${d.value}`}
                                    id={`USD-${d.value}`}
                                    type="number"
                                    defaultValue={d == "USD" ? "1" : null}
                                    value={
                                      state?.["USD"]?.[d.value] !== "undefined"
                                        ? state?.["USD"]?.[d.value]
                                        : 0
                                    }
                                    disabled={d.value === "USD"}
                                    onChange={e => {
                                      handleChange(e);
                                    }}
                                  />
                                </td>
                              );
                            })}
                          </tr>
                          {/* );
                          })} */}
                        </tbody>
                      </table>
                      <div className="text-center">
                        <Button
                          color="primary"
                          hidden={currencyDate == "DEFAULT_DATA" ? true : false}
                          disabled={
                            currencyDate == "DEFAULT_DATA" ? true : false
                          }
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </AvForm>
              </ModalBody>
            </Modal>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default currencyConversion;
