import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";

const PlatformEngagement = () => {
  const [platformData, setPlatformData] = useState({});

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard/platformEngagementData`,
        {
          params: {
            saas_parent_id: authUser?.userInfo?.saas_parent_id,
            show_all_saas_data,
            is_super_admin: authUser?.userInfo?.is_super_admin,
          },
          headers: {
            Authorization: authUser?.token,
          },
        }
      )
      .then(response => {
        const responseData = response?.data?.data;
        setPlatformData(responseData);
      })
      .catch(err => {});
    return () => {};
  }, []);
  return (
    <div className="column-sec">
      <Row className="mb-4 align-items-center">
        <Col md="12">
          <h3 className="mb-3 mt-3 title-sm">Platform Engagement</h3>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="engagement-col col1">
            <div className="engagement-title">
              <strong>
                {new Intl.NumberFormat("en").format(
                  platformData?.activeInvestors ?? 0
                )}
              </strong>{" "}
              Active Investors
            </div>
            <div className="sec-pic">
              <img src="../../../img/active-invstor.svg" alt="" />
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="engagement-col col2">
            <div className="engagement-title">
              <strong>
                {new Intl.NumberFormat("en").format(
                  platformData?.postByUsers ?? 0
                )}
              </strong>{" "}
              User Posts
            </div>
            <div className="sec-pic">
              <img src="../../../img/user-post.svg" alt="" />
            </div>
          </div>
        </Col>
        <Col md="12">
          <div
            className="engagement-col col3"
            style={
              platformData?.totalMinutes > 0
                ? {}
                : { justifyContent: "space-around" }
            }
          >
            <div className="engagement-col--column d-flex align-items-center">
              <div className="sec-pic">
                <img src="../../../img/event-done.svg" alt="" />
              </div>
              <div className="engagement-title">
                <strong>
                  {new Intl.NumberFormat("en").format(
                    platformData?.totalEvents ?? 0
                  )}
                </strong>{" "}
                Events Done
              </div>
            </div>
            <div className="engagement-col--column d-flex align-items-center">
              <div className="sec-pic">
                <img src="../../../img/count-attend.svg" alt="" />
              </div>
              <div className="engagement-title">
                <strong>
                  {new Intl.NumberFormat("en").format(
                    platformData?.totalAttendees ?? 0
                  )}
                </strong>{" "}
                Count of Attendees
              </div>
            </div>
            {platformData?.totalMinutes > 0 ? (
              <div className="engagement-col--column d-flex align-items-center">
                <div className="sec-pic">
                  <img src="../../../img/no-mint.svg" alt="" />
                </div>
                <div className="engagement-title">
                  <strong>
                    {new Intl.NumberFormat("en").format(
                      platformData?.totalMinutes ?? 0
                    )}
                  </strong>{" "}
                  No. of Minutes
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PlatformEngagement;
