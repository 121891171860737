import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button, Alert } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import DataFetchLoader from "components/Common/DataFetchLoader";

class dailyDeductCredits extends Component {

    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            isLoaded: false,
            status:false,
            err:false
        }
    }
    dailyDeductCredit = async () => {
        this.setState({isLoaded:true})
        const token = authHeader();
        await axios({
            url: `${process.env.REACT_APP_API_URL}/api/user/credit/daily-deduct-credits`,
            method: "GET",
            headers: {
                Authorization: `${token}`,
            },
        }).then(res => {
            console.log("---.split('Bearer ')[1]-----", res?.data?.status);
            
            if(res?.data?.status===true){
                this.setState({ status:true})
            }
           else if(res?.data?.status===false){
                this.setState({ status:false, err: res?.data?.message })
            }else{
                this.setState({ err: "Something went wrong" })
            }
            this.setState({isLoaded:false})
          
        }).catch(err => {
            this.setState({ err: "Something went wrong" })
            this.setState({isLoaded:false})
        });
    }

    render() {
        return (<React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Daily Deduct Credits| Invstt - Admin</title>
                </MetaTags>
                <Container fluid>
                <DataFetchLoader loading={this.state.isLoaded} />
                    <Row className="mb-3">
                        <Col sm="6">
                            <h5 className="text-uppercase">Daily Deduct Credits</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={() => this.dailyDeductCredit()}>
                                        Deduct Credits
                                    </Button>

                                    {this.state?.status ? (
                                        <Alert color="success" className="mt-4">Credits deduct successfully</Alert>
                                    ) : null}

                                    {(this.state?.err && !this.state?.status)? (
                                        <Alert color="danger" className="mt-4">{this.state?.err} </Alert>
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        )
    }
}

dailyDeductCredits.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

const mapStateToProps = ({ users }) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(dailyDeductCredits));