import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  CountryData,
  StateData,
  CityData,
} from "../../../components/MultipleComp";
import AsyncSelect from "react-select";
import Select from "react-select";
// import "../../post.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import DataFetchLoader from "../../../components/Common/DataFetchLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { isEmpty } from "lodash";
import { Form } from "react-bootstrap";

// Quill Editor for Event Description
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const capitalizeWord = location => {
  let loc = location?.toLowerCase() || "";
  return (
    loc
      ?.split(" ")
      ?.map(item => item.charAt(0)?.toUpperCase() + item?.substring(1))
      ?.join(" ") || ""
  );
};

function ApproveJobs() {
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: false,
  };
  const [error, setError] = useState({});
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [job, setJob] = useState({
    job_tags: [{ value: "data", label: "data" }],
  });
  const [access, setAccess] = useState({});

  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;
  const roleAccess = JSON.parse(localStorage.getItem("roleWiseAccess"));

  // const onSelectFile = e => {
  //   if (!e.target.files || e.target.files.length === 0) {
  //     setSelectedFile(undefined);
  //     return;
  //   }
  //   setSelectedFile(e.target.files[0]);
  //   console.log(e.target.files[0]);
  // };
  function fetchData() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/jobs/getall/admin`, {
        headers: { Authorization: `${token}` },
      })
      .then(response => {
        setIsLoaded(true);
        setJobs(response?.data?.jobs);
      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
      });
  }
  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  // useEffect(() => {
  // const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;
  // axios
  //   .get(
  //     `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
  //     {
  //       headers: {
  //         Authorization: JSON.parse(localStorage.getItem("authUser")).token,
  //       },
  //     }
  //   )
  //   .then(res => {
  //     setAccess(res.data.roleWiseAccess);
  //   })
  //   .catch(error => {
  //     console.log("route-error", error?.message);
  //   });
  // }, []);

  const toggleActiveStatus = job => {
    setModal(false);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/jobs/update/status/admin/${job._id}`,
        {
          active: !job.active,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };

  const toggleApprovalStatus = job => {
    setModal(false);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/jobs/update/approval/admin/${job._id}`,
        {
          approved: !job.approved,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then(res => {
        console.log(res);
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const handleJobUpdate = async () => {
    let bodyFormData = new FormData();
    let data = {
      title: job.title,
      description: job.description,
      country: job.country,
      states: job.states,
      city: job.city,
      job_tags: JSON.stringify(job.job_tags),
      type: job.type,
      num_of_openings: job.num_of_openings,
      max_year_experience: job.max_year_experience,
      pay_scale: job.pay_scale,
      nature_of_job: job.nature_of_job,
    }

    let encryptedQuery = encryptDataToArray(data)
    bodyFormData.append("q", JSON.stringify(encryptedQuery));

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/jobs/admin/update/${job._id}`,
        bodyFormData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(res => {
        setError({});
        setModal(false);
        fetchData();
      })
      .catch(err => {
        setError({ ...err.response.data.error });
      });
  };

  const handleJobDelete = async id => {
    await Swal.fire({
      title: "Do you want to delete this Job ?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async result => {
      if (result.isConfirmed) {
        if (id !== undefined) {
          await axios({
            url: process.env.REACT_APP_API_URL + `/api/jobs/delete/${id}`,
            method: "DELETE",
            headers: {
              Authorization: `${token}`,
            },
          })
            .then(res => {
              fetchData();
            })
            .catch(err => {
              setError(err);
            });
        }
      }
    });
  };

  const handleChange = event => {
    if (event.key === "Enter") {
      let obj = { label: event.target.value, value: event.target.value };
      setJob({
        ...job,
        job_tags: [...job?.job_tags, obj],
      });
    }
  };

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "title",
      text: "Title",
      filter: textFilter(),
    },
    {
      dataField: "user_name",
      text: "User Name",
      filter: textFilter(),
    },
    {
      dataField: "user_role",
      text: "User Role",
      filter: textFilter(),
    },
    {
      dataField: "job_for",
      text: "Job For",
      filter: textFilter(),
    },
    {
      dataField: "startup_info",
      text: "Startup Name",
      filter: textFilter(),
    },
    // {
    //   dataField: "description",
    //   text: "Description",
    //   filter: textFilter(),
    //   formatter: (cellContent, post) => (
    //     <div
    //       className="react-quill-preview"
    //       style={{ whiteSpace: "pre-line" }}
    //       dangerouslySetInnerHTML={{ __html: post.description }}
    //     ></div>
    //   ),
    // },
    // access?.adminPostStatus
    // true
    //   ? {
    //       dataField: "active",
    //       text: "Status",
    //       formatter: (cellContent, job) => (
    //         <>
    //           <p>
    //             <label
    //               className="toggleSwitch large"
    //               onClick={() => toggleActiveStatus(job)}
    //             >
    //               <input type="checkbox" defaultChecked={job.active} />
    //               <span>
    //                 <span></span>
    //                 <span></span>
    //               </span>
    //               <a></a>
    //             </label>
    //           </p>
    //         </>
    //       ),
    //     }
    //   : "",
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, job) => (
        <>{moment(new Date(job.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated On",
      sort: true,
      formatter: (cellContent, job) => (
        <>{moment(new Date(job.updatedAt)).format("DD MMM Y")}</>
      ),
    },
    // access?.adminPostEditAction
    roleAccess?.job_approval === true
      ? {
          dataField: "approved",
          text: "Approved",
          formatter: (cellContent, job) => (
            <>
              <p>
                <label
                  className="toggleSwitch large"
                  onClick={() => toggleApprovalStatus(job)}
                >
                  <input type="checkbox" defaultChecked={job.approved} />
                  <span>
                    <span></span>
                    <span></span>
                  </span>
                  <a></a>
                </label>
              </p>
            </>
          ),
        }
      : "",
    roleAccess?.job_edit === true
      ? {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, job) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    if (roleAccess?.job_edit === true) {
                      let job_tags = job?.job_tags?.map(item => ({
                        label: item,
                        value: item,
                      }));
                      setJob({ ...job, job_tags });
                      setModal(true);
                      setIsEdit(true);
                    } else {
                      setJob(job);
                      setModal(false);
                      setIsEdit(false);
                    }
                  }}
                ></i>
                {/* <i
                  style={{ marginLeft: "5px", color: "tomato" }}
                  className="mdi mdi-delete font-size-18"
                  id="edittooltip"
                  onClick={() => handleJobDelete(job?._id)}
                ></i> */}
              </Link>
            </div>
          ),
        }
      : "",
  ];

  const styles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#E27235" : "",
    }),
  };

  const job_type = [
    {
      label: "Permanent",
      value: "permanent",
    },
    {
      label: "Contractual",
      value: "contractual",
    },
  ];

  const job_nature = [
    {
      label: "On-Site",
      value: "On-Site",
    },
    {
      label: "Hybrid",
      value: "hybrid",
    },
    {
      label: "Remote",
      value: "remote",
    },
  ];

  const payScale = [
    {
      value: "Upto 3",
      label: "Upto 3 LPA",
    },
    {
      value: "3 - 6",
      label: "3 - 6 LPA",
    },
    {
      value: "6 - 9",
      label: "6 - 9 LPA",
    },
    {
      value: "9 - 12",
      label: "9 - 12 LPA",
    },
    {
      value: "12 - 15",
      label: "12 - 15 LPA",
    },
    {
      value: "15 - 20",
      label: "15 - 20 LPA",
    },
    {
      value: "20 - 25",
      label: "20 - 25 LPA",
    },
    {
      value: "25 - 50",
      label: "25 - 50 LPA",
    },
    {
      value: "50 - 75",
      label: "50 - 75 LPA",
    },
    {
      value: "75 - 100",
      label: "75 - 100 LPA",
    },
    {
      value: "100+",
      label: "100+ LPA",
    },
  ];

  const minexp = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
  ];

  const maxexp = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
  ];

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  // const handleJobTags = tags => {
  //   setJob({
  //     ...job,
  //     job_tags: tags,
  //   });
  // };

  console.log("Error:", error);
  console.log(access);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Jobs | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="General Posts" breadcrumbItem="Posts List" /> */}
          <Row className="">
            <Col sm="6">
              <h5 className="text-uppercase mb-0">Admin Jobs List</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={jobs || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={jobs || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={jobs}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) ? (
                          <p className="text-danger">Some error occured!</p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Modal
                            size="lg"
                            show={modal}
                            className="mb-3"
                            onHide={() => {
                              if (Object.keys(error).length === 0)
                                setModal(false);
                            }}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{"Edit Job Details"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="form-column p-0">
                                <Row style={{ marginBottom: "5px" }}>
                                  <Form.Group className="col-md-12 mb-4 floating-label">
                                    <Form.Label className="float-label">
                                      Job Title*
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=" "
                                      value={job.title}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          title: e.target.value,
                                        })
                                      }
                                    />
                                    {error?.title && (
                                      <small className="text-danger">
                                        {" "}
                                        {error?.title}{" "}
                                      </small>
                                    )}
                                  </Form.Group>
                                  <Form.Group className="col-md-12 mb-4">
                                    <Form.Label>Job Description</Form.Label>
                                    <ReactQuill
                                      style={{
                                        height: "200px",
                                        marginBottom: "3rem",
                                      }}
                                      theme="snow"
                                      modules={modules}
                                      // ref={reactQuillRef}
                                      value={job.description}
                                      // onKeyDown={checkCharacterCount}
                                      onChange={(
                                        content,
                                        delta,
                                        source,
                                        editor
                                      ) => {
                                        setJob({
                                          ...job,
                                          description: content,
                                        });
                                      }}
                                    />
                                    {error.description && (
                                      <small className="text-danger">
                                        {" "}
                                        {error.description}{" "}
                                      </small>
                                    )}
                                  </Form.Group>

                                  <Form.Group className="col-md-12 mb-4">
                                    <Row>
                                      <Col xs={12} sm={12} md={12}>
                                        Job Tags
                                        <span className="text-danger">*</span>
                                      </Col>
                                      <Col xs={12} sm={12} md={12}>
                                        <AsyncSelect
                                          placeholder={`Type suitable tags for this job`}
                                          isClearable
                                          isMulti={true}
                                          value={job?.job_tags}
                                          onKeyDown={event => {
                                            handleChange(event);
                                          }}
                                          onChange={event => {
                                            if (event === null) {
                                              setJob({
                                                ...job,
                                                job_tags: [],
                                              });
                                            }

                                            if (
                                              event &&
                                              event.length <
                                                job?.job_tags?.length
                                            ) {
                                              setJob({
                                                ...job,
                                                job_tags: event,
                                              });
                                            }
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    {error.job_tags && (
                                      <small className="text-danger">
                                        {" "}
                                        {error.job_tags}{" "}
                                      </small>
                                    )}
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Form.Group className="col-md-4 mb-3 floating-label float-select">
                                    <CountryData
                                      onChange={e => {
                                        setJob(prevState => ({
                                          ...prevState,
                                          country: e.value,
                                          states: null,
                                          city: null,
                                        }));
                                      }}
                                      name="country"
                                      selected={job?.country}
                                    />
                                    {error?.country && (
                                      <p className="text-danger">
                                        {" "}
                                        {error?.country}{" "}
                                      </p>
                                    )}
                                  </Form.Group>

                                  <Form.Group className="col-md-4 mb-3 floating-label float-select">
                                    <StateData
                                      onChange={e => {
                                        setJob(prevState => ({
                                          ...prevState,
                                          states: e.value,
                                          city: null,
                                        }));
                                      }}
                                      name="states"
                                      country={job?.country}
                                      selected={job?.states}
                                    />
                                    {error?.states && (
                                      <p className="text-danger">
                                        {" "}
                                        {error?.states}{" "}
                                      </p>
                                    )}
                                  </Form.Group>

                                  <Form.Group className="col-md-4 mb-3 floating-label float-select">
                                    <CityData
                                      onChange={e => {
                                        setJob(prevState => ({
                                          ...prevState,
                                          city: e.value,
                                        }));
                                      }}
                                      name="city"
                                      country={job?.country}
                                      state={job?.states}
                                      selected={job?.city}
                                    />
                                    {error?.city && (
                                      <p className="text-danger">
                                        {" "}
                                        {error?.city}{" "}
                                      </p>
                                    )}
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Form.Group className="col-md-6 col-lg-4 mb-4 floating-label float-select">
                                    <Form.Label className="float-label">
                                      Job Type
                                    </Form.Label>
                                    <Select
                                      id="select"
                                      placeholder="Select"
                                      options={job_type}
                                      styles={styles}
                                      value={job_type.filter(
                                        item => item.value === job.type
                                      )}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          type: e.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group className="col-md-4 mb-4 floating-label">
                                    <Form.Label className="float-label">
                                      Number of openings for this role*
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=" "
                                      value={job.num_of_openings}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          num_of_openings: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group className="col-md-6 col-lg-4 mb-4 floating-label float-select">
                                    <Form.Label className="float-label">
                                      Nature of the Job
                                    </Form.Label>
                                    <Select
                                      id="select"
                                      placeholder="Select"
                                      options={job_nature}
                                      styles={styles}
                                      value={job_nature.filter(
                                        item => item.value === job.nature_of_job
                                      )}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          nature_of_job: e.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Form.Group className="col-md-6 col-lg-4 mb-4 floating-label float-select">
                                    <Form.Label className="float-label">
                                      Min. years of Experience
                                    </Form.Label>
                                    <Select
                                      id="select"
                                      placeholder="Select"
                                      options={minexp}
                                      styles={styles}
                                      value={minexp.filter(
                                        item =>
                                          item.value === job.min_year_experience
                                      )}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          min_year_experience: e.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group className="col-md-6 col-lg-4 mb-4 floating-label float-select">
                                    <Form.Label className="float-label">
                                      Max. years of Experience
                                    </Form.Label>
                                    <Select
                                      id="select"
                                      placeholder="Select"
                                      options={maxexp}
                                      styles={styles}
                                      value={maxexp.filter(
                                        item =>
                                          item.value === job.max_year_experience
                                      )}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          max_year_experience: e.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group className="col-md-6 col-lg-4 mb-4 floating-label float-select">
                                    <Form.Label className="float-label">
                                      Pay scale range
                                    </Form.Label>
                                    <Select
                                      id="select"
                                      placeholder="Select"
                                      options={payScale}
                                      value={payScale.filter(
                                        item => item.value === job.pay_scale
                                      )}
                                      styles={styles}
                                      onChange={e =>
                                        setJob({
                                          ...job,
                                          pay_scale: e.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </Row>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              {true && (
                                <Button
                                  variant="primary"
                                  onClick={handleJobUpdate}
                                >
                                  Save Job
                                </Button>
                              )}
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
ApproveJobs.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default ApproveJobs;
