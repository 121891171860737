import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { Col, Row } from "reactstrap";
import {
  CountryData,
  StateData,
  CityData,
} from "components/Common/CityStateCountryComponent";
import Select from "react-select";
import moment from "moment";

const NewInvestorsGraph = () => {
  const [newInvestorChartData, setNewInvestorChartData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    value: null,
    label: "All Country",
  });
  const [selectedState, setSelectedState] = useState({
    value: null,
    label: "All States",
  });
  const [selectedCity, setSelectedCity] = useState({
    value: null,
    label: "All Cities",
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: null,
    label: `Since ${moment().format("MMM YY")}`,
  });
  const [monthOptions, setMonthOptions] = useState([]);

  const handleChangeMonth = selectedOption => {
    setSelectedMonth(selectedOption);
  };

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard/newInvestorsChart`,
        {
          params: {
            saas_parent_id: authUser?.userInfo?.saas_parent_id,
            show_all_saas_data,
            is_super_admin: authUser?.userInfo?.is_super_admin,
            country: selectedCountry?.value,
            state: selectedState?.value,
            city: selectedCity?.value,
            queryMonth: selectedMonth?.value,
          },
          headers: {
            Authorization: authUser?.token,
          },
        }
      )
      .then(response => {
        const responseData = response?.data?.data;
        setNewInvestorChartData(responseData?.modifiedInvestorsGraphData);
        const monthData = responseData?.resultMonths?.map(data => ({
          value: data?.period,
          label: "Since " + data?.period,
        }));

        let currentMonthOptionLabel = `Since ${moment().format("MMM YY")}`;
        if (
          !monthData?.find(
            month =>
              month?.label?.toLowerCase() ===
              currentMonthOptionLabel?.toLowerCase()
          )
        ) {
          monthData.unshift({
            label: currentMonthOptionLabel,
            value: null,
          });
        }
        setMonthOptions(monthData);
      })
      .catch(err => {});

    return () => {};
  }, [selectedCountry, selectedState, selectedCity, selectedMonth]);

  const handleCountryChange = selectedOption => {
    setSelectedState();
    setSelectedCity();
    setSelectedCountry(selectedOption);
  };

  const handleStateChange = selectedOption => {
    setSelectedCity();
    setSelectedState(selectedOption);
  };

  const CustomizedTick = graphVal => {
    const { x, y, stroke, payload } = graphVal;
    const [month, year] = payload.value.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={7} fill="#666" fontSize={11}>
          <tspan textAnchor="middle" x="0">
            {month}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="12">
            {year}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <div className="column-sec">
      <Row className="mb-2 d-flex align-items-center">
        <Col md={!selectedCountry?.value ? 4 : 8}>
          <h2 className="mb-4 mt-3 title-sm">Investor Onboarding</h2>
        </Col>
        <Col md={4}>
          <Select
            className="custom-select form-control-sm"
            id="period"
            name="period"
            options={monthOptions}
            value={selectedMonth}
            onChange={handleChangeMonth}
          />
        </Col>
        {!selectedCountry?.value ? (
          <Col md={4} className="text-start">
            <CountryData
              className="custom-select form-control-sm p-0"
              name="country"
              placeholder="All Country"
              selected={selectedCountry?.value}
              onChange={handleCountryChange}
              value={selectedCountry?.value}
              showAllCountryOption={true}
            />
          </Col>
        ) : (
          ""
        )}
      </Row>
      <Row className="justify-content-end">
        {selectedCountry?.value ? (
          <Col md={4} className="text-start">
            <CountryData
              className="custom-select form-control-sm p-0"
              name="country"
              placeholder="All Country"
              selected={selectedCountry?.value}
              onChange={handleCountryChange}
              value={selectedCountry?.value}
              showAllCountryOption={true}
            />
          </Col>
        ) : (
          ""
        )}
        {selectedCountry?.value && (
          <Col md={4} className="text-start">
            <StateData
              className="custom-select form-control-sm p-0"
              name="state"
              placeholder="All State"
              countryCode={
                selectedCountry?.value ? selectedCountry?.value : "IN"
              }
              selected={selectedState?.value}
              onChange={handleStateChange}
              showAllStateOption={true}
            />
          </Col>
        )}
        {selectedState?.value && (
          <Col md={4} className="text-start">
            <CityData
              className="custom-select form-control-sm p-0"
              name="city"
              placeholder="All City"
              countryCode={selectedCountry?.value}
              stateCode={selectedState?.value}
              selected={selectedCity?.value}
              onChange={setSelectedCity}
              showAllCityOption={true}
            />
          </Col>
        )}
      </Row>
      <Row>
        <div className="mt-4 mb-4">
          <ResponsiveContainer width="100%" height={280}>
            <BarChart
              data={newInvestorChartData}
              margin={{
                top: 5,
                right: 15,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" tick={<CustomizedTick />} interval={0} />
              <YAxis
                tickFormatter={value =>
                  new Intl.NumberFormat("en").format(value)
                }
              />
              <Tooltip
                formatter={value => new Intl.NumberFormat("en").format(value)}
              />
              <Legend wrapperStyle={{ bottom: -20 }} align="center" />
              <Bar
                dataKey="new_investors"
                fill="#de8787"
                name="Registered Investors"
                activeBar={<Rectangle fill="#de8787" stroke="#de8787" />}
              />
              <Bar
                dataKey="unique_investors"
                fill="#3e916b"
                name="Angel Investors"
                activeBar={<Rectangle fill="#3e916b" stroke="#3e916b" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Row>
    </div>
  );
};

export default NewInvestorsGraph;
