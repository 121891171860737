import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import axios from "axios";
import { useEffect } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

const UploadInvsttMedia = ({ ...props }) => {
  const [allMedia, setAllMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);

  const mediaForList = [
    {
      tabKey: "investor",
      tabLabel: "Investor",
      inputFields: [
        {
          key: "timeline",
          label: "Timeline",
        },
        {
          key: "my_network",
          label: "My Network",
        },
        {
          key: "investment_deals",
          label: "Investment Deals",
        },
        {
          key: "my_portfolio",
          label: "My Portfolio",
        },
        {
          key: "post_job",
          label: "Post Job",
        },
        {
          key: "startups",
          label: "Startups",
        },
        {
          key: "discover_syndicates",
          label: "Discover Syndicates",
        },
        {
          key: "my_kyc",
          label: "My KYC",
        },
      ],
    },
    {
      tabKey: "founder",
      tabLabel: "Founder",
      inputFields: [
        {
          key: "timeline",
          label: "Timeline",
        },
        {
          key: "my_startup",
          label: "My Startup",
        },
        {
          key: "funding_rounds",
          label: "Funding Rounds",
        },
        {
          key: "my_network",
          label: "My Network",
        },
        {
          key: "post_job",
          label: "Post Job",
        },
        {
          key: "discover_syndicates",
          label: "Discover Syndicates",
        },
      ],
    },
    {
      tabKey: "professional",
      tabLabel: "Professional",
      inputFields: [
        {
          key: "timeline",
          label: "Timeline",
        },
        {
          key: "my_network",
          label: "My Network",
        },
        {
          key: "apply_for_jobs",
          label: "Apply For Jobs",
        },
      ],
    },
    {
      tabKey: "admin",
      tabLabel: "Admin",
      inputFields: [
        {
          key: "admin_url",
          label: "Admin Media URL",
        },
      ],
    },
    {
      tabKey: "homepage",
      tabLabel: "Homepage",
      inputFields: [
        {
          key: "homepage_url",
          label: "Homepage Media URL",
        },
      ],
    },
    {
      tabKey: "syndicate",
      tabLabel: "Domain Expert",
      inputFields: [
        {
          key: "syndicate_url",
          label: "Domain Expert Media URL",
        },
      ],
    },
  ];

  const handleSubmit = async e => {
    e.preventDefault();
    setSaveLoading(true);
    let formData = new FormData(e.target);
    let values = Object.fromEntries(formData.entries());

    let media_links = Object.entries(values).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key?.split("-")?.[1]]: value,
      }),
      {}
    );

    let reqBody = {
      media_for: selectedMedia?.media_for,
      media_links,
    };

    let config = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };

    let encryptedQuery = {
      q: JSON.stringify(encryptDataToArray(reqBody))
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/admin/media/update`,
        encryptedQuery,
        config
      )
      .then(async response => {
        await fetchAllInvsttMedia();
        setSaveLoading(false);
      })
      .catch(error => {
        setSaveLoading(false);
      });
  };

  const fetchAllInvsttMedia = async () => {
    let config = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/media/get-all`, config)
      .then(response => {
        let { allMedia } = response.data || {};
        setAllMedia(allMedia || []);

        if (!selectedMedia) {
          let firstElement = mediaForList?.[0]?.tabKey;
          let firstElementLinks =
            (allMedia?.find(media => media?.media_for === firstElement) || {})
              ?.media_links || {};

          let media_links = Object.keys(firstElementLinks || {}).reduce(
            (prev, key) => ({
              ...prev,
              [`${firstElement}-${key}`]: firstElementLinks?.[key],
            }),
            {}
          );

          setSelectedMedia({
            media_for: firstElement,
            media_links: media_links || {},
          });
        }
      })
      .catch(error => {
        setAllMedia([]);
        let firstElement = mediaForList?.[0]?.tabKey;

        setSelectedMedia({
          media_for: firstElement,
          media_links: {},
        });
      });
  };

  useEffect(() => {
    fetchAllInvsttMedia();
    return () => {};
  }, []);

  const checkIfValidYoutubeURL = (url = "") => {
    let regex = /^https:\/\/www.youtube.com\/watch\?v=(\w|-){11}$/;

    return regex.test(url);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Upload Media | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Row className="">
            <Col sm="10">
              <h5 className="text-uppercase">Upload Media</h5>
            </Col>
          </Row>
          <div>
            <Tabs
              onSelect={e => {
                let found =
                  (allMedia?.find(media => media?.media_for === e) || {})
                    ?.media_links || {};

                let media_links = Object.keys(found || {}).reduce(
                  (prev, key) => ({
                    ...prev,
                    [`${e}-${key}`]: found?.[key],
                  }),
                  {}
                );

                setSelectedMedia({
                  media_for: e,
                  media_links: media_links || {},
                });
              }}
            >
              {mediaForList.map(({ tabKey, tabLabel, inputFields }, index) => {
                return (
                  <Tab title={tabLabel} eventKey={tabKey} key={tabKey}>
                    <Form
                      className="mt-3"
                      key={tabKey}
                      onSubmit={e => {
                        handleSubmit(e);
                      }}
                    >
                      <Row>
                        {inputFields?.map(({ key, label }, idx) => {
                          let keyValue = `${tabKey}-${key}`;

                          let enteredValue =
                            selectedMedia?.media_links?.[keyValue];

                          let isValid = checkIfValidYoutubeURL(enteredValue);

                          let restProps = {};

                          if (!!enteredValue?.length) {
                            restProps = {
                              ...restProps,
                              isValid: isValid,
                              isInvalid: !isValid,
                            };
                          }

                          return (
                            <Col
                              key={keyValue}
                              className="mt-3"
                              lg={4}
                              md={6}
                              sm={12}
                            >
                              <Form.Group>
                                <Form.Label className="d-flex align-items-center font-size-18">
                                  {label}

                                  {!!restProps?.isValid && (
                                    <>
                                      <a
                                        className="text-info ms-3"
                                        href={enteredValue}
                                        target="_blank"
                                        title="Watch Video"
                                      >
                                        <i
                                          className="mdi mdi-video-outline"
                                          id="logintooltip"
                                        ></i>
                                      </a>
                                    </>
                                  )}
                                </Form.Label>

                                <Form.Control
                                  type="url"
                                  id={keyValue}
                                  name={keyValue}
                                  value={enteredValue}
                                  placeholder="Youtube Link Here ..."
                                  onChange={event => {
                                    setSelectedMedia({
                                      ...(selectedMedia || {}),
                                      media_links: {
                                        ...(selectedMedia?.media_links || {}),
                                        [keyValue]: event?.target?.value,
                                      },
                                    });
                                  }}
                                  pattern="https:\/\/www.youtube.com\/watch\?v=(\w|-){11}"
                                  {...restProps}
                                  autoComplete="off"
                                />
                              </Form.Group>
                            </Col>
                          );
                        })}
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          {saveLoading ? (
                            <>
                              <Button type="submit" disabled>
                                <Spinner size="sm" className="me-2" />
                                Saving...
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button type="submit" outline>
                                Save
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadInvsttMedia;
