import Swal from "sweetalert2";
import * as moment from "moment";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { withRouter, Redirect } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import GroupsList from "../Users/UserList/users-groups";
import { Form } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  CustomInput,
} from "reactstrap";

let counter = 0;

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  addPitchDay,
  getPitchFounders,
  getPitchInvestors,
  getPitchPanellist,
} from "store/pitchdays/actions";
import FounderList from "./FounderList";
import UserGroupsModal from "./Modals/UserGroupsModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Quill Editor for Event Description
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import _ from "lodash";
import Select from "react-select";
import {
  getAllUsersForReference,
  getPitchBookedSlotes,
  getTimezones,
} from "helpers/backend_helper";
import { format } from "date-fns";
import { getNumberOfParticipantsOptions } from "helpers/common";

class PitchDaysCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_saving_event: false,
      zoom_meeting_id: "",
      post_category: "",
      title: "",
      event_slug: "",
      event_register_for: "investor",
      description: "",
      descriptionText: "",
      post_pitchday_marketing_content: "",
      event_datetime: "",
      event_end_datetime: "",
      zoom_link: "",
      TotalParticipantsOptions: getNumberOfParticipantsOptions(),
      TotalParticipants: 11,
      additional_information: "",
      industrySectors: [{ sectorName: "" }],
      investors: [{ investorName: "" }],
      panellist: [{ investorName: "" }],
      founders: [{ founderName: "" }],
      images: [{ fileName: "", file: "" }],
      login_banner: { fileName: "", file: "" },
      small_banner: { fileName: "", file: "" },
      // big_banner: { fileName: "", file: "" },
      // fieldType: [{ field_name: "", field_type: "" }],
      fieldType: [],
      fieldTypeOptions: [
        { label: "Text Box", value: "text" },
        { label: "Single Choice", value: "radio" },
        { label: "Multiple Choice", value: "checkbox" },
      ],
      fieldLabel: "",

      newFieldAddIndex: -1,
      customFieldCount: 1,
      customFieldIndex: 1,

      pitch_day_marketing_images: [{ fileName: "", file: "" }],
      selectedRanges: [],
      alert_visible: false,
      modal: false,
      group_model: false,
      selectedFounders: [],
      selectedInvestors: [],
      selectedPanellist: [],
      isInvestors: "",
      userRoleType: "",
      outside_investor: [],
      selectedFounderGroups: [],
      selectedInvestorGroups: [],
      userGroupsModalOpen: false,
      minFounder: 0,
      referenceUserList: [],
      saasParentList: [],
      event_address: "",
      timezonelist: [],
      selectedTimezon: "IST",
      event_plateform: "zoom",
      selectedSaasParent: "",
      selectedParentError: "",
      event_end_datetime_error: "",
      event_datetime_error: "",
      user: {},
      errors: {
        errorStatus: false,
        founder: "",
      },
      groupType: "investor",
      event_type: "",
      investor_groups: {
        groups: [],
        countries: [],
        states: [],
        cities: [],
      },
      founder_groups: {
        groups: [],
        countries: [],
        states: [],
        cities: [],
      },
      selectAllInvestors: false,
      selectAllFounders: false,
      enableRecordings: false,
      // disableReminders: false,
      eventInHours: false,
      eventToday: false,
      eventTommorow: false,
      eventInAWeek: false,
      saasName: "",
      authUser: JSON.parse(localStorage.getItem("authUser")),
    };

    this.eventDateTimeChange.bind(this);
    this.eventEndDateTimeChange.bind(this);
    this.handleAddIndustrySectors.bind(this);
    this.handleRemoveIndustrySectors.bind(this);
    this.handleAddInvestors.bind(this);
    this.handleRemoveInvestors.bind(this);
    this.handleAddFounders.bind(this);
    this.handleRemoveFounders.bind(this);
    this.handleAddImages.bind(this);
    this.handleRemoveImages.bind(this);
    this.handleRemovePostPitchDayMarketingImages.bind(this);
    this.saveEvent.bind(this);
    this.handleChangeIndustrySector.bind(this);
    this.handleChangeInvestor.bind(this);
    this.handleChangeFounder.bind(this);
    this.handleChangeImage.bind(this);
    this.handleChangePostPitchDayMarketingImage.bind(this);
    this.onAlertDismiss.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleGroupModel = this.toggleGroupModel.bind(this);
    this.setFounders = this.setFounders.bind(this);
    this.toggleInv = this.toggleInv.bind(this);
    this.selectedGroups = this.selectedGroups.bind(this);
    this.setUserGroupsModal = this.setUserGroupsModal.bind(this);
    this.handleUserGroupsModal = this.handleUserGroupsModal.bind(this);
    this.handleGroupsValuesChange = this.handleGroupsValuesChange.bind(this);
    this.setSelectAllInvestors = this.setSelectAllInvestors.bind(this);
    this.setSelectAllFounders = this.setSelectAllFounders.bind(this);
    this.getSaasParentUserList = this.getSaasParentUserList.bind(this);
  }

  setSelectAllInvestors(selectAllInvestors) {
    this.setState({ selectAllInvestors });
  }

  setSelectAllFounders(selectAllFounders) {
    this.setState({ selectAllFounders });
  }

  componentDidMount() {
    this.props.onGetPitchFounders();
    this.props.onGetPitchInvestors();
    this.props.onGetPitchPanellist();
    this.getSaasParentUserList();
    this.getTimezonDetails();
    const currentTime = moment().utc().format("YYYY-MM-DD hh:mm:ss A");

    getPitchBookedSlotes({ currentTime: currentTime })
      .then(res => {
        this.setState({ selectedRanges: res?.eventBookedSlotes });
        setTimeout(() => {
          this.generateExcludedTimes("start", new Date());
        }, 100);
      })
      .catch(err => {
        console.trace(err);
      });
  }
  getTimezonDetails = () => {
    getTimezones()
      .then(response => {
        let storeTimezone = [];
        response?.zonelist?.map(item => {
          if (item) {
            storeTimezone.push({
              value: item?.alias,
              label: item?.alias + " (" + item?.location + ")",
              offset: item?.offset,
            });
          }
        });
        this.setState({ timezonelist: storeTimezone });
      })
      .catch(error => {
        console.trace(error);
      });
  };
  getSaasParentUserList = () => {
    getAllUsersForReference()
      .then(response => {
        let users = response.data;
        let saasPartners = [];
        users = users
          .map(user => {
            if (!user) return null;

            let {
              _id,
              firstname,
              lastname,
              email,
              saas_name,
              other_primary_saas,
            } = user;

            if (!_id) return null;

            let temp = `${firstname || ""} ${lastname || ""}`.trim();

            if (temp) {
              temp = `${temp} ${email ? "- " + email : ""}`.trim();
            } else {
              temp = email || "";
            }

            if (!temp) return null;

            let otherSaas = [];
            if (user?.other_primary_saas) {
              for (let other_saas of user.other_primary_saas) {
                other_saas?.status === "accepted"
                  ? otherSaas.push(other_saas)
                  : [];
              }
            }

            if (user?.is_saas_parent) {
              temp = temp + " - " + user?.saas_fullname;
              saasPartners.push({
                value: _id,
                label: temp,
                saas_name: saas_name,
                other_primary_saas: otherSaas,
              });
            }

            return {
              value: _id,
              label: temp,
              saas_name: saas_name,
              other_primary_saas: other_primary_saas,
            };
          })
          .filter(data => !!data);
        this.setState({
          referenceUserList: users,
          saasParentList: saasPartners,
        });
      })
      .catch(error => {
        console.trace(error);
        this.setState({ referenceUserList: [], saasParentList: [] });
      });
  };

  setUserGroupsModal = userGroupsModalOpen =>
    this.setState({ userGroupsModalOpen });

  handleUserGroupsModal = () =>
    this.setState(prevState => ({
      userGroupsModalOpen: !prevState.userGroupsModalOpen,
    }));

  handleGroupsValuesChange = (groupKey, key, value) => {
    this.setState(prevState => ({
      [groupKey]: { ...prevState[groupKey], [key]: value },
    }));
  };

  handleCancelEvent = () => {
    window.location.href = "/event";
  };

  toggleInv = value => {
    this.setState(prevState => ({
      isInvestors: value,
    }));
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  toggleUserRoleType = value => {
    this.setState(prevState => ({
      isInvestors: value,
    }));
  };

  toggleGroupModel = () => {
    this.setState(prevState => ({
      group_model: !prevState.group_model,
    }));
  };

  setFounders = (data, outsideInvestor) => {
    if (this.state.isInvestors === "investor") {
      let dataNew = data;
      dataNew = dataNew.concat(this.state.selectedInvestors);
      let set = new Set(dataNew);
      let result = [...set];
      let newdata = result.filter(function (el) {
        return data.indexOf(el) < 0;
      });
      result = result.filter(o1 => !newdata.some(o2 => o1._id === o2._id));
      this.setState({
        selectedInvestors: result.map(e => ({
          _id: e._id,
          startup_name: e?.startup_name,
          firstname: e.firstname,
          lastname: e.lastname,
          email: e.email,
          mobile_country_code: e.mobile_country_code,
          mobile_number: e.mobile_number,
          is_saas_parent: e?.is_saas_parent,
          saas_name: e?.saas_name,
          saas_detail: e?.saas_detail,
        })),
        investors: data.map(e => ({ investorName: e._id })),
        outside_investor: outsideInvestor,
      });
    } else if (this.state.isInvestors === "founder") {
      if (data.length > 0) {
        this.setState({ errors: { errorStatus: false, founder: "" } });
      } else {
        if (this.state?.event_type !== "General Event") {
          this.setState({
            errors: { errorStatus: true, founder: "Please select founders." },
          });
        }
      }
      this.setState({
        selectedFounders: data.map(e => ({
          ...e,
          description_by_admin: e.description_by_admin,
        })),
        founders: data.map(e => ({
          founderName: e._id,
          description_by_admin: e.description_by_admin,
        })),
        error: {
          description: "",
        },
      });
    } else if (this.state.isInvestors === "panellist") {
      let dataNew = data;
      dataNew = dataNew.concat(this.state.selectedPanellist);
      let set = new Set(dataNew);
      let result = [...set];
      let newdata = result.filter(function (el) {
        return data.indexOf(el) < 0;
      });
      result = result.filter(o1 => !newdata.some(o2 => o1._id === o2._id));
      this.setState({
        selectedPanellist: result.map(e => ({
          _id: e._id,
          startup_name: e?.startup_name,
          firstname: e.firstname,
          lastname: e.lastname,
          email: e.email,
          mobile_country_code: e.mobile_country_code,
          mobile_number: e.mobile_number,
          is_saas_parent: e?.is_saas_parent,
          saas_name: e?.saas_name,
          saas_detail: e?.saas_detail,
        })),
        panellist: data.map(e => ({ investorName: e._id })),
      });
    }
  };

  eventDateTimeChange = e => {
    console.log(e, "event");
    this.setState({
      event_datetime: e.currentTarget.value,
    });
  };
  eventEndDateTimeChange = e => {
    console.log(e, "e");
    this.setState({
      event_end_datetime: e.currentTarget.value,
    });
  };
  handleEventAddressChange = e => {
    this.setState({
      event_address: e.currentTarget.value,
    });
  };

  handleZoomMeetingIdChange = e => {
    this.setState({
      zoom_meeting_id: e.currentTarget.value,
    });
  };

  // Function for change Input Fields for IndustrySector
  handleChangeIndustrySector = (e, index) => {
    const sectors = this.state.industrySectors;
    sectors[index]["sectorName"] = e.currentTarget.value;
    this.setState({
      industrySectors: sectors,
    });
  };

  // Function for Create Input Fields for IndustrySector
  handleAddIndustrySectors = e => {
    var values = this.state.industrySectors;
    values.push({ sectorName: "" });
    this.setState({ industrySectors: values });
  };

  // Function for Remove Input Fields for IndustrySector
  handleRemoveIndustrySectors = index => {
    var values = this.state.industrySectors;
    values.splice(index, 1);
    this.setState({ industrySectors: values });
  };

  handleCustomFieldAddMore = e => {
    var values = this.state.fieldType;
    if (this.state.customFieldCount <= 3) {
      this.setState({ customFieldCount: this.state.customFieldCount + 1 });
      values.push({ field_name: "", field_type: "text" });
      this.setState({ fieldType: values });
    }
  };
  // Function for Remove Input Fields for Custom Field
  handleRemoveCustomField = index => {
    var values = this.state.fieldType;
    values.splice(index, 1);
    this.setState({ fieldType: values });
    let customFieldCount = this.state.customFieldCount;
    this.setState({ customFieldCount: customFieldCount - 1 });
  };

  // Function for change Input Fields for IndustrySector
  handleChangeFieldType = (e, index) => {
    const sectors = this.state.investmentDeal;
    if (e.currentTarget?.name == "calendly_link") {
      sectors[index]["selectedCalendlyLink"] = e.currentTarget.value;
    } else {
      sectors[index]["selectedDeal"] = e.value;
    }
    this.setState({
      investmentDeal: sectors,
    });
  };

  // Function for change Input Fields for Investors
  handleChangeInvestor = (e, index) => {
    const investors = this.state.investors;
    investors[index]["investorName"] = e?.value || "";
    this.setState({
      investors,
    });
  };

  // Function for Create Input Investors
  handleAddInvestors = e => {
    var values = this.state.investors;
    values.push({ investorName: "" });
    this.setState({ investors: values });
  };

  // Function for Remove Input Investors
  handleRemoveInvestors = index => {
    var values = this.state.investors;
    values.splice(index, 1);
    this.setState({ investors: values });
  };

  // Function for change Input Fields for Founder
  handleChangeFounder = (e, index) => {
    const founders = this.state.founders;
    founders[index]["founderName"] = e?.value || "";
    this.setState({
      founders,
    });
  };

  // Function for Create Input Founder
  handleAddFounders = e => {
    var values = this.state.founders;
    values.push({ founderName: "" });
    this.setState({ founders: values });
  };

  // Function for Remove Input Founder
  handleRemoveFounders = index => {
    var values = this.state.founders;
    values.splice(index, 1);
    this.setState({ founders: values });
  };

  // Function for change Input Fields for Founder
  handleChangeImage = (e, index) => {
    const images = this.state.images;
    images[index]["fileName"] = e.target.value;
    images[index]["file"] = e.target.files[0];
    this.setState({
      images,
    });
  };

  handleChangeBanner = (e, index, filename) => {
    // console.log("e.target.value", e.target.value)
    // console.log("e.target.name", e.target.name)
    const images = this.state.images;
    let target_name = e.target.name;
    target_name["fileName"] = e.target.value;
    target_name["file"] = e.target.files[0];
    this.setState({
      target_name,
    });
  };

  handleChangePostPitchDayMarketingImage = (e, index) => {
    const pitch_day_marketing_images = this.state.pitch_day_marketing_images;
    pitch_day_marketing_images[index]["fileName"] = e.target.value;
    pitch_day_marketing_images[index]["file"] = e.target.files[0];
    this.setState({
      pitch_day_marketing_images,
    });
  };

  // Function for Create File Image
  handleAddImages = e => {
    var values = this.state.images;
    values.push({ fileName: "", file: "" });
    this.setState({ images: values });
  };

  // Function for Remove File Image
  handleRemoveImages = index => {
    var values = this.state.images;
    values.splice(index, 1);
    this.setState({ images: values });
  };

  handleRemovePostPitchDayMarketingImages = index => {
    var values = this.state.pitch_day_marketing_images;
    values.splice(index, 1);
    this.setState({ pitch_day_marketing_images: values });
  };

  handleTitleChange = e => {
    this.setState({
      title: e.currentTarget.value,
    });
  };
  handleAliasChange = e => {
    this.setState({
      event_slug: e.currentTarget.value,
    });
  };

  setFieldType = (field, value, index, inner_index) => {
    counter++;
    // this.setState({ customFieldCount: counter });
    this.setState({ customFieldIndex: index });
    const tempArr = this.state.fieldType;
    const tempObj = tempArr[index];
    if (field === "option") {
      const tempOption = tempObj["option"];
      tempOption[inner_index] = { [value]: value };
      tempArr[index]["option"] = tempOption;
      this.setState({ fieldType: tempArr });
    } else if (
      field === "field_type" &&
      (value === "radio" ||
        value === "checkbox" ||
        value === "dropdown_selection")
    ) {
      tempObj["option"] = [{ "": "" }, { "": "" }];
      tempObj[field] = value;
      tempArr[index] = tempObj;
      this.setState({ fieldType: tempArr });
    } else {
      tempObj[field] = value;
      tempArr[index] = tempObj;
      this.setState({ fieldType: tempArr });
    }
  };

  checkUniqueAlias = async event_slug => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/post/pitchday/checkUniqueSlug`,
      {
        event_slug: event_slug,
      },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      }
    );
    if (response.data.status === true) {
      this.setState({
        error: {
          event_slug: response.data.message,
        },
      });
      this.setState({ is_saving_event: true });
    } else {
      this.setState({
        error: {
          event_slug: "",
        },
      });
      this.setState({ is_saving_event: false });
    }
    return true;
  };

  handleDescriptionChange = e => {
    this.setState({
      description: e.currentTarget.value,
    });
  };

  handleZoomLinkChange = e => {
    this.setState({
      zoom_link: e.currentTarget.value,
    });
  };
  handleChangeParticipants = e => {
    this.setState({
      TotalParticipants: e.currentTarget.value,
    });
  };
  handleAdditionalInformationChange = e => {
    this.setState({
      additional_information: e.currentTarget.value,
    });
  };

  handleEventType = e => {
    this.setState({
      event_type: e.currentTarget.value,
    });
  };
  handleEventPlateform = e => {
    this.setState({
      event_plateform: e.currentTarget.value,
    });
  };
  handleSaasParentType = async e => {
    await this.setState({
      selectedSaasParent: e.value,
    });
    const name = this.state.saasParentList.filter(item => {
      return item.value == this.state.selectedSaasParent;
    });
    this.setState({ saasName: name[0]?.saas_name });
    this.props.onGetPitchInvestors({
      selectedSaasParent: this.state.selectedSaasParent,
    });
    this.props.onGetPitchPanellist({
      selectedSaasParent: this.state.selectedSaasParent,
    });
  };
  handleTimezone = async e => {
    this.setState({
      selectedTimezon: e.value,
    });
  };

  selectedGroups = async (groups, type = "investor") => {
    let data = groups.map(e => e._id);
    if (type === "investor") {
      this.setState({ selectedInvestorGroups: data });
    } else {
      this.setState({ selectedFounderGroups: data });
    }

    //update members on close of modal 2
    this.toggleGroupModel();
  };

  saveEvent = async (e, values) => {
    const { is_saving_event } = this.state;
    if (
      !this.state.event_datetime ||
      this.state.event_datetime === undefined ||
      this.state.event_datetime === "undefined"
    ) {
      this.setState({
        event_datetime_error: "The event start date and time is required",
      });
      this.setState({
        error: {
          description: "The event start date and time is required",
        },
      });
      this.setState({ is_saving_event: false });
      return false;
    }

    if (
      !this.state.event_end_datetime ||
      this.state.event_end_datetime === undefined ||
      this.state.event_end_datetime === "undefined"
    ) {
      this.setState({
        event_end_datetime_error: "The event end date and time is required",
      });
      this.setState({
        error: {
          description: "The event end date and time is required",
        },
      });
      this.setState({ is_saving_event: false });
      return false;
    }

    this.setState({ is_saving_event: true });
    if (this.state.selectedSaasParent == "") {
      this.setState({ selectedParentError: "Required Field" });
    }

    if (!is_saving_event) {
      e.preventDefault();

      const { onAddPitchDay, success, error } = this.props;

      // Validations
      if (this.state.selectedSaasParent === "") {
        this.setState({
          error: {
            description: "Syndicate Parent is Required.",
          },
        });
        this.setState({ is_saving_event: false });
        return false;
      }

      let description = this.state.description.replace(/(<([^>]+)>)/gi, "");
      if (description === "" || description.length < 5) {
        this.setState({
          error: {
            description:
              "Description should be of minimum length of five character",
          },
        });
        this.setState({ is_saving_event: false });
        return false;
      }

      // let post_pitchday_marketing_content =
      //   this.state.post_pitchday_marketing_content.replace(/(<([^>]+)>)/gi, "");
      // if (
      //   post_pitchday_marketing_content === "" ||
      //   post_pitchday_marketing_content.length < 5
      // ) {
      //   this.setState({
      //     error: {
      //       post_pitchday_marketing_content:
      //         "Post pitchday marketing content should be of minimum length of five character",
      //     },
      //   });
      //   this.setState({ is_saving_event: false });
      // }

      if (Date.parse(this.state.event_datetime) - Date.parse(new Date()) < 0) {
        this.setState({
          error: {
            description: "Event Start Date & Time cannot be in the past.",
          },
        });
        this.setState({ is_saving_event: false });
        return false;
      }

      if (
        Date.parse(this.state.event_end_datetime) -
          Date.parse(this.state.event_datetime) <
        0
      ) {
        this.setState({
          error: {
            description:
              "Event End Date & Time cannot be before Event Start Date & Time.",
          },
        });
        this.setState({ is_saving_event: false });
        return false;
      }

      let checkValid = await this.checkUniqueAlias(this.state.event_slug);
      if (checkValid && this.state.error.event_slug) {
        return false;
      }

      this.setState({ error: "" });

      let industrySectorValues = "";
      if (this.state.industrySectors.length > 0) {
        const industrySectorValueArr = [];
        this.state.industrySectors.map(item => {
          industrySectorValueArr.push(item.sectorName);
        });
        industrySectorValues = industrySectorValueArr.join(",");
      }

      let investorsValues = "";
      if (this.state.selectAllInvestors) {
        const investorsArr = [];
        this.props.investors.map(investor =>
          investorsArr.push(investor._id.toString())
        );
        investorsValues = investorsArr.join(",");
      } else {
        const investorsArr = [];
        if (this.state.investors.length > 0) {
          this.state.investors.map(item => {
            investorsArr.push(item.investorName);
          });
        }
        investorsValues = investorsArr.join(",");
      }
      if (this.state?.panellist?.length > 9) {
        this.setState({
          error: {
            panellist: "Can not select more then 9 panel list",
          },
        });
        this.setState({ is_saving_event: false });
        return false;
      } else {
        this.setState({
          error: {
            panellist: "",
          },
        });
      }

      let panellistValues = "";

      if (this.state.panellist.length > 0) {
        const panellistArr = [];
        this.state.panellist.map(item => {
          panellistArr.push(item.investorName);
        });
        panellistValues = panellistArr.join(",");
      }

      let outsideInvestorsValues = "";
      if (this.state.outside_investor.length > 0) {
        const investorsArr = [];
        this.state.outside_investor.map(item => {
          investorsArr.push(item.id);
        });
        outsideInvestorsValues = investorsArr.join(",");
      }

      let foundersValues = "";
      if (this.state.founders.length > 0) {
        const foundersValueArr = [];
        this.state.founders.map(item => {
          foundersValueArr.push(item.founderName);
          // foundersValueArr.push({ id: item.founderName, description_by_admin: item.description_by_admin });
        });
        foundersValues = foundersValueArr.join(",");
      }

      const files = [];
      if (this.state.images.length > 0) {
        this.state.images.map(item => {
          files.push(item.file);
        });
      }

      const pitch_day_marketing_images_files = [];
      if (this.state.pitch_day_marketing_images.length > 0) {
        this.state.pitch_day_marketing_images.map(item => {
          pitch_day_marketing_images_files.push(item.file);
        });
      }

      let { investor_groups, founder_groups } = this.state;

      investor_groups = {
        ...investor_groups,
        groups: investor_groups.groups.map(group => group.value),
      };

      founder_groups = {
        ...founder_groups,
        groups: founder_groups.groups.map(group => group.value),
      };
      let isError = false;
      if (this.state.event_type === "Pitch Day") {
        if (
          foundersValues === "" ||
          founder_groups?.cities.length > 0 ||
          founder_groups?.countries.length > 0 ||
          founder_groups?.groups.length > 0 ||
          founder_groups?.states.length > 0
        ) {
          console.log("Error");
          isError = true;

          this.setState({
            errors: { errorStatus: true, founder: "Please select founders." },
            error: {
              description: "Please select founders.",
            },
          });
        } else {
          console.log("No Error");
          isError = false;
          this.setState({
            errors: { errorStatus: false, founder: "" },
            error: {
              description: "",
            },
          });
        }
      }
      let localoffset = moment().utcOffset();
      let offsetData = this.state?.timezonelist?.filter(
        item => item?.value === (this.state.selectedTimezon ?? "IST")
      )?.[0]?.offset;
      let ChangedEventDate = moment(this.state.event_datetime)
        .utc()
        .format()
        .trim();
      let ChangedEndEventDate = moment(this.state.event_end_datetime)
        .utc()
        .format()
        .trim();
      if (offsetData) {
        ChangedEventDate = moment(this.state.event_datetime)
          .utc()
          .add(localoffset, "minutes")
          .subtract(offsetData)
          .format()
          .trim();
        ChangedEndEventDate = moment(this.state.event_end_datetime)
          .utc()
          .add(localoffset, "minutes")
          .subtract(offsetData)
          .format()
          .trim();
      }

      if (this.state.event_plateform === "enablex") {
        let currentTimeOf = moment()
          .utc()
          .add(localoffset, "minutes")
          .subtract(offsetData)
          .format()
          .trim();

        const minimumStartTime = new Date(
          new Date(currentTimeOf).getTime() + 45 * 60000
        );
        if (new Date(ChangedEventDate) <= minimumStartTime) {
          this.setState({
            error: {
              description: `Event Start Date & Time must be after ${moment(
                minimumStartTime
              ).format("MM/DD/yyyy h:mm A")}.`,
            },
          });
          this.setState({ is_saving_event: false });
          return false;
        }
      }

      let login_banner = "";
      let small_banner = "";
      // let big_banner = '';
      if (this.state.event_plateform == "enablex") {
        login_banner = this.state.login_banner?.file;
        small_banner = this.state.small_banner?.file;
        // big_banner = this.state.big_banner?.file;
      }
      let userData = JSON.parse(localStorage.getItem("user_info"));
      const loggedInBySuperAdmin = userData?.is_super_admin || "";
      const adminUserId = userData?.user_id || "";
      const newPitchDay = {
        post_category: "Events",
        saas_parent_id: this.state.selectedSaasParent,
        event_type: this.state.event_type,
        event_plateform: this.state?.event_plateform,
        event_address: this.state?.event_address,
        title: this.state.title.trim(),
        event_slug: this.state.event_slug?.trim(),
        event_register_for: this.state.event_register_for,
        description: this.state.description.trim(),
        custom_fields: JSON.stringify(this.state.fieldType),
        descriptionText: this.state.descriptionText?.trim(),
        post_pitchday_marketing_content:
          this.state.post_pitchday_marketing_content.trim(),
        event_datetime: ChangedEventDate,
        event_end_datetime: ChangedEndEventDate,
        zoom_link: this.state.zoom_link.trim(),
        zoom_meeting_id: this.state.zoom_meeting_id.replace(/\s+/g, "").trim(),
        additional_information: this.state.additional_information.trim(),
        industry_sectors: industrySectorValues.trim(),
        invited_investor: investorsValues.trim(),
        invited_founder: foundersValues.trim(),
        inveted_panellist: panellistValues.trim(),
        moderators: this.state.founders?.length,
        outside_investor: outsideInvestorsValues.trim(),
        images: files,
        pitch_day_marketing_images: pitch_day_marketing_images_files,

        login_banner: login_banner,
        small_banner: small_banner,
        // big_banner: big_banner,

        selectedFounderGroups: this.state.selectedFounderGroups,
        selectedInvestorGroups: this.state.selectedInvestorGroups,
        investor_groups: JSON.stringify(investor_groups),
        founder_groups: JSON.stringify(founder_groups),
        selectAllInvestors: this.state.selectAllInvestors,
        selectAllFounders: this.state.selectAllFounders,
        saasName: this.state.saasName,
        selectedTimezon: this.state.selectedTimezon,
        totalParticipants: this.state.TotalParticipants,
        enableRecordings: this?.state?.enableRecordings,
        // disableReminders: this?.state?.disableReminders,
        eventInHours: this?.state?.eventInHours,
        eventToday: this?.state?.eventToday,
        eventTommorow: this?.state?.eventTommorow,
        eventInAWeek:this?.state?.eventInAWeek,
        loggedInBySuperAdmin: loggedInBySuperAdmin,
        adminUserId: adminUserId,
      };

      if (isError === false) {
        // Save new pitchDay
        let sbc = await onAddPitchDay(newPitchDay);
        this.onAlertDismiss(sbc);
        this.setState({ is_saving_event: false });
      } else {
        this.setState({ is_saving_event: false });
      }
    }
  };

  onAlertDismiss = data => {
    this.setState({ alert_visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alert_visible: false });
        // window.location.href = "/event";
      }, 2000);
    });
  };

  eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  handleStartDateChange = date => {
    if (this.state.event_datetime_error) {
      this.setState({ event_datetime_error: "" });
    }
    this.setState(
      { event_datetime: moment(date).format("YYYY-MM-DDTHH:mm") },
      this.generateExcludedTimes("start", date)
    );
  };

  handleEndDateChange = date => {
    if (this.state.event_end_datetime_error) {
      this.setState({ event_end_datetime_error: "" });
    }
    this.setState(
      { event_end_datetime: moment(date).format("YYYY-MM-DDTHH:mm") },
      this.generateExcludedTimes("end", date)
    );
  };

  create_slug_from_title = str => {
    let generated_slug = String(this.state.title)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
    this.setState({ event_slug: generated_slug });
    this.checkUniqueAlias(generated_slug);
  };

  generateExcludedTimes = (type, selectedDate) => {
    const { selectedRanges } = this.state;
    const excludedTimes = [];
    for (const range of selectedRanges) {
      const start = new Date(
        moment
          .utc(range?.date?.event_date, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
      );
      const end = new Date(
        moment
          .utc(range?.date?.event_end_date, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
      );
      const startDate = new Date(moment(start).format("yyyy-MM-DD"));
      const endDate = new Date(moment(end).format("yyyy-MM-DD"));
      let currentDate = new Date(startDate);
      let selected = new Date(selectedDate);
      if (type === "start") {
        currentDate = new Date(startDate);
      } else {
        currentDate = new Date(endDate);
      }
      if (currentDate.getDate() === selected.getDate()) {
        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const startTime =
            date.getTime() === startDate.getTime() ? start : new Date(date);
          const endTime =
            date.getTime() === endDate.getTime() ? end : new Date(date);

          const startHour = startTime.getHours();
          const endHour = endTime.getHours();
          const startMinute = startTime.getMinutes();
          const endMinute = endTime.getMinutes();

          for (let hour = startHour; hour <= endHour; hour++) {
            const minuteStart = hour === startHour ? startMinute : 0;
            const minuteEnd = hour === endHour ? endMinute : 59;

            for (let minute = minuteStart; minute <= minuteEnd; minute += 15) {
              const excludedTime = new Date(date);
              excludedTime.setHours(hour);
              excludedTime.setMinutes(minute);
              excludedTimes.push(excludedTime);
            }
          }
        }
      }
    }
    this.setState({ excludedTimes });
  };

  render() {
    const { is_saving_event, selectedRanges, excludedTimes } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Event | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Event" breadcrumbItem="Create Event" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="outer-repeater"
                      onValidSubmit={this.saveEvent}
                    >
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Syndicate Parent:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Select
                                placeholder={"Select Syndicate Parent"}
                                id="select_saas_parent"
                                options={this.state.saasParentList?.sort(
                                  (a, b) => {
                                    if (
                                      a?.label?.toLowerCase() <
                                      b?.label?.toLowerCase()
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.label?.toLowerCase() >
                                      b?.label?.toLowerCase()
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  }
                                )}
                                onChange={this.handleSaasParentType}
                                value={this.state.saasParentList.filter(
                                  item =>
                                    item.value === this.state.selectedSaasParent
                                )}
                                errorMessage="Required Field"
                                // validate={{ required: { value: true } }}
                                // validate = {{
                                //   required:{
                                //     selectedSaasParent:true
                                //   }
                                // }}
                              />
                              {this.state.selectedSaasParent === "" && (
                                <p className="text-danger">
                                  {this.state.selectedParentError}
                                </p>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Event Platform:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                type="select"
                                name="event_platform"
                                onChange={this.handleEventPlateform}
                                errorMessage="Required Field"
                              >
                                {/* <option value="enablex">EnableX</option> */}
                                {(this.state.authUser?.userInfo?.email
                                  .toLowerCase()
                                  .includes(
                                    "@wefoundercircle.com".toLowerCase()
                                  ) ||
                                  this.state.authUser?.userInfo?.email
                                    .toLowerCase()
                                    .includes("@invstt.com".toLowerCase()) ||
                                  this.state.authUser?.userInfo
                                    ?.is_super_admin) && (
                                  <option value="zoom" selected>
                                    Zoom
                                  </option>
                                )}
                                <option value="offline">Offline</option>
                                <option value="online_others">
                                  Online - Others
                                </option>
                              </AvField>
                            </Col>
                          </FormGroup>
                          {(this.state.event_plateform === "zoom" ||
                            this.state.event_plateform === "online_others") && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="zoom_link"
                                  className="col-form-label"
                                >
                                  {this.state.event_plateform ===
                                  "online_others"
                                    ? "Meeting Link: "
                                    : "Zoom Link: "}
                                  <span className="text-danger">
                                    {this.state.event_plateform ===
                                    "online_others"
                                      ? ""
                                      : "*"}
                                  </span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  id="zoom_link"
                                  name="zoom_link"
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    this.state.event_plateform ===
                                    "online_others"
                                      ? "Enter Meeting Link"
                                      : "Enter Zoom Link"
                                  }
                                  errorMessage="Required Field"
                                  validate={{
                                    required: {
                                      value:
                                        this.state.event_plateform ===
                                        "online_others"
                                          ? false
                                          : true,
                                    },
                                  }}
                                  value={this.state.zoom_link || ""}
                                  onChange={this.handleZoomLinkChange}
                                />
                              </Col>
                            </FormGroup>
                          )}

                          {this.state.event_plateform === "enablex" && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="enablex_link"
                                  className="col-form-label"
                                >
                                  Total Participants:{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  type="select"
                                  name="participants"
                                  value={this.state.TotalParticipants}
                                  onChange={this.handleChangeParticipants}
                                  errorMessage="Required Field"
                                  validate={{
                                    required: { value: true },
                                  }}
                                >
                                  {this.state.TotalParticipantsOptions?.map(
                                    opt => {
                                      return (
                                        <option value={opt?.value}>
                                          {opt?.label}
                                        </option>
                                      );
                                    }
                                  )}
                                </AvField>
                              </Col>
                            </FormGroup>
                          )}
                          {this.state.event_plateform === "offline" && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="event_address"
                                  className="col-form-label"
                                >
                                  Event Address :
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  id="event_address"
                                  name="event_address"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Event Address"
                                  value={this.state.event_address || ""}
                                  onChange={this.handleEventAddressChange}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          {this.state.event_plateform === "zoom" && (
                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="zoom_meeting_id"
                                  className="col-form-label"
                                >
                                  Zoom Meeting Id:{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvField
                                  id="zoom_meeting_id"
                                  name="zoom_meeting_id"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Zoom Meeting Id"
                                  errorMessage="Required Field"
                                  validate={{ required: { value: true } }}
                                  value={this.state.zoom_meeting_id || ""}
                                  onChange={this.handleZoomMeetingIdChange}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Event Type:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                type="select"
                                name="event_type"
                                onChange={this.handleEventType}
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                  minLength: {
                                    value: 6,
                                    errorMessage:
                                      "Your name must be between 6 and 16 characters",
                                  },
                                }}
                              >
                                <option value="" selected>
                                  --Select Event Type--
                                </option>
                                <option value="Pitch Day">
                                  Pitch Day Event
                                </option>
                                <option value="General Event">
                                  General Event
                                </option>
                              </AvField>
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Title: <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="title"
                                name="title"
                                type="text"
                                className="form-control"
                                placeholder="Enter Event Title"
                                errorMessage="Required Field"
                                validate={{ required: { value: true } }}
                                value={this.state.title || ""}
                                onChange={this.handleTitleChange}
                                onBlur={this.create_slug_from_title}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label
                                htmlFor="event_slug"
                                className="col-form-label"
                              >
                                Event Alias:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="event_slug"
                                name="event_slug"
                                type="text"
                                className="form-control"
                                // readOnly="true"
                                placeholder="Event Alias"
                                errorMessage="The Event Alias is required."
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[a-z0-9]+(?:-[a-z0-9]+)*$",
                                    errorMessage: "Invalid Event Alias.",
                                  },
                                }}
                                value={this.state.event_slug || ""}
                                onChange={this.handleAliasChange}
                                onBlur={event => {
                                  this.checkUniqueAlias(event.target.value);
                                }}
                              />
                              <small>
                                <div className="text-danger">
                                  {this.state.error?.event_slug}
                                </div>
                              </small>

                              <small>
                                <strong>Note:</strong> (Only small letter and
                                dash(-) are allowed. E.g : sample-event)
                              </small>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                Start Date Time:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              {/* <AvField
                                id="event_datetime"
                                name="event_datetime"
                                type="datetime-local"
                                className="form-control"
                                errorMessage="Required Field"
                                validate={{ required: { value: true } }}
                                value={this.state.event_datetime || ""}
                                onChange={this.eventDateTimeChange}
                              /> */}
                              <DatePicker
                                id="event_datetime"
                                name="event_datetime"
                                selected={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                onChange={this.handleStartDateChange}
                                selectsStart
                                startDate={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                endDate={
                                  this.state.event_end_datetime
                                    ? new Date(this.state.event_end_datetime)
                                    : ""
                                }
                                showTimeSelect
                                placeholderText="mm/dd/yyyy --:-- --"
                                timeFormat="h:mm aa"
                                required={true}
                                timeIntervals={15}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                timeCaption="Time"
                                className={`form-control ${
                                  this.state.event_datetime_error
                                    ? "datepicker-container-error"
                                    : ""
                                }`}
                                excludeTimes={
                                  this.state.event_plateform === "enablex"
                                    ? excludedTimes
                                    : []
                                }
                                onKeyDown={e => e.preventDefault()}
                              />
                              {this.state.event_datetime_error && (
                                <div className="end_start_date_required">
                                  {this.state.event_datetime_error}
                                </div>
                              )}
                              {this.state.event_plateform === "enablex" && (
                                <small>
                                  <strong>Note:</strong> (The already booked
                                  time slot is displayed as disabled)
                                </small>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label className="col-form-label">
                                End Date Time:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              {/* <AvField
                                id="event_end_datetime"
                                name="event_end_datetime"
                                type="datetime-local"
                                className="form-control"
                                errorMessage="Required Field"
                                // min={this.state.event_datetime}
                                validate={{ required: { value: true } }}
                                value={this.state.event_end_datetime || ""}
                                onChange={this.eventEndDateTimeChange}
                              /> */}

                              <DatePicker
                                selected={
                                  this.state.event_end_datetime
                                    ? new Date(this.state.event_end_datetime)
                                    : ""
                                }
                                onChange={this.handleEndDateChange}
                                selectsEnd
                                startDate={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                endDate={
                                  this.state.event_end_datetime
                                    ? new Date(this.state.event_end_datetime)
                                    : ""
                                }
                                minDate={
                                  this.state.event_datetime
                                    ? new Date(this.state.event_datetime)
                                    : ""
                                }
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                placeholderText="mm/dd/yyyy --:-- --"
                                timeFormat="h:mm aa"
                                timeCaption="Time"
                                className={`form-control ${
                                  this.state.event_end_datetime_error
                                    ? "datepicker-container-error"
                                    : ""
                                }`}
                                excludeTimes={
                                  this.state.event_plateform === "enablex"
                                    ? excludedTimes
                                    : []
                                }
                                onKeyDown={e => e.preventDefault()}
                              />
                              {this.state.event_end_datetime_error && (
                                <div className="end_start_date_required">
                                  {this.state.event_end_datetime_error}
                                </div>
                              )}
                              {this.state.event_plateform === "enablex" && (
                                <small>
                                  <strong>Note:</strong> (The already booked
                                  time slot is displayed as disabled)
                                </small>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Select Timezone:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <Select
                                placeholder={"Select Timezone"}
                                id="select_timezon"
                                options={this.state.timezonelist?.sort(
                                  (a, b) => {
                                    if (
                                      a?.alias?.toLowerCase() <
                                      b?.alias?.toLowerCase()
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.alias?.toLowerCase() >
                                      b?.alias?.toLowerCase()
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  }
                                )}
                                onChange={this.handleTimezone}
                                value={this.state.timezonelist.filter(
                                  item =>
                                    item?.value === this.state.selectedTimezon
                                )}
                                errorMessage="Required Field"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Description's Header:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <textarea
                                id="title1"
                                name="title1"
                                type="text"
                                rows="5"
                                disabled
                                className="form-control"
                                placeholder="Attendees name"
                                value={`Dear [Attendees name], \n\nYou have been invited for [${
                                  this.state.title || "Event Title"
                                }] conducted by [${
                                  this.state.saasName?.toUpperCase() ||
                                  "Syndicate Name"
                                }]. \n\nEvent On: [${
                                  this.state.event_datetime
                                    ? moment(this.state.event_datetime).format(
                                        "Do MMMM YYYY, h:mm A"
                                      )
                                    : "Date-Time"
                                } ${this.state.selectedTimezon || "IST"}]`}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup
                            className=""
                            row
                            style={{ height: "300px" }}
                          >
                            <Col lg="3">
                              <Label className="col-form-label">
                                Description:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col lg="9">
                              <ReactQuill
                                id="description"
                                style={{ height: "258px" }}
                                placeholder="Enter Event Description"
                                value={this.state.description || ""}
                                onChange={(content, delta, source, editor) => {
                                  this.setState({ description: content });
                                  this.setState({
                                    descriptionText: editor.getText(),
                                  });
                                }}
                                modules={this.eventDescriptionEditorModules}
                                formats={this.eventDescriptionEditorFormats}
                                required
                              />
                              {/* <AvField
                                id="description"
                                name="description"
                                type="textarea"
                                className="form-control"
                                placeholder="Enter Event Description"
                                errorMessage="Required Field"
                                validate={{
                                  required: { value: true },
                                }}
                                value={this.state.description || ""}
                                onChange={this.handleDescriptionChange}
                              /> */}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label htmlFor="title" className="col-form-label">
                                Description's Footer:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <textarea
                                id="title2"
                                name="title2"
                                type="text"
                                disabled
                                className="form-control"
                                placeholder="desc footer"
                                value={`[${
                                  this.state.saasName?.toUpperCase() ||
                                  "Syndicate Name"
                                }] is looking forward to see you! \nTeam Invstt`}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Col lg="3">
                              <Label
                                htmlFor="additional_information"
                                className="col-form-label"
                              >
                                Additional Information:
                              </Label>
                            </Col>
                            <Col lg="9">
                              <AvField
                                id="additional_information"
                                name="additional_information"
                                type="text"
                                className="form-control"
                                placeholder="Enter Additional Information"
                                value={this.state.additional_information || ""}
                                onChange={
                                  this.handleAdditionalInformationChange
                                }
                              />
                            </Col>
                          </FormGroup>
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Industry Sectors:
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater_industry_sector"
                              >
                                {this.state.industrySectors.map(
                                  (field, key) => (
                                    <Fragment key={"_field_" + key}>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="11">
                                          <AvField
                                            id="sectorName"
                                            type="text"
                                            name="sectorName"
                                            className="inner form-control"
                                            placeholder="Enter Industry Sector"
                                            value={field.sectorName}
                                            onChange={e =>
                                              this.handleChangeIndustrySector(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </Col>

                                        <Col md="1">
                                          <div className="mt-2 mt-md-0 d-grid">
                                            <Button
                                              color="danger"
                                              className="inner"
                                              onClick={() => {
                                                this.handleRemoveIndustrySectors(
                                                  key
                                                );
                                              }}
                                              block
                                            >
                                              <i className="bx bx-trash fs-3"></i>
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                    </Fragment>
                                  )
                                )}
                              </Col>
                            </div>
                            <Row className="justify-content-end">
                              <Col lg="9">
                                <Button
                                  color="success"
                                  className="inner"
                                  onClick={this.handleAddIndustrySectors}
                                >
                                  <i className="bx bx-plus-circle fs-3"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Add Investors:
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row">
                                    <Col md={12}>
                                      <Form.Check
                                        type="switch"
                                        label="Select All Investors"
                                        checked={this.state.selectAllInvestors}
                                        onChange={e => {
                                          this.setState({
                                            selectAllInvestors:
                                              e.target.checked,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </div>
                                  {!this.state.selectAllInvestors && (
                                    <div className="mb-3 row align-items-center">
                                      <Col md="9">
                                        <ul
                                          style={{
                                            maxHeight: "100px",
                                            overflowX: "auto",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {this.state.selectedInvestors
                                            .length ? (
                                            this.state.selectedInvestors.map(
                                              (e, ind) => (
                                                <li key={ind}>{e.firstname}</li>
                                              )
                                            )
                                          ) : (
                                            <li className="placeHolder">
                                              {"No investors added."}
                                            </li>
                                          )}
                                        </ul>
                                      </Col>
                                      <Col md={3} className="d-flex">
                                        <Button
                                          color="primary"
                                          className="inner mx-1 p-1"
                                          onClick={() => {
                                            this.toggleInv("investor");
                                            this.toggle();
                                          }}
                                        >
                                          <i className="bx bx-plus-circle fs-3"></i>
                                        </Button>

                                        <Button
                                          color="warning"
                                          onClick={() => {
                                            this.setState({
                                              groupType: "investor",
                                            });
                                            this.handleUserGroupsModal();
                                          }}
                                        >
                                          Add Group
                                        </Button>
                                      </Col>
                                      <Col md="2"></Col>
                                    </div>
                                  )}
                                </Fragment>
                              </Col>
                            </div>

                            {/* Investor Groups Row */}
                            <div className="inner justify-content-end form-group mb-0 row">
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row align-items-center">
                                    <Col
                                      md="12"
                                      className="mb-3"
                                      hidden={
                                        !this.state.investor_groups.groups
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.groups
                                          .length ? (
                                          this.state.investor_groups.groups.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No groups selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.investor_groups.countries
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.countries
                                          .length ? (
                                          this.state.investor_groups.countries.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No countries selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.investor_groups.states
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.states
                                          .length ? (
                                          this.state.investor_groups.states.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No states selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.investor_groups.cities
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.investor_groups.cities
                                          .length ? (
                                          this.state.investor_groups.cities.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No cities selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                  </div>
                                </Fragment>
                              </Col>
                            </div>
                          </div>

                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Add Founders:{" "}
                                  {this.state.event_type !==
                                    "General Event" && (
                                    <span className="text-danger">*</span>
                                  )}
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  {/* <div className="mb-3 row">
                                    <Col md={12}>
                                      <Form.Check
                                        type="switch"
                                        label="Select All Founders"
                                        checked={this.state.selectAllFounders}
                                        onChange={e => {
                                          this.setState({
                                            selectAllFounders: e.target.checked,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </div> */}
                                  {/* {!this.state.selectAllFounders && ( */}
                                  <div className="mb-3 row align-items-center">
                                    <Col md="9">
                                      {this.state.selectedFounders.length ? (
                                        <Table bordered responsive>
                                          <thead>
                                            <tr>
                                              <th>Founder</th>
                                              <th>Startup</th>
                                              <th>Description</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.selectedFounders.map(
                                              (e, ind) => (
                                                <tr key={ind}>
                                                  <td>{e.firstname}</td>
                                                  <td>{e.startup_name}</td>
                                                  <td>
                                                    {e.description_by_admin}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      ) : (
                                        <ul
                                          className={
                                            this.state?.errors?.errorStatus &&
                                            "border border-danger"
                                          }
                                        >
                                          {" "}
                                          <li className="placeHolder">
                                            {"No founders added."}
                                          </li>{" "}
                                        </ul>
                                      )}
                                    </Col>
                                    <Col md={3} className="d-flex">
                                      <Button
                                        color="primary"
                                        className="inner mx-1 p-1"
                                        onClick={() => {
                                          this.toggleInv("founder");
                                          this.toggle();
                                        }}
                                      >
                                        <i className="bx bx-plus-circle fs-3"></i>
                                      </Button>

                                      {/* <Button
                                        color="warning"
                                        onClick={() => {
                                          this.setState({
                                            groupType: "founder",
                                          });
                                          this.handleUserGroupsModal();
                                        }}
                                      >
                                        Add Group
                                      </Button> */}
                                    </Col>
                                    {this.state?.errors?.founder !== "" &&
                                      this.state.event_type === "Pitch Day" && (
                                        <p className="text-danger">
                                          Required Field
                                        </p>
                                      )}
                                    {/* <p className="text-warning">
                                      Note: Only founder with live deal are
                                      applicable for invitation of Pitch Day
                                    </p> */}
                                  </div>
                                  {/* )} */}
                                </Fragment>
                              </Col>
                            </div>

                            {/* Founder Groups Row */}
                            <div className="inner justify-content-end form-group mb-0 row">
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="repeater"
                              >
                                <Fragment>
                                  <div className="mb-3 row align-items-center">
                                    <Col
                                      md="12"
                                      className="mb-3"
                                      hidden={
                                        !this.state.founder_groups.groups.length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.groups
                                          .length ? (
                                          this.state.founder_groups.groups.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No groups selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.founder_groups.countries
                                          .length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.countries
                                          .length ? (
                                          this.state.founder_groups.countries.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No countries selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.founder_groups.states.length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.states
                                          .length ? (
                                          this.state.founder_groups.states.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No states selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                    <Col
                                      md="4"
                                      hidden={
                                        !this.state.founder_groups.cities.length
                                      }
                                    >
                                      <ul>
                                        {this.state.founder_groups.cities
                                          .length ? (
                                          this.state.founder_groups.cities.map(
                                            (e, ind) => (
                                              <li key={ind}>{e.label}</li>
                                            )
                                          )
                                        ) : (
                                          <li className="placeHolder">
                                            {"No cities selected."}
                                          </li>
                                        )}
                                      </ul>
                                    </Col>
                                  </div>
                                </Fragment>
                              </Col>
                            </div>
                          </div>
                          {this.state.event_plateform === "enablex" && (
                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Add Panellist:
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="repeater"
                                >
                                  <Fragment>
                                    <div className="mb-3 row align-items-center">
                                      <Col md="9">
                                        <ul
                                          style={{
                                            maxHeight: "100px",
                                            overflowX: "auto",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {this.state?.selectedPanellist
                                            ?.length ? (
                                            this.state?.selectedPanellist?.map(
                                              (e, ind) => (
                                                <li key={ind}>{e.firstname}</li>
                                              )
                                            )
                                          ) : (
                                            <li className="placeHolder">
                                              {"No Panelist added."}
                                            </li>
                                          )}
                                        </ul>
                                      </Col>
                                      <Col md={3} className="d-flex">
                                        <Button
                                          color="primary"
                                          className="inner mx-1 p-1"
                                          onClick={() => {
                                            this.toggleInv("panellist");
                                            this.toggle();
                                          }}
                                        >
                                          <i className="bx bx-plus-circle fs-3"></i>
                                        </Button>
                                      </Col>
                                      <Col md="2"></Col>
                                    </div>
                                  </Fragment>
                                </Col>
                              </div>
                            </div>
                          )}
                          <div className="inner-repeater mb-4">
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Event Image
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                {this.state.images.map((field, key) => (
                                  <Fragment key={"_field_" + key}>
                                    <div className="mb-3 row align-items-center">
                                      <Col md="11">
                                        <AvField
                                          id="fileName"
                                          name="fileName"
                                          type="file"
                                          className="inner form-control"
                                          placeholder="Enter Industry Sector"
                                          value={field.fileName}
                                          onChange={e =>
                                            this.handleChangeImage(e, key)
                                          }
                                        />
                                      </Col>
                                      {/* <Col md="1">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="danger"
                                            className="inner"
                                            onClick={() => {
                                              this.handleRemoveImages(key);
                                            }}
                                          >
                                            <i className="bx bx-trash fs-3"></i>
                                          </Button>
                                        </div>
                                      </Col> */}
                                      <Col md={11}>[Dimension 621*200 px]</Col>
                                    </div>
                                  </Fragment>
                                ))}
                              </Col>
                            </div>

                            {this.state.event_plateform == "enablex" && (
                              <>
                                <div className="inner form-group mb-0 row">
                                  <Col lg="3">
                                    <Label className="col-form-label">
                                      Meeting Login Banner
                                    </Label>
                                  </Col>
                                  <Col
                                    lg={9}
                                    className="inner ms-md-auto"
                                    id="image_repeater"
                                  >
                                    <Fragment>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="11">
                                          <AvField
                                            id="login_banner"
                                            name="login_banner"
                                            // errorMessage="The field is required."
                                            // validate={{ required: { value: true } }}
                                            accept=".jpeg, .png, .jpg, .gif"
                                            type="file"
                                            className="inner form-control"
                                            // value={this.state.login_banner.fileName}
                                            onChange={e => {
                                              const login_banner = [];
                                              login_banner["fileName"] =
                                                e.target.value;
                                              login_banner["file"] =
                                                e.target.files[0];
                                              const scope = this;

                                              var _URL =
                                                window.URL || window.webkitURL;
                                              var file;
                                              if ((file = e.target.files[0])) {
                                                let img = new Image();
                                                img.onload = function () {
                                                  if (
                                                    this.width !== 525 ||
                                                    this.height !== 300
                                                  ) {
                                                    alert(
                                                      "Meeting Login Banner Height and Width are not matched "
                                                    );
                                                    e.target.value = null;
                                                  } else {
                                                    scope.setState({
                                                      login_banner:
                                                        login_banner,
                                                    });
                                                  }
                                                };
                                                img.src =
                                                  _URL.createObjectURL(file);
                                              }
                                            }}
                                          />
                                        </Col>

                                        <Col md={11}>
                                          [Dimension 525x300 px]
                                        </Col>
                                      </div>
                                    </Fragment>
                                  </Col>
                                </div>

                                <div className="inner form-group mb-0 row">
                                  <Col lg="3">
                                    <Label className="col-form-label">
                                      Meeting Topbar Banner
                                    </Label>
                                  </Col>
                                  <Col
                                    lg={9}
                                    className="inner ms-md-auto"
                                    id="image_repeater"
                                  >
                                    <Fragment>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="11">
                                          <AvField
                                            id="small_banner"
                                            name="small_banner"
                                            // errorMessage="The field is required."
                                            // validate={{
                                            //   required: { value: true },
                                            // }}
                                            accept=".jpeg, .png, .jpg, .gif"
                                            type="file"
                                            className="inner form-control"
                                            // value={this.state.small_banner.fileName}
                                            onChange={e => {
                                              const small_banner = [];
                                              small_banner["fileName"] =
                                                e.target.value;
                                              small_banner["file"] =
                                                e.target.files[0];
                                              const scope1 = this;

                                              var _URL =
                                                window.URL || window.webkitURL;
                                              let file;
                                              if ((file = e.target.files[0])) {
                                                let img = new Image();
                                                img.onload = function () {
                                                  // alert("width : "+this.width + " and height : " + this.height);
                                                  if (
                                                    this.width !== 330 ||
                                                    this.height !== 45
                                                  ) {
                                                    alert(
                                                      "Meeting Topbar Banner Hight and Width are not matched "
                                                    );
                                                    e.target.value = null;
                                                  } else {
                                                    scope1.setState({
                                                      small_banner:
                                                        small_banner,
                                                    });
                                                  }
                                                };
                                                img.src =
                                                  _URL.createObjectURL(file);
                                              }
                                            }}
                                          />
                                        </Col>

                                        <Col md={11}>[Dimension 330x45 px]</Col>
                                      </div>
                                    </Fragment>
                                  </Col>
                                </div>

                                {/* <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                Big Banner
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                  <Fragment >
                                    <div className="mb-3 row align-items-center">
                                      <Col md="11">
                                        <AvField
                                          id="big_banner"
                                          name="big_banner"
                                          errorMessage="The field is required."
                                          validate={{ required: { value: true } }}
                                          accept=".jpeg, .png, .jpg, .gif"
                                          type="file"
                                          className="inner form-control"
                                          // value={this.state.big_banner.fileName}
                                          onChange={e =>{
                                            const big_banner = [];
                                            big_banner["fileName"] = e.target.value;
                                            big_banner["file"] = e.target.files[0];
                                            const scope2 = this

                                            var _URL = window.URL || window.webkitURL;
                                            let file;
                                            if ((file = e.target.files[0])) {
                                              let img = new Image();
                                              img.onload = function () {
                                                // alert("width : "+this.width + " and height : " + this.height);
                                                if(this.width !== 330 || this.height !== 330){
                                                  alert("Big Banner Hight and Width are not matched")
                                                  e.target.value = '';
                                                  
                                                }else{
                                                  scope2.setState({
                                                      big_banner:big_banner,
                                                    });
                                                }
                                              };
                                              img.src = _URL.createObjectURL(file);
                                            }
                                          }
                                        }   

                                        />
                                      </Col>
                                    
                                      <Col md={11}>[Dimension 330x330 px]</Col>
                                    </div>
                                  </Fragment>
                              </Col>
                            </div> */}
                              </>
                            )}

                            <FormGroup className="mb-4" row>
                              <Col lg="3">
                                <Label
                                  htmlFor="title"
                                  className="col-form-label"
                                >
                                  Event Is For:{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                              </Col>
                              <Col lg="9">
                                <AvRadioGroup
                                  name="event_register_for"
                                  onChange={event => {
                                    this.setState({
                                      event_register_for: event.target.value,
                                    });
                                  }}
                                  // value="investor"

                                  errorMessage="Required Field"
                                  validate={{ required: { value: true } }}
                                  // errorMessage="Pick one!"
                                >
                                  <AvRadio label="Investors" value="investor" />
                                  <AvRadio label="Founders" value="founder" />
                                </AvRadioGroup>
                              </Col>
                            </FormGroup>

                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Custom Fields:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="repeater_industry_sector"
                                >
                                  {this.state.fieldType.map((field, key) => (
                                    <Fragment key={"_field_" + key}>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="5">
                                          <AvField
                                            id="field_name"
                                            type="text"
                                            name="field_name"
                                            className="inner form-control"
                                            placeholder="Label Text"
                                            value={field.field_name}
                                            onChange={e =>
                                              this.setFieldType(
                                                "field_name",
                                                e.target.value,
                                                key
                                              )
                                            }
                                          />
                                        </Col>

                                        <Col md="5">
                                          <Select
                                            id="selectedGroups"
                                            name="field_type"
                                            options={
                                              this.state.fieldTypeOptions
                                            }
                                            // styles={styles}
                                            value={this.state.fieldTypeOptions.filter(
                                              function (option) {
                                                return (
                                                  option.value ===
                                                  field.field_type
                                                );
                                              }
                                            )}
                                            onChange={e => {
                                              this.setFieldType(
                                                "field_type",
                                                e.value,
                                                key
                                              );
                                            }}
                                          />
                                        </Col>

                                        <Col md="2">
                                          <div className="mt-2 mt-md-0 d-grid">
                                            <Button
                                              color="danger"
                                              title="Remove"
                                              // disabled={
                                              //   this.state.fieldType
                                              //     .length < 2
                                              //     ? true
                                              //     : false
                                              // }
                                              className="inner"
                                              onClick={() => {
                                                this.handleRemoveCustomField(
                                                  key
                                                );
                                              }}
                                              block
                                            >
                                              <i className="bx bx-trash fs-3"></i>
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                      <div className="mb-3 row align-items-center">
                                        {this.state.fieldType[key]
                                          ?.field_type ===
                                          "dropdown_selection" ||
                                        this.state.fieldType[key]
                                          ?.field_type === "radio" ||
                                        this.state.fieldType[key]
                                          ?.field_type === "checkbox" ? (
                                          <>
                                            {this.state.fieldType[
                                              key
                                            ]?.option?.map(
                                              (item, inner_index) => {
                                                return (
                                                  <Col md={4} className="mb-3">
                                                    {/* {console.log(
                                            'item---->',
                                            item[Object.keys(item)[0]]
                                          )} */}
                                                    <div className="my-form-input d-flex align-items-center">
                                                      <AvField
                                                        id="option"
                                                        type="text"
                                                        name="option"
                                                        className="inner form-control "
                                                        value={
                                                          Object.keys(item)[0]
                                                        }
                                                        onChange={e => {
                                                          this.setFieldType(
                                                            "option",
                                                            e.target.value,
                                                            key,
                                                            inner_index
                                                          );
                                                        }}
                                                        placeholder={
                                                          "Option " +
                                                          (inner_index + 1)
                                                        }
                                                      />

                                                      <i
                                                        class="bx bx-trash fs-3 ms-2"
                                                        onClick={() => {
                                                          const tempArr =
                                                            this.state
                                                              .fieldType;
                                                          const tempObj =
                                                            tempArr[key];
                                                          if (
                                                            tempObj["option"]
                                                              .length > 2
                                                          ) {
                                                            counter++;
                                                            // setCount(counter);
                                                            tempObj[
                                                              "option"
                                                            ].splice(
                                                              inner_index,
                                                              1
                                                            );
                                                            tempArr[key] =
                                                              tempObj;
                                                            this.setState({
                                                              fieldType:
                                                                tempArr,
                                                            });
                                                          }
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </Col>
                                                );
                                              }
                                            )}
                                            <Col md={4} className="mb-3">
                                              <Button
                                                title="Add More Option"
                                                color="primary"
                                                className="inner p-1"
                                                onClick={() => {
                                                  counter++;
                                                  this.setState({
                                                    counter: counter,
                                                  });
                                                  const tempArr =
                                                    this.state.fieldType;
                                                  const tempObj = tempArr[key];
                                                  tempObj["option"].push({
                                                    "": "",
                                                  });
                                                  tempArr[key] = tempObj;
                                                  this.setState({
                                                    fieldType: tempArr,
                                                  });
                                                }}
                                              >
                                                <i className="bx bx-plus-circle fs-4"></i>
                                              </Button>
                                            </Col>
                                          </>
                                        ) : null}
                                      </div>
                                    </Fragment>
                                  ))}
                                </Col>
                              </div>
                              <Row className="justify-content-end">
                                <Col lg="9">
                                  <Button
                                    title="Add More"
                                    color="success"
                                    className="inner"
                                    onClick={this.handleCustomFieldAddMore}
                                    // onClick={() => {
                                    //   setNewFieldAddIndex(index + 1);
                                    //   let data = [...fieldType];
                                    //   data.splice(index + 1, 0, {
                                    //     field_type: 'text',
                                    //     field_label: '',
                                    //   });
                                    //   setForm(data);
                                    // }}
                                  >
                                    <i className="bx bx-plus-circle fs-3"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </div>

                            {/* <Row className="justify-content-end">
                              <Col lg={9}>
                                <Button
                                  color="primary"
                                  className="inner"
                                  onClick={this.handleAddImages}
                                >
                                  <i className="bx bx-plus-circle fs-4 m-0"></i>
                                </Button>
                              </Col>
                            </Row> */}
                            <hr
                              className="mb-4"
                              style={{
                                height: "2px",
                                border: "none",
                                color: "#333",
                                backgroundColor: "#333",
                              }}
                            />
                            <FormGroup
                              className="mb-4"
                              row
                              style={{ height: "300px" }}
                            >
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Post Pitchday Marketing Content:{" "}
                                  {/* <span className="text-danger">*</span> */}
                                </Label>
                              </Col>
                              <Col lg="9">
                                <ReactQuill
                                  id="description"
                                  style={{ height: "258px" }}
                                  placeholder="Enter post pitchday marketing content"
                                  value={
                                    this.state
                                      .post_pitchday_marketing_content || ""
                                  }
                                  onChange={content => {
                                    this.setState({
                                      post_pitchday_marketing_content: content,
                                    });
                                  }}
                                  modules={this.eventDescriptionEditorModules}
                                  formats={this.eventDescriptionEditorFormats}
                                />
                              </Col>
                            </FormGroup>
                            <div className="inner form-group mb-0 row">
                              <Col lg="3">
                                <Label className="col-form-label">
                                  Post Pitchday Marketing Image:
                                </Label>
                              </Col>
                              <Col
                                lg={9}
                                className="inner ms-md-auto"
                                id="image_repeater"
                              >
                                {this.state.pitch_day_marketing_images.map(
                                  (field, key) => (
                                    <Fragment key={"_field_" + key}>
                                      <div className="mb-3 row align-items-center">
                                        <Col md="12">
                                          <AvField
                                            id="fileName"
                                            name="fileName"
                                            type="file"
                                            className="inner form-control"
                                            placeholder="Add Post Pitchday Marketing Image"
                                            value={field.fileName}
                                            onChange={e =>
                                              this.handleChangePostPitchDayMarketingImage(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </Col>

                                        <Col md={11}>
                                          [Dimension 621*200 px]
                                        </Col>
                                      </div>
                                    </Fragment>
                                  )
                                )}
                              </Col>
                            </div>
                          </div>
                          {this.state.event_plateform === "enablex" && (
                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Enable Recording:
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="repeater"
                                >
                                  <AvCheckboxGroup
                                    inline
                                    name="maxCheckedCheckboxList"
                                    validate={{ max: { value: 1 } }}
                                  >
                                    <AvCheckbox
                                      value="true"
                                      onClick={() => {
                                        this.setState({
                                          disableRecordings:
                                            !this.state.disableRecordings,
                                        });
                                      }}
                                    />
                                  </AvCheckboxGroup>
                                </Col>
                              </div>
                            </div>
                          )}
                          {this.state.event_plateform && (
                            <div className="inner-repeater mb-4">
                              <div className="inner form-group mb-0 row">
                                <Col lg="3">
                                  <Label className="col-form-label">
                                    Disable Event Reminders:
                                  </Label>
                                </Col>
                                <Col
                                  lg={9}
                                  className="inner ms-md-auto"
                                  id="repeater"
                                >
                                  <AvCheckboxGroup inline name="checkbox-group">
                                  <AvCheckbox
                                      label="7 days"
                                      value="true"
                                      onClick={() => {
                                        this.setState({
                                          eventInAWeek:
                                            !this.state.eventInAWeek,
                                        });
                                      }}
                                    />
                                    <AvCheckbox
                                      label="48 Hrs"
                                      value="true"
                                      onClick={() => {
                                        this.setState({
                                          eventTommorow:
                                            !this.state.eventTommorow,
                                        });
                                      }}
                                    />
                                    <AvCheckbox
                                      label="24 Hrs"
                                      value="true"
                                      onClick={() => {
                                        this.setState({
                                          eventToday: !this.state.eventToday,
                                        });
                                      }}
                                    />
                                    <AvCheckbox
                                      label="1 Hr"
                                      value="true"
                                      onClick={() => {
                                        this.setState({
                                          eventInHours:
                                            !this.state.eventInHours,
                                        });
                                      }}
                                    />
                                  </AvCheckboxGroup>
                                  {/* <AvCheckboxGroup
                                    inline
                                    name="reminder-disable"
                                    validate={{ max: { value: 1 } }}
                                  >
                                    <AvCheckbox
                                      value="true"
                                      onClick={() => {
                                        this.setState({
                                          disableReminders:
                                            !this.state.disableReminders,
                                        });
                                      }}
                                    />
                                  </AvCheckboxGroup> */}
                                </Col>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Row className="justify-content-end">
                        <Col lg={9}>
                          <Button
                            type="submit"
                            color="success"
                            disabled={is_saving_event ? true : false}
                          >
                            Create Event
                          </Button>
                          <Button
                            type="reset"
                            color="danger"
                            className="mx-2"
                            onClick={() => this.handleCancelEvent()}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                    {this.state?.error?.description && (
                      // <h6 className="text-danger mt-2 mb-2">
                      //   {this.state?.error?.description}
                      // </h6>
                      <Alert
                        color="danger"
                        className="mt-4"
                        // isOpen={this.state.alert_visible}
                      >
                        {this.state?.error?.description}
                      </Alert>
                    )}
                    {this.state?.error?.panellist && (
                      <Alert color="danger" className="mt-4">
                        {this.state?.error?.panellist}
                      </Alert>
                    )}
                    {!!this.props.error ? (
                      <Alert
                        color="danger"
                        className="mt-4"
                        isOpen={this.state.alert_visible}
                      >
                        {this.props.error}
                      </Alert>
                    ) : null}
                    {!!this.props.success ? (
                      <>
                        <Alert
                          color="success"
                          className="mt-4"
                          isOpen={this.state.alert_visible}
                        >
                          {this.props.success}
                        </Alert>
                      </>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Modal for user group of investors/founders */}
        <UserGroupsModal
          userGroupsModalOpen={this.state.userGroupsModalOpen}
          setUserGroupsModal={this.setUserGroupsModal}
          handleUserGroupsModal={this.handleUserGroupsModal}
          selectedGroupType={
            this.state.groupType === "investor"
              ? this.state.investor_groups
              : this.state.founder_groups
          }
          groupType={this.state.groupType}
          handleGroupsValuesChange={this.handleGroupsValuesChange}
        />

        <Modal
          isOpen={this.state.modal}
          toggle={() => {
            this.toggle();
          }}
          size="xl"
        >
          <ModalHeader toggle={this.toggle} tag="h4">
            {this.state.isInvestors === "investor" ? "Investors list" : ""}
            {this.state.isInvestors === "founder" ? "Founders list" : ""}
            {this.state.isInvestors === "panellist" ? "Panel list" : ""}
          </ModalHeader>
          <FounderList
            toggle={this.toggle}
            getAll={
              this.state.isInvestors === "investor"
                ? this.props.onGetPitchInvestors
                : this.state.isInvestors === "founder"
                ? this.props.onGetPitchFounders
                : this.props.onGetPitchPanellist
            }
            selectedSaasParent={this.state.selectedSaasParent}
            founders={
              this.state.isInvestors === "investor"
                ? this.props.investors
                : this.state.isInvestors === "founder"
                ? this.props.founders.filter(e => e.status === "Approved")
                : this.props.panellist
            }
            selectedFounders={
              this.state.isInvestors === "investor"
                ? this.state.selectedInvestors
                : this.state.isInvestors === "founder"
                ? this.state.selectedFounders
                : this.state.selectedPanellist
            }
            setFounders={this.setFounders}
            isInvestors={this.state.isInvestors}
            isCreate={true}
            outsideInvestor={this.state.outside_investor}
          />
        </Modal>
        <Modal
          isOpen={this.state.group_model}
          toggle={this.toggleGroupModel}
          size="xl"
        >
          <ModalHeader toggle={this.toggleGroupModel} tag="h4">
            Add{" "}
            {this.state.isInvestors === "investor" ? "Investors" : "Founders"}{" "}
            Groups
          </ModalHeader>
          <ModalBody>
            {/* <GroupsList
              usedForEvent={true}
              userRoleType={this.state.isInvestors}
              onSelectGroup={this.selectedGroups}
            /> */}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

PitchDaysCreate.propTypes = {
  usedForEvent: PropTypes.any,
  history: PropTypes.any,
  className: PropTypes.any,
  onAddPitchDay: PropTypes.func,
  pitchday: PropTypes.any,
  error: PropTypes.string,
  success: PropTypes.string,
  onSubmit: PropTypes.func,
  onGetPitchFounders: PropTypes.func,
  onGetPitchInvestors: PropTypes.func,
  onGetPitchPanellist: PropTypes.func,
  panellist: PropTypes.array,
  founders: PropTypes.array,
  investors: PropTypes.array,
};

const mapStateToProps = ({ pitchdays }) => ({
  error: pitchdays.createError,
  success: pitchdays.createSuccess,
  founders: pitchdays.founders,
  investors: pitchdays.investors,
  panellist: pitchdays.panellist,
});

const mapDispatchToProps = dispatch => ({
  onAddPitchDay: pitchday => dispatch(addPitchDay(pitchday)),
  onGetPitchFounders: payload => dispatch(getPitchFounders(payload)),
  onGetPitchInvestors: payload => dispatch(getPitchInvestors(payload)),
  onGetPitchPanellist: payload => dispatch(getPitchPanellist(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PitchDaysCreate));
