import React, { useEffect, useState } from "react";

import {
  addEventImage,
  deleteEventImage,
  fetchAllEventImages,
} from "helpers/backend_helper";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const EventImages = ({ ...props }) => {
  const [allEventImages, setAllEventImages] = useState([]);

  const [addImageModal, setAddImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(null);

  const toggleImageModal = () => {
    setAddImageModal(!addImageModal);

    setSelectedImages([]);
    setFileUploadError(null);
  };

  const fetchAllEventImagesAPI = async reqBody => {
    try {
      let response = await fetchAllEventImages({
        ...reqBody,
      });

      if (!response?.status) {
        setAllEventImages([]);

        return;
      }

      setAllEventImages(response?.data?.eventImages);
    } catch (error) {
      setAllEventImages([]);
    }
  };

  useEffect(() => {
    if (!!props?.postDetails?._id) {
      fetchAllEventImagesAPI({
        postId: props?.postDetails?._id,
      });
    }
  }, [props?.postDetails?._id]);

  const handleSubmitEventImages = async () => {
    try {
      if (!selectedImages?.length) {
        setFileUploadError("Please select atleast one event image");
        return;
      }

      let postId = props?.postDetails?._id;

      let formData = new FormData();
      formData.append("postId", postId);

      for (let image of selectedImages) {
        formData.append("event_images[]", image);
      }

      let response = await addEventImage(formData);

      if (!response?.status) {
        setFileUploadError(
          response?.message || "Error while uploading event images"
        );
        return;
      }

      await fetchAllEventImagesAPI({
        postId,
      });
      alert("Images uploaded");

      toggleImageModal();
    } catch (error) {
      let errorMsg =
        error?.response?.data?.message ||
        error?.message ||
        "Error while uploading event images";
      setFileUploadError(errorMsg);
      return;
    }
  };

  const handleDeleteEventImage = (reqBody = {}) => {
    Swal.fire({
      title: `Are you sure you want to delete this image?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then(async result => {
      try {
        if (result.isConfirmed) {
          let postId = props?.postDetails?._id;
          reqBody = {
            ...reqBody,
            postId,
          };

          let response = await deleteEventImage(reqBody);

          if (!response?.status) {
            alert(response?.message || "Error while deleting event image");
            return;
          }

          await fetchAllEventImagesAPI({
            postId,
          });
        }
      } catch (error) {
        alert(
          error?.response?.data?.message ||
            error?.message ||
            "Error while deleting event image"
        );
      }
    });
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col md={12} className="mb-2">
            <div className="text-end">
              <Button
                color="primary"
                className="font-16 btn-block"
                onClick={() => {
                  toggleImageModal();
                }}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add Event Images
              </Button>
            </div>
          </Col>

          <Col md={12}>
            <Row>
              {allEventImages?.length ? (
                <>
                  {allEventImages?.map(image => (
                    <>
                      <Col
                        xl={4}
                        md={6}
                        xs={12}
                        className="text-center event-images"
                      >
                        <div className="mb-2">
                          <img src={image?.image_url} />
                        </div>
                        <Link
                          className="text-danger"
                          to="#"
                          title="Delete Image"
                        >
                          <i
                            className="bx bx-trash font-size-18"
                            id="edittooltip"
                            onClick={e => {
                              handleDeleteEventImage({
                                eventImageId: image?._id,
                              });
                            }}
                          ></i>
                        </Link>
                      </Col>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <Col md={12} className="text-center">No Images Uploaded Yet</Col>
                </>
              )}
            </Row>
          </Col>
        </Row>

        <Modal
          isOpen={addImageModal}
          toggle={() => {
            toggleImageModal();
          }}
          size="lg"
        >
          <ModalHeader
            toggle={() => {
              toggleImageModal();
            }}
            tag="h4"
          >
            Event Images
          </ModalHeader>

          <ModalBody>
            <Row className="mb-2">
              <Col>
                <Label tag="h5">
                  Select Event Images
                  <br />
                  <small className="text-info">
                    NOTE: Only image/gif files are allowed with maximum image
                    size of 640px * 480px
                  </small>
                </Label>

                <Input
                  id="event_images"
                  name="event_images"
                  type="file"
                  accept="image/*"
                  className="form-control mb-2"
                  placeholder="Select Event Images"
                  multiple={true}
                  onChange={event => {
                    let files = event?.target?.files || [];

                    let _URL = window.URL || window.webkitURL;

                    for (let file of [...files]) {
                      let image = new Image();

                      image.onload = () => {
                        if (image.width !== 640 && image.height !== 480) {
                          setFileUploadError(
                            "Invalid image dimensions, please upload image with mentioned dimensions"
                          );
                        } else {
                          setSelectedImages([...selectedImages, file]);
                        }
                      };

                      image.src = _URL.createObjectURL(file);
                    }
                  }}
                />

                {!!fileUploadError && (
                  <>
                    <Label tag="span" className="text-danger">
                      {fileUploadError}
                    </Label>
                  </>
                )}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col className="d-flex gap-2">
                <Button
                  color="success"
                  size="md"
                  onClick={e => {
                    handleSubmitEventImages();
                  }}
                >
                  Save
                </Button>

                <Button
                  color="danger"
                  size="md"
                  onClick={e => {
                    toggleImageModal();
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default EventImages;
