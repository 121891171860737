import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";

import { AvForm, AvField } from "availity-reactstrap-validation";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { isEmpty } from "lodash";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

function Mentoships(props) {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
  };
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [mentorships, setMentorships] = useState([]);
  const [state, setState] = useState({});
  const token = authHeader();
  async function fetchData() {
    await axios
      .get(process.env.REACT_APP_API_URL + "/api/mentoship/getall", {
        headers: { Authorization: `${token}` },
      })
      .then(async response => {
        await setIsLoaded(true);
        await setMentorships(response.data.mentorships);
      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
      });
  }
  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const togglePost = post => {
    setModal(false);
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/mentoship/toggle/${post._id}`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const updatePost = post => {
    let bodyFormData = new FormData();
    bodyFormData.append("title", post.title);
    bodyFormData.append("description", post.description);
    bodyFormData.append("type", post.type);
    bodyFormData.append("duration", post.duration);
    bodyFormData.append("date", post.date);
    bodyFormData.append("time", post.time);
    bodyFormData.append("active", post.active);
    setModal(false);
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/mentoship/update/${post._id}`,
      data: bodyFormData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async res => {
        await fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const deletePost = id => {
    axios({
      url: process.env.REACT_APP_API_URL + `/api/mentoship/delete/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };
  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "title",
      text: "Title",
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter(),
    },
    {
      dataField: "time",
      text: "Time",
    },
    {
      dataField: "type",
      text: "Workshop Type",
      filter: textFilter(),
    },
    {
      dataField: "duration",
      text: "Duration",
    },
    {
      dataField: "fees",
      text: "Fees",
      filter: textFilter(),
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, mentorpost) => (
        <>{moment(new Date(mentorpost.date)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "active",
      text: "Active",
      formatter: (cellContent, mentorpost) => (
        <p>
          <label
            className="toggleSwitch large"
            onClick={() => togglePost(mentorpost)}
          >
            <input type="checkbox" checked={mentorpost.active} />
            <span>
              <span></span>
              <span></span>
            </span>
            <a></a>
          </label>
        </p>
      ),
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, mentorpost) => (
        <>{moment(new Date(mentorpost.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated On",
      sort: true,
      formatter: (cellContent, mentorpost) => (
        <>{moment(new Date(mentorpost.updatedAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, mentorpost) => (
        <div className="d-flex gap-3">
          {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deletePost(mentorpost._id)}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ];
  const handleRangeFilter = (start, end) => {
    const filteredResult = mentorships.filter(item => {
      const createdAt = moment(new Date(item.createdAt));
      const startedAt = moment(new Date(start));
      const endedAt = moment(new Date(end));
      if (createdAt > startedAt && createdAt < endedAt) {
        return item;
      } else {
        return;
      }
    });
    setMentorships(filteredResult);
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Investor List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Posts" breadcrumbItem="Funding Deals Posts" /> */}
            <Row className="mb-3">
              <Col sm="6">
                <h5 className="text-uppercase">Funding Deals Posts</h5>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <DateRangePicker
                                initialSettings={{
                                  startDate: "12/20/2021",
                                  endDate: "12/30/2021",
                                }}
                                onApply={(event, picker) =>
                                  handleRangeFilter(
                                    picker.startDate._d,
                                    picker.endDate._d
                                  )
                                }
                                onCancel={() => fetchData()}
                              >
                                <button className="btn btn-primary">
                                  Select Range
                                </button>
                              </DateRangePicker>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <PaginationProvider
                        pagination={paginationFactory(options)}
                        keyField="_id"
                        columns={columns}
                        data={mentorships || []}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="_id"
                            columns={columns}
                            data={mentorships || []}
                            search={{
                              searchFormatted: true,
                            }}
                          >
                            {toolkitprops => (
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                keyField="_id"
                                data={mentorships}
                                columns={columns}
                                bordered={false}
                                striped={true}
                                responsive
                                filter={filterFactory()}
                              />
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <Row>
                        <Col xl="12">
                          {!isEmpty(error) ? (
                            <p className="text-danger">Some error occured!</p>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <Modal
                              size="lg"
                              isOpen={modal}
                              className="mb-3"
                              toggle={() => setModal(false)}
                            >
                              <ModalHeader
                                toggle={() => setModal(false)}
                                tag="h4"
                              >
                                {!!isEdit ? "Edit User" : "Add User"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={() => {
                                    isEdit && updatePost(state);
                                  }}
                                  model={isEdit}
                                >
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="Title"
                                          type="hidden"
                                          errorMessage="Invalid title"
                                          value={"null"}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="description"
                                          type="hidden"
                                          errorMessage="Invalid Description"
                                          value="null"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="name"
                                          label="Name (Startup name)"
                                          type="text"
                                          onChange={event => {
                                            setState({
                                              ...state,
                                              title: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid Name"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={state.title || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="lead investor"
                                          label="Lead Investor"
                                          type="text"
                                          onChange={event => {
                                            setState({
                                              ...state,
                                              description: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid Investor"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={state.description || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="co investor platform"
                                          label="Co Investor Platforms"
                                          type="text"
                                          onChange={event => {
                                            setState({
                                              ...state,
                                              co_investor_platform:
                                                event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid Platform"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={state.date || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="amount"
                                          label="Amount"
                                          type="number"
                                          onChange={event => {
                                            setState({
                                              ...state,
                                              amount: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid amount"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={state.time || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="valuation"
                                          label="Valuation"
                                          type="number"
                                          onChange={event => {
                                            setState({
                                              ...state,
                                              valuation: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid valuation"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={state.type || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="valuation"
                                          label="Valuation"
                                          type="number"
                                          onChange={event => {
                                            setState({
                                              ...state,
                                              valuation: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid valuation"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={state.duration || ""}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
Mentoships.propTypes = {
  workshops: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default Mentoships;
