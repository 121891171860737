import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// //PitchDays
import EventList from "../pages/Event/EventList";
import EventCreate from "../pages/Event/CreateEvent";

import GeneralPosts from "../pages/Posts/general-posts";
import UserPosts from "../pages/Posts/user-posts";
import ReportPosts from "../pages/Posts/report-posts";
import FundingDeals from "../pages/FundingDeals/FundingDeals";
import UsersReport from "../pages/Report/UsersReport";
import PayoutReport from "../pages/Report/PayoutReport";

// import UserReport from "../pages/Report/UserReport";

import DocumentRules from "../pages/DocRules/document-rules";
import KPIs from "../pages/Kpis/startup-kpi";
import InvestmentReports from "../pages/InvestmentReports/investment-reports";
import StartupInvestments from "../pages/InvestmentReports/startup-investments";
import StartupList from "../pages/Founders/startup-list";

import AccessDenied from "pages/Authentication/AccessDenied";

//Users
// import UsersList from "../pages/Users/UserList/users-list";
import UsersLists from "../pages/Users/UserList/users-lists";
import KycList from "../pages/Users/UserList/kyc-list";
import deletionRequests from "../pages/Users/UserList/accountDeletionRequests";
import FoundersList from "../pages/Founders/founder-list.js";
import InvestorList from "../pages/Investor/investor-list.js";
import FounderDetails from "../pages/Founders/founderDetails.js";
import GroupsList from "../pages/Users/UserList/users-groups";
import SaasWiseInvestor from "../pages/Users/UserList/saasWiseInvestors";
import VenturePartnersList from "../pages/Users/Partners/VenturePartnersList";
import SyndicateDetails from "../pages/Users/Partners/SyndicateDetails";
import PartnerDetails from "../pages/Users/Partners/PartnerDetails";
import DematList from "pages/Users/UserList/DematList";

// mentoships -> workshops

import Mentoships from "../pages/Mentorships/MentorshipList.js";

// call for money

import CallForMoney from "../pages/CallForMoney/CallForMoney.js";
import CreateCallForMoney from "../pages/CallForMoney/CreateCallForMoney.js";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import subscriptionList from "pages/Subscription/SubscriptionList/subscription-list";
import SaasPayout from "pages/Subscription/SubscriptionList/saas-payout";

import appliedFundingList from "pages/AppliedFunding/AppliedFundingList/applied-funding-list";
import AppliedFundingDetails from "pages/AppliedFunding/ApplyForFundingDetails";

import pitchdayUpdate from "pages/Event/UpdateEvent";
import investorRequestList from "pages/Investor/investorRequestList";
import investorDetails from "pages/Investor/investorDetails";
import createEventReportList from "pages/Event/EventReportList";
// import ViewAssesmentReport from "pages/Event/ViewAssessmentReport";
import ViewAssesmentReport from "pages/Event/EventReport/EventReportDetails";
import ViewEventReport from "pages/Event/EventReport/ViewEventReport";

import generateToken from "pages/Zoom/generateToken";
import currencyConversion from "pages/Users/MultiCurrency/currencyConversion";
import UploadInvsttMedia from "pages/Media/InvsttMedia/UploadInvsttMedia";
import ApproveJobs from "pages/Media/InvsttJobs/ApproveJobs";

import Message from "../pages/Message/message";
import ReportDetails from "pages/Report/ReportDetails";
import InvestorReport from "pages/Report/InvestorReport";
import FounderReport from "pages/Report/FounderReport";
import GeneralMaster from "pages/GeneralMaster";
import AdminSettings from "pages/AccessSettings/AdminSettings";
import FrontendSettings from "pages/AccessSettings/FrontendSettings";

import Credits from "pages/Credits/user-credits";
import dailyDeductCredits from "pages/DailyDeductCredits/dailyDeductCredits";
import CreateWeeklyBrew from "pages/weeklyBrew/CreateWeeklyBrew";
import WeeklyBrewList from "pages/weeklyBrew/WeeklyBrewList";
import WeeklyBrewLogs from "pages/weeklyBrew/WeeklyBrewLogs";
import WeeklyBrewLogsCount from "pages/weeklyBrew/WeeklyBrewLogsCount";

import CreateDemoDay from "pages/demoDay/CreateDemoDay";
import DemoDayList from "pages/demoDay/DemoDayList";
import InvestorCommunication from "pages/InvestorCommunication/investor-communication";
import EventListOptimized from "pages/Event/EventListOptimized";

const authProtectedRoutes = [
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  // Users
  { path: "/users-list", component: UsersLists },
  { path: "/onboarding-list", component: KycList },
  { path: "/demat-list", component: DematList },
  { path: "/deletion-requests", component: deletionRequests },
  { path: "/investor-communication", component: InvestorCommunication },
  // { path: "/users-list", component: UsersList },

  //founder
  { path: "/founders-list", component: FoundersList },
  { path: "/founders-detail/:id", component: FounderDetails },

  // Venture Partners List
  { path: "/partners-list", component: VenturePartnersList },

  // Syndicate Payouts
  { path: "/syndicate-details/:user_id", component: SyndicateDetails },

  // Partners Payouts
  { path: "/partner-details/:user_id", component: PartnerDetails },

  // Report Details
  { path: "/Report-detail", component: ReportDetails },
  // Investor Report
  // { path: "/Investor-report", component: InvestorReport },
  { path: "/Investor-report/:id", component: InvestorReport },

  // Founder-Report
  // { path: "/Founder-report", component: FounderReport },
  { path: "/Founder-report/:id", component: FounderReport },

  //investor
  { path: "/investor-list", component: InvestorList },
  { path: "/investor-req-list", component: investorRequestList },
  { path: "/investor-details/:id", component: investorDetails },

  //grouping
  { path: "/user-groups", component: GroupsList },

  //saas wise investors
  { path: "/saas-wise-investors", component: SaasWiseInvestor },

  // subscription
  { path: "/subscription-list", component: subscriptionList },
  { path: "/saas-payout/:userID", component: SaasPayout },

  // applied funding
  { path: "/applied-funding-list", component: appliedFundingList },
  { path: "/applied-funding-details/:id", component: AppliedFundingDetails },

  // Pitch Days
  { path: "/event", component: EventListOptimized },
  { path: "/create-event", component: EventCreate },
  { path: "/pitchdays-update/:id", component: pitchdayUpdate },
  { path: "/create-event-report-list", component: createEventReportList },
  { path: "/create-event-report/:id", component: ViewAssesmentReport },
  { path: "/event-report/:id", component: ViewEventReport },
  // { path: "/event-attendance-report/:id", component: EventAttendanceReport },

  // posts management
  { path: "/general-posts/", component: GeneralPosts },
  { path: "/user-posts/", component: UserPosts },
  { path: "/report-posts/", component: ReportPosts },
  // funding-deals | commented by PHP
  // { path: "/funding-deals", component: FundingDeals }, | commented by sanket, as was discussed in client meeting

  // mentoships

  { path: "/mentoships", component: Mentoships },
  //daily deduct credits
  { path: "/daily-deduct-credits", component: dailyDeductCredits },

  // call for money
  { path: "/create-call-for-money", component: CreateCallForMoney },
  { path: "/call-for-money/", component: CallForMoney },

  // zoom token management
  { path: "/zoom", component: generateToken },

  // multi currency admin module
  { path: "/currency-conversion", component: currencyConversion },

  // invstt media upload module
  { path: "/upload-invstt-media", component: UploadInvsttMedia },

  // invstt job approval module
  { path: "/approve-invstt-jobs", component: ApproveJobs },
  { path: "/investor-communications", component: InvestorCommunication },

  // document rules
  { path: "/document-rules", component: DocumentRules },
  { path: "/startup-kpi", component: KPIs },
  { path: "/investment-reports", component: InvestmentReports },
  { path: "/startup/investment-details/:sid", component: StartupInvestments },
  { path: "/startup-list", component: StartupList },

  { path: "/message", component: Message },
  // { path: "/funding-deals", component: FundingDeals },

  // Report
  // user-report
  // { path: "/user-report", component: UsersReport },
  { path: "/user-report", component: UsersReport },
  { path: "/payout-report", component: PayoutReport },
  { path: "/general-master", component: GeneralMaster },
  { path: "/admin-settings", component: AdminSettings },
  { path: "/frontend-settings", component: FrontendSettings },
  { path: "/access-denied", component: AccessDenied },
  { path: "/user-credits", component: Credits },
  { path: "/weekly-brew/create", component: CreateWeeklyBrew },
  { path: "/weekly-brew", component: WeeklyBrewList },
  { path: "/weekly-brew/view-logs-count", component: WeeklyBrewLogsCount },
  { path: "/weekly-brew/view-logs", component: WeeklyBrewLogs },

  { path: "/demo-day", component: DemoDayList },
  { path: "/demo-day/create", component: CreateDemoDay },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  // funding-deals | commented by PHP - Remove from here
];

export { authProtectedRoutes, publicRoutes };
