import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Spinner,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// actions
import { apiError, loginUser } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.blue.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import axios from "axios";
import { Country } from "country-state-city";
import { decryptDataFromArray } from "helpers/EncryptDecrypt";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      otpStatus: false,
      otpTimer: false, //not in use for now
      errors: undefined,
      Buttonloading: false,
      success: undefined,
      countryData: this.getCountryData(),
      loginType: "email",
      countryCode: "",
      countryCodeError: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  sendOTP = async () => {
    if (this.state.loginType === "mobile" && this.state.countryCode === "") {
      this.setState({
        countryCodeError: "This Field is Required.",
      });
      return false;
    }

    let ths = this;
    if (!this.state.otpStatus)
      ths.setState({ Buttonloading: true, otpStatus: false });

    let formData = {
      email: this.state.email.trim(),
      adminLogin:true,
    };

    if (this.state.loginType === "mobile") {
      (formData.login_from = "mobile"),
        (formData.mobile_country_code = this.state.countryCode);
      formData.adminLogin = true;
    }

    let config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/auth/sendotp",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(formData),
    };

    await axios(config)
      .then(function (res) {
        let wrong_OTP = res.data.connection_ping;
        // console.log("Connection Ping:", wrong_OTP);
        // console.log("OTP:", res.data.test_otp);
        let successmsg =
          ths.state.loginType === "email"
            ? `OTP sent successfully on your Email Address.`
            : "OTP sent successfully on your Mobile Number.";
        ths.setState({
          Buttonloading: false,
          otpStatus: true,
          success: [successmsg],
        });
      })
      .catch(function (err) {
        let error;
        if (err.response?.data?.message?.errors) {
          error = err.response?.data?.message?.errors;
        } else {
          error = { email: err.response?.data?.message || err.message };
        }

        ths.setState({
          errors: [error.email],
          Buttonloading: false,
          otpStatus: false,
        });
      });
  };

  onSubmit = async e => {
    e.preventDefault();
    let ths = this;
    const { history } = this.props;
    if (!this.state.otp) {
      if (this.state.otpStatus === false) {
        this.sendOTP();
      }
      return false;
    }
    this.setState({ Buttonloading: true });
    localStorage.clear();

    let formData = {
      email: this.state.email.trim(),
      otp: this.state.otp.trim(),
      login_from_log: "admin",
    };

    if (this.state.loginType === "mobile") {
      (formData.login_from = "mobile"),
        (formData.mobile_country_code = this.state.countryCode);
    }

    let config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/auth/admin/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(formData),
    };

    await axios(config)
      .then(function (response) {
        let res = decryptDataFromArray(response?.data)

        if (
          res.userInfo.role === "wfc" ||
          res.userInfo.is_saas_parent ||
          res.userInfo.is_super_admin
        ) {
          ths.setState({ Buttonloading: false });

          localStorage.setItem("authUser", JSON.stringify(res));
          localStorage.setItem("auth_token", res.token);
          localStorage.setItem("user_info", JSON.stringify(res.userInfo));
          localStorage.setItem(
            "user_id",
            JSON.stringify(res.userInfo.user_id)
          );
          localStorage.setItem("logintime", JSON.stringify(new Date()));

          if (ths.state.from) {
            history.push(ths.state.from);
          } else {
            history.push("/dashboard");
          }
        } else {
          ths.setState({
            errors: ["You do not have rights to access this area!"],
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          ths.setState({ errors: [error.response.data?.message] });
        }
      });

    ths.setState({ Buttonloading: false });
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError("");
  }

  handleMobileCountryCodeChange(e) {
    this.setState({
      countryCode: e.value,
      countryCodeError: "",
    });
  }

  getCountryData() {
    let countries = [];
    let label, value;
    let countryData = Country.getAllCountries();
    countryData.sort((a, b) => a.name.localeCompare(b.name));
    countryData.map(country => {
      value = country.phonecode;
      label = country.name;

      if (value.startsWith("+") !== true && value.length > 0) {
        value = "+" + country.phonecode;
      }
      if (value.length > 0) {
        if (value.indexOf("and") !== -1) {
          let value_child = value.split(" and ");
          countries.push(
            {
              label: label + " " + value_child[0],
              value: value_child[0],
              countryValue: country.name,
            },
            {
              label: label + " +" + value_child[1],
              value: "+" + value_child[1],
              countryValue: country.name,
            }
          );
        } else {
          countries.push({
            label: label + " " + value,
            value: value,
            countryValue: country.name,
          });
        }
      }
      return null;
    });
    return countries;
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        // onValidSubmit={this.handleValidSubmit}
                      >
                        {this.state.errors ? (
                          <div
                            className="alert alert-danger fade show"
                            role="alert"
                            dangerouslySetInnerHTML={{
                              __html: this.state.errors,
                            }}
                          ></div>
                        ) : this.state.success ? (
                          <div
                            className="alert alert-success fade show"
                            role="alert"
                            dangerouslySetInnerHTML={{
                              __html: this.state.success,
                            }}
                          ></div>
                        ) : null}

                        {this.state.loginType === "email" && (
                          <>
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email"
                                value=""
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                required
                                disabled={this.state.otpStatus}
                                onChange={e =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </div>
                          </>
                        )}

                        {this.state.loginType === "mobile" && (
                          <>
                            <div className="mb-3">
                              <Label
                                className={`${
                                  this.state.countryCodeError
                                    ? "text-danger"
                                    : ""
                                }`}
                              >
                                Country Code
                              </Label>
                              <Select
                                id="countryCode"
                                options={this.state.countryData}
                                isDisabled={this.state.otpStatus}
                                required
                                // value={this.state.countryCode}
                                onChange={e =>
                                  this.handleMobileCountryCodeChange(e)
                                }
                              />
                              {this.state.countryCodeError && (
                                <span className="text-danger small">
                                  {this.state.countryCodeError}
                                </span>
                              )}
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Mobile No."
                                value=""
                                className="form-control"
                                placeholder="Enter Mobile Number"
                                type="number"
                                disabled={this.state.otpStatus}
                                required
                                onChange={e =>
                                  this.setState({ email: e.target.value })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "Please enter valid mobile number",
                                  },
                                  minLength: {
                                    value: 10,
                                    errorMessage:
                                      "Please enter valid mobile number",
                                  },
                                  maxLength: {
                                    value: 10,
                                    errorMessage:
                                      "Please enter valid mobile number",
                                  },
                                }}
                              />
                            </div>
                          </>
                        )}

                        {this.state.loginType === "mobile" && !this.state.otpStatus && (
                          <>
                            <Link
                              className="col-md-12 mb-2"
                              to="#"
                              onClick={() => {
                                this.setState({
                                loginType : "email",
                                errors:undefined
                                });
                              }}
                            >
                            Not by your Mobile? Click here to login via Email.
                            </Link>
                          </>
                        )} 

                        {this.state.loginType === "email" && !this.state.otpStatus && (
                          <>
                            <Link
                              className="col-md-12 mb-2"
                              to="#"
                              onClick={() => {
                                this.setState({
                                loginType : "mobile",
                                errors:undefined
                                });
                              }}
                            >
                            Not by your Email? Click here to login via Mobile.
                            </Link>
                          </>
                        )} 

                        {this.state.otpStatus === true && (
                          <div className="mb-3">
                            <AvField
                              name="otp"
                              label="Otp"
                              value=""
                              type="text"
                              required
                              placeholder="Enter Otp"
                              onChange={e =>
                                this.setState({ otp: e.target.value })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Didn't receive OTP? &nbsp;
                              <span
                                style={{ cursor: "pointer", color: "#e27235" }}
                                onClick={this.sendOTP}
                              >
                                Request again.
                              </span>
                            </label>
                          </div>
                        )}
                        <div className="mt-3 d-grid">
                          {this.state.otpStatus === false ? (
                            <button
                              className="btn btn-primary btn-block"
                              onClick={this.sendOTP}
                            >
                              {this.state.Buttonloading ? (
                                <Spinner size="sm" />
                              ) : (
                                "Send Otp"
                              )}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-block"
                              onClick={this.onSubmit}
                            >
                              {this.state.Buttonloading ? (
                                <Spinner size="sm" />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          )}
                        </div>

                        {/* <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            value=""
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div> */}

                        <br />
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        {/* <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div> */}

                        {/* <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Forgot your
                            password?
                          </Link>
                        </div> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);
