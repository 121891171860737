import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { displayImage } from "../../helpers/storage_helper";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";

import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { isEmpty } from "lodash";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";

// Quill Editor for Event Description
import ReactQuill from "react-quill";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

//Import Breadcrumb

function DemoDayList() {
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: false,
  };
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [posts, setWeeklyBrewList] = useState([]);
  const [investmentDeal, setInvestmentDeal] = useState([
    { selectedDeal: "", selectedCalendlyLink: "" },
  ]);
  const [status, setStatus] = useState([
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [post, setPost] = useState({
    datetime: "01-01-1971",
  });

  const [dynamicContentImage, setDynamicContentImage] = useState({
    fileName: null,
    file: null,
  });

  const [marketingImgFile, setMarketingImgFile] = useState({
    fileName: null,
    file: null,
  });

  const [postsData, setPostsData] = useState([]);
  const [selectedPostsData, setSelectedPostsData] = useState([]);

  const [access, setAccess] = useState({});

  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;

  function fetchData() {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/demoDay/getDemoDay`, {
        headers: { Authorization: `${token}` },
      })
      .then(
        ({ data }) => {
          // console.log("data.result", data.result)

          setIsLoaded(true);
          setWeeklyBrewList(data.result);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  function fetchFundingDeals() {
    let config = {
      method: "get",
      // url: `${process.env.REACT_APP_API_URL}/api/weeklyBrew/get_timeline_posts`,
      url: `${process.env.REACT_APP_API_URL}/api/applyforfunding/getall_deals?saas_parent_id=null&is_super_admin=true&show_all_saas_data=true`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    axios(config)
      .then(res => {
        let postsData = [];
        if (res.data.status) {
          let deals = res?.data?.data;
          // console.log("dealsDataaaaaaaaaaaaa", deals)

          if (deals.length > 0) {
            for (let deal of deals) {
              let deal_detail = deal?.applyforfundings;
              let deal_lebel =
                deal_detail?.startup_id?.basic_information?.startup_name +
                " (" +
                deal_detail?.funding_information?.funding_round +
                ")";
              let deal_date =
                deal_detail?.funding_information?.deal_date !== undefined ||
                deal_detail?.funding_information?.deal_date !== "undefined"
                  ? " (" +
                    moment(deal_detail?.funding_information?.deal_date).format(
                      "MMM YYYY"
                    ) +
                    ")"
                  : "";

              let sassName = deal?.userDetailsObj?.saas_name;
              let dealDetail = deal_lebel + deal_date + " - " + sassName;
              postsData.push({
                label: dealDetail,
                value: deal_detail._id,
              });
            }
          }
          setPostsData(postsData);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  }

  useEffect(() => {
    fetchData();
    fetchFundingDeals();
    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    return () => {};
  }, []);
  const createPost = async brew => {
    setModal(false);
    let bodyFormData = {
      title: brew.title,
      content: brew.description,
      marketing_content: brew.marketing_content,
      datetime: brew.datetime,
    };
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL + "/api/weeklyBrew/createBrew"}`,
      data: bodyFormData,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        console.log("-----------FOUND DATA----------", res);
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };

  const findFormErrors = () => {
    const newErrors = {};
    // let regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
    // if (post?.demo_day_alias !=='' && (regexExp.test(post?.demo_day_alias) === false)){
    //     // console.log("testing aliassssssssssssss", regexExp.test(alias));
    //     newErrors.demo_day_alias = "Invalid Demo Day Alias.";
    // }

    let host_logo_file_type = dynamicContentImage?.fileName?.split(".").pop();
    // console.log("file typeeeeeeeeeeeeeeeeeeeee", host_logo_file_type)
    if (
      host_logo_file_type &&
      host_logo_file_type !== "jpeg" &&
      host_logo_file_type !== "jpg" &&
      host_logo_file_type !== "png"
    ) {
      newErrors.demo_day_host_logo = "Invalid file type selected.";
    }

    let file_type = marketingImgFile?.fileName?.split(".").pop();
    // console.log("file typeeeeeeeeeeeeeeeeeeeee", file_type)
    if (
      file_type &&
      file_type !== "jpeg" &&
      file_type !== "jpg" &&
      file_type !== "png"
    ) {
      newErrors.demo_day_banner = "Invalid file type selected.";
    }

    let scriptReg = /<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/;

    if (
      !post?.description ||
      post?.description === "" ||
      scriptReg.test(post?.description) == false
    ) {
      console.log("testtest", post?.description);
      newErrors.description = "The Description is required.";
    }

    let blankDeals = investmentDeal.filter(item => item.selectedDeal == "");
    if (blankDeals.length > 0) {
      // if (investmentDeal[0].selectedDeal== ""){
      newErrors.investmentDeal = "The Investment Deal is required.";
    }

    let dealid = investmentDeal.filter(item => item.selectedDeal !== "");
    console.log("investmentDeal", dealid);

    if (dealid.length > 0) {
      let newData = [];
      let idArr = [];
      let isDuplicate = false;

      dealid.forEach(item => {
        if (item.selectedDeal && !idArr.includes(item.selectedDeal)) {
          idArr.push(item.selectedDeal);
        } else {
          isDuplicate = true;
        }
      });

      if (isDuplicate === true) {
        newErrors.investmentDeal = "The Investment Deal is duplicate.";
      }
    }

    // if (!datetime || datetime === "")
    //   newErrors.datetime = "The Scheduled Date is required.";

    return newErrors;
  };

  const updatePost = async demo_day => {
    let user_detail = localStorage.getItem("user_info")
      ? JSON.parse(localStorage.getItem("user_info"))
      : {};
    let created_by = user_detail?.firstname + " " + user_detail?.lastname;
    let created_user_mail_id = user_detail?.email;

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log("error falsessssssssssssssssss");

      const formData = new FormData();
      let data = {
        title: demo_day.title,
        demo_day_alias: demo_day.demo_day_alias,
        description: demo_day.description,
        status: selectedStatus?.value,
        created_by: created_by,
        created_user_mail_id: created_user_mail_id,
        deals: JSON.stringify(investmentDeal),
      }

      // formData.append('marketingImgFile', marketingImgFile);
      // formData.append("deals", JSON.stringify(investmentDeal));

      if (marketingImgFile?.file) {
        formData.append("demo_day_banner", marketingImgFile?.file);
      }
      if (dynamicContentImage?.file) {
        formData.append("demo_day_host_logo", dynamicContentImage?.file);
      }

      let encryptedQuery = encryptDataToArray(data)
      formData.append("q", JSON.stringify(encryptedQuery));

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/demoDay/updateDemoDay/${demo_day._id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        data: formData,
      };
      await axios(config)
        .then(res => {
          // console.log("resp", res.data);
          if (res.data.status == true) {
            setErrorMessage("");
            setSuccessMessage("Demo day updated successfully!");
            fetchData();
            setTimeout(function () {
              setModal(false);
            }, 4000);
          } else {
            console.log("update failed", res.data.status);
            setErrorMessage(res.data.message);
          }
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };

  const fetchValues = posts => {
    let allPostID = [];
    for (let post of posts) {
      // console.log("postsDataaaaaaaaaa", post)
      allPostID.push(post.value);
    }
    return allPostID;

    // console.log("changed data", e[0].value)
  };
  const filterRecords = postIDs => {
    let posts = postsData;
    let selectedPosts = [];
    postIDs.forEach(item => {
      selectedPosts.push({
        selectedDeal: item?.selectedDeal,
        selectedCalendlyLink: item?.selectedCalendlyLink,
      });
      //  if(postIDs.includes(item.value))
      //  selectedPosts.push(item);
    });

    return selectedPosts;
  };

  // Function for change Input Fields for IndustrySector
  const handleChangeIndustrySector = (e, index) => {
    // console.log("checking index value",index)
    // console.log("checking e value",e)

    const sectors = investmentDeal;
    if (e.currentTarget?.name == "calendly_link") {
      sectors[index]["selectedCalendlyLink"] = e.currentTarget.value;
    } else {
      sectors[index]["selectedDeal"] = e.value;
    }
    setInvestmentDeal([...sectors]);
  };

  // Function for Remove Input Fields for IndustrySector
  const handleRemoveIndustrySectors = index => {
    var values = investmentDeal;
    values.splice(index, 1);
    // console.log("splice index", values)

    setInvestmentDeal([...values]);
  };

  //handle copy enablex moderator link
  const handleCopyEnablexLink = async link => {
    const unsecuredCopyToClipboard = text => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        // console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };

    let front_url = process.env.REACT_APP_FRONTEND_URL + "/demo-day/" + link;

    if (window?.isSecureContext && navigator?.clipboard) {
      await navigator?.clipboard?.writeText(front_url);
    } else {
      unsecuredCopyToClipboard(front_url);
    }
  };

  const handleStatusChange = e => {
    setSelectedStatus(e);
  };

  // Function for Create Input Fields for Investment Deals
  const handleAddIndustrySectors = e => {
    setInvestmentDeal([
      ...investmentDeal,
      { selectedDeal: "", selectedCalendlyLink: "" },
    ]);
  };

  // setOptions([...options, { sno: newArray.length + 1, text: "" }]);

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },
    {
      dataField: "title",
      text: "Demo Day Title",
    },
    {
      dataField: "demo_day_alias",
      text: "Alias",
      // formatter: (cellContent, post) => (
      //     <div className="react-quill-preview" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: post.content }}></div>
      // ),
    },
    {
      dataField: "demo_day_host_logo",
      text: "Host Logo",
      formatter: (cellContent, post) => (
        <>
          {post.demo_day_host_logo && (
            <img
              src={post.demo_day_host_logo}
              className="img rounded"
              width="70px"
            />
          )}
        </>
      ),
    },
    {
      dataField: "demo_day_banner",
      text: "Banner",
      formatter: (cellContent, post) => (
        <>
          {post.demo_day_banner && (
            <img
              src={post.demo_day_banner}
              className="img rounded"
              width="70px"
            />
          )}
        </>
      ),
    },
    {
      dataField: "created_by",
      text: "Created By",
      sort: false,
      formatter: (cellContent, post) => (
        <>{post.created_by ? post.created_by : "Super Admin"}</>
      ),
    },

    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, post) => (
        <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
      ),
    },

    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, post) => (
        // <>{post?.status == '1' ? 'Active': post?.status[0].toUpperCase() + post?.status.substring(1)
        <>{post?.status == "1" ? "Active" : "In Active"}</>
      ),
    },
    access?.demo_day_edit
      ? {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, post) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  title="Edit"
                  onClick={() => {
                    setErrors({});
                    if (is_super_admin === true) {
                      // setPost({ ...post, datetime: moment(post.datetime).format('DD-MM-YYYY')});
                      let selectedPost = filterRecords(post?.deals);

                      setInvestmentDeal(selectedPost);
                      setSelectedStatus({
                        label: post?.status == "0" ? "In Active" : "Active",
                        value: post?.status,
                      });
                      setPost(post);
                      setSuccessMessage("");
                      setErrorMessage("");
                      setModal(true);
                      setIsEdit(true);
                    } else {
                      // setPost({ ...post, datetime: moment(post.datetime).format('DD-MM-YYYY')});
                      let selectedPost = filterRecords(post?.deals);
                      setInvestmentDeal(selectedPost);
                      setSelectedStatus({
                        label: post?.status == "0" ? "In Active" : "Active",
                        value: post?.status,
                      });
                      setSuccessMessage("");
                      setErrorMessage("");
                      setPost(post);
                      setModal(true);
                      setIsEdit(true);
                    }
                  }}
                ></i>
              </Link>

              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-content-copy font-size-18"
                  id="editooltip2"
                  title="Copy demo day link"
                  onClick={() => handleCopyEnablexLink(post?.demo_day_alias)}
                  style={post?.status == "1" ? null : { color: "grey" }}
                ></i>
              </Link>

              {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deletePost(post._id)}
            ></i>
          </Link> */}

              {/* <Link className="font-16 btn-block btn btn-secondary justify-content-center" to={"/weekly-brew/view-logs-count?brew_date="+ moment(post?.newDateTime).format("DD MMM Y")+"&datetime="+post?.newDateTime}>View Logs</Link> */}
            </div>
          ),
        }
      : "",
  ];

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Posts | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          <Row className="d-flex justify-content-between align-items-center">
            <Col sm="6">
              <h5 className="text-uppercase mb-0">Demo Day List</h5>
            </Col>
            <Col sm="3">
              {access?.demo_day_edit && (
                <div className="text-sm-end mx-4">
                  <Link
                    className="font-16 btn-block btn btn-primary justify-content-center"
                    to="/demo-day/create"
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create Demo Day
                  </Link>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                {/* {console.log(posts, "postOffer")} */}
                <DataFetchLoader loading={posts ? false : true} />
                <CardBody>
                  <React.Fragment>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={posts || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={posts || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={posts}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) ? (
                          <p className="text-danger">Some error occured!</p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Modal
                            size="lg"
                            isOpen={modal}
                            className="mb-3"
                            toggle={() => setModal(false)}
                          >
                            <ModalHeader
                              toggle={() => setModal(false)}
                              tag="h4"
                            >
                              {!!isEdit ? "Edit Demo Day" : "Add Demo Day"}
                            </ModalHeader>
                            <ModalBody>
                              <AvForm
                                onValidSubmit={() => {
                                  isEdit ? updatePost(post) : createPost(post);
                                }}
                                model={
                                  post === undefined ? defaultValues : post
                                }
                              >
                                <Row form>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label>
                                        Title{" "}
                                        <span className="text-danger">*</span>
                                      </Label>

                                      <AvField
                                        name="title"
                                        // label="Title "
                                        type="text"
                                        errorMessage="The Title is required"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            title: event.target.value,
                                          });
                                        }}
                                        value={post.title || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label>
                                        Alias{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <AvField
                                        name="demo_day_alias"
                                        // label="Alias"
                                        type="text"
                                        errorMessage="The Alias is required"
                                        validate={{
                                          required: { value: true },
                                          pattern: {
                                            value: "^[a-z0-9]+(?:-[a-z0-9]+)*$",
                                            errorMessage:
                                              "Invalid Demo Day Alias.",
                                          },
                                        }}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            demo_day_alias: event.target.value,
                                          });
                                        }}
                                        value={post.demo_day_alias || ""}
                                      />

                                      <small>
                                        <div className="text-danger">
                                          {errors?.demo_day_alias}
                                        </div>
                                        <strong>Note:</strong> (Only small
                                        letter and dash(-) are allowed. E.g :
                                        blume-ventures)
                                      </small>
                                    </div>

                                    <div className="mb-3">
                                      <Label>
                                        Description{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <ReactQuill
                                        id="description"
                                        style={{ height: "150px" }}
                                        placeholder="Enter Description"
                                        defaultValue={post.description || ""}
                                        onChange={event => {
                                          setPost({
                                            ...post,
                                            description: event,
                                          });
                                        }}
                                        modules={eventDescriptionEditorModules}
                                        formats={eventDescriptionEditorFormats}
                                      />
                                      <br></br>
                                      <br></br>

                                      <label>
                                        <small>
                                          <div className="text-danger">
                                            {errors?.description}
                                          </div>
                                        </small>
                                      </label>
                                    </div>

                                    <div className="mb-3">
                                      <Label>
                                        Host Logo{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        type="file"
                                        accept=".jpeg, .png, .jpg"
                                        className="form-control"
                                        placeholder="Select Host Logo"
                                        onChange={event => {
                                          setDynamicContentImage({
                                            fileName: event.target.value,
                                            file: event.target.files[0],
                                          });
                                        }}
                                        multiple={false}
                                      />
                                      <small>
                                        <div className="text-danger">
                                          {errors?.demo_day_host_logo}
                                        </div>
                                        <strong>Note:</strong> (Only jpg, jpeg,
                                        png files are allowed. Standard Size:
                                        127px*45px)
                                      </small>

                                      <br></br>
                                      {post?.demo_day_host_logo && (
                                        <img
                                          src={post?.demo_day_host_logo}
                                          className="img rounded"
                                          width="100px"
                                        />
                                      )}
                                    </div>

                                    <div className="mb-3">
                                      <Label>
                                        Demo day banner{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        type="file"
                                        accept=".jpeg, .png, .jpg"
                                        className="form-control"
                                        placeholder="Select Favicon Logo"
                                        onChange={event => {
                                          setMarketingImgFile({
                                            fileName: event.target.value,
                                            file: event.target.files[0],
                                          });
                                        }}
                                        multiple={false}
                                      />
                                      <small>
                                        <div className="text-danger">
                                          {errors?.demo_day_banner}
                                        </div>
                                        <strong>Note:</strong> (Only jpg, jpeg,
                                        png files are allowed. Standard Size:
                                        1920px*400px)
                                      </small>
                                      <br></br>
                                      {post?.demo_day_banner && (
                                        <img
                                          src={post?.demo_day_banner}
                                          className="img rounded"
                                          width="100px"
                                        />
                                      )}
                                    </div>
                                    <div className="pb-3">
                                      <Label>
                                        Investment Deals:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      {/* {console.log("investmentDeal data list", investmentDeal)} */}

                                      {investmentDeal.map((field, key) => (
                                        <Fragment key={"_field_" + key}>
                                          <div className="mb-3 row align-items-center">
                                            <Col md="5">
                                              <Select
                                                id="selectedGroups"
                                                placeholder="Investment Deals"
                                                name="deals"
                                                options={postsData}
                                                // styles={styles}
                                                value={postsData.filter(
                                                  function (option) {
                                                    return (
                                                      option.value ===
                                                      field.selectedDeal
                                                    );
                                                  }
                                                )}
                                                onChange={e =>
                                                  handleChangeIndustrySector(
                                                    e,
                                                    key
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col md="5">
                                              <AvField
                                                id="calendly_link"
                                                type="text"
                                                name="calendly_link"
                                                className="inner form-control"
                                                placeholder="Calendly link"
                                                value={
                                                  field.selectedCalendlyLink
                                                }
                                                onChange={e =>
                                                  handleChangeIndustrySector(
                                                    e,
                                                    key
                                                  )
                                                }
                                              />
                                            </Col>

                                            <Col md="2">
                                              <div className="mt-2 mt-md-0 d-grid">
                                                <Button
                                                  color="danger"
                                                  title="Remove"
                                                  disabled={
                                                    investmentDeal.length < 2
                                                      ? true
                                                      : false
                                                  }
                                                  className="inner"
                                                  onClick={() => {
                                                    handleRemoveIndustrySectors(
                                                      key
                                                    );
                                                  }}
                                                  block
                                                >
                                                  <i className="bx bx-trash fs-3"></i>
                                                </Button>
                                              </div>
                                            </Col>
                                          </div>
                                        </Fragment>
                                      ))}
                                      <small>
                                        <div className="text-danger">
                                          {errors?.investmentDeal}
                                        </div>
                                      </small>

                                      <Button
                                        color="success"
                                        title="Add More"
                                        className="inner"
                                        onClick={handleAddIndustrySectors}
                                        // onChange={posts => handlePostChange(posts || [])}
                                      >
                                        <i className="bx bx-plus-circle fs-3"></i>
                                      </Button>
                                    </div>

                                    <div className="mb-3">
                                      <Label>
                                        Status:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Row>
                                        <Col lg="5">
                                          <Select
                                            id="statusaaaa"
                                            placeholder="Select Status"
                                            name="status"
                                            className="mb-3"
                                            options={status}
                                            value={selectedStatus}
                                            onChange={status_data =>
                                              handleStatusChange(status_data)
                                            }

                                            // value={post.status}
                                            // onChange={event => {
                                            //     setPost({
                                            //         ...post,
                                            //         status: event.target.value,
                                            //     });
                                            // }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>

                                    {successMessage && (
                                      <div
                                        class="alert alert-success"
                                        role="alert"
                                      >
                                        {successMessage}
                                      </div>
                                    )}

                                    {errorMessage && (
                                      <div
                                        class="alert alert-danger"
                                        role="alert"
                                      >
                                        {errorMessage}
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </ModalBody>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
DemoDayList.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default DemoDayList;
