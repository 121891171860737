import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { Form as Form1, Popover, OverlayTrigger } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

import {
  getAllVenturePartners,
  getAllPartnersForEmployees,
  updatePartnerUserInfoUserList,
  getAllUsersForReference,
  toCommaFormat,
  getPartnerAdditionalDetails,
  verifyAdminEmailOTP,
} from "../../../helpers/backend_helper";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { loginAsUser } from "../../../helpers/backend_helper";

// Loader for data fetch
import DataFetchLoader from "../../../components/Common/DataFetchLoader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

const VenturePartnersList = () => {
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortQuery, setSortQuery] = useState("createdAt=-1");
  const upArrow = <i className="bx bx-up-arrow-alt fs-5"></i>;
  const downArrow = <i className="bx bx-down-arrow-alt fs-5"></i>;
  const sortOptions = [
    {
      value: "createdAt=-1",
      label: (
        <>
          <span>{downArrow} Created On</span>
        </>
      ),
    },
    {
      value: "createdAt=1",
      label: (
        <>
          <span>{upArrow} Created On</span>
        </>
      ),
    },
    {
      value: "name=-1",
      label: (
        <>
          <span>{downArrow} Name</span>
        </>
      ),
    },
    {
      value: "name=1",
      label: (
        <>
          <span>{upArrow} Name</span>
        </>
      ),
    },
    {
      value: "email=-1",
      label: (
        <>
          <span>{downArrow} Email</span>
        </>
      ),
    },
    {
      value: "email=1",
      label: (
        <>
          <span>{upArrow} Email</span>
        </>
      ),
    },
    {
      value: "status=-1",
      label: (
        <>
          <span>{downArrow} Status</span>
        </>
      ),
    },
    {
      value: "status=1",
      label: (
        <>
          <span>{downArrow} Status</span>
        </>
      ),
    },
  ];

  const [savedFlag, setSavedFlag] = useState(false);

  const [venturePartnersList, setVenturePartnersList] = useState([]);

  const [userData, setUserData] = useState({});
  const [editModal, setEditModal] = useState(false);

  const [allRefPartners, setAllRefPartners] = useState([]);
  const [selectedRefPartner, setSelectedRefPartner] = useState(null);

  const [saasPartnersList, setSaasPartnersList] = useState([]);

  const [expandedRows, setExpandedRows] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment().set({ month: 8, date: 1, year: 2022 }).format()
    ),
    endDate: new Date(moment().format()),
  });
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [details, setDetails] = useState({});

  const [rangefilter, setRangefilter] = useState(0);

  const fetchPartnerDetails = async userId => {
    if (!userId) {
      return;
    }
    try {
      setDetailsLoading(true);

      let response = await getPartnerAdditionalDetails({
        userId,
        fromDate: moment(dateRange.startDate)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        toDate: moment(dateRange.endDate)
          .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
          .format(),
      });

      if (!response?.status) {
        let message =
          response?.message || "Error while fetching partner details";
        setDetails({});
        setDetailsLoading(false);
        alert(message);
        return;
      }

      let data = response?.data || {};
      setDetails(data);
      setDetailsLoading(false);
      return;
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while fetching partner details";
      setDetails({});
      setDetailsLoading(false);
      alert(message);
      return;
    }
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const closeModalSuccess = () => {
    setUserData({});
    setSavedFlag(!savedFlag);
  };

  useEffect(() => {
    setLoading(true);
    getAllVenturePartners(
      page,
      limit,
      searchText,
      sortQuery,
      dateRange.startDate,
      dateRange.endDate
    )
      .then(response => {
        if (response?.status) {
          let { venturePartners, totalData } = response.data;
          setVenturePartnersList(venturePartners);
          setTotal(totalData);
        } else {
          setVenturePartnersList([]);
          setTotal(0);
          alert(response?.message || "Error while fetching venture partners");
        }
      })
      .catch(error => {
        setVenturePartnersList([]);
        setTotal(0);
        alert(error?.message || "Error while fetching venture partners");
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, [page, limit, searchText, sortQuery, savedFlag, rangefilter]);

  useEffect(() => {
    if (!editModal) {
      getAllPartnersForEmployees()
        .then(response => {
          let partners = response.data || [];
          partners = partners
            .map(partner => {
              if (!partner) return null;
              // let {
              //   _id: partnerId,
              //   user_id: { _id, userName, firstname, lastname, email },
              // } = partner;
              let {
                _id,
                userName,
                firstname,
                lastname,
                email,
                is_saas_parent,
                saas_fullname,
                saas_name,
                saas_alias,
              } = partner;
              if (!_id) return null;
              let temp =
                `${firstname || ""} ${lastname || ""}`.trim() || userName;
              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }
              if (!temp) return null;

              if (is_saas_parent) {
                let temp2 = saas_fullname;
                if (temp2) {
                  temp2 += ` (${saas_name})`;
                } else {
                  temp2 = saas_alias;
                }
                temp = temp2 || temp;
              }

              return { value: _id, label: temp };
            })
            .filter(data => data);
          setAllRefPartners(partners);
        })
        .catch(error => {
          console.trace(error);
          setAllRefPartners([]);
        });
    }
    return () => {};
  }, [editModal]);

  useEffect(() => {
    if (!editModal) {
      getAllUsersForReference()
        .then(response => {
          let users = response.data;
          let saasPartners = [];
          users = users
            .map(user => {
              if (!user) return null;

              let { _id, firstname, lastname, email } = user;

              if (!_id) return null;

              let temp = `${firstname || ""} ${lastname || ""}`.trim();

              if (temp) {
                temp = `${temp} ${email ? "- " + email : ""}`.trim();
              } else {
                temp = email || "";
              }

              if (!temp) return null;

              if (user?.is_saas_parent) {
                saasPartners.push({
                  value: _id,
                  label: temp,
                });
              }

              return { value: _id, label: temp };
            })
            .filter(data => !!data);
          // setReferenceUserList(users);
          setSaasPartnersList(saasPartners);
        })
        .catch(error => {
          console.trace(error);
          // setReferenceUserList([]);
          setSaasPartnersList([]);
        });
    }
    return () => {};
  }, [editModal]);

  const handleUserLogin = async user => {
    try {
      let flag = await verifyAdminEmailOTP();

      if (!flag) {
        alert("Error while logging in to the user.");
        return;
      }

      //login admin as user
      let url = process.env.REACT_APP_FRONTEND_URL;
      if (!url) {
        url = window.location.protocol + "//" + window.location.hostname;
      }

      let loggedUser = await loginAsUser({ userId: user._id });

      if (loggedUser) {
        window.open(url + "/loginAsUser/" + loggedUser.token);
      }
    } catch (err) {
      // console.log(err.response);
      return false;
    }
  };

  const handleUserClick = async user => {
    if (!user?.user) {
      return alert("User data cannot be edited");
    }

    setUserData(user);

    let userId = user?.user?._id;
    let tempList = saasPartnersList.filter(user => user.value !== userId);
    setSaasPartnersList(tempList);

    // setAllRefPartners(
    //   allRefPartners.filter(partner => partner?.value !== userId)
    // );

    let tempRefPartner = {
      value: null,
      label: "Select Partner",
    };
    let value = user?.reference_user_id?.[0] || null;
    if (value) {
      let label = allRefPartners.find(part => part?.value === value)?.label;
      if (!label) {
        value = null;
        label = "Select Partner";
      }
      tempRefPartner = { value, label };
    }
    setSelectedRefPartner(tempRefPartner?.value ? tempRefPartner : null);

    setEditModal(true);
  };

  const defaultSorted = [
    {
      dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const userListColumns = [
    {
      text: "id",
      dataField: "_id",
      sort: false,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      text: "Name",
      dataField: "firstname",
      sort: false,
      formatter: (cellContent, { user }) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.firstname} {user.lastname}
            </Link>
          </h5>
        </>
      ),
    },
    {
      dataField: "user.email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "user.saas_parent",
      text: "SaaS Name",
      sort: false,
      formatter: (cellContent, user) => {
        let value = user?.user?.saas_parent?.saas_name;

        if (user?.user?.is_saas_parent) {
          value = `Parent - ${user?.user?.saas_name}`;
        }
        return <>{value}</>;
      },
    },
    {
      dataField: "user_types[0].user_type[0]",
      text: "User Type",
      sort: false,
      formatter: (cellContent, { user }) => (
        <div className="text-capitalize">
          {user?.user_types
            ?.map(userType => userType?.user_type?.[0] || "")
            .filter(data => !!data)
            .join(", ")}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, { user }) => {
        let status = user?.status?.toLowerCase();
        if (status === "declined") status = "disabled";
        return <div className="text-capitalize">{status}</div>;
      },
    },
    {
      dataField: "email_verified",
      text: "Email Verified",
      sort: false,
      formatter: (cellContent, { user }) => (
        <>
          {user.email_verified.charAt(0).toUpperCase() +
            user.email_verified.slice(1)}
        </>
      ),
    },
    // {
    //   dataField: "total_payout",
    //   text: "Total Payout",
    //   sort: false,
    //   formatter: (cellContent, { user }) => (
    //     <>{toCommaFormat(user?.total_payout || 0)}</>
    //   ),
    // },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: false,
      formatter: (cellContent, { user }) => (
        <>{moment(user.createdAt).format("DD MMM YYYY")}</>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, user) => {
        let isParent = user?.user?.is_saas_parent;

        let partnerInfo = {};

        if (isParent) {
          let {
            registration_credits,
            subscription_fee,
            amounts,
            visible_startups,
            visible_investors,
            currency: { symbol },
          } = user?.user || {};

          let registrationCredits = toCommaFormat(
            parseInt(registration_credits || 0) || 0
          );

          let bpCredits = parseInt(subscription_fee?.annually_amount || 0) || 0;
          let bpAmount = parseInt(amounts?.annually_amount || 0) || 0;
          let spCredits =
            parseInt(subscription_fee?.quarterly_amount || 0) || 0;
          let spAmount = parseInt(amounts?.quarterly_amount || 0) || 0;

          let businessPack = "None";
          let sachetPack = "None";

          if (bpCredits && bpAmount) {
            businessPack = `${toCommaFormat(
              bpCredits
            )} Credits For ${symbol} ${toCommaFormat(bpAmount)}`;
          }

          if (spCredits && spAmount) {
            sachetPack = `${toCommaFormat(
              spCredits
            )} Credits For ${symbol} ${toCommaFormat(spAmount)}`;
          }

          let dailyCredits = toCommaFormat(
            parseInt(subscription_fee?.per_day_deduction || 0) || 0
          );

          let startupsVisible =
            visible_startups
              ?.map(startup => startup?.saas_fullname)
              ?.filter(data => data)
              ?.join(", ") || "None";

          let investorsVisible =
            visible_investors
              ?.map(investor => investor?.saas_fullname)
              ?.filter(data => data)
              ?.join(", ") || "None";

          partnerInfo = {
            "Registration Credits": registrationCredits,
            "Business Pack": businessPack,
            "Sachet Pack": sachetPack,
            "Daily Credits": dailyCredits,
            "Startups Visible": startupsVisible,
            "Investors Visible": investorsVisible,
          };
        } else {
          let {
            apply_parent_settings,
            level_1_settings: { subscription_fee, carry_fee },
            transaction_fee_disc_self_investments,
            reference_user_id,
          } = user;
          let subscriptionFee = subscription_fee?.amount;
          let carryFee = carry_fee?.amount;

          let referenceUserId = reference_user_id?.[0];
          let referenceUser = venturePartnersList?.find(
            partner =>
              partner?.user?._id?.toString() === referenceUserId?.toString()
          );

          subscriptionFee = toCommaFormat(parseInt(subscriptionFee || 0) || 0);
          carryFee = toCommaFormat(parseInt(carryFee || 0) || 0);

          let selfRefund = toCommaFormat(
            parseInt(transaction_fee_disc_self_investments || 0) || 0
          );
          let referenceUserName = "None";

          if (referenceUser) {
            if (apply_parent_settings) {
              subscriptionFee = toCommaFormat(
                parseInt(
                  referenceUser?.level_1_settings?.subscription_fee?.amount || 0
                ) || 0
              );
              carryFee = toCommaFormat(
                parseInt(
                  referenceUser?.level_1_settings?.carry_fee?.amount || 0
                ) || 0
              );
            }

            let { firstname, lastname, userName } = referenceUser?.user || {};
            referenceUserName =
              `${firstname || ""} ${lastname || ""}`.trim() || userName;
          }

          partnerInfo = {
            "Subscription Fee (%)": subscriptionFee,
            "Carry Fee (%)": carryFee,
            "Refund for transaction fee on self investment": selfRefund,
            "Reference Partner": referenceUserName,
          };
        }
        return (
          <div className="d-flex gap-2">
            <Link className="text-success" to="#" title="Login with this user">
              <i
                className="mdi mdi-share font-size-18"
                id="logintooltip"
                onClick={() => handleUserLogin(user.user)}
              ></i>
            </Link>
            <Link className="text-success" to="#" title="Edit User Data">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => handleUserClick(user)}
              ></i>
            </Link>
            <Link
              className="text-success"
              to={`/partner-details/${user.user._id}`}
              title="See Payments"
            >
              <i
                className="mdi mdi-currency-inr font-size-18"
                id="edittooltip"
              ></i>
            </Link>
            <OverlayTrigger
              trigger="focus"
              placement="auto"
              flip
              overlay={
                <Popover
                  id={`popover-basic-${user._id}`}
                  style={{
                    minWidth: 400,
                  }}
                >
                  <Popover.Header>Partner Details</Popover.Header>
                  <Popover.Body>
                    <Card className="mb-0">
                      <CardBody className="p-1">
                        <table>
                          <tbody>
                            {Object.keys(partnerInfo).map(key => {
                              let value = partnerInfo[key];
                              return (
                                <>
                                  <tr key={key} className="align-top">
                                    <th colSpan={3} className="pe-1">
                                      {key}
                                    </th>
                                    <td colSpan={4} className="ps-1">
                                      {value}
                                    </td>
                                  </tr>
                                  <tr>&nbsp;</tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Popover.Body>
                </Popover>
              }
            >
              <Link
                className="text-success"
                to="#"
                title="Click to view details"
                onClick={e => {}}
              >
                <i className="mdi mdi-eye font-size-18"></i>
              </Link>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
      setLimit(size);
    },
    onSizePerPageChange: (page, size) => {
      setPage(page);
      setLimit(size);
    },
    onSortChange: (...args) => {
      console.log(args);
    },
  };

  const handleValidEditPartnerSubmit = async event => {
    event.preventDefault();

    let userId = userData?.user?._id;
    if (!userId) {
      return alert("Invalid User Id");
    }

    try {
      if (!userData?.user?.is_saas_parent && !selectedRefPartner?.value) {
        return alert("Please Select Reference Partner");
      }

      if (
        userData?.user?.is_saas_parent ||
        (userData?.can_get_payout && !userData?.apply_parent_settings)
      ) {
        let level_1_settings = userData?.level_1_settings || {};
        let { transaction_fee, subscription_fee, carry_fee } = level_1_settings;
        if (transaction_fee) {
          let { amount } = transaction_fee;
          if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
            return alert(
              "Please enter % of referral transaction fee between 0-100"
            );
          }
        }

        if (subscription_fee) {
          let { amount } = subscription_fee;
          if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
            return alert("Please enter subscription fee % between 0-100");
          }
        }

        if (carry_fee) {
          let { amount } = carry_fee;
          if (amount && (parseInt(amount) > 100 || parseInt(amount) < 0)) {
            return alert("Please enter carry fee % between 0-100");
          }
        }

        let credits_per_lac_of_investments =
          userData?.credits_per_lac_of_investments || {};

        let { for_self, for_referral } = credits_per_lac_of_investments;

        if (for_self && parseInt(for_self) < 0) {
          return alert(
            "Please enter credits for self investments greater than 0"
          );
        }

        if (for_referral && parseInt(for_referral) < 0) {
          return alert(
            "Please enter credits for referral investments greater than 0"
          );
        }
      }

      let transaction_fee_disc_self_investments =
        userData?.transaction_fee_disc_self_investments;

      if (
        transaction_fee_disc_self_investments &&
        (parseInt(transaction_fee_disc_self_investments) < 0 ||
          parseInt(transaction_fee_disc_self_investments) > 100)
      ) {
        return alert(
          "Please enter transaction fee refund % for self investments between 0-100"
        );
      }

      let reqBody = {};

      reqBody["isPartner"] = true;
      reqBody["partnerType"] = "partner";
      reqBody["level_1_settings"] = userData?.level_1_settings;
      reqBody["refPartnerId"] = selectedRefPartner?.value || null;
      // reqBody["see_all_startups"] = userData?.see_all_startups;
      // reqBody["see_all_investors"] = userData?.see_all_investors;
      reqBody["credits_per_lac_of_investments"] =
        userData?.credits_per_lac_of_investments;
      reqBody["transaction_fee_disc_self_investments"] =
        userData?.transaction_fee_disc_self_investments;
      reqBody["display_collection"] = userData?.display_collection;
      reqBody["display_commision"] = userData?.display_commision;
      reqBody["can_get_payout"] = userData?.can_get_payout;
      reqBody["apply_parent_settings"] = userData?.apply_parent_settings;
      reqBody["is_vp"] = userData?.is_vp;
      reqBody["vp_individual"] = userData?.vp_individual;
      reqBody["vp_company"] = userData?.vp_company;
      reqBody["vp_syndicate"] = userData?.vp_syndicate;
      reqBody["vp_syndicate_subuser"] = userData?.vp_syndicate_subuser;

      let response = await updatePartnerUserInfoUserList(userId, reqBody);
      let data = response?.data;
      if (data?.status) {
        alert(data?.message || "Something went wrong while updating partner");
      } else {
        alert(data?.message || "Data updated successfully");
        closeModalSuccess();
        setEditModal(false);
      }
    } catch (error) {
      console.trace(error);
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong while updating partner";
      alert(message);
    }
  };

  const handleRemovePartner = async e => {
    e.preventDefault();

    let userId = userData?.user?._id;
    if (!userId) {
      return alert("Invalid User Id");
    }

    try {
      let reqBody = {};
      reqBody["isPartner"] = false;
      let response = await updatePartnerUserInfoUserList(userId, reqBody);

      if (!response?.status) {
        return alert(response?.message || "Cannot remove partner details");
      }

      alert(response?.message || "Partner removed successfully");
      closeModalSuccess();
      toggleEditModal();
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "Error while removing partner details";
      return alert(message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Partner List | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={loading} />
          <Row className="mb-3">
            <Col sm="6">
              <h5 className="text-uppercase">Partners </h5>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={userListColumns}
                    data={venturePartnersList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={userListColumns}
                        data={venturePartnersList}
                        search={{
                          searchFormatted: true,
                        }}
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md={8} sm={12} className="d-block d-sm-flex">
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={4}
                                  xs={12}
                                  className="mb-2 mb-sm-0"
                                >
                                  <Select
                                    placeholder="Sort By"
                                    options={sortOptions}
                                    styles={{
                                      option: (styles, { data }) => ({
                                        ...styles,
                                      }),
                                    }}
                                    value={sortOptions.find(
                                      ({ value }) => value === sortQuery
                                    )}
                                    onChange={({ value }) => {
                                      setSortQuery(value);
                                    }}
                                  />
                                </Col>
                                <Col lg={9} md={9} sm={8} xs={12}>
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                        searchText={searchText}
                                        onSearch={setSearchText}
                                        delay={500}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Col>
                              <Col md={4} sm={12} className="text-end">
                                <Row>
                                  <Col>
                                    {/* <DateRangePicker
                                      initialSettings={dateRange}
                                      onApply={(event, picker) => {
                                        setDateRange({
                                          startDate: picker.startDate._d,
                                          endDate: picker.endDate._d,
                                        });
                                        console.log(rangefilter);
                                        setRangefilter(rangefilter + 1);
                                        setExpandedRows([]);
                                      }}
                                    >
                                      <button className="btn btn-outline-dark">
                                        {moment(dateRange.startDate).format(
                                          "DD MMM, YYYY"
                                        ) +
                                          " - " +
                                          moment(dateRange.endDate).format(
                                            "DD MMM, YYYY"
                                          )}
                                      </button>
                                    </DateRangePicker> */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    remote={{
                                      pagination: true,
                                      search: true,
                                      sort: true,
                                    }}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    noDataIndication="No Partners Available"
                                    rowStyle={row => ({
                                      backgroundColor: row?.user?.is_saas_parent
                                        ? "lightgray"
                                        : "",
                                      fontWeight: row?.user?.is_saas_parent
                                        ? "600"
                                        : "0",
                                    })}
                                    // expandRow={{
                                    //   renderer: (row, index) => {
                                    //     let {
                                    //       totalPayoutAmount,
                                    //       totalPendingAmount,
                                    //       totalActiveReferrals,
                                    //       totalInactiveReferrals,
                                    //       totalLiveDeals,
                                    //       totalClosedDeals,
                                    //       totalApprovedInvestments,
                                    //       totalUnapprovedInvestments,
                                    //     } = details;
                                    //     return (
                                    //       <Card>
                                    //         <CardBody>
                                    //           {detailsLoading ? (
                                    //             <>
                                    //               <Row className="text-center">
                                    //                 <Col>
                                    //                   <Spinner color="primary" />
                                    //                 </Col>
                                    //               </Row>
                                    //             </>
                                    //           ) : (
                                    //             <>
                                    //               <Row>
                                    //                 <Col
                                    //                   xs={12}
                                    //                   sm={6}
                                    //                   md={6}
                                    //                   lg={3}
                                    //                   className="mb-4 mb-lg-0"
                                    //                 >
                                    //                   <Row>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-2"
                                    //                     >
                                    //                       <b>
                                    //                         <u>Payout Amount</u>
                                    //                       </b>
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Total :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalPayoutAmount ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Pending :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalPendingAmount ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                   </Row>
                                    //                 </Col>
                                    //                 <Col
                                    //                   xs={12}
                                    //                   sm={6}
                                    //                   md={6}
                                    //                   lg={3}
                                    //                   className="mb-4 mb-lg-0"
                                    //                 >
                                    //                   <Row>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-2"
                                    //                     >
                                    //                       <b>
                                    //                         <u>
                                    //                           No. of Referral
                                    //                           Investors
                                    //                         </u>
                                    //                       </b>
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Active :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalActiveReferrals ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Inactive :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalInactiveReferrals ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                   </Row>
                                    //                 </Col>
                                    //                 <Col
                                    //                   xs={12}
                                    //                   sm={6}
                                    //                   md={6}
                                    //                   lg={3}
                                    //                   className="mb-4 mb-lg-0"
                                    //                 >
                                    //                   <Row>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-2"
                                    //                     >
                                    //                       <b>
                                    //                         <u>No. of Deals</u>
                                    //                       </b>
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Live :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalLiveDeals || 0
                                    //                       )}
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Closed :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalClosedDeals ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                   </Row>
                                    //                 </Col>
                                    //                 <Col
                                    //                   xs={12}
                                    //                   sm={6}
                                    //                   md={6}
                                    //                   lg={3}
                                    //                   className="mb-4 mb-lg-0"
                                    //                 >
                                    //                   <Row>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-2"
                                    //                     >
                                    //                       <b>
                                    //                         <u>
                                    //                           Investment Amount
                                    //                         </u>
                                    //                       </b>
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Approved :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalApprovedInvestments ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                     <Col
                                    //                       xs={12}
                                    //                       className="mb-1"
                                    //                     >
                                    //                       <b>Unapproved :</b>{" "}
                                    //                       {toCommaFormat(
                                    //                         totalUnapprovedInvestments ||
                                    //                           0
                                    //                       )}
                                    //                     </Col>
                                    //                   </Row>
                                    //                 </Col>
                                    //               </Row>
                                    //             </>
                                    //           )}
                                    //         </CardBody>
                                    //       </Card>
                                    //     );
                                    //   },
                                    //   showExpandColumn: true,
                                    //   expandColumnRenderer: ({ expanded }) =>
                                    //     expanded ? (
                                    //       <>
                                    //         <i
                                    //           className="mdi mdi-chevron-down font-size-22"
                                    //           title="Click to hide"
                                    //         ></i>
                                    //       </>
                                    //     ) : (
                                    //       <>
                                    //         <i
                                    //           className="mdi mdi-chevron-right font-size-22"
                                    //           title="Click for more details"
                                    //         ></i>
                                    //       </>
                                    //     ),
                                    //   expandHeaderColumnRenderer: props => (
                                    //     <></>
                                    //   ),
                                    //   onlyOneExpanding: true,
                                    //   onExpand: (row, isExpand) => {
                                    //     if (isExpand) {
                                    //       setExpandedRows([row._id]);
                                    //       fetchPartnerDetails(row?.user?._id);
                                    //     } else {
                                    //       setExpandedRows([]);
                                    //     }
                                    //   },
                                    //   expanded: expandedRows,
                                    //   expandByColumnOnly: true,
                                    // }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>

                {/* Edit Partner Modal */}
                <Modal size="lg" isOpen={editModal} toggle={toggleEditModal}>
                  <ModalHeader toggle={toggleEditModal} tag="h4">
                    Edit Partner
                  </ModalHeader>
                  <ModalBody>
                    <Form onSubmit={handleValidEditPartnerSubmit}>
                      <Row className="text-end">
                        <Col>
                          <Button
                            className="me-3"
                            type="button"
                            color="danger"
                            size="sm"
                            onClick={handleRemovePartner}
                          >
                            Remove Partner
                          </Button>
                        </Col>
                      </Row>

                      {!userData?.user?.is_saas_parent && (
                        <>
                          <Row className="mt-3 align-items-center">
                            <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                              <Label>
                                Reference Partner{" "}
                                <span className="text-danger">*</span>
                              </Label>
                            </Col>
                            <Col md={9} sm={8} xs={8} className="mt-2 mt-sm-0">
                              <Select
                                className="mt-1"
                                placeholder={"Select Partner"}
                                options={allRefPartners
                                  .filter(
                                    partner =>
                                      partner?.value !== userData?.user?._id
                                  )
                                  .sort((a, b) => {
                                    if (
                                      a?.label?.toLowerCase() <
                                      b?.label?.toLowerCase()
                                    ) {
                                      return -1;
                                    }
                                    if (
                                      a?.label?.toLowerCase() >
                                      b?.label?.toLowerCase()
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  })}
                                onChange={value => {
                                  setSelectedRefPartner(value);
                                }}
                                value={selectedRefPartner}
                                // isClearable
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="mt-3 align-items-middle" hidden>
                        <Col sm={6} xs={6}>
                          <Row>
                            <Col md={6} sm={8} xs={9} className="mt-2 mt-sm-0">
                              <Label>All Startups Visible</Label>
                            </Col>
                            <Col md={6} sm={4} xs={3} className="mt-2 mt-sm-0">
                              <Form1.Check
                                type="switch"
                                checked={userData?.see_all_startups}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    see_all_startups: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col sm={6} xs={6}>
                          <Row>
                            <Col md={6} sm={8} xs={9} className="mt-2 mt-sm-0">
                              <Label>All Investors Visible</Label>
                            </Col>
                            <Col md={6} sm={4} xs={3} className="mt-2 mt-sm-0">
                              <Form1.Check
                                type="switch"
                                checked={userData?.see_all_investors}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    see_all_investors: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={3} sm={4} xs={4} className="mt-2 mt-sm-0">
                          <Label>VP Type</Label>
                        </Col>
                        <Col md={9} sm={8} xs={8} className="mt-2 mt-sm-0">
                          <Row>
                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-individual"
                                name="vp-individual"
                                type="radio"
                                inline
                                label="VP - Individual"
                                checked={userData?.vp_individual}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: event.target.checked,
                                    vp_company: !event.target.checked,
                                    vp_syndicate: !event.target.checked,
                                    vp_syndicate_subuser: !event.target.checked,
                                  });
                                }}
                              />
                            </Col>

                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-company"
                                name="vp-company"
                                type="radio"
                                inline
                                label="VP - Company"
                                checked={userData?.vp_company}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: !event.target.checked,
                                    vp_company: event.target.checked,
                                    vp_syndicate: !event.target.checked,
                                    vp_syndicate_subuser: !event.target.checked,
                                  });
                                }}
                              />
                            </Col>

                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-syndicate"
                                name="vp-syndicate"
                                type="radio"
                                inline
                                label="VP - Syndicate"
                                checked={userData?.vp_syndicate}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: !event.target.checked,
                                    vp_company: !event.target.checked,
                                    vp_syndicate: event.target.checked,
                                    vp_syndicate_subuser: !event.target.checked,
                                  });
                                }}
                              />
                            </Col>

                            <Col md={6} sm={12}>
                              <Form1.Check
                                id="vp-syndicate-subuser"
                                name="vp-syndicate-subuser"
                                type="radio"
                                inline
                                label="VP - Syndicate SubUser"
                                checked={userData?.vp_syndicate_subuser}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    vp_individual: !event.target.checked,
                                    vp_company: !event.target.checked,
                                    vp_syndicate: !event.target.checked,
                                    vp_syndicate_subuser: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                          {/* <Form1.Check
                            type="switch"
                            label="Is VP ?"
                            checked={userData?.is_vp}
                            onChange={event => {
                              setUserData({
                                ...userData,
                                is_vp: event.target.checked,
                              });
                            }}
                          /> */}
                        </Col>
                      </Row>

                      {selectedRefPartner?.value && (
                        <>
                          <Row className="mt-3 align-items-middle">
                            <Col>
                              <Form1.Check
                                type="switch"
                                label="Display Collection"
                                checked={userData?.display_collection}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    display_collection: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                            <Col>
                              <Form1.Check
                                type="switch"
                                label="Display Commision"
                                checked={userData?.display_commision}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    display_commision: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3 align-items-middle">
                            <Col>
                              <Form1.Check
                                type="switch"
                                label="Get Payout"
                                checked={userData?.can_get_payout}
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    can_get_payout: event.target.checked,
                                  });
                                }}
                              />
                            </Col>
                            {!!userData?.user?.is_saas_parent ||
                            !!userData?.can_get_payout ? (
                              <>
                                <Col>
                                  <Form1.Check
                                    type="switch"
                                    label="Apply Parent Settings"
                                    checked={userData?.apply_parent_settings}
                                    onChange={event => {
                                      setUserData({
                                        ...userData,
                                        apply_parent_settings:
                                          event.target.checked,
                                      });
                                    }}
                                  />
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </>
                      )}

                      {/* Level 1 settings */}
                      {!!userData?.user?.is_saas_parent ||
                      (!!userData?.can_get_payout &&
                        !userData?.apply_parent_settings) ? (
                        <>
                          <Row className="mt-3">
                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                            >
                              <Label>
                                % Of Investor/Referral Transaction Fee{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                type="number"
                                step="any"
                                min="0"
                                max="100"
                                placeholder="Enter % Of Investor/Referral Transaction Fee"
                                // required
                                value={
                                  userData?.level_1_settings?.transaction_fee
                                    ?.amount
                                }
                                onChange={event => {
                                  let level_1_settings =
                                    userData?.level_1_settings || {};
                                  let transaction_fee =
                                    level_1_settings?.transaction_fee || {};
                                  transaction_fee["amount"] =
                                    event.target.value;
                                  level_1_settings["transaction_fee"] =
                                    transaction_fee;
                                  setUserData({
                                    ...userData,
                                    level_1_settings,
                                  });
                                }}
                              />
                            </Col>
                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                            >
                              <Label>
                                Subscription Fee (%){" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                type="number"
                                step="any"
                                min="0"
                                max="100"
                                placeholder="Enter Subscription Fee (%)"
                                // required
                                value={
                                  userData?.level_1_settings?.subscription_fee
                                    ?.amount
                                }
                                onChange={event => {
                                  let level_1_settings =
                                    userData?.level_1_settings || {};
                                  let subscription_fee =
                                    level_1_settings?.subscription_fee || {};
                                  subscription_fee["amount"] =
                                    event.target.value;
                                  level_1_settings["subscription_fee"] =
                                    subscription_fee;
                                  setUserData({
                                    ...userData,
                                    level_1_settings,
                                  });
                                }}
                              />
                            </Col>
                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 d-flex flex-column justify-content-between"
                            >
                              <Label>
                                Carry Fee (%){" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                type="number"
                                step="any"
                                min="0"
                                max="100"
                                placeholder="Enter Carry Fee (%)"
                                // required
                                value={
                                  userData?.level_1_settings?.carry_fee?.amount
                                }
                                onChange={event => {
                                  let level_1_settings =
                                    userData?.level_1_settings || {};
                                  let carry_fee =
                                    level_1_settings?.carry_fee || {};
                                  carry_fee["amount"] = event.target.value;
                                  level_1_settings["carry_fee"] = carry_fee;
                                  setUserData({
                                    ...userData,
                                    level_1_settings,
                                  });
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-3 align-items-center">
                            <Col
                              md={3}
                              sm={12}
                              xs={12}
                              className="mt-2 mt-sm-0 d-flex flex-column justify-content-between"
                            >
                              <Label>Credits Per Lac Of Investment</Label>
                            </Col>
                            <Col
                              md={9}
                              sm={12}
                              xs={12}
                              className="mt-2 mt-sm-0"
                            >
                              <Row>
                                <Col>
                                  <Label>
                                    Self Investments{" "}
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                </Col>
                                <Col>
                                  <Label>
                                    Referral Investments{" "}
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    type="number"
                                    step="any"
                                    min="0"
                                    placeholder="For Self Investments"
                                    // required
                                    value={
                                      userData?.credits_per_lac_of_investments
                                        ?.for_self
                                    }
                                    onChange={event => {
                                      let credits_per_lac_of_investments =
                                        userData?.credits_per_lac_of_investments ||
                                        {};
                                      credits_per_lac_of_investments[
                                        "for_self"
                                      ] = event.target.value;
                                      setUserData({
                                        ...userData,
                                        credits_per_lac_of_investments,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    type="number"
                                    step="any"
                                    min="0"
                                    placeholder="For Referral Investments"
                                    // required
                                    value={
                                      userData?.credits_per_lac_of_investments
                                        ?.for_referral
                                    }
                                    onChange={event => {
                                      let credits_per_lac_of_investments =
                                        userData?.credits_per_lac_of_investments ||
                                        {};
                                      credits_per_lac_of_investments[
                                        "for_referral"
                                      ] = event.target.value;
                                      setUserData({
                                        ...userData,
                                        credits_per_lac_of_investments,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      {userData?.vp_individual && (
                        <>
                          <Row className="mt-3 align-items-center">
                            <Col md={12}>
                              <Label>
                                Transaction Fee Refund For Self-Investments (%)
                              </Label>
                            </Col>
                            <Col md={6} sm={9} xs={12}>
                              <Input
                                type="number"
                                step="any"
                                min="0"
                                max="100"
                                placeholder="Enter Transaction Fee Refund For Self-Investments (%)"
                                required
                                value={
                                  userData?.transaction_fee_disc_self_investments
                                }
                                onChange={event => {
                                  setUserData({
                                    ...userData,
                                    transaction_fee_disc_self_investments:
                                      event.target.value,
                                  });
                                }}
                              />
                              <span className="text-secondary">
                                <i className="fa fa-asterisk text-secondary" />{" "}
                                Only for VP - Individual
                              </span>
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="mt-3 text-end">
                        <Col>
                          <Button type="submit">Update</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VenturePartnersList;
