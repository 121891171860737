import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

import { Form } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Table,
  Alert,
} from "reactstrap";

import {
  getUsers2 as getUsersAll,
  getAllUsersForReference,
} from "../../../helpers/backend_helper";

import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

const SaasPayout = () => {
  const [creditinfo, setCreditinfo] = useState([]);
  const [creditHistory, setCreditHistory] = useState([]);

  const [access, setAccess] = useState({});
  //const [roleId, setRoleId] = useState();
  const [loginUser, setLoginUserData] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const params = useParams();

  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPage: 25,
    totalSize: total,
    disablePageTitle: false,
    onPageChange: async (page, size) => {
      setPage(page);
    },
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/user/credit/info?page=0&limit=2&userID=${params.userID}&isadmin=true}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        console.log(res);
        setCreditinfo(res.data?.creditinfo);
        if (res.data?.creditInfo?.credit_history.length > 0) {
          setCreditHistory(res.data?.creditInfo?.credit_history);
        }

        setTotal(res.data?.creditInfo?.credit_history?.length);
      })
      .catch(err => {
        // console.log(err)
      });
    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    const loginUserInfo = JSON.parse(localStorage.getItem("authUser")).userInfo;
    setLoginUserData(loginUserInfo);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });
    return () => {};
  }, []);

  const defaultSorted = [
    {
      dataField: "_id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const saaspaoutListColumns = [
    {
      text: "Sr. No",
      sort: true,
      formatter: (cellContent, CreditHistory, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      text: "Credit Type",
      dataField: "credit_type",
      sort: true,
      formatter: (cellContent, CreditHistory) => (
        <>
          <span className="text-capitalize">
            {(CreditHistory?.credit_type === "per_day_spent"
              ? "Credit Spent"
              : CreditHistory?.credit_type || ""
            )
              .split("_")
              .join(" ")}
          </span>
        </>
      ),
    },
    {
      text: "Credit Amounts",
      dataField: "credit_amount",
      sort: true,
      formatter: (cellContent, CreditHistory) => (
        <>{CreditHistory?.credit_amount}</>
      ),
    },
    {
      text: "Referrer Name",
      formatter: (cellContent, CreditHistory) => (
        <>{CreditHistory?.other_credit_details?.referred_investor_name || ""}</>
      ),
    },
    {
      text: "Startup Name",
      formatter: (cellContent, CreditHistory) => (
        <>{CreditHistory?.other_credit_details?.startup_name || ""}</>
      ),
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      formatter: (cellContent, CreditHistory) => (
        <>{moment(new Date(CreditHistory.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      text: "Saas Name",
      dataField: "other_credit_details.saas_parent",
      formatter: (cellContent, CreditHistory) => (
        <>
          {CreditHistory?.other_credit_details?.saas_parent?.toUpperCase() ||
            ""}
        </>
      ),
      filter: textFilter(),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Saas Payout | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <Row className="mb-3">
            <Col sm="6">
              <h5 className="text-uppercase">Saas Payout</h5>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(options)}
                    keyField="_id"
                    columns={saaspaoutListColumns}
                    data={creditHistory}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={saaspaoutListColumns}
                        data={creditHistory}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-hover"}
                                    bordered={false}
                                    striped={true}
                                    responsive
                                    filter={filterFactory()}
                                    rowStyle={row => ({
                                      backgroundColor: row?.is_saas_parent
                                        ? "lightgray"
                                        : "",
                                      fontWeight: row?.is_saas_parent
                                        ? "600"
                                        : "0",
                                    })}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SaasPayout;
