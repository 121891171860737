import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Button,
  Table,
  Alert,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { closeAlertBox, updateUrlAlias } from "../../store/startups/actions";
import logoLightSvg from "../../assets/images/removeIcon.svg";
import axios from "axios";
import { encryptDataToArray, encryptDataToArrayV2 } from "helpers/EncryptDecrypt";

class EditStartupUrlModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startup: null,
      new_url_alias: "",
      startup_status: "",
      updateSuccess: false,
      error: "",
      show: true,
      success: "",
      startup_deal_logo: "",
      startup_deal_logo_name: "",
      iconSizeError: "",
    };
  }

  componentDidMount() {
    const { startup } = this.props;
    this.setState({
      startup: startup,
      startup_status: startup?.status,
      startup_deal_logo: startup?.basic_information?.startup_deal_logo || "",
      startup_deal_logo_name:
        startup?.basic_information?.startup_deal_logo_name || "",
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.error,
      success: nextProps.success,
      show: nextProps.show,
    });
  }

  onClose = () => {
    this.setState({ show: !this.state.show });
    this.props.reloadStartups();
    this.props.onClose();
  };

  handleUrlAliasUpdate = async () => {
    try {
      this.props.enableLoading();
      const { onUrlAliasUpdate } = this.props;
      const { startup, startup_status, startupLogoFile, startupLogoName } =
        this.state;

      let { new_url_alias } = this.state;
      if (new_url_alias === "") {
        new_url_alias = startup.url_alias;
      }

      let data = new FormData();
      let q = {}
      q.startup_id = startup?._id || ""
      q.new_url_alias = new_url_alias
      q.startup_status = startup_status || startup?.status
      q.startupLogoName = startupLogoName || ""
      data.append("startupLogoFile", startupLogoFile);
      let encryptedQuery = encryptDataToArray(q)
      data.append("q", JSON.stringify(encryptedQuery));

      const token = authHeader();
      let configHeaders;
      if (token) {
        configHeaders = {
          headers: {
            Authorization: token,
          },
        };
      }
      let url = `${process.env.REACT_APP_API_URL}/api/admin/startup/update/url/alias`;
      let response = await axios.patch(url, data, { ...configHeaders });

      if (response.status === 201) {
        let { startup } = response.data;
        this.setState({ startup_status: startup_status });
        this.setState({ ...startup, status: startup_status });
        //if(updateSuccess){
        this.onClose();
        //}
      }

      setTimeout(() => this.props.disableLoading(), 1500);
    } catch (err) {
      this.props.disableLoading();

      alert(
        err?.response?.data?.message ||
          err?.message ||
          "Error while updating data"
      );

      return;
    }
  };

  handleUrlChange = e => {
    let alias_value = e.target.value;
    alias_value = alias_value.split(" ").join("-");
    this.setState({ new_url_alias: alias_value || "" });
  };

  handleStatusUpdate = e => {
    this.setState({ startup_status: e.target.value });
  };

  removeImage = async startupId => {
    const token = authHeader();
    let configHeaders;
    if (token) {
      configHeaders = {
        headers: {
          Authorization: token,
        },
      };
    }
    let url = `${process.env.REACT_APP_API_URL}/api/admin/startup/update/url/remove/deal-logo`;
    let response = await axios.patch(
      url,
      { startupId: startupId },
      { ...configHeaders }
    );

    if (response.status === 201) {
      this.setState({
        startup_deal_logo: "",
        startup_deal_logo_name: "",
      });
    }
  };

  render() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let is_super_admin = authUser?.userInfo?.is_super_admin;

    let startup = this.props.startup || {};
    let url_alias = startup?.url_alias?.split(" ").join("-");
    return (
      <div className="page-content">
        <Modal isOpen={this.state.show} toggle={() => this.onClose()} size="lg">
          <ModalHeader toggle={() => this.onClose()} tag="h4">
            Edit{" "}
            <span className="text-primary">
              {startup?.basic_information?.startup_name || ""}
            </span>{" "}
          </ModalHeader>
          <ModalBody>
            <React.Fragment>
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box ms-2 mb-2 d-inline-block">
                    <div className="position-relative"></div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <AvForm
                    className="outer-repeater"
                    onValidSubmit={() => this.handleUrlAliasUpdate()}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="old_url"
                            className="col-form-label col-lg-3"
                          >
                            Old Url Alias
                          </Label>
                          <Col lg="9">
                            <AvField
                              id="old_url"
                              name="old_url"
                              type="text"
                              className="form-control"
                              placeholder=""
                              errorMessage="Required Field"
                              validate={{
                                required: { value: true },
                              }}
                              value={url_alias || ""}
                              disabled
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="url_alias"
                            className="col-form-label col-lg-3"
                          >
                            New Url Alias
                          </Label>
                          <Col lg="9">
                            <AvField
                              id="url_alias"
                              name="url_alias"
                              type="text"
                              className="form-control"
                              placeholder="startup new url alias"
                              errorMessage="New url alias is required"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: "^[A-Za-z0-9-]*$",
                                  errorMessage:
                                    "Only Alphabets, Numeric and -(dash) to be allowed",
                                },
                              }}
                              defaultValue={url_alias}
                              value={
                                this.state.new_url_alias.split(" ").join("-") ||
                                ""
                              }
                              onChange={e => this.handleUrlChange(e)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="status"
                            className="col-form-label col-lg-3"
                          >
                            Status
                          </Label>
                          <Col lg="9">
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  value="pending"
                                  type="radio"
                                  name="status"
                                  onChange={this.handleStatusUpdate}
                                  checked={
                                    this.state.startup_status === "pending"
                                  }
                                />{" "}
                                Pending
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  value="approve"
                                  type="radio"
                                  name="status"
                                  onChange={this.handleStatusUpdate}
                                  checked={
                                    this.state.startup_status === "approve"
                                  }
                                />{" "}
                                Approve
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  value="rejected"
                                  type="radio"
                                  name="status"
                                  onChange={this.handleStatusUpdate}
                                  checked={
                                    this.state.startup_status === "rejected"
                                  }
                                />{" "}
                                Rejected
                              </Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                        {is_super_admin && (
                          <FormGroup className="mb-4" row>
                            <Row className="mb-3 align-items-center">
                              <Col
                                md={3}
                                sm={4}
                                xs={4}
                                className="mt-2 mt-sm-0"
                              >
                                <Label>
                                  Deal Lead{" "}
                                  <span className="text-danger"></span>
                                </Label>
                              </Col>
                              <Col
                                md={9}
                                sm={8}
                                xs={8}
                                className="mt-2 mt-sm-0"
                              >
                                <Input
                                  type="file"
                                  size={10}
                                  accept=".jpeg, .png, .jpg, .gif"
                                  className="form-control"
                                  placeholder="Select Logo"
                                  onChange={event => {
                                    let maxSize = 500100;
                                    let fileSize = event.target.files[0]?.size;
                                    if (fileSize <= maxSize) {
                                      this.setState({
                                        startupLogoName: event.target.value,
                                        startupLogoFile: event.target.files[0],
                                        iconSizeError: "",
                                      });
                                    } else {
                                      this.setState({
                                        iconSizeError:
                                          "Icon size must be less than 500kb",
                                      });
                                    }
                                  }}
                                  multiple={false}
                                  required={false}
                                />
                                <span
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  Preferred Size: 127x45
                                </span>
                                {this.state.iconSizeError && (
                                  <span
                                    style={{
                                      color: "tomato",
                                      marginTop: "3px",
                                      display: "block",
                                    }}
                                  >
                                    {this.state.iconSizeError}
                                  </span>
                                )}
                                {this.state.startup_deal_logo && (
                                  <>
                                    <a
                                      href={this.state.startup_deal_logo}
                                      target="_blank"
                                    >
                                      {this.state.startup_deal_logo_name}
                                    </a>
                                    <span
                                      className="text-danger"
                                      onClick={() => {
                                        this.removeImage(startup._id);
                                      }}
                                    >
                                      <img src={logoLightSvg} />
                                    </span>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        )}
                      </div>
                    </div>
                    <Row className="text-center">
                      <Col lg="12">
                        <Button type="submit" color="primary">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </React.Fragment>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

EditStartupUrlModal.propTypes = {
  startup: PropTypes.any,
  onAlertClose: PropTypes.func,
  onClose: PropTypes.func,
  reloadStartups: PropTypes.func,
  handleUrlAliasUpdate: PropTypes.func,
  handleUrlChange: PropTypes.func,
  onUrlAliasUpdate: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  updateSuccess: PropTypes.bool,
};

const mapStateToProps = ({ kpi }) => ({
  error: kpi.error,
  success: kpi.success,
});

const mapDispatchToProps = dispatch => ({
  onAlertClose: () => dispatch(closeAlertBox()),
  onUrlAliasUpdate: data => dispatch(updateUrlAlias(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStartupUrlModal);
