import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Placeholder from "react-bootstrap/Placeholder";
import { exchangeCurrencyRates } from "../../helpers/common";
import moment from "moment";

let baseCurrency = {
  value: "INR",
  locale: "en-IN",
};

let targetCurrency = {
  value: "USD",
  locale: "en-US",
};

function nFormatter(num, digits) {
  let dollarNum = exchangeCurrencyRates(num, targetCurrency, targetCurrency);
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return dollarNum >= item.value;
    });
  let result = item
    ? (dollarNum / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
  return "US$" + result;
}

const CardsData = () => {
  const [cardsData, setCardsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/cardsData`, {
        params: {
          saas_parent_id: authUser?.userInfo?.saas_parent_id,
          show_all_saas_data,
          is_super_admin: authUser?.userInfo?.is_super_admin,
        },
        headers: {
          Authorization: authUser?.token,
        },
      })
      .then(response => {
        const responseData = response?.data?.data;
        setCardsData(responseData);
      })
      .catch(err => {})
      .finally(() => setIsLoading(false));

    return () => {};
  }, []);

  const convertToDealDateConversionRate = (amount, dealDate) => {
    let allCurrencyRates = JSON.parse(
      localStorage.getItem("all-currency-rates") || "{}"
    );

    let matchedCurrencyDate;

    let allCurrencyDates = Object.keys(allCurrencyRates);

    if (!allCurrencyDates?.includes(dealDate)) {
      allCurrencyDates = allCurrencyDates
        ?.filter(d => d?.toLowerCase() !== "default_data")
        ?.sort((a, b) => b?.toLowerCase()?.localeCompare(a?.toLowerCase()));

      let flag = false;

      for (let curDate of allCurrencyDates) {
        if (moment(dealDate, "YYYY-MM-DD").diff(curDate, "YYYY-MM-DD") > 0) {
          matchedCurrencyDate = curDate;
          flag = true;
        } else {
          flag = false;
        }

        if (flag) {
          break;
        }
      }
    } else {
      matchedCurrencyDate = dealDate;
    }

    let dealDateCurrencyRate =
      allCurrencyRates?.[matchedCurrencyDate || "DEFAULT_DATA"]?.["INR"]?.[
        "USD"
      ] || 1;

    return (amount || 0) * dealDateCurrencyRate;
  };

  const formattedCardsData = useMemo(() => {
    const numberFormat = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      compactDisplay: "short",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      maximumSignificantDigits: 3,
      minimumSignificantDigits: 3,
    });

    let dealValuation = cardsData?.DealValuation || {};

    let dataObj = Object.entries(dealValuation)?.reduce(
      (prev, [key, value]) => {
        let prevLive = prev?.live || 0;
        let prevTotal = prev?.total || 0;

        prevLive += value?.["USD"]?.["live"] || 0;
        prevLive += convertToDealDateConversionRate(
          value?.["INR"]?.["live"] || 0,
          key
        );

        prevTotal += value?.["USD"]?.["total"] || 0;
        prevTotal += convertToDealDateConversionRate(
          value?.["INR"]?.["total"] || 0,
          key
        );

        return {
          live: prevLive,
          total: prevTotal,
        };
      },
      {
        live: 0,
        total: 0,
      }
    );

    return {
      live: numberFormat.format(dataObj?.live),
      total: numberFormat.format(dataObj?.total),
    };
  }, [cardsData.DealValuation]);

  return (
    <div className="milestone-sec mb-4">
      <div className="d-flex milestone-col color1">
        <div className="sec-pic me-3">
          <img src="../img/mile1.svg" alt="" />
        </div>
        {isLoading ? (
          <div className="mile-col w-50">
            <Placeholder as="p" animation="glow">
              <Placeholder size="lg" xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder size="sm" xs={8} />
            </Placeholder>
          </div>
        ) : (
          <div className="mile-col">
            <strong>
              {new Intl.NumberFormat("en").format(
                cardsData?.uniqueInvestorsCount
              )}{" "}
              / {new Intl.NumberFormat("en").format(cardsData?.totalInvestors)}{" "}
              Investors
            </strong>
            <span> Angel Investors / Registered Investors</span>
          </div>
        )}
      </div>
      <div className="d-flex milestone-col color2">
        <div className="sec-pic me-3">
          <img src="../img/mile2.svg" alt="" />
        </div>
        {isLoading ? (
          <div className="mile-col w-50">
            <Placeholder as="p" animation="glow">
              <Placeholder size="lg" xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder size="sm" xs={8} />
            </Placeholder>
          </div>
        ) : (
          <div className="mile-col">
            <strong>
              {cardsData?.totalRaisedFund
                ? nFormatter(cardsData?.totalRaisedFund, 2)
                : ""}
            </strong>
            <span>Fund Raised</span>
          </div>
        )}
      </div>
      <div className="d-flex milestone-col color3">
        <div className="sec-pic me-3">
          <img src="../img/mile3.svg" alt="" />
        </div>
        {isLoading ? (
          <div className="mile-col w-50">
            <Placeholder as="p" animation="glow">
              <Placeholder size="lg" xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder size="sm" xs={8} />
            </Placeholder>
          </div>
        ) : (
          <div className="mile-col">
            <strong>
              {new Intl.NumberFormat("en").format(cardsData?.liveDealCount)}/
              {new Intl.NumberFormat("en").format(cardsData?.totalDealCount)}
            </strong>
            <span>Live/Total Deals</span>
          </div>
        )}
      </div>
      <div className="d-flex milestone-col color5">
        <div className="sec-pic me-3">
          <img src="../img/mile5.svg" alt="" />
        </div>
        {isLoading ? (
          <div className="mile-col w-50">
            <Placeholder as="p" animation="glow">
              <Placeholder size="lg" xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder size="sm" xs={8} />
            </Placeholder>
          </div>
        ) : (
          <div className="mile-col">
            <strong>
              {formattedCardsData.live}/{formattedCardsData.total}
            </strong>
            <span>Live/Total Valuations</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardsData;
