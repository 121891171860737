import React, { Component } from "react"

class AccessDenied extends Component {

  render() {
    return <React.Fragment>
      <h1>Access Denied</h1>
    </React.Fragment>
  }
}


export default AccessDenied
