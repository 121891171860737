import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import NewInvestorsGraph from "components/DashboardGraphs/NewInvestorsGraph";
import GrowthInvestorsGraph from "components/DashboardGraphs/GrowthInvestorsGraph";
import InvestorFunds from "components/DashboardGraphs/InvestorFunds";
import RaisedFund from "components/DashboardGraphs/RaisedFunds";
import FundCumulativeData from "components/DashboardGraphs/FundCumulativeData";
import PlatformEngagement from "components/DashboardGraphs/PlatformEngagement";
import TopInvestments from "components/DashboardGraphs/TopInvestments";
import TopInvestors from "components/DashboardGraphs/TopInvestors";

const DashboardGraphs = ({ fundOptionsList = [] }) => {
  const [topInvestor, setTopInvestor] = useState([]);
  const [topInvestment, setTopInvestment] = useState([]);

  useEffect(() => {
    let show_all_saas_data = JSON.parse(
      localStorage.getItem("roleWiseAccess")
    )?.show_all_saas_data;
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard/topInvestmentData`,
        {
          params: {
            saas_parent_id: authUser?.userInfo?.saas_parent_id,
            show_all_saas_data,
            is_super_admin: authUser?.userInfo?.is_super_admin,
          },
          headers: {
            Authorization: authUser?.token,
          },
        }
      )
      .then(response => {
        const responseData = response?.data?.data;
        setTopInvestor(responseData?.topInvestors);
        setTopInvestment(responseData?.topInvestments);
      })
      .catch(err => {});

    return () => {};
  }, []);

  return (
    <>
      <Row>
        <Col md={6}>
          <NewInvestorsGraph />
        </Col>
        <Col md={6}>
          <GrowthInvestorsGraph />
        </Col>
      </Row>
      <hr className="mt-3 mb-3" />
      <Row>
        <Col md={6}>
          <InvestorFunds />
        </Col>
        <Col md={6}>
          <RaisedFund />
        </Col>
      </Row>
      <hr className="mt-3 mb-3" />
      <Row>
        <Col md={6}>
          <FundCumulativeData fundOptionsList={fundOptionsList} />
        </Col>
        <Col md={6}>
          <PlatformEngagement />
        </Col>
      </Row>
      <hr className="mt-3 mb-3" />
      <Row>
        <Col md={6}>
          <TopInvestors investorData={topInvestor} />
        </Col>
        <Col md={6}>
          <TopInvestments investmentData={topInvestment} />
        </Col>
      </Row>
    </>
  );
};

export default DashboardGraphs;
