import React, { useState, useEffect } from "react";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "reactjs-popup/dist/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

function FounderList() {
  const [founders, setFounders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [founder, setFounder] = useState({});
  const [error, setError] = useState("");
  const [input, setInput] = useState("");
  const [defaultPosts, setDefaultPosts] = useState([]);

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const deletePost = async id => {
    console.log(id + "id need to delete");
    const token = authHeader();
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/admin/user/delete/${id}`,
      method: "DELETE",
      headers: { Authorization: `${token}` },
    })
      .then(res => {
        fetchData();
      })
      .catch(err => {
        setError(err);
      });
  };

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, appliedFunding) => (
        <>{appliedFunding.applyforfundings.id}</>
      ),
    },
    {
      dataField: "firstname",
      text: "Founder Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "mobile_number",
      text: "Mobile Number",
    },
    {
      dataField: "user_types",
      text: "Type",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          {user.user_types.map(val =>
            val.user_type === "founder" ? val.user_type : ""
          )}
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, user) => (
        <>{user.status.charAt(0).toUpperCase() + user.status.slice(1)}</>
      ),
      //
    },
    {
      dataField: "email_verified",
      text: "Email Verified",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          {user.email_verified.charAt(0).toUpperCase() +
            user.email_verified.slice(1)}
        </>
      ),
      //
    },
    {
      dataField: "createdAt",
      text: "Created On",
      sort: true,
      formatter: (cellContent, founder) => (
        <>{moment(new Date(founder.createdAt)).format("DD MMM Y")}</>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, founder) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                setFounder(founder);
                setModal(true);
                setIsEdit(true);
              }}
            ></i>
          </Link>
          {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deletePost(founder._id)}
            ></i>
          </Link> */}
          <Link className="text-success" to={`/founders-detail/${founder._id}`}>
            <i color="primary" className="font-16 btn-block btn btn-primary">
              View Details
            </i>
          </Link>

          {/* <Link className="text-success" to="#"><i className="mdi mdi-eye font-size-18" id="edittooltip" onClick={() => this.handleClick(user)}></i></Link> */}
        </div>
      ),
    },
  ];

  const updateInput = async input => {
    // const filtered = defaultPosts.filter(post => {
    //   return founder.firstname
    //     .toLowerCase()
    //     .includes(input.toLowerCase());
    // });
    // setInput(input);
    // setPosts(filtered);
    alert("under development");
  };

  function fetchData() {
    setLoading(true);
    const token = authHeader();
    axios
      .get(process.env.REACT_APP_API_URL + `/api/admin/user/getallfound`, {
        headers: { Authorization: `${token}` },
      })
      .then(res => {
        console.log(res.data);
        setFounders(res.data);
      })
      .catch(err => {
        setError(err);
      });
    setLoading(false);
  }
  if (loading) {
    return <div>Loading</div>;
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Founder List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/*<Breadcrumbs
              title="Founder Deatils"
              breadcrumbItem="Founder Details"
            />*/}
            <Row className="mb-3">
              <Col sm="6">
                <h5 className="text-uppercase">Startup list </h5>
              </Col>
              <Col sm="6" className="text-center">
                <span>Startups / Startup List</span>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <input
                                key="random1"
                                className="form-control"
                                value={input}
                                placeholder={"search"}
                                onChange={e => updateInput(e.target.value)}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        keyField="_id"
                        data={founders}
                        columns={columns}
                        bordered={false}
                        striped={true}
                        responsive
                      />

                      <Row>
                        {/* <Col xl="12">
                          {!isEmpty(error) ? (
                            <p className="text-danger">Some error occured!</p>
                          ) : null}
                        </Col> */}
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <Modal
                              size="lg"
                              isOpen={modal}
                              className="mb-3"
                              toggle={() => setModal(false)}
                            >
                              <ModalHeader
                                toggle={() => setModal(false)}
                                tag="h4"
                              >
                                {!!isEdit ? "Edit Founder" : "Add Founder"}
                              </ModalHeader>
                              <ModalBody>
                                {/* <AvForm
                                  onValidSubmit={() => {
                                    isEdit
                                      ? updatePost(founder)
                                      : createPost(founder);
                                  }}
                                  model={{}}
                                >
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="firstname"
                                          label="Firstname"
                                          type="text"
                                          errorMessage="Invalid title"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={event => {
                                            setPost({
                                              ...founder,
                                              firstname: event.target.value,
                                            });
                                          }}
                                          value={founder.firstname || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="lastname"
                                          label="Lastname"
                                          type="text"
                                          onChange={event => {
                                            setPost({
                                              ...founder,
                                              lastname: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid Description"
                                          validate={{
                                            required: { value: false },
                                          }}
                                          value={founder.lastname || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="mobile_number"
                                          label="Mobile Number"
                                          type="text"
                                          onChange={event => {
                                            setPost({
                                              ...founder,
                                              mobile_number: event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid Description"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={founder.mobile_number || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="linkedin_profile_link"
                                          label="Linkedin Id"
                                          type="text"
                                          onChange={event => {
                                            setPost({
                                              ...founder,
                                              linkedin_profile_link:
                                                event.target.value,
                                            });
                                          }}
                                          errorMessage="Invalid Description"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            founder.linkedin_profile_link || ""
                                          }
                                        />
                                      </div>
                                       <div className="mb-3">
                                        <label>Active</label>
                                        <AvRadioGroup
                                          onChange={event => {
                                            setPost({
                                              ...founder,
                                              status: event.target.value,
                                            });
                                          }}
                                          name="status"
                                          required
                                        >
                                          <AvRadio
                                            label="Approve"
                                            value="true"
                                          />
                                          <AvRadio
                                            label="Reject"
                                            value="false"
                                          />
                                        </AvRadioGroup>
                                      </div> 
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm> */}
                              </ModalBody>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FounderList;
