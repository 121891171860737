/**
 * Send Zoom Meeting Invitation
 */
export const UPDATE_DEEPDIVE_ROLE = "UPDATE_DEEPDIVE_ROLE";
export const UPDATE_DEEPDIVE_ROLE_SUCCESS = "UPDATE_DEEPDIVE_ROLE_SUCCESS";
export const UPDATE_DEEPDIVE_ROLE_FAIL = "UPDATE_DEEPDIVE_ROLE_FAIL";

/**
 * Send Zoom Meeting Invitation
 */
export const SEND_ZOOM_MEETING_INVITATION = "SEND_ZOOM_MEETING_INVITATION";
export const SEND_ZOOM_MEETING_INVITATION_SUCCESS = "SEND_ZOOM_MEETING_INVITATION_SUCCESS";
export const SEND_ZOOM_MEETING_INVITATION_FAIL = "SEND_ZOOM_MEETING_INVITATION_FAIL";

/* PITCH_DAY_POSTS */
export const GET_ASSESSMENT_REPORT = "GET_ASSESSMENT_REPORT";
export const GET_ASSESSMENT_REPORT_SUCCESS = "GET_ASSESSMENT_REPORT_SUCCESS";
export const GET_ASSESSMENT_REPORT_FAIL = "GET_ASSESSMENT_REPORT_FAIL";

export const GET_PITCH_DAY_POSTS = "GET_PITCH_DAY_POSTS";
export const GET_PITCH_DAY_POSTS_SUCCESS = "GET_PITCH_DAY_POSTS_SUCCESS";
export const GET_PITCH_DAY_POSTS_FAIL = "GET_PITCH_DAY_POSTS_FAIL";

export const DELETE_PITCH_DAY = "DELETE_PITCH_DAY";
export const DELETE_PITCH_DAY_SUCCESS = "DELETE_PITCH_DAY_SUCCESS";
export const DELETE_PITCH_DAY_FAIL = "DELETE_PITCH_DAY_FAIL";

export const ADD_PITCH_DAY = "ADD_PITCH_DAY";
export const ADD_PITCH_DAY_SUCCESS = "ADD_PITCH_DAY_SUCCESS";
export const ADD_PITCH_DAY_FAIL = "ADD_PITCH_DAY_FAIL";

export const GET_PITCH_FOUNDERS = "GET_PITCH_FOUNDERS";
export const GET_PITCH_FOUNDERS_SUCCESS = "GET_PITCH_FOUNDERS_SUCCESS";
export const GET_PITCH_FOUNDERS_FAIL = "GET_PITCH_FOUNDERS_FAIL";

export const GET_PITCH_INVESTORS = "GET_PITCH_INVESTORS";
export const GET_PITCH_INVESTORS_SUCCESS = "GET_PITCH_INVESTORS_SUCCESS";
export const GET_PITCH_INVESTORS_FAIL = "GET_PITCH_INVESTORS_FAIL";

export const GET_PITCH_PANELLIST = "GET_PITCH_PANELLIST";
export const GET_PITCH_PANELLIST_SUCCESS = "GET_PITCH_PANELLIST_SUCCESS";
export const GET_PITCH_PANELLIST_FAIL = "GET_PITCH_PANELLIST_FAIL";

export const GET_PITCH_DAY_DETAIL = "GET_PITCH_DETAIL_DETAIL";
export const GET_PITCH_DAY_DETAIL_SUCCESS = "GET_PITCH_DAY_DETAIL_SUCCESS";
export const GET_PITCH_DAY_DETAIL_FAIL = "GET_PITCH_DAY_DETAIL_FAIL";

export const UPDATE_PITCH_DAY = "UPDATE_PITCH_DAY";
export const UPDATE_PITCH_DAY_SUCCESS = "UPDATE_PITCH_DAY_SUCCESS";
export const UPDATE_PITCH_DAY_FAIL = "UPDATE_PITCH_DAY_FAIL";
export const UPDATE_PITCH_DAY_DATA = "UPDATE_PITCH_DAY_DATA";


export const SEND_INVITE_TO_ALL = "SEND_INVITE_TO_ALL";
export const SEND_INVITE_TO_ALL_SUCCESS = "SEND_INVITE_TO_ALL_SUCCESS";
export const SEND_INVITE_TO_ALL_FAIL = "SEND_INVITE_TO_ALL_FAIL";

export const SEND_INVITE_TO_INVESTOR = "SEND_INVITE_TO_INVESTOR";
export const SEND_INVITE_TO_INVESTOR_SUCCESS =
  "SEND_INVITE_TO_INVESTOR_SUCCESS";
export const SEND_INVITE_TO_INVESTOR_FAIL = "SEND_INVITE_TO_INVESTOR_FAIL";

export const SEND_INVITE_TO_FOUNDER = "SEND_INVITE_TO_FOUNDER";
export const SEND_INVITE_TO_FOUNDER_SUCCESS = "SEND_INVITE_TO_FOUNDER_SUCCESS";
export const SEND_INVITE_TO_FOUNDER_FAIL = "SEND_INVITE_TO_FOUNDER_FAIL";

export const GET_POST_EVENT_LIST = "GET_POST_EVENT_LIST";
export const GET_POST_EVENT_LIST_SUCCESS = "GET_POST_EVENT_LIST_SUCCESS";
export const GET_POST_EVENT_LIST_FAIL = "GET_POST_EVENT_LIST_FAIL";

export const GET_POST_EVENT_DETAIL = "GET_POST_EVENT_DETAIL";
export const GET_POST_EVENT_DETAIL_SUCCESS = "GET_POST_EVENT_DETAIL_SUCCESS";
export const GET_POST_EVENT_DETAIL_FAIL = "GET_POST_EVENT_DETAIL_FAIL";

export const FETCH_RECORDING = "FETCH_RECORDING";
export const FETCH_RECORDING_SUCCESS = "FETCH_RECORDING_SUCCESS";
export const FETCH_RECORDING_FAIL = "FETCH_RECORDING_FAIL";

export const FETCH_QA = "FETCH_QA";
export const FETCH_QA_SUCCESS = "FETCH_QA_SUCCESS";
export const FETCH_QA_FAIL = "FETCH_QA_FAIL";

export const FETCH_POLL = "FETCH_POLL";
export const FETCH_POLL_SUCCESS = "FETCH_POLL_SUCCESS";
export const FETCH_POLL_FAIL = "FETCH_POLL_FAIL";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL";

export const UPDATE_EVENT_QUESTION = "UPDATE_EVENT_QUESTION";
export const UPDATE_EVENT_QUESTION_SUCCESS = "UPDATE_EVENT_QUESTION_SUCCESS";
export const UPDATE_EVENT_QUESTION_FAIL = "UPDATE_EVENT_QUESTION_FAIL";

export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAIL = "GET_QUESTION_FAIL";

export const ASSOCIATE_POLL = "ASSOCIATE_POLL";
export const ASSOCIATE_POLL_SUCCESS = "ASSOCIATE_POLL_SUCCESS";
export const ASSOCIATE_POLL_FAIL = "ASSOCIATE_POLL_FAIL";

export const GET_ASSOCIATE_POLL = "GET_ASSOCIATE_POLL";
export const GET_ASSOCIATE_POLL_SUCCESS = "GET_ASSOCIATE_POLL_SUCCESS";
export const GET_ASSOCIATE_POLL_FAIL = "GET_ASSOCIATE_POLL_FAIL";

export const GET_EVENT_PARTICIPANTS = "GET_EVENT_PARTICIPANTS";
export const GET_EVENT_PARTICIPANTS_SUCCESS = "GET_EVENT_PARTICIPANTS_SUCCESS";
export const GET_EVENT_PARTICIPANTS_FAIL = "GET_EVENT_PARTICIPANTS_FAIL";

export const FETCH_REGISTRANTS = "FETCH_REGISTRANTS";
export const FETCH_REGISTRANTS_SUCCESS = "FETCH_REGISTRANTS_SUCCESS";
export const FETCH_REGISTRANTS_FAIL = "FETCH_REGISTRANTS_FAIL";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const FETCH_POLL_REPORT = "FETCH_POLL_REPORT";
export const FETCH_POLL_REPORT_SUCCESS = "FETCH_POLL_REPORT_SUCCESS";
export const FETCH_POLL_REPORT_FAIL = "FETCH_POLL_REPORT_FAIL";

export const GENERATE_POLL_REPORT = "GENERATE_POLL_REPORT";
export const GENERATE_POLL_REPORT_SUCCESS = "GENERATE_POLL_REPORT_SUCCESS";
export const GENERATE_POLL_REPORT_FAIL = "GENERATE_POLL_REPORT_FAIL";

export const STORE_OTHER_DETAILS = "STORE_OTHER_DETAILS";
export const STORE_OTHER_DETAILS_SUCCESS = "STORE_OTHER_DETAILS_SUCCESS";
export const STORE_OTHER_DETAILS_FAIL = "STORE_OTHER_DETAILS_FAIL";

export const GET_FUNDING_DEAL_DETAILS = "GET_FUNDING_DEAL_DETAILS";
export const GET_FUNDING_DEAL_DETAILS_SUCCESS = "GET_FUNDING_DEAL_DETAILS_SUCCESS";
export const GET_FUNDING_DEAL_DETAILS_FAIL = "GET_FUNDING_DEAL_DETAILS_FAIL";
