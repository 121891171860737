import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";

import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser
} from "store/users/actions";

import { isEmpty, size } from "lodash";

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      modal: false,
      userListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => (
            <>
              {user.id}
            </>
          ),
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {user.firstname} {user.lastname}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
        },
        {
          dataField: "mobile_number",
          text: "Mobile Number",
        },
        {
          dataField: "user_types",
          text: "Type",
          sort: false,
          formatter: (cellContent, user) => (
            <>
              {user.user_types.map(val => val.user_type === 'investor' ? val.user_type : '')}
            </>
          ),
        },
        {
          dataField: "status",
          text: "Status",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
            </>
          ),
          //
        },
        {
          dataField: "email_verified",
          text: "Email Verified",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {user.email_verified.charAt(0).toUpperCase() + user.email_verified.slice(1)}
            </>
          ),
          //
        },
        // {
        //   dataField: "is_admin",
        //   text: "Is Admin",
        //   sort: true,
        //   formatter: (cellContent, user) => (
        //     <>
        //       {user.is_admin.charAt(0).toUpperCase() + user.is_admin.slice(1)}
        //     </>
        //   ),
        //   //
        // },
        {
          dataField: "createdAt",
          text: "Created On",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {moment(new Date(user.createdAt)).format("DD MMM Y")}
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleUserClick(user)}></i></Link>
              {/* <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.handleDeleteUser(user)}></i></Link> */}
            </div>
          ),
        },
      ],
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleValidUserSubmit = this.handleValidUserSubmit.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    onGetUsers();
    this.setState({ users });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserClicks = arg => {
    this.setState({ users: '', isEdit: false });
    this.toggle();
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteUser = (user) => {
    const { onDeleteUser } = this.props;
    if (user._id !== undefined) {
      onDeleteUser(user);
      this.onPaginationPageChange(1);
    }
  };

  handleUserClick = arg => {
    const user = arg;

    this.setState({
      users: {
        id: user._id,
        firstname: user.firstname,
        lastname: user.lastname,
        mobile_country_code: user.mobile_country_code,
        mobile_number: user.mobile_number,
        alternateMobileNumber: user.alternateMobileNumber,
        email: user.email,
        linkedin_profile_link: user.linkedin_profile_link,
        is_admin: user.is_admin,
        mobile_number_verified: user.mobile_number_verified,
        email_verified: user.email_verified,
        status: user.status,
        user_types: user.user_types
      },
      isEdit: true,
    });

    this.toggle();
  };

  /**
   * Handling submit user on user form
   */
  handleValidUserSubmit = (e, values) => {
    const { onAddNewUser, onUpdateUser } = this.props;
    const { isEdit, users, selectedUser } = this.state;

    if (isEdit) {
      const updateUser = {
        id: users.id,
        firstname: values.firstname,
        lastname: values.lastname,
        mobile_country_code: values.mobile_country_code,
        mobile_number: values.mobile_number,
        alternateMobileNumber: values.alternateMobileNumber,
        email: values.email,
        linkedin_profile_link: values.linkedin_profile_link,
        is_admin: values.is_admin,
        mobile_number_verified: values.mobile_number_verified,
        email_verified: values.email_verified,
        status: values.status,
        user_types: users.user_types
      };

      // update user
      onUpdateUser(updateUser);
    } else {

      const newUser = {
        firstname: values["firstname"],
        lastname: values["lastname"],
        mobile_country_code: values["mobile_country_code"],
        mobile_number: values["mobile_number"],
        alternateMobileNumber: values["alternateMobileNumber"],
        email: values["email"],
        linkedin_profile_link: values["linkedin_profile_link"],
        is_admin: values["is_admin"],
        mobile_number_verified: values["mobile_number_verified"],
        email_verified: values["email_verified"],
        status: values["status"],
        password: values["password"],

        user_types: values["user_types"],
      };
      // save new user
      onAddNewUser(newUser);
    }
    this.setState({ selectedUser: null });
    this.toggle();
  };

  /* Insert,Update Delete data */
  render() {
    // const { SearchBar } = Search;

    const { users, error } = this.props;

    const { isEdit } = this.state;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [{
      dataField: '_id', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }];

    const defaultValues = {
      is_admin: !isEmpty(this.state.users.is_admin) ? this.state.users.is_admin : 'false',
      mobile_number_verified: !isEmpty(this.state.users.mobile_number_verified) ? this.state.users.mobile_number_verified : 'false',
      email_verified: !isEmpty(this.state.users.email_verified) ? this.state.users.email_verified : 'false',
      status: !isEmpty(this.state.users.status) ? this.state.users.status : 'false',
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Investor List | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Investors" breadcrumbItem="Investor List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='_id'
                      columns={this.state.userListColumns}
                      data={users}
                    >
                      {
                        ({
                          paginationProps,
                          paginationTableProps
                        }) => (
                          <ToolkitProvider
                            keyField='_id'
                            columns={this.state.userListColumns}
                            data={users}
                            search={{
                              searchFormatted: true
                            }}
                          >
                            {
                              toolkitprops => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          {/* <SearchBar {...toolkitprops.searchProps} /> */}
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    {/* <Col sm="8">
                                      <div className="text-sm-end">
                                        <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={this.handleUserClicks}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Create New User
                                        </Button>
                                      </div>
                                    </Col> */}
                                  </Row>


                                  <Row>
                                    <Col xl="12">
                                      {
                                        !isEmpty(error) ? (
                                          <p className="text-danger">Some error occured!</p>
                                        ) : null
                                      }
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          {...toolkitprops.baseProps}
                                          {...paginationTableProps}
                                          defaultSorted={defaultSorted}
                                          classes={"table align-middle table-hover"}
                                          bordered={false}
                                          striped={true}
                                          responsive
                                          ref={this.node}
                                        />

                                        <Modal
                                          size="lg"
                                          isOpen={this.state.modal}
                                          className={this.props.className}
                                          toggle={this.toggle}
                                        >
                                          <ModalHeader toggle={this.toggle} tag="h4">
                                            {!!isEdit ? "Edit User" : "Add User"}
                                          </ModalHeader>
                                          <ModalBody>
                                            <AvForm
                                              onValidSubmit={
                                                this.handleValidUserSubmit
                                              }
                                              model={defaultValues}
                                            >
                                              <Row form>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="firstname"
                                                      label="First Name"
                                                      type="text"
                                                      errorMessage="Invalid firstname"
                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                      value={this.state.users.firstname || ""}
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="lastname"
                                                      label="Last Name"
                                                      type="text"
                                                      errorMessage="Invalid lastname"
                                                      validate={{
                                                        required: { value: false },
                                                      }}
                                                      value={this.state.users.lastname || ""}
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="email"
                                                      label="Email"
                                                      type="email"
                                                      errorMessage="Invalid Email"
                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                      value={this.state.users.email || ""}
                                                    />
                                                  </div>

                                                  {!!isEdit ? null :
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        errorMessage="Invalid password"
                                                        validate={{
                                                          required: { value: true },
                                                        }}
                                                        value=""
                                                      />
                                                    </div>
                                                  }

                                                  {!!isEdit ? null :
                                                    <div className="mb-3">
                                                      <label>User Type</label>
                                                      <AvRadioGroup name="user_types" required>
                                                        <AvRadio label="Investor" value="investor" />
                                                        <AvRadio label="Founder" value="founder" />
                                                        <AvRadio label="Individual" value="individual" />
                                                        <AvRadio label="Invstt" value="wfc" />
                                                      </AvRadioGroup>
                                                    </div>
                                                  }

                                                  <div className="mb-3">
                                                    <AvField
                                                      name="mobile_country_code"
                                                      label="Mobile Country Code"
                                                      type="text"
                                                      errorMessage="Invalid mobile country code"
                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                      value={this.state.users.mobile_country_code || ""}
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="mobile_number"
                                                      label="Mobile Number"
                                                      type="text"
                                                      errorMessage="Invalid mobile number"
                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                      value={this.state.users.mobile_number || ""}
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="alternateMobileNumber"
                                                      label="Alternate Mobile Number"
                                                      type="text"
                                                      errorMessage="Invalid alternate Mobile Number"
                                                      value={this.state.users.alternateMobileNumber || ""}
                                                      validate={{
                                                        required: { value: false },
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="linkedin_profile_link"
                                                      label="Linkedin Profile Link"
                                                      type="text"
                                                      errorMessage="Invalid linkedin profile link"
                                                      value={this.state.users.linkedin_profile_link || ""}
                                                    />
                                                  </div>
                                                  {/* <div className="mb-3">
                                                    <label>Is Admin</label>
                                                    <AvRadioGroup name="is_admin" required>
                                                      <AvRadio label="Yes" value="true" />
                                                      <AvRadio label="No" value="false" />
                                                    </AvRadioGroup>
                                                  </div> */}
                                                  <div className="mb-3">
                                                    <label>Mobile Number Verified</label>
                                                    <AvRadioGroup name="mobile_number_verified" required>
                                                      <AvRadio label="Yes" value="true" />
                                                      <AvRadio label="No" value="false" />
                                                    </AvRadioGroup>
                                                  </div>
                                                  <div className="mb-3">
                                                    <label>Email Verified</label>
                                                    <AvRadioGroup name="email_verified" required>
                                                      <AvRadio label="Yes" value="true" />
                                                      <AvRadio label="No" value="false" />
                                                    </AvRadioGroup>
                                                  </div>
                                                  <div className="mb-3">
                                                    <label>Status</label>
                                                    <AvRadioGroup name="status" required>
                                                      <AvRadio label="Pending" value="Pending" />
                                                      <AvRadio label="Approved" value="Approved" />
                                                      <AvRadio label="Declined" value="Declined" />
                                                    </AvRadioGroup>
                                                  </div>

                                                </Col>

                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">

                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </AvForm>
                                          </ModalBody>
                                        </Modal>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                          </ToolkitProvider>
                        )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  error: PropTypes.string,
};

const mapStateToProps = ({ users }) => ({
  users: users.users,
  error: users.error,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers('investor', 'Pending')),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersList));