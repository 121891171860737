import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
import user1 from "../../assets/images/users/avatar.png";

import { connect } from "react-redux";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj;
  }
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "Admin",
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({ name: userData.username })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username })
      }
    }
  }

  render() {
    const profile_pic = JSON.parse(localStorage.getItem('authUser'))?.userInfo?.profile_pic || "";
    const is_super_admin = JSON.parse(localStorage.getItem("authUser"))
            ?.userInfo?.is_super_admin;
    const is_saas_parent = JSON.parse(localStorage.getItem("authUser"))
            ?.userInfo?.is_saas_parent;
    const saas_logo = JSON.parse(localStorage.getItem("authUser"))
            ?.userInfo?.saas_logo;
    const lastname = JSON.parse(localStorage.getItem('authUser'))?.userInfo?.lastname || "";
    const username = JSON.parse(localStorage.getItem("authUser"))
            ?.userInfo?.firstname+' '+lastname;
    return (
	<>
	<div>
		<Link to="/" className="logo logo-light d-flex">
		  <span className="logo-lg">
			<img src="https://www.invstt.com/img/logo.png" alt="" style={{maxHeight:30}} />
		  </span>
      {(!is_super_admin && saas_logo)?(
		  <span className="sasslogo logo-lg">
			<img src={saas_logo} alt="" style={{maxHeight:30}} />
		  </span>):("")}
		</Link>
	</div>
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={profile_pic || user1}
              alt="Header Avatar"
            />{" "}
            <span className="d-xl-inline-block ms-1">
              {username} 
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {/* <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              Profile
            </DropdownItem> */}

            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
	  </>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string
}

const mapStateToProps = state => {
  const { success } = state.Profile
  return { success }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
