import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Select from "react-select";

import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";

import { getGroups } from "../../../helpers/backend_helper";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import { Country, State, City } from "country-state-city";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const UserGroupsModal = ({
  userGroupsModalOpen,
  setUserGroupsModal,
  handleUserGroupsModal,
  selectedGroupType,
  groupType,
  handleGroupsValuesChange,
}) => {
  const [isInvestor, setIsInvestor] = useState(true);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [country, setCountry] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [state, setState] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState([]);

  const styles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#E27235" : "",
    }),
  };

  useEffect(() => {
    setIsInvestor(groupType.toLowerCase() === "investor");
    setCountryOptions(fetchCountryOptions);
    return () => {};
  }, [groupType]);

  useEffect(() => {
    if (!userGroupsModalOpen) {
      setSelectedGroups([]);
      setCountry([]);
      setState([]);
      setCity([]);
    } else {
      fetchGroups();
      let oldGroups = selectedGroupType?.groups || [];
      let countries = selectedGroupType?.countries || [];
      let states = selectedGroupType?.states || [];
      let cities = selectedGroupType?.cities || [];

      setSelectedGroups(
        oldGroups.map(old => {
          let group = groups.find(group => group._id === old.value);
          return group ? { value: group._id, label: group.name } : old;
        })
      );
      setCountry(countries);
      setState(states);
      setCity(cities);
    }
    return () => {};
  }, [userGroupsModalOpen]);

  const fetchGroups = async () => {
    try {
      let res = await getGroups();
      setGroups(res?.length ? res.filter(ele => ele.status === "Active") : []);
    } catch (error) {
      console.debug(error);
      setGroups([]);
    }
  };

  useEffect(() => {
    let temp = fetchStateOptions(country.map(country => country.value));
    setStateOptions(temp);
    return () => {};
  }, [country]);

  useEffect(() => {
    let temp = fetchCityOptions(
      country.map(country => country.value),
      state.map(state => state.value)
    );
    setCityOptions(temp);
    return () => {};
  }, [state]);

  const handleSelectedGroupChange = selectedGroups => {
    setSelectedGroups(selectedGroups);
    handleGroupsValuesChange(
      isInvestor ? "investor_groups" : "founder_groups",
      "groups",
      selectedGroups
    );
  };

  const handleSelectedCountryChange = countries => {
    setCountry(countries);
    handleGroupsValuesChange(
      isInvestor ? "investor_groups" : "founder_groups",
      "countries",
      countries
    );
  };

  const handleSelectedStateChange = states => {
    setState(states);
    handleGroupsValuesChange(
      isInvestor ? "investor_groups" : "founder_groups",
      "states",
      states
    );
  };

  const handleSelectedCityChange = cities => {
    setCity(cities);
    handleGroupsValuesChange(
      isInvestor ? "investor_groups" : "founder_groups",
      "cities",
      cities
    );
  };

  const fetchCountryOptions = () =>
    Country.getAllCountries().map(({ name, isoCode }) => ({
      label: name,
      value: isoCode,
    }));

  const fetchStateOptions = (countryCodes = []) => {
    let stateOptions = [];

    for (let countryCode of countryCodes) {
      stateOptions.push(
        ...State.getStatesOfCountry(countryCode).map(({ name, isoCode }) => ({
          label: name,
          value: isoCode,
        }))
      );
    }

    return stateOptions;
  };

  const fetchCityOptions = (countryCodes = [], stateCodes = []) => {
    let cityOptions = [];

    for (let countryCode of countryCodes) {
      for (let stateCode of stateCodes) {
        cityOptions.push(
          ...City.getCitiesOfState(countryCode, stateCode).map(({ name }) => ({
            label: name,
            value: name,
          }))
        );
      }
    }

    return cityOptions;
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={userGroupsModalOpen}
        toggle={handleUserGroupsModal}
      >
        <ModalHeader toggle={handleUserGroupsModal} tag="h4">
          {`Select ${isInvestor ? "Investor" : "Founder"} Groups`}
        </ModalHeader>
        <Card>
          <CardBody>
            <ModalBody>
              <Label>Groups</Label>
              <Row className="mb-3">
                <Col className="col-12">
                  <Select
                    id="selectedGroups"
                    placeholder="Select Group(s)"
                    name="selectedGroups"
                    className="mb-3"
                    options={groups
                      .filter(group => group.type.toLowerCase() === groupType)
                      .map(group => ({
                        label: group.name,
                        value: group._id,
                      }))}
                    styles={styles}
                    value={selectedGroups}
                    isMulti={true}
                    isClearable={true}
                    onChange={groups => handleSelectedGroupChange(groups || [])}
                  />
                </Col>
              </Row>
              <Label>Select Location</Label>
              <Row className="mb-3 justify-content-center align-items-center">
                <Col md={4}>
                  <FormGroup>
                    <Label>Country</Label>
                    <Select
                      id="country"
                      name="country"
                      placeholder="Select country"
                      options={countryOptions}
                      styles={styles}
                      value={country}
                      onChange={ctrs => handleSelectedCountryChange(ctrs || [])}
                      isMulti
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>State</Label>
                    <Select
                      id="state"
                      name="state"
                      placeholder="Select state"
                      options={stateOptions}
                      styles={styles}
                      value={state}
                      onChange={stts => handleSelectedStateChange(stts || [])}
                      isMulti
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>City</Label>
                    <Select
                      id="city"
                      name="city"
                      placeholder="Select city"
                      options={cityOptions}
                      styles={styles}
                      value={city}
                      onChange={cts => handleSelectedCityChange(cts || [])}
                      isMulti
                      isClearable
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    {
                      <button
                        type="submit"
                        className="btn btn-success save-group"
                        onClick={handleUserGroupsModal}
                      >
                        Select
                      </button>
                    }
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

UserGroupsModal.propTypes = {
  userGroupsModalOpen: PropTypes.bool.isRequired,
  setUserGroupsModal: PropTypes.func.isRequired,
  handleUserGroupsModal: PropTypes.func.isRequired,
  selectedGroupType: PropTypes.object.isRequired,
  groupType: PropTypes.oneOf(["investor", "founder"]).isRequired,
  handleGroupsValuesChange: PropTypes.func.isRequired,
};

UserGroupsModal.defaultProps = {
  groupType: "investor",
  selectedGroupType: {
    groups: [],
    countries: [],
    states: [],
    cities: [],
  },
};

const mapStateToProps = ({ appliedFunding }) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupsModal);
