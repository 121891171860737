import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Card, Col, Container, Row, Button, Table } from "reactstrap";
import * as moment from "moment";

import { isEqual } from "lodash";

// import authHeader from "../../../src/helpers/jwt-token-access/auth-token-header";
import authHeader from "../../../helpers/jwt-token-access/auth-token-header";
import axios from "axios";
import { encryptDataToArray } from "helpers/EncryptDecrypt";

class EventDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            post: {},
            fundingDealId: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.postDetails, nextProps.postDetails)) {
            this.setState({ post: nextProps.postDetails })
        }
    }

    handelFundingDealId = (element) => {
        this.setState({ fundingDealId: element.target.value });
    }

    engagementActivity = async (startup_id) => {
        const { fundingDealId } = this.state;
        const { match } = this.props;
        let postId = match.params.id;

        const token = authHeader();

        let data = {
            startup_id: startup_id,
            postId: postId
        }
        let encryptedQuery = encryptDataToArray(data)
        let reqData = {
            q: JSON.stringify(encryptedQuery)
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/api/event/admin/engagement`, reqData, { headers: { Authorization: `${token}` } })
            .then(async (response) => {
                this.props?.onGetPostEventDetail({ postId: postId, showAll: true })
            }).catch((error) => {
                this.setState({ btnDisable: false });
            });
    }

    render() {
        const { post } = this.state;
        let founder_count=0;
        return (
            <React.Fragment>
                <div className="">
                    <Container fluid>
                        <Row className="my-5">
                            <Col lg="12">
                                <Card>
                                    <Row className="mb-2">
                                        <Col lg={4}>
                                            {
                                                post?.event_image &&
                                                <img
                                                    src={post?.event_image}
                                                    alt="Event Banner"
                                                    className="img-fluid rounded"
                                                    width="500px"
                                                    height="500px"
                                                />
                                            }
                                        </Col>
                                        <Col lg={4} md={4}>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th>Title:</th>
                                                        <td>{post && post?.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Date:</th>
                                                        <td>{moment.utc(post?.date?.event_date).local().format("DD MMM YYYY hh:mm A")}</td>
                                                        {/* <td>{post && moment(new Date(post?.date?.event_date)).format("DD MMM YYYY hh:ss A")}</td> */}
                                                    </tr>
                                                    <tr>
                                                        <th>Type:</th>
                                                        <td>{post && post?.event_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Participants:</th>
                                                        <td>{post && post?.totalParticipants}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Invited:</th>
                                                        <td>{post && post?.totalInvited}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total RSVP | Attending | Not Attending | Not Responded:</th>
                                                        <td>
                                                            {post && post?.totalRsvp} | {post && post?.totalRsvpAttending} | {post && post?.totalRsvpDecline} | {post && post?.totalRsvpNotSure}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>Participant marking RSVP as Attending:</th>
                                                        <td>{post && post?.totalRsvpAttending}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Participant marking RSVP as Not Attending:</th>
                                                        <td>{post && post?.totalRsvpDecline}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Participant marking RSVP as Not Responsed:</th>
                                                        <td>{post && post?.totalRsvpNotSure}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <th>Total participation time of participants:</th>
                                                        <td>{post && post?.totalDuration}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Attendees | Invited Attendees | Uninvited Attendees :</th>
                                                        <td>{post && post?.totalAttendees} | {post && post?.totalInvitedAttendees} | {post && post?.totalUninvitedAttendees}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Attended + Invited + RSVP Yes :</th>
                                                        <td>{post && post?.totalAttendedInvitedRSVPYes}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Attended + Invited + RSVP No/Not Responded :</th>
                                                        <td>{post && post?.totalAttendedInvitedRSVPNo}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Attended + Not Invited (New users) :</th>
                                                        <td>{post && post?.totalAttendedNotInvited}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th>Description:</th>
                                                        <td>
                                                            {post && <div dangerouslySetInnerHTML={{ __html: post?.description }}></div>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                {
                                    post && post?.event_type === "General Event" &&
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Founder | Startup</th>
                                                {/* <th>Funding Deal List</th> */}
                                                <th>Associate To</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                post && post?.invited_founder.length > 0 && post?.invited_founder.map((invitedFounderVal, invitedFounderKey) => 
                                                
                                                {
                                                    if(invitedFounderVal?.startup?.basic_information?.startup_name){++founder_count}
                                                    return (invitedFounderVal?.startup?.basic_information?.startup_name && <tr>
                                                        <td>{founder_count}</td>
                                                        <td>{invitedFounderVal?.founder_info?.founder_name} | {invitedFounderVal?.startup?.basic_information?.startup_name}</td>
                                                        {/* <td>
                                                            <select className="form-control" onChange={(e) => this.handelFundingDealId(e)}>
                                                                <option>Select</option>
                                                                {post && post?.founderFundingDeal.length > 0 && post?.founderFundingDeal.map((founderFundingDealVal) => {
                                                                    if (invitedFounderVal?.founder_info?.isEngagement?.founderId === founderFundingDealVal?.user_id) {
                                                                        return (<option value={founderFundingDealVal?._id} selected="selected">{founderFundingDealVal?.funding_information?.funding_round} | {founderFundingDealVal?.current_status}</option>)
                                                                    } else {
                                                                        return (<option value={founderFundingDealVal?._id}>{founderFundingDealVal?.funding_information?.funding_round} | {founderFundingDealVal?.current_status}</option>)
                                                                    }
                                                                })}
                                                            </select>
                                                        </td> */}
                                                        <td>
                                                            {invitedFounderVal?.startup?.engagement_activities?.filter((items)=>{
                                                             return  items.event_id===this.props?.match?.params?.id
                                                            })?.length  ? "Associated" : <Button className="btn btn-primary" onClick={() => this.engagementActivity(invitedFounderVal?.founder_info?.startup_id)}>Associate To</Button>}
                                                        </td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

EventDetails.propTypes = {
    postDetails: PropTypes.object,
    match: PropTypes.object,
};

export default connect(
)(withRouter(EventDetails));