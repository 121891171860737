import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { displayImage } from "../../helpers/storage_helper";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";

import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import DataFetchLoader from "../../components/Common/DataFetchLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { isEmpty } from "lodash";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Form } from "react-bootstrap";

// Quill Editor for Event Description
import ReactQuill from "react-quill";

//Import Breadcrumb

function WeeklyBrewLogs() {
  const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: false,
    prePageText: false,
    nextPageText: false,
    lastPageText: false,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: false,
  };
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [posts, setWeeklyBrewList] = useState([]);
  const [post, setPost] = useState({
    datetime: "01-01-1971",
  });

  const [dynamicContentImage, setDynamicContentImage] = useState({
    fileName: null,
    file: null,
  });

  const [marketingImgFile, setMarketingImgFile] = useState({
    fileName: null,
    file: null,
  });

  const [marketing_title, setMarketing_title] = useState();

  const [postsData, setPostsData] = useState([]);
  const [selectedPostsData, setSelectedPostsData] = useState([]);

  const [access, setAccess] = useState({});

  const token = authHeader();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const saas_parent_id = authUser?.userInfo?.saas_parent_id;
  const is_super_admin = authUser?.userInfo?.is_super_admin;

  function fetchData() {
    const queryParameters = new URLSearchParams(window.location.search);
    let email_event = queryParameters.get("event");
    let brew_date = queryParameters.get("brew_date");
    let datetime = queryParameters.get("datetime");

    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/weeklyBrew/getBrewLog?event=${email_event}&brew_date=${brew_date}&datetime=${datetime}`,
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then(
        ({ data }) => {
          console.log("data.result", data.result);
          setIsLoaded(true);
          setWeeklyBrewList(data.result);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    const roleId = JSON.parse(localStorage.getItem("authUser")).userInfo.roleId;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/admin/role/role-wise-access/${roleId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        }
      )
      .then(res => {
        setAccess(res.data.roleWiseAccess);
      })
      .catch(error => {
        console.log("route-error", error?.message);
      });

    return () => {};
  }, []);

  const fetchValues = posts => {
    let allPostID = [];
    for (let post of posts) {
      // console.log("postsDataaaaaaaaaa", post)
      allPostID.push(post.value);
    }
    return allPostID;

    // console.log("changed data", e[0].value)
  };
  const filterRecords = postIDs => {
    let posts = postsData;
    let selectedPosts = [];

    posts.forEach(item => {
      if (postIDs.includes(item.value)) selectedPosts.push(item);
    });

    return selectedPosts;
  };

  const columns = [
    {
      text: "id",
      dataField: "_id",
      sort: true,
      hidden: true,
      formatter: (cellContent, post) => <>{post._id}</>,
    },

    {
      dataField: "mail_to",
      text: "Email ID",
    },
    {
      dataField: "eventType",
      text: "Event Type",
    },

    {
      dataField: "mail_subject",
      text: "Brew Subject",
    },

    // {
    //     dataField: "createdAt",
    //     text: "Created On",
    //     sort: true,
    //     formatter: (cellContent, post) => (
    //         <>{moment(new Date(post.createdAt)).format("DD MMM Y")}</>
    //     ),
    // },
  ];

  const eventDescriptionEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "+1" },
        { indent: "-1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const eventDescriptionEditorFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean",
  ];

  console.log("POST:", post);
  console.log(
    "dynamicContentImageeeeeeeeeeeeeeeeeeeeeeeeee:",
    dynamicContentImage
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Posts | Invstt - Admin</title>
        </MetaTags>
        <Container fluid>
          <DataFetchLoader loading={!isLoaded} />
          <Row className="mb-3">
            <Col sm="6">
              <h5 className="text-uppercase">WEEKLY BREW EMAIL LOGS</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="9">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative"></div>
                        </div>
                      </Col>
                    </Row>
                    <PaginationProvider
                      pagination={paginationFactory(options)}
                      keyField="_id"
                      columns={columns}
                      data={posts || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          columns={columns}
                          data={posts || []}
                          search={{
                            searchFormatted: true,
                          }}
                        >
                          {toolkitprops => (
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              keyField="_id"
                              data={posts}
                              columns={columns}
                              bordered={false}
                              striped={true}
                              responsive
                              filter={filterFactory()}
                            />
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Row>
                      <Col xl="12">
                        {!isEmpty(error) ? (
                          <p className="text-danger">Some error occured!</p>
                        ) : null}
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
WeeklyBrewLogs.propTypes = {
  posts: PropTypes.array,
  className: PropTypes.any,
  error: PropTypes.string,
};

export default WeeklyBrewLogs;
