import React, { Component } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Alert} from "reactstrap";
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom"
import { amountNumberToTextFrmt } from "../../helpers/utils";
import PropTypes from "prop-types"
import { getInvestors, closeAlertBox } from "../../store/credits/actions"
import { isEmpty } from "lodash";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AddCreditModal from "./add-credit-modal";
import DataFetchLoader from "components/Common/DataFetchLoader";


class Credits extends Component {

  constructor(props) {
    super(props);
    this.state = {
      investors: [],
      investor: null,
      show: false,
      loading: false,
      page: 1,
      query: "",
      dataSize: 0,
      error: "",
      success: "",
      str:"",
      tableaction:""
    }

    

    
  }

  loadInvestors = (page) => {
      this.setState({loading: true})
      const { investors, dataSize, onGetInvestors} = this.props;
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const saas_parent_id = authUser?.userInfo?.saas_parent_id;
      const is_super_admin = authUser?.userInfo?.is_super_admin;
      const show_all_saas_data = JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data;
      const str = this.state.str;
      onGetInvestors({query: `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`+str});
     
      this.setState({ investors: investors, dataSize: dataSize, loading: false, page:page });
  }

  componentDidMount() {
    this.loadInvestors(this.state.page);
  }

  
static getDerivedStateFromProps(props, state) {
  if(props.dataSize !== state.dataSize){
      //Change in props
      return{
        dataSize: props.dataSize
      };
  }
  return null; // No change to state
}   
  handleShow = () => {
    this.setState({ show: !this.state.show });
  }

  handleSelect = (investor) => {
    this.setState({ investor: investor });
    this.handleShow();
  }

  handleCreditUpdateDone = () => {
    this.loadInvestors(this.state.page);
  }

  handleAlertClose = () => {
    const { onAlertClose } = this.props;
    onAlertClose();
  }

  render() {

    const user_types = (types) => {
        let uTypes = [];
        types.map((u,i) => {
            if(u?.user_type && u?.user_type.length > 0){
               uTypes.push(u?.user_type[0]);
            }
        });
        return uTypes;
    }

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size}
      </span>
    );
    const options = {
      paginationSize: 1,
      pageStartIndex: 1,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: false,
      prePageText: false,
      nextPageText: false,
      lastPageText: false,
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      sizePerPage: 25,
      totalSize: this.state.dataSize,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: false,
      onPageChange: (page, size) => {
          this.loadInvestors(page);
          //return false;
      },
    };

    const columns = [
      {
        text: "id",
        dataField: "_id",
        sort: true,
        hidden: true,
        formatter: (cellContent, startup) => <>{startup.id}</>,
      },
      {
        dataField: "firstname",
        text: "User Name",
        formatter: (cellContent, investor) => <> <span className="text-capitalize ">{`${investor?.firstname} ${investor?.lastname}`}</span> <br/>
                                                  <span className="text-capitalize text-primary">{user_types(investor?.user_types || []).join(", ")}</span></>,
        filter: textFilter()
      },
      {
        dataField: "email",
        text: "Email",
        formatter: (cellContent, investor) => <>{investor.email}</>,
        filter: textFilter()
      },
      {
        dataField: "credit_info.active_plan_name",
        text: "Plan Name",
        formatter: (cellContent, investor) => <span className="text-capitalize d-flex justify-content-center">{investor?.credit_info?.active_plan_name}</span>,
        filterValue: (cell, row) => row?.credit_info?.active_plan_name,
        filter: textFilter(),
      },
      {
        dataField: "credit_info.active_plan_type",
        text: "Plan Type",
        formatter: (cellContent, investor) => <span className="d-flex justify-content-center">{investor?.credit_info?.active_plan_type}</span>,
        filterValue: (cell, row) => row?.credit_info?.active_plan_type,
        filter: textFilter()
      },
      {
        dataField: "credit_info.credit_amount",
        text: "Credit Amount",
        formatter: (cellContent, investor) => <span className="d-flex justify-content-center">{amountNumberToTextFrmt(investor?.credit_info?.credit_amount)}</span>,
        filterValue: (cell, row) => row?.credit_info?.credit_amount,
      },
      {
        dataField: "credit_info.is_active",
        text: "Plan Active",
        formatter: (cellContent, investor) => <span className="d-flex justify-content-center">{investor?.credit_info?.is_active === true? "Yes": "No"}</span>,
        filterValue: (cell, row) => row?.credit_info?.is_active,
        filter: textFilter()
      },
      {
        dataField: "credit_info.expires_on",
        text: "Credit Expire On",
        formatter: (cellContent, investor) => <span className="d-flex justify-content-center">{investor?.credit_info?.expires_on}</span>,
        filterValue: (cell, row) => row?.credit_info?.expires_on,
      },
      {
        dataField: "menu",
        isDummyField: true,
        editable: false,
        text: "Add / Remove Credit",
        formatter: (cellContent, investor) => (
          <div className="d-flex gap-3 justify-content-center">
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                title="Click to View / Add Credit"
                id="edittooltip"
                onClick={() => this.handleSelect(investor)}
              ></i>
            </Link>
          </div>
        ),
      },
    ];

    const onTableChange = async (action, { filters, sortOrder, sortField }) => {
      
        let str = "";
        Object.keys(filters).forEach(filter => {
          let filterVal = filters?.[filter]?.filterVal;
          if (filterVal) {
            switch (filter) {
              case "credit_info.active_plan_name":
                str += `&planName=${filterVal}`;
                break;
              default:
                str += `&${filter}=${filterVal}`;
                break;
            }
          }
        });

        this.setState({str:str});    
        if(str!='' && action!='pagination'){
          this.setState({loading: true});
      const { investors, dataSize, onGetInvestors} = this.props;
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const saas_parent_id = authUser?.userInfo?.saas_parent_id;
      const is_super_admin = authUser?.userInfo?.is_super_admin;
      const show_all_saas_data = JSON.parse(localStorage.getItem("roleWiseAccess"))?.show_all_saas_data;
            
      const page = this.state.page;
      
      onGetInvestors({query: `?page=${page}&saas_parent_id=${saas_parent_id}&is_super_admin=${is_super_admin}&show_all_saas_data=${show_all_saas_data}`+str});
      
      this.setState({ investors: investors, dataSize: dataSize, loading: false,page:page,tableaction:'filter' });
      
        }
        

    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Credits | Invstt - Admin</title>
          </MetaTags>
          <Container fluid>
          <DataFetchLoader loading={this.state.loading} />
            {/* Render Breadcrumbs */}
            <Row className="">
              <Col sm="6">
                <h5 className="text-uppercase">Credits</h5>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                {
                  !isEmpty(this.state.error) ? (
                    <Alert color="danger" toggle={this.handleAlertClose}>Some error occured!</Alert>
                  ) : null
                }
                {!!this.state.success ? (
                  <Alert color="success" toggle={this.handleAlertClose}>{this.state.success}</Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg="12">
              <Card>
              <DataFetchLoader loading={(this.state?.investors) ? false : true} />
                <CardBody>
                    <React.Fragment>
                        <PaginationProvider
                            pagination={paginationFactory(options)}
                            keyField="_id"
                            columns={columns}
                            data={this.props.investors}
                        >
                            {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                    keyField="_id"
                                    columns={columns}
                                    data={this.props.investors}
                                    search={{
                                      searchFormatted: true,
                                    }}
                                    exportCSV
                                  >
                                  {
                                   props => (
                                      <BootstrapTable
                                        {...props.baseProps}
                                        {...paginationTableProps}
                                        keyField="_id"
                                        columns={columns}
                                        data={this.props.investors}
                                        remote={true}
                                        bordered={false}
                                       striped={true}
                                        responsive
                                        onTableChange={onTableChange}
                                        filter={filterFactory()}
                                      />
                                   )
                                  }
                                  </ToolkitProvider>
                            )}
                        </PaginationProvider>
                    </React.Fragment>
                </CardBody>
                </Card>
              </Col>

              {/*<Col lg="12">
                <React.Fragment>
                  <PaginationProvider
                    pagination={paginationFactory(this.options)}
                    keyField="_id"
                    columns={columns}
                    data={this.state.investors}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={this.props.investors}
                        search={{
                          searchFormatted: true,
                        }}
                        exportCSV
                      >
                        {toolkitprops => (
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            {...paginationTableProps}

                            keyField="_id"
                            data={this.state.investors}
                            columns={columns}
                            bordered={false}
                            ref={n => this.node = n}
                            striped={false}
                            responsive
                            filter={filterFactory()}

                          />
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </React.Fragment>
              </Col>*/}
            </Row>
          </Container>
          { (this.state.show && this.state.investor) && <AddCreditModal investor={this.state.investor} onClose={this.handleShow} onCreditUpdateDone={this.handleCreditUpdateDone} />}

        </div>
      </React.Fragment >
    )
  }
}

Credits.propTypes = {
  investors: PropTypes.array,
  dataSize: PropTypes.any,
  onGetInvestors: PropTypes.func,
  onAlertClose: PropTypes.func,
  className: PropTypes.any,
  error: PropTypes.any,
  success: PropTypes.any,
  investor: PropTypes.any,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
}

const mapStateToProps = ({ credits }) => ({
  investors: credits?.investors || [],
  investor: credits?.investor || null,
  dataSize: credits?.dataSize || 0,
  error: credits?.error || "",
  success: credits?.success || "",
  show: credits?.show || false,
})

const mapDispatchToProps = dispatch => ({
  onGetInvestors: (data) => dispatch(getInvestors(data)),
  onAlertClose: () => dispatch(closeAlertBox()),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Credits))
