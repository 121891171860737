import React from "react";
import Select from "react-select";
import { Accordion } from "react-bootstrap";
import { Table } from "reactstrap";
import { toCommaFormat } from "helpers/backend_helper";
import moment from "moment";

const rightAlign = { textAlign: "right", whiteSpace: "nowrap" };

function LedgerDetails(props) {
  console.log("PROPS:::", props);
  let {
    relatives,
    currentlySelectedRelative,
    setCurrentySelectedRelative,
    handleReport,
    caDdDetails,
  } = props;
  console.log("Errorrs:", caDdDetails?.errors);
  console.log("Errorrs:", caDdDetails?.consentReport);

  let finalData = [];
  if (caDdDetails?.consentReport?.length >= 2) {
    let data = caDdDetails?.consentReport?.map(item => {
      let approvedDate = "--";
      if (
        typeof item?.approvedAt !== "undefined" &&
        item?.approvedAt !== "" &&
        moment(item?.approvedAt, "DD-MMM-YYYY", true).isValid()
      ) {
        approvedDate = item?.approvedAt;
      }
      if (
        typeof item?.approvedAt !== "undefined" &&
        item?.approvedAt !== "" &&
        (item?.approvedAt === "Not Available" || item.approvedAt === "--")
      ) {
        approvedDate = "--";
      } else if (
        typeof item?.approvedAt === "undefined" ||
        item?.approvedAt === "" ||
        item?.approvedAt === "--"
      ) {
        approvedDate = "--";
      } else {
        approvedDate = moment(item?.approvedAt).format("DD-MMM-YYYY");
      }

      return {
        ...item,
        approvedAt: approvedDate,
      };
    });

    let investmentArray = data?.slice(1, data?.length - 1);

    let blankDates = investmentArray?.filter(item => item?.approvedAt === "--");
    let withDates = investmentArray?.filter(item => item?.approvedAt !== "--");

    finalData = [data[0], ...blankDates, ...withDates, data[data?.length - 1]];
  }

  return (
    <>
      <div style={{ marginBottom: "10px", marginTop: "10px" }}>
        <Select
          options={relatives || []}
          value={currentlySelectedRelative}
          onChange={e => {
            setCurrentySelectedRelative({
              value: e.value,
              label: e.label,
            });
            handleReport({
              ...caDdDetails.generalDetails,
              relation: e.value,
              fund_type_id: e.fund_type_id,
            });
          }}
        />
      </div>
      <Accordion defaultActiveKey={["0"]}>
        {(Object.keys(caDdDetails?.reportDetails || {}) || [])?.length > 0 && (
          <Accordion.Item eventKey="0">
            <Accordion.Header>Investment Statistics</Accordion.Header>
            <Accordion.Body>
              <div className="table-responsive int_table">
                <div className="react-bootstrap-table">
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Field</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(
                        Object.keys(caDdDetails?.reportDetails || {}) || []
                      ).map((key, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{key}</td>
                            <td>
                              {key === "Total Investment so far"
                                ? `₹${toCommaFormat(
                                    caDdDetails?.reportDetails[`${key}`]
                                  )}`
                                : caDdDetails?.reportDetails[`${key}`]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
      <br />
      <Accordion defaultActiveKey={["0"]}>
        {(Object.keys(caDdDetails?.errors || {}) || [])?.length > 0 && (
          <Accordion.Item eventKey="0">
            <Accordion.Header className="accordion-custom-header">
              Alert
            </Accordion.Header>
            <Accordion.Body style={{ paddingBottom: 0 }}>
              <div>
                <ul>
                  {Object.keys(caDdDetails?.errors)?.map((key, index) => {
                    let str = caDdDetails?.errors[key];
                    if (str?.includes("support@invstt.com")) {
                      str = str.split("support@invstt.com");
                      str = (
                        <span style={{ color: "#d0342c" }}>
                          {str[0]}{" "}
                          <span style={{ color: "blue", cursor: "pointer" }}>
                            support@invstt.com
                          </span>{" "}
                          {str[1]}
                        </span>
                      );
                    } else {
                      str = <span style={{ color: "#d0342c" }}>{str}</span>;
                    }
                    return (
                      <li
                        className={index > 0 ? "mt-2" : ""}
                        style={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {str}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
      <br />
      <Accordion defaultActiveKey={["0"]}>
        {(finalData || [])?.length > 0 && (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Contribution Amount Consumption Trail
            </Accordion.Header>
            <Accordion.Body>
              <div className="table-responsive int_table">
                <div className="react-bootstrap-table">
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Startup</th>
                        <th>Round Name</th>
                        <th>Syndicate</th>
                        <th>Month/Year</th>
                        <th>Base Amount</th>
                        <th>Service Fee</th>
                        <th>Amount</th>
                        <th>Approved On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(finalData || []).map((item, index) => {
                        let greyStyle = {};
                        if (item?.contribution_limit_exceeded === true) {
                          greyStyle = { background: "#ccc", color: "#000" };
                        }

                        return (
                          <tr
                          // style={
                          //   item?.currency?.includes("$")
                          //     ? { background: "#ccc", color: "#000" }
                          //     : item?.contribution_limit_exceeded === true
                          //     ? { background: "#ccc", color: "#000" }
                          //     : {}
                          // }
                          >
                            <th scope="row" style={{ ...greyStyle }}>
                              {caDdDetails?.consentReport?.length === index + 1
                                ? "Total"
                                : index + 1}
                            </th>
                            <td style={{ whiteSpace: "nowrap", ...greyStyle }}>
                              {item?.startup}
                            </td>
                            <td style={{ ...greyStyle }}>
                              {item?.funding_round}
                            </td>
                            <td style={{ ...greyStyle }}>{item?.syndicate}</td>
                            <td style={{ ...greyStyle }}>{item?.deal_month}</td>
                            <td style={{ ...greyStyle }}>
                              {item?.baseConsentAmount}
                            </td>
                            <td style={{ ...greyStyle }}>
                              {item?.baseConsentAmountFee}
                            </td>
                            <td
                              // style={rightAlign}
                              className={
                                item?.contribution_limit_exceeded === true
                                  ? "text-black"
                                  : item?.className
                              }
                              style={{ ...greyStyle }}
                            >{`${
                              item?.contribution_limit_exceeded === true
                                ? ""
                                : item?.sign
                            }${item?.currency}${toCommaFormat(
                              item?.consentAmount
                            )}`}</td>
                            <td style={{ ...greyStyle }}>{item?.approvedAt}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </>
  );
}

export default LedgerDetails;
